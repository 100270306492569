import React from 'react';

import CsoMembershipState from './operator/getCsoMembership';
import CsoAccount from './operator/getCsoAccount';
import CsoCharger from './operator/chargerSetup';
import CsoChargingStations from './operator/getCsoChargers';
import Reports from './operator/getReports';
import Errors from './shared/ErrorContext';

const ContextWrapper = (props) => {
  return (
    <Errors>
      <CsoAccount>
        <CsoChargingStations>
          <CsoCharger>
            <CsoMembershipState>
              <Reports>{props.children}</Reports>
            </CsoMembershipState>
          </CsoCharger>
        </CsoChargingStations>
      </CsoAccount>
    </Errors>
  );
};

export default ContextWrapper;
