import React from 'react';
import { FaCreditCard } from 'react-icons/fa';

//components
import Button from '../../../shared/Buttons/Button';

//styles
import {
  PaymentCreditCardDisplayStyles,
  PaymentCreditCardDetailStyles,
  ButtonContainerStyles,
  ButtonStyles,
} from '../../../SharedStyles';

const CreditCardDisplay = (props) => {
  const { customerData, toggleEditCard, removeCard, strings } = props;
  return (
    <PaymentCreditCardDisplayStyles pSize='14px'>
      <PaymentCreditCardDetailStyles detailsSize='12px'>
        <FaCreditCard />
        <div className='Details'>
          <p>{customerData.brand}</p>
          <p>************{customerData.last4}</p>
          <p>
            {customerData.exp_month} / {customerData.exp_year}
          </p>
        </div>
      </PaymentCreditCardDetailStyles>
      <p className='Note'>{strings.ccOnFile}</p>
      <p className='Note'>{strings.ccUpdate}</p>
      <Button
        driver
        onclick={toggleEditCard}
        buttonText={strings.ccUpdateButton}
      />     
      <ButtonContainerStyles
      borderBottom={true}
      margin={'0'}
      padding={'20px 0'}>
        <ButtonStyles
           inverse
          title={strings.ccRemoveButton}
          onClick={removeCard}>
          {strings.ccRemoveButton}
        </ButtonStyles>

    </ButtonContainerStyles>

    </PaymentCreditCardDisplayStyles>
  );
};

export default CreditCardDisplay;