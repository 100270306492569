import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//component
import ModalButton from '../../../../../shared/Buttons/ModalButton';

//styles
import {
  DivStyles,
  ModalContainerStyles,
  ModalContentStyles,
  ModalHeaderStyles,
  ModalStyles,
  TextStyles,
  TitleStyles,
} from '../../../../../SharedStyles';

import { timeConvert } from '../../../../../shared/Helpers';

const DiagnosticsModal = ({ diagnostics, closeModal }) => {
  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{'Diagnostics'}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles textMargin='0'>
          {/* Section */}
          <DivStyles
            display='flex'
            alignItems='center'
            size='14px'
            align='left'
            margin='0 0 5px'>
            <TitleStyles flex='1' size='14px' color='#000' margin='0'>
              {'Connector Status'}:
            </TitleStyles>
            <TextStyles flex='1' size='14px' color='#000' margin='0'>
              {diagnostics.online}
            </TextStyles>
          </DivStyles>
          <DivStyles
            display='flex'
            alignItems='center'
            size='14px'
            align='left'
            margin='0 0 5px'>
            <TitleStyles flex='1' size='14px' color='#000' margin='0'>
              {'Authenticated User'}:
            </TitleStyles>
            <TextStyles flex='1' size='14px' color='#000' margin='0'>
              {diagnostics.authenticatedUserName
                ? diagnostics.authenticatedUserName
                : 'None'}
            </TextStyles>
          </DivStyles>
          <DivStyles
            display='flex'
            alignItems='center'
            size='14px'
            align='left'
            margin='0 0 5px'>
            <TitleStyles flex='1' size='14px' color='#000' margin='0'>
              {'Vehicle ID'}:
            </TitleStyles>
            <TextStyles flex='1' size='14px' color='#000' margin='0'>
              {diagnostics.vehicle ? diagnostics.vehicle : 'None'}
            </TextStyles>
          </DivStyles>

          {/* Section */}
          {diagnostics.authenticatedUser &&
            diagnostics.authenticatedUser.chargeRangeInfo && (
              <>
                <DivStyles
                  borderBottom='1px solid #c2c2c2'
                  padding='0 0 5px'
                  margin='10px 0 5px'
                  align='left'>
                  <TitleStyles flex='100%' size='14px' margin='0'>
                    {'Transaction Information'}
                  </TitleStyles>
                </DivStyles>
                <DivStyles
                  display='flex'
                  alignItems='center'
                  size='12px'
                  align='left'
                  margin='0 0 5px'>
                  <TitleStyles flex='1' size='12px' color='#000' margin='0'>
                    {'Transaction ID'}:
                  </TitleStyles>
                  <TextStyles flex='1' size='12px' color='#000' margin='0'>
                    1284
                  </TextStyles>
                </DivStyles>
                {/* ============== */}
                <DivStyles
                  borderBottom='1px solid #c2c2c2'
                  padding='0 0 5px'
                  margin='10px 0 5px'
                  align='left'>
                  <TitleStyles flex='100%' size='14px' margin='0'>
                    {'Range Configuration'}
                  </TitleStyles>
                </DivStyles>
                <DivStyles
                  display='flex'
                  alignItems='center'
                  size='12px'
                  align='left'
                  margin='0 0 5px'>
                  <TitleStyles flex='1' size='12px' color='#000' margin='0'>
                    {'Desired Range'}:
                  </TitleStyles>
                  <TextStyles flex='1' size='12px' color='#000' margin='0'>
                    {
                      diagnostics.authenticatedUser.chargeRangeInfo
                        .desiredMaxRange
                    }
                  </TextStyles>
                </DivStyles>
                <DivStyles
                  display='flex'
                  alignItems='center'
                  size='12px'
                  align='left'
                  margin='0 0 5px'>
                  <TitleStyles flex='1' size='12px' color='#000' margin='0'>
                    {'Start Range'}:
                  </TitleStyles>
                  <TextStyles flex='1' size='12px' color='#000' margin='0'>
                    {
                      diagnostics.authenticatedUser.chargeRangeInfo
                        .startRangeOfVehicle
                    }
                  </TextStyles>
                </DivStyles>
                <DivStyles
                  display='flex'
                  alignItems='center'
                  size='12px'
                  align='left'
                  margin='0 0 5px'>
                  <TitleStyles flex='1' size='12px' color='#000' margin='0'>
                    {'Max Range'}:
                  </TitleStyles>
                  <TextStyles flex='1' size='12px' color='#000' margin='0'>
                    {
                      diagnostics.authenticatedUser.chargeRangeInfo
                        .maxRangeOfVehicle
                    }
                  </TextStyles>
                </DivStyles>
                <DivStyles
                  display='flex'
                  alignItems='center'
                  size='12px'
                  align='left'
                  margin='0 0 5px'>
                  <TitleStyles flex='1' size='12px' color='#000' margin='0'>
                    {'Battery Capacity'}:
                  </TitleStyles>
                  <TextStyles flex='1' size='12px' color='#000' margin='0'>
                    {
                      diagnostics.authenticatedUser.chargeRangeInfo
                        .vehicleBatteryCapacity
                    }{' '}
                    kWh
                  </TextStyles>
                </DivStyles>
                {/* ============================ */}
                <DivStyles
                  borderBottom='1px solid #c2c2c2'
                  padding='0 0 5px'
                  margin='10px 0 5px'
                  align='left'>
                  <TitleStyles flex='100%' size='14px' margin='0'>
                    {'Rate Configuration'}
                  </TitleStyles>
                </DivStyles>
                <DivStyles
                  display='flex'
                  alignItems='center'
                  size='12px'
                  align='left'
                  margin='0 0 5px'>
                  <TitleStyles flex='1' size='12px' color='#000' margin='0'>
                    {'Rate'}:
                  </TitleStyles>
                  <TextStyles flex='1' size='12px' color='#000' margin='0'>
                    {diagnostics.authenticatedUser.rate === 0 && 'Free'}
                    {diagnostics.authenticatedUser.rate > 0 &&
                      `$${diagnostics.authenticatedUser.rate.toFixed(2)}/${
                        diagnostics.authenticatedUser.rateType
                      }`}
                  </TextStyles>
                </DivStyles>
                <DivStyles
                  display='flex'
                  alignItems='center'
                  size='12px'
                  align='left'
                  margin='0 0 5px'>
                  <TitleStyles flex='1' size='12px' color='#000' margin='0'>
                    {'Idle Rate'}:
                  </TitleStyles>
                  <TextStyles flex='1' size='12px' color='#000' margin='0'>
                    {diagnostics.authenticatedUser.idleRate === 0 && 'None'}
                    {diagnostics.authenticatedUser.idleRate > 0 &&
                      `$${diagnostics.authenticatedUser.idleRate.toFixed(2)}/${
                        diagnostics.authenticatedUser.idleRateType
                      }`}
                  </TextStyles>
                </DivStyles>
                <DivStyles
                  display='flex'
                  alignItems='center'
                  size='12px'
                  align='left'
                  margin='0 0 5px'>
                  <TitleStyles flex='1' size='12px' color='#000' margin='0'>
                    {'Grace period'}:
                  </TitleStyles>
                  <TextStyles flex='1' size='12px' color='#000' margin='0'>
                    {timeConvert(diagnostics.authenticatedUser.gracePeriod)}
                  </TextStyles>
                </DivStyles>
                <DivStyles
                  display='flex'
                  alignItems='center'
                  size='12px'
                  align='left'
                  margin='0 0 5px'>
                  <TitleStyles flex='1' size='12px' color='#000' margin='0'>
                    {'Coupon'}:
                  </TitleStyles>
                  <TextStyles flex='1' size='12px' color='#000' margin='0'>
                    {diagnostics.authenticatedUser.couponId
                      ? diagnostics.authenticatedUser.couponId
                      : 'None'}
                  </TextStyles>
                </DivStyles>
                <DivStyles
                  display='flex'
                  alignItems='center'
                  size='12px'
                  align='left'
                  margin='0 0 5px'>
                  <TitleStyles flex='1' size='12px' color='#000' margin='0'>
                    {'Membership'}:
                  </TitleStyles>
                  <TextStyles flex='1' size='12px' color='#000' margin='0'>
                    {diagnostics.authenticatedUser.membershipId !== ''
                      ? 'Member'
                      : 'None'}
                  </TextStyles>
                </DivStyles>
                {/* ============================ */}
              </>
            )}
          {/* Note Section */}
          {/* <ModalNoteStyles>Special Notes</ModalNoteStyles> */}
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={closeModal}
          buttonText={'OK'}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default DiagnosticsModal;
