//services
import AppSubscriptionService from './AppSubscriptionService';

import shareImage from '../../../assets/images/subscriptions/AmpedUpShareImage.png';
import smallBizImage from '../../../assets/images/subscriptions/AmpedUpSmallBizImage.png';
import enterpriseImage from '../../../assets/images/subscriptions/AmpedUpEnterpriseImage.png';

const { strings } = AppSubscriptionService;

export const plans = [
  {
    title: strings.shareTitle,
    name: 1,
    fee: strings.shareFee,
    content: strings.shareContent,
    image: shareImage,
    enabled: false,
  },
  {
    title: strings.smallBizTitle,
    name: 2,
    fee: strings.smallBizFee,
    content: strings.smallBizContent,
    image: smallBizImage,
    enabled: true,
  },
  {
    title: strings.enterpriseTitle,
    name: 3,
    fee: strings.enterpriseFee,
    content: strings.enterpriseContent,
    image: enterpriseImage,
    enabled: false,
  },
];
