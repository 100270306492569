import React from 'react';
import axios from 'axios';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppModalService from './AppModalService';

//global components
import ModalButton from '../../../../../shared/Buttons/ModalButton';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  InputStyles,
  FormStyles,
  DivStyles,
} from '../../../../../SharedStyles';

//key
import { MAP_API } from '../../../../../shared';

const Edit = (props) => {
  const { chargerDetails, location } = props;
  const { strings } = AppModalService;

  const closeModal = () => {
    if (chargerDetails.publiclySearchable) {
      props.enableSearchAfterUpdate();
    }
    props.handleModalClose();
  };

  // const closeModalWithoutSaving = () => {
  //   if (chargerDetails.publiclySearchable) {
  //     props.enableSearchAfterUpdate();
  //   }
  //   props.handleModalClose();
  // };

  const handleChange = async (e) => {
    props.handleChange(e.target.value, [e.target.name]);
    if (chargerDetails.publiclySearchable) {
      await AppModalService.disableChargerSearch(chargerDetails.iotHubDeviceId);
    }
  };

  const submitAddress = async (e) => {
    e.preventDefault();

    let googleResults = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${chargerDetails.chargerAddress1}${location.city}${location.state}${location.zip}${location.country}&key=${MAP_API}`
    );

    let chargerLocation = {
      type: 'Point',
      coordinates: [
        googleResults.data.results[0].geometry.location.lat,
        googleResults.data.results[0].geometry.location.lng,
      ],
    };
    props.handleChange(chargerLocation, 'location');

    props.handleModalClose();
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.editTitle}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <p>{strings.editContent}</p>
          </ModalItemStyles>
          {/* Section */}
          <ModalItemStyles padding='0' margin='0 0 15px'>
            <FormStyles
              display='block'
              id='AddressForm'
              onSubmit={submitAddress}>
              <InputStyles
                padding='0'
                margin='0'
                type='text'
                name='chargerName'
                placeholder={strings.editNamePlaceholder}
                value={chargerDetails.chargerName || ''}
                onChange={handleChange}
                required
              />
              <InputStyles
                padding='0'
                margin='0'
                type='text'
                name='chargerAddress1'
                placeholder={strings.editAdd1Placeholder}
                value={chargerDetails.chargerAddress1 || ''}
                onChange={handleChange}
                required
              />
              <InputStyles
                padding='0'
                margin='0'
                type='text'
                name='chargerAddress2'
                placeholder={strings.editAdd2Placeholder}
                value={chargerDetails.chargerAddress2 || ''}
                onChange={handleChange}
              />
            </FormStyles>
          </ModalItemStyles>
          <ModalItemStyles pSize='12px' textMargin='0 0 5px'>
            <DivStyles display='flex' alignItems='center' align='left'>
              <DivStyles weight='600' align='left' flex='1'>
                <p>{strings.editCity}:</p>
                <p>{strings.editState}:</p>
                <p>{strings.editZip}:</p>
                <p>{strings.editCountry}:</p>
              </DivStyles>
              <DivStyles align='left' flex='1' color='#aaa6a6'>
                <p>{location.city}</p>
                <p>{location.state}</p>
                <p>{location.zip}</p>
                <p>{location.country}</p>
              </DivStyles>
            </DivStyles>
          </ModalItemStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          formSubmit='AddressForm'
          buttonText={strings.ok}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default Edit;
