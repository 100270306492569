import React, { useState, useEffect, useContext } from 'react';

//service
import CustomIncentiveService from '../CustomIncentiveService';

//layout
import {
  ComponentWrapper,
  OuterWrapper,
  RoleCanEdit,
  SubscriptionAccess,
} from '../../../../../OperatorShared';

//components
import ReportFilter from '../../../../ReportFilter';
import LoadingSpinner from '../../../../../../shared/Loading/LoadingSpinner';
import NYSERDAChargerStatusReportList from './NYSERDAChargerStatusReportList';

//context
import { CsoAccountContext } from '../../../../../../context/operator/getCsoAccount';

//helpers
import { handleFilterUpdates } from '../../ChargingSessions/Details/helpers';
import { downloadMultiCSVReport } from '../../../../../../shared/Download/download';
import { dateRange } from '../../EnergyReport/helpers';

import {
  dateProperties,
  locationProperties,
  metergroupProperties,
  chargerProperties,
  chargerTypeProperties,
} from '../../filterProperties';

//styles
import { ButtonGreyStyles, DivStyles } from '../../../../../../SharedStyles';
import AppPlatformService from '../../../../../../../services/AppPlatform/AppPlatformService';

const NYSERDAChargerStatusReport = () => {
  const { strings } = CustomIncentiveService;
  const { account } = useContext(CsoAccountContext);
  const [filters, setFilters] = useState({
    location: [locationProperties[0]],
    metergroup: [metergroupProperties[0]],
    charger: [chargerProperties[0]],
    date: dateProperties[0],
  });

  const [locSessionStrings, setLocSessionStrings] = useState([]);
  const [mgSessionStrings, setMgSessionStrings] = useState([]);
  const [chargerSessionStrings, setChargerSessionStrings] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [exportReport, setExportReport] = useState({
    Date: [],
    Location: [],
    Chargers: [],
    Metergroup: [],
    ChargerType: [],
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { locStr, mgStr, chargerStr } = handleFilterUpdates(
      account,
      filters,
      strings
    );
    setLocSessionStrings(locStr);
    setMgSessionStrings(mgStr);
    setChargerSessionStrings(chargerStr.flat());

    if (chargerStr.length > 0) {
      generateReport(chargerStr.flat());
    }
  }, [filters, strings, account]);

  const generateReport = async (availableChargers) => {
    try {
      const { startDate, endDate } = dateRange(filters.date);

      let chargersFilter = [];

      const indexAllChargers = filters.charger.indexOf(chargerProperties[0]);

      if (indexAllChargers > -1) {
        chargersFilter = availableChargers.slice(indexAllChargers + 1);
      }
      else {
        chargersFilter = filters.charger;
      }

      let query = {
        startDate: startDate,
        endDate: endDate,
        chargers: chargersFilter,
      };

      if (!query.chargers.includes('allchargers')) {
        const res = await CustomIncentiveService.getNYSERDAChargerStatusReport(query);
        setReportData(res);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    AppPlatformService.debug('export report filters ' + exportReport);
    let utilityReport = reportData.map((x) => {
      const d = {...x};
      delete d.time;
      return d;
    });
    downloadMultiCSVReport(
      `NYSERDAChargerStatusReport-${Date.now()}`,
      [{ title: '', voidFormat: true, data: utilityReport,
         headerVal: ['EVSE Serial Number',
                      'EVSE Status',
                      'Effective Status Date',
                      'Effective Status Time'] }],
      'NYSERDAChargeStatusReport',
    );
  };

  const updateReportFilters = (name, structure) => {
    setExportReport((prev) => ({ ...prev, [name]: structure }));
  };

  useEffect(() => {
    //check if strings include whats in filter if not reset filter
    let someLoc = locSessionStrings.some((l) => {
      if (l === strings.filterLocationList[0]) {
        l = locationProperties[0];
      }
      return filters.location.includes(l);
    });

    let someMg = mgSessionStrings.some((m) => {
      if (m === strings.filterMetergroupList[0]) {
        m = metergroupProperties[0];
      }
      return filters.metergroup.includes(m);
    });

    let someChar = chargerSessionStrings.some((c) => {
      if (c === strings.filterChargersList[0]) {
        c = chargerProperties[0];
      }
      return filters.charger.includes(c);
    });

    if (!someLoc) {
      setFilters((prev) => ({
        ...prev,
        location: [locationProperties[0]],
      }));
    }
    if (!someMg) {
      setFilters((prev) => ({
        ...prev,
        metergroup: [metergroupProperties[0]],
      }));
    }
    if (!someChar) {
      setFilters((prev) => ({
        ...prev,
        charger: [chargerProperties[0]],
      }));
    }

    // eslint-disable-next-line
  }, [locSessionStrings, mgSessionStrings, chargerSessionStrings]);

  const handleFilterChange = (type, filter) => {
    switch (true) {
      case type === 'Location':
        if (filter.includes(locationProperties[0])) {
          return setFilters({
            ...filters,
            location: [locationProperties[0]],
          });
        } else {
          return setFilters({ ...filters, location: filter });
        }
      case type === 'Metergroup':
        if (filter.includes(metergroupProperties[0])) {
          return setFilters({
            ...filters,
            metergroup: [metergroupProperties[0]],
          });
        } else {
          return setFilters({ ...filters, metergroup: filter });
        }
      case type === 'Chargers':
        if (filter.includes(locationProperties[0])) {
          return setFilters({
            ...filters,
            charger: [locationProperties[0]],
          });
        } else {
          return setFilters({ ...filters, charger: filter });
        }
      case type === 'Date':
        return setFilters({ ...filters, date: filter });
      case type === 'ChargerType':
        if (filter.includes(chargerTypeProperties[0])) {
          return setFilters({
            ...filters,
            chargerType: [chargerTypeProperties[0]],
          });
        } else if (filter.includes(chargerTypeProperties[1])) {
          return setFilters({
            ...filters,
            chargerType: [chargerTypeProperties[1]],
          });
        } else if (filter.includes(chargerTypeProperties[2])) {
          return setFilters({
            ...filters,
            chargerType: [chargerTypeProperties[2]],
          });
        } else {
          return setFilters({ ...filters, chargerType: filter });
        }
      default:
        return setFilters({
          location: [locationProperties[0]],
          metergroup: [metergroupProperties[0]],
          charger: [chargerProperties[0]],
          date: dateProperties[2],
          chargerType: [chargerTypeProperties[0]],
        });
    }
  };

  return (
    <OuterWrapper
      title={strings.NYSERDAChargerStatusReport}
      maxWidth='1200px'
      internal
      path='/'>
      <SubscriptionAccess levels={[1, 2, 3]}>
        <RoleCanEdit roles={[1, 2, 4]}>
          <ComponentWrapper title={strings.NYSERDAChargerStatusReport}>
            {loading && <LoadingSpinner />}
            <DivStyles>
              <DivStyles
                display='flex'
                alignItems='center'
                justifyContent='space-between'>
                <ReportFilter
                  width='200px'
                  margin='10px 10px 5px'
                  label='Incident Date'
                  name='Date'
                  updateReportFilters={updateReportFilters}
                  filterBy={strings.filterDateList}
                  filterProps={dateProperties}
                  filteredValues={filters.date}
                  handleFilterChange={handleFilterChange}
                />
                <DivStyles display='flex' alignItems='center'>
                  {/* <ButtonGreyStyles
                    padding='5px 8px'
                    disabled={blockGenerate}
                    margin='0 10px 0 0'
                    onClick={handleGenerateReport}>
                    {'generate'}
                  </ButtonGreyStyles> */}
                  <ButtonGreyStyles
                    padding='5px 8px'
                    margin='0 10px 0 0'
                    disabled={reportData.length === 0}
                    onClick={handleDownload}>
                    {strings.export}
                  </ButtonGreyStyles>
                </DivStyles>
              </DivStyles>
              <DivStyles display='flex' justifyContent='space-between'>
                <ReportFilter
                  margin='5px 10px'
                  borderRadius='0'
                  noDropdown
                  multiple
                  multipleSize='3'
                  label='Charger Locations'
                  name='Location'
                  updateReportFilters={updateReportFilters}
                  filterBy={locSessionStrings}
                  filterProps={locationProperties}
                  filteredValues={filters.location}
                  handleFilterChange={handleFilterChange}
                />
                <ReportFilter
                  margin='5px 10px'
                  borderRadius='0'
                  noDropdown
                  multiple
                  multipleSize='3'
                  label='Metergroups'
                  name='Metergroup'
                  updateReportFilters={updateReportFilters}
                  filterBy={mgSessionStrings}
                  filterProps={metergroupProperties}
                  filteredValues={filters.metergroup}
                  handleFilterChange={handleFilterChange}
                />
                <ReportFilter
                  margin='5px 10px'
                  borderRadius='0'
                  noDropdown
                  multiple
                  multipleSize='3'
                  label='Chargers'
                  name='Chargers'
                  updateReportFilters={updateReportFilters}
                  filterBy={chargerSessionStrings}
                  filterProps={chargerProperties}
                  filteredValues={filters.charger}
                  handleFilterChange={handleFilterChange}
                />
              </DivStyles>
            </DivStyles>
            {/* list of filtered results */}
            <NYSERDAChargerStatusReportList
              filters={filters}
              strings={strings}
              account={account}
              data={reportData}
              loading={loading}
            />
          </ComponentWrapper>
        </RoleCanEdit>
      </SubscriptionAccess>
    </OuterWrapper>
  );
};

export default NYSERDAChargerStatusReport;
