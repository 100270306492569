import React, { useState, useEffect, useContext } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';

//layout
import {
  OuterWrapper,
  ComponentWrapper,
  RoleCanEdit,
  SubscriptionAccess,
} from '../../OperatorShared';

//services
import AppSystemUsersService from '../AppSystemUsersService';
import AppPlatformService from '../../../../services/AppPlatform/AppPlatformService';

//component
import EnterpriseLocations from './EnterpriseForm/EnterpriseLocations';
import EnterpriseRoles from './EnterpriseForm/EnterpriseRoles';
import UserInfoForm from './EnterpriseForm/UserInfoForm';
import UserHeaderDisplay from './UserHeaderDisplay';
import Button from '../../../shared/Buttons/Button';

//non enterprise form
import Location from './NonEnterpriseForm/Location';

//global component
import { LoadingSpinner } from '../../../shared';
import CsoNotFound from '../../../shared/AppNotFound/CsoNotFound';

//modals
import ImageModal from './ImageModal';
import CsoModal from '../../CsoModal/CsoModal';
import TokenModal from './TokenModal';
import CloseAccountModal from '../../AccountProfile/AccountModals/CloseAccountModal';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import { ErrorContext } from '../../../context/shared/ErrorContext';

//styles
import { FormStyles, DivStyles } from '../../../SharedStyles';

const SystemUserEdit = (props) => {
  const { strings } = AppSystemUsersService;
  const { account, setAccount, currentUser, setCurrentUser } = useContext(
    CsoAccountContext
  );
  const { setError } = useContext(ErrorContext);
  const [pendingUser, setPendingUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [systemUser, setSystemUser] = useState({});
  const [location, setLocation] = useState({});
  const [showEditImage, setShowEditImage] = useState(false);
  const [deleteSystemUser, setDeleteSystemUser] = useState(false);
  const [notFound, setNotFound] = useState({
    display: false,
    message: '',
  });
  const [token, setToken] = useState({});
  const [tokenModal, setTokenModal] = useState(false);

  const toggleModal = () => {
    setTokenModal(!tokenModal);
  };

  const toggleImageModal = () => {
    setShowEditImage(!showEditImage);
  };

  const toggleDelete = () => {
    setDeleteSystemUser(!deleteSystemUser);
  };

  const updateImage = (image) => {
    setSystemUser({ ...systemUser, imagePath: image });
  };

  // enterprise - Will check the users collection and pull in the user for their profile by the ID in profile. If no props it will display their profile.
  // other subscriptions - will display the current users profile
  // Ampedup Admins - Can see each user for all enterprise accounts | they will now also be able to see the account user by clicking on the user profile instead of the admin account.
  useEffect(() => {
    setLoading(true);
    const fetchingUsers = async () => {
      try {
        let users = await AppSystemUsersService.getSystemUsers();

        let singleUser;
        if (!currentUser.isAdminUser) {
          if (props.match.params.id) {
            singleUser = users.filter(
              (user) => user.id === props.match.params.id
            );
          }

          if (singleUser.length === 0) {
            setNotFound({
              display: true,
              message: currentUser.isAdminUser
                ? 'Ampedup Admins do not need access to the user profile'
                : 'No User with this Id could be found.',
            });
            return;
          }
          if (singleUser[0].pending) {
            setPendingUser(true);
          }
          setSystemUser(singleUser[0]);
        } else {
          if (account.subscriptionLevel !== 3 && users.length > 0) {
            setSystemUser(users[0]);
          } else {
            if (props.match.params.id !== null) {
              singleUser = users.filter(
                (user) => user.id === props.match.params.id
              );
              if (singleUser.length === 0) {
                setNotFound({
                  display: true,
                  message: currentUser.isAdminUser
                    ? 'Ampedup Admins do not need access to the user profile'
                    : 'No User with this Id could be found.',
                });
                return;
              }
              if (singleUser[0].pending) {
                setPendingUser(true);
              }
              setSystemUser(singleUser[0]);
            } else {
              setNotFound({
                display: true,
                message: currentUser.isAdminUser
                  ? 'Ampedup Admins do not need access to the user profile'
                  : 'No User with this Id could be found.',
              });
              setSystemUser(null);
            }
          }
        }
      } catch (err) {
        if (err.response) {
          setNotFound({ display: true, message: err.response.data });
        }
        AppPlatformService.debug('ERROR: ' + err);
      }
      setLoading(false);
    };
    fetchingUsers();
    return () => {
      setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id]);

  useEffect(() => {
    let accountLocation = account.locations[0];
    setLocation(accountLocation);
    if (account.subscriptionLevel !== 3) {
      setSystemUser({
        ...systemUser,
        locationIds: [accountLocation.id],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSystemUserChange = (e) => {
    setSystemUser({ ...systemUser, [e.target.name]: e.target.value });
  };

  //first part updates the locations from the form name attr.
  //the second part updates the cso account address for the name by adding headquarters first and cap the e.target.name
  const handleLocationChange = (e) => {
    setLocation({ ...location, [e.target.name]: e.target.value });
  };

  const updateAccountLocation = (updatedLocation) => {
    let updatedAccount = account;
    let changedAccountLoc = updatedAccount.locations.map((loc) => {
      if (loc.id === updatedLocation.id) {
        loc = updatedLocation;
      }
      return loc;
    });
    account.locations = changedAccountLoc;
    account.headquartersAddress1 = updatedLocation.address1;
    account.headquartersAddress2 = updatedLocation.address2;
    account.headquartersCity = updatedLocation.city;
    account.headquartersCountry = updatedLocation.country;
    account.headquartersState = updatedLocation.state;
    account.headquartersZip = updatedLocation.zip;

    return updatedAccount;
  };

  /* =====================Enterprise Only===================================== */
  const updateRole = (role) => {
    let convertRole = parseInt(role);
    setSystemUser({ ...systemUser, role: convertRole });
  };
  const updateLocations = (locations) => {
    setSystemUser({ ...systemUser, locationIds: locations });
  };
  /* ========================================================================= */

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let updatedAccountLocation = updateAccountLocation(location);
      if (!pendingUser) {
        let updatedUser = await AppSystemUsersService.updateSystemUser(
          systemUser
        );
        setSystemUser(updatedUser);

        if (updatedUser) {
          let updatedAccount = await AppSystemUsersService.updateCSOAccount(
            updatedAccountLocation
          );
          setAccount((a) => ({ ...a, ...updatedAccount }));

          if (systemUser.id === currentUser.id) {
            console.log('UPDATED USER', updatedUser);
            setCurrentUser((u) => ({ ...u, imagePath: updatedUser.imagePath }));
          }
        }

        setLoading(false);
        props.history.goBack();
      } else {
        let resentToken = await AppSystemUsersService.resendSystemUserToken(
          systemUser.id
        );
        const resetTimeStamp = resentToken.ttl + resentToken._ts;

        let refreshedUser = systemUser;
        refreshedUser.token = resentToken.token;
        refreshedUser.expiresIn = resetTimeStamp;
        setSystemUser(refreshedUser);
        setLoading(false);
        setToken(resentToken);
        setTokenModal(true);
      }
    } catch (err) {
      setLoading(false);
      setError({
        display: true,
        title: 'Something went wrong',
        message: err.response.data.split(/[|]+/).pop(),
        styles: 'cso',
      });
    }
  };

  const toggleAllLocations = (name, value) => {
    setSystemUser({ ...systemUser, [name]: value });
  };

  if (
    notFound.display ||
    (currentUser.isAdminUser &&
      (props.match.params.id === currentUser.id || !props.match.params.id))
  ) {
    return <CsoNotFound message={notFound.message} />;
  }

  return (
    <OuterWrapper
      title={
        (account && account.subscriptionLevel === 3) ||
        (account && account.subscriptionLevel === 2)
          ? strings.editTitle
          : strings.accountTitle
      }
      internal
      myForm
      save={systemUser.pending ? strings.tokenButtonInvite : strings.save}
      path={
        account && [0, 1, 2].includes(account.subscriptionLevel)
          ? '/'
          : '/system-users'
      }
      roles={[1, 2, 3, 4]}>
      {loading && <LoadingSpinner />}
      <ComponentWrapper
        title={
          (account && account.subscriptionLevel === 3) ||
          (account && account.subscriptionLevel === 2)
            ? strings.editTitle
            : strings.accountTitle
        }>
          {tokenModal && <TokenModal token={token} toggleModal={toggleModal} />}
        {deleteSystemUser && (
          <CsoModal
            deleteModal
            systemUserModal
            pending={systemUser.pending}
            systemUserId={systemUser.id}
            userId={systemUser.userId}
            toggleModal={toggleDelete}
            title={strings.deleteTitle}
            content={account.subscriptionLevel < 3 ? strings.deleteContentNoneEnterprise 
              : `${strings.deleteContentOne} ${systemUser.firstName} ${systemUser.lastName} ${strings.deleteContentTwo}`}
            button={strings.delete}
          />
        )}
        {showEditImage && (
          <ImageModal
            toggleModal={toggleImageModal}
            user={systemUser}
            updateImage={updateImage}
          />
        )}
        {/* ====================FORM================================ */}
        <FormStyles display='block' id='myForm' onSubmit={handleSubmit}>
          {currentUser && (
            <>
              {Object.keys(systemUser).length > 0 && (
                <DivStyles
                  display='flex'
                  justifyContent='space-between'
                  borderBottom>
                  <UserHeaderDisplay
                    systemUser={systemUser}
                    toggleImageModal={toggleImageModal}
                  />
                  {(account.subscriptionLevel !== 3 || (currentUser.id !== systemUser.id && account.subscriptionLevel === 3)) && (
                    <DivStyles margin='10px 10px 0 0'>
                      <RoleCanEdit roles={[1, 2]}>
                        <FaRegTrashAlt onClick={toggleDelete} />
                      </RoleCanEdit>
                    </DivStyles>
                  )}
                </DivStyles>
              )}
            </>
          )}
          <DivStyles>
            {/* ===== User Information ======= */}
            <UserInfoForm
              handleChange={handleSystemUserChange}
              systemUser={systemUser}
              pendingUser={pendingUser}
            />
            <SubscriptionAccess levels={[0, 1, 2]}>
              <Location
                location={location}
                pendingUser={pendingUser}
                handleLocationChange={handleLocationChange}
              />
            </SubscriptionAccess>

            <SubscriptionAccess levels={[3]}>
              {Object.keys(systemUser).length !== 0 && (
                <>
                  <EnterpriseLocations
                    canEdit={[1, 2]}
                    user={systemUser}
                    pendingUser={pendingUser}
                    currentUser={currentUser}
                    updateLocations={updateLocations}
                    account={account}
                    handleChange={toggleAllLocations}
                  />
                  <EnterpriseRoles
                    canEdit={[1, 2]}
                    user={systemUser}
                    pendingUser={pendingUser}
                    currentUser={currentUser}
                    updateRole={updateRole}
                    account={account}
                  />
                </>
              )}
            </SubscriptionAccess>
          </DivStyles>
        </FormStyles>
      </ComponentWrapper>
      <Button
        cso
        hasMobile
        formSubmit
        disabled={loading}
        buttonText={
          systemUser.pending ? strings.tokenButtonInvite : strings.save
        }
        roles={[1, 2, 3, 4]}
      />
      <SubscriptionAccess levels={[1]}>
        <RoleCanEdit roles={[1]}>
          <CloseAccountModal 
            strings={strings} 
            account={account} 
          />
        </RoleCanEdit>
      </SubscriptionAccess>
    </OuterWrapper>
  );
};

export default SystemUserEdit;
