export class ErrorResponse extends Error {
  /**
   *
   * @param {*} name The name for the error in the catch for checking
   * @param {*} message The message to display in the component
   * @param {*} title The title to display in the component
   */
  constructor(name, message, title) {
    super(message);
    this.name = name;
    this.title = title;
  }
}
