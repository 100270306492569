import React, { useState, useEffect, useRef, useContext } from 'react';
import useSupercluster from 'use-supercluster';
import axios from 'axios';
import GoogleMapReact from 'google-map-react';
import {
  FaSearchengin,
  FaRegListAlt,
  FaFilter,
  FaRegWindowClose,
} from 'react-icons/fa';

//services
import AppFindChargerService from '../AppFindChargerService';

//components
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';
import SearchedChargers from './SearchedChargers/SearchedChargers';
import FilterMap from './FilterMap';

//hooks
import { useContainerHeight } from '../../../shared/hooks/useContainerHeight';
import { useRouter } from '../../../shared/hooks/useRouter';

//styles
import '../FindCharger.scss';

//context
import { ChargerContext } from '../../../context/driver/getChargingStation';
import { DriverChargerContext } from '../../../context/driver/getDriverLinkedChargers';
import { ErrorContext } from '../../../context/shared/ErrorContext';
import { DriverContext } from '../../../context/driver/getDriverContext';

//helpers
// import { googleZoomToMeters } from '../../helpers';

//key
import { MAP_API } from '../../../shared';
import {
  ButtonPlusStyles,
  DivStyles,
  FormSmallStyles,
  InputRoundStyles,
  ImageThumbnailStyles,
} from '../../../SharedStyles';

//error
import { ErrorResponse } from '../../../shared/ErrorResponse';

// InfoWindow component
const InfoWindow = (props) => {
  const router = useRouter();
  const { strings } = AppFindChargerService;
  const { charger } = props;

  const infoWindowStyle = {
    position: 'relative',
    bottom: 190,
    left: '0px',
    width: 220,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 10,
    fontSize: 14,
    zIndex: 100,
  };

  const handleRedirect = () => {
    router.history.push(`/charging/${charger.iotHubDeviceId}`);
  };

  return (
    <div style={infoWindowStyle}>
      <div style={{ fontSize: 16 }}>
        {charger.chargerName}
        <FaRegWindowClose
          style={{
            float: 'right',
            cursor: 'pointer',
          }}
          onClick={() => props.closeInfoWindow(charger.iotHubDeviceId)}
        />
      </div>
      <div style={{ fontSize: 14 }}>
        <div style={{ color: 'grey' }}>{charger.chargerAddress} </div>
        <div style={{ color: 'orange' }}>Distance: {charger.distance}</div>{' '}
      </div>
      <div style={{ fontSize: 14, color: 'lightgrey' }}>
        Arrival ETA: {charger.duration}
      </div>
      <div style={{ fontSize: 14, color: 'grey' }}>{charger.connector}</div>
      <div style={{ fontSize: 14, color: 'green' }}>
        {charger.chargerConnectedStatus}
      </div>
      <div className='app-button' onClick={handleRedirect}>
        {strings.button}
      </div>
    </div>
  );
};

const ClusterMarker = ({ children, ...props }) => {
  return (
    <>
      {children}
      {props.cluster.properties.cluster &&
        props.chargers.map((c) =>
          c.clusterShow ? (
            <InfoWindow
              clusterMarker
              key={c.iotHubDeviceId}
              {...props}
              charger={c}
            />
          ) : null
        )}
    </>
  );
};

// Marker component
const Marker = (props) => {
  if (props.user) {
    return (
      <>
        <ImageThumbnailStyles
          transform='translate(-50%, -50%)'
          src={require('../../../../assets/images/blue-dot.png').default}
          alt='Your Location'
        />
      </>
    );
  }
  return (
    <>
      <ImageThumbnailStyles
        transform='translate(-50%, -50%)'
        cursor='pointer'
        src={require('../../../../assets/images/spotlight-poi2.png').default}
        alt={props.charger.iotHubDeviceId}
      />
      {!props.charger.cluster && props.charger.show && (
        <InfoWindow pinMarker {...props} charger={props.charger} />
      )}
    </>
  );
};

const ClusterChargerMap = (props) => {
  const { strings } = AppFindChargerService;
  const { linkedChargers } = useContext(DriverChargerContext);
  const { driver } = useContext(DriverContext);
  const { searched, setSearched } = useContext(ChargerContext);
  const { setError } = useContext(ErrorContext);
  const mapRef = useRef();
  const searchRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [chargers, setChargers] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locationSearch, setLocationSearch] = useState(null);
  const [toggleChargersList, setToggleChargersList] = useState(false);
  const [toggleFilterList, setToggleFilterList] = useState(false);
  const [filters, setFilters] = useState(
    JSON.parse(localStorage.getItem('searchFilterOptions')) || {
      chargerConnectedStatus: false,
      j1772: false,
      quickChargeCHAdeMO: false,
      quickChargeCCSSAE: false,
    }
  );

  const CardRef = useRef();

  const { containerHeight, containerRef, setContainerRef } = useContainerHeight(
    70,
    CardRef
  );

  //gets card container height for responsive
  useEffect(() => {
    setContainerRef(CardRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CardRef]);

  const points = chargers.map((charger) => ({
    type: 'Feature',
    properties: {
      cluster: false,
      ...charger,
    },
    geometry: {
      type: 'Point',
      coordinates: [
        charger.location.coordinates[1],
        charger.location.coordinates[0],
      ],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const handleSearchChange = (e) => {
    setLocationSearch(e.target.value);
    setSearched(e.target.value);
  };

  const handleChargersToggle = () => {
    setToggleFilterList(false);
    setToggleChargersList(!toggleChargersList);
  };

  const handleFiltersToggle = () => {
    setToggleChargersList(false);
    setToggleFilterList(!toggleFilterList);
  };

  useEffect(() => {
    if (mapRef.current && coordinates.length > 0) {
      mapRef.current.panTo({
        lat: coordinates[0],
        lng: coordinates[1],
      });
    }
  }, [coordinates]);

  // onChildClick callback can take two arguments: key and childProps
  const onChildClickCallback = (key, ...childProps) => {
    const { isCluster } = childProps[0];

    if (isCluster) {
      const { id } = childProps[0].cluster;
      const [longitude, latitude] = childProps[0].cluster.geometry.coordinates;

      const expansionZoom = Math.min(
        supercluster.getClusterExpansionZoom(id),
        20
      );

      if (expansionZoom > 19) {
        let items = supercluster.getLeaves(id);
        let allChargers = chargers.map((c) => {
          items.forEach((i) => {
            if (c.iotHubDeviceId === i.properties.iotHubDeviceId) {
              c.show = false;
              c.clusterShow = true;
            }
          });
          return c;
        });

        setChargers(allChargers);
        mapRef.current.setZoom(expansionZoom);
        mapRef.current.panTo({
          lat: latitude,
          lng: longitude,
        });
      } else {
        mapRef.current.setZoom(expansionZoom);
        mapRef.current.panTo({
          lat: latitude,
          lng: longitude,
        });
      }
    }

    if (!isCluster) {
      const { lat, lng } = childProps[0];
      let allChargers = chargers.map((c) => {
        if (c.iotHubDeviceId === key) {
          c.show = true;
        } else {
          c.show = false;
        }
        return c;
      });
      setChargers(allChargers);
      mapRef.current.panTo({
        lat,
        lng,
      });
    }
  };

  //closes info window
  const closeInfoWindow = (chargerId) => {
    let allChargers = chargers.map((c) => {
      if (c.iotHubDeviceId === chargerId) {
        c.show = false;
        c.clusterShow = false;
      }
      return c;
    });
    setChargers(allChargers);
  };

  // Handle Search Form Submit
  const handleSearchSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    try {
      setZoom(10);

      setLoading(true);

      //location
      if (!driver.driverLocation) {
        return;
      }

      setToggleChargersList(false);
      setToggleFilterList(false);

      let previousSearch = () => {
        return searched !== ''
          ? searched
          : locationSearch
          ? locationSearch
          : `${driver.driverLocation.coordinates[0]},${driver.driverLocation.coordinates[1]}`;
      };

      let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${previousSearch()}&key=${MAP_API}`;

      let googleResults = await axios.get(url);

      if (googleResults.data && googleResults.data.status === 'ZERO_RESULTS') {
        throw new ErrorResponse(
          'frontend',
          'We could not find any results with the provided address',
          'Invalid Address'
        );
      }

      setCoordinates([
        googleResults.data.results[0].geometry.location.lat,
        googleResults.data.results[0].geometry.location.lng,
      ]);

      let newMapRequest = {
        distance: 100000,
        location: {
          type: 'Point',
          coordinates: [
            googleResults.data.results[0].geometry.location.lat,
            googleResults.data.results[0].geometry.location.lng,
          ],
        },
        userLocation: driver.driverLocation,
        bypassDirection: false,
      };

      let returnData = await AppFindChargerService.searchChargers(
        newMapRequest
      );

      let newCoords = [];
      let newChargers = [];

      returnData.map((charger) => {
        linkedChargers.forEach((item) => {
          if (item.iotHubDeviceId === charger.iotHubDeviceId) {
            charger.home = item.home;
          }
        });
        return charger;
      });

      let filteredOwnedChargers = returnData.filter((charger) => !charger.home);

      filteredOwnedChargers.map((data) => {
        data.show = false;
        let newPoint = {
          lat: data.location.coordinates[0],
          lng: data.location.coordinates[1],
        };
        newCoords.push(newPoint);
        newChargers.push(data);
        return data;
      });

      //filter checks
      let filteredChargers = newChargers;
      if (filters.chargerConnectedStatus) {
        filteredChargers = newChargers.filter((charger) => {
          return charger.chargerConnectedStatus === 'Available';
        });
      }
      // if (filters.public) {
      //   filteredChargers = newChargers.filter((charger) => {
      //     return charger.public === true;
      //   });
      // }
      // if (filters.member) {
      //   filteredChargers = newChargers.filter((charger) => {
      //     return charger.member === true;
      //   });
      // }

      if (
        filters.j1772 ||
        filters.quickChargeCHAdeMO ||
        filters.quickChargeCCSSAE
      ) {
        let myChargers = [];
        newChargers.forEach((charger) => {
          charger.connectors.forEach((connector) => {
            if (filters.j1772) {
              if (connector.connectorName.toLowerCase().includes('j1772')) {
                myChargers.push(charger);
              }
            }
            if (filters.quickChargeCHAdeMO) {
              if (connector.connectorName.toLowerCase().includes('chademo')) {
                myChargers.push(charger);
              }
            }
            if (filters.quickChargeCCSSAE) {
              if (connector.connectorName.toLowerCase().includes('ccssae')) {
                myChargers.push(charger);
              }
            }
          });
        });

        filteredChargers = filteredChargers.filter((elem) =>
          myChargers.find(
            ({ iotHubDeviceId }) => elem.iotHubDeviceId === iotHubDeviceId
          )
        );
      }

      //Finished Building
      setChargers(filteredChargers);
      setLoading(false);
    } catch (err) {
      setLoading(false);

      if (err.name === 'frontend') {
        setError({
          display: true,
          title: err.title,
          message: err.message,
          styles: 'driver',
        });
      } else {
        setError({
          display: true,
          title: 'Something went wrong',
          message:
            'We are currently experiencing issues with the service at this time.',
          styles: 'driver',
        });
      }
    }
  };

  // //drag end search
  const handleSearch = async (e) => {
    let openWindow = chargers.some((c) => {
      return c.show || c.clusterShow;
    });

    if (openWindow) {
      return;
    }

    setLoading(true);
    setLocationSearch('');
    setZoom(10);

    try {
      let newMapRequest = {
        Distance: 100000,
        location: {
          type: 'Point',
          coordinates: [e.center.lat(), e.center.lng()],
        },
        userLocation: driver.driverLocation,
      };

      let returnData = await AppFindChargerService.searchChargers(
        newMapRequest
      );
      let newCoords = [];
      let newChargers = [];

      returnData.map((charger) => {
        linkedChargers.forEach((item) => {
          if (item.iotHubDeviceId === charger.iotHubDeviceId) {
            charger.home = item.home;
          }
        });
        return charger;
      });

      let filteredOwnedChargers = returnData.filter(
        (charger) => !charger.home || !charger.assigned
      );

      filteredOwnedChargers.map((data) => {
        data.show = false;
        let newPoint = {
          lat: data.location.coordinates[0],
          lng: data.location.coordinates[1],
        };
        newCoords.push(newPoint);
        newChargers.push(data);
        return data;
      });

      // filteredChargers will be onlineChargers later
      setChargers(newChargers);
      setCoordinates([e.center.lat(), e.center.lng()]);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError({
        display: true,
        title: 'Something went wrong',
        message: 'Having  issuess with the service at the current moment',
        styles: 'driver',
      });
    }
  };

  // const handleZoomUpdate = e => {
  //   setMapData({ ...mapData, zoom: e });
  // };

  // useEffect(() => {
  //   if (mapData.coordinates.length > 0) {
  //     const zoomSearch = async () => {
  //       setLoading(true);
  //       setLocationSearch('');

  //       let newMapRequest = {
  //         Distance: googleZoomToMeters[mapData.zoom] * 1000,
  //         location: {
  //           type: 'Point',
  //           coordinates: mapData.coordinates
  //         }
  //       };

  //       let returnData = await AppFindChargerService.searchChargers(
  //         newMapRequest
  //       );
  //       let newCoords = [];
  //       let newChargers = [];

  //       returnData.map(data => {
  //         let newPoint = {
  //           lat: data.location.coordinates[0],
  //           lng: data.location.coordinates[1]
  //         };
  //         newCoords.push(newPoint);
  //         newChargers.push(data);
  //         return data;
  //       });

  //       // filteredChargers will be onlineChargers later
  //       setMapData(prevData => ({
  //         ...prevData,
  //         markerLocations: newCoords,
  //         searchedChargers: newChargers,
  //         coordinates: mapData.coordinates
  //       }));
  //       setLoading(false);
  //     };
  //     zoomSearch();
  //   }
  //   if (mapData.coordinates.length === 0 && userLocation) {
  //     const zoomSearch = async () => {
  //       setLoading(true);
  //       setLocationSearch('');

  //       let newMapRequest = {
  //         Distance: googleZoomToMeters[mapData.zoom] * 1000,
  //         location: {
  //           type: 'Point',
  //           coordinates: [userLocation.lat, userLocation.lng]
  //         }
  //       };

  //       console.log(newMapRequest);

  //       let returnData = await AppFindChargerService.searchChargers(
  //         newMapRequest
  //       );
  //       let newCoords = [];
  //       let newChargers = [];

  //       returnData.map(data => {
  //         let newPoint = {
  //           lat: data.location.coordinates[0],
  //           lng: data.location.coordinates[1]
  //         };
  //         newCoords.push(newPoint);
  //         newChargers.push(data);
  //         return data;
  //       });

  //       // filteredChargers will be onlineChargers later
  //       setMapData(prevData => ({
  //         ...prevData,
  //         markerLocations: newCoords,
  //         searchedChargers: newChargers,
  //         coordinates: []
  //       }));
  //       setLoading(false);
  //     };
  //     zoomSearch();
  //   }
  // }, [mapData.zoom]);

  const handleFilterUpdate = (name, key) => {
    setFilters({ ...filters, [name]: key });
  };

  useEffect(() => {
    // public: false,
    // member: false,
    localStorage.setItem('searchFilterOptions', JSON.stringify(filters));
    if (searchRef.current) {
      if (searched) {
        searchRef.current.click();
      }
      searchRef.current.click();
      setZoom(10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <DivStyles
      height='65vh'
      overflow='hidden'
      margin='10px'
      borderTop='1px solid #e7e7e7'
      borderLeft='1px solid #e7e7e7'
      borderRight='1px solid #e7e7e7'
      borderBottom='1px solid #e7e7e7'>
      {loading && <LoadingSpinner />}
      {toggleFilterList && (
        <FilterMap
          top={containerHeight}
          searched={searched}
          filters={filters}
          handleFilterUpdate={handleFilterUpdate}
        />
      )}
      {toggleChargersList && (
        <DivStyles
          dayBackground='#fff'
          borderTop='1px solid #e7e7e7'
          borderBottom='1px solid #e7e7e7'
          position='absolute'
          align='center'
          margin='0 auto'
          width='100%'
          height='calc(100% - 65px)'
          zIndex='1'
          top={`${containerHeight}px`}
          left='0'
          right='0'
          overflow='scroll'>
          <SearchedChargers chargers={chargers} />
        </DivStyles>
      )}
      <DivStyles
        ref={containerRef}
        display='flex'
        flexWrap='wrap'
        size='14px'
        align='left'
        padding='15px'>
        <FormSmallStyles
          mobileMedia='420px'
          mobileWidth='100%'
          id='searchForm'
          onSubmit={handleSearchSubmit}
          margin='0'>
          <InputRoundStyles
            width='300px'
            mobileWidth='100%'
            type='text'
            autoFocus
            maxLength='256'
            disabled={!driver.driverLocation}
            value={locationSearch ? locationSearch : searched}
            placeholder={strings.enterLocation}
            onChange={handleSearchChange}
          />
        </FormSmallStyles>
        <DivStyles
          display='flex'
          svgSize='28px'
          alignItems='center'
          svgMargin='0 10px'>
          <ButtonPlusStyles
            ref={searchRef}
            padding='0'
            type='submit'
            form='searchForm'>
            <FaSearchengin />
          </ButtonPlusStyles>
          <FaRegListAlt
            onClick={handleChargersToggle}
            style={
              toggleChargersList && {
                color: 'rgb(44, 69, 108)',
              }
            }
          />
          <FaFilter
            onClick={handleFiltersToggle}
            style={
              toggleFilterList && {
                color: 'rgb(44, 69, 108)',
              }
            }
          />
        </DivStyles>
      </DivStyles>
      <DivStyles height='90%' width='100%'>
        <GoogleMapReact
          bootstrapURLKeys={{ key: MAP_API }}
          defaultCenter={driver.driverLocation.coordinates}
          defaultZoom={10}
          zoom={zoom}
          fullscreenControl={false}
          boundsChanged={bounds}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }) => {
            mapRef.current = map;
          }}
          onChange={({ zoom, bounds }) => {
            setZoom(zoom);
            setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat,
            ]);
          }}
          draggable={true}
          onDragEnd={handleSearch}
          onChildClick={onChildClickCallback}>
          {driver.driverLocation && clusters.length === 0 && (
            <Marker
              lat={driver.driverLocation.coordinates[0]}
              lng={driver.driverLocation.coordinates[1]}
              user
            />
          )}
          {clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount,
            } = cluster.properties;

            if (isCluster) {
              return (
                <ClusterMarker
                  cluster={cluster}
                  chargers={chargers}
                  closeInfoWindow={closeInfoWindow}
                  isCluster
                  key={`cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}>
                  <DivStyles
                    transform='translate(-50%, -50%)'
                    zIndex='2'
                    color='#fff'
                    dayBackground='#2c456c'
                    borderRadius='50%'
                    padding='10px'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    width={`${10 + (pointCount / points.length) * 20}px`}
                    height={`${10 + (pointCount / points.length) * 20}px`}>
                    {pointCount}
                  </DivStyles>
                </ClusterMarker>
              );
            }
            return (
              <Marker
                {...props}
                key={cluster.properties.iotHubDeviceId}
                lat={latitude}
                lng={longitude}
                charger={cluster.properties}
                closeInfoWindow={closeInfoWindow}
              />
            );
          })}
        </GoogleMapReact>
      </DivStyles>
    </DivStyles>
  );
};

export default ClusterChargerMap;
