import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppModalService from './AppModalService';

//global components
import ModalButton from '../../../../../shared/Buttons/ModalButton';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
} from '../../../../../SharedStyles';

const ApprovalModal = (props) => {
  const { strings } = AppModalService;

  const closeModal = () => {
    props.handleModalClose();
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.photoTitle}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <p>{strings.photoApproval}</p>
          </ModalItemStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          disabled={props.chargers.length === 0}
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={closeModal}
          buttonText={strings.ok}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default ApprovalModal;
