import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppCSOSettingsService = {
  strings: localStrings,
  getSupportedLanguages: () => {
    return AppPlatformService.getSupportedLanguages();
  }
};

export default AppCSOSettingsService;
