import React from 'react';
import { FaChargingStation, FaSearchLocation } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

//styles
import { CenterNavStyles } from '../../../SharedStyles';

const CenterNav = (props) => {
  const { strings } = props;
  return (
    <CenterNavStyles>
      <NavLink activeClassName='is-active' to='/charging'>
        <FaChargingStation className='nav-icons' />
        {strings.charging}
      </NavLink>
      <NavLink exact activeClassName='is-active' to='/'>
        <FaSearchLocation className='nav-icons' />
        {strings.myChargers}
      </NavLink>
    </CenterNavStyles>
  );
};

export default CenterNav;
