import React, { useEffect, useContext, useState } from 'react';
import Button from '../../../../shared/Buttons/Button';

//service
import AccessCardsService from '../../AccessCardsService';

//styles
import {
  DivStyles,
  FormContainerStyles,
  InputRoundStyles,
  InputStyles,
  SelectStyles,
  TextStyles,
} from '../../../../SharedStyles';

//form json
import { formCardTotal, formSetup } from './form';

//context
import { DriverContext } from '../../../../context/driver/getDriverContext';

//hooks
import { useRouter } from '../../../../shared/hooks/useRouter';
import { LoadingSpinner } from '../../../../shared';
import { ErrorContext } from '../../../../context/shared/ErrorContext';

const OrderForm = ({ strings, globalVar }) => {
  const { driver } = useContext(DriverContext);
  const { setError } = useContext(ErrorContext);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const router = useRouter();

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      shippingFirstName: driver.firstName,
      shippingLastName: driver.lastName,
      shippingAddress1: driver.address1,
      shippingAddress2: driver.address2,
      shippingCity: driver.city,
      shippingState: driver.state,
      shippingZip: driver.zip,
      shippingCountry: driver.country,
    }));
  }, [driver]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (data.count > 0) {
        await AccessCardsService.orderAccessCards(data);
        router.history.push('/access-cards');
      } else {
        throw new Error();
      }
    } catch (err) {
      setLoading(false);
      setError({
        display: true,
        title: 'Error',
        message: err.response.data.split(/[|]+/).pop(),
        styles: 'driver',
      });
    }
  };

  return (
    <form id='myForm' onSubmit={handleSubmit}>
      {loading && <LoadingSpinner />}
      <FormContainerStyles padding='10px 10px 15px'>
        {formSetup.map((f, i) => (
          <DivStyles key={i} padding='0 10px' width={f.flex}>
            {f.element === 'input' && (
              <InputStyles
                key={i}
                type={f.type}
                name={f.name}
                placeholder={f.placeholder}
                value={data[f.name] ? data[f.name] : ''}
                onChange={handleChange}
                required={f.required}
                disabled={f.disabled}
              />
            )}
            {f.element === 'select' && (
              <SelectStyles
                key={i}
                flexWidth={f.flex}
                name={f.name}
                onChange={handleChange}
                value={data[f.name] ? data[f.name] : ''}
                required={f.required}
                disabled={f.disabled}>
                {f.options.map((o) => (
                  <option key={o.value} value={o.value} disabled={o.disabled}>
                    {o.key}
                  </option>
                ))}
              </SelectStyles>
            )}
          </DivStyles>
        ))}
      </FormContainerStyles>
      <DivStyles align='center'>
        <InputRoundStyles
          margin='0 0 20px'
          size='13px'
          width='25%'
          type={formCardTotal.type}
          name={formCardTotal.name}
          placeholder={formCardTotal.placeholder}
          value={data[formCardTotal.name] ? data[formCardTotal.name] : ''}
          onChange={handleChange}
          required={formCardTotal.required}
          disabled={formCardTotal.disabled}
        />
        <TextStyles margin='0 0 10px' size='13px'>
          {strings.fee} ${globalVar.accessCardsFee}
        </TextStyles>
      </DivStyles>
      <Button driver hasMobile formSubmit buttonText={strings.order} />
    </form>
  );
};

export default OrderForm;
