import React, { useState, useContext, useEffect } from 'react';

//layout
import {
  OuterWrapper,
  ComponentWrapper,
  SubscriptionAccess,
} from '../../OperatorShared';
import Button from '../../../shared/Buttons/Button';

//services
import AppChargersService from '../AppChargersService';

//components
import ChargersScanner from './ChargersSetup/ChargersScanner';

//modals
import VerifyModal from '../ChargersModals/VerifyModal';
import MissingData from '../../OperatorShared/Modals/MissingData';

//images
// import qrImage from '../../../../assets/images/qr-code.jpg';
import ampedUpLogo from '../../../../assets/images/logo.png';
import ampedUpInsideLogo from '../../../../assets/images/insideLogo.png';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';

//styles
import {
  TextStyles,
  DivStyles,
  FormStyles,
  InputStyles,
  SelectStyles,
  ImageMediumStyles,
} from '../../../SharedStyles';

const ChargersAdd = (props) => {
  const { account } = useContext(CsoAccountContext);
  const { strings, chargerToAccount } = AppChargersService;
  const [formData, setFormData] = useState({
    verifyOnly: true,
  });
  const [verifyModal, setVerifyModal] = useState(false);
  const [validateModal, setValidateModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    display: false,
    message: '',
  });
  const [disabled, setDisabled] = useState(false);
  const [charger, setCharger] = useState(null);
  
  useEffect(() => {
    if (formData.locationId && formData.meterGroupId && isCompleteConnectorCircuitIds()) {
      setDisabled(false);
    }
  }, [formData]);

  useEffect(() => {
    if (account && account.subscriptionLevel === 3) {
      setDisabled(true);
    }
  }, [account]);

  const handleCloseModal = () => {
    setVerifyModal(false);
    setValidateModal(false);
    setErrorMessage({ display: false, message: '' });
  };

  const handleChange = (e) => {
    if (e.target.name === 'locationId') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        meterGroupId: null,
        circuitId: null,
        connectorCircuitIds: [],
      });
      setDisabled(true);
    }
    if (e.target.name === 'meterGroupId') {
      const cnnCircs = charger.evses[0].connectors.map(x => {return {connectorId: x.id, csoCircuitId: null};});
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        circuitId: null,
        connectorCircuitIds: cnnCircs,
      });
      setDisabled(true);
    }
  };  

  const handleChangeCircuitId = (cnnId, e) => {
    if (e.target.name === 'circuitId') {
      formData.circuitId = e.target.value;

      const cnnCircs = [];
      let cnnCirc = {connectorId: cnnId, csoCircuitId: e.target.value};
      cnnCircs.push(cnnCirc);

      formData.connectorCircuitIds.forEach((x) => {
        if (x.connectorId > -1 && x.connectorId !== cnnId) {
          cnnCircs.push(x);
        }
      });

      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        connectorCircuitIds: cnnCircs,
      });
    }
  }; 
  
  const handleChangeChargerId = (e) => {
    if (e.target.name === 'chargerId') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleScan = (data) => {
    setFormData({
      ...formData,
      chargerId: data?.text,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (account.subscriptionLevel === 3) {
      if (
        formData.chargerId &&
        formData.locationId &&
        formData.meterGroupId &&
        isCompleteConnectorCircuitIds()
      ) {
        setVerifyModal(true);
      } else {
        setValidateModal(true);
      }
    } else {
      if (formData.chargerId) {
        setVerifyModal(true);
      } else {
        setValidateModal(true);
      }
    }
  };

  const isCompleteConnectorCircuitIds = () => {
    if (formData.connectorCircuitIds.length === 0){
      return false;
    }
    formData.connectorCircuitIds.forEach(x => {
      if (!x.csoCircuitId || x.csoCircuitId === '') {
        return false;
      }
    });

    return true;
  };

  const verifyChargerId = async (e) => {
    e.preventDefault();
    const chargerId = formData.chargerId;
    if (chargerId && chargerId !== '') {
      const chargerData = { 
        chargerId: chargerId.toUpperCase(),
        verifyOnly: true,
      };
      try {
        const response = await AppChargersService.chargerToAccount(chargerData);
        setCharger(response);
      }
      catch (err){
        if (err.response) {
          setErrorMessage({ display: true, message: err.response.data });
          //alert(err.response.data); //todo: use modal
          console.log(err.response.data);
        }
        console.log(err);
      }
    }
    else {
      setFormData({
        ...formData,
        locationId: null,
        meterGroupId: null,
        circuitId: null,
        connectorCircuitIds: [],
      });
      setDisabled(true);
    }
  }
  
  const selectCircuitId = (cnnId) => {
    const value = cnnId !== -1 
                  ? formData.connectorCircuitIds.find(z => cnnId === z.connectorId)?.csoCircuitId 
                  : formData.circuitId;
    return (
      <SelectStyles
        name='circuitId'
        onChange={e => handleChangeCircuitId(cnnId, e)}
        value={value || ''}
        key={cnnId}>
        <option value='' disabled>
          {`${strings.addPlaceholderCircuit} ${cnnId === -1 ? '' : cnnId}`}
        </option>
        {account.locations
          .filter((loc) => {
            return loc.id === formData.locationId;
          })
          .map((loc) => {
            return loc.meterGroups
              .filter((group) => {
                return group.meterGroupId === formData.meterGroupId;
              })
              .map((meterGroup) => {
                return meterGroup.circuits.map((circuit) => {
                  return (
                    <option key={circuit.id} value={circuit.id}>
                      {circuit.name} ({circuit.maxAmperage}a /{' '}
                      {circuit.chargingStationConnectorIds.reduce((a, b) => a + b?.connectorIds?.length, 0)}{' '}
                      {`port${
                        circuit.chargingStationConnectorIds.reduce((a, b) => a + b?.connectorIds?.length, 0) > 1
                          ? 's'
                          : ''
                      }`}
                      )
                    </option>
                  );
                });
              });
          })}
      </SelectStyles>
    );
  };

  return (
    <OuterWrapper
      title={strings.addChargerTitle}
      internal
      add
      path='/chargers'
      myForm
      roles={[1, 3]}>
      <ComponentWrapper title={strings.addChargerTitle}>
        {verifyModal && (
          <VerifyModal data={formData} handleCloseModal={handleCloseModal} />
        )}
        {validateModal && (
          <MissingData
            message={strings.missingData}
            handleClose={handleCloseModal}
          />
        )}
        {errorMessage.display && (
          <MissingData
            message={errorMessage.message}
            handleClose={handleCloseModal}
          />
        )}
        <DivStyles padding='25px' align='center'>
          <TextStyles size='14px'>{strings.addQRSentence}</TextStyles>
          <ChargersScanner handleScan={handleScan} strings={strings} />
          <TextStyles size='14px'>{strings.addEnterId}</TextStyles>
          <FormStyles
            width='70%'
            margin='30px auto'
            display='block'
            id='myForm'
            onSubmit={handleSubmit}>
            <InputStyles
              type='text'
              name='chargerId'
              value={formData.chargerId || ''}
              onChange={handleChangeChargerId}
              placeholder={strings.addPlaceholderId}
            />
            <Button
              cso
              hasMobile
              disabled={!formData.chargerId}
              onclick={verifyChargerId}
              buttonText={strings.verify}
              roles={[1, 3]}
            />
            <SubscriptionAccess levels={[3]}>
              {charger && (
                <SelectStyles
                  name='locationId'
                  onChange={handleChange}
                  value={formData.locationId || ''}>
                  <option value='' disabled>
                    {strings.addPlaceholderLocation}
                  </option>
                  {account.locations.map((loc) => {
                    return (
                      <option key={loc.id} value={loc.id}>
                        {loc.name} {!loc.enabled && '- Disabled'}
                      </option>
                    );
                  })}
                </SelectStyles>
              )}
              {formData.locationId && (
                <SelectStyles
                  name='meterGroupId'
                  onChange={handleChange}
                  value={formData.meterGroupId || ''}>
                  <option value='' disabled>
                    {strings.addPlaceholderMeterGroup}
                  </option>
                  {account.locations
                    .filter((loc) => {
                      return loc.id === formData.locationId;
                    })
                    .map((loc) => {
                      return loc.meterGroups.map((group) => {
                        return (
                          <option
                            // className={!group.enabled && 'disabled-option'}
                            key={group.meterGroupId}
                            value={group.meterGroupId}>
                            {group.name} {!group.enabled && '- Disabled'}
                          </option>
                        );
                      });
                    })}
                </SelectStyles>
              )}
              {formData.meterGroupId && (
                  charger.evses[0].connectors.length === 0 
                  ? selectCircuitId(-1)
                  : charger.evses[0].connectors.map(x => {
                    return selectCircuitId(x.id);
                  })
              )}
            </SubscriptionAccess>
          </FormStyles>
          <TextStyles
            size='14px'
            margin='20px auto'
            padding='0 10%'
            align='left'>
            <strong>{strings.note}</strong>: {strings.addNote}
          </TextStyles>
          <DivStyles display='block' margin='0 auto'>
            <ImageMediumStyles
              display='block'
              margin='0 auto 10px'
              width='200px'
              src={ampedUpLogo}
              alt='AmpedUp! Logo'
            />
            <ImageMediumStyles
              display='block'
              margin='0 auto'
              width='200px'
              src={ampedUpInsideLogo}
              alt='AmpedUp! Inside Logo'
            />
          </DivStyles>
        </DivStyles>
      </ComponentWrapper>
      <Button
        cso
        hasMobile
        disabled={disabled}
        formSubmit
        buttonText={strings.add}
        roles={[1, 3]}
      />
    </OuterWrapper>
  );
};

export default ChargersAdd;
