import React, { useContext } from 'react';

//services
import AppPaymentsService from '../AppPaymentsService';
import AppPlatformService from '../../../..//services/AppPlatform/AppPlatformService';

//modals
// import PaymentModal from '../PaymentModal';

//global components
// import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';

//context
import { DriverContext } from '../../../context/driver/getDriverContext';

//styles
import { PaymentBalanceStyles, PaymentHeader } from '../../../DriverStyles';
import {
  // ButtonStyles,
  // SelectRoundStyles,
  ListPlaceholderStyles,
  CenterStyles,
  ButtonStyles,
} from '../../../SharedStyles';

const PaymentsBalance = (props) => {
  const { driver } = useContext(DriverContext);

  // const [balanceAdded, setBalanceAdded] = useState(null);
  // const [confirmModal, setConfirmModal] = useState(false);
  // const [successModal, setSuccessModal] = useState(false);
  // const [loading, setLoading] = useState(false);

  const { strings } = AppPaymentsService;

  // const handleChange = (e) => {
  //   setBalanceAdded(e.target.value);
  // };

  // const closeModal = () => {
  //   setConfirmModal(false);
  //   setSuccessModal(false);
  // };

  // const openConfirmModal = (e) => {
  //   e.preventDefault();
  //   setConfirmModal(true);
  // };
  // const handleSuccessModal = () => {
  //   setBalanceAdded(null);
  // };

  // const handleSubmit = async (e) => {
  //   setLoading(true);
  //   const data = {
  //     description: 'AmpedUp! account funded',
  //     amount: balanceAdded,
  //   };
  //   let resData = await AppPaymentsService.makePayment(data);
  //   if (resData) {
  //     fetchDriver();
  //     setSuccessModal(true);
  //     setLoading(false);
  //   }
  // };

  return (
    <PaymentBalanceStyles>
      {/* {confirmModal && (
        <PaymentModal
          closeModal={closeModal}
          handlesuccess={handleSubmit}
          title={strings.paymentConfirmTitle}
          content={`${strings.paymentConfirm}${balanceAdded}${strings.paymentConfirmTwo}`}
        />
      )}
      {successModal && (
        <PaymentModal
          success
          closeModal={closeModal}
          handlesuccess={handleSuccessModal}
          title={strings.paymentSuccessTitle}
          content={`${strings.paymentSuccess}${balanceAdded}${strings.paymentSuccessTwo}`}
        />
      )} */}
      <PaymentHeader>
        {/* {loading && <LoadingSpinner />} */}
        <h2>{strings.balance}</h2>
        <h3>
          {Object.keys(driver).length !== 0
            ? AppPlatformService.currencyFormat(parseFloat(driver.balance))
            : '$0'}
        </h3>
      </PaymentHeader>
      {!driver.customerPaymentId && (
        <ListPlaceholderStyles pColor='#ff8f45' borderBottom='none'>
          <p>
            <em>{strings.missingBalance}</em>
          </p>

          <ButtonStyles onClick={() => props.setTabIndex(2)}>
            {strings.optionsTitle}
          </ButtonStyles>
        </ListPlaceholderStyles>
      )}
      {/* {driver.customerPaymentId ? (
        <>
          <SelectRoundStyles
            width='50%'
            required
            name='balanceID'
            onChange={handleChange}
            defaultValue={strings.defaultOption}>
            <option disabled>{strings.defaultOption}</option>
            <option value='20'>$20.00</option>
            <option value='25'>$25.00</option>
            <option value='50'>$50.00</option>
            <option value='100'>$100.00</option>
          </SelectRoundStyles>
          <ButtonStyles
            onClick={openConfirmModal}
            disabled={!driver.customerPaymentId || !balanceAdded}>
            {strings.add}
          </ButtonStyles>
        </>
      ) : (
        <ListPlaceholderStyles pColor='#ff8f45' borderBottom='none'>
          <p>
            <em>{strings.missingBalance}</em>
          </p>
        </ListPlaceholderStyles>
      )} */}
      <CenterStyles margin='45px auto 30px'>
        <p>
          <strong>{strings.balanceNoteTitle}</strong> {strings.balanceNoteText}{' '}
          {props.autoPaymentAmount &&
            AppPlatformService.currencyFormat(parseInt(props.autoPaymentAmount))}{' '}
          {strings.balanceNoteTextTwo} $
          {props.lowBalanceTrigger &&
            AppPlatformService.currencyFormat(parseInt(props.lowBalanceTrigger))}
        </p>
      </CenterStyles>
    </PaymentBalanceStyles>
  );
};

export default PaymentsBalance;
