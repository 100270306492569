import React, { useState, useEffect, useContext } from 'react';
import { FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa';
import moment from 'moment';

//services
import AppStatementsService from '../AppStatementsService';

//components
import StatementCard from './StatementCard';

//global components
import Pagination from '../../../shared/Pagination/Pagination';
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';

//hooks
import { useWindowResize } from '../../../shared/hooks/useWindowSize';
import { usePagination } from '../../../shared/hooks/usePagination';
import { useSortAndReverse } from '../../../shared/hooks/useSortAndReverse';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';

//styles
import {
  TableStyles,
  TableHeaderStyles,
  TableItemStyles,
  ListPlaceholderStyles,
} from '../../../SharedStyles';

//helpers
import { tableHeaderStatementConvert } from '../helpers';

const StatementList = (props) => {
  const { strings } = AppStatementsService;
  const { account } = useContext(CsoAccountContext);

  const [statements, setStatements] = useState([]);
  const [filteredStatements, setFilteredStatements] = useState([]);
  const [loading, setLoading] = useState(true);

  const size = useWindowResize(window.innerWidth, window.innerHeight);

  const { filteredValue, searchedValue } = props;

  //fetches data
  const fetchingData = async () => {
    let allStatements = await AppStatementsService.getStatements();
    allStatements.sort((a, b) => {
      return a['statementDate'] > b['statementDate'];
    });
    setStatements(allStatements);
    if (allStatements.length === 0) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredStatements(statements);
  }, [statements]);

  useEffect(() => {
    if (filteredStatements.length) {
      setLoading(false);
    }
  }, [filteredStatements]);

  const updateSortedItems = (items) => {
    setFilteredStatements(items);
  };

  /**
   * Can be two of these per page for enterprise and non-enterprise
   * @param {*} sortStatus - status for the default sort
   * @param {*} dataArray - items that will be sorted
   * @param {*} headerText - strings array that will show user friendly name to map over
   * @param {*} headerConvert - converts strings in headerText array to object notation, custom function needed for this logic
   * @param {*} updateSortedItem - Function to update the current state with the sorted items
   */
  const [isActiveSort, sortReverse, changeActiveSort] = useSortAndReverse(
    'statementDate',
    statements,
    strings.headerData,
    tableHeaderStatementConvert,
    updateSortedItems,
    'desc'
  );

  useEffect(() => {
    const dateFields = ['activityPeriod'];
    const lowercasedSearch = searchedValue.toLowerCase();
    const searchedStatements = statements.filter((ment) => {
      return Object.keys(ment).some((key) => {
        if (dateFields.includes(filteredValue)) {
          return (
            moment(lowercasedSearch).format('MM/DD/YYYY') >=
              moment(ment.startDate).format('MM/DD/YYYY') &&
            moment(lowercasedSearch).format('MM/DD/YYYY') <=
              moment(ment.stopDate).format('MM/DD/YYYY')
          );
        } else if (ment[key] !== null) {
          return ment[filteredValue ? filteredValue : key]
            .toString()
            .toLowerCase()
            .includes(lowercasedSearch);
        }
        return null;
      });
    });
    setFilteredStatements(searchedStatements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedValue, filteredValue]);

  /**
   * pagination for the data
   * @param {*} dataArray - items that will be sorted
   * @param {*} itemsPerPage - Custom items limit per page
   */
  const {
    next,
    prev,
    jump,
    currentData,
    currentPage,
    maxPage,
    totalData,
    currentCount,
    pageNumbers,
  } = usePagination(filteredStatements, 20);

  return (
    <>
      {loading && <LoadingSpinner />}
      {statements.length !== 0 && (
        <Pagination
          currentCount={currentCount}
          totalData={totalData}
          next={next}
          prev={prev}
          jump={jump}
          currentPage={currentPage}
          maxPage={maxPage}
          pageNumbers={pageNumbers}
        />
      )}
      <TableStyles minHeight='500px'>
        <TableHeaderStyles
          borderLeft='none'
          borderRight='none'
          width={size[0] >= 768 ? '100%' : '820px'}>
          {strings.headerData.map((title) => {
            return (
              <TableItemStyles
                width={
                  title === 'Statement Date' ||
                  title === 'Activity Period'
                    ? '160px'
                    : '110px'
                }
                borderRight
                key={title}
                onClick={() => {
                  changeActiveSort(tableHeaderStatementConvert[title]);
                }}>
                {isActiveSort[tableHeaderStatementConvert[title]] && (
                  <div>
                    {sortReverse[tableHeaderStatementConvert[title]] ? (
                      <FaLongArrowAltDown />
                    ) : (
                      <FaLongArrowAltUp />
                    )}{' '}
                  </div>
                )}
                {title}
              </TableItemStyles>
            );
          })}
        </TableHeaderStyles>
        {currentData().map((statement, idx) => {
          return (
            <StatementCard
              account={account}
              key={statement.id}
              statementDate={moment(statement.statementDate).format('MM/DD/YY')}
              activityPeriod={
                statement.totalPayoutAmount === 144.92899971008302
                  ? `${moment(statement.startDate)
                      .add(1, 'month')
                      .format('MM/DD/YY')} - ${moment(statement.stopDate)
                      .add(1, 'month')
                      .format('MM/DD/YY')}`
                  : `${moment.utc(statement.startDate).format(
                      'MM/DD/YY'
                    )} - ${moment.utc(statement.stopDate).format('MM/DD/YY')}`
              }
              earnings={statement.totalEarnings}
              otherFees={statement.totalOtherFees}
              payout={statement.totalPayoutAmount}
              subFees={statement.totalSubscriptionFees}
              id={statement.id}
              strings={strings}
            />
          );
        })}
        {/* </Sort> */}
        {statements.length === 0 && !loading && (
          <ListPlaceholderStyles borderBottom='none'>
            <p>{strings.noStatements}</p>
          </ListPlaceholderStyles>
        )}
      </TableStyles>
    </>
  );
};

export default StatementList;
