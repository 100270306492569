import React from 'react';

//global components
import CsoToggleSwitch from '../OperatorShared/ToggleSwitch/CsoToggleSwitch';

//styles
import {
  ToggleSwitchInputFields,
  InputContainerStyles,
  InputStyles,
} from '../../SharedStyles';

const NotifyBySection = ({
  strings,
  user,
  handleChange,
  handleTextChange,
  textRequired,
  emailRequired,
}) => {
  return (
    <ToggleSwitchInputFields textAlign='left'>
      <h2 className='Content-Title'>{strings.headerNotifyBy}</h2>
      <CsoToggleSwitch
        labelMargin='0 15px'
        padding='10px 0'
        checked={!!user.notifyByEmail}
        name='notifyByEmail'
        handleChange={(e) => handleChange(e.target.name, e.target.checked)}
        label={strings.optionEMail}
      />
      <InputContainerStyles padding='0 20px 0 10px'>
        <InputStyles
          type='email'
          onChange={handleTextChange}
          value={user.notificationEmail || ''}
          name='notificationEmail'
          placeholder={strings.placeholderEmail}
          required={user.notifyByEmail || emailRequired}
        />
      </InputContainerStyles>
      <CsoToggleSwitch
        labelMargin='0 15px'
        padding='10px 0'
        checked={!!user.notifyByText}
        name='notifyByText'
        handleChange={(e) => handleChange(e.target.name, e.target.checked)}
        label={strings.optionTextMessage}
      />
      <InputContainerStyles padding='0 20px 0 10px'>
        <InputStyles
          type='tel'
          value={user.notificationText || ''}
          onChange={handleTextChange}
          placeholder={strings.placeholderCellNumber}
          name='notificationText'
          required={user.notifyByText || textRequired}
        />
      </InputContainerStyles>
    </ToggleSwitchInputFields>
  );
};

export default NotifyBySection;
