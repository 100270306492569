import AppApiService from '../../../services/AppApi/AppApiService';
import strings from './strings';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import AppImagingService from '../../../services/AppImaging/AppImagingService';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppAccountSettingsService = {
  prepareImage: function(image) {
    return AppImagingService.getBlobForDataUrl(image);
  },
  uploadImage: function(imageBlob) {
    return AppApiService.post('uploadImage', null, imageBlob);
  },
  closeDriverAccount: function(driver) {
    return AppApiService.post('closeDriverAccount', null, driver);
  },
  strings: localStrings
};

export default AppAccountSettingsService;
