import React from 'react';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { DivStyles } from '../../../SharedStyles';

const SlideItem = (props) => {
  return (
    <div className='slider-item'>
      <img
        className='slide'
        style={
          props.image && props.image.placeholder
            ? {
                objectFit: 'contain',
                padding: '10px',
              }
            : null
        }
        src={props.image && props.image.imagePath}
        alt='slider'
      />
      <DivStyles
        position='absolute'
        bottom='0'
        right='0'
        display='flex'
        alignItems='center'
        svgOpacity='.5'
        svgColor='#fff'
        svgSize='26px'
        zIndex='200'
        svgMargin='5px 5px 10px'>
        {!props.charger.home &&
          (props.charger.favorite ? (
            <FaHeart onClick={props.toggleFavorites} />
          ) : (
            <FaRegHeart onClick={props.toggleFavorites} />
          ))}
      </DivStyles>
    </div>
  );
};

export default SlideItem;
