import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppFindChargerService from '../../AppFindChargerService';

const ConfirmRemove = (props) => {
  const { strings } = AppFindChargerService;

  const handleRemove = () => {
    props.removefav(props.charger);
    props.cancelModal();
  };

  const closeModal = () => {
    props.cancelModal();
  };

  return (
    <div className='Modal-Display-Driver'>
      <div className='Modal'>
        <div className='Modal-Header'>
          {strings.confirmTitle}
          <div className='Modal-Close'>
            <FaRegWindowClose onClick={closeModal} />
          </div>
        </div>
        <div className='Modal-Content'>
          <p className='Modal-Content-Text'>{strings.confirmContent}</p>
        </div>
        <div className='Modal-Button'>
          <button className='app-button' onClick={handleRemove}>
            {strings.confirm}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmRemove;
