import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

//services
import AppDashboardService from '../../AppDashboardService';

//global components
import { LoadingSpinner } from '../../../../shared';

//styles
import { DivStyles, TitleStyles, SelectStyles } from '../../../../SharedStyles';

//helpers
import { dateRange } from '../../../Reports/Details/Shared/RevenueReport/helpers';
import RevenueService from '../../../Reports/Details/Shared/RevenueReport/RevenueService';
import RevenueChart from '../../../Reports/Details/Shared/RevenueReport/RevenueChart';

const ChargerRevenue = () => {
  const { strings } = AppDashboardService;
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('thismonth');
  const [reportData, setReportData] = useState({});

  const fetchingData = async () => {
    try {
      setLoading(true);
      const { startDate, endDate, groupBy } = dateRange(filter);
      let query = {
        startDate: startDate,
        endDate: endDate,
        groupBy: groupBy,
        charger: ['allchargers'],
        location: ['alllocations'],
        chargerType: ['allchargertypes'],
        metergroup: ['allmetergroups'],
      };

      const res = await RevenueService.generateRevenueSummaryReport(query);
      setReportData(res);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchingData();
    // eslint-disable-next-line
  }, [filter]);

  const handleChange = (e) => {
    setFilter(e.target.value);
  };

  return (
    <DivStyles
      width='100%'
      margin='0'
      flex='1'
      borderRight='1px solid #e7e7e7'
      borderLeft='1px solid #e7e7e7'
      borderTop='1px solid #e7e7e7'
      borderBottom='1px solid #e7e7e7'>
      {loading && <LoadingSpinner />}
      <TitleStyles
        size='14px'
        align='left'
        padding='5px'
        margin='0'
        borderBottom
        weight='400'>
        {strings.chargerRevenueTitle}
      </TitleStyles>
      <DivStyles padding='0 10px 10px'>
        <SelectStyles
          width='150px'
          padding='3px 8px'
          margin='5px 20px 0 0'
          bgPosition='.75em'
          size='14px'
          name='time'
          onChange={handleChange}
          value={filter.time}>
          <option value='thismonth'>This month</option>
          <option value='today'>Today</option>
          <option value='yesterday'>Yesterday</option>
          <option value='lastmonth'>Last month</option>
          <option value='thisweek'>This week</option>
          <option value='lastweek'>Last week</option>
          <option value='last3months'>3 months</option>
          <option value='last6months'>6 months</option>
          <option value='thisyear'>This year</option>
          <option value='lastyear'>Last year</option>
        </SelectStyles>
      </DivStyles>
      {Object.keys(reportData).length > 0 && (
        <DivStyles width='100%' overflowX='auto'>
          <RevenueChart
            minWidth='1000px'
            filterDate={filter}
            reportData={reportData.revenueData}
          />
        </DivStyles>
      )}
      <DivStyles align='right' linkColor='grey' margin='0 10px' size='14px'>
        <Link to='/reports/revenue-summary'>more...</Link>
      </DivStyles>
    </DivStyles>
  );
};

export default ChargerRevenue;
