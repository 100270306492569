import styled from 'styled-components';

export const TitleStyles = styled.h2`
  padding: ${({ padding }) => padding};
  font-weight: ${({ weight }) => weight};
  font-size: ${({ size }) => size};
  flex: ${({ flex }) => flex};
  line-height: ${({ lineHeight }) => lineHeight};
  margin: ${({ margin }) => margin};
  text-align: ${({ align }) => align};
  background: ${({ background }) => background};
  color: ${({ color, theme }) => (color ? color : theme.color)};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? '1px solid #e7e7e7' : 'none'};
  display: ${({ display }) => display};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    font-size: ${({ mobileSize }) => mobileSize};
  }

  .Subtitle {
    font-weight: 400;
  }
  .Close {
    cursor: pointer;
    font-size: 22px;
  }
  .Edit-Icon {
    font-weight: 400;
    color: #8999ae;
    font-size: ${({ iconSize }) => (iconSize ? iconSize : '17px')};
    margin: ${({ iconMargin }) => (iconMargin ? iconMargin : '0 0 0 10px')};
    cursor: pointer;
    line-height: 1;
  }
`;

export const TextStyles = styled.p`
  text-transform: ${({ textTransform }) => textTransform};
  flex: ${({ flex }) => flex};
  font-weight: ${({ weight }) => weight};
  font-size: ${({ size }) => size};
  font-style: ${({ fontStyle }) => fontStyle};
  line-height: ${({ lineHeight }) => lineHeight};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => (margin ? margin : '0 0 5px')};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? '1px solid #e7e7e7' : 'none'};
  text-align: ${({ align }) => align};
  color: ${({ color, theme }) => (color ? color : theme.color)};
  background: ${({ background }) => background};
  text-decoration: ${({ textDecoration }) =>
    textDecoration ? 'underline' : null};
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    font-size: ${({ mobileSize }) => mobileSize};
    word-break: ${({ wordBreak }) => wordBreak};
  }

  .Warning {
    color: red;
  }

  .bold {
    font-weight: 700;
  }

  span.Searching {
    opacity: 0;
    animation-name: fadein;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }

  span.Searching:nth-child(1) {
    animation-delay: 0s;
  }
  span.Searching:nth-child(2) {
    animation-delay: 0.2s;
  }
  span.Searching:nth-child(3) {
    animation-delay: 0.4s;
  }
  span.Searching:nth-child(4) {
    animation-delay: 0.6s;
  }
  span.Searching:nth-child(5) {
    animation-delay: 0.8s;
  }
  span.Searching:nth-child(6) {
    animation-delay: 1s;
  }
  span.Searching:nth-child(7) {
    animation-delay: 1.2s;
  }
  span.Searching:nth-child(8) {
    animation-delay: 1.4s;
  }
  span.Searching:nth-child(9) {
    animation-delay: 1.6s;
  }
  span.Searching:nth-child(10) {
    animation-delay: 1.8s;
  }
  span.Searching:nth-child(11) {
    animation-delay: 2s;
  }
  span.Searching:nth-child(12) {
    animation-delay: 2.2s;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const LIStyles = styled.li`
  text-transform: ${({ textTransform }) => textTransform};
  flex: ${({ flex }) => flex};
  font-weight: ${({ weight }) => weight};
  font-size: ${({ size }) => size};
  line-height: ${({ lineHeight }) => lineHeight};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => (margin ? margin : '10px 0')};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? '1px solid #e7e7e7' : 'none'};
  text-align: ${({ align }) => align};
  color: ${({ color, theme }) => (color ? color : theme.color)};
  background: ${({ background }) => background};
  span {
    margin: 10px 0 10px 10px;
    display: block;
  }
  .bold {
    font-weight: 700;
  }
`;
