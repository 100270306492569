//forms
import FormInput from './Forms/FormInput';

//loading
import LoadingSpinner from './Loading/LoadingSpinner';
import AppPlatformService from '../../services/AppPlatform/AppPlatformService';

//google api key
const MAP_API = 'AIzaSyBYjaAgPMzLq5OcS5kI4tmiypLkZlaJXBA';

//app store urls
export const DRIVER_APPLE_APP_STORE =
  'https://apps.apple.com/us/app/ampedup-ev-driver/id1528849964';
export const DRIVER_GOOGLE_PLAY_STORE =
  'https://play.google.com/store/apps/details?id=net.ampedupnetworks.driver';
export const CSO_APPLE_APP_STORE =
  'https://apps.apple.com/us/app/ampedup-cso/id1528892741';
export const CSO_GOOGLE_PLAY_STORE =
  'https://play.google.com/store/apps/details?id=net.ampedup.cso';

let API_URL;
let RESET_PASSWORD_URL;

if (AppPlatformService.getVersion() === 'dev') {
  API_URL = 'https://ampedupdriverdev.azurewebsites.net';
  RESET_PASSWORD_URL =
    'https://AmpedUpB2C.b2clogin.com/AmpedUpB2C.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_WebApp_ResetPassword&client_id=8f4eed75-51c7-472d-b8bf-c4162806c97e&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fampedupdriverdev.azurewebsites.net&scope=openid&response_type=id_token&prompt=login&reason=accountProfileReset';
} else {
  API_URL = 'https://apps.ampedup.net';
  RESET_PASSWORD_URL =
    'https://ampedupb2c.b2clogin.com/AmpedUpB2C.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_WebApp_ResetPassword&client_id=8f4eed75-51c7-472d-b8bf-c4162806c97e&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapps.ampedup.net%2F&scope=openid%20https%3A%2F%2FAmpedUpB2C.onmicrosoft.com%2Fapp%2Fuser_impersonation&response_type=id_token%20token&prompt=login';
}

let STRIPE_API;
let STRIPE_SUCCESS_URL = `${API_URL}/bank`;
let STRIPE_FAIL_URL = `${API_URL}/bank/failed`;

let CHARGER_IMAGE =
  // 'https://assets.website-files.com/5cd0acd3ee113701d8036b37/5cf18cf99af399c0a8982be2_ClipperCreekLogo50x50.png';
  require('../../assets/images/ChargingStationPlaceholder100x100.png').default;
// require('../../assets/images/vendorImages/BTCPowerLogo135x135.png');.default

if (AppPlatformService.getVersion() === 'dev') {
  STRIPE_API = 'pk_test_XqJNgtb3aZ262BZZJcZZAgaD00zAGvggx9';
} else {
  STRIPE_API = 'pk_live_zUU8V3xyGCL4nEQTYLrvXLUG005Jhwv89X';
}

// 60kb
const IMAGE_UPLOAD_SIZE_LIMIT = 60000;
// 200kb
const IMAGE_UPLOAD_CHARGER_SIZE_LIMIT = 204800;
// ranges from 0 to 1
const IMAGE_COMPRESSION = 0.5;
// ranges from 0 to 1
const IMAGE_SIZE_RATIO = 0.5;

const STRIPE_OPTIONS = (fontSize = '14px', padding = '0') => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Raleway, sans-serif',
        '::placeholder': {
          color: '#aab7c4',
        },
        ...(padding ? { padding } : {}),
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

const CSO_THEME = {
  color: '#2c456c',
  defaultBgColor: '#8999ae',
  defaultBgInverseColor: '#fff',
  defaultModalBgColor: '#2c456c',
  defaultHoverDarkColor: '#617793',
  defaultInteralNavColor: '#28456a',
  defaultMediaQuery: '575px',
  defaultModalButtonBg: '#2c456c',
  toggleOffColor: '#fff',
  toggleOffSliderColor: '#ccc',
  toggleColor: '#617793',
  toggleSliderColor: '#c3d6f0',
  toggleShadow: '#28456a',
  toggleDisabledSliderColor: '#e7e7e7',
  toggleDisabledColor: '#b3b2b2',
  svgColor: '#8999ae',
  svgHoverColor: '#2c456c',
};

const DRIVER_THEME = {
  color: '#2c456c',
  defaultBgColor: '#ff8f45',
  defaultBgInverseColor: '#fff',
  defaultModalBgColor: '#ff8f45',
  defaultHoverDarkColor: '#ff7317',
  defaultInteralNavColor: '#f60',
  defaultMediaQuery: '575px',
  defaultModalButtonBg: '#ff8f45',
  toggleOffColor: '#fff',
  toggleOffSliderColor: '#ccc',
  toggleColor: '#617793',
  toggleSliderColor: '#ff8f45',
  toggleShadow: '#28456a',
  toggleDisabledSliderColor: '#e7e7e7',
  toggleDisabledColor: '#b3b2b2',
  svgColor: '#2c456c',
  svgHoverColor: '#2c456c',
};

export {
  FormInput,
  LoadingSpinner,
  MAP_API,
  API_URL,
  STRIPE_API,
  STRIPE_SUCCESS_URL,
  STRIPE_FAIL_URL,
  IMAGE_UPLOAD_SIZE_LIMIT,
  IMAGE_UPLOAD_CHARGER_SIZE_LIMIT,
  IMAGE_COMPRESSION,
  IMAGE_SIZE_RATIO,
  CSO_THEME,
  DRIVER_THEME,
  STRIPE_OPTIONS,
  CHARGER_IMAGE,
  RESET_PASSWORD_URL,
};
