import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import AppApiService from '../../../services/AppApi/AppApiService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppCSOMeterGroupsService = {
  getAllMeterGroups: function() {
    return AppApiService.get('getAllMeterGroups');
  },
  getAllMeterGroupsByLocation: function(locationId) {
    return AppApiService.post('getAllMeterGroupsByLocation', null, locationId);
  },
  getSingleMeterGroup: function(id) {
    return AppApiService.post('getSingleMeterGroup', null, id);
  },
  createMeterGroup: function(meterGroup) {
    return AppApiService.post('createMeterGroup', null, meterGroup);
  },
  updateMeterGroup: function(meterGroup) {
    return AppApiService.post('updateMeterGroup', null, meterGroup);
  },
  deleteMeterGroup: function(id) {
    return AppApiService.post('deleteMeterGroup', null, id);
  },
  createCircuit: function(circuitType) {
    return AppApiService.post('createCircuit', null, circuitType);
  },
  deleteCircuit: function(id) {
    return AppApiService.post('deleteCircuit', null, id);
  },
  strings: localStrings
};

export default AppCSOMeterGroupsService;
