import React, { useEffect, useState } from 'react';
import {
  FaRegWindowClose,
  FaArrowCircleLeft,
  FaArrowCircleRight,
} from 'react-icons/fa';

//services
import AppBankService from '../AppBankService';

//styles
import {
  InputStyles,
  RowStyles,
  JumboTronHeaderStyles,
  FormContainerStyles,
  TitleStyles,
} from '../../../SharedStyles';

const BankForm = (props) => {
  const { strings } = AppBankService;

  const {
    bankForm,
    handleBankForm,
    prevStep,
    nextStep,
    connectAccount,
  } = props;
  const [disable, setDisable] = useState(true);

  const handleChange = (e) => {
    handleBankForm('payoutForm', e.target.name, e.target.value);
  };

  useEffect(() => {
    let payoutCompleted = Object.keys(bankForm.payoutForm).every((key) => {
      return bankForm.payoutForm[key] !== '';
    });
    if (payoutCompleted || connectAccount.payoutVerified) {
      setDisable(false);
    }
  }, [bankForm, connectAccount.payoutVerified]);

  return (
    <>
      <JumboTronHeaderStyles
        align='left'
        contentAlign='left'
        contentMargin='10px 0 15px'
        contentPadding='0'
        contentSize='16px'>
        <TitleStyles
          size='28px'
          borderBottom
          padding='0 0 5px'
          display='flex'
          alignItems='center'
          justifyContent='space-between'>
          {strings.wizard2Title}
          {connectAccount.payoutVerified && (
            <span class='Close'>
              <FaRegWindowClose onClick={props.handleCloseWizard} />
            </span>
          )}
        </TitleStyles>
        <div className='Content'>
          <p>{strings.wizard2Content1}</p>
          <p>
            {strings.wizard2Content3a} <em>{strings.next}</em>{' '}
            {strings.wizard2Content3b}
          </p>
        </div>
        <FormContainerStyles>
          <InputStyles
            type='text'
            name='account_holder_name'
            onChange={handleChange}
            placeholder={strings.bankFormLabel1}
            value={bankForm.payoutForm.account_holder_name}
          />
          <InputStyles
            type='number'
            name='account_number'
            onChange={handleChange}
            placeholder={strings.bankFormLabel2}
            value={bankForm.payoutForm.account_number}
          />
          <InputStyles
            type='number'
            name='confirm_account_number'
            onChange={handleChange}
            placeholder={strings.bankFormLabel3}
            value={bankForm.payoutForm.confirm_account_number}
          />
          <InputStyles
            type='number'
            name='routing_number'
            onChange={handleChange}
            placeholder={strings.bankFormLabel4}
            value={bankForm.payoutForm.routing_number}
          />
        </FormContainerStyles>
      </JumboTronHeaderStyles>
      <RowStyles borderBottom='none' flex='100%'>
        <div className='Arrows'>
          <button title={strings.previous} onClick={prevStep}>
            <FaArrowCircleLeft />
          </button>
          <button disabled={disable} onClick={nextStep} title={strings.next}>
            <FaArrowCircleRight />
          </button>
        </div>
      </RowStyles>
    </>
  );
};

export default BankForm;
