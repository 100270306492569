import styled from 'styled-components';

import { defaultMediaQuery } from '../defaultValues';

export const ComponentInternalStyles = styled.div`
  text-align: ${({ align }) => (align ? align : 'left')};
  border: ${({ border }) => (border ? 'none' : '1px solid #e7e7e7')};
  margin: ${({ margin }) => margin};
  min-height: ${({ minHeight }) => minHeight};
  @media screen and (max-width: ${({ mobileMedia }) =>
      mobileMedia ? mobileMedia : defaultMediaQuery}) {
    border: none;
    margin: 0;
    min-height: 0;
  }
`;

export const ComponentExternalStyles = styled.div`
  position: relative;
  display: block;
  min-height: 500px;
  padding-bottom: 10px;
  text-align: ${({ align }) => (align ? align : 'center')};
  border: ${({ border }) => (border ? '1px solid #e7e7e7' : 'none')};
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    border: none;
    min-height: 0;
  }
`;

export const ComponentHeaderStyles = styled.div`
  border-bottom: 1px solid #e7e7e7;
  @media screen and (max-width: ${({ mobileMedia }) =>
      mobileMedia ? mobileMedia : defaultMediaQuery}) {
    display: none;
  }
`;

export const ComponentTitleStyles = styled.h2`
  padding: 10px;
  color: #2c456c;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  margin: 0;
  display: ${({ display }) => display};
  align-items: ${({ alignItems }) => alignItems};
`;
