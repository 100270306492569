import React, { useState, useEffect } from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppChargerCardService from '../../../ChargerCard/AppChargerCardService';
import AppChargingService from '../../../../driver/AppCharging/AppChargingService';

//global component
import ModalButton from '../../../../shared/Buttons/ModalButton';
import LoadingSpinner from '../../../../shared/Loading/LoadingSpinner';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  ModalNoteStyles,
} from '../../../../SharedStyles';
import { DivStyles } from '../../../../SharedStyles';

//helpers
import { chargerConnectorObj } from '../../../../shared/Helpers';
import { connectorConstants } from '../../../../driver/helpers';
import DriverMessageModal from './DriverMessageModal';

const WaitModal = (props) => {
  const { strings } = AppChargerCardService;
  const { charger } = props;
  const [isUserInQue, setIsUserInQue] = useState(false);
  const [position, setPosition] = useState(null);
  const [dialogChargerStatus, setDialogChargerStatus] = useState({});
  const [addToWaitQueueBlocked, setAddToWaitQueueBlocked] = useState(false);
  const [addToWaitQueueBlockedReason, setAddToWaitQueueBlockedReason] = useState('');
  const [connectors, setConnectors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [waitQueData, setWaitQueData] = useState({
    iotHubDeviceId: charger.iotHubDeviceId,
  });
  const [error, setError] = useState({ display: false, message: '' });
  const [connectorIsOpen, setConnectorIsOpen] = useState(true);
  const [availablePorts, setAvailablePorts] = useState([]);
  const [confirmLeaveModal, setConfirmLeaveModal] = useState(false);

  let inWaitQueueCookie = JSON.parse(localStorage.getItem('inWaitQueue'));

  // const getDialogChargerStatus = (chargerStatus) => {
  //   let dialogChargerStatus = {...chargerStatus, canAddToWaitQueue: true, reason: ''};

  //   if(chargerStatus.availabilityStatus === connectorConstants.Unavailable 
  //     && chargerStatus.connectorStatuses.length === 0){
  //     dialogChargerStatus.reason = strings.chargerOffline;
  //     dialogChargerStatus.canAddToWaitQueue = false;

  //     return dialogChargerStatus;
  //   }

  //   // if(chargerStatus.connectorStatuses.length > 0){//available and chargerCurrentlyReserved
  //   //   let availablePorts = chargerStatus.connectorStatuses.filter(connector => !connector.waitQueueBlocked);
  //   //   if (availablePorts.length === 0) {
  //   //     dialogChargerStatus.reason = strings.chargerCurrentlyReserved;
  //   //     dialogChargerStatus.canAddToWaitQueue = true;
  //   //   } 
  //   // }

  //   if(chargerStatus.connectorStatuses.length > 0){
  //     let waitQueueBlockedPorts = chargerStatus.connectorStatuses.filter(cnnStatus => cnnStatus.waitQueueBlocked);
  //     if (waitQueueBlockedPorts.length === chargerStatus.connectorStatuses.length) { //all ports blocked
  //       dialogChargerStatus.reason = waitQueueBlockedPorts.map(cnnStatus => cnnStatus.waitQueueBlockedReason).join(', ');
  //       dialogChargerStatus.canAddToWaitQueue = false;

  //       return dialogChargerStatus;
  //     }

  //     let isCurrentUserPorts = chargerStatus.connectorStatuses.filter(cnnStatus => cnnStatus.IsCurrentUser);
  //     if (isCurrentUserPorts.length !== 0) {
  //       dialogChargerStatus.reason = strings.chargerAlreadyUsing;
  //       dialogChargerStatus.canAddToWaitQueue = false;

  //       return dialogChargerStatus;
  //     } 
  //   }

  //   return dialogChargerStatus;
  // }

  const checkUserQueueStatus = async () => {
      setLoading(true);
      let chargerStatus = await AppChargingService.getChargingStationStatus(charger.iotHubDeviceId);
      setDialogChargerStatus(chargerStatus);

      let addToWaitQueueBlocked = false;
      let addToWaitQueueBlockedReason = '';

      if(chargerStatus.availabilityStatus === connectorConstants.Unavailable 
        && chargerStatus.connectorStatuses.length === 0){ //Off-line
        addToWaitQueueBlocked = true;
        addToWaitQueueBlockedReason = strings.chargerOffline;
      }
      else {
        let allBlockedPorts = chargerStatus.connectorStatuses
          .filter((c) => c.addToWaitQueueBlocked)
          .map((c) => c.addToWaitQueueBlockedReason);
        if (allBlockedPorts.length === chargerStatus.connectorStatuses.length) {
          addToWaitQueueBlocked = true;
          addToWaitQueueBlockedReason = allBlockedPorts[0];
        }
      }

      setAddToWaitQueueBlocked(addToWaitQueueBlocked);
      setAddToWaitQueueBlockedReason(addToWaitQueueBlockedReason);

      let position = await AppChargerCardService.waitQueueReady(charger.iotHubDeviceId);
      setPosition(position);

      if (position.positionInQueue > -1) {
        setIsUserInQue(true);
        props.checkingWaitQueue(true);
        setLoading(false);
      } else {
        props.checkingWaitQueue(false);
        setLoading(false);
      }
    };

    useEffect(() => {
      checkUserQueueStatus();
      // eslint-disable-next-line
    }, []);
  
    useEffect(() => {
      //todo: move to checkUserQueStatus 
      //connectors will be an array - each item in the array represents a different connector type - not port
      setConnectors(charger.connectors);
  
      //connector single type - can be a single port or dual but both types are the same example j-1772
      if (charger.connectors.length === 1) {
        if (
          charger.connectors[0].activeConnectorCount ===
          charger.connectors[0].connectorCount
        ) {
          setConnectorIsOpen(false);
        }
        //setting connectorType to the type automatically for single types
        setWaitQueData((prev) => ({
          ...prev,
          connectorType: charger.connectors[0].connectorName,
        }));
      }
  
      //connector multi types - this will be different types of connectors
      if (charger.connectors.length > 1) {
        //checking if every connector is occupied - will be true if all occupied
        let allPortsOpen = charger.connectors.every((c) => {
          return c.activeConnectorCount === c.connectorCount;
        });
        //some or no connectors are occupied
        if (!allPortsOpen) {
          // looping through and filtering open chargers
          let allAvailablePorts = charger.connectors
            .filter((c) => c.activeConnectorCount === c.connectorCount)
            .map((c) => c.connectorName);
          //storing values in array to check radio disable for connector type selection
          if (allAvailablePorts.length > 0) {
            setConnectorIsOpen(false);
          }
          setAvailablePorts(allAvailablePorts);
        } else {
          //all ports are occupied and will need to enter wait que
          setConnectorIsOpen(false);
          let allAvailablePorts = charger.connectors
            .filter((c) => c.activeConnectorCount === c.connectorCount)
            .map((c) => c.connectorName);
          setAvailablePorts(allAvailablePorts);
        }
      }

      if (connectorIsOpen && !props.hasActiveQueue) {
          setAddToWaitQueueBlocked(true);
          setAddToWaitQueueBlockedReason(strings.openPorts);
      }

    }, [charger]);
  
    const closeModal = () => {
      props.handleFavoriteModal('waitQueue');
      setConfirmLeaveModal(false);
    };
  
    const handleConnectorType = (e) => {
      setWaitQueData({
        ...waitQueData,
        [e.target.name]: e.target.value,
      });
    };
  
    const handleEnterQue = async () => {
      if (!waitQueData.connectorType) {
        setError({
          display: true,
          message: 'Connector type is required for multi type chargers.',
        });
        return;
      }
      let res = await AppChargerCardService.waitQueueAdd(waitQueData);
  
      if (res === '') {
        let position = await AppChargerCardService.waitQueueReady(
          charger.iotHubDeviceId
        );
  
        setPosition(position);
  
        if (position.positionInQueue > -1) {
          setIsUserInQue(true);
          props.checkingWaitQueue(true);
          props.handleUserInActiveQueue(true);
          localStorage.setItem(
            'inWaitQueue',
            JSON.stringify({
              inQueue: true,
              iotHubDeviceId: charger.iotHubDeviceId,
              chargerName: charger.chargerName,
            })
          );
        }
      }
    };
  
    const confirmLeaveQue = () => {
      setConfirmLeaveModal(true);
    };
  
    const handleLeaveQue = async () => {
      let res = await AppChargerCardService.waitQueueRemove(
        charger.iotHubDeviceId
      );
      if (!res) {
        props.checkingWaitQueue(false);
        props.handleUserInActiveQueue(false);
        localStorage.removeItem('inWaitQueue');
        closeModal();
      }
    };

    
  if (isUserInQue 
    && !loading 
    && confirmLeaveModal) {
    return (
      <ModalStyles>
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{strings.waitQueue}</h2>
            <FaRegWindowClose className='Close' onClick={closeModal} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            <ModalItemStyles padding='0 10px'>
              Are you sure you'd like to leave this wait queue?
            </ModalItemStyles>
          </ModalContentStyles>
          {/* Button Component for Modals*/}
          <DivStyles
            display='flex'
            alignItems='center'
            justifyContent='flex-end'>
            <ModalButton
              inverse
              containerMargin='10px 10px 0'
              containerAlign='right'
              buttonMargin='0'
              handleClick={closeModal}
              buttonText={strings.cancel}
            />
            <ModalButton
              containerMargin='10px 10px 0'
              containerAlign='right'
              buttonMargin='0'
              handleClick={handleLeaveQue}
              buttonText={strings.yes}
            />
          </DivStyles>
        </ModalContainerStyles>
      </ModalStyles>
    );
  }

  if (false && connectorIsOpen 
    && !loading
    && !props.hasActiveQueue) {
    return (
      <ModalStyles>
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{strings.waitQueue}</h2>
            <FaRegWindowClose className='Close' onClick={closeModal} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            <ModalItemStyles padding='0 10px'>
              <p>{strings.openPorts}</p>
            </ModalItemStyles>
          </ModalContentStyles>
          {/* Button Component for Modals*/}
          <DivStyles
            display='flex'
            alignItems='center'
            justifyContent='flex-end'>
            <ModalButton
              containerMargin='10px 10px 0'
              containerAlign='right'
              buttonMargin='0'
              handleClick={closeModal}
              buttonText={strings.ok}
            />
          </DivStyles>
        </ModalContainerStyles>
      </ModalStyles>
    );
  }

  if (isUserInQue 
    && !loading) {
    return (
      <ModalStyles>
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{strings.waitQueue}</h2>
            <FaRegWindowClose className='Close' onClick={closeModal} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            <ModalItemStyles padding='0 10px'>
              {console.log(
                position.gracePeriodLeft,
                charger.waitQueueGracePeriod
              )}
              {position.positionInQueue === 0 &&
              position.gracePeriodLeft < charger.waitQueueGracePeriod ? (
                <p>
                  {strings.yourNextOne} {charger.chargerName}{' '}
                  {strings.yourNextTwo} {position.gracePeriodLeft}{' '}
                  {strings.yourNextThree}
                </p>
              ) : (
                <p>
                  {strings.currentPosition} {position.positionInQueue + 1}.
                </p>
              )}
            </ModalItemStyles>
          </ModalContentStyles>
          {/* Button Component for Modals*/}
          <DivStyles
            display='flex'
            alignItems='center'
            justifyContent='flex-end'>
            <ModalButton
              inverse
              containerMargin='10px 10px 0'
              containerAlign='right'
              buttonMargin='0'
              handleClick={confirmLeaveQue}
              buttonText={strings.leaveQue}
            />
            <ModalButton
              containerMargin='10px 10px 0'
              containerAlign='right'
              buttonMargin='0'
              handleClick={closeModal}
              buttonText={strings.ok}
            />
          </DivStyles>
        </ModalContainerStyles>
      </ModalStyles>
    );
  }

  if (addToWaitQueueBlocked
    && !loading ) {
    return (
      <ModalStyles>
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{strings.waitQueue}</h2>
            <FaRegWindowClose className='Close' onClick={closeModal} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            <ModalItemStyles padding='0 10px'>
              <p>{addToWaitQueueBlockedReason}</p>
            </ModalItemStyles>
          </ModalContentStyles>
          {/* Button Component for Modals*/}
          <DivStyles
            display='flex'
            alignItems='center'
            justifyContent='flex-end'>
            <ModalButton
              containerMargin='10px 10px 0'
              containerAlign='right'
              buttonMargin='0'
              handleClick={closeModal}
              buttonText={strings.ok}
            />
          </DivStyles>
        </ModalContainerStyles>
      </ModalStyles>
    );
  }

  if (
    props.hasActiveQueue
    && inWaitQueueCookie
    && inWaitQueueCookie.iotHubDeviceId !== charger.iotHubDeviceId
    && !loading
  ) {
    return (
      <ModalStyles>
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{strings.waitQueue}</h2>
            <FaRegWindowClose className='Close' onClick={closeModal} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            <ModalItemStyles padding='0 10px'>
              <p>
                {strings.currentInQueueOne}
                {inWaitQueueCookie.chargerName}
                {strings.currentInQueueTwo}
                {inWaitQueueCookie.chargerName}
                {strings.currentInQueueThree}.
              </p>
            </ModalItemStyles>
          </ModalContentStyles>
          {/* Button Component for Modals*/}
          <DivStyles
            display='flex'
            alignItems='center'
            justifyContent='flex-end'>
            <ModalButton
              containerMargin='10px 10px 0'
              containerAlign='right'
              buttonMargin='0'
              handleClick={closeModal}
              buttonText={strings.ok}
            />
          </DivStyles>
        </ModalContainerStyles>
      </ModalStyles>
    );
  }

  if (!loading) {
    return (
      <ModalStyles>
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{strings.waitQueue}</h2>
            <FaRegWindowClose className='Close' onClick={closeModal} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            {loading ? (
              <ModalItemStyles padding='0 10px'>
                <p>{strings.checkingQueue}</p>
              </ModalItemStyles>
            ) : (
              <>
                {connectors.length === 1 && (
                  <ModalItemStyles padding='0 10px' margin='15px 0'>
                    <p>{strings.enterQueContent}<br/><br/>{strings.enterQueContent2nd}</p>
                    <p className='danger-text'>
                      {error.display && error.message}
                    </p>
                  </ModalItemStyles>
                )}
                {connectors.length > 1 && (
                  <>
                    <ModalItemStyles
                      padding='0 10px'
                      borderBottom
                      margin='0 0 15px'>
                      <p>{strings.multiConnectorContent}</p>
                      <p className='danger-text'>
                        {error.display && error.message}
                      </p>
                    </ModalItemStyles>
                    <ModalItemStyles
                      padding='0 10px'
                      fontWeight='bold'
                      color='#2c456c'>
                      <p>{strings.selectConnector}:</p>
                    </ModalItemStyles>
                    <ModalNoteStyles
                      color='#333333'
                      padding='10px 20px 0'
                      align='left'>
                      {connectors.map((c) => {
                        chargerConnectorObj.forEach((connImage) => {
                          if (
                            connImage.type.toLowerCase() ===
                            c.connectorName.toLowerCase()
                          ) {
                            c = {
                              ...connImage,
                              ...c,
                            };
                          }
                        });
                        return (
                          <DivStyles key={c.connectorName}>
                            <label htmlFor={c.connectorName}>
                              <input
                                id={c.connectorName}
                                disabled={
                                  !availablePorts.includes(c.connectorName)
                                }
                                type='radio'
                                name='connectorType'
                                value={c.connectorName}
                                onChange={handleConnectorType}
                              />
                              <span>{c.connectorName}</span>
                              <img
                                className='connector-image-small'
                                src={c.image}
                                alt={c.type}
                              />
                            </label>
                          </DivStyles>
                        );
                      })}
                    </ModalNoteStyles>
                  </>
                )}
              </>
            )}
          </ModalContentStyles>
          {/* Button Component for Modals*/}
          <DivStyles display='flex' alignItems='center' justifyContent='flex-end'>
            <ModalButton
              containerMargin='10px 10px 0'
              containerAlign='right'
              buttonMargin='0'
              handleClick={handleEnterQue}
              buttonText={strings.enterQue}
            />
          </DivStyles>
        </ModalContainerStyles>
      </ModalStyles>
    );
 }
//  if (loading) {
//   return (<LoadingSpinner />)
//  }
 return (<> </>)
};

export default WaitModal;
