import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import AppApiService from '../../../services/AppApi/AppApiService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppOwnedChargersService = {
  redeemToken: (token) => {
    return AppApiService.post('redeemToken', null, token);
  },
  setChargingStationPrivacy: (data) => {
    return AppApiService.post('setChargingStationPrivacy', null, data);
  },
  strings: localStrings,
};

export default AppOwnedChargersService;
