import React from 'react';
import CsoToggleSwitch from '../../OperatorShared/ToggleSwitch/CsoToggleSwitch';

const NotificationsForm = (props) => {
  const { userNotificationSettings, handleChange, user } = props;
  const handleCheckboxChange = (e) => {
    handleChange(e.target.name, e.target.checked);
  };

  return (
    <>
      {userNotificationSettings.map((setting) => {
        const { name, label } = setting;
        return (
          <CsoToggleSwitch
            labelMargin='0 15px'
            padding='10px 0'
            key={name}
            checked={!!user[name]}
            name={name}
            handleChange={handleCheckboxChange}
            label={label}
          />
        );
      })}
    </>
  );
};

export default NotificationsForm;
