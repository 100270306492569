import React from 'react';

//services
import AppAccountProfileService from '../AppAccountProfileService';

//styles
import {
  FormContainerStyles,
  FormRowStyles,
  InputStyles,
  SelectStyles,
} from '../../../SharedStyles';

//helpers
import states from '../../../shared/Address/states';

const AccountForm = (props) => {
  const { strings } = AppAccountProfileService;
  const { account, handleChange } = props;
  console.log(account);
  return (
    <>
      <FormContainerStyles padding='10px 0 15px' borderBottom>
        <h3 className='Form-Title'>{strings.companyContactTitle}</h3>
        <FormRowStyles>
          <InputStyles
            flexWidth='50%'
            margin='0 10px'
            type='text'
            maxLength='256'
            name='contactFirstName'
            placeholder={strings.placeholderFirstName}
            value={account.contactFirstName || ''}
            onChange={handleChange}
            required
          />
          <InputStyles
            flexWidth='50%'
            margin='0 10px'
            type='text'
            maxLength='256'
            name='contactLastName'
            placeholder={strings.placeholderLastName}
            value={account.contactLastName || ''}
            onChange={handleChange}
            required
          />
        </FormRowStyles>
        <FormRowStyles>
          <InputStyles
            flexWidth='50%'
            margin='0 10px'
            type='text'
            maxLength='256'
            name='contactEmail'
            placeholder={strings.placeholderEmail}
            value={account.contactEmail || ''}
            onChange={handleChange}
            required
          />
          <InputStyles
            flexWidth='50%'
            margin='0 10px'
            type='text'
            maxLength='256'
            name='contactPhone'
            placeholder={strings.placeholderPhone}
            value={account.contactPhone || ''}
            onChange={handleChange}
            id='phone'
            required
          />
        </FormRowStyles>
      </FormContainerStyles>
      <FormContainerStyles padding='10px 0 15px'>
        <h3 className='Form-Title'>{strings.headquartersTitle}</h3>
        <FormRowStyles>
          <InputStyles
            flexWidth='100%'
            margin='0 10px'
            type='text'
            maxLength='256'
            name='headquartersAddress1'
            placeholder={strings.placeholderAddress1}
            value={account.headquartersAddress1 || ''}
            onChange={handleChange}
            required
          />
        </FormRowStyles>
        <FormRowStyles>
          <InputStyles
            flexWidth='100%'
            margin='0 10px'
            type='text'
            maxLength='256'
            name='headquartersAddress2'
            placeholder={strings.placeholderAddres2}
            value={account.headquartersAddress2 || ''}
            onChange={handleChange}
          />
        </FormRowStyles>
        <FormRowStyles>
          <InputStyles
            flexWidth='50%'
            margin='0 10px'
            type='text'
            maxLength='256'
            name='headquartersCity'
            placeholder={strings.placeholderCity}
            value={account.headquartersCity || ''}
            onChange={handleChange}
            required
          />
          <SelectStyles
            flexWidth='50%'
            margin='0 10px'
            name='headquartersState'
            required
            onChange={handleChange}
            defaultValue={
              account.headquartersState || strings.placeholderState
            }>
            <option disabled>{strings.placeholderState}</option>
            {states &&
              states.filter(x=>x.country===account.headquartersCountry).map((h, i) => (
                <option key={i} value={h.abbreviation}>
                  {h.name}
                </option>
              ))}
          </SelectStyles>
        </FormRowStyles>
        <FormRowStyles>
          <InputStyles
            flexWidth='50%'
            margin='0 10px'
            type='text'
            maxLength='256'
            name='headquartersZip'
            placeholder={strings.placeholderZIP}
            value={account.headquartersZip || ''}
            onChange={handleChange}
            required
          />
          <SelectStyles
            flexWidth='50%'
            margin='0 10px'
            name='headquartersCountry'
            onChange={handleChange}
            defaultValue={
              account.headquartersCountry || strings.placeholderCountry
            }>
            <option disabled={true} required={true}>
              {strings.placeholderCountry}
            </option>
            <option>United States</option>
            <option>Canada</option>
          </SelectStyles>
        </FormRowStyles>
      </FormContainerStyles>
    </>
  );
};

export default AccountForm;
