import React, { useState, useContext, useRef, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FaInfoCircle, FaRegTrashAlt, FaEllipsisV } from 'react-icons/fa';

//services
import AppSystemUsersService from '../AppSystemUsersService';

//hooks
import useOnClickOutside from '../../../shared/hooks/useOnClickOutside';
import { useWindowResize } from '../../../shared/hooks/useWindowSize';
import { useContainerHeight } from '../../../shared/hooks/useContainerHeight';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import { ErrorContext } from '../../../context/shared/ErrorContext';

//modals
import CsoModal from '../../CsoModal/CsoModal';

//styles
import { TableRowStyles, TableItemStyles } from '../../../SharedStyles';
import { ToolsStyles } from '../../../OperatorStyles';
import { deletedDiff } from 'deep-object-diff';

const SystemUserCard = (props) => {
  const { strings } = AppSystemUsersService;
  const [deleteSystemUser, setDeleteSystemUser] = useState(false);
  const { currentUser } = useContext(CsoAccountContext);
  const { setError } = useContext(ErrorContext);
  const CardRef = useRef();
  const OutsideRef = useRef();
  const size = useWindowResize(window.innerWidth, window.innerHeight);
  const { containerHeight, containerRef, setContainerRef } = useContainerHeight(
    50,
    CardRef
  );
  const [openTools, setOpenTools] = useState(false);

  useOnClickOutside(OutsideRef, () => setOpenTools(false));

  //gets card container height for responsive
  useEffect(() => {
    setContainerRef(CardRef);
  }, [CardRef, setContainerRef]);

  const toggleDelete = () => {
    if (props.role === 'Administrator' && props.adminsCount <= 1){
      setError({
        display: true,
        message: 'You are not able to delete last Administrator account.',
        title: 'Invalid Request',
        styles: 'cso',
      });
    }
    else if (currentUser.id === props.id) {
      setError({
        display: true,
        message: 'You are not able to delete your own account.',
        title: 'Invalid Request',
        styles: 'cso',
      });
    } else {
      setDeleteSystemUser(!deleteSystemUser);
    }
  };

  const toggleToolBar = () => {
    setOpenTools(!openTools);
  };

  return (
    <>
      {deleteSystemUser && (
        <CsoModal
          deleteModal
          systemUserModal
          fromCard
          pending={props.pending}
          systemUserId={props.id}
          userId={props.userId}
          toggleModal={toggleDelete}
          title={strings.deleteTitle}
          content={`${strings.deleteContentOne} ${props.firstName} ${props.lastName} ${strings.deleteContentTwo}`}
          button={strings.delete}
        />
      )}

      <TableRowStyles
        rowbackground={props.pending && '#f6d9c6'}
        hoverBackground='#f1f7ff'
        borderTop='none'
        borderLeft='none'
        borderRight='none'
        width={size[0] >= 768 ? '100%' : '760px'}
        align='center'
        ref={containerRef}
        onMouseEnter={() => {
          size[0] >= 768 && setOpenTools(true);
        }}
        onMouseLeave={() => {
          size[0] >= 768 && setOpenTools(false);
        }}>
        <Link
          to={
            !props.pending
              ? `/system-users/${props.id}`
              : `/system-users/pending/${props.id}`
          }>
          <TableItemStyles width='90px'>{props.firstName}</TableItemStyles>
          <TableItemStyles width='90px'>{props.lastName}</TableItemStyles>
          <TableItemStyles width='200px'>{props.email}</TableItemStyles>
          <TableItemStyles width='90px'>{props.role}</TableItemStyles>
          <TableItemStyles width='90px'>
            {props.createdDate !== '0001-01-01T00:00:00'
              ? moment(props.createdDate).format('MM/DD/YY')
              : 'Pending'}
          </TableItemStyles>
          <TableItemStyles width='90px'>
            {props.lastSignIn !== '0001-01-01T00:00:00'
              ? moment(props.lastSignIn).format('MM/DD/YY hh:mma')
              : 'Never'}
          </TableItemStyles>
          <TableItemStyles width='150px'>
            {props.locationDisplayName}
          </TableItemStyles>
        </Link>
        <FaEllipsisV
          className='Card-Tools-Toggle'
          style={size[0] >= 768 ? { display: 'none' } : { display: 'block' }}
          onClick={toggleToolBar}
        />
        <ToolsStyles
          ref={OutsideRef}
          displayTools={openTools}
          containerHeight={(containerHeight - 35) / 2}>
          {!props.pending && (
            <Link
              title={strings.notificationsTitle}
              to={`/system-users/${props.id}/notifications`}
              className='Tool-Item'>
              <FaInfoCircle />
            </Link>
          )}
          <div className='Tool-Item' onClick={toggleDelete}>
            <FaRegTrashAlt title={strings.delete} />
          </div>
        </ToolsStyles>
      </TableRowStyles>
    </>
  );
};

export default SystemUserCard;
