import React, { useState, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  FaUserCircle,
  FaUserFriends,
  FaCity,
  FaTachometerAlt,
  FaDollarSign,
  FaMoneyBillWave,
  FaUniversity,
  FaAddressCard,
  FaUsers,
  FaChargingStation,
  FaTags,
  FaTasks,
  FaChevronRight,
  FaChevronDown,
  FaCaretDown,
  FaExclamationCircle,
  FaCogs,
  FaRegLifeRing,
  FaSignOutAlt,
  FaBars,
  FaHandHoldingUsd,
} from 'react-icons/fa';

//services
import AppNavSectionService from '../AppNavSectionService';

//global component
import LogoutModal from '../../../shared/Modals/LogoutModal';
import NavAccountModal from '../Modals/NavAccountModal';
import RoleCanEdit from '../../OperatorShared/RoleCanEdit';
import SubscriptionAccess from '../../OperatorShared/SubscriptionAccess';

//hooks
import useOnClickOutside from '../../../shared/hooks/useOnClickOutside';
import { useRouter } from '../../../shared/hooks/useRouter';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';

//styles
import {
  UserProfileNavStyles,
  DropDownMenuStyles,
} from '../../../SharedStyles';

const AccountBlock = (props) => {
  const { strings } = AppNavSectionService;
  const { account, currentUser } = useContext(CsoAccountContext);
  const router = useRouter();
  const ref = useRef();
  const [isHidden, setIsHidden] = useState(true);
  const [showMoneyMenu, setShowMoneyMenu] = useState(false);
  const [showSubscriptionMenu, setShowSubscriptionMenu] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);

  useOnClickOutside(ref, () => setIsHidden(true));

  const toggleAccountBlock = () => {
    setIsHidden(!isHidden);
  };

  const toggleMoneyMenu = () => {
    setShowMoneyMenu(!showMoneyMenu);
    setShowSubscriptionMenu(false);
  };

  const handleModalAndNull = (route, allow) => {
    if (account.subscriptionLevel !== 3) {
      setDisplayModal(true);
    } else if (
      account.subscriptionLevel === 3 &&
      !allow.includes(currentUser.role)
    ) {
      setDisplayModal(true);
    } else {
      router.history.push(route);
    }
  };

  const toggleSubscriptionMenu = () => {
    setShowSubscriptionMenu(!showSubscriptionMenu);
    setShowMoneyMenu(false);
  };

  const closeModal = () => {
    setDisplayModal(false);
  };

  const logout = () => {
    AppNavSectionService.logout();
  };

  const handleLogoutModal = () => {
    setLogoutModal(!logoutModal);
  };

  return (
    <UserProfileNavStyles mobileMedia='767px'>
      {logoutModal && (
        <LogoutModal
          handleLogout={logout}
          handleCloseModal={handleLogoutModal}
        />
      )}
      <div className='Toggle' onClick={toggleAccountBlock}>
        <div className='Image-Container'>
          <img
            src={
              currentUser && currentUser.imagePath
                ? currentUser.imagePath
                : require('../../../../assets/images/UsersPlaceholder.png').default
            }
            alt={currentUser && currentUser.firstName}
          />
        </div>
        <div className='Account-Container'>
          <span>{strings.acct}</span>
          <FaCaretDown className='carret' />
        </div>
        <FaBars className='Mobile-Menu-Display' />
        {displayModal && (
          <NavAccountModal closeModal={closeModal} account={account} />
        )}
      </div>
      {!isHidden && (
        <DropDownMenuStyles id='Account-List' ref={ref}>
          {/* Account Info Or Current User Profile based on subscription Level route */}
          <SubscriptionAccess levels={[0, 1]}>
            <Link to={`/system-users/${currentUser.id}`} className='Menu-Link'>
              <div>
                <FaUserCircle className='Menu-Icon' />
                <span>{strings.acctProfile}</span>
              </div>
            </Link>
          </SubscriptionAccess>
          <SubscriptionAccess levels={[3]}>
            <RoleCanEdit roles={[3, 4]}>
              <Link
                to={`/system-users/${currentUser.id}`}
                className='Menu-Link'>
                <div>
                  <FaUserCircle className='Menu-Icon' />
                  <span>{strings.acctProfile}</span>
                </div>
              </Link>
            </RoleCanEdit>
          </SubscriptionAccess>
          <SubscriptionAccess levels={[2, 3]}>
            <RoleCanEdit roles={[1, 2]}>
              <Link to={`/account-profile`} className='Menu-Link'>
                <div>
                  <FaUserCircle className='Menu-Icon' />
                  <span>{strings.acctProfile}</span>
                </div>
              </Link>
            </RoleCanEdit>
          </SubscriptionAccess>
          {/* Enterprise System Users route */}
          <SubscriptionAccess levels={[3]}>
            <RoleCanEdit roles={[1, 2]}>
              <div
                onClick={() => handleModalAndNull('/system-users', [1, 2])}
                className='Menu-Link'>
                <FaUserFriends className='Menu-Icon' />
                <span>{strings.sysUsers}</span>
              </div>
            </RoleCanEdit>
            <RoleCanEdit roles={[1, 2, 3]}>
              {/* Enterprise Locations route */}
              <div
                onClick={() => handleModalAndNull('/locations', [1, 2, 3])}
                className='Menu-Link'>
                <FaCity className='Menu-Icon' />
                <span>{strings.locations}</span>
              </div>
            </RoleCanEdit>
            {/* Enterprise meter groups route */}
            <RoleCanEdit roles={[1, 2, 3]}>
              <div
                onClick={() => handleModalAndNull('/meter-groups', [1, 2, 3])}
                className='Menu-Link'>
                <FaTachometerAlt className='Menu-Icon' />
                <span>{strings.groups}</span>
              </div>
            </RoleCanEdit>
          </SubscriptionAccess>
          <SubscriptionAccess
            specialAccess
            allowAccess={props.hasStripeAccount}
            levels={[0, 1, 2, 3]}>
            <RoleCanEdit roles={[1, 2]}>
              <div
                onClick={() => {
                  toggleMoneyMenu();
                }}
                className='Menu-Link'>
                <div className='Spaced'>
                  <div>
                    <FaDollarSign className='Menu-Icon' id='dollarSignIcon' />
                    <span>{strings.money}</span>
                  </div>
                  <div>
                    {showMoneyMenu ? (
                      <FaChevronDown
                        onClick={() => {
                          toggleMoneyMenu();
                        }}
                      />
                    ) : (
                      <FaChevronRight
                        onClick={() => {
                          toggleMoneyMenu();
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              {showMoneyMenu && (
                <div className='Sub-Menu'>
                  <SubscriptionAccess levels={[1, 2, 3]}>
                    <Link to='/statements' className='Sub-Menu-Link'>
                      <FaMoneyBillWave className='Menu-Icon' />
                      <span>{strings.statements}</span>
                    </Link>
                  </SubscriptionAccess>
                  <SubscriptionAccess levels={[1, 2, 3]}>
                    <Link to='/bank/earned-income' className='Sub-Menu-Link'>
                      <FaHandHoldingUsd className='Menu-Icon' />
                      <span>{strings.earnedIncome}</span>
                    </Link>
                  </SubscriptionAccess>
                  <Link to='/bank' className='Sub-Menu-Link'>
                    <FaUniversity className='Menu-Icon' />
                    <span>{strings.banks}</span>
                  </Link>
                </div>
              )}
            </RoleCanEdit>
          </SubscriptionAccess>
          <SubscriptionAccess levels={[0, 1, 2, 3]}>
            {/* enterprise, small biz, shared */}
            <SubscriptionAccess levels={[1, 2, 3]}>
              <RoleCanEdit roles={[1, 2, 4]}>
                <div
                  onClick={() => {
                    toggleSubscriptionMenu();
                  }}
                  className='Menu-Link'>
                  <div className='Spaced'>
                    <div>
                      <FaAddressCard
                        className='Menu-Icon'
                        id='subscriptionIcon'
                      />
                      <span>{strings.subscription}</span>
                    </div>
                    {showSubscriptionMenu ? (
                      <FaChevronDown
                        onClick={() => {
                          toggleSubscriptionMenu();
                        }}
                      />
                    ) : (
                      <FaChevronRight
                        onClick={() => {
                          toggleSubscriptionMenu();
                        }}
                      />
                    )}
                  </div>
                </div>
              </RoleCanEdit>
            </SubscriptionAccess>
            {/* non subscriber */}
            <SubscriptionAccess levels={[0]}>
              <Link to='/subscription' className='Menu-Link'>
                <FaAddressCard className='Menu-Icon' id='subscriptionIcon' />
                <span>{strings.subscription}</span>
              </Link>
            </SubscriptionAccess>
            {showSubscriptionMenu && (
              <div className='Sub-Menu'>
                <div className='subscription-sub-menu'>
                  <SubscriptionAccess levels={[1, 2, 3]}>
                    <Link
                      to='/subscription/memberships'
                      className='Sub-Menu-Link'>
                      <FaUsers className='Menu-Icon' />
                      <span>{strings.memberships}</span>
                    </Link>
                  </SubscriptionAccess>
                  <SubscriptionAccess levels={[3]}>
                    <Link
                      to='/subscription/charging-profiles'
                      className='Sub-Menu-Link'>
                      <FaChargingStation className='Menu-Icon' />
                      <span>{strings.chargingProfiles}</span>
                    </Link>
                  </SubscriptionAccess>

                  <SubscriptionAccess levels={[1, 2, 3]}>
                    {account.subscriptionLevel === 1 ? (
                      <Link
                        to='/subscription/pricing-schedules/shared'
                        className='Sub-Menu-Link'>
                        <FaDollarSign className='Menu-Icon' />
                        <span>{strings.pricingSchedule}</span>
                      </Link>
                    ) : (
                      <Link
                        to='/subscription/pricing-schedules'
                        className='Sub-Menu-Link'>
                        <FaDollarSign className='Menu-Icon' />
                        <span>{strings.pricingSchedules}</span>
                      </Link>
                    )}
                  </SubscriptionAccess>
                  <SubscriptionAccess levels={[1, 2, 3]}>
                    {account.subscriptionLevel === 1 ? (
                      <Link
                        to='/subscription/discount-schedules/shared'
                        className='Sub-Menu-Link'>
                        <FaTags className='Menu-Icon' />
                        <span>{strings.discountSchedules}</span>
                      </Link>
                    ) : (
                      <Link
                        to='/subscription/discount-schedules'
                        className='Sub-Menu-Link'>
                        <FaTags className='Menu-Icon' />
                        <span>{strings.discountSchedules}</span>
                      </Link>
                    )}
                  </SubscriptionAccess>
                  <Link to='/subscription' className='Sub-Menu-Link'>
                    <FaTasks className='Menu-Icon' />
                    <span>{strings.subscriptionSettings}</span>
                  </Link>
                </div>
              </div>
            )}
          </SubscriptionAccess>
          <SubscriptionAccess levels={[2, 3]}>
            <RoleCanEdit roles={[1, 2]}>
              <Link
                to={`/system-users/${currentUser.id}?profile=true`}
                className='Menu-Link'>
                <div>
                  <FaUserCircle className='Menu-Icon' />
                  <span>{strings.userProfile}</span>
                </div>
              </Link>
            </RoleCanEdit>
          </SubscriptionAccess>
          <Link to='/notifications' className='Menu-Link'>
            <div>
              <FaExclamationCircle className='Menu-Icon' />
              <span>{strings.notifications}</span>
            </div>
          </Link>
          <Link to='/settings' className='Menu-Link'>
            <div>
              <FaCogs className='Menu-Icon' />
              <span>{strings.settings}</span>
            </div>
          </Link>
          <Link to='/privacy' className='Menu-Link'>
            <div>
              <FaRegLifeRing className='Menu-Icon' />
              <span>{strings.about}</span>
            </div>
          </Link>
          <div className='Menu-Link' onClick={handleLogoutModal}>
            <FaSignOutAlt className='Menu-Icon' id='signOutIcon' />
            <span>{strings.logout}</span>
          </div>
        </DropDownMenuStyles>
      )}
    </UserProfileNavStyles>
  );
};

export default AccountBlock;
