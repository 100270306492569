import React, { useState, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  FaUserAlt,
  FaAddressCard,
  FaCarAlt,
  FaCaretDown,
  FaExclamationCircle,
  FaUniversity,
  FaChargingStation,
  FaCogs,
  FaLock,
  FaClipboardList,
  FaSignOutAlt,
  FaBars,
  FaRegLifeRing,
  FaChevronDown,
  FaChevronRight,
  FaIdCard,
} from 'react-icons/fa';

//services
import AppNavService from '../AppNavService';

//hooks
import useOnClickOutside from '../../../shared/hooks/useOnClickOutside';

//global components
import LogoutModal from '../../../shared/Modals/LogoutModal';

//styles
import {
  UserProfileNavStyles,
  DropDownMenuStyles,
} from '../../../SharedStyles';

//context
import { DriverContext } from '../../../context/driver/getDriverContext';
import DevModeOnly from '../../../shared/Tools/DevModeOnly';

const AccountBlock = (props) => {
  const { strings } = AppNavService;
  const { driver } = useContext(DriverContext);
  const [isHidden, setIsHidden] = useState(true);
  const [logoutModal, setLogoutModal] = useState(false);
  const [aboutMenu, setAboutMenu] = useState(false);

  const ref = useRef();
  useOnClickOutside(ref, () => setIsHidden(true));

  const handleClickAccountBlock = () => {
    setIsHidden(!isHidden);
  };

  const toggleAboutMenu = () => {
    setAboutMenu(!aboutMenu);
  };

  const logout = () => {
    AppNavService.logout();
  };

  const handleLogoutModal = () => {
    setLogoutModal(!logoutModal);
  };

  return (
    <UserProfileNavStyles mobileMedia='767px'>
      {logoutModal && (
        <LogoutModal
          handleLogout={logout}
          handleCloseModal={handleLogoutModal}
        />
      )}
      <div className='Image-Container'>
        {driver.imagePath ? (
          <img src={driver.imagePath} alt={driver.firstName} />
        ) : (
          <img
            src={require('../../../../assets/images/UsersPlaceholder.png').default}
            alt='placeholder'
          />
        )}
      </div>
      <div className='Account-Container' onClick={handleClickAccountBlock}>
        <span>{strings.acct}</span>
        <FaCaretDown className='carret' />
      </div>
      <FaBars
        className='Mobile-Menu-Display'
        onClick={handleClickAccountBlock}
      />
      {!isHidden && (
        <DropDownMenuStyles ref={ref}>
          <Link to='/account-settings' className='Menu-Link'>
            <div>
              <FaUserAlt className='Menu-Icon' />
              <span>{strings.profile}</span>
            </div>
          </Link>
          <Link to='/memberships/' className='Menu-Link'>
            <div>
              <FaAddressCard className='Menu-Icon' />
              <span>{strings.memberships}</span>
            </div>
          </Link>
          <Link to='/vehicle-list' className='Menu-Link'>
            <div>
              <FaCarAlt className='Menu-Icon' />
              <span>{strings.vehicles}</span>
            </div>
          </Link>
          <Link to='/my-chargers' className='Menu-Link'>
            <div>
              <FaChargingStation className='Menu-Icon' />
              <span>{strings.owned}</span>
            </div>
          </Link>
          <Link to='/payments' className='Menu-Link'>
            <div>
              <FaUniversity className='Menu-Icon' />
              <span>{strings.payments}</span>
            </div>
          </Link>
          <Link to='/notifications' className='Menu-Link'>
            <div>
              <FaExclamationCircle className='Menu-Icon' />
              <span>{strings.notifications}</span>
            </div>
          </Link>
          <Link to='/access-cards' className='Menu-Link'>
            <div>
              <FaIdCard className='Menu-Icon' />
              <span>{strings.accessCards}</span>
            </div>
          </Link>
          <Link to='/settings' className='Menu-Link'>
            <div>
              <FaCogs className='Menu-Icon' />
              <span>{strings.settings}</span>
            </div>
          </Link>
          <div
            onClick={() => {
              toggleAboutMenu();
            }}
            className='Menu-Link'>
            <div className='Spaced'>
              <div>
                <FaRegLifeRing className='Menu-Icon' />
                <span>{strings.about}</span>
              </div>
              {aboutMenu ? (
                <FaChevronDown
                  onClick={() => {
                    toggleAboutMenu();
                  }}
                />
              ) : (
                <FaChevronRight
                  onClick={() => {
                    toggleAboutMenu();
                  }}
                />
              )}
            </div>
          </div>
          {aboutMenu && (
            <div className='Sub-Menu'>
              <Link to='/privacy' className='Sub-Menu-Link'>
                <FaLock className='Menu-Icon' />
                <span>{strings.privacy}</span>
              </Link>
              <Link to='/terms' className='Sub-Menu-Link'>
                <FaClipboardList className='Menu-Icon' />
                <span>{strings.terms}</span>
              </Link>
            </div>
          )}
          <div className='Menu-Link' onClick={handleLogoutModal}>
            <FaSignOutAlt className='Menu-Icon' id='signOutIcon' />
            <span>{strings.logout}</span>
          </div>
        </DropDownMenuStyles>
      )}
    </UserProfileNavStyles>
  );
};

export default AccountBlock;
