import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppReportsService = {
  strings: localStrings,
};

export default AppReportsService;
