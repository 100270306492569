import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import strings from './strings';
import AppApiService from '../../../services/AppApi/AppApiService';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppDiscountSchedulesService = {
  createDiscountSchedule: function (schedule) {
    return AppApiService.post('createDiscountSchedule', null, schedule);
  },
  updateDiscountSchedule: function (schedule) {
    return AppApiService.post('updateDiscountSchedule', null, schedule);
  },
  deleteDiscountSchedule: function (schedule) {
    return AppApiService.post('deleteDicountSchedule', null, schedule);
  },
  createCouponToken: function (coupon) {
    return AppApiService.post('createCouponToken', null, coupon);
  },
  deleteCouponToken: function (couponId) {
    return AppApiService.post('deleteCouponToken', null, couponId);
  },
  getCouponTokens: function (discountId) {
    return AppApiService.post('getCouponTokens', null, discountId);
  },
  strings: localStrings,
};

export default AppDiscountSchedulesService;
