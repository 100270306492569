import React, { useContext, useEffect, useState } from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppChargersModalService from './AppChargersModalService';

//global component
import { LoadingSpinner } from '../../../shared';
import ModalButton from '../../../shared/Buttons/ModalButton';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import { ErrorContext } from '../../../context/shared/ErrorContext';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  SelectStyles,
  ModalNoteStyles,
} from '../../../SharedStyles';

import '../Chargers.scss';

const CouponModal = (props) => {
  const { strings } = AppChargersModalService;
  const { account, setAccount, updatingAccount } = useContext(
    CsoAccountContext
  );
  const { setError } = useContext(ErrorContext);
  const [discounts, setDiscounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCoupons, setSelectedCoupons] = useState([]);

  useEffect(() => {
    setDiscounts(account.discountSchedules);
    if (props.cardCoupon) {
      //loads ids to the value for coupons
      let defaultIds = account.discountSchedules
        .filter((sx) => {
          return (
            sx.chargingStations.includes(props.charger.iotHubDeviceId) && sx
          );
        })
        .map((sx) => {
          return sx.id;
        });

      setSelectedCoupons(defaultIds);
    }
  }, [account, props.cardCoupon, props.charger]);

  const closeModal = () => {
    props.handleCloseModal();
  };

  const handleChange = (e) => {
    let options = e.target.options;
    let selectedOptions;
    selectedOptions = [...options]
      .filter((option) => {
        return option.selected;
      })
      .map((selectedOption) => {
        return selectedOption.value;
      });
    setSelectedCoupons(selectedOptions);
  };

  const handleSaveInfo = async (e) => {
    setLoading(true);
    try {
      //add mutilple chargers to discount
      if (props.addCoupon) {
        let allCoupons = discounts.filter((coupon) => {
          props.chargers.forEach((charger) => {
            coupon.chargingStations = [
              ...new Set([...coupon.chargingStations, charger.iotHubDeviceId]),
            ];
          });
          return selectedCoupons.includes(coupon.id) && coupon;
        });
        setAccount({ ...account, allCoupons });
        updatingAccount(account);
        setTimeout(() => {
          setLoading(false);
          closeModal();
        }, 1500);
      }
      //remove mutiple chargers from discount
      if (props.removeCoupon) {
        let allCoupons = discounts.filter((coupon) => {
          for (let charger of props.chargers) {
            if (coupon.chargingStations.includes(charger.iotHubDeviceId)) {
              coupon.chargingStations = coupon.chargingStations.filter(
                (station) => station !== charger.iotHubDeviceId
              );
            }
          }
          return coupon;
        });
        setAccount({ ...account, allCoupons });
        updatingAccount(account);
        setTimeout(() => {
          setLoading(false);
          closeModal();
        }, 1500);
      }
      //add single charger to discount
      if (props.cardCoupon) {
        let allCoupons = [];
        discounts.forEach((coupon) => {
          if (selectedCoupons.includes(coupon.id)) {
            coupon.chargingStations = [
              ...new Set([
                ...coupon.chargingStations,
                props.charger.iotHubDeviceId,
              ]),
            ];
          } else {
            coupon.chargingStations = coupon.chargingStations.filter(
              (station) => {
                return station !== props.charger.iotHubDeviceId;
              }
            );
          }
          allCoupons.push(coupon);
        });

        setAccount({ ...account, allCoupons });
        updatingAccount(account);
        setTimeout(() => {
          setLoading(false);
          closeModal();
        }, 1500);
      }
    } catch (err) {
      if (err.response) {
        setError({ display: true, message: err.response.data });
      }
    }
  };

  if (props.addCoupon) {
    return (
      <ModalStyles>
        {loading && <LoadingSpinner />}
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{strings.addCouponTitle}</h2>
            <FaRegWindowClose className='Close' onClick={closeModal} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            <ModalItemStyles padding='0 10px'>
              <p>
                {strings.addCouponContentOne} {props.chargers.length}{' '}
                {strings.addCouponContentTwo}
              </p>
            </ModalItemStyles>
            {/* Section */}
            <ModalItemStyles padding='0 10px'>
              <SelectStyles
                noDropdown
                name='membershipIds'
                width='100%'
                onChange={handleChange}
                size={discounts.length}
                multiple>
                {account.discountSchedules.map((discount) => {
                  return (
                    <option key={discount.id} value={discount.id}>
                      {discount.name}
                    </option>
                  );
                })}
              </SelectStyles>
            </ModalItemStyles>
            {/* Note Section */}
            <ModalNoteStyles>
              <p>
                <strong>{strings.note}</strong> {strings.addCouponNote}
              </p>
            </ModalNoteStyles>
          </ModalContentStyles>
          {/* Button Component for Modals*/}
          <ModalButton
            disabled={!props.chargers.length || !selectedCoupons.length}
            containerMargin='10px 10px 0'
            containerAlign='right'
            buttonPadding='10px'
            buttonMargin='0'
            handleClick={handleSaveInfo}
            buttonText={strings.assignCoupons}
          />
        </ModalContainerStyles>
      </ModalStyles>
    );
  }

  if (props.removeCoupon) {
    return (
      <ModalStyles>
        {loading && <LoadingSpinner />}
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{strings.removeCouponTitle}</h2>
            <FaRegWindowClose className='Close' onClick={closeModal} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            <ModalItemStyles padding='0 10px'>
              <p>
                {strings.removeCouponContentOne} {props.chargers.length}{' '}
                {strings.removeCouponContentTwo}
              </p>
            </ModalItemStyles>
            {/* Note Section */}
            <ModalNoteStyles>
              <p>
                <strong>{strings.note}</strong> {strings.removeCouponNote}
              </p>
            </ModalNoteStyles>
          </ModalContentStyles>
          {/* Button Component for Modals*/}
          <ModalButton
            disabled={props.chargers.length === 0}
            containerMargin='10px 10px 0'
            containerAlign='right'
            buttonPadding='10px'
            buttonMargin='0'
            handleClick={handleSaveInfo}
            buttonText={strings.removeCoupons}
          />
        </ModalContainerStyles>
      </ModalStyles>
    );
  }

  if (props.cardCoupon) {
    return (
      <ModalStyles>
        {loading && <LoadingSpinner />}
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{strings.addSCouponTitle}</h2>
            <FaRegWindowClose className='Close' onClick={closeModal} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            <ModalItemStyles padding='0 10px'>
              <p>
                {strings.addSCouponContentOne}
                {strings.addSCouponContentTwo}
              </p>
            </ModalItemStyles>
            {/* Section */}
            <ModalItemStyles padding='0 10px'>
              <SelectStyles
                noDropdown
                multiple
                onChange={handleChange}
                size={discounts.length}
                name='membershipIds'
                value={selectedCoupons}>
                {account.discountSchedules.map((discount) => {
                  return (
                    <option key={discount.id} value={discount.id}>
                      {discount.name}
                    </option>
                  );
                })}
              </SelectStyles>
            </ModalItemStyles>
            {/* Note Section */}
            <ModalNoteStyles>
              <p>
                <strong>{strings.note}</strong> {strings.addCouponNote}
              </p>
            </ModalNoteStyles>
          </ModalContentStyles>
          {/* Button Component for Modals*/}
          <ModalButton
            disabled={!selectedCoupons.length}
            containerMargin='10px 10px 0'
            containerAlign='right'
            buttonPadding='10px'
            buttonMargin='0'
            handleClick={handleSaveInfo}
            buttonText={strings.assignCoupons}
          />
        </ModalContainerStyles>
      </ModalStyles>
    );
  }
};

export default CouponModal;
