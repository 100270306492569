import styled from 'styled-components';

export const ButtonContainerStyles = styled.div`
  display: ${({ display }) => display};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  flex: ${({ flexWidth }) => flexWidth};
  text-align: ${({ align }) => (align ? align : 'center')};
  text-transform: capitalize;
  margin: ${({ margin }) => (margin ? margin : '20px auto')};
  padding: ${({ padding }) => padding};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? '1px solid #e7e7e7' : 'none'};
  @media screen and (max-width: ${({ hasMobile, noMobile, theme }) => {
      if (hasMobile || noMobile) {
        return theme.defaultMediaQuery;
      } else {
        return '0px';
      }
    }}) {
    display: none;
  }
`;

export const ButtonResetStyles = styled.button`
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
`;

export const ButtonStyles = styled.button`
  text-transform: capitalize;
  background-color: ${({ inverse, theme }) =>
    inverse ? theme.defaultBgInverseColor : theme.defaultBgColor};
  border-radius: 15px;
  border: ${({ theme, noBorder }) =>
    noBorder ? 'none' : `1px solid ${theme.defaultBgColor}`};
  display: inline-block;
  cursor: pointer;
  color: ${({ inverse, theme }) =>
    inverse ? theme.defaultBgColor : theme.defaultBgInverseColor};
  font-size: 14px;
  margin: ${({ margin }) => (margin ? margin : '0 10px')};
  padding: ${({ padding }) => (padding ? padding : '6px 10px')};
  text-decoration: none;
  outline: none;
  font-family: 'Raleway', sans-serif;
  transition: all 300ms ease;
  flex: ${({ flex }) => flex};
  text-align: ${({ align }) => align};
  &:active {
    outline: none;
    transform: translateX(2px) translateY(2px);
  }
  &:hover,
  &:focus {
    outline: none;
    background-color: ${({ inverse, theme }) =>
      inverse ? theme.defaultBgColor : theme.defaultHoverDarkColor};
    color: #fff;
  }
  :disabled {
    background: lightgrey;
    color: darkgrey;
    border: 1px solid lightgrey;
  }
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    font-size: 12px;
  }
`;

export const ButtonSpanStyles = styled.span`
  text-transform: capitalize;
  background-color: ${({ inverse, theme }) =>
    inverse ? theme.defaultBgInverseColor : theme.defaultBgColor};
  border-radius: 15px;
  border: ${({ theme, noBorder }) =>
    noBorder ? 'none' : `1px solid ${theme.defaultBgColor}`};
  display: inline-block;
  cursor: pointer;
  color: ${({ inverse, theme }) =>
    inverse ? theme.defaultBgColor : theme.defaultBgInverseColor};
  font-size: 14px;
  margin: ${({ margin }) => (margin ? margin : '0 10px')};
  padding: ${({ padding }) => (padding ? padding : '6px 10px')};
  text-decoration: none;
  outline: none;
  font-family: 'Raleway', sans-serif;
  transition: all 300ms ease;
  &:active {
    outline: none;
    transform: translateX(2px) translateY(2px);
  }
  &:hover,
  &:focus {
    outline: none;
    background-color: ${({ inverse, theme }) =>
      inverse ? theme.defaultBgColor : theme.defaultHoverDarkColor};
    color: #fff;
  }
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    font-size: 12px;
  }
`;

export const ButtonDivStyles = styled.div`
  text-transform: capitalize;
  background-color: ${({ inverse, theme }) =>
    inverse ? theme.defaultBgInverseColor : theme.defaultBgColor};
  border-radius: 15px;
  border: ${({ theme, noBorder }) =>
    noBorder ? 'none' : `1px solid ${theme.defaultBgColor}`};
  display: inline-block;
  cursor: pointer;
  color: ${({ inverse, theme }) =>
    inverse ? theme.defaultBgColor : theme.defaultBgInverseColor};
  font-size: 14px;
  margin: ${({ margin }) => (margin ? margin : '0 10px')};
  padding: ${({ padding }) => (padding ? padding : '6px 10px')};
  text-decoration: none;
  outline: none;
  font-family: 'Raleway', sans-serif;
  transition: all 300ms ease;
  &:active {
    outline: none;
    transform: translateX(2px) translateY(2px);
  }
  &:hover,
  &:focus {
    outline: none;
    background-color: ${({ inverse, theme }) =>
      inverse ? theme.defaultBgColor : theme.defaultHoverDarkColor};
    color: #fff;
  }
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    font-size: 12px;
  }
`;

export const ButtonLinkStyles = styled.a`
  text-transform: capitalize;
  background-color: ${({ inverse, theme }) =>
    inverse ? theme.defaultBgInverseColor : theme.defaultBgColor};
  border-radius: 15px;
  border: ${({ theme, noBorder }) =>
    noBorder ? 'none' : `1px solid ${theme.defaultBgColor}`};
  display: inline-block;
  cursor: pointer;
  color: ${({ inverse, theme }) =>
    inverse ? theme.defaultBgColor : theme.defaultBgInverseColor};
  font-size: 14px;
  margin: ${({ margin }) => (margin ? margin : '0 10px')};
  padding: ${({ padding }) => (padding ? padding : '6px 10px')};
  text-decoration: none;
  outline: none;
  font-family: 'Raleway', sans-serif;
  transition: all 300ms ease;
  &:active {
    outline: none;
    transform: translateX(2px) translateY(2px);
  }
  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
    background-color: ${({ inverse, theme }) =>
      inverse ? theme.defaultBgColor : theme.defaultHoverDarkColor};
    color: #fff;
  }
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    font-size: 12px;
  }
`;

export const ButtonPlusStyles = styled.button`
  background: none;
  outline: none;
  border: none;
  text-decoration: ${({ textDecoration }) => textDecoration};
  position: ${({ position }) => position};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  cursor: pointer;
  svg {
    font-size: ${({ size }) => size};
    color: ${({ color }) => color};
    margin: ${({ svgMargin }) => svgMargin};
  }
  &:active {
    border: none;
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    color: #c2c2c2;
  }
`;

export const ButtonModalStyles = styled.button`
  background-color: ${({ background, inverse, theme }) =>
    background
      ? background
      : inverse
      ? theme.defaultBgInverseColor
      : theme.defaultModalButtonBg};
  border-radius: 15px;
  display: inline-block;
  cursor: pointer;
  font-weight: 600;
  color: ${({ inverse, theme }) =>
    inverse ? theme.defaultBgColor : theme.defaultBgInverseColor};
  font-size: ${({ size }) => (size ? size : '15px')};
  margin: ${({ margin }) => (margin ? margin : '0 10px')};
  padding: ${({ padding }) => (padding ? padding : '6px 10px')};
  text-decoration: none;
  outline: none;
  font-family: 'Raleway', sans-serif;
  transition: all 300ms ease;
  text-transform: uppercase;
  border: ${({ theme, noBorder }) =>
    noBorder ? 'none' : `1px solid ${theme.defaultBgColor}`};
  &:active {
    outline: none;
    transform: translateX(2px) translateY(2px);
  }
  &:disabled {
    background: grey;
    cursor: not-allowed;
    border: 1px solid grey;
    &:hover,
    &:focus {
      outline: none;
      background-color: grey;
      border: 1px solid grey;
      color: #fff;
    }
  }
  &:hover,
  &:focus {
    outline: none;
    background-color: ${({ inverse, theme }) =>
      inverse ? theme.defaultBgColor : theme.defaultHoverDarkColor};
    color: #fff;
  }
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    font-size: 12px;
  }
`;

export const ButtonDivGreyStyles = styled.div`
  text-transform: capitalize;

  display: inline-block;
  font-size: ${({ size }) => (size ? size : '14px')};
  margin: ${({ margin }) => (margin ? margin : '3px auto')};
  padding: ${({ padding }) => (padding ? padding : '5px')};
  border: ${({ border, background }) =>
    border ? `1px solid ${background}` : '1px solid #c2c2c2'};
  border-radius: 15px;
  background-color: ${({ background }) =>
    background ? background : '#c2c2c2'};
  color: #fff;
  outline: none;
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
  &:focus,
  &:hover {
    outline: none;
    background-color: #c2c2c2;
  }
  &:active {
    outline: none;
    transform: translateX(2px) translateY(2px);
  }
`;

export const ButtonGreyStyles = styled.button`
  text-transform: capitalize;
  display: inline-block;
  white-space: nowrap;
  font-size: ${({ size }) => (size ? size : '14px')};
  margin: ${({ margin }) => (margin ? margin : '3px auto')};
  padding: ${({ padding }) => (padding ? padding : '5px')};
  border: 1px solid #c2c2c2;
  border-radius: 15px;
  background-color: #c2c2c2;
  color: #fff;
  outline: none;
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
  &:focus,
  &:hover {
    outline: none;
    border: 1px solid #c2c2c2;
    background-color: #c2c2c2;
  }
  &:active {
    outline: none;
    border: 1px solid #c2c2c2;
    transform: translateX(2px) translateY(2px);
  }
  &:disabled {
    background: #f2f2f2;
    border: 1px solid #f2f2f2;
    color: #c2c2c2;
    cursor: not-allowed;
  }
`;

export const ButtonMobileStyles = styled.div`
  color: ${({ theme }) => theme.defaultInteralNavColor};
  position: fixed;
  bottom: 20px;
  right: 5px;
  cursor: pointer;
  display: none;
  padding: 5px;
  z-index: 99999;
  // animation: scaleBack 1s ease-in-out 1 forwards;
  // -webkit-animation: scaleBack 1s ease-in-out 1 forwards;

  .Mobile-Link {
    color: ${({ theme }) => theme.defaultBgInverseColor};
    font-size: 40px;
    visibility: hidden;
    cursor: pointer;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0 0;
    border-radius: 50%;
    p {
      color: ${({ theme }) => theme.defaultInteralNavColor};
      font-size: 12px;
      width: auto;
      padding: 0 10px;
    }
    svg {
      box-shadow: 2px 2px 3px 0 #a5a5a5;
      border-radius: 50%;
      background: ${({ theme }) => theme.defaultInteralNavColor};
      padding: 8px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    display: block;
    .Mobile-Link {
      visibility: visible;
    }
  }
`;

export const ButtonStartStyles = styled.button`
  text-transform: capitalize;
  background-color: ${({ bgColor }) => bgColor};
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};
  border-radius: 15px;
  border: ${({ border }) => border};
  display: inline-block;
  cursor: pointer;
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  text-decoration: none;
  outline: none;
  font-family: 'Raleway', sans-serif;
  transition: all 300ms ease;
  flex: ${({ flex }) => flex};
  text-align: ${({ align }) => align};
  &:active {
    outline: none;
    transform: translateX(2px) translateY(2px);
  }
  &:hover,
  &:focus {
    outline: none;
    background-color: ${({ hoverBg }) => hoverBg};
    color: ${({ hoverColor }) => hoverColor};
  }
  :disabled {
    background: lightgrey;
    color: darkgrey;
    border: 1px solid lightgrey;
  }
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    font-size: 12px;
  }
`;
