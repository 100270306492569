/* THIS SERVICE SHOULD NOT HAVE DEPENDENCIES ON ANY OTHER SERVICE */

import strings from './strings';
import version from './version';
import type from './type';

const AppPlatformService = {
  isIOS: function () {
    if (window.cordova) {
      return window.cordova.platformId.toLowerCase() === 'ios';
    }
    return false;
  },
  isAndroid: function () {
    if (window.cordova) {
      return window.cordova.platformId.toLowerCase() === 'android';
    }
    return false;
  },
  isBrowser: function () {
    return type['current'] === strings.types.web;
  },
  isCSOApp: function () {
    return type['current'] === strings.types.cso;
  },
  isDriverApp: function () {
    return type['current'] === strings.types.driver;
  },
  isWebApp: function () {
    return type['current'] === strings.types.web;
  },
  getVersion: function () {
    return version['current'];
  },
  getLanguage: function () {
    // const currentLanguage = window.navigator.language;
    if (
      !localStorage.getItem('cso-language') ||
      !localStorage.getItem('driver-language')
    ) {
      localStorage.setItem('cso-language', strings.defaultLanguage);
      localStorage.setItem('driver-language', strings.defaultLanguage);
    }
    let currentLanguage;
    if (localStorage.getItem('csoAccount')) {
      currentLanguage = localStorage.getItem('cso-language');
    } else {
      currentLanguage = localStorage.getItem('driver-language');
    }

    const isSupported =
      strings.supportedLanguages.filter(
        (item) => item.language === currentLanguage
      ).length > 0;

    if (isSupported) {
      return currentLanguage;
    } else {
      return strings.defaultLanguage;
    }
  },
  getSupportedLanguages: function () {
    return strings.supportedLanguages;
  },
  debug: function (message) {
    if (version['current'] === 'dev') {
      console.log(`>>>>> DEBUG >>>>> ${message}`);
    }
  },
  currentLanguage: undefined,
  currencyFormat: function (n) {
    if (this.currentLanguage === undefined) {
      this.currentLanguage = this.getLanguage();
    }
    return new Intl.NumberFormat(this.currentLanguage, {style: 'currency', currency: 'USD', maximumFractionDigits: 2}).format(n);
  }
};

export default AppPlatformService;
