import React, { useState, useContext } from 'react';

//layout
import { OuterWrapper, ComponentWrapper } from '../OperatorShared/index';
import Button from '../../shared/Buttons/Button';

//services
import AppSettingsService from './AppSettingsService';

//components
import LoadingSpinner from '../../shared/Loading/LoadingSpinner';
import CsoNotFound from '../../shared/AppNotFound/CsoNotFound';

//context
import { CsoAccountContext } from '../../context/operator/getCsoAccount';

//styles
import {
  FormContainerStyles,
  FormRowStyles,
  SelectStyles,
} from '../../SharedStyles';

const Settings = () => {
  const { strings } = AppSettingsService;
  const [prevLanguage] = useState(localStorage.getItem('cso-language'));
  const languages = AppSettingsService.getSupportedLanguages();

  const {
    loading,
    currentUser,
    setCurrentUser,
    updatingCurrentUser,
  } = useContext(CsoAccountContext);

  const handleChange = (e) => {
    localStorage.setItem('cso-language', e.target.value);
    setCurrentUser({ ...currentUser, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updatingCurrentUser(prevLanguage);
  };

  function populateLanguageOptions() {
    return languages.map((item) => {
      return (
        <option key={item.language} value={item.language}>
          {item.description}
        </option>
      );
    });
  }

  if (currentUser.isAdminUser) {
    return (
      <CsoNotFound message='Ampedup Admins do not need access to the settings' />
    );
  }

  //DO NOT REDIRECT AFTER SAVE - Language selection will not working since window has to refresh.
  return (
    <OuterWrapper
      title={strings.pageTitle}
      internal
      myForm
      path='/'
      roles={[1, 2, 3, 4]}>
      <ComponentWrapper title={strings.pageTitle}>
        {loading && <LoadingSpinner />}
        <form onSubmit={handleSubmit} id='myForm'>
          <FormContainerStyles headersPadding='0'>
            <h3>{strings.languageTitle}</h3>
            <FormRowStyles>
              <SelectStyles
                name='language'
                data-name='LanguageSelect'
                value={currentUser.language}
                onChange={handleChange}>
                <option value={strings.chooseLanguageSelection}>
                  {strings.chooseLanguageSelection}
                </option>
                {populateLanguageOptions()}
              </SelectStyles>
            </FormRowStyles>
            <h3>{strings.unitOfMeasurementTitle}</h3>
            <FormRowStyles>
              <SelectStyles
                name='unitOfMeasurement'
                data-name='UnitSelect'
                value={currentUser.unitOfMeasurement}
                onChange={handleChange}>
                <option value={strings.unitOfMeasurementSelection}>
                  {strings.unitOfMeasurementSelection}
                </option>
                <option value={strings.imperialSelection}>
                  {strings.imperialSelection}
                </option>
                <option value={strings.metricSelection}>
                  {strings.metricSelection}
                </option>
              </SelectStyles>
            </FormRowStyles>
          </FormContainerStyles>
        </form>
      </ComponentWrapper>
      <Button
        cso
        hasMobile
        buttonText={strings.save}
        formSubmit
        roles={[1, 2]}
      />
    </OuterWrapper>
  );
};

export default Settings;
