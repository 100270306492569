import React from 'react';
import moment from 'moment';

//service
import AppPlatformService from '../../../../../../../../../services/AppPlatform/AppPlatformService';

//styles
import {
  TableRowStyles,
  TableItemStyles,
} from '../../../../../../../../SharedStyles';

//helpers
import { timeConvert } from '../../../../../../../../shared/Helpers';

const EnterpriseCard = (props) => {
  const { statementDetails } = props;

  return (
    <TableRowStyles
      hoverBackground='#f1f7ff'
      borderTop='none'
      borderLeft='none'
      borderRight='none'
      align='center'
      width='760px'>
      <TableItemStyles width='100%' maxWidth='80px'>
        {moment(statementDetails.transactionDate).format('MM/DD/YY')}
      </TableItemStyles>
      <TableItemStyles width='100%' maxWidth='80px'>
        {moment(statementDetails.transactionDate).format('h:mma')}
      </TableItemStyles>
      <TableItemStyles width='100%' maxWidth='95px' minWidth='95px'>
        {statementDetails.iotHubDeviceId}
      </TableItemStyles>
      <TableItemStyles width='100%' maxWidth='80px'>
        {statementDetails.entryType}
      </TableItemStyles>
      <TableItemStyles width='100%' maxWidth='80px'>
        {statementDetails.avgkW > 0 ? statementDetails.avgkW.toFixed(2) : '--'}
      </TableItemStyles>
      <TableItemStyles width='100%' maxWidth='80px'>
        {statementDetails.totalkWh > 0
          ? statementDetails.totalkWh.toFixed(2)
          : '--'}
      </TableItemStyles>
      <TableItemStyles width='100%' maxWidth='95px' minWidth='95px'>
        {statementDetails.rateType}
      </TableItemStyles>
      <TableItemStyles width='100%' maxWidth='80px'>
        {AppPlatformService.currencyFormat(statementDetails.rate)}
      </TableItemStyles>
      <TableItemStyles width='100%' maxWidth='80px'>
        {AppPlatformService.currencyFormat(statementDetails.totalCost)}
      </TableItemStyles>
      <TableItemStyles width='100%' maxWidth='80px'>
        {timeConvert(statementDetails.totalTime, true)}
      </TableItemStyles>
    </TableRowStyles>
  );
};

export default EnterpriseCard;
