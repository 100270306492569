import React, { useState } from 'react';
import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa';

//service
import CustomIncentiveService from '../CustomIncentiveService';

//styles
import {
  DivStyles,
  ListPlaceholderStyles,
  TableHeaderStyles,
  TableItemStyles,
  TableStyles,
} from '../../../../../../SharedStyles';
import Pagination from '../../../../../../shared/Pagination/Pagination';
import { useSortAndReverse } from '../../../../../../shared/hooks/useSortAndReverse';
import { usePagination } from '../../../../../../shared/hooks/usePagination';

import { NYSERDAHeaderConvert } from '../../../../../Chargers/helpers';

//context
import NYSERDAReportItem from './NYSERDAReportItem';

const NYSERDAReportList = (props) => {
  const { strings } = CustomIncentiveService;
  const { filters, loading, data } = props;

  const [paginationSessions, setPaginationSessions] = useState([]);

  const updateSortedItems = (items) => {
    setPaginationSessions(items);
  };

  const tableHeader = strings.NYSERDAHeaderTable;
  const tableHeaderConvertor = NYSERDAHeaderConvert;

  const [isActiveSort, sortReverse, changeActiveSort] = useSortAndReverse(
    'SessionStartDate',
    data,
    tableHeader,
    tableHeaderConvertor,
    updateSortedItems,
    'desc'
  );

  /**
   * pagination for the data
   * @param {*} dataArray - items that will be sorted
   * @param {*} itemsPerPage - Custom items limit per page
   */
  const {
    next,
    prev,
    jump,
    currentData,
    currentPage,
    maxPage,
    totalData,
    currentCount,
    pageNumbers,
  } = usePagination(paginationSessions, 20);

  const tableMaxItemSize = (title) => {
    switch (true) {
      default:
        return '82px';
    }
  };

  const tableMinItemSize = (title) => {
    switch (true) {
      case title.includes('PeakPower'):
        return '82px';
      default:
        return '82px';
    }
  };

  return (
    <>
      <Pagination
        currentCount={currentCount}
        totalData={totalData}
        next={next}
        prev={prev}
        jump={jump}
        currentPage={currentPage}
        maxPage={maxPage}
        pageNumbers={pageNumbers}
      />
      <TableStyles minHeight='500px' maxHeight='500px'>
        <TableHeaderStyles
          borderTop='none'
          borderLeft='none'
          borderRight='none'
          width='100%'
          minWidth='1160px'>
          {tableHeader.map((title, i) => {
            return (
              <TableItemStyles
                width='100%'
                minWidth={tableMinItemSize(title)}
                maxWidth={tableMaxItemSize(title)}
                borderRight
                key={i}
                onClick={() => {
                  changeActiveSort(tableHeaderConvertor[title]);
                }}>
                {isActiveSort[title] && (
                  <DivStyles
                    position='absolute'
                    left='5px'
                    top='45%'
                    transform='translate(0, -55%)'>
                    {sortReverse[title] ? (
                      <FaLongArrowAltDown />
                    ) : (
                      <FaLongArrowAltUp />
                    )}{' '}
                  </DivStyles>
                )}
                {title}
              </TableItemStyles>
            );
          })}
        </TableHeaderStyles>
        {currentData().map((session, i) => (
          <NYSERDAReportItem key={i} session={session} />
        ))}
        {data.length === 0 && !loading && (
          <ListPlaceholderStyles borderBottom='none'>
            <p>{strings.noResults}</p>
          </ListPlaceholderStyles>
        )}
      </TableStyles>
    </>
  );
};

export default NYSERDAReportList;
