import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  FaCircle,
  FaRegClock,
  FaCut,
  FaInfoCircle,
  FaDirections,
  FaCalendarCheck,
  FaHeart,
  FaRegHeart,
  FaRegBell
} from 'react-icons/fa';

//services
import AppChargerCardService from './AppChargerCardService';
import AppFindChargerService from '../FindCharger/AppFindChargerService';
import AppChargingService from '../AppCharging/AppChargingService';

//modals
import WaitModal from '../FindCharger/Favorites/modals/WaitModal';
import ReservationModal from '../FindCharger/Favorites/modals/ReservationModal';
import CouponModal from '../FindCharger/Favorites/modals/CouponModal';
import ConfirmRemove from '../FindCharger/Favorites/modals/ConfirmRemove';
import PropertyContainer from '../../shared/PropertyContainer/PropertyContainer';
import DriverMessageModal from '../FindCharger/Favorites/modals/DriverMessageModal';

//global components
import ConnectorImages from '../../shared/ConnectorImages/ConnectorImages';
import Button from '../../shared/Buttons/Button';

//context
import { CouponContext } from '../../context/driver/addCoupon';
import { DriverContext } from '../../context/driver/getDriverContext';
import { VehiclesContext } from '../../context/driver/getVehiclesContext';
import { ErrorContext } from '../../context/shared/ErrorContext';

//styles
import {
  ChargerCardStyles,
  ChargerCardHeaderStyles,
  ChargerCardAddressStyles,
  ChargerCardPaneStyles,
  ChargerCardIconStyles,
} from '../../DriverStyles';
import { PropertiesContentContainerStyles } from '../../SharedStyles';

import '../FindCharger/Favorites/Favorites.scss';

//helpers
import {
  splitDistance,
  getChargerStatusColor,
  calculateMilesKwh,
  converHoursAndMins,
} from '../../shared/Helpers';

const ChargerCard = (props) => {
  const { strings } = AppFindChargerService;
  const { coupon } = useContext(CouponContext);
  const { driver } = useContext(DriverContext);
  const { setError } = useContext(ErrorContext);
  const { defaultVehicle } = useContext(VehiclesContext);
  const { charger, linkedCharger } = props;
  const [modals, setModals] = useState({
    waitQueue: false,
    reservationModal: false,
    couponModal: false,
    showConfirm: false,
    driverMessageModal: false,
  });
  const [inWaitQueue, setInWaitQueue] = useState(false);
  const [userHasReservation, setUserHasReservation] = useState(false);
  const [userHasActiveQueue, setUserHasActiveQueue] = useState(false);
  const [cardCharger, setCardCharger] = useState({});
  const [increaseInterval, setIncreaseInterval] = useState(false);
  // AppPlatformService.debug('InWaitQueue' + inWaitQueue);
  // AppPlatformService.debug('Has reserve' + userHasReservation);

  //incoming Charger and Linked Driver Charger merged to one
  useEffect(() => {
    let incomingCharger = props.charger;

    // Checking if Linked Charger from Favorites List is passed in and combines to one object here.
    if (linkedCharger) {
      if (linkedCharger.home) {
        incomingCharger.home = linkedCharger.home;
      } else {
        incomingCharger.home = false;
      }
      // Checking Favorites List vs single Favorite
      // Assigns status by favorites list entry record - Favorite List
      if (linkedCharger.assigned) {
        incomingCharger.assigned = linkedCharger.assigned;
      } else {
        incomingCharger.assigned = false;
      }
      // Checking Favorites List vs single Favorite
      // Assigns status by favorites list entry record - Favorite List
      if (linkedCharger.status) {
        incomingCharger.statusDescription = linkedCharger.status;
      } else {
        // Status from search charger
        if (props.searchCard) {
          incomingCharger.statusDescription =
            props.charger.chargerConnectedStatus;
        } else {
          // Assigns by the charging station object entry  - Single
          incomingCharger.statusDescription = props.charger.statusDescription;
        }
      }
      // Checking Favorites List vs single Favorite
      // Assigns status by favorites list entry record - Favorite List
      if (linkedCharger.subscriptionEnabled) {
        incomingCharger.enabledSubscription = linkedCharger.subscriptionEnabled;
      } else {
        // Assigns by the charging station object entry  - Single
        incomingCharger.enabledSubscription = props.charger.enabledSubscription;
      }
    } else {
      incomingCharger.home = false;
      incomingCharger.assigned = false;
      incomingCharger.statusDescription = props.charger.status;
      incomingCharger.enabledSubscription = props.charger.enabledSubscription;
    }

    //setCardCharger(incomingCharger);

    getChargerStatus(incomingCharger).then((charger) => {
      setCardCharger(charger);
    });

    let apiCounter = 0;
    //Inital call interval for repeated called
    const fetchingCharger = setInterval(
      async () => {
        apiCounter = ++apiCounter;
        if (apiCounter === 30) {
          setIncreaseInterval(true);
        }

        getChargerStatus(incomingCharger).then((charger) => {
          setCardCharger(charger);
        });
      },
      increaseInterval ? 600000 : 30000
    );
    return () => {
      clearInterval(fetchingCharger);
    };

    // eslint-disable-next-line
  }, [props.charger, props.linkedCharger]);

  let inWaitQueueCookie = JSON.parse(localStorage.getItem('inWaitQueue'));
  let hasReservation = JSON.parse(localStorage.getItem('hasReservation'));

  useEffect(() => {
    if (Object.keys(cardCharger).length > 0) {
      checkSvgStatus(cardCharger.iotHubDeviceId);
    }  
  }, [cardCharger, inWaitQueueCookie]);

  const handleRemoveChargerFavorite = async (charger) => {
    props.handleRemoveFavorite(charger.id);
    let chargerUpdated = {
      iotHubDeviceId: charger.iotHubDeviceId,
      favorite: !charger.favorite,
      home: charger.home,
    };
    await AppFindChargerService.linkDriverCharger(chargerUpdated);
  };

  const showConfirmRemove = () => {
    setModals({ ...modals, showConfirm: !modals.showConfirm });
  };

  let chargerStatus;

  /* Search Card from maps */
  if (props.searchCard) {
    chargerStatus = getChargerStatusColor(cardCharger.chargerConnectedStatus);
  } else {
    chargerStatus = getChargerStatusColor(cardCharger.statusDescription);
  }

  let actionsPane;
  let googleMapsLink;

  if (Object.keys(cardCharger).length > 0) {
    googleMapsLink = `https://maps.google.com/?q=${cardCharger.location.coordinates[0]},${cardCharger.location.coordinates[1]}`;
  }

  const handleModal = (modal) => {
    if (inWaitQueueCookie && inWaitQueueCookie.inQueue) {
      setUserHasActiveQueue(true);
    }
    setModals({ ...modals, [modal]: !modals[modal] });
  };

  //turns modal svgs Red if cookie is set
  //from AppChargingContent
  const checkSvgStatus = async (iotHubDeviceId) => {
    // checking reservation exist and have expired
    if (hasReservation) {
      if (hasReservation.iotHubDeviceId === cardCharger.iotHubDeviceId) {
        setUserHasReservation(true);

        const checkingReservation = await AppChargerCardService.reservationGet(
          cardCharger.iotHubDeviceId
        );

        console.log('CHECKING RESERVE', checkingReservation);

        //removing local storage if it has passed and expired
        if (checkingReservation) {
          let userReservation = checkingReservation.findIndex((r) => {
            return r.driverId === driver.userId;
          });
          console.log('USER RESERVER', userReservation);
          if (userReservation === -1) {
            localStorage.removeItem('hasReservation');
            setUserHasReservation(false);
          }
        }

        if (!checkingReservation) {
          localStorage.removeItem('hasReservation');
          setUserHasReservation(false);
        }
      }
    }

    //checking wait queue for expired
    if (inWaitQueueCookie) {
      let position;
      if (inWaitQueueCookie.iotHubDeviceId === iotHubDeviceId) {
        setInWaitQueue(true);
        position = await AppChargerCardService.waitQueueReady(
          inWaitQueueCookie.iotHubDeviceId
        );
      }

      //removing it has expired.
      if (position && position.positionInQueue === -1) {
        setError({
          display: true,
          title: 'Wait Queue Expired',
          message: 'Your wait queue grace period for charger ID ' + iotHubDeviceId 
            + ' has expired. You may re-join the wait queue at any time if the charging station is occupied.',
          styles: 'driver',
        });
        localStorage.removeItem('inWaitQueue');
        setInWaitQueue(false);
      }
    }
  };

  const checkingWaitQueue = (status) => {
    setInWaitQueue(status);
  };

  const handleUserInActiveQueue = (boo) => {
    setUserHasActiveQueue(boo);
  };

  const getChargerStatus = async (incomingCharger) => {
    try {
      let chargerStatus = await AppChargingService.getChargingStationStatus(incomingCharger.iotHubDeviceId);

      let tmpCharger = { ...incomingCharger, ...chargerStatus };
      //tmpCharger.statusDescription = chargerStatus.statusDescription;
      if (tmpCharger.allowWaitQueue) {
        let position = await AppChargerCardService.waitQueueReady(incomingCharger.iotHubDeviceId);
        tmpCharger = { ...tmpCharger, position };
      }
      return tmpCharger;
    } catch (err) {
      setError({
        display: true,
        title: 'Something went wrong',
        message:
          'Unable to retrieve your favorite chargers at this current time.',
        styles: 'driver',
      });
    }
  }

  if (props.details) {
    actionsPane = (
      <ChargerCardPaneStyles>
        {cardCharger?.lastMessage ? (
          <ChargerCardIconStyles>
            <FaRegBell
              className='actions-icon'
              size={30}
              onClick={() => handleModal('driverMessageModal')}
            />
          </ChargerCardIconStyles>
        ) : (
          <ChargerCardIconStyles />
        )}
        {!(cardCharger.home || cardCharger.assigned) &&
          cardCharger.enabledSubscription && (
            <>
              {cardCharger.allowWaitQueue ? (
                <ChargerCardIconStyles
                  color={
                    inWaitQueueCookie &&
                    inWaitQueueCookie.iotHubDeviceId ===
                      cardCharger.iotHubDeviceId
                      ? 'red'
                      : null
                  }>
                  <FaRegClock
                    className='actions-icon'
                    size={30}
                    onClick={() => handleModal('waitQueue')}
                  />
                  {cardCharger.position && cardCharger.position.positionInQueue >= 0 && (
                    <span>
                      &nbsp;{cardCharger.position.positionInQueue + 1}
                    </span>
                  )}
                </ChargerCardIconStyles>
              ) : (
                <ChargerCardIconStyles />
              )}
              {cardCharger.allowReservations ? (
                <ChargerCardIconStyles
                  color={
                    hasReservation &&
                    hasReservation.iotHubDeviceId === cardCharger.iotHubDeviceId
                      ? 'red'
                      : null
                  }>
                  <FaCalendarCheck
                    className='actions-icon'
                    size={30}
                    onClick={() => handleModal('reservationModal')}
                  />
                </ChargerCardIconStyles>
              ) : (
                <ChargerCardIconStyles />
              )}
            </>
          )}
        <Button
          driver
          margin='0'
          redirect={`/charging/${cardCharger.iotHubDeviceId}`}
          buttonText={strings.button}
        />
        {!(cardCharger.home || cardCharger.assigned) &&
          cardCharger.enabledSubscription && (
            <>
              {cardCharger.allowCoupon ? (
                <ChargerCardIconStyles
                  color={
                    coupon &&
                    coupon.iotHubDeviceId === cardCharger.iotHubDeviceId
                  }>
                  <FaCut
                    className='actions-icon'
                    size={30}
                    onClick={() => handleModal('couponModal')}
                  />
                </ChargerCardIconStyles>
              ) : (
                <ChargerCardIconStyles />
              )}
              {cardCharger.home ? (
                <ChargerCardIconStyles />
              ) : (
                <ChargerCardIconStyles>
                  <a
                    href={googleMapsLink}
                    target='_blank'
                    rel='noopener noreferrer'>
                    <FaDirections className='actions-icon' size={30} />
                  </a>
                </ChargerCardIconStyles>
              )}
            </>
          )}
        {modals.waitQueue && (
          <WaitModal
            handleFavoriteModal={handleModal}
            charger={cardCharger}
            checkingWaitQueue={checkingWaitQueue}
            hasActiveQueue={userHasActiveQueue}
            handleUserInActiveQueue={handleUserInActiveQueue}
          />
        )}
        {modals.reservationModal && (
          <ReservationModal
            handleModalState={handleModal}
            charger={cardCharger}
          />
        )}
        {modals.couponModal && (
          <CouponModal
            charger={cardCharger}
            coupon={coupon}
            handleModalState={handleModal}
          />
        )}
        {modals.driverMessageModal && (
          <DriverMessageModal
            lastMessage={cardCharger.lastMessage}
            closeModal={() => handleModal('driverMessageModal')}
            setAsRead={() => setCardCharger({ ...cardCharger, lastMessage: null })}
          />
        )}
      </ChargerCardPaneStyles>
    );
  } else {
    actionsPane = (
      <ChargerCardPaneStyles>
        {cardCharger?.lastMessage ? (
          <ChargerCardIconStyles>
            <FaRegBell
              className='actions-icon'
              size={30}
              onClick={() => handleModal('driverMessageModal')}
            />
          </ChargerCardIconStyles>
        ) : (
          <ChargerCardIconStyles />
        )}      
        {!(cardCharger.home || cardCharger.assigned) &&
        cardCharger.enabledSubscription ? (
          <>
            {cardCharger.allowWaitQueue ? (
              <ChargerCardIconStyles
                color={
                  inWaitQueueCookie &&
                  inWaitQueueCookie.iotHubDeviceId ===
                    cardCharger.iotHubDeviceId
                    ? 'red'
                    : null
                }>
                <FaRegClock
                  className='actions-icon'
                  size={30}
                  onClick={() => handleModal('waitQueue')}
                />
                {cardCharger.position && cardCharger.position.positionInQueue >= 0 && (
                  <span>
                    &nbsp;{cardCharger.position.positionInQueue + 1}
                  </span>
                )}
              </ChargerCardIconStyles>
            ) : (
              <ChargerCardIconStyles />
            )}
            {cardCharger.allowReservations ? (
              <ChargerCardIconStyles
                color={
                  hasReservation &&
                  hasReservation.iotHubDeviceId === cardCharger.iotHubDeviceId
                    ? 'red'
                    : null
                }>
                <FaCalendarCheck
                  className='actions-icon'
                  size={30}
                  onClick={() => handleModal('reservationModal')}
                />
              </ChargerCardIconStyles>
            ) : (
              <ChargerCardIconStyles />
            )}
          </>
        ) : (
          <>
            <ChargerCardIconStyles />
            <ChargerCardIconStyles />
          </>
        )}
        <Button
          driver
          margin='0'
          redirect={`/charging/${cardCharger.iotHubDeviceId}`}
          buttonText={strings.button}
        />
        {!(cardCharger.home || cardCharger.assigned) &&
        cardCharger.enabledSubscription ? (
          <>
            {cardCharger.allowCoupon ? (
              <ChargerCardIconStyles
                color={coupon && coupon.completed && 'red'}>
                <FaCut
                  className='actions-icon'
                  size={30}
                  onClick={() => handleModal('couponModal')}
                />
              </ChargerCardIconStyles>
            ) : (
              <ChargerCardIconStyles />
            )}
          </>
        ) : (
          <ChargerCardIconStyles />
        )}
        {cardCharger.dead ||
        cardCharger.home ||
        cardCharger.assigned ||
        !cardCharger.enabledSubscription ? (
          <ChargerCardIconStyles />
        ) : (
          <ChargerCardIconStyles>
            <Link to={`/charger-details/${cardCharger.iotHubDeviceId}`}>
              <FaInfoCircle className='actions-icon' size={30} />
            </Link>
          </ChargerCardIconStyles>
        )}
        {modals.waitQueue && (
          <WaitModal
            handleFavoriteModal={handleModal}
            charger={cardCharger}
            checkingWaitQueue={checkingWaitQueue}
            hasActiveQueue={userHasActiveQueue}
            handleUserInActiveQueue={handleUserInActiveQueue}
          />
        )}
        {modals.reservationModal && (
          <ReservationModal
            handleModalState={handleModal}
            charger={cardCharger}
          />
        )}
        {modals.couponModal && (
          <CouponModal charger={cardCharger} handleModalState={handleModal} />
        )}
        {modals.driverMessageModal && (
          <DriverMessageModal
            lastMessage={cardCharger.lastMessage}
            closeModal={() => handleModal('driverMessageModal')}
            setAsRead={() => setCardCharger({ ...cardCharger, lastMessage: null })}
          />
        )}        
      </ChargerCardPaneStyles>
    );
  }

  return (
    <ChargerCardStyles>
      {modals.showConfirm && (
        <ConfirmRemove
          removefav={handleRemoveChargerFavorite}
          charger={cardCharger}
          cancelModal={showConfirmRemove}
        />
      )}
      <ChargerCardHeaderStyles
        titleColor={cardCharger.home && '#f60'}
        statusColor={chargerStatus}>
        <span className='Title'>
          {cardCharger.chargerName}{' '}
          {cardCharger.home && `- ${strings.ownedCharger}`}
          {cardCharger.assigned && `- ${strings.assignedCharger}`}
        </span>
        <div className='Action'>
          {/* Non map Cards*/}
          {!props.searchCard &&
            !props.details &&
            !cardCharger.home &&
            !cardCharger.assigned && (
              <FaHeart className='Heart' onClick={showConfirmRemove} />
            )}
          {/* Search Card from maps */}
          {props.searchCard &&
            (!props.details && cardCharger.favorite ? (
              <FaHeart
                className='Heart'
                onClick={() => props.togglefavorites(cardCharger)}
              />
            ) : (
              <FaRegHeart
                className='Heart'
                onClick={() => props.togglefavorites(cardCharger)}
              />
            ))}
          <FaCircle
            style={{ color: chargerStatus, borderRadius: '50%' }}
            className={`Status ${chargerStatus}`}
          />
        </div>
      </ChargerCardHeaderStyles>
      <ChargerCardAddressStyles>
        <span className='Distance'>
          {Object.keys(cardCharger).length > 0 && (
            <>
              {splitDistance(driver.unitOfMeasurement, cardCharger.distance)}{' '}
              {converHoursAndMins(cardCharger.duration)}
            </>
          )}
        </span>{' '}
        <span className='Address'>{cardCharger.chargerAddress}</span>
      </ChargerCardAddressStyles>
      <PropertiesContentContainerStyles flexWrap='nowrap' margin='0'>
        <PropertyContainer
          title={strings.power}
          content={`${cardCharger.power} ${strings.kw}`}
        />
        <PropertyContainer
          title={
            driver.unitOfMeasurement === 'Miles'
              ? strings.mileage
              : strings.kiloM
          }
          content={
            defaultVehicle && Object.keys(defaultVehicle).length > 0
              ? calculateMilesKwh(
                  defaultVehicle,
                  cardCharger,
                  driver.unitOfMeasurement
                )
              : '--'
          }
        />
        {Object.keys(cardCharger).length > 0 && (
          <PropertyContainer
            title={strings.conn}
            content={<ConnectorImages charger={cardCharger} />}
          />
        )}
        <PropertyContainer
          title={
            !cardCharger.home
              ? charger.membershipId
                ? strings.memberRate
                : strings.rate
              : strings.utility
          }
          content={
            !cardCharger.home
              ? cardCharger.dead
                ? '--'
                : cardCharger.rateType === 'Free'
                ? strings.free
                : `${cardCharger.rate}/${cardCharger.rateType}`
              : `${
                  driver.homeChargerRate
                    ? `$${parseFloat(driver.homeChargerRate).toFixed(2)}`
                    : '$0'
                }/kWh`
          }
        />
      </PropertiesContentContainerStyles>
      {!cardCharger.dead ? (
        actionsPane
      ) : (
        <ChargerCardPaneStyles>
          <p className='danger-text'>
            <em>{strings.deadCharger}</em>
          </p>
        </ChargerCardPaneStyles>
      )}
    </ChargerCardStyles>
  );
};

export default ChargerCard;
