import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppLeaveGuardService = {
  strings: localStrings,
};

export default AppLeaveGuardService;
