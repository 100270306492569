import React, { createContext, useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';

//services
import ChargingSessionService from '../../operator/Reports/Details/Shared/ChargingSessions/ChargingSessionService';

//context
import { ErrorContext } from '../shared/ErrorContext';
import { CsoChargerContext } from './getCsoChargers';

export const ReportContext = createContext();

const Reports = (props) => {
  const { setError } = useContext(ErrorContext);
  const { loading } = useContext(CsoChargerContext);
  const [sessionsContext, setSessionsContext] = useState([]);
  const [reportLoading, setReportLoading] = useState(true);

  const fetchChargingSessions = async () => {
    try {
      let allSessions = await ChargingSessionService.getCSOSessions();
      let sortedSessions = allSessions.sort(
        (a, b) => new Date(b.transactionDate) - new Date(a.transactionDate)
      );
      setSessionsContext(sortedSessions);
      setReportLoading(false);
    } catch (err) {
      setError({
        display: true,
        title: 'Something went wrong',
        message: 'Could not gather your charging sessions at this time.',
        styles: 'cso',
      });
    }
  };

  useEffect(() => {
    //Charger loading takes the longest since we talk to the device twin
    //once it loads, we will then trigger stop report loading.
    if (!loading) {
      fetchChargingSessions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <ReportContext.Provider
      value={{
        sessionsContext,
        setSessionsContext,
        reportLoading,
        setReportLoading,
      }}>
      {props.children}
    </ReportContext.Provider>
  );
};

export default withRouter(Reports);
