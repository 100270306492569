import React from 'react';

//services
import AppImagingService from '../../../../services/AppImaging/AppImagingService';
import AppFindChargerService from '../AppFindChargerService';

//hooks
import { useRouter } from '../../../shared/hooks/useRouter';

//components
import ScannerInside from './ScannerInside';

const Scanner = (props) => {
  const { scannerInstructions } = AppFindChargerService.strings;

  const router = useRouter();

  const scanImage = () => {
    AppImagingService.getFromScanner(onScanSuccess, onScanError);
  };

  const onScanSuccess = (data) => {
    if (data.text && typeof data.text === 'string' && data.text.length > 0) {
      props.handleRouteChange(data.text);
    } else {
      // On Android phones where we have a back button, we need to push '/' to the history stack
      // to keep the app from navigating back to a previous page.
      router.history.push('/');
    }
  };

  const onScanError = (error) => {
    console.log(error);
  };

  const scanner =
    window.cordova !== undefined ? (
      <button className='app-button' onClick={scanImage}>
        {scannerInstructions}
      </button>
    ) : (
      <ScannerInside />
    );

  return <div className='scannerWrapper'>{scanner}</div>;
};

export default Scanner;
