import React from 'react';
import { Link } from 'react-router-dom';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppDiscountSchedulesService from './../AppDiscountSchedulesService';

//hooks
import { useRouter } from '../../../shared/hooks/useRouter';

//global component
import ModalButton from '../../../shared/Buttons/ModalButton';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
} from '../../../SharedStyles';

const UpgradeModal = (props) => {
  const { strings } = AppDiscountSchedulesService;
  const { handleCloseModal, subscriptionLevel } = props;
  const router = useRouter();

  const closeModal = () => {
    handleCloseModal();
  };

  const redirectModal = () => {
    router.history.push('/subscription/discount-schedules');
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.smallBizDeny}</h2>
          <FaRegWindowClose
            className='Close'
            onClick={props.redirect ? redirectModal : closeModal}
          />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <p>
              {subscriptionLevel === 1 ? strings.sharedDenyMessageOne : strings.smallBizDenyMessageOne}
              <Link to='/subscription'>{strings.enterpriseUpgrade}</Link>{' '}
              {strings.smallBizDenyMessageTwo}
            </p>
          </ModalItemStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={props.redirect ? redirectModal : closeModal}
          buttonText={strings.ok}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default UpgradeModal;
