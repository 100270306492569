import styled from 'styled-components';

export const RowStyles = styled.div`
  width: ${({ width }) => width};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? borderBottom : '1px solid #e7e7e7'};
  padding: ${({ padding }) => (padding ? padding : '0.5em')};
  display: ${({ display }) => (display ? display : 'flex')};
  align-items: center;
  font-size: ${({ size }) => (size ? size : '14px')};
  text-align: ${({ align }) => align};
  flex: ${({ flex }) => flex};
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    font-size: 12px;
  }
  .Title {
    text-align: ${({ titleAlign }) => titleAlign};
    color: ${({ theme }) => theme.color};
    font-size: ${({ headerSize }) => (headerSize ? headerSize : '18px')};
    font-weight: ${({ headerWeight }) => headerWeight};
    margin: ${({ headerMargin }) => (headerMargin ? headerMargin : '0')};
  }
  .Details-Icon {
    flex: 20px;
    svg {
      color: #8497b6;
      display: block;
      font-size: 20px;
      margin-right: 10px;
    }
  }
  .Details-Content {
    color: #2c456c;
    flex: 100%;
  }
  .Arrows {
    width: 300px;
    margin: 0 auto;
    font-size: 30px;
    color: #2c456c;
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
      width: auto;
    }
    button {
      background: none;
      border: none;
      outline: none;
      flex: 1;
      color: #2c456c;
      cursor: pointer;
      &:disabled {
        color: lightgrey;
        cursor: not-allowed;
      }
    }
  }
`;
