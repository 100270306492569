import React from 'react';

//services
import AddressService from './AddressService';

//styles
import './Address.scss';

//helpers
import states from './states';
import { FormRowStyles, InputStyles, SelectStyles } from '../../SharedStyles';

const Address = (props) => {
  const { driver } = props;

  const { strings } = AddressService;

  const updateDriver = (e) => {
    props.onChange(e);
  };

  return (
    <>
      <FormRowStyles>
        <InputStyles
          flexWidth='50%'
          margin='0 10px'
          type='text'
          name='address1'
          placeholder={strings.address1}
          value={driver.address1 || ''}
          onChange={updateDriver}
          required
        />
      </FormRowStyles>
      <FormRowStyles>
        <InputStyles
          flexWidth='50%'
          margin='0 10px'
          type='text'
          name='address2'
          placeholder={strings.address2}
          value={driver.address2 || ''}
          onChange={updateDriver}
        />
        <div className='Form-Input' />
      </FormRowStyles>
      <FormRowStyles>
        <InputStyles
          flexWidth='50%'
          margin='0 10px'
          type='text'
          name='city'
          placeholder={strings.city}
          value={driver.city || ''}
          onChange={updateDriver}
          required
        />
        <SelectStyles
          flexWidth='50%'
          margin='0 10px'
          name='state'
          onChange={updateDriver}
          value={driver.state || ''}
          required>
          <option disabled value=''>
            {strings.state}
          </option>
          {states &&
            states.filter(x=>x.country===driver.country).map((h, i) => (
              <option key={i} value={h.abbreviation}>
                {h.name}
              </option>
            ))}
        </SelectStyles>
      </FormRowStyles>
      <FormRowStyles>
        <InputStyles
          flexWidth='50%'
          margin='0 10px'
          type='text'
          name='zip'
          placeholder={strings.zip}
          value={driver.zip || ''}
          onChange={updateDriver}
          required
        />
        <SelectStyles
          flexWidth='50%'
          margin='0 10px'
          name='country'
          onChange={updateDriver}
          value={driver.country || ''}
          required>
          <option value='' disabled>
            {strings.country}
          </option>
          {strings.countries.map((c) => (
            <option key={c}>{c}</option>
          ))}
        </SelectStyles>
      </FormRowStyles>
    </>
  );
};

export default Address;
