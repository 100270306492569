import styled from 'styled-components';

// removedHeight
export const StatementContainerStyle = styled.div`
  /* max-height: ${(props) => props.minHeight}; */
  width: ${(props) => props.width};
  /* overflow-y: scroll; */
  overflow-x: hidden;
  margin: 0 0 30px;
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }

  h2 {
    margin: 25px 10px 0;
    font-size: 19px;
    line-height: 25px;
    text-align: left;
    font-weight: 400;
  }
  .Sub-Section {
    text-align: left;
    padding: 0 10px 10px;
    color: #8f8d8d;
    font-size: 13px;
    border-bottom: 1px solid #e7e7e7;
  }
`;
