import React, { useState, useContext, useEffect } from 'react';

//layout
import {
  OuterWrapper,
  ComponentWrapper,
  SubscriptionAccess,
} from '../../OperatorShared';
import Button from '../../../shared/Buttons/Button';

//services
import AppDiscountSchedulesService from '../AppDiscountSchedulesService';

//components
import FormHeaderComponent from './FormHeaderComponent';
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';
import SmallBizAdmin from '../../SmallBizAdmin';
import CouponComponent from './Coupon/CouponComponent';

//modals
import UpgradeModal from '../DiscountSchedulesModal/UpgradeModal';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import { ErrorContext } from '../../../context/shared/ErrorContext';

const DiscountSchedulesAdd = (props) => {
  const { strings } = AppDiscountSchedulesService;
  const { account, setAccount, currentUser } = useContext(CsoAccountContext);
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(false);
  const [showCoupon, setShowCoupon] = useState(false);
  const [couponLength, setCouponLength] = useState(0);
  const [discountSchedule, setDiscountSchedule] = useState({
    csoAccountId: account.id,
    chargingStations: [],
  });
  const [limitReached, setLimitReached] = useState(false);

  //Read only for non-admins
  let adminPermission;
  if (currentUser) {
    adminPermission = currentUser.role === 1 || currentUser.role === 2;
  }

  const closeReachModal = () => {
    setLimitReached(!limitReached);
  };
  useEffect(() => {
    if (account) {
      if (
        account.subscriptionLevel === 2 &&
        account.discountSchedules.length >= 2
      ) {
        setLoading(false);
        setLimitReached(true);
      }
    }
  }, [account]);

  const handleChange = (name, value) => {
    setDiscountSchedule({
      ...discountSchedule,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (showCoupon) {
        let createdProfile = await AppDiscountSchedulesService.updateDiscountSchedule(
          discountSchedule
        );
        setAccount(createdProfile);
        if (createdProfile) {
          props.history.push('/subscription/discount-schedules');
        }
      } else {
        let createdProfile = await AppDiscountSchedulesService.createDiscountSchedule(
          discountSchedule
        );
        if (createdProfile) {
          setAccount(createdProfile);
          setDiscountSchedule((d) => ({
            ...d,
            ...createdProfile.discountSchedules[
              createdProfile.discountSchedules.length - 1
            ],
          }));
        }
        if (createdProfile) {
          setShowCoupon(true);
          setLoading(false);
        }
        if (account && account.subscriptionLevel === 2) {
          props.history.push('/subscription/discount-schedules');
        }
      }
    } catch (err) {
      if (err.response) {
        setLoading(false);
        setError({
          display: true,
          title: strings.invalidTitle,
          message: err.response.data.split(/[|]+/).pop(),
          styles: 'cso',
        });
      }
    }
  };

  const couponCounter = (num) => {
    setCouponLength(couponLength + num);
  };

  return (
    <OuterWrapper
      title={
        adminPermission
          ? strings.addTitle
          : `${strings.addTitle} ${strings.readOnly}`
      }
      internal
      path='/subscription/discount-schedules'
      roles={[1, 2]}
      myForm='myForm'>
      {loading && <LoadingSpinner />}
      <SmallBizAdmin allowCustomer>
        {limitReached && (
          <UpgradeModal redirect handleCloseModal={closeReachModal} />
        )}
        <ComponentWrapper roles={[1, 2]} title={strings.addTitle}>
          <FormHeaderComponent
            editSx={showCoupon}
            account={account}
            profile={discountSchedule}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            couponCounter={couponCounter}
          />
          <SubscriptionAccess levels={[3]}>
            {showCoupon && (
              <CouponComponent
                profile={discountSchedule}
                strings={strings}
                couponLength={couponLength}
              />
            )}
          </SubscriptionAccess>
        </ComponentWrapper>
        <Button
          cso
          hasMobile
          formSubmit
          buttonText={strings.save}
          roles={[1, 2]}
        />
      </SmallBizAdmin>
    </OuterWrapper>
  );
};

export default DiscountSchedulesAdd;
