import React, { useState, useEffect, useContext } from 'react';

//layout
import { OuterWrapper, ComponentWrapper } from '../../OperatorShared';
import Button from '../../../shared/Buttons/Button';

//services
import AppMembershipsService from '../AppMembershipsService';

//components
import MembershipChargers from './MembershipsOptions/MembershipChargers';
import MembershipOptions from './MembershipsOptions/MembershipOptions';
import MembershipMessage from './MembershipsOptions/MembershipMessage';
import MembershipHeader from './MembershipHeader';

//global components
import SubscriptionAccess from '../../OperatorShared/SubscriptionAccess';
import AccessDeniedComponent from '../../../shared/AppAccessDenied/AccessDeniedComponent';
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';
import CsoNotFound from '../../../shared/AppNotFound/CsoNotFound';
import ToggleSwitch from '../../../driver/shared/ToggleSwitch';

//modals
import EditModal from '../MembershipsModals/EditModal';

//context
import { ErrorContext } from '../../../context/shared/ErrorContext';
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import { CsoMembershipContext } from '../../../context/operator/getCsoMembership';

//styles
import { FormStyles, TitleStyles } from '../../../SharedStyles';
import { EnableContainerStyles } from '../../../OperatorStyles';

//error Class
import { ErrorResponse } from '../../../shared/ErrorResponse';

const MembershipEdit = (props) => {
  const { setError } = useContext(ErrorContext);
  const { account, currentUser } = useContext(CsoAccountContext);
  const { fetchMemberships } = useContext(CsoMembershipContext);
  const [loading, setLoading] = useState(true);
  const [membership, setMembership] = useState({});

  const [toggleModals, setToggleModals] = useState({
    deleteModal: false,
    editModal: false,
  });
  const [notFound, setNotFound] = useState({
    display: false,
    message: '',
  });

  const { strings } = AppMembershipsService;

  useEffect(() => {
    const fetchingMembership = async () => {
      try {
        const { id } = props.match.params;
        let currentMembership = await AppMembershipsService.getMembershipById(
          id
        );
        if (account && 
            (account.subscriptionLevel === 1 || account.subscriptionLevel === 2)) { //SmallBusiness-2, Share-1
          currentMembership.visibility = 2; //PublicWithApproval-2
        }
        if (currentMembership.id) {
          setMembership((prevMembership) => {
            let membershipIndex = account.discountSchedules.findIndex(
              (sx) => sx.id === currentMembership.discountScheduleId
            );
            if (membershipIndex < 0) {
              currentMembership.discountScheduleId = null;
            }
            return { ...prevMembership, ...currentMembership };
          });
          setLoading(false);
        } else {
          setNotFound({
            display: true,
            message: 'Could not find a membership with the request Id',
          });
          setLoading(false);
        }
      } catch (err) {
        props.history.push('/404');
      }
    };
    fetchingMembership();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleModal = (e, value) => {
    if (value === 'editModal') {
      setToggleModals({ ...toggleModals, editModal: !toggleModals.editModal });
    } else if (value === 'deleteModal') {
      setToggleModals({
        ...toggleModals,
        deleteModal: !toggleModals.deleteModal,
      });
    } else {
      setToggleModals({
        ...toggleModals,
        deleteModal: false,
        editModal: false,
      });
    }
  };

  const handleDataChange = (updatedMembership) => {
    setMembership({
      ...membership,
      ...updatedMembership,
    });
  };

  const handleEnableToggle = (e) => {
    setMembership({ ...membership, enabled: !membership.enabled });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (membership.enabled && !membership.discountScheduleId) {
        throw new ErrorResponse(
          'Frontend',
          'You must have a discount applied to enable the membership.',
          'Failed Save'
        );
      }

      let data = await AppMembershipsService.updateMembership(membership);
      setMembership(data);

      if (data) {
        props.history.push('/subscription/memberships');
      }
    } catch (err) {
      setLoading(false);
      if (err.name === 'Frontend') {
        setError({
          display: true,
          title: err.title,
          message: err.message,
          styles: 'cso',
        });
      }
    }
  };

  const removeMembership = async () => {
    setLoading(true);
    await fetchMemberships();
  };

  if (notFound.display) {
    return <CsoNotFound message={notFound.message} />;
  }

  return (
    <OuterWrapper
      title={strings.editMembership}
      internal
      myForm
      path='/subscription/memberships'
      roles={[1, 2, 4]}>
      {loading && <LoadingSpinner />}
      {toggleModals.editModal && (
        <EditModal
          membership={membership}
          handleChangeModal={handleDataChange}
          toggleModal={toggleModal}
          title={strings.membershipNameLogo}
          content={strings.membershipModalContent}
        />
      )}
      {toggleModals.deleteModal && (
        <EditModal
          deleteModal
          membership={membership}
          toggleModal={toggleModal}
          removeMembership={removeMembership}
          title={strings.membershipDelete}
          content={strings.membershipDeleteMessage}
          button={strings.membershipDeleteButton}
        />
      )}
      {/* =====================Enterprise and Small Biz Only===================================== */}
      {/*<SmallBizAdmin allowCustomer>*/}
      <SubscriptionAccess levels={[1, 2, 3]}>
      {(account &&
          (account.subscriptionLevel === 3 &&
            currentUser &&
            currentUser.role !== 3)) ||
        (account && account.subscriptionLevel === 2) ||
        (account && account.subscriptionLevel === 1) ? (
          <>
            <ComponentWrapper title={strings.editMembership}>
              <FormStyles display='block' id='myForm' onSubmit={handleSubmit}>
                {Object.keys(membership).length > 0 && (
                  <MembershipHeader
                    strings={strings}
                    membership={membership}
                    edit={(e) => toggleModal(e, 'editModal')}
                  />
                )}
                <EnableContainerStyles>
                  <TitleStyles size='18px' margin='0' weight='500'>
                    {strings.enableMembership}
                  </TitleStyles>
                  <ToggleSwitch
                    name='enabled'
                    checked={!!membership.enabled}
                    handleChange={handleEnableToggle}
                  />
                </EnableContainerStyles>
                <MembershipChargers
                  editMembership
                  membership={membership}
                  handleDataChange={handleDataChange}
                />
                <MembershipOptions
                  editMembership
                  membership={membership}
                  handleDataChange={handleDataChange}
                />
                <MembershipMessage
                  editMembership
                  membership={membership}
                  handleDataChange={handleDataChange}
                />
              </FormStyles>
            </ComponentWrapper>
            <Button
              cso
              hasMobile
              formSubmit
              buttonText={strings.modalSave}
              roles={[1, 2, 4]}
            />
          </>
        ) : 
          <AccessDeniedComponent
            smallBiz
            permissionIssue={
              account.subscriptionLevel === 3 &&
              currentUser &&
              currentUser.role !== 3
            }
          />
        }
      </SubscriptionAccess>
      {/*</SmallBizAdmin>*/}
      {/* ========================================================================= */}
    </OuterWrapper>
  );
};

export default MembershipEdit;
