import styled from 'styled-components';

export const NavBarContainerStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 10px;
  max-height: 70px;
  @media screen and (max-width: ${({ theme, mobileMedia, isInternal }) => {
      if (isInternal) {
        return mobileMedia ? mobileMedia : theme.defaultMediaQuery;
      } else {
        return '0px';
      }
    }}) {
    display: none;
  }
  .Left-Align {
    display: flex;
    align-items: center;
    flex: 1;
  }
`;

export const LogoContainerStyles = styled.div`
  .FullScreen {
    display: block;
    @media screen and (max-width: ${({ theme, mobileMedia }) =>
        mobileMedia ? mobileMedia : theme.defaultMediaQuery}) {
      display: none;
    }
  }
  .Mobile {
    display: none;
    @media screen and (max-width: ${({ theme, mobileMedia }) =>
        mobileMedia ? mobileMedia : theme.defaultMediaQuery}) {
      display: block;
    }
  }
`;

export const CenterNavStyles = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  @media screen and (max-width: 580px) {
    padding: 0 2px;
  }

  a {
    display: flex;
    align-items: center;
    margin: 0 15px;
    padding: 0 0 4px;
    color: ${({ theme }) => theme.svgColor};
    text-decoration: none;
    display: inherit;
    font-size: 18px;
    border-bottom: 2px solid transparent;
    font-weight: 500;
    @media screen and (max-width: 580px) {
      font-size: 15px;
      padding: 0;
      margin: 0 5px;
    }
    &:hover {
      color: ${({ theme }) => theme.svgHoverColor};
      border-bottom: 2px solid #2c456c;
    }
    &.is-active {
      border-bottom: 2px solid #28456a;
      color: #28456a;
      svg {
        color: #28456a;
      }
    }
  }
  svg {
    margin: 0 10px 0 0;
    @media screen and (max-width: 580px) {
      margin: 0 2px 0 0;
    }
    @media screen and (max-width: 360px) {
      display: none;
    }
  }
`;

export const UserProfileNavStyles = styled.div`
  position: relative;
  color: #a5a5a5;
  cursor: pointer;
  display: flex;
  align-items: center;
  .Toggle {
    display: flex;
    align-items: center;
  }
  .Image-Container {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 10px 0 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media screen and (max-width: 400px) {
      display: none;
    }
  }
  .Account-Container {
    margin: 5px 0 0;
    font-weight: 600;
    font-size: 15px;
    @media screen and (max-width: ${({ theme, mobileMedia }) =>
        mobileMedia ? mobileMedia : theme.defaultMediaQuery}) {
      display: none;
    }
  }
  .Mobile-Menu-Display {
    display: none;
    display: none;
    color: #2c456c;
    height: 25px;
    width: 25px;
    margin-left: 2vw;
    @media screen and (max-width: ${({ theme, mobileMedia }) =>
        mobileMedia ? mobileMedia : theme.defaultMediaQuery}) {
      display: block;
      margin-left: 0;
    }
  }
`;

export const DropDownMenuStyles = styled.div`
  animation: fadein 300ms;
  position: absolute;
  top: 53px;
  right: -2px;
  display: block;
  width: 225px;
  border: 1px solid #c2c2c2;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 #a5a5a5;
  z-index: 20;
  .Menu-Link {
    display: block;
    padding: 13px 10px;
    border-bottom: 1px solid #c2c2c2;
    color: ${({ theme }) => theme.svgColor};
    font-size: 15px;
    text-decoration: none;
    cursor: pointer;
    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    &:hover {
      color: ${({ theme }) => theme.svgHoverColor};
      background-color: #f0f0f0;
      text-decoration: none;
      svg {
        color: ${({ theme }) => theme.svgHoverColor};
      }
    }
    .Menu-Icon {
      font-size: 18px;
      color: ${({ theme }) => theme.svgColor};
      margin: 0 10px 0 0;
    }
    .Spaced {
      display: flex;
      justify-content: space-between;
    }
  }
  .Sub-Menu {
    display: flex;
    flex-wrap: wrap;
    flex: 100%;
    align-items: center;
    border-bottom: 1px solid #c2c2c2;
    .Sub-Menu-Link {
      flex: 100%;
      padding: 10px 10px 10px 20px;
      color: #8999ae;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      font-size: 14px;
      text-decoration: none;
      svg {
        font-size: 18px;
      }
      &:hover {
        color: #28456a;
        background: #f0f0f0;
        text-decoration: none;
      }
      span {
        margin: 0 0 0 10px;
      }
    }
  }
`;

export const MobileNavStyles = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme, mobileMedia }) =>
      mobileMedia ? mobileMedia : theme.defaultMediaQuery}) {
    background-color: ${({ theme }) => theme.defaultInteralNavColor};
    padding: 10px 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    position: fixed;
    z-index: 5000;
    .Mobile-Nav-Container {
      display: inline-flex;
      align-items: center;
      color: #ffffff;
      font-weight: 600;
      .Arrow {
        margin: 0 10px;
        color: #ffffff;
        cursor: pointer;
      }
      .Title {
        color: #ffffff;
        font-weight: 600;
      }
    }
  }
`;

export const MobileSaveStyles = styled.button`
  background: none;
  outline: none;
  border: none;
  color: #ffffff;
  font-weight: 600;
  margin: 0 5px 0 0;
  &:disabled {
    color: #cacaca;
  }
`;
