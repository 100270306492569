import React, { useState, useContext, useEffect, useRef } from 'react';
// import { diff } from 'deep-object-diff';

//layout
import { OuterWrapper, ComponentWrapper } from '../../OperatorShared';
import Button from '../../../shared/Buttons/Button';

//services
import AppMeterGroupsService from '../AppMeterGroupsService';

//components
import MeterGroupDetails from './MeterGroupDetails/MeterGroupDetails';
import MeterGroupOptions from './MeterGroupsOptions';

//global components
import EnterpriseAdmin from '../../EnterpriseAdmin';
import CsoNotFound from '../../../shared/AppNotFound/CsoNotFound';
import ToggleSwitch from '../../OperatorShared/ToggleSwitch/CsoToggleSwitch';
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';
// import RouteLeavingGuard from '../../../shared/LeaveGuard/LeaveGuard';

//modals
import VoltageModal from '../MeterGroupsModals/DisplayModals/VoltageModal';
import MaxAmpsModal from '../MeterGroupsModals/DisplayModals/MaxAmpsModal';
import LoadLimitModal from '../MeterGroupsModals/DisplayModals/LoadLimitModal';
import EditModal from '../MeterGroupsModals/DisplayModals/EditModal';
import DeleteModal from '../MeterGroupsModals/DisplayModals/DeleteModal';
import PhaseModal from '../MeterGroupsModals/DisplayModals/PhaseModal';
import CircuitsModal from '../MeterGroupsModals/DisplayModals/CircuitsModal';
import MissingInfoModal from '../MeterGroupsModals/DisplayModals/MissingInfoModal';

//hooks
import { useRouter } from '../../../shared/hooks/useRouter';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import { ErrorContext } from '../../../context/shared/ErrorContext';

//styles
import { EnableContainerStyles } from '../../../OperatorStyles';
import { TitleStyles } from '../../../SharedStyles';

//helpers
import { checkingValueOfZero } from '../../helpers';
// import { diff } from '../../../shared/ObjDiffCheck';

//error Class
import { ErrorResponse } from '../../../shared/ErrorResponse';
import MeterGroupHeader from './MeterGroupDetails/MeterGroupHeader';

const MeterGroupsEdit = (props) => {
  const { strings } = AppMeterGroupsService;
  const { account, setAccount } = useContext(CsoAccountContext);
  const { setError } = useContext(ErrorContext);
  const router = useRouter();
  const [meterGroup, setMeterGroup] = useState({});
  const [loading, setLoading] = useState(false);
  const [finishedUpdating, setFinishedUpdating] = useState(false);
  const [editMeterGroupModal, setEditMeterGroupModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [voltageModal, setVoltageModal] = useState(false);
  const [phaseModal, setPhaseModal] = useState(false);
  const [circuitsModal, setCircuitsModal] = useState(false);
  const [maxAmpsModal, setMaxAmpsModal] = useState(false);
  const [loadLimitModal, setLoadLimitModal] = useState(false);
  const [disableEnableButton, setDisableEnableButton] = useState(true);
  const [missingInfoModal, setMissingInfoModal] = useState(false);
  const [dataToCheck, setDataToCheck] = useState({});
  const [notFound, setNotFound] = useState({
    display: false,
    message: '',
  });

  const buttonRef = useRef(null);
  const [prevMeterGroup, setPrevMeterGroup] = useState({});
  // const [isBlocking, setIsBlocking] = useState(false);

  // TODO - Leave Guard
  console.log(prevMeterGroup);

  // useEffect(() => {
  //   let data = diff(meterGroup, prevMeterGroup);
  //   if (Object.keys(data).length > 0) {
  //     setIsBlocking(true);
  //   } else {
  //     setIsBlocking(false);
  //   }
  // }, [meterGroup, prevMeterGroup]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        let group = await AppMeterGroupsService.getSingleMeterGroup(
          props.match.params.id
        );
        let checkingIds = account.chargingProfiles.some((profile) => {
          return profile.id === group.chargingProfileId;
        });
        if (!checkingIds) {
          group.chargingProfileId = null;
          group.enableMeterGroupChargingProfile = false;
        }
        let groupIndex = account.chargingProfiles.findIndex(
          (sx) => sx.id === group.chargingProfileId
        );
        if (groupIndex < 0) {
          group.chargingProfileId = null;
        }

        setMeterGroup(group);
        setPrevMeterGroup(group);
        setDataToCheck({
          phaseType: group.phaseType,
          voltage: group.voltage,
          amps: group.maxAmps,
          load: group.loadLimit,
          circuits: group.circuits,
        });
      } catch (err) {
        if (err.response) {
          setNotFound({
            display: true,
            message: err.response.data.split(/[|]+/).pop(),
          });
        }
      }
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, router.match.params.id]);

  useEffect(() => {
    if (finishedUpdating) {
      setTimeout(() => {
        router.history.push('/meter-groups');
      }, 400);
    }
  }, [finishedUpdating, router.history]);

  const setButtonDisable = (boo) => {
    setDisableEnableButton(boo);
  };
  // const toggleBlocking = () => {
  //   setIsBlocking(!isBlocking);
  // };

  //displays Phase modal
  const togglePhaseModal = () => {
    setPhaseModal(!phaseModal);
  };

  //displays Voltage modal
  const toggleVoltageModal = () => {
    setVoltageModal(!voltageModal);
  };

  //displays amps modal
  const toggleAmpsModal = () => {
    setMaxAmpsModal(!maxAmpsModal);
  };

  const toggleCircuitsModal = () => {
    setCircuitsModal(!circuitsModal);
  };

  //displays delete modal
  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };
  //displays limitmodal
  const toggleLoadLimitModal = () => {
    setLoadLimitModal(!loadLimitModal);
  };
  //displays the edit location modal
  const toggleMeterGroupModal = () => {
    setEditMeterGroupModal(!editMeterGroupModal);
  };
  //enable and disables location
  const handleEnable = (e) => {
    setMeterGroup({
      ...meterGroup,
      enabled: !meterGroup.enabled,
    });
  };

  //display missing data
  const handleMissingInfoModal = () => {
    setMissingInfoModal(!missingInfoModal);
  };

  //handles the update of information from the modals
  const handleDataChange = (key, value) => {
    if (key === 'circuits') {
      if (value.length === 0) {
        setMeterGroup({
          ...meterGroup,
          [key]: value,
          circuitCount: value.length,
          enabled: false,
        });
        setDataToCheck({
          ...dataToCheck,
          [key]: value,
          circuitCount: value.length,
          enabled: false,
        });
      } else {
        setMeterGroup({
          ...meterGroup,
          [key]: value,
          circuitCount: value.length,
        });
        setDataToCheck({
          ...dataToCheck,
          [key]: value,
          circuitCount: value.length,
        });
      }
    } else if (key === 'meterGroup') {
      setMeterGroup({
        ...meterGroup,
        ...value,
      });
    } else {
      setMeterGroup({
        ...meterGroup,
        [key]: value,
      });
      setDataToCheck({
        ...dataToCheck,
        [key]: value,
      });
    }
  };

  // useEffect(() => {
  //   let currentMg = account.locations.map((loc) => {
  //     loc.meterGroups.map((mg) => {
  //       if (mg.id === meterGroup.id) {
  //         mg = meterGroup;
  //       }
  //     });
  //   });
  //   setAccount((a) => ({ ...a, meterGroups: currentMg }));
  // }, [meterGroup]);

  useEffect(() => {
    if (checkingValueOfZero(dataToCheck)) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [meterGroup, dataToCheck]);

  const handleDeleteMeterGroup = async (id) => {
    try {
      let updatedAccount = await AppMeterGroupsService.deleteMeterGroup(id);
      setAccount(updatedAccount);
      setFinishedUpdating(true);
    } catch (err) {
      if (err.response.data === 'MeterGroup Last One') {
        setError({
          display: true,
          title: strings.failedLocationDelete,
          message: strings.failedLocationDeleteMessage,
          styles: 'cso',
        });
      }
      if (err.response.data === 'MeterGroup Charger Assigned') {
        setError({
          display: true,
          title: strings.failedChargerDelete,
          message: strings.failedChargerDeleteMessage,
          styles: 'cso',
        });
      }
      if (err.response.data === 'MeterGroup Not Found') {
        setError({
          display: true,
          title: strings.failedChargerDelete,
          message: strings.failedNotFoundMessage,
          styles: 'cso',
        });
      }
    }
  };

  // const handleSubmitCheck = () => {
  //   buttonRef.current.click();
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (
        meterGroup.enableMeterGroupChargingProfile &&
        !meterGroup.chargingProfileId
      ) {
        throw new ErrorResponse(
          'Frontend',
          strings.missingProfileIdError,
          strings.missingInfoTitle
        );
      }
      if (
        meterGroup.enablePowerManagementPolicy &&
        !meterGroup.powerManagementPolicyId
      ) {
        throw new ErrorResponse(
          'Frontend',
          strings.missingPowerPolicyError,
          strings.missingInfoTitle
        );
      }
      setPrevMeterGroup(meterGroup);
      let updatedAccount = await AppMeterGroupsService.updateMeterGroup(
        meterGroup
      );
      if (updatedAccount) {
        setAccount(updatedAccount);
        setFinishedUpdating(true);
      }
    } catch (err) {
      setLoading(false);
      if (err.name === 'Frontend') {
        setError({
          display: true,
          title: err.title,
          message: err.message,
          styles: 'cso',
        });
      }
    }
  };

  if (notFound.display) {
    return <CsoNotFound message={notFound.message} />;
  }
  return (
    <OuterWrapper
      title={strings.editMeterGroup}
      internal
      path='/meter-groups'
      roles={[1]}
      myForm>
      {/* =====================Enterprise Only===================================== */}
      <EnterpriseAdmin allowTech>
        {/* <RouteLeavingGuard
          cso
          when={isBlocking}
          toggleBlocking={toggleBlocking}
          action={handleSubmitCheck}
          loading={loading}
          navigate={(path) => props.history.push(path)}
          shouldBlockNavigation={(location) => {
            if (isBlocking) {
              return true;
            }
            return false;
          }}
        /> */}
        <ComponentWrapper title={strings.editMeterGroup} roles={[1]}>
          {loading && <LoadingSpinner />}
          {phaseModal && (
            <PhaseModal
              handleCloseModal={togglePhaseModal}
              handleDataChange={handleDataChange}
              phase={meterGroup.phaseType}
            />
          )}
          {voltageModal && (
            <VoltageModal
              handleCloseModal={toggleVoltageModal}
              handleDataChange={handleDataChange}
              voltage={meterGroup.voltage}
            />
          )}
          {circuitsModal && (
            <CircuitsModal
              handleCloseModal={toggleCircuitsModal}
              handleDataChange={handleDataChange}
              circuits={meterGroup.circuits}
              meterGroup={meterGroup}
            />
          )}
          {maxAmpsModal && (
            <MaxAmpsModal
              handleCloseModal={toggleAmpsModal}
              handleDataChange={handleDataChange}
              maxAmps={meterGroup.maxAmps}
            />
          )}
          {loadLimitModal && (
            <LoadLimitModal
              handleCloseModal={toggleLoadLimitModal}
              handleDataChange={handleDataChange}
              loadLimit={meterGroup.loadLimit}
            />
          )}
          {editMeterGroupModal && (
            <EditModal
              handleCloseModal={toggleMeterGroupModal}
              handleDataChange={handleDataChange}
              meterGroup={meterGroup}
              account={account}
            />
          )}
          {deleteModal && (
            <DeleteModal
              handleCloseModal={toggleDeleteModal}
              handleDeleting={handleDeleteMeterGroup}
              meterGroup={meterGroup}
            />
          )}
          {missingInfoModal && (
            <MissingInfoModal handleCloseModal={handleMissingInfoModal} />
          )}
          <form id='myForm' onSubmit={handleSubmit}>
            {Object.keys(meterGroup).length > 0 && (
              <MeterGroupHeader
                meterGroup={meterGroup}
                toggleDeleteModal={toggleDeleteModal}
                toggleMeterGroupModal={toggleMeterGroupModal}
              />
            )}
            <EnableContainerStyles>
              <TitleStyles size='18px' margin='0' weight='500'>
                {strings.formEnableMeterGroup}
              </TitleStyles>
              <ToggleSwitch
                name='enabled'
                canEdit={[1]}
                type='metergroup'
                disabledModal
                disable={disableEnableButton}
                checked={!!meterGroup.enabled}
                handleChange={handleEnable}
              />
            </EnableContainerStyles>
            <MeterGroupDetails
              meterGroup={meterGroup}
              setButtonDisable={setButtonDisable}
              togglePhaseModal={togglePhaseModal}
              toggleVoltageModal={toggleVoltageModal}
              toggleAmpsModal={toggleAmpsModal}
              toggleCircuitsModal={toggleCircuitsModal}
              toggleLoadLimitModal={toggleLoadLimitModal}
            />
            <MeterGroupOptions
              meterGroup={meterGroup}
              handleDataChange={handleDataChange}
            />
          </form>
        </ComponentWrapper>
        <Button
          cso
          hasMobile
          formSubmit
          ref={buttonRef}
          buttonText={strings.editSave}
          roles={[1]}
        />
      </EnterpriseAdmin>
      {/* ========================================================================= */}
    </OuterWrapper>
  );
};

export default MeterGroupsEdit;
