import styled from 'styled-components';

export const ModalStyles = styled.div`
  position: fixed;
  z-index: 9500;
  left: 0;
  display: flex;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;

export const ModalContainerStyles = styled.div`
  display: block;
  margin: auto;
  border: 1px solid #888;
  border-radius: 1em;
  max-width: 320px;
  min-width: 300px;
  padding-bottom: 10px;
  border: 1px solid #000;
  border-radius: 15px;
  background: #fff;
  text-align: center;
`;

export const ModalHeaderStyles = styled.div`
  padding: 7px 10px;
  border-bottom: 1px solid #c2c2c2;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  .Title {
    display: inline-block;
    color: ${({ theme }) => (theme.titleColor ? theme.titleColor : '#f60')};
    line-height: 20px;
    font-weight: 600;
    font-size: 14px;
    margin: 0;
  }
  .Close {
    float: right;
    color: #a5a5a5;
    font-size: 24px;
    line-height: 20px;
    font-weight: 300;
    cursor: pointer;
  }
`;

export const ModalContentStyles = styled.div`
  padding: ${({ padding }) => (padding ? padding : '10px')};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? borderBottom : '1px solid #e7e7e7'};
  p {
    margin: ${({ textMargin }) => (textMargin ? textMargin : '0 0 10px')};
  }
`;

export const ModalItemStyles = styled.div`
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};
  padding: ${({ padding }) => (padding ? padding : '0 0 10px')};
  text-align: ${({ align }) => (align ? align : 'center')};
  margin: ${({ margin }) => margin};
  display: ${({ display }) => display};
  align-items: ${({ alignItems }) => alignItems};
  max-height: ${({ maxHeight }) => maxHeight};
  overflow-y: ${({ maxHeight }) => maxHeight && 'scroll'};
  border-bottom: ${({ borderBottom }) => borderBottom && '1px solid #e7e7e7'};
  font-size: ${({ pSize }) => pSize};
  ol {
    margin: 0;
    padding: 0;
    li {
      font-size: ${({ pSize }) => (pSize ? pSize : '13px')};
    }
  }
  p {
    font-size: ${({ pSize }) => (pSize ? pSize : '14px')};
    margin: ${({ textMargin }) => (textMargin ? textMargin : '0 0 10px')};
  }
  img {
    max-width: ${({ imgMaxWidth }) => (imgMaxWidth ? imgMaxWidth : '120px')};
  }
  .rc-time-picker {
    width: 100%;
    .rc-time-picker-input {
      text-align: center;
      padding: 8px 0px 5px 5px;
      cursor: text;
      font-size: 16px;
      border: none;
      border-bottom: 1px solid #d9d9d9;
      height: auto;
    }
    .rc-time-picker-clear-icon {
      display: none;
    }
  }
  .react-html5-camera-photo {
    video {
      height: 180px;
      max-height: 180px;
    }
    #inner-circle {
      border-radius: 15px;
      width: auto;
      display: inline-block;
      height: 33px;
      z-index: 99999;
      margin: 3px auto;
      padding: 5px;
      border: 1px solid #c2c2c2;
      background-color: #c2c2c2;
      color: #fff;
      outline: none;
      font-size: 14px;
      cursor: pointer;
      &:after {
        content: 'Take Photo';
      }
    }
  }

  .circuit-amp-table {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    max-width: 80%;
    margin: 0 auto;
    padding: 0 !important;
    .circuit-amp-name {
      font-weight: 500;
      text-align: left;
    }
    .circuit-amp-max {
      color: #8f8d8d;
    }
    p,
    input,
    span,
    button {
      font-size: 12px;
      flex: 1;
      padding: 0 !important;
      margin: 0 !important;
    }
    button {
      background: none;
      border: none;
      outline: none;
      text-align: right;
      font-size: 18px;
      text-align: right;
      margin: 0 10px 0 0 !important;
      cursor: pointer;
    }
    p:last-child {
      font-size: 20px;
      text-align: right;
      margin: 0 10px 0 0 !important;
      cursor: pointer;
    }
    .circuit-amp-name-input,
    .circuit-amp-max-input {
      margin: 0 10px 0 0 !important;
    }
  }
  &.Eye-modal-header {
    margin: 0 0 5px;
    font-size: 13px;
    font-weight: 600;
    text-align: left;
  }
  &.Eye-time-table {
    margin: ${({ noTableMargin }) => (noTableMargin ? '0' : '10px 0')};
    text-align: left;
    font-size: 12px;
    color: #28456a;
    span {
      font-weight: 500;
    }
    div {
      display: flex;
      justify-content: space-between;
      span:first-child {
        flex: 1.5;
        font-weight: 500;
        color: #28456a;
      }
      span {
        flex: 1;
        color: #8f8d8d;
        font-size: 12px;
      }
    }
    .Day {
      display: inline-block;
      span {
        flex: 0;
        color: #8f8d8d;
        font-size: 12px;
        margin: 0 5px 0 0;
        &:first-child {
          flex: 0;
          font-weight: 400;
        }
      }
    }
  }
`;

export const ModalColumnStyles = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  text-align: left;
  padding: 0;
  font-size: 12px;
  .Header {
    font-size: 13px;
    font-weight: 600;
  }
  .Title {
    flex: 1;
  }
  .Content {
    flex: 1;
    margin: 0 0 0 10px;
    color: #c2c2c2;
  }
`;

export const ModalImageStyles = styled.div`
  .Upload {
    display: inline-block;
    margin: 3px 0 0;
    padding: 7px 10px;
    border-radius: 15px;
    background: #c2c2c2;
    color: #fff;
    cursor: pointer;
  }
`;

export const ModalNoteStyles = styled.div`
  text-align: ${({ align }) => (align ? align : 'center')};
  font-size: 12px;
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  color: ${({ color }) => (color ? color : '#28456a')};
  border-bottom: ${({ borderBottom }) => borderBottom && '1px solid #e7e7e7'};

  p {
    margin: 0;
  }

  label {
    display: flex;
    align-items: center;
    input {
      margin: 0 15px 0 0;
    }
    span {
      flex-basis: 75px;
    }
  }
`;

export const ModalOverflowStyles = styled.div`
  overflow-y: scroll;
  height: ${({ height }) => height};
`;
