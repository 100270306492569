import React, { useEffect, useContext, useState } from 'react';

import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppMeterGroupsService from '../../AppMeterGroupsService';

//context
import { CsoAccountContext } from '../../../../context/operator/getCsoAccount';

//global component
import ModalButton from '../../../../shared/Buttons/ModalButton';

//hooks
import { useHours } from '../../../../shared/hooks/useHours';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  ModalNoteStyles,
} from '../../../../SharedStyles';

//helpers
import { dayOfWeek } from '../../../helpers';

const EyeModal = (props) => {
  const { strings } = AppMeterGroupsService;
  const { account } = useContext(CsoAccountContext);
  const [chargingProfile, setChargingProfile] = useState({});
  const [hours] = useHours();

  const closeModal = () => {
    props.closeModal();
  };

  useEffect(() => {
    if (account) {
      const getChargingProfile = () => {
        let profile = account.chargingProfiles.filter((prof) => {
          return prof.id === props.meterGroup.chargingProfileId;
        });
        setChargingProfile(profile[0]);
      };
      getChargingProfile();
    }
  }, [props.meterGroup, account]);

  if (props.chargingProfile && Object.keys(chargingProfile).length > 0) {
    return (
      <ModalStyles>
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{strings.chargingProfileTitle}</h2>
            <FaRegWindowClose className='Close' onClick={closeModal} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            <ModalItemStyles
              align='left'
              padding='0'
              className='Eye-modal-header'>
              <p>{chargingProfile.name}</p>
            </ModalItemStyles>
            {/* Section */}
            <ModalItemStyles padding='0'>
              <p>{strings.chargingProfileContent}</p>
            </ModalItemStyles>
            {/* Section */}
            {/* <ModalItemStyles align='left' padding='0'> */}
            {chargingProfile.schedules.map((sx) => {
              return (
                <ModalItemStyles
                  align='left'
                  className='Eye-time-table'
                  padding='0'
                  key={sx.id}>
                  {Object.keys(sx.dow).map((day) => {
                    if (sx.dow[day]) {
                      return <span key={day}>{dayOfWeek[day]} </span>;
                    }
                    return null;
                  })}
                  {sx.times.map((time) => {
                    let startingTime;
                    let endingTime;
                    hours.forEach((hr) => {
                      if (hr.time === time.timeStart) {
                        startingTime = hr.display;
                      }
                      if (hr.time === time.timeEnd) {
                        endingTime = hr.display;
                      }
                    });
                    return (
                      <div key={time.timeStart}>
                        <span>
                          {startingTime} - {endingTime}
                        </span>
                        <span>{time.percentage}%</span>
                      </div>
                    );
                  })}
                </ModalItemStyles>
              );
            })}
            {/* </ModalItemStyles> */}
            <ModalNoteStyles>
              <p className='Warning-modal'>{strings.chargingProfileNote}</p>
            </ModalNoteStyles>
          </ModalContentStyles>
          {/* Button Component for Modals*/}
          <ModalButton
            containerMargin='10px 10px 0'
            containerAlign='right'
            buttonPadding='10px'
            buttonMargin='0'
            handleClick={closeModal}
            buttonText={strings.ok}
          />
        </ModalContainerStyles>
      </ModalStyles>
    );
  }
  if (props.powerPolicy) {
    const { powerPolicy, meterGroup } = props;
    return (
      <ModalStyles>
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{strings.powerPolicyTitle}</h2>
            <FaRegWindowClose className='Close' onClick={closeModal} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            <ModalItemStyles
              align='left'
              padding='0'
              className='Eye-modal-header'>
              <p>{powerPolicy[meterGroup.powerManagementPolicyId]}</p>
            </ModalItemStyles>
            {/* Section */}
            <ModalItemStyles padding='0'>
              <p>{strings.powerPolicyContent}</p>
            </ModalItemStyles>
            {/* Button Component for Modals*/}
          </ModalContentStyles>
          <ModalButton
            containerMargin='10px 10px 0'
            containerAlign='right'
            buttonPadding='10px'
            buttonMargin='0'
            handleClick={closeModal}
            buttonText={strings.ok}
          />
        </ModalContainerStyles>
      </ModalStyles>
    );
  }
  return null;
};

export default EyeModal;
