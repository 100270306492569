import React from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from 'react-stripe-elements';

//components
import Button from '../../../shared/Buttons/Button';

//styles
import { FormStyles, DivStyles, FormLabelStyles } from '../../../SharedStyles';

//helpers
import { STRIPE_OPTIONS } from '../../../shared';

const NewCreditCard = (props) => {
  const { handleSubmit, strings } = props;

  return (
    <FormStyles
      display='block'
      margin='0 auto'
      width='90%'
      id='myForm'
      onSubmit={handleSubmit}>
      <DivStyles width='100%'>
        <FormLabelStyles width='100%' margin='0 0 5px 0'>
          <CardNumberElement {...STRIPE_OPTIONS()} />
        </FormLabelStyles>
        <DivStyles display='flex'>
          <FormLabelStyles width='100%' margin='0 10px 5px 0'>
            <CardExpiryElement {...STRIPE_OPTIONS()} />
          </FormLabelStyles>
          <FormLabelStyles width='100%' margin='0 0 5px 10px'>
            <CardCvcElement {...STRIPE_OPTIONS()} />
          </FormLabelStyles>
        </DivStyles>
      </DivStyles>
      <Button driver noMobile formSubmit buttonText={strings.save} />
    </FormStyles>
  );
};

export default NewCreditCard;
