import styled from 'styled-components';

export const FooterStyles = styled.footer`
  z-index: ${({ zIndex }) => zIndex};
  position: ${({ position }) => (position ? position : 'relative')};
  color: ${({ theme }) => theme.color};
  transform: ${({ transform }) => transform};
  background: ${({ background }) => background};
  font-size: ${({ size }) => size};
  max-height: ${({ maxHeight }) => maxHeight};
  min-height: ${({ minHeight }) => minHeight};
  display: ${({ display }) => display};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  align-items: ${({ alignItems }) => alignItems};
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  height: ${({ height }) => height};
  flex: ${({ flex }) => flex};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  font-weight: ${({ weight }) => weight};
  text-align: ${({ align }) => align};
  align-self: ${({ alignSelf }) => alignSelf};
  line-height: ${({ lineHeight }) => lineHeight};
  cursor: ${({ cursor }) => cursor};
  &:hover {
    background: ${({ hoverBackground }) => hoverBackground};
  }
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    display: ${({ isMobile }) => (isMobile ? 'block' : 'none')};
    justify-content: ${({ justifyContentMobile }) => justifyContentMobile};
    margin: ${({ wrapMargin }) => wrapMargin};
    padding: ${({ mobilePadding }) => mobilePadding};
  }
`;
