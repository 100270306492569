import React, { useState, useEffect } from 'react';

//layout
import { ComponentWrapper, InteriorWrapper } from '../../shared';
import { LoadingSpinner } from '../../../shared';

//service
import AccessCardsService from '../AccessCardsService';

//components
import Description from '../Shared/Description';
import { useParams } from '../../../shared/hooks/useParams';

//styles
import OrderForm from '../Components/OrderForm/OrderForm';

const AccessCardOrder = () => {
  const { strings } = AccessCardsService;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const [formData] = useParams({
    entityId: '',
    paramType: 1,
    page: 'system-variables | driver-defaults',
    error: 'Error',
  });

  return (
    <InteriorWrapper
      title={strings.orderTitle}
      isInternal
      myForm='myForm'
      actionText={strings.order}
      path='/access-cards'>
      {loading && <LoadingSpinner />}
      <ComponentWrapper title={strings.orderTitle}>
        <Description
          message={strings.orderDescription}
          noteSection={{
            margin: '15px 0 0',
            note: strings.note,
            message: `${strings.orderNoteOne}${formData.accessCardsFee}${strings.orderNoteTwo}`,
          }}
          fontSize='14px'
          borderBottom
        />
        <OrderForm strings={strings} globalVar={formData} />
      </ComponentWrapper>
    </InteriorWrapper>
  );
};

export default AccessCardOrder;
