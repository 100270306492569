import React, { useContext } from 'react';
import { CsoAccountContext } from '../../context/operator/getCsoAccount';

const SubscriptionAccess = (props) => {
  const { account } = useContext(CsoAccountContext);
  const { levels, allowAccess, specialAccess } = props;

  if (specialAccess) {
    return <>{account && allowAccess ? props.children : null}</>;
  }

  return (
    <>
      {account && levels && levels.includes(account.subscriptionLevel)
        ? props.children
        : null}
    </>
  );
};

export default SubscriptionAccess;
