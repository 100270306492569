import React, {useState, useContext} from 'react';

//service
import AppAccountSettingsService from '../AppAccountSettingsService';
import AppNavSectionService from '../../Nav/NavSection';

//components
import Modal from '../../../shared/Modals/Modal';

//styles
import {
  ButtonContainerStyles,
  ButtonStyles
} from '../../../SharedStyles';

//hooks
import { LoadingSpinner } from '../../../shared';

//context
import { ErrorContext } from '../../../context/shared/ErrorContext';

const CloseDriverAccount = props => {
  const { strings, driver } = props;
  const [closeDriverAccountModal, setCloseDriverAccountModal] = useState(false);
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(false);
  
  const toggleDelete = () => {
    setCloseDriverAccountModal(!closeDriverAccountModal);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      await AppAccountSettingsService.closeDriverAccount(driver);

      toggleDelete();

      AppNavSectionService.logout();

      setLoading(false);
    } catch (err) {
      setLoading(false);

      setError({
        display: true,
        title: 'Something went wrong',
        message: err.response ? 
          err.response.data === '' ? err.message : err.response.data
          : err.message,
        styles: 'driver',
      });
    }
  };

  return (
    <div>
    {loading && <LoadingSpinner />}
    {closeDriverAccountModal && (
      <Modal
          title={strings.closeDriverAccountTitle}
          message={strings.deleteContent}
          cancelButton='No'
          confirmButton='Yes'
          onCancel={() => toggleDelete()}
          onConfirm={handleDelete}
        />
    )}
    <ButtonContainerStyles
      borderBottom={!props.cso}
      align={props.cso && 'left'}
      margin={props.cso ? '5px 0' : '0'}
      padding={props.cso ? '0' : '20px 0'}>

        <ButtonStyles
          margin={props.cso && '0'}
          inverse
          title={strings.closeDriverAccountModal}
          onClick={toggleDelete}>
          {strings.closeDriverAccountModal}
        </ButtonStyles>

    </ButtonContainerStyles>
    </div>
  );
};

export default CloseDriverAccount;
