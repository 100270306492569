import styled from 'styled-components';

let defaultMediaQuery = '575px';

export const ComponentInternalStyles = styled.div`
  text-align: ${({ align }) => (align ? align : 'left')};
  border: 1px solid #e7e7e7;
  margin: 0 10px;
  @media screen and (max-width: ${({ mobileMedia }) =>
      mobileMedia ? mobileMedia : defaultMediaQuery}) {
    border: none;
    margin: 0;
  }
  .Header {
    border-bottom: 1px solid #e7e7e7;
    @media screen and (max-width: ${({ mobileMedia }) =>
        mobileMedia ? mobileMedia : defaultMediaQuery}) {
      display: none;
    }
    .Title {
      padding: 10px;
      color: #2c456c;
      font-size: 16px;
      font-weight: 600;
      text-align: left;
    }
  }
  .Header-Mobile {
  }
`;

export const ComponentExternalStyles = styled.div`
  text-align: ${({ align }) => (align ? align : 'center')};
  border: none;
  margin: 0 10px;
`;
