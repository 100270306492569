import React from 'react';
import moment from 'moment';

//styles
import {
  TextStyles,
  TitleStyles,
  DivStyles,
  ImageThumbnailStyles,
  ButtonDivGreyStyles,
} from '../../../SharedStyles';

const StatementHeader = (props) => {
  const { account, statementDetails, strings, handleDownload } = props;

  return (
    <DivStyles
      padding='10px'
      borderBottom
      display='flex'
      flexWrap='nowrap'
      alignItems='center'
      justifyContent='space-between'>
      {/* Left Card */}
      <DivStyles display='flex' flexWrap='nowrap' alignItems='center'>
        <ImageThumbnailStyles
          maxWidth='75px'
          margin='0 10px 0 0'
          src={
            account.imagePath
              ? account.imagePath
              : require('../../../../assets/images/AccountPlaceholder.png').default
          }
          alt={account.csoAccountName}
        />
        <DivStyles align='left'>
          <TitleStyles size='14px' weight='700' margin='10px auto'>
            {account.csoAccountName}
          </TitleStyles>
        </DivStyles>
      </DivStyles>
      {/* Right Card */}
      {statementDetails && (
        <DivStyles display='block' align='right'>
          {/* device ID */}
          <DivStyles color='#8999ae' padding='0 10px'>
            <TextStyles size='12px'>
              {strings.statementDate}:{' '}
              {moment(statementDetails.statementDate).format('MM/DD/YY')}
            </TextStyles>
          </DivStyles>
          <DivStyles color='#8999ae' padding='0 10px'>
            <TextStyles size='12px'>
              {strings.statementPeriod}:{' '}
              {moment.utc(statementDetails.startDate).format('MM/DD/YY')} -{' '}
              {moment.utc(statementDetails.stopDate).format('MM/DD/YY')}
            </TextStyles>
          </DivStyles>
          <DivStyles color='#8999ae' padding='0 10px'>
            <ButtonDivGreyStyles
              size='11px'
              padding='3px 20px'
              margin='0'
              background='#a5a5a5'
              border
              onClick={handleDownload}>
              {strings.export}
            </ButtonDivGreyStyles>
          </DivStyles>
        </DivStyles>
      )}
    </DivStyles>
  );
};

export default StatementHeader;
