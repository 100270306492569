import React, { useContext, useState, useEffect } from 'react';

//layout
import { OuterWrapper, ComponentWrapper } from '../OperatorShared';
import Button from '../../shared/Buttons/Button';

//services
import AppNotificationsService from './AppNotificationsService';

//components
import NotificationsForm from './PermissionNotifications/NotificationsForm';
import CsoNotFound from '../../shared/AppNotFound/CsoNotFound';

//global components
import { LoadingSpinner } from '../../shared';

//context
import { CsoAccountContext } from '../../context/operator/getCsoAccount';
import { ErrorContext } from '../../context/shared/ErrorContext';

//styles
import { ToggleSwitchContent } from '../../SharedStyles';

//helpers
import {
  adminNotificationSettings,
  managerNotificationSettings,
  techNotificationSettings,
  customerNotificationSettings,
  smallBizNotificationSettings,
  sharedAccountNotificationSettings,
  nonSubAccountNotificationSettings,
} from './helpers';

import NotifyBySection from './NotifyBySection';

const Notifications = (props) => {
  const { strings } = AppNotificationsService;
  const { account, currentUser, setCurrentUser } = useContext(
    CsoAccountContext
  );
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [emailRequired, setEmailRequired] = useState(true);
  const [textRequired, setTextRequired] = useState(true);
  const [notFound, setNotFound] = useState({
    display: false,
    message: '',
  });

  useEffect(() => {
    const fetchingUsers = async () => {
      let users = await AppNotificationsService.getSystemUsers();

      let singleUser;

      //cycle through users by id, admin single account and non enterprise
      if (props.match.params.id) {
        singleUser = users
          .filter((user) => user.id === props.match.params.id)
          .reduce((acc, next) => ({ ...acc, ...next }), {});
      } else if (currentUser.isAdminUser) {
        if (account.subscriptionLevel !== 3 && users.length > 0) {
          singleUser = users[0];
        } else {
          setNotFound({
            display: true,
            message: currentUser.isAdminUser
              ? 'Ampedup Admins do not need access to the user profile'
              : 'No User with this Id could be found.',
          });
        }
      } else {
        singleUser = users
          .filter((user) => user.id === currentUser.id)
          .reduce((acc, next) => ({ ...acc, ...next }), {});
      }

      if (singleUser) {
        if (!singleUser.notificationEmail) {
          singleUser.notificationEmail = singleUser.email;
        }
        if (!singleUser.notificationText) {
          singleUser.notificationText = singleUser.phone;
        }
        if (singleUser.notifyByEmail && singleUser.notificationEmail) {
          setEmailRequired(false);
        }
        if (singleUser.notifyByText && singleUser.notificationText) {
          setTextRequired(false);
        }

        setUser(singleUser);
      }

      setLoading(false);
    };
    fetchingUsers();
    return () => {
      setLoading(false);
    };
  }, [props.match.params.id, currentUser, account.subscriptionLevel]);

  const handleChange = (name, value) => {
    setUser({ ...user, [name]: value });
  };

  const handleTextChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleValidation = (employee) => {
    let employeeValues = adminNotificationSettings.some((notif) => {
      return employee[notif.name];
    });
    if (employeeValues) {
      if (employee.notifyByEmail && employee.notificationEmail) {
        setEmailRequired(false);
      } else {
        setEmailRequired(true);
      }
      if (employee.notifyByText && employee.notificationText) {
        setTextRequired(false);
      } else {
        setTextRequired(true);
      }
    } else {
      if (!employee.notifyByEmail) {
        setEmailRequired(false);
      }
      if (!employee.notifyByText) {
        setTextRequired(false);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      handleValidation(user);
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let updatedSystemUser = await AppNotificationsService.updateSystemUser(
        user
      );
      if (updatedSystemUser.id === currentUser.id) {
        setCurrentUser((prev) => ({ ...prev, ...updatedSystemUser }));
      }
      if (updatedSystemUser) {
        if (props.match.params.id) {
          props.history.push('/system-users');
        } else {
          props.history.push('/');
        }
      }
    } catch (err) {
      setError({
        display: true,
        title: 'Something went wrong',
        message: err.response.data.split(/[|]+/).pop(),
        styles: 'cso',
      });
    }
  };

  console.log('Notifications');

  if (notFound.display) {
    return <CsoNotFound message={notFound.message} />;
  }

  if (account && account.subscriptionLevel === 0) {
    //non-subscription
    return (
      <OuterWrapper
        title={strings.notification}
        internal
        myForm
        roles={[1, 2, 3, 4]}
        adminPermission
        path='/'>
        {loading && <LoadingSpinner />}
        <ComponentWrapper title={strings.notification}>
          <form id='myForm' onSubmit={handleSubmit}>
            <ToggleSwitchContent>
              {/* Notifications form based on roles */}
              <NotificationsForm
                userNotificationSettings={nonSubAccountNotificationSettings}
                handleChange={handleChange}
                user={user}
              />
              <NotifyBySection
                user={user}
                strings={strings}
                handleChange={handleChange}
                handleTextChange={handleTextChange}
                textRequired={textRequired}
                emailRequired={emailRequired}
              />
            </ToggleSwitchContent>
          </form>
        </ComponentWrapper>
        <Button
          cso
          hasMobile
          buttonText={strings.save}
          formSubmit
          roles={[1]}
        />
      </OuterWrapper>
    );
  }

  //shared subscription
  if (account && account.subscriptionLevel === 1) {
    return (
      <OuterWrapper
        title={strings.notification}
        internal
        myForm
        roles={[1, 2, 3, 4]}
        adminPermission
        path='/'>
        {loading && <LoadingSpinner />}
        <ComponentWrapper title={strings.notification}>
          <form id='myForm' onSubmit={handleSubmit}>
            <ToggleSwitchContent>
              {/* Notifications form based on roles */}
              <NotificationsForm
                userNotificationSettings={sharedAccountNotificationSettings}
                handleChange={handleChange}
                user={user}
              />
              <NotifyBySection
                user={user}
                strings={strings}
                handleChange={handleChange}
                handleTextChange={handleTextChange}
                textRequired={textRequired}
                emailRequired={emailRequired}
              />
            </ToggleSwitchContent>
          </form>
        </ComponentWrapper>
        <Button
          cso
          hasMobile
          buttonText={strings.save}
          formSubmit
          roles={[1]}
        />
      </OuterWrapper>
    );
  }

  if (account && account.subscriptionLevel === 2) {
    return (
      <OuterWrapper
        title={strings.pageTitle}
        internal
        myForm
        roles={[1, 2, 3, 4]}
        adminPermission
        path='/'>
        {loading && <LoadingSpinner />}
        <ComponentWrapper title={strings.pageTitle}>
          <form id='myForm' onSubmit={handleSubmit}>
            <div className='Content-header-container'>
              <div className='Left-align'>
                <div className='Left-stack'>
                  <h3>
                    {user.firstName} {user.lastName}
                    {console.log(user)}
                  </h3>
                  <p>
                    {strings.locationTitle}: {user.locationDisplayName}
                  </p>
                </div>
              </div>
            </div>
            {/* Notifications form based on roles */}
            <ToggleSwitchContent borderBottom='1px solid #e7e7e7'>
              <NotificationsForm
                userNotificationSettings={smallBizNotificationSettings}
                handleChange={handleChange}
                user={user}
              />
            </ToggleSwitchContent>
            <NotifyBySection
              user={user}
              strings={strings}
              handleChange={handleChange}
              handleTextChange={handleTextChange}
              textRequired={textRequired}
              emailRequired={emailRequired}
            />
          </form>
        </ComponentWrapper>
        <Button
          cso
          hasMobile
          formSubmit
          buttonText={strings.save}
          roles={[1, 2, 3, 4]}
        />
      </OuterWrapper>
    );
  }

  return (
    <OuterWrapper
      title={strings.pageTitle}
      internal
      myForm
      roles={[1, 2, 3, 4]}
      adminPermission
      path={props.match.params.id ? '/system-users' : '/'}>
      {loading && <LoadingSpinner />}
      <ComponentWrapper title={strings.pageTitle}>
        <form id='myForm' onSubmit={handleSubmit}>
          <div className='Content-header-container'>
            <div className='Left-align'>
              <div className='Left-stack'>
                <h3>
                  {user.firstName} {user.lastName}
                </h3>
                <p>
                  {strings.locationTitle}: {user.locationDisplayName}
                </p>
              </div>
            </div>
          </div>
          {/* Notifications form based on roles */}
          {user.role === 1 && (
            <ToggleSwitchContent borderBottom='1px solid #e7e7e7'>
              <NotificationsForm
                userNotificationSettings={adminNotificationSettings}
                handleChange={handleChange}
                user={user}
              />
            </ToggleSwitchContent>
          )}
          {user.role === 2 && (
            <ToggleSwitchContent borderBottom='1px solid #e7e7e7'>
              <NotificationsForm
                userNotificationSettings={managerNotificationSettings}
                handleChange={handleChange}
                user={user}
              />
            </ToggleSwitchContent>
          )}
          {user.role === 3 && (
            <ToggleSwitchContent borderBottom='1px solid #e7e7e7'>
              <NotificationsForm
                userNotificationSettings={techNotificationSettings}
                handleChange={handleChange}
                user={user}
              />
            </ToggleSwitchContent>
          )}
          {user.role === 4 && (
            <ToggleSwitchContent borderBottom='1px solid #e7e7e7'>
              <NotificationsForm
                userNotificationSettings={customerNotificationSettings}
                handleChange={handleChange}
                user={user}
              />
            </ToggleSwitchContent>
          )}
          <NotifyBySection
            user={user}
            strings={strings}
            handleChange={handleChange}
            handleTextChange={handleTextChange}
            textRequired={textRequired}
            emailRequired={emailRequired}
          />
        </form>
      </ComponentWrapper>
      <Button
        cso
        hasMobile
        formSubmit
        buttonText={strings.save}
        roles={[1, 2, 3, 4]}
      />
    </OuterWrapper>
  );
};

export default Notifications;
