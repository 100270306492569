import React from 'react';
import moment from 'moment';

//service
import AppPlatformService from '../../../../../../../../../services/AppPlatform/AppPlatformService';

//styles
import {
  TableRowStyles,
  TableItemStyles,
} from '../../../../../../../../SharedStyles';

const IncomeCard = (props) => {
  const { item } = props;

  return (
    <TableRowStyles
      hoverBackground='#f1f7ff'
      borderTop='none'
      borderLeft='none'
      borderRight='none'
      align='center'
      width='780px'
      hideScroll>
      <TableItemStyles width='100%'>
        {moment(item.date).format('MM/DD/YY')}
      </TableItemStyles>
      <TableItemStyles minWidth='125px' width='100%'>
        {item.bankName}
      </TableItemStyles>
      <TableItemStyles width='100%'>{item.routingNumber}</TableItemStyles>
      <TableItemStyles width='100%'>****{item.last4}</TableItemStyles>
      <TableItemStyles minWidth='200px' width='100%'>
        {item.transactionId}
      </TableItemStyles>
      <TableItemStyles width='100%'>{AppPlatformService.currencyFormat(item.amount)}</TableItemStyles>
    </TableRowStyles>
  );
};

export default IncomeCard;
