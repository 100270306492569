import React from 'react';

//services
import AppMembershipsService from '../../AppMembershipsService';

//global components
import PropertyContainer from '../../../../shared/PropertyContainer/PropertyContainer';
import ConnectorImages from '../../../../shared/ConnectorImages/ConnectorImages';

//styles
import {
  PropertiesContentContainerStyles,
  PropertiesCardStyles,
  PropertiesHeaderContainerStyles,
  TitleStyles,
} from '../../../../SharedStyles';

const MembershipChargers = (props) => {
  const { strings } = AppMembershipsService;
  const { membership } = props;

  if (!props.newMembership) {
    return (
      <PropertiesCardStyles borderBottom='1px solid #e7e7e7'>
        <PropertiesHeaderContainerStyles>
          <TitleStyles size='14px' weight='600' flex='1' margin='0'>
            {strings.membershipInfo}
          </TitleStyles>
        </PropertiesHeaderContainerStyles>
        <PropertiesContentContainerStyles>
          <PropertyContainer
            title={strings.membershipCount}
            content={membership.membersCount}
          />
          <PropertyContainer
            title={strings.membershipChargers}
            content={membership.chargingStationCount}
          />
          <PropertyContainer
            title={strings.membershipMeterGroups}
            content={membership.meterGroupCount}
          />
          {/* <PropertyContainer
            title={strings.membershipLocations}
            content={membership.locations && membership.locations.length}
          /> */}
          <PropertyContainer
            title={strings.membershipConnectors}
            content={
              membership.connectors && (
                <ConnectorImages charger={membership} showName margin='0 5px' />
              )
            }
          />
        </PropertiesContentContainerStyles>
      </PropertiesCardStyles>
    );
  } else {
    return (
      <PropertiesCardStyles borderBottom='1px solid #e7e7e7'>
        <PropertiesHeaderContainerStyles>
          <TitleStyles size='14px' weight='600' flex='1' margin='0'>
            {strings.membershipInfo}
          </TitleStyles>
        </PropertiesHeaderContainerStyles>
        <PropertiesContentContainerStyles>
          <PropertyContainer title={strings.membershipCount} content='--' />
          <PropertyContainer title={strings.membershipChargers} content='--' />
          <PropertyContainer
            title={strings.membershipMeterGroups}
            content='--'
          />
          {/* <PropertyContainer title={strings.membershipLocations} content='--' /> */}
          <PropertyContainer
            title={strings.membershipConnectors}
            content='--'
          />
        </PropertiesContentContainerStyles>
      </PropertiesCardStyles>
    );
  }
};

export default MembershipChargers;
