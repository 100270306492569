import moment from 'moment';

import {
  dateProperties,
  chargerProperties,
  chargerTypeProperties,
  locationProperties,
  metergroupProperties,
} from './Details/Shared/filterProperties';

export const handleBuildStructure = (filterBys, filtersProperties) => {
  let structure = {};
  filterBys.forEach((action, idx, arr) => {
    filtersProperties.forEach((obj, index, parr) => {
      if (idx === index) {
        structure = {
          ...structure,
          [obj]: action,
        };
      }

      if (arr.length !== parr.length) {
        let existingItem = Object.entries(structure).some(([k, v]) => {
          return v === action;
        });

        if (!existingItem) {
          structure = { ...structure, [action]: action };
        }
      }
    });
  });

  return structure;
};

// Sessions Report Helper for filtering
export const filteringSessions = (sessions, filters, dateField) => {
  let filteredSessions = sessions;

  //checking all locations selected via string for translation
  if (!filters.location.includes(locationProperties[0])) {
    let filArr = [];
    filters.location.forEach((fl) => {
      filteredSessions.forEach((s) => {
        if (s.location.includes(fl)) {
          filArr.push(s);
        }
      });
    });

    filteredSessions = filArr;
  }

  if (!dateField) 
    dateField = 'transactionDate'

  //checking by date
  switch (filters.date) {
    // Today
    case dateProperties[0]:
      let today = moment().format('YYYY-MM-DD');
      filteredSessions = filteredSessions.filter((session) => {
        let sessionDate = moment(session[dateField]).format('YYYY-MM-DD');
        return sessionDate === today;
      });
      break;
    // Yesterday
    case dateProperties[1]:
      let oneDayAgo = moment().subtract(1, 'd').format('YYYY-MM-DD');
      filteredSessions = filteredSessions.filter((session) => {
        let sessionDate = moment(session[dateField]).format('YYYY-MM-DD');
        return sessionDate === oneDayAgo;
      });
      break;
    // This Week
    case dateProperties[2]:
      filteredSessions = filteredSessions.filter((session) => {
        return (
          moment(session[dateField]).format('YYYY-MM-DD') >=
          moment().startOf('week').format('YYYY-MM-DD')
        );
      });
      break;
    // This Week
    case dateProperties[3]:
      let startOfLastWeek = moment()
        .startOf('week')
        .subtract(1, 'week')
        .format('YYYY-MM-DD');
      let endOfLastWeek = moment()
        .endOf('week')
        .subtract(1, 'week')
        .format('YYYY-MM-DD');

      filteredSessions = filteredSessions.filter((session) => {
        return (
          moment(session[dateField]).format('YYYY-MM-DD') >=
            startOfLastWeek &&
          moment(session[dateField]).format('YYYY-MM-DD') <= endOfLastWeek
        );
      });
      break;
    // This Month
    case dateProperties[4]:
      let thisMonth = moment().format('YYYY-MM');
      filteredSessions = filteredSessions.filter((session) => {
        let sessionDate = moment(session[dateField]).format('YYYY-MM');
        return sessionDate === thisMonth;
      });
      break;
    //Last Month
    case dateProperties[5]:
      let lastMonth = moment().subtract(1, 'M').format('YYYY-MM');
      filteredSessions = filteredSessions.filter((session) => {
        let sessionDate = moment(session[dateField]).format('YYYY-MM');
        return sessionDate === lastMonth;
      });
      break;
    // Last 3 Months
    case dateProperties[6]:
      let lastThreeMonths = moment().subtract(3, 'M').format('YYYY-MM');
      let lastThreeToday = moment().format('YYYY-MM');
      filteredSessions = filteredSessions.filter((session) => {
        let sessionDate = moment(session[dateField]).format('YYYY-MM');
        return sessionDate >= lastThreeMonths && sessionDate <= lastThreeToday;
      });
      break;
    // Last 6 Months
    case dateProperties[7]:
      let lastSixMonths = moment().subtract(6, 'M').format('YYYY-MM');
      let lastSixToday = moment().format('YYYY-MM');
      filteredSessions = filteredSessions.filter((session) => {
        let sessionDate = moment(session[dateField]).format('YYYY-MM');
        return sessionDate >= lastSixMonths && sessionDate <= lastSixToday;
      });
      break;
    // This Year
    case dateProperties[8]:
      let thisYear = moment().format('YYYY');
      filteredSessions = filteredSessions.filter((session) => {
        let sessionDate = moment(session[dateField]).format('YYYY');
        return sessionDate === thisYear;
      });
      break;
    // Last Year
    case dateProperties[9]:
      let lastYear = moment().subtract(1, 'Y').format('YYYY');
      filteredSessions = filteredSessions.filter((session) => {
        let sessionDate = moment(session[dateField]).format('YYYY');
        return sessionDate === lastYear;
      });
      break;
    default:
      break;
  }

  //Filtering all charging sessions by the Metergroup
  if (!filters.metergroup.includes(metergroupProperties[0])) {
    filteredSessions = filteredSessions.filter((s) => {
      return filters.metergroup.includes(s.meterGroup);
    });
  }

  // Filtering all charging sessions by the iotHubDeviceId
  // let allChargers = langSessionStrings.filterChargersList[0];
  if (!filters.charger.includes(chargerProperties[0])) {
    filteredSessions = filteredSessions.filter((s) => {
      return filters.charger.includes(s.iotHubDeviceId);
    });
  }

  //checking all charger types selected via string for translation
  let allChargersTypes = chargerTypeProperties[0];
  let levelTwo = chargerTypeProperties[1];
  let levelThree = chargerTypeProperties[2];

  if (!filters.chargerType.includes(allChargersTypes)) {
    if (filters.chargerType.includes(levelTwo)) {
      filteredSessions = filteredSessions.filter((s) => {
        return s.chargerLevel === 2;
      });
    }
    if (filters.chargerType.includes(levelThree)) {
      filteredSessions = filteredSessions.filter((s) => {
        return s.chargerLevel === 3;
      });
    }

    if (
      !(
        filters.chargerType.includes(levelTwo) ||
        filters.chargerType.includes(levelThree)
      )
    ) {
      let filArr = [];
      filters.chargerType.forEach((ct) => {
        let split = ct.split('-');

        let level = parseInt(split[0].split('level')[1]);

        let levelSplit = parseInt(split[1]);

        let startRangeSplit = 0;
        let endRangeSplit = 0;

        switch (true) {
          case levelSplit === 30 && level === 2:
            startRangeSplit = 7;
            endRangeSplit = 9.5;
            break;
          case levelSplit === 40 && level === 2:
            startRangeSplit = 9.6;
            endRangeSplit = 11.5;
            break;
          case levelSplit === 50 && level === 2:
            startRangeSplit = 11.6;
            endRangeSplit = 21.9;
            break;
          case levelSplit === 22 && level === 3:
            startRangeSplit = 22;
            endRangeSplit = 49;
            break;
          case levelSplit === 50 && level === 3:
            startRangeSplit = 50;
            endRangeSplit = 99;
            break;
          case levelSplit === 100 && level === 3:
            startRangeSplit = 100;
            endRangeSplit = 149;
            break;
          case levelSplit === 150 && level === 3:
            startRangeSplit = 150;
            endRangeSplit = 199;
            break;
          case levelSplit === 200 && level === 3:
            startRangeSplit = 200;
            endRangeSplit = 350;
            break;
          default:
            break;
        }

        filteredSessions.forEach((s) => {
          if (s.chargerLevel === level) {
            if (
              s.chargerOutput >= startRangeSplit &&
              s.chargerOutput <= endRangeSplit
            ) {
              filArr.push(s);
            }
          }
        });
      });

      filteredSessions = filArr;
    }
  }

  return filteredSessions;
};

export const filteredSessionsCalculations = (sessions) => {
  let totalSessionTime = 0;
  let averageSessionTime = 0;
  let averagekWOutput = 0;
  let averagekWh = 0;
  let totalkW = 0;
  let totalkWh = 0;
  let totalRange = 0;
  let totalSessionAmt = 0;
  let totalIdleAmt = 0;

  sessions.forEach((s) => {
    totalSessionTime += s.totalTime;
    totalkW += s.avgkW;
    totalkWh += s.totalkWh;
    totalRange += s.milesAdded;
    totalSessionAmt += s.totalCost;
    totalIdleAmt += s.idleCost;
  });

  if (totalSessionTime > 0) {
    averageSessionTime = totalSessionTime / sessions.length;
  }
  if (totalkWh > 0) {
    averagekWh = totalkWh / sessions.length;
    averagekWOutput = totalkW / sessions.length;
  }

  return {
    totalSessionTime,
    averageSessionTime,
    averagekWOutput,
    averagekWh,
    totalkWh,
    totalRange,
    totalSessionAmt,
    totalIdleAmt,
  };
};
