import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppModalService from './AppModalService';

//global components
import ModalButton from '../../../../../shared/Buttons/ModalButton';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  ButtonContainerStyles,
} from '../../../../../SharedStyles';

const ConfirmChange = (props) => {
  const { strings } = AppModalService;

  const closeModal = () => {
    props.closeModal();
  };

  const handleConfirm = () => {
    props.handleConnectorDataChange(
      props.data.id,
      props.data.eventName,
      props.data.eventValue
    );
    closeModal();
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.chargingProfileChangeTitle}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <p>
              {strings.chargingProfileChangeContent}
              {props.data.id}
              {strings.chargingProfileChangeContentTwo}
            </p>
          </ModalItemStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ButtonContainerStyles
          display='flex'
          justifyContent='flex-end'
          margin='0'>
          <ModalButton
            containerMargin='10px 10px 0'
            containerAlign='right'
            buttonPadding='10px'
            buttonMargin='0'
            handleClick={closeModal}
            buttonText={strings.cancel}
          />
          <ModalButton
            containerMargin='10px 10px 0'
            containerAlign='right'
            buttonPadding='10px'
            buttonMargin='0'
            handleClick={handleConfirm}
            buttonText={strings.ok}
          />
        </ButtonContainerStyles>
      </ModalContainerStyles>
      {/* <div className='AppCso-modal-container'>
        <div className='AppCso-modal-title'>
          <div>{strings.chargingProfileChangeTitle}</div>
          <div className='AppCso-modal-close' onClick={closeModal}>
            <FaRegWindowClose />
          </div>
        </div>
        <div className='AppCso-modal-content'>
          <p>
            {strings.chargingProfileChangeContent}
            {props.data.id}
            {strings.chargingProfileChangeContentTwo}
          </p>
        </div>
        <div className='AppCso-modal-button-container'>
          <div onClick={closeModal} className='AppCso-modal-button'>
            {strings.cancel}
          </div>
          <div onClick={handleConfirm} className='AppCso-modal-button'>
            {strings.ok}
          </div>
        </div>
      </div> */}
    </ModalStyles>
  );
};

export default ConfirmChange;
