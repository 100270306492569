import React, { createContext, useState } from 'react';

export const CouponContext = createContext();

const Coupon = (props) => {
  const [coupon, setCoupon] = useState(null);

  return (
    <CouponContext.Provider
      value={{
        coupon,
        setCoupon,
      }}>
      {props.children}
    </CouponContext.Provider>
  );
};

export default Coupon;
