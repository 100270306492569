import moment from 'moment';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import locationStrings from '../Locations/strings';
import meterGroupStrings from '../MeterGroups/strings';
import systemUserStrings from '../SystemUsers/strings';
import membershipStrings from '../Memberships/strings';
import chargerStrings from '../Chargers/strings';
import statementStrings from '../Statements/strings';
import payoutStrings from '../Reports/Details/Shared/IncomeReports/strings';

const locStrings = locationStrings[AppPlatformService.getLanguage()];
const mgStrings = meterGroupStrings[AppPlatformService.getLanguage()];
const suStrings = systemUserStrings[AppPlatformService.getLanguage()];
const msStrings = membershipStrings[AppPlatformService.getLanguage()];
const charStrings = chargerStrings[AppPlatformService.getLanguage()];
const statStrings = statementStrings[AppPlatformService.getLanguage()];
const payStrings = payoutStrings[AppPlatformService.getLanguage()];

//Location filter settings
let locFilterObject = {};
const locationFilter = ['name', 'address', 'highCurrentLoad'];
locStrings.filterList.forEach((action, ind) => {
  locationFilter.forEach((obj, index) => {
    if (ind === index) {
      locFilterObject = { ...locFilterObject, [action]: obj };
    }
  });
});
export const locationFiltersObject = locFilterObject;

//MeterGroup Settings
let meterGroupObject = {};
const meterGroupFilter = [
  'name',
  'locationAddress',
  'locationName',
  'lotId',
  'enabled',
  'highCurrentLoad',
];
mgStrings.filterList.forEach((action, ind) => {
  meterGroupFilter.forEach((obj, index) => {
    if (ind === index) {
      meterGroupObject = { ...meterGroupObject, [action]: obj };
    }
  });
});
export const MeterGroupFilterObjects = meterGroupObject;

//Systemuser Settings
let systemUserObject = {};
const systemUserFilter = [
  'firstName',
  'lastName',
  'email',
  'role',
  'locationDisplayNameSearch',
  'pending',
  'nonPending',
  'expires',
];
suStrings.filterList.forEach((action, ind) => {
  systemUserFilter.forEach((obj, index) => {
    if (ind === index) {
      systemUserObject = { ...systemUserObject, [action]: obj };
    }
  });
});
export const systemUserFilterObjects = systemUserObject;

//Membership filter settings
let membershipObject = {};
const membershipFilter = ['membershipName'];
msStrings.filterList.forEach((action, ind) => {
  membershipFilter.forEach((obj, index) => {
    if (ind === index) {
      membershipObject = { ...membershipObject, [action]: obj };
    }
  });
});
export const membershipFilterObjects = membershipObject;

//Members filter settings
let membersObject = {};
const membersFilter = [
  'lastName',
  'firstName',
  'email',
  'approved',
  'paused',
  'memberSince',
  'expired',
  'last7Days',
  'last30Days',
  'last3Months',
  'last6Months',
  'lastYear',
  'thisYear'
];
msStrings.membersFilter.forEach((action, ind) => {
  membersFilter.forEach((obj, index) => {
    if (ind === index) {
      membersObject = { ...membersObject, [action]: obj };
    }
  });
});
export const membersFilterObjects = membersObject;

//session filter settings
let sessionObject = {};

const sessionFilter = [
  'transactionDate',
  'transactionId',
  'userId',
  'rateType',
  'totalCost',
  'idleCost',
  'free',
];
charStrings.sessionAdminFilterList.forEach((action, ind) => {
  sessionFilter.forEach((obj, index) => {
    if (ind === index) {
      sessionObject = { ...sessionObject, [action]: obj };
    }
  });
});

export const sessionFilterObjects = sessionObject;

//statements filter settings
let statementObject = {};

const statementFilter = [
  'statementDate',
  'activityPeriod',
  'totalChargeAmount',
  'totalIdleFees',
  'totalEarnings',
  'totalSubscriptionFees',
];
statStrings.filterList.forEach((action, ind) => {
  statementFilter.forEach((obj, index) => {
    if (ind === index) {
      statementObject = { ...statementObject, [action]: obj };
    }
  });
});
export const statementFilterObjects = statementObject;

//payout filter settings
let payoutObject = {};

const payoutFilter = ['payoutDate', 'transactionId', 'amount'];
payStrings.filterList.forEach((action, ind) => {
  payoutFilter.forEach((obj, index) => {
    if (ind === index) {
      payoutObject = { ...payoutObject, [action]: obj };
    }
  });
});
export const payoutFilterObjects = payoutObject;

//Time calcuate function difference in
export const filterByTime = (filterTime) => {
  let today = moment();
  if (filterTime === 'last7Days') {
    return today.subtract(7, 'd').format('YYYY-MM-DD');
  }
  if (filterTime === 'last30Days') {
    return today.subtract(30, 'd').format('YYYY-MM-DD');
  }
  if (filterTime === 'last3Months') {
    return today.subtract(3, 'M').format('YYYY-MM-DD');
  }
  if (filterTime === 'last6Months') {
    return today.subtract(6, 'M').format('YYYY-MM-DD');
  }
  if (filterTime === 'lastYear') {
    return today.subtract(1, 'y').format('YYYY-MM-DD');
  }
  if (filterTime === 'thisYear') {
    return today.format('YYYY-MM-DD');
  }
};
