import { useState, useEffect, useReducer } from 'react';

function reducer(state, action) {
  switch (action.type) {
    case 'increment':
      return (state += action.payload);
    default:
      // throw new Error();
      return state;
  }
}

const ChargerProgressLogic = (props) => {
  const stopValue = parseFloat(props.desiredRange);
  const { handleStartStop } = props;

  const [valuesIndex, dispatch] = useReducer(reducer, parseFloat(props.value));
  const [chargeComplete, setChargeComplete] = useState(false);
  const { isCharging } = props;

  useEffect(() => {
    setChargeComplete(false);
  }, [stopValue]);

  useEffect(() => {
    dispatch({
      type: 'increment',
      payload: props.addedRange,
    });
  }, [props.addedRange, isCharging]);

  useEffect(() => {
    const progress = () => {
      if (!chargeComplete && isCharging) {
        if (valuesIndex === stopValue) {
          setChargeComplete(true);
          handleStartStop();
        } else {
          if (isCharging) {
            dispatch({
              type: 'increment',
              payload: props.addedRange,
            });
          }
        }
      }
    };
    progress();
    return () => {
      setChargeComplete(true);
    };
  }, [
    valuesIndex,
    chargeComplete,
    isCharging,
    props.addedRange,
    handleStartStop,
    stopValue,
  ]);

  return props.children({ valuesIndex, stopValue });
};

export default ChargerProgressLogic;
