import styled from 'styled-components';

// removedHeight
export const TableStyles = styled.div`
  font-size: ${({ size }) => (size ? size : '12px')};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  border-top: ${({ borderTop }) => borderTop};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? borderBottom : 'none'};
  border-right: ${({ borderRight }) => borderRight};
  min-height: ${({ minHeight }) => minHeight};
  max-height: ${({ maxHeight }) => maxHeight};
  width: ${({ width }) => (width ? width : '100%')};
  flex: ${({ flex }) => (flex ? flex : '100%')};
  overflow: ${({ minHeight }) => minHeight && 'scroll'};
  overflow-x: ${({ overflowX }) => overflowX};
  -ms-overflow-style: ${({ hideScroll }) => hideScroll && 'none'}; // IE 10+
  scrollbar-width: ${({ hideScroll }) => hideScroll && 'none'};
  &::-webkit-scrollbar {
    display: ${({ hideScroll }) => hideScroll && 'none'}; // Safari and Chrome
  }
`;

export const TableHeaderStyles = styled.div`
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 1;
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? borderBottom : '1px solid #e7e7e7'};
  border-top: ${({ borderTop }) =>
    borderTop ? borderTop : '1px solid #e7e7e7'};
  border-right: ${({ borderRight }) =>
    borderRight ? borderRight : '1px solid #e7e7e7'};
  border-left: ${({ borderLeft }) =>
    borderLeft ? borderLeft : '1px solid #e7e7e7'};
  background-color: #fcfcfc;
  color: #28456a;
  font-weight: 500;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  padding: ${({ padding }) => (padding ? padding : '10px 0')};
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ maxWidth }) => maxWidth};
  white-space: ${({ whiteSpace }) => whiteSpace};
`;

export const TableRowStyles = styled.div`
  position: relative;
  display: flex;
  color: #8f8d8d;
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? borderBottom : '1px solid #e7e7e7'};
  border-top: ${({ borderTop }) =>
    borderTop ? borderTop : '1px solid #e7e7e7'};
  border-right: ${({ borderRight }) =>
    borderRight ? borderRight : '1px solid #e7e7e7'};
  border-left: ${({ borderLeft }) =>
    borderLeft ? borderLeft : '1px solid #e7e7e7'};
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding: ${({ padding }) => (padding ? padding : '10px 0')};
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};

  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ maxWidth }) => maxWidth};
  background: ${({ background }) => background};
  background: ${({ rowbackground }) => rowbackground};
  text-align: ${({ align }) => align};
  &:nth-child(even) {
    background: ${({ rowbackground }) => !rowbackground && '#f7f7f7'};
  }
  &:hover {
    background: ${({ hoverBackground }) => hoverBackground};
  }
  a {
    position: relative;
    display: flex;
    color: #8f8d8d;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    background: ${({ background }) => background};
    text-decoration: none;
    width: ${({ width }) => width};
    &:hover,
    &:focus {
      text-decoration: none;
    }
    .Card-Tools-Toggle {
      color: #2c456c;
      height: 20px;
      cursor: pointer;
      margin: 0 0 0 5px;
      position: absolute;
      right: 0;
      z-index: 4000;
    }
  }
  .Card-Tools-Toggle {
    color: #2c456c;
    height: 20px;
    cursor: pointer;
    margin: 0 0 0 5px;
    position: absolute;
    right: 10px;
  }
`;

export const TableItemStyles = styled.div`
  position: relative;
  padding: ${({ padding }) => (padding ? padding : '0 5px')};
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ maxWidth }) => maxWidth};
  flex: ${({ flex }) => flex};
  border-right: ${({ borderRight }) =>
    borderRight ? '1px solid #000' : 'none'};
  display: flex;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'center'};
  cursor: pointer;
  text-align: ${({ align }) => align};
  &:last-child {
    border: none;
  }
  a {
    display: block;
    text-align: center;
    color: blue;
    /* text-decoration: underline; */
  }
`;
