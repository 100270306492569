import React from 'react';

//styles
import { ButtonContainerStyles, ButtonModalStyles } from '../../SharedStyles';

const ModalButton = (props) => {
  return (
    <ButtonContainerStyles
      align={props.containerAlign}
      margin={props.containerMargin}>
      <ButtonModalStyles
        inverse={props.inverse}
        background={props.background}
        disabled={props.disabled}
        type='submit'
        form={props.formSubmit}
        padding={props.buttonPadding}
        margin={props.buttonMargin}
        onClick={props.handleClick}>
        {props.buttonText}
      </ButtonModalStyles>
    </ButtonContainerStyles>
  );
};

export default ModalButton;
