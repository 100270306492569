import styled from 'styled-components';

export const SectionImageGallery = styled.section``;

export const ImageContainerStyles = styled.div`
  display: block;
  position: relative;
  max-width: 100%;
  height: ${({ height }) => (height ? height : '11em')};
  clear: both;
  text-align: ${({ align }) => align};

  .Charger-Icons-Container {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 0 1em 1em 1em;
    display: flex;
    justify-content: space-between;
    div {
      svg {
        margin: 0 10px 0 0;
      }
    }
    svg {
      color: #e7e7e7;
      cursor: pointer;
    }
  }
`;
