import React, { useState, useContext } from 'react';

//layout
import { ComponentWrapper, OuterWrapper, RoleCanEdit } from '../OperatorShared';

//services
import AppStatementsService from './AppStatementsService';

//components
import StatementHeader from './StatementComponents/StatementHeader';
import StatementList from './StatementList/StatementList';

//global components
import SearchFilterBar from '../OperatorShared/SearchFilterBar';

//context
import { CsoAccountContext } from '../../context/operator/getCsoAccount';
import SubscriptionAccess from '../OperatorShared/SubscriptionAccess';

const Statements = () => {
  const { strings } = AppStatementsService;
  const { account } = useContext(CsoAccountContext);
  const [searchedValue, setSearchedValue] = useState('');
  const [filteredValue, setFilteredValue] = useState(null);
  const [submitSearch, setSubmitSearch] = useState(false);

  const handleFilterChange = filter => {
    setSubmitSearch(false);
    setFilteredValue(filter);
  };

  const handleSearchChange = value => {
    setSubmitSearch(false);
    setSearchedValue(value);
  };

  const handleSearchSubmit = submit => {
    setSubmitSearch(submit);
  };

  return (
    <OuterWrapper title={strings.pageTitle} internal path='/'>
      <SubscriptionAccess levels={[1, 2, 3]}>
        <RoleCanEdit roles={[1, 2]}>
          <ComponentWrapper title={strings.pageTitle}>
            <StatementHeader account={account} />
            <SearchFilterBar
              statementFilter
              filterBy={strings.filterList}
              handleSearchSubmit={handleSearchSubmit}
              handleSearchChange={handleSearchChange}
              handleFilterChange={handleFilterChange}
            />
            {/* list of searched and filtered results */}
            <StatementList
              filteredValue={submitSearch ? filteredValue : ''}
              searchedValue={submitSearch ? searchedValue : ''}
            />
          </ComponentWrapper>
        </RoleCanEdit>
      </SubscriptionAccess>
    </OuterWrapper>
  );
};

export default Statements;
