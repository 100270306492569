import React from 'react';

//layout
import { ComponentWrapper, OuterWrapper } from '../../operator/OperatorShared';

//styles
import {
  DivStyles,
  TextStyles,
  LIStyles,
  TitleStyles,
} from '../../SharedStyles';

const CsoPolicy = (props) => {
  const { strings } = props;

  return (
    <OuterWrapper
      title={strings.pageTitle}
      internal
      path='/'
      roles={[1, 2, 3, 4]}>
      <ComponentWrapper title={strings.pageTitle}>
        <DivStyles size='14px' padding='10px'>
          <TextStyles margin='0 0 10px'>
            {strings.lastUpdated}: July, 9 2020
          </TextStyles>
          <TextStyles margin='0 0 10px'>
            {strings.paragraphOneContentOne}
          </TextStyles>
          <TextStyles margin='0 0 10px'>
            {strings.paragraphTwoContentOne}
          </TextStyles>
          <TextStyles margin='0 0 10px'>
            {strings.paragraphThreeContentOne}
          </TextStyles>
          <ol>
            {/* 1. Information We Collect */}
            <LIStyles>
              <span className='bold'>{strings.informationListHeaderOne}</span>
              <TextStyles margin='0 0 10px'>
                {strings.informationParagraphContentOne}
              </TextStyles>
              <ol type='a'>
                {/* a. Information You Provide Directly to Us */}
                <LIStyles>
                  <span>{strings.informationTitleOne}</span>
                  <TextStyles margin='0 0 10px'>
                    {strings.informationParagraphContentThree}
                  </TextStyles>
                  <ul>
                    <LIStyles>{strings.informationLiOne}</LIStyles>
                    <LIStyles>{strings.informationLiTwo}</LIStyles>
                    <LIStyles>{strings.informationLiThree}</LIStyles>
                    <LIStyles>{strings.informationLiFour}</LIStyles>
                    <LIStyles>{strings.informationLiFive}</LIStyles>
                    <LIStyles>{strings.informationLiSix}</LIStyles>
                    <LIStyles>{strings.informationLiSeven}</LIStyles>
                  </ul>
                  <TextStyles margin='10px 0'>
                    {strings.informationParagraphContentFour}
                  </TextStyles>
                  <ul>
                    <LIStyles>{strings.informationLiEight}</LIStyles>
                    <LIStyles>{strings.informationLiNine}</LIStyles>
                    <LIStyles>{strings.informationLiTen}</LIStyles>
                    <LIStyles>{strings.informationLiEleven}</LIStyles>
                    <LIStyles>{strings.informationLiTwelve}</LIStyles>
                  </ul>
                </LIStyles>
                {/* b. Information We Collect From Third Parties */}
                <LIStyles>
                  <span>{strings.informationTitleTwo}</span>
                  <TextStyles margin='0 0 10px'>
                    {strings.informationTwoParagraphContentOne}
                  </TextStyles>
                  <TextStyles margin='0 0 10px'>
                    {strings.informationTwoParagraphContentTwo}
                  </TextStyles>
                  <ul>
                    <LIStyles>{strings.informationLiOne}</LIStyles>
                    <LIStyles>{strings.informationLiTwo}</LIStyles>
                    <LIStyles>{strings.informationLiThree}</LIStyles>
                    <LIStyles>{strings.informationLiFour}</LIStyles>
                    <LIStyles>{strings.informationLiFive}</LIStyles>
                    <LIStyles>{strings.informationLiSix}</LIStyles>
                    <LIStyles>{strings.informationLiSeven}</LIStyles>
                  </ul>
                  <TextStyles margin='10px 0'>
                    {strings.informationParagraphContentFour}
                  </TextStyles>
                  <ul>
                    <LIStyles>{strings.informationLiEight}</LIStyles>
                    <LIStyles>{strings.informationLiNine}</LIStyles>
                    <LIStyles>{strings.informationLiTen}</LIStyles>
                    <LIStyles>{strings.informationLiEleven}</LIStyles>
                    <LIStyles>{strings.informationLiTwelve}</LIStyles>
                  </ul>
                </LIStyles>
                {/* c. Information We Collect Automatically */}
                <LIStyles>
                  <span>{strings.informationTitleThree}</span>
                  <TextStyles margin='0 0 10px'>
                    {strings.informationThreeParagraphContentOne}
                  </TextStyles>
                  <TextStyles margin='0 0 10px'>
                    {strings.informationThreeParagraphContentTwo}
                  </TextStyles>
                  <TextStyles margin='0 0 10px'>
                    {strings.informationThreeParagraphContentThree}
                  </TextStyles>
                  <TextStyles margin='0 0 10px'>
                    {strings.informationThreeParagraphContentFour}
                  </TextStyles>
                  <ul>
                    <LIStyles>
                      <u>{strings.informationThreeLiOneHeader}</u>{' '}
                      {strings.informationThreeLiOneContent}
                    </LIStyles>
                    <LIStyles>
                      <u>{strings.informationThreeLiTwoHeader}</u>{' '}
                      {strings.informationThreeLiTwoContent}
                    </LIStyles>
                    <LIStyles>
                      <u>{strings.informationThreeLiThreeHeader}</u>{' '}
                      {strings.informationThreeLiThreeContent}
                    </LIStyles>
                    <LIStyles>
                      <u>{strings.informationThreeLiFourHeader}</u>{' '}
                      {strings.informationThreeLiFourContent}
                    </LIStyles>
                  </ul>
                </LIStyles>
              </ol>
            </LIStyles>
            {/* 2.  How We Use Information Collected*/}
            <LIStyles>
              <span className='bold'>{strings.howWeCollectHeader}</span>
              <TextStyles margin='0 0 10px'>
                {strings.howWeCollectParagraphOne}
              </TextStyles>
              <ul>
                <LIStyles>{strings.howWeCollectLiOne}</LIStyles>
                <LIStyles>{strings.howWeCollectLiTwo}</LIStyles>
                <LIStyles>{strings.howWeCollectLiThree}</LIStyles>
                <LIStyles>{strings.howWeCollectLiFour}</LIStyles>
                <LIStyles>{strings.howWeCollectLiFive}</LIStyles>
                <LIStyles>{strings.howWeCollectLiSix}</LIStyles>
                <LIStyles>{strings.howWeCollectLiSeven}</LIStyles>
                <LIStyles>{strings.howWeCollectLiEight}</LIStyles>
                <LIStyles>{strings.howWeCollectLiNine}</LIStyles>
                <LIStyles>{strings.howWeCollectLiTen}</LIStyles>
                <LIStyles>{strings.howWeCollectLiEleven}</LIStyles>
                <LIStyles>{strings.howWeCollectLiTwelve}</LIStyles>
                <LIStyles>{strings.howWeCollectLiThirteen}</LIStyles>
                <LIStyles>{strings.howWeCollectLiFourteen}</LIStyles>
                <LIStyles>{strings.howWeCollectLiFifteen}</LIStyles>
                <LIStyles>{strings.howWeCollectLiSixteen}</LIStyles>
                <LIStyles>{strings.howWeCollectLiSeventeen}</LIStyles>
                <LIStyles>{strings.howWeCollectLiEighteen}</LIStyles>
              </ul>
              <TextStyles margin='10px 0'>
                {strings.howWeCollectParagraphTwo}
              </TextStyles>
            </LIStyles>
            {/* 3.  When We Share Your Information */}
            <LIStyles>
              <span className='bold'>{strings.shareInformationHeader}</span>
              <TextStyles margin='0 0 10px'>
                {strings.shareInformationParagraphOne}
              </TextStyles>
              <ul>
                <LIStyles>{strings.shareInformationLiOne}</LIStyles>
                <LIStyles>{strings.shareInformationLiTwo}</LIStyles>
                <LIStyles>{strings.shareInformationLiThree}</LIStyles>
                <LIStyles>{strings.shareInformationLiFour}</LIStyles>
                <LIStyles>{strings.shareInformationLiFive}</LIStyles>
                <LIStyles>{strings.shareInformationLiSix}</LIStyles>
                <LIStyles>{strings.shareInformationLiSeven}</LIStyles>
                <LIStyles>{strings.shareInformationLiEight}</LIStyles>
                <LIStyles>{strings.shareInformationLiNine}</LIStyles>
              </ul>
            </LIStyles>
            {/* 4.  Online Analytics and Tailored Advertising */}
            <LIStyles>
              <span className='bold'>{strings.onlineAnalyticsHeader}</span>
              <TextStyles margin='0 0 10px'>
                {strings.shareInformationParagraphOne}
              </TextStyles>
              <TextStyles margin='0 0 10px'>
                {strings.onlineAnalyticsParagraphTwo}
              </TextStyles>
            </LIStyles>
            {/* 5.  Security Measures */}
            <LIStyles>
              <span className='bold'>{strings.securityMeasuresHeader}</span>
              <TextStyles margin='0 0 10px'>
                {strings.securityMeasuresParagraphOne}
              </TextStyles>
              <TextStyles margin='0 0 10px'>
                {strings.securityMeasuresParagraphTwo}
              </TextStyles>
              <TextStyles margin='0 0 10px'>
                {strings.securityMeasuresParagraphThree}
              </TextStyles>
              <TextStyles margin='0 0 10px'>
                {strings.securityMeasuresParagraphFour}
              </TextStyles>
            </LIStyles>
            {/* 6.  Your Choices */}
            <LIStyles>
              <span className='bold'>{strings.yourChoicesHeader}</span>
              <TextStyles margin='0 0 10px'>
                {strings.yourChoicesParagraphOne}
              </TextStyles>
              <TextStyles margin='0 0 10px'>
                {strings.yourChoicesParagraphTwo}
              </TextStyles>
              <TextStyles margin='0 0 10px'>
                {strings.yourChoicesParagraphThree}
              </TextStyles>
            </LIStyles>
            {/* 7.  Links to Third Party Websites and Services */}
            <LIStyles>
              <span className='bold'>{strings.linkedThirdPartyHeader}</span>
              <TextStyles margin='0 0 10px'>
                {strings.linkedThirdPartyParagraphOne}
              </TextStyles>
            </LIStyles>
            {/* 8.  EU-U.S. Privacy Shield and Swiss-U.S. Privacy Shield */}
            <LIStyles>
              <span className='bold'>{strings.privacyShieldHeader}</span>
              <TextStyles margin='0 0 10px'>
                {strings.privacyShieldParagraphOne}
              </TextStyles>
              <TextStyles margin='0 0 10px'>
                {strings.privacyShieldParagraphTwo}
              </TextStyles>
              <TextStyles margin='0 0 10px'>
                {strings.privacyShieldParagraphThree}
              </TextStyles>
              <TextStyles margin='0 0 10px'>
                {strings.privacyShieldParagraphFour}
              </TextStyles>
              <TextStyles margin='0 0 10px'>
                {strings.privacyShieldParagraphFive}
              </TextStyles>
              <TextStyles margin='0 0 10px'>
                {strings.privacyShieldParagraphSix}
              </TextStyles>
              <TextStyles margin='0 0 10px'>
                {strings.privacyShieldParagraphSeven}
              </TextStyles>
              <TextStyles margin='0 0 10px'>
                {strings.privacyShieldParagraphEight}
              </TextStyles>
            </LIStyles>
            {/* 9. Children’s Privacy */}
            <LIStyles>
              <span className='bold'>{strings.childPrivacyHeader}</span>
              <TextStyles margin='0 0 10px'>
                {strings.childPrivacyParagraphOne}
              </TextStyles>
            </LIStyles>
            {/* 10. Changes to Our Privacy Policy */}
            <LIStyles>
              <span className='bold'>{strings.changesToPrivacyHeader}</span>
              <TextStyles margin='0 0 10px'>
                {strings.changedToPrivacyParagraphOne}
              </TextStyles>
            </LIStyles>
            {/* 11. Contact Us */}
            <LIStyles>
              <span className='bold'>{strings.contactUsHeader}</span>
              <TextStyles margin='0 0 10px'>
                {strings.contactUsParagraph}
              </TextStyles>
            </LIStyles>
          </ol>
          <DivStyles margin='20px 0'>
            {/* Supplemental Privacy Notice for California Residents */}
            <TitleStyles
              margin='0 0 10px'
              size='14px'
              weight='600'
              align='center'>
              {strings.caliResHeader}
            </TitleStyles>
            <TextStyles margin='0 0 10px'>
              {strings.caliResParagraphOne}
            </TextStyles>

            {/* Summary of Information We Collect */}
            <TitleStyles margin='0 0 10px' size='14px' weight='600'>
              {strings.summaryInformationCollectedHeader}
            </TitleStyles>
            <TextStyles margin='0 0 10px'>
              {strings.summaryInformationCollectedOne}
            </TextStyles>
            <TextStyles margin='0 0 10px'>
              {strings.summaryInformationCollectedTwo}
            </TextStyles>
            <ul>
              <LIStyles>{strings.summaryInformationLiOne}</LIStyles>
              <LIStyles>{strings.summaryInformationLiTwo}</LIStyles>
              <LIStyles>{strings.summaryInformationLiThree}</LIStyles>
              <LIStyles>{strings.summaryInformationLiFour}</LIStyles>
              <LIStyles>{strings.summaryInformationLiFive}</LIStyles>
              <LIStyles>{strings.summaryInformationLiSix}</LIStyles>
              <LIStyles>{strings.summaryInformationLiSeven}</LIStyles>
              <LIStyles>{strings.summaryInformationLiEight}</LIStyles>
              <LIStyles>{strings.summaryInformationLiNine}</LIStyles>
            </ul>
            {/* Rights */}
            <TitleStyles margin='0 0 10px' size='14px' weight='600'>
              {strings.rightsHeader}
            </TitleStyles>
            <TextStyles margin='0 0 10px'>
              {strings.rightsParagraphOne}
            </TextStyles>

            <ul>
              <LIStyles>{strings.rightsLiOne}</LIStyles>
              <LIStyles>{strings.rightsLiTwo}</LIStyles>
              <LIStyles>{strings.rightsLiThree}</LIStyles>
            </ul>
            <TextStyles margin='0 0 10px'>
              {strings.rightsParagraphTwo}
            </TextStyles>
            <TextStyles margin='0 0 10px'>
              {strings.rightsParagraphThree}
            </TextStyles>
            <TextStyles margin='0 0 10px'>
              {strings.rightsParagraphFour}
            </TextStyles>
            {/* California Do-Not-Track */}
            <TitleStyles margin='0 0 10px' size='14px' weight='600'>
              {strings.doNotTrack}
            </TitleStyles>
            <TextStyles margin='0 0 10px'>
              {strings.doNotTrackParagraph}
            </TextStyles>
          </DivStyles>
          <DivStyles>
            <TextStyles>{strings.updated}: July, 9 2020</TextStyles>
          </DivStyles>
        </DivStyles>
      </ComponentWrapper>
    </OuterWrapper>
  );
};

export default CsoPolicy;
