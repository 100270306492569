import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import strings from './strings';
import AppApiService from '../../../services/AppApi/AppApiService';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppLocationsService = {
  getAllLocations: function() {
    return AppApiService.get('getAllLocations');
  },
  getSingleLocation: function(id) {
    return AppApiService.post('getSingleLocation', null, id);
  },
  createLocation: function(location) {
    return AppApiService.post('createLocation', null, location);
  },
  updateLocation: function(location) {
    return AppApiService.post('updateLocation', null, location);
  },
  deleteLocation: function(id) {
    return AppApiService.post('deleteLocation', null, id);
  },
  strings: localStrings
};

export default AppLocationsService;
