import strings from "./strings";
import AppPlatformService from "../../../services/AppPlatform/AppPlatformService";

const localStrings = strings[AppPlatformService.getLanguage()];

const AddressService = {
  strings: localStrings
};

export default AddressService;
