import React from 'react';
import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { ThemeProvider } from 'styled-components';

import AppPlatformService from '../AppPlatform/AppPlatformService';

//Context
import ContextWrapper from '../../components/context/ContextWrapper';
import CsoContextWrapper from '../../components/context/CsoContextWrapper';
//Shared Components
import AppLanding from '../../components/AppLanding/AppLanding';
import AppWebLanding from '../../components/AppWebLanding/AppWebLanding';
import AppNotFound from '../../components/shared/AppNotFound/AppNotFound';
import CsoNotFound from '../../components/shared/AppNotFound/CsoNotFound';

// import PrivateRoute from './SmallBusinessRouting';
import EnterpriseRouting from './EnterpriseRouting';
import SmallBusinessRouting from './SmallBusinessRouting';
import Offline from '../../components/shared/Offline/Offline';

import { CSO_THEME, DRIVER_THEME } from '../../components/shared';

import { driverRoutingMap } from './DriverRoutingSetup';
import { csoRoutingMap } from './CsoRoutingSetup';

const history = createBrowserHistory();

const isDevMode = AppPlatformService.getVersion() === 'dev';

const routingWeb = function (isAuthenticated) {
  if (localStorage.getItem('auth.token')) {
    isAuthenticated = true;
    if (isAuthenticated) {
      if (localStorage.getItem('driverAccount')) {
        return (
          <ThemeProvider theme={DRIVER_THEME}>
            <Router>
              <ContextWrapper>
                <Switch>
                  {/* Driver Routes */}
                  {driverRoutingMap
                    .filter((r) => (!isDevMode ? !r.devOnly : r))
                    .map((r, i) => {
                      return (
                        <Route
                          key={i}
                          exact
                          path={r.route}
                          component={r.component}
                        />
                      );
                    })}
                </Switch>
              </ContextWrapper>
            </Router>
          </ThemeProvider>
        );
      }
      if (localStorage.getItem('csoAccount')) {
        return (
          <ThemeProvider theme={CSO_THEME}>
            <Router>
              <CsoContextWrapper>
                <Switch>
                  {csoRoutingMap
                    .filter((r) => (!isDevMode ? !r.devOnly : r))
                    .map((r, i) => {
                      if (r.level === 1) {
                        return (
                          <Route
                            key={i}
                            exact
                            path={r.route}
                            component={r.component}
                          />
                        );
                      }
                      if (r.level === 2) {
                        return (
                          <SmallBusinessRouting
                            key={i}
                            exact
                            path={r.route}
                            component={r.component}
                          />
                        );
                      }
                      if (r.level === 3) {
                        return (
                          <EnterpriseRouting
                            key={i}
                            exact
                            path={r.route}
                            component={r.component}
                          />
                        );
                      }
                      return <Route key={i} path='*' component={CsoNotFound} />;
                    })}
                </Switch>
              </CsoContextWrapper>
            </Router>
          </ThemeProvider>
        );
      }
      if (
        !localStorage.getItem('driverAccount') &&
        !localStorage.getItem('csoAccount')
      ) {
        return (
          <Router>
            <Switch>
              <Route exact path='/' component={AppWebLanding} />
              <Redirect from='*' to='/' />
            </Switch>
          </Router>
        );
      }
    }
  } else {
    return (
      <Router>
        <Switch>
          <Route exact path='/' component={AppWebLanding} />
          <Redirect from='*' to='/' />
        </Switch>
      </Router>
    );
  }
};

const routingDriver = function (isAuthenticated) {
  if (isAuthenticated) {
    return (
      <ThemeProvider theme={DRIVER_THEME}>
        <HashRouter history={history}>
          <ContextWrapper>
            <Switch>
              {driverRoutingMap
                .filter((r) => (!isDevMode ? !r.devOnly : r))
                .map((r, i) => {
                  return (
                    <Route
                      key={i}
                      exact
                      path={r.route}
                      component={r.component}
                    />
                  );
                })}
            </Switch>
          </ContextWrapper>
        </HashRouter>
      </ThemeProvider>
    );
  } else {
    return (
      <HashRouter history={history}>
        <Switch>
          <Route exact path='/' component={AppLanding} />
        </Switch>
      </HashRouter>
    );
  }
};

const routingCSO = function (isAuthenticated) {
  if (isAuthenticated) {
    return (
      <ThemeProvider theme={CSO_THEME}>
        <HashRouter history={history}>
          <CsoContextWrapper>
            <Switch>
              {csoRoutingMap
                .filter((r) => (!isDevMode ? !r.devOnly : r))
                .map((r, i) => {
                  if (r.level === 1) {
                    return (
                      <Route
                        key={i}
                        exact
                        path={r.route}
                        component={r.component}
                      />
                    );
                  }
                  if (r.level === 2) {
                    return (
                      <SmallBusinessRouting
                        key={i}
                        exact
                        path={r.route}
                        component={r.component}
                      />
                    );
                  }
                  if (r.level === 3) {
                    return (
                      <EnterpriseRouting
                        key={i}
                        exact
                        path={r.route}
                        component={r.component}
                      />
                    );
                  }
                  return <Route key={i} path='*' component={CsoNotFound} />;
                })}
            </Switch>
          </CsoContextWrapper>
        </HashRouter>
      </ThemeProvider>
    );
  } else {
    return (
      <HashRouter history={history}>
        <Switch>
          <Route exact path='/' component={AppLanding} />
        </Switch>
      </HashRouter>
    );
  }
};

const routingOffline = function () {
  return (
    <HashRouter history={history}>
      <Switch>
        <Route exact path='/' component={Offline} />
      </Switch>
    </HashRouter>
  );
};

const AppRoutingService = {
  offlineRouting: function () {
    return routingOffline();
  },
  getRouting: function (isAuthenticated = false) {
    if (AppPlatformService.isDriverApp()) {
      AppPlatformService.debug(
        'AppRoutingService: Using routing for Driver app.'
      );

      localStorage.setItem('driverAccount', true);
      return routingDriver(isAuthenticated);
    } else if (AppPlatformService.isCSOApp()) {
      localStorage.setItem('csoAccount', true);

      AppPlatformService.debug('AppRoutingService: Using routing for CSO app.');
      return routingCSO(isAuthenticated);
    } else {
      /* isWebApp */
      AppPlatformService.debug('AppRoutingService: Using routing for Web app.');
      return routingWeb(isAuthenticated);
    }
  },
};

export default AppRoutingService;
