import React, { createContext, useState, useEffect } from 'react';

import AppChargingService from '../../driver/AppCharging/AppChargingService';

export const VehiclesContext = createContext();

const AllDriverVehicles = (props) => {
  const [vehicles, setVehicles] = useState([]);
  const [defaultVehicle, setDefaultVehicle] = useState({});
  const [vehicleLoading, setvehicleLoading] = useState(true);

  useEffect(() => {
    let vehicle = vehicles.filter((veh) => veh.default);
    setDefaultVehicle(vehicle[0]);
  }, [vehicles]);

  async function fetchVehicles() {
    let vehiclesData = await AppChargingService.getVehicles();
    // let driverLedger = await AppPaymentsService.getDriverLedger();

    let vehicleDataWithHistory = vehiclesData.map((vehicle) => {
      if (vehicle.default) {
        setDefaultVehicle(vehicle);
      }
      vehicle.chargingHistory = [];
      // let vehicleLedger = driverLedger.filter(
      //   ledger => ledger.vehicleId === vehicle.vehicleId
      // );
      // vehicle.chargingHistory = vehicleLedger;
      return vehicle;
    });

    setVehicles(vehicleDataWithHistory);
    // let timeOut = setTimeout(() => {
    //   if (driverLedger) {
    //     setvehicleLoading(false);
    //   }
    // }, 300);
    if (vehiclesData) {
      setvehicleLoading(false);
    }
    return () => {
      setvehicleLoading(false);
    };
  }

  useEffect(() => {
    fetchVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VehiclesContext.Provider
      value={{
        vehicles,
        defaultVehicle,
        vehicleLoading,
        setvehicleLoading,
        setVehicles,
        fetchVehicles,
      }}>
      {props.children}
    </VehiclesContext.Provider>
  );
};

export default AllDriverVehicles;
