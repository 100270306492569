import React, { useContext } from 'react';
import { FaArrowLeft } from 'react-icons/fa';

//global components
import { RoleCanEdit } from '../../OperatorShared';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';

//styles
import { MobileNavStyles, MobileSaveStyles } from '../../../SharedStyles';

const MobileMenu = React.forwardRef((props, ref) => {
  const { strings } = props;
  const { currentUser } = useContext(CsoAccountContext);

  return (
    <MobileNavStyles ref={ref}>
      <div className='Mobile-Nav-Container'>
        <div className='Arrow'>
          <span onClick={props.handleRedirection}>
            <FaArrowLeft />
          </span>
        </div>
        <div className='Title'>
          {props.title}{' '}
          {props.roles &&
            !props.roles.includes(currentUser.role) &&
            `- ${props.sharedStrings.readOnly}`}
        </div>
      </div>
      <RoleCanEdit roles={props.roles}>
        <>
          {props.onSaveClick && !props.disabled && (
            <div className='Save' onClick={props.onSaveClick}>
              {strings.save}
            </div>
          )}
          {props.myForm && (
            <MobileSaveStyles
              className='Save'
              type='submit'
              form={'myForm'}
              disabled={props.disabled}>
              {props.add
                ? strings.add
                : props.subscribe
                ? props.subscribe
                : props.save
                ? props.save
                : strings.save}
            </MobileSaveStyles>
          )}
        </>
      </RoleCanEdit>
    </MobileNavStyles>
  );
});

export default MobileMenu;
