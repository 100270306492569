import strings from './strings';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppNotFoundService = {
  strings: localStrings
};

export default AppNotFoundService;
