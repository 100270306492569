import styled from 'styled-components';

export const SearchFormStyles = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ padding }) => (padding ? padding : '10px 5px')};
  border-bottom: 1px solid #e7e7e7;
`;

export const SearchIconButton = styled.div`
  flex-basis: 80px;
  text-align: center;
  @media screen and (max-width: $${({ theme }) => theme.defaultMediaQuery}) {
    flex-basis: 50px;
    text-align: left;
  }
  button {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    svg {
      height: 38px;
      color: #8497b6;
      font-size: 30px;
      line-height: 30px;
      cursor: pointer;
      &:hover {
        color: #2c456c;
      }
    }
  }
`;
