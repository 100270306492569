import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

import { CsoAccountContext } from '../../components/context/operator/getCsoAccount';
import AppSharedAccessDenied from '../../components/shared/AppAccessDenied/AppSharedAccessDenied';

const SmallBusinessRouting = ({ component: Component, ...rest }) => {
  const { account } = useContext(CsoAccountContext);

  if (
    account &&
    !(account.subscriptionLevel === 0 || account.subscriptionLevel === 1)
  ) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  } else {
    return (
      <Route
        {...rest}
        render={(props) => <AppSharedAccessDenied {...props} />}
      />
    );
  }
};

export default SmallBusinessRouting;
