import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import strings from './strings';
import AppApiService from '../../../services/AppApi/AppApiService';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppChargingProfileService = {
  createChargingProfile: function(profile) {
    return AppApiService.post('createChargingProfile', null, profile);
  },
  updateChargingProfile: function(profile) {
    return AppApiService.post('updateChargingProfile', null, profile);
  },
  deleteChargingProfile: function(profile) {
    return AppApiService.post('deleteChargingProfile', null, profile);
  },
  strings: localStrings
};

export default AppChargingProfileService;
