import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';

//layout
import {
  OuterWrapper,
  ComponentWrapper,
  SubscriptionAccess,
} from '../../OperatorShared';
import Button from '../../../shared/Buttons/Button';

//services
import AppChargersService from '../AppChargersService';
import AppChargerDetailsService from '../../../driver/ChargerDetails/AppChargerDetailsService';

//components
import ChargerHeader from '../ChargerHeader';
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';
import CsoNotFound from '../../../shared/AppNotFound/CsoNotFound';
import AssignedUsers from './AssignedUsers';
import ToggleSwitch from '../../../driver/shared/ToggleSwitch';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import { ErrorContext } from '../../../context/shared/ErrorContext';

//hooks
import { useWindowResize } from '../../../shared/hooks/useWindowSize';

//styles
import {
  TitleStyles,
  DivStyles,
  JumboTronHeaderStyles,
  TextStyles,
  TableStyles,
  TableHeaderStyles,
  TableItemStyles,
  ListPlaceholderStyles,
} from '../../../SharedStyles';

//error
import { ErrorResponse } from '../../../shared/ErrorResponse';

const ChargerShare = (props) => {
  const { strings } = AppChargersService;
  const { currentUser } = useContext(CsoAccountContext);
  const { setError } = useContext(ErrorContext);
  const [charger, setCharger] = useState(null);
  const [driverLinkRecords, setDriverLinkRecords] = useState([]);
  const [token, setToken] = useState({
    display: false,
  });
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState({
    display: false,
    message: '',
  });

  const size = useWindowResize(window.innerWidth, window.innerHeight);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const request = {
          iotHubDeviceId: props.match.params.id,
          location: currentUser.userLocation,
        };

        let response = await AppChargersService.getCharger(request);
        let driverRecords = await AppChargersService.getChargerLinksFromDriver(
          props.match.params.id
        );

        setCharger(response);
        setDriverLinkRecords(driverRecords);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setNotFound({
          display: true,
          message: strings.fourOhFour,
        });
      }
    };
    fetchData();
  }, [props.match.params.id, strings, currentUser.userLocation]);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        let tokenResult = await AppChargersService.csoGetChargerToken(
          props.match.params.id
        );
        if (tokenResult !== null) {
          setToken({ display: true, ...tokenResult });
          setLoading(false);
        }
      } catch (err) {
        setError({
          display: true,
          title: strings.noTokenTitle,
          message: strings.noTokenContent,
          styles: 'cso',
        });
      }
    };

    fetchToken();
  }, [props.match.params.id, setError, strings]);

  const removeAssignedDriver = async (record) => {
    const updatedCharger = { ...record, assigned: false, home: false };

    let res = await AppChargerDetailsService.linkDriverCharger(updatedCharger);
    if (res) {
      let updatedLinks = driverLinkRecords.filter((records) => {
        return records.id !== record.id;
      });
      setDriverLinkRecords(updatedLinks);
    }
  };

  const getToken = async () => {
    setLoading(true);

    const chargingStation = {
      iotHubDeviceId: charger.iotHubDeviceId,
      tokenType: 'ownedChargerToken',
    };

    try {
      let tokenData = await AppChargersService.createToken(chargingStation);
      if (tokenData) {
        setToken({ ...token, display: true, ...tokenData });
        setLoading(false);
      }
    } catch (err) {
      setError({
        display: true,
        title: 'Something went wrong',
        message: err.response.data.split(/[|]+/).pop(),
        styles: 'cso',
      });
    }
  };

  const togglePrivate = async (e) => {
    try {
      let privateOptions = {
        iotHubDeviceId: charger.iotHubDeviceId,
        private: e.target.checked,
      };

      if (driverLinkRecords.length > 0 && charger.private) {
        throw new ErrorResponse(
          'frontend',
          strings.privateToggleFailMessage,
          strings.privateToggleFail
        );
      }

      let res = await AppChargersService.setChargingStationPrivacy(
        privateOptions
      );

      if (res) {
        setCharger({ ...charger, private: res.private });
      }
    } catch (err) {
      setError({
        display: true,
        title: err.title,
        message: err.message,
        styles: 'cso',
      });
    }
  };

  if (notFound.display) {
    return <CsoNotFound message={notFound.message} />;
  }

  return (
    <OuterWrapper title={strings.sharePage} internal path='/chargers'>
      {loading && <LoadingSpinner />}
      <ComponentWrapper title={strings.sharePage}>
        {charger && <ChargerHeader charger={charger} />}
        <JumboTronHeaderStyles borderBottom='none'>
          <TitleStyles size='25px' weight='400' margin='10px auto'>
            {strings.tokenCode}
          </TitleStyles>
          {token.display && (
            <>
              <TitleStyles size='40px' margin='10px 0 20px' weight='400'>
                {token.token}
              </TitleStyles>
              <TextStyles size='14px'>
                {strings.expires}:{' '}
                {moment(token.expiration).format('MM/DD/YYYY h:mm a')}
              </TextStyles>
            </>
          )}
        </JumboTronHeaderStyles>
        <SubscriptionAccess levels={[0, 1]}>
          <Button
            cso
            margin='10px auto 15px'
            disabled={token.token}
            onclick={getToken}
            buttonText={strings.tokenGenerate}
            roles={[1, 2, 3, 4]}
          />
        </SubscriptionAccess>
        <SubscriptionAccess levels={[2, 3]}>
          <Button
            cso
            margin='10px auto 15px'
            onclick={getToken}
            buttonText={strings.tokenGenerate}
            disabled={(charger && !charger.enabledSubscription) || token.token}
            roles={[1, 2, 3, 4]}
          />
          {charger && !charger.enabledSubscription && (
            <TextStyles align='center' color='red' margin='0 0 10px'>
              Disabled due to not being an enabled subscription charger.
            </TextStyles>
          )}
        </SubscriptionAccess>

        {charger && (
          <>
            <SubscriptionAccess levels={[0, 1]}>
              <DivStyles padding='0 15%' margin='0 0 30px'>
                <TextStyles size='14px'>
                  <strong>{strings.note}</strong>: {strings.tokenNoteOwned}{' '}
                  <em>{strings.ampedup}</em> {strings.tokenNoteTwoOwned}
                </TextStyles>
              </DivStyles>
            </SubscriptionAccess>
            <SubscriptionAccess levels={[2, 3]}>
              {charger.enabledSubscription ? (
                <DivStyles padding='0 15%' margin='0 0 30px'>
                  <TextStyles size='14px'>
                    <strong>{strings.note}</strong>: {strings.tokenNoteAssigned}{' '}
                    <em>{strings.ampedup}</em> {strings.tokenNoteTwoAssigned}
                  </TextStyles>
                </DivStyles>
              ) : (
                <DivStyles padding='0 15%' margin='0 0 30px'>
                  <TextStyles size='14px'>
                    <strong>{strings.note}</strong>: {strings.tokenNoteOwned}{' '}
                    <em>{strings.ampedup}</em> {strings.tokenNoteTwoOwned}
                  </TextStyles>
                </DivStyles>
              )}
            </SubscriptionAccess>
          </>
        )}
        {/* Private Switch */}
        {charger && (
          <DivStyles>
            <ToggleSwitch
              justifyContent='center'
              label={strings.makePrivate}
              checked={!!charger.private}
              handleChange={togglePrivate}
            />
          </DivStyles>
        )}
        {charger && driverLinkRecords && charger.private && (
          <>
            <TitleStyles
              size='22px'
              weight='400'
              margin='25px auto'
              align='center'>
              <SubscriptionAccess levels={[0, 1]}>
                {strings.shareOwned}
              </SubscriptionAccess>
              <SubscriptionAccess levels={[2, 3]}>
                {charger.enabledSubscription
                  ? strings.shareAssigned
                  : strings.shareOwned}
              </SubscriptionAccess>
            </TitleStyles>
            <TableStyles minHeight='300px'>
              <TableHeaderStyles
                borderLeft='none'
                borderRight='none'
                padding='5px 0'
                width={size[0] >= 768 ? '100%' : '100%'}>
                <TableItemStyles flex='1' borderRight>
                  {strings.shareTableFirst}
                </TableItemStyles>
                <TableItemStyles flex='1' borderRight>
                  {strings.shareTableLast}
                </TableItemStyles>
                <TableItemStyles flex='1' borderRight>
                  {strings.shareTableEmail}
                </TableItemStyles>
              </TableHeaderStyles>
              {driverLinkRecords.map((driver) => {
                return (
                  <AssignedUsers
                    key={driver.userId}
                    driver={driver}
                    removeAssignedDriver={removeAssignedDriver}
                  />
                );
              })}
              {driverLinkRecords.length === 0 && (
                <ListPlaceholderStyles>
                  <p>{strings.shareNoAssigned}.</p>
                </ListPlaceholderStyles>
              )}
            </TableStyles>
          </>
        )}
      </ComponentWrapper>
    </OuterWrapper>
  );
};

export default ChargerShare;
