import AppPlatformService from '../AppPlatform/AppPlatformService';

let AppGeoPosition = function () {
  this.lat = 0;
  this.lng = 0;

  this.getSet = function () {
    return { lat: this.lat, lng: this.lng };
  };
};

function showError(error) {
  console.log('error', error);
  switch (error.code) {
    case error.PERMISSION_DENIED:
      localStorage.setItem(
        'geoLocation',
        JSON.stringify({
          denied: true,
          message: 'User denied the request for Geolocation.',
        })
      );
      break;
    case error.POSITION_UNAVAILABLE:
      localStorage.setItem(
        'geoLocation',
        JSON.stringify({
          denied: true,
          message: 'Location information is unavailable.',
        })
      );
      break;
    case error.TIMEOUT:
      localStorage.setItem(
        'geoLocation',
        JSON.stringify({
          denied: true,
          message: 'The request to get user location timed out.',
        })
      );
      break;
    case error.UNKNOWN_ERROR:
      localStorage.setItem(
        'geoLocation',
        JSON.stringify({ denied: true, message: 'An unknown error occurred.' })
      );
      break;
    default:
      localStorage.setItem(
        'geoLocation',
        JSON.stringify({
          denied: true,
          message: 'An unknown error occurred.',
        })
      );
  }
}

const AppGeoLocationService = {
  startObservation: function () {
    return new Promise((resolve, reject) => {
      reject('Not yet implemented');
    });
  },
  endObservation: function () {
    return new Promise((resolve, reject) => {
      reject('Not yet implemented');
    });
  },
  getCurrentPosition: function () {
    return new Promise((resolve, reject) => {
      if (AppPlatformService.isWebApp()) {
        AppPlatformService.debug(
          'AppGeoLocationService: Using browser geolocation implementation...'
        );
        let currentPosition = new AppGeoPosition();
        navigator.geolocation.getCurrentPosition(
          (position) => {
            currentPosition.lat = position.coords.latitude;
            currentPosition.lng = position.coords.longitude;
            resolve(currentPosition);
          },
          (err) => {
            let error = showError(err);
            return reject(error);
          }
        );
      } else {
        AppPlatformService.debug(
          'AppGeoLocationService: Using Cordova geolocation implementation...'
        );
        let currentPosition = new AppGeoPosition();
        navigator.geolocation.getCurrentPosition(
          (position) => {
            currentPosition.lat = position.coords.latitude;
            currentPosition.lng = position.coords.longitude;
            resolve(currentPosition);
          },
          (err) => {
            let error = showError(err);
            return reject(error);
          },
          { enableHighAccuracy: true }
        );
      }
    });
  },
};

export { AppGeoLocationService, AppGeoPosition };
