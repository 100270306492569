import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { FaRegTrashAlt, FaRegCheckSquare, FaRegThumbsUp } from 'react-icons/fa';

//services
import AppSlideService from './AppSlideService';
import AppChargersService from '../../operator/Chargers/AppChargersService';

//global components
import { RoleCanEdit } from '../../operator/OperatorShared';
import { LoadingSpinner } from '../../shared';

const SlideItem = (props) => {
  const [loading, setLoading] = useState(false);

  const approveImage = async () => {
    setLoading(true);
    let approvedImage = await AppSlideService.approveChargerImage({
      iotHubDeviceId: props.match.params.id,
      imageId: props.image.id,
    });
    await props.updateCharger(approvedImage);
    setLoading(false);
  };

  const deleteImage = async () => {
    setLoading(true);
    let deletedImage = await AppSlideService.deleteChargerImage({
      iotHubDeviceId: props.match.params.id,
      imageId: props.image.id,
    });

    if (props.index !== 0) {
      props.prevSlide();
    }

    await props.updateCharger(deletedImage);
    setLoading(false);
  };

  const makeImageDefault = async () => {
    props.updateDefaultImage(props.image.id);
    props.handleDataChange('imagePath', props.image.imagePath);
    await AppChargersService.updateCharger({
      ...props.charger,
      imagePath: props.image.imagePath,
    });
  };

  return (
    <div
      className={
        props.image.approved ? 'slider-item' : 'slider-item unapproved'
      }>
      <img
        className={props.image.approved ? 'slide' : 'slide unapproved'}
        style={
          props.image.placeholder && {
            objectFit: 'contain',
            padding: '10px',
          }
        }
        src={props.image.imagePath}
        alt='slider'
      />
      <div className='image-actions'>
        {loading && <LoadingSpinner />}
        <RoleCanEdit roles={[1, 2, 4]}>
          {!props.image.approved && (
            <FaRegCheckSquare title='Approve Image' onClick={approveImage} />
          )}
          {props.image.approved && !props.image.default && (
            <FaRegThumbsUp title='Make Default' onClick={makeImageDefault} />
          )}
          {!props.image.placeholder && !props.image.default && (
            <FaRegTrashAlt title='Delete Image' onClick={deleteImage} />
          )}
        </RoleCanEdit>
      </div>
    </div>
  );
};

export default withRouter(SlideItem);
