import React, { createContext, useState } from 'react';

export const SearchContext = createContext();

const PreviousMapSearch = props => {
  const [searched, setSearched] = useState({});
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <SearchContext.Provider
      value={{
        setSearched,
        searched,
        currentTab,
        setCurrentTab
      }}>
      {props.children}
    </SearchContext.Provider>
  );
};

export default PreviousMapSearch;
