import React, { useState, useEffect } from 'react';
import { withRouter, Prompt } from 'react-router-dom';
import LeaveModal from './LeaveModal';

const RouteLeavingGuard = (props) => {
  const { when, navigate, shouldBlockNavigation } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [allowCancel, setAllowCancel] = useState(false);
  const [allowSave, setAllowSave] = useState(false);

  console.log(confirmedNavigation);

  const showModal = (location) => {
    setModalVisible(true);
    setLastLocation(location);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const closeModalCallback = (callback) => {
    setModalVisible(false);
    callback();
  };

  const closeModalCancel = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
    setAllowCancel(true);
  };

  useEffect(() => {
    setConfirmedNavigation(false);
    // eslint-disable-next-line
  }, [props.error]);

  useEffect(() => {
    if (lastLocation) {
      navigate(lastLocation.pathname);
    }
    // eslint-disable-next-line
  }, [allowCancel]);

  useEffect(() => {
    if (allowSave && confirmedNavigation) {
      props.action();
    }
    // eslint-disable-next-line
  }, [allowSave, confirmedNavigation]);

  useEffect(() => {
    if (lastLocation && !props.loading && !props.error) {
      navigate(lastLocation.pathname);
    }
    // eslint-disable-next-line
  }, [props.loading]);

  const handleBlockedNavigation = (nextLocation) => {
    console.log(
      'confirmedNavigation',
      confirmedNavigation,
      'shouldblock',
      shouldBlockNavigation(nextLocation)
    );
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    closeModalCallback(() => {
      if (lastLocation) {
        setAllowSave(true);
        setConfirmedNavigation(true);
      }
    });
  };

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <LeaveModal
        action={props.action}
        requiredFields={props.requiredFields}
        cso={props.cso}
        driver={props.driver}
        visible={modalVisible}
        closeModal={closeModal}
        onCancel={closeModalCancel}
        onConfirm={handleConfirmNavigationClick}
      />
    </>
  );
};

export default withRouter(RouteLeavingGuard);
