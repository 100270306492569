import React, { useState, useContext } from 'react';

//modals
import DisableModal from './DisableModal';
import MissingInfoModal from '../../MeterGroups/MeterGroupsModals/DisplayModals/MissingInfoModal';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import { ErrorContext } from '../../../context/shared/ErrorContext';

//styles
import {
  ToggleContainerStyles,
  ToggleSwitchStyles,
  ToggleLabelStyles,
} from '../../../SharedStyles';

const ToggleSwitch = (props) => {
  const { currentUser } = useContext(CsoAccountContext);
  const [disableModal, setDisableModal] = useState(false);
  const [missingInfoModal, setMissingInfoModal] = useState(false);
  const { setError } = useContext(ErrorContext);

  const handleModalPopup = (e) => {
    if (props.name === 'allDay' && props.disable) {
      setError({
        display: true,
        title: 'Invalid Time Options',
        message: 'You can only have one time for all day schedule.',
        styles: 'cso',
      });
    } else if (props.deny) {
      setError({
        display: true,
        title: 'Invalid Permissions',
        message: 'Please contact the admins for assistance.',
        styles: 'cso',
      });
    } else if (props.disable) {
      setMissingInfoModal(!missingInfoModal);
    } else if (
      props.disabledModal &&
      props.checked &&
      currentUser &&
      props.canEdit.includes(currentUser.role)
    ) {
      setDisableModal(!disableModal);
    } else {
      // passing true for the checkboxes inputs do not need this
      props.handleChange(e, props.name, true);
    }
  };

  const toggleModal = () => {
    setDisableModal(false);
    setMissingInfoModal(false);
    setError({ display: false, title: '', message: '' });
  };

  return (
    <>
      {disableModal && (
        <DisableModal
          type={props.type}
          handleChange={props.handleChange}
          handleCloseModal={toggleModal}
        />
      )}
      {missingInfoModal && <MissingInfoModal handleCloseModal={toggleModal} />}
      <ToggleContainerStyles
        display='flex'
        padding={props.padding}
        margin={props.margin}
        justifyContent={props.justifyContent}>
        <ToggleSwitchStyles>
          <input
            checked={props.checked}
            name={props.name}
            onChange={handleModalPopup}
            type='checkbox'
            disabled={
              (props.canEdit && !props.canEdit.includes(currentUser.role)) ||
              props.disabled
            }
          />
          <span className='Slider Round' />
        </ToggleSwitchStyles>
        <ToggleLabelStyles
          margin={props.labelMargin}
          display={props.labelDisplay}>
          {props.label}
        </ToggleLabelStyles>
      </ToggleContainerStyles>
    </>
  );
};

export default ToggleSwitch;
