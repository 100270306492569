import { useState } from 'react';

import { CLOUD_NAME } from '../config/config';

export const useCloudinary = (intialValues) => {
  const [values] = useState(intialValues);
  const [cloudData, setCloudData] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);

  const uploadImage = async (e) => {
    setImageLoading(true);
    const data = new FormData();

    //cordova and browser camera plugins
    // else condition is browser files
    if (typeof e === 'string') {
      let imageData = e.replace(/\r?\n|\r/g, '');
      data.append('file', imageData);
    } else {
      const files = e.target.files;
      data.append('file', files[0]);
    }
    //cloudinary params that can be attached with the file
    data.append('upload_preset', values.preset);
    data.append('tags', values.tags);

    const res = await fetch(
      `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/image/upload`,
      {
        method: 'POST',
        body: data,
      }
    );
    const file = await res.json();

    if (file) {
      setCloudData(file);
      setImageLoading(false);
    }
  };

  return { cloudData, imageLoading, uploadImage };
};
