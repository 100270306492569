import React from 'react';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';

//global components
import RoleCanEdit from '../../../OperatorShared/RoleCanEdit';

//styles
import {
  HeaderContainerStyles,
  HeaderLeftStyles,
  HeaderRightStyles,
} from '../../../../OperatorStyles';

const LocationHeader = (props) => {
  const { location, toggleDeleteModal, toggleLocationModal, strings } = props;
  return (
    <HeaderContainerStyles justifyContent='space-between' padding='10px'>
      <HeaderLeftStyles>
        <h3 className='Title'>
          {location.name || strings.addLocationName}
          <RoleCanEdit roles={[1]}>
            <span className='Edit-icon'>
              <FaRegEdit onClick={toggleLocationModal} />
            </span>
          </RoleCanEdit>
        </h3>
      </HeaderLeftStyles>
      <HeaderRightStyles>
        {toggleDeleteModal && (
          <RoleCanEdit roles={[1]}>
            <FaRegTrashAlt onClick={toggleDeleteModal} />
          </RoleCanEdit>
        )}
      </HeaderRightStyles>
    </HeaderContainerStyles>
  );
};

export default LocationHeader;
