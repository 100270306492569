import React from 'react';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';

//images
import placeholder from '../../../../assets/images/subscriptionShareImage.png';

//styles
import {
  DivStyles,
  ImageContainerFitStyles,
  ImageThumbnailStyles,
  TitleStyles,
  TextStyles,
} from '../../../SharedStyles';

const MembershipHeader = (props) => {
  const { membership, strings } = props;

  return (
    <DivStyles
      display='flex'
      alignItems='center'
      padding='10px'
      justifyContent='space-between'
      borderBottom>
      {/* Left Card */}
      <DivStyles display='flex' flexWrap='nowrap' alignItems='center'>
        <ImageContainerFitStyles
          width='50px'
          height='50px'
          removeMobileImg='320px'
          margin='0 10px 0 0'>
          <ImageThumbnailStyles
            width='100%'
            height='100%'
            objectFit='cover'
            src={membership.imagePath ? membership.imagePath : placeholder}
            alt={membership.membershipName}
          />
        </ImageContainerFitStyles>
        <DivStyles align='left'>
          <TitleStyles size='14px' weight='700' margin='0 0 5px'>
            {membership.membershipName}
            {props.edit && (
              <span className='Edit-Icon' onClick={props.edit}>
                <FaRegEdit />
              </span>
            )}
          </TitleStyles>
          {!props.noCode && (
            <TextStyles size='12px' weight='500' margin='0'>
              {strings.code}: {membership.membershipCode}
            </TextStyles>
          )}
        </DivStyles>
      </DivStyles>
      {/* Right Card */}
      {props.delete && (
        <DivStyles
          display='flex'
          flexWrap='nowrap'
          alignItems='center'
          align='right'
          wrapMargin='5px 0 0'>
          <FaRegTrashAlt onClick={props.delete} />
        </DivStyles>
      )}
    </DivStyles>
  );
};

export default MembershipHeader;
