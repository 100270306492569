import React, { createContext, useState, useEffect, useContext } from 'react';

//services
import AppDriverService from './AppDriverServices';

//global components
import LoadingSpinner from '../../shared/Loading/LoadingSpinner';

//context
import { DriverContext } from './getDriverContext';

export const DriverChargerContext = createContext();

const DriverLinkedChargers = (props) => {
  const { driver, driverLoading } = useContext(DriverContext);
  const [linkedChargers, setLinkedChargers] = useState([]);
  const [linkedCharger, setLinkedCharger] = useState({});
  const [chargerLoading, setChargerLoading] = useState(true);

  async function fetchLinkCharger(id) {
    let requestData = {
      iotHubDeviceId: id,
      location: driver.driverLocation,
      bypassDirection: true,
    };
    let charger = await AppDriverService.getDriverCharger(requestData);

    setLinkedCharger((c) => ({ ...c, ...charger }));
    if (charger) {
      fetchLinkedChargerData();
    }

    return charger;
  }

  async function fetchLinkedChargerData() {
    let requestData = {
      location: driver.driverLocation,
      bypassDirection: true,
    };
    let linkedChargerData = await AppDriverService.getDriverChargers(
      requestData
    );

    let validChargers = linkedChargerData.map((d) => {
      if (d.rateType === '--') {
        d.dead = true;
      }
      if (!(d.home || d.assigned) && d.private) {
        d.dead = true;
      }

      return d;
    });

    setLinkedChargers(validChargers);
    if (linkedChargerData) {
      setChargerLoading(false);
    }
    return linkedChargerData;
  }

  async function UpdateDriverSingleCharger(charger) {
    let linkedChargerData = await AppDriverService.updateDriverCharger(charger);
    setLinkedCharger(linkedChargerData);
    return linkedChargerData;
  }

  useEffect(() => {
    if (driver) {
      fetchLinkedChargerData();
    }
    // eslint-disable-next-line
  }, [driverLoading, driver]);

  return (
    <DriverChargerContext.Provider
      value={{
        linkedCharger,
        linkedChargers,
        chargerLoading,
        setLinkedCharger,
        setLinkedChargers,
        setChargerLoading,
        fetchLinkedChargerData,
        UpdateDriverSingleCharger,
        fetchLinkCharger,
      }}>
      {!chargerLoading ? props.children : <LoadingSpinner />}
    </DriverChargerContext.Provider>
  );
};

export default DriverLinkedChargers;
