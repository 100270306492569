import React, { useState, useContext, useEffect } from 'react';
import {
  FaChargingStation,
  FaDollarSign,
  FaRegCalendarCheck,
  FaSearchengin,
  FaTags,
  FaClock,
  FaUsers,
  FaUserTag,
  FaRegEye,
} from 'react-icons/fa';

//services
import AppOptionsService from './AppOptionsService';
import AppDiscountSchedulesService from '../../../DiscountSchedules/AppDiscountSchedulesService';

//global components
import ToggleSwitch from '../../../OperatorShared/ToggleSwitch/CsoToggleSwitch';
import SubscriptionAccess from '../../../OperatorShared/SubscriptionAccess';

//modals
import EyeModal from './ChargerOptionsModals/EyeModal';
import ConfirmChange from './ChargerOptionsModals/ConfirmChange';

//context
import { CsoAccountContext } from '../../../../context/operator/getCsoAccount';
import { CsoMembershipContext } from '../../../../context/operator/getCsoMembership';

//styles
import {
  FormContainerStyles,
  FormRowStyles,
  FormLeftStyles,
  FormRightStyles,
  SelectStyles,
  TitleStyles,
  InputStyles,
  InputContainerStyles,
  SelectContainerStyles,
} from '../../../../SharedStyles';
import { resMinsTime, resGuaranteeSessionTime } from './helper';

const ChargerOptions = (props) => {
  const { strings } = AppOptionsService;
  const { charger } = props;

  const { currentUser, account, setAccount } = useContext(CsoAccountContext);
  const { allMemberships } = useContext(CsoMembershipContext);

  const [discountSx, setDiscountSx] = useState([]);
  const [chargingProfileModal, setChargingProfileModal] = useState(false);
  const [pricingSxModal, setPricingSxModal] = useState(false);
  const [couponModal, setCouponModal] = useState(false);
  const [membershipModal, setMembershipModal] = useState(false);
  const [displayConfirmModal, setDisplayConfirmModal] = useState({
    display: false,
    eventName: null,
    eventValue: null,
    id: null,
  });
  const [discountIds, setDiscountIds] = useState([]);
  const [profileId, setProfileId] = useState(null);
  const [activeMemberships, setActiveMemberships] = useState([]);
  const [activeDiscounts, setActiveDiscounts] = useState([]);

  let adminPermission;
  if (currentUser) {
    adminPermission = currentUser.role === 1;
  }

  useEffect(() => {
    const mergeCouponsDiscount = () => {
      let discountsSx = account.discountSchedules.map((discount) => {
        AppDiscountSchedulesService.getCouponTokens(discount.id).then(
          (data) => {
            discount.coupons = data;
          }
        );
        return discount;
      });
      setDiscountSx(discountsSx);
    };

    mergeCouponsDiscount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    let active = allMemberships
      .filter((membership) => {
        return membership.chargingStationIds.includes(charger.iotHubDeviceId);
      })
      .map((membership) => {
        return membership.id;
      });

    localStorage.setItem('activeMembershipIds', JSON.stringify(active));
    setActiveMemberships(active);
  }, [allMemberships, charger.iotHubDeviceId]);

  useEffect(() => {
    //loads ids to the value for coupons
    let defaultIds = account.discountSchedules
      .filter((sx) => {
        return sx.chargingStations?.includes(charger.iotHubDeviceId);
      })
      .map((sx) => {
        return sx.id;
      });

    setDiscountIds(defaultIds);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charger.iotHubDeviceId]);

  const handleInputChange = (e) => {
    props.handleDataChange([e.target.name], e.target.value);
  };

  const getPermissionToChange = (connectorId, e) => {
    charger.evses[0].connectors.forEach((connector) => {
      if (
        connectorId === connector.id &&
        connector.chargingProfileId !== null
      ) {
        setDisplayConfirmModal({
          display: !displayConfirmModal.display,
          eventName: e.target.name,
          eventValue: e.target.value,
          id: connectorId,
        });
      } else if (connectorId === connector.id) {
        handleConnector(connectorId, e);
      }
    });
  };

  const closePermissionToChange = () => {
    setDisplayConfirmModal({
      display: false,
      eventName: null,
      eventValue: null,
      id: null,
    });
  };

  const handleConnector = (connectorId, e) => {
    if (e.target.name === 'enabledChargingProfile') {
      props.handleConnectorDataChange(
        connectorId,
        [e.target.name],
        e.target.checked
      );
    } else {
      props.handleConnectorDataChange(
        connectorId,
        [e.target.name],
        e.target.value
      );
    }
  };

  const toggleDisplayModal = (modal, id) => {
    if (modal === 'chargingProfile') {
      setChargingProfileModal(!chargingProfileModal);
      setProfileId(id);
    }
    if (modal === 'pricingSxModal') {
      setPricingSxModal(!pricingSxModal);
    }
    if (modal === 'couponModal') {
      setCouponModal(!couponModal);
    }
    if (modal === 'membershipModal') {
      setMembershipModal(!membershipModal);
    }
  };

  // Revisit
  const handleEnable = async (e, name) => {
    props.handleDataChange(name, e.target.checked);
  };

  const handleDiscountChange = (e) => {
    let options = e.target.options;
    let selectedOptions;
    selectedOptions = [...options]
      .filter((option) => {
        return option.selected;
      })
      .map((selectedOption) => {
        return selectedOption.value;
      });

    setDiscountIds(selectedOptions);
    setActiveDiscounts(selectedOptions);
  };

  const handleMembershipChange = (e) => {
    let options = e.target.options;
    let selectedOptions;
    selectedOptions = [...options]
      .filter((option) => {
        return option.selected;
      })
      .map((selectedOption) => {
        return selectedOption.value;
      });

    props.handleUpdateMemberships(selectedOptions);
    setActiveMemberships(selectedOptions);
  };

  useEffect(() => {
    let allCoupons = discountSx.map((coupon) => {
      if (discountIds.includes(coupon.id)) {
        coupon.chargingStations = [
          ...new Set([...coupon.chargingStations, charger.iotHubDeviceId]),
        ];
      } else {
        coupon.chargingStations = coupon.chargingStations?.filter((station) => {
          return station !== charger.iotHubDeviceId;
        });
      }
      return coupon;
    });
    setAccount((a) => ({
      ...a,
      discountSchedules: [...allCoupons],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountIds]);

  useEffect(() => {
    if (
      account &&
      account.subscriptionLevel === 1 &&
      account.pricingSchedules.length
    ) {
      props.handleDataChange('enabledPricingSchedule', true);
      props.handleDataChange(
        'pricingScheduleId',
        account.pricingSchedules[0].id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <>
      {displayConfirmModal.display && (
        <ConfirmChange
          handleConnectorDataChange={props.handleConnectorDataChange}
          data={displayConfirmModal}
          closeModal={closePermissionToChange}
        />
      )}
      {chargingProfileModal && (
        <EyeModal
          chargingProfile
          profileId={profileId}
          charger={charger}
          closeModal={() => toggleDisplayModal('chargingProfile')}
        />
      )}
      {pricingSxModal && (
        <EyeModal
          charger={charger}
          pricingSx
          title={strings.defaultPricingSchedule}
          closeModal={() => toggleDisplayModal('pricingSxModal')}
        />
      )}
      {couponModal && (
        <EyeModal
          charger={charger}
          discountSx={discountSx}
          discountIds={discountIds}
          title={strings.defaultCouponSchedule}
          closeModal={() => toggleDisplayModal('couponModal')}
        />
      )}
      {membershipModal && (
        <EyeModal
          charger={charger}
          activeMemberships={activeMemberships}
          allMemberships={allMemberships}
          title={strings.memberships}
          closeModal={() => toggleDisplayModal('membershipModal')}
        />
      )}
      <FormContainerStyles padding='10px' headersPadding='0' borderBottom>
        <TitleStyles size='14px' margin='0 0 10px' weight='600'>
          {strings.chargerOptions}
        </TitleStyles>
        {/* Charging Profiles Schedule - Enterprise Only*/}
        <SubscriptionAccess levels={[3]}>
          {charger.evses[0].connectors.map((connector) => {
            return (
              <FormRowStyles
                display='block'
                padding='5px 5px 0'
                margin='0 0 10px'
                key={connector.id}>
                <FormRowStyles justifyContent='space-between' margin='0'>
                  <FormLeftStyles display='flex'>
                    <FaChargingStation />
                    <TitleStyles size='16px' margin='0' weight='500'>
                      {strings.defaultChargingProfile} {connector.id}
                    </TitleStyles>
                  </FormLeftStyles>
                  <FormRightStyles>
                    <ToggleSwitch
                      deny={currentUser && currentUser.role === 4}
                      adminPermission={adminPermission}
                      name='enabledChargingProfile'
                      checked={!!connector.enabledChargingProfile}
                      handleChange={(e) => handleConnector(connector.id, e)}
                    />
                  </FormRightStyles>
                </FormRowStyles>
                <FormRowStyles alignItems='center'>
                  <SelectStyles
                    size='14px'
                    mobileSize='14px'
                    width='60%'
                    mobileWidth='80%'
                    margin='0 20px 0 35px'
                    mobileMargin='0 0 15px'
                    padding='5px 0px 5px 4px'
                    bgPosition='1em'
                    disabled={currentUser && currentUser.role === 4}
                    name='chargingProfileId'
                    onChange={(e) => getPermissionToChange(connector.id, e)}
                    value={connector.chargingProfileId || ''}>
                    <option value='' disabled>
                      {strings.selectProfile}
                    </option>
                    {account &&
                      account.chargingProfiles.map((option) => {
                        return (
                          <option
                            className='AppCso-options-item-option'
                            key={option.id}
                            value={option.id}>
                            {option.name}
                          </option>
                        );
                      })}
                  </SelectStyles>
                  {connector.chargingProfileId && (
                    <FaRegEye
                      className='View'
                      onClick={() =>
                        toggleDisplayModal('chargingProfile', connector.id)
                      }
                    />
                  )}
                </FormRowStyles>
              </FormRowStyles>
            );
          })}
        </SubscriptionAccess>
        {/* pricing Schedule - Enterprise and Small Biz */}
        <SubscriptionAccess levels={[2, 3]}>
          <FormRowStyles display='block' padding='5px 5px 0' margin='0 0 10px'>
            <FormRowStyles justifyContent='space-between' margin='0'>
              <FormLeftStyles display='flex'>
                <FaDollarSign />
                <TitleStyles size='16px' margin='0' weight='500'>
                  {strings.defaultPricingSchedule}
                </TitleStyles>
              </FormLeftStyles>
              <FormRightStyles>
                <ToggleSwitch
                  deny={currentUser && currentUser.role === 4}
                  adminPermission={adminPermission}
                  name='enabledPricingSchedule'
                  checked={!!charger.enabledPricingSchedule}
                  handleChange={handleEnable}
                />
              </FormRightStyles>
            </FormRowStyles>
            <FormRowStyles alignItems='center'>
              <SelectStyles
                size='14px'
                mobileSize='14px'
                width='60%'
                mobileWidth='80%'
                margin='0 20px 0 35px'
                mobileMargin='0 0 15px'
                padding='5px 0px 5px 4px'
                bgPosition='1em'
                name='pricingScheduleId'
                disabled={currentUser && currentUser.role === 4}
                onChange={handleInputChange}
                value={charger.pricingScheduleId || ''}>
                <option value='' disabled>
                  {strings.selectPrice}
                </option>
                {account &&
                  account.pricingSchedules.map((option) => {
                    return (
                      <option
                        className='AppCso-options-item-option'
                        key={option.id}
                        value={option.id}>
                        {option.name}
                      </option>
                    );
                  })}
              </SelectStyles>
              {charger.pricingScheduleId && (
                <FaRegEye
                  className='View'
                  onClick={() => toggleDisplayModal('pricingSxModal')}
                />
              )}
            </FormRowStyles>
          </FormRowStyles>
        </SubscriptionAccess>
        {/* Allow Reservations - Enterprise Only */}
        <SubscriptionAccess levels={[3]}>
          <FormRowStyles display='block' padding='5px 5px 0' margin='0 0 10px'>
            <FormRowStyles justifyContent='space-between' margin='0'>
              <FormLeftStyles display='flex'>
                <FaRegCalendarCheck />
                <TitleStyles size='16px' margin='0' weight='500'>
                  {strings.allowRes}
                </TitleStyles>
              </FormLeftStyles>
              <FormRightStyles>
                <ToggleSwitch
                  deny={currentUser && currentUser.role === 4}
                  adminPermission={adminPermission}
                  name='allowReservations'
                  checked={!!charger.allowReservations}
                  handleChange={handleEnable}
                />
              </FormRightStyles>
            </FormRowStyles>
            <FormRowStyles alignItems='center'>
              <InputContainerStyles
                margin='0 20px 0 35px'
                mobileMargin='5px 0 0'
                color='#28456a'>
                $
                <InputStyles
                  size='14px'
                  mobileSize='14px'
                  width='63%'
                  mobileWidth='77%'
                  mobileMargin='0 0 15px'
                  padding='5px 0px 5px 4px'
                  type='text'
                  placeholder={strings.resPlaceholder}
                  onChange={handleInputChange}
                  name='reservationFee'
                  disabled={currentUser && currentUser.role === 4}
                  value={charger.reservationFee || ''}
                />
              </InputContainerStyles>
            </FormRowStyles>
            <FormRowStyles alignItems='center'>
              <SelectContainerStyles>
                <SelectStyles
                  size='14px'
                  mobileSize='14px'
                  width='60%'
                  mobileWidth='80%'
                  margin='0 20px 0 35px'
                  mobileMargin='0 0 15px'
                  padding='5px 0px 5px 4px'
                  bgPosition='1em'
                  name='holdTime'
                  onChange={handleInputChange}
                  disabled={currentUser && currentUser.role === 4}
                  value={charger.holdTime || strings.selectTime}>
                  <option value={strings.selectTime} disabled>
                    {strings.selectTime}
                  </option>
                  {resMinsTime.map((time) => (
                    <option value={time.value} key={time.value}>
                      {time.key}
                    </option>
                  ))}
                </SelectStyles>
              </SelectContainerStyles>
            </FormRowStyles>
            <FormRowStyles alignItems='center'>
              <SelectContainerStyles>
                <SelectStyles
                  size='14px'
                  mobileSize='14px'
                  width='60%'
                  mobileWidth='80%'
                  margin='0 20px 0 35px'
                  mobileMargin='0 0 15px'
                  padding='5px 0px 5px 4px'
                  bgPosition='1em'
                  name='guaranteedSessionTime'
                  disabled={currentUser && currentUser.role === 4}
                  onChange={handleInputChange}
                  value={
                    charger.guaranteedSessionTime || strings.selectSession
                  }>
                  <option value={strings.selectSession} disabled>
                    {strings.selectSession}
                  </option>
                  {resGuaranteeSessionTime.map((time) => (
                    <option value={time.value} key={time.value}>
                      {time.key}
                    </option>
                  ))}
                </SelectStyles>
              </SelectContainerStyles>
            </FormRowStyles>
          </FormRowStyles>
        </SubscriptionAccess>
        {/* Memberships */}
        <SubscriptionAccess levels={[1, 2, 3]}>
          <FormRowStyles display='block' padding='5px 5px 0' margin='0 '>
            <FormRowStyles justifyContent='space-between' margin='0'>
              <FormLeftStyles display='flex'>
                <FaUsers />
                <TitleStyles size='16px' margin='0' weight='500'>
                  {strings.memberships}
                </TitleStyles>
              </FormLeftStyles>
            </FormRowStyles>
            <FormRowStyles alignItems='center'>
              <SelectStyles
                mobileSize='14px'
                width='60%'
                mobileWidth='80%'
                margin='0 20px 0 35px'
                mobileMargin='0 0 15px'
                padding='5px 0px 5px 4px'
                bgPosition='1em'
                multiple
                noDropdown
                size={allMemberships.length}
                disabled={currentUser && currentUser.role === 4}
                name='chargingProfileId'
                onChange={handleMembershipChange}
                value={activeMemberships || ''}>
                {allMemberships.map((option) => {
                  return (
                    <option
                      className='AppCso-options-item-option'
                      key={option.id}
                      value={option.id}>
                      {option.membershipName}
                    </option>
                  );
                })}
              </SelectStyles>
              <FaRegEye
                className='View'
                onClick={() => toggleDisplayModal('membershipModal')}
              />
            </FormRowStyles>
          </FormRowStyles>
        </SubscriptionAccess>
        {/* Member Access Only - Enterprise */}
        <SubscriptionAccess levels={[3]}>
          <FormRowStyles
            display='block'
            padding='5px 5px 0'
            margin='0 0 10px 35px'>
            <FormRowStyles justifyContent='space-between' margin='0'>
              <FormLeftStyles display='flex'>
                <FaUserTag />
                <TitleStyles size='16px' margin='0' weight='500'>
                  {strings.memberAccessOnly}
                </TitleStyles>
              </FormLeftStyles>
              <FormRightStyles>
                <ToggleSwitch
                  deny={currentUser && currentUser.role === 4}
                  adminPermission={adminPermission}
                  name='membershipOnly'
                  checked={!!charger.membershipOnly}
                  handleChange={handleEnable}
                />
              </FormRightStyles>
            </FormRowStyles>
          </FormRowStyles>
        </SubscriptionAccess>
        {/* Allow Wait Queue - Enterprise Only */}
        <SubscriptionAccess levels={[3]}>
          <FormRowStyles display='block' padding='5px 5px 0' margin='10px 0'>
            <FormRowStyles justifyContent='space-between' margin='0'>
              <FormLeftStyles display='flex'>
                <FaClock />
                <TitleStyles size='16px' margin='0' weight='500'>
                  {strings.allowWait}
                </TitleStyles>
              </FormLeftStyles>
              <FormRightStyles>
                <ToggleSwitch
                  deny={currentUser && currentUser.role === 4}
                  adminPermission={adminPermission}
                  name='allowWaitQueue'
                  checked={!!charger.allowWaitQueue}
                  handleChange={handleEnable}
                />
              </FormRightStyles>
            </FormRowStyles>
            <FormRowStyles alignItems='center'>
              <InputContainerStyles
                margin='0 20px 0 35px'
                mobileMargin='5px 0 0'
                color='#28456a'>
                <InputStyles
                  size='14px'
                  mobileSize='14px'
                  width='63%'
                  mobileWidth='77%'
                  mobileMargin='0 0 15px'
                  padding='5px 0px 5px 4px'
                  type='number'
                  max='60'
                  min='0'
                  placeholder={strings.gracePeriod}
                  onChange={handleInputChange}
                  name='waitQueueGracePeriod'
                  disabled={currentUser && currentUser.role === 4}
                  value={charger.waitQueueGracePeriod || ''}
                />
              </InputContainerStyles>
            </FormRowStyles>
          </FormRowStyles>
        </SubscriptionAccess>
        {/* Allow Coupons - Enterprise Only */}
        <SubscriptionAccess levels={[3]}>
          <FormRowStyles display='block' padding='5px 5px 0' margin='0'>
            <FormRowStyles justifyContent='space-between' margin='0'>
              <FormLeftStyles display='flex'>
                <FaTags />
                <TitleStyles size='16px' margin='0' weight='500'>
                  {strings.allowCoupons}
                </TitleStyles>
              </FormLeftStyles>
              <FormRightStyles>
                <ToggleSwitch
                  deny={currentUser && currentUser.role === 4}
                  adminPermission={adminPermission}
                  name='allowCoupon'
                  checked={!!charger.allowCoupon}
                  handleChange={handleEnable}
                />
              </FormRightStyles>
            </FormRowStyles>
          </FormRowStyles>
          <FormRowStyles alignItems='center' size='14px'>
            <SelectStyles
              mobileSize='14px'
              width='60%'
              mobileWidth='80%'
              margin='0 20px 0 35px'
              mobileMargin='0 0 15px'
              padding='5px 0px 5px 4px'
              noDropdown
              multiple
              size={account.discountSchedules.length}
              name='coupons'
              onChange={handleDiscountChange}
              className='multiple'
              disabled={currentUser && currentUser.role === 4}
              value={discountIds}>
              {account &&
                account.discountSchedules.map((option) => {
                  return (
                    <option
                      className='AppCso-options-item-option'
                      key={option.id}
                      value={option.id}>
                      {option.name}
                    </option>
                  );
                })}
            </SelectStyles>
            <FaRegEye
              className='View'
              onClick={() => toggleDisplayModal('couponModal')}
            />
          </FormRowStyles>
        </SubscriptionAccess>
        {/* Publicly Searchable - Small Biz and Enterprise Only - Shared enabled by default*/}
        <SubscriptionAccess levels={[2, 3]}>
          <FormRowStyles
            display='block'
            padding='5px 5px 0'
            margin='10px 0 10px'>
            <FormRowStyles justifyContent='space-between' margin='0'>
              <FormLeftStyles display='flex'>
                <FaSearchengin />
                <TitleStyles size='16px' margin='0' weight='500'>
                  {strings.publicSearch}
                </TitleStyles>
              </FormLeftStyles>
              <FormRightStyles>
                <ToggleSwitch
                  deny={currentUser && currentUser.role === 4}
                  adminPermission={adminPermission}
                  name='publiclySearchable'
                  checked={!!charger.publiclySearchable}
                  handleChange={handleEnable}
                />
              </FormRightStyles>
            </FormRowStyles>
          </FormRowStyles>
        </SubscriptionAccess>
      </FormContainerStyles>
    </>
  );
};

export default ChargerOptions;
