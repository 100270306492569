import AppChargingService from './AppCharging/AppChargingService';

const { strings } = AppChargingService;

export const connectorStatuses = {
  1: strings.status1,
  2: strings.status2,
  3: strings.status3,
  4: strings.status4,
  5: strings.status5,
  6: strings.status6,
  7: strings.status7,
  8: strings.status8,
  9: strings.status9,
  10: strings.status10,
};

export const connectorConstants = {
  Available: 1,
  Preparing: 2,
  Charging: 3,
  SuspendedEvse: 4,
  SuspendedEv: 5,
  Finishing: 6,
  Reserved: 7,
  Unavailable: 8,
  Faulted: 9,
};

export const googleZoomToMeters = {
  20: 1,
  19: 2,
  18: 4,
  17: 6,
  16: 8,
  15: 10,
  14: 12,
  13: 14,
  12: 16,
  11: 18,
  10: 20,
  9: 60,
  8: 200,
  7: 300,
  6: 800,
  5: 1200,
  4: 15000,
  3: 35000,
  2: 50000,
  1: 100000,
};
