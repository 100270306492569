import React from 'react';
import { ButtonStartStyles, DivStyles } from '../../../../SharedStyles';

const ChargerButton = (props) => {
  const { strings } = props;
  console.log('BUTTON PROPS', props);

  return (
    <DivStyles
      display='flex'
      alignItems='center'
      justifyContent='center'
      align='center'>
      <ButtonStartStyles
        flex='1'
        maxWidth='230px'
        fontSize='16px'
        fontWeight='600'
        bgColor='#ff8f45'
        hoverBg='#ff7317'
        color='#fff'
        hoverColor='#fff'
        border='1px solid #ff8f45'
        margin='0 0 2%'
        padding='6px 10px'
        onClick={props.handleStartStop}
        disabled={
          props.isDisabled ||
          props.showSummary ||
          props.chargerDisabled ||
          props.isFinished
        }>
        {props.chargerDisabled && 'Unavailable'}
        {(props.showSummary || props.isFinished) && 'Finished'}
        {!props.chargerDisabled &&
          !props.showSummary &&
          !props.isFinished &&
          (!props.isCharging
            ? `${strings.startCharging}`
            : `${strings.stopCharging}`)}
      </ButtonStartStyles>
    </DivStyles>
  );
};

export default ChargerButton;
