import React, { useEffect, useContext } from 'react';
//layout
import {
  OuterWrapper,
  SubscriptionAccess,
  ComponentWrapper,
} from '../OperatorShared';
//services
import AppDashboardService from './AppDashboardService';

//components
import SbAndEnter from './DashboardDetails/SbAndEnter';
import NonAndShared from './DashboardDetails/NonAndShared';

//context
import { CsoAccountContext } from '../../context/operator/getCsoAccount';
import { CsoChargerContext } from '../../context/operator/getCsoChargers';
import { LoadingSpinner } from '../../shared';
import { ReportContext } from '../../context/operator/getReports';

const Dashboard = (props) => {
  const { strings } = AppDashboardService;
  const { fetchChargers, allChargers } = useContext(CsoChargerContext);
  const { account } = useContext(CsoAccountContext);
  const { sessionsContext, reportLoading } = useContext(ReportContext);

  useEffect(() => {
    fetchChargers();
    // eslint-disable-next-line
  }, []);

  return (
    <OuterWrapper maxWidth='1200px' title={strings.title}>
      <ComponentWrapper
        dashboardTitle={strings.title}
        dashboardSubTitle={`- ${account.csoAccountName}`}>
        {reportLoading && <LoadingSpinner />}
        <SubscriptionAccess levels={[0, 1, 2]}>
          <NonAndShared
            strings={strings}
            loading={reportLoading}
            account={account}
            sessions={sessionsContext}
            allChargers={allChargers}
          />
        </SubscriptionAccess>
        <SubscriptionAccess levels={[3]}>
          <SbAndEnter
            strings={strings}
            loading={reportLoading}
            account={account}
            sessions={sessionsContext}
            allChargers={allChargers}
          />
        </SubscriptionAccess>
      </ComponentWrapper>
    </OuterWrapper>
  );
};

export default Dashboard;
