import { useState, useEffect } from 'react';

export const useRequiredFields = (intialValues) => {
  const [values, setValues] = useState(intialValues);
  const [missingRequired, setMissingRequired] = useState(true);

  useEffect(() => {
    let requiredArray = values.every((item) => {
      return item.current.value !== '';
    });
    setMissingRequired(!requiredArray);
  }, [values]);

  return [
    (array) => {
      setValues(array);
    },
    missingRequired,
  ];
};
