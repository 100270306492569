import React from 'react';

//layout
import { OuterWrapper, ComponentWrapper } from '../OperatorShared';
import Button from '../../shared/Buttons/Button';

//styles
import { ListPlaceholderStyles } from '../../SharedStyles';

const IncompleteAccount = (props) => {
  const { strings } = props;
  return (
    <OuterWrapper title={strings.pageTitle} internal path='/' roles={[1]}>
      <ComponentWrapper title={strings.pageTitle}>
        <ListPlaceholderStyles borderBottom='none'>
          <p>{strings.finishBank}</p>
          <img
            src={require('../../../assets/images/BankPlaceholder.png').default}
            alt='Bank'
            className='Placeholder'
          />
        </ListPlaceholderStyles>
      </ComponentWrapper>
      <Button
        cso
        hasMobile
        redirect='/bank'
        buttonText={strings.linkBank}
        roles={[1]}
      />
    </OuterWrapper>
  );
};

export default IncompleteAccount;
