import AppApiService from '../../../services/AppApi/AppApiService';

const ParamsService = {
  getParams: function (params) {
    return AppApiService.post('getParams', null, params);
  },
  setParams: function (params) {
    return AppApiService.post('setParams', null, params);
  },
};

export default ParamsService;
