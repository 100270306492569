import React, { useState, useEffect, useContext } from 'react';

//layout
import Button from '../../../../shared/Buttons/Button';

//components
import GoogleChart from '../../../OperatorShared/GoogleCharts/GoogleChart';

//hooks
import { useRouter } from '../../../../shared/hooks/useRouter';

//context
import { CsoChargerContext } from '../../../../context/operator/getCsoChargers';

//styles
import {
  DivStyles,
  ListPlaceholderStyles,
  TitleStyles,
} from '../../../../SharedStyles';
import { Link } from 'react-router-dom';

const ChargerStatus = (props) => {
  const { strings, reduceWidth, loading } = props;
  const { allChargers } = useContext(CsoChargerContext);

  const [chartData, setChartData] = useState([]);
  const [portCount, setPortCount] = useState(0);
  const router = useRouter();

  useEffect(() => {
    let dataArray = [
      ['Available', 0],
      ['Connected', 0],
      ['Disabled', 0],
      ['In-use/Charging', 0],
      ['Trouble', 0],
      ['Off-line', 0],
    ];
    let portCount = 0;
    allChargers.forEach((charger) => {
      dataArray[0][1] += charger.portStat?.availablePortCount;
      dataArray[1][1] += charger.portStat?.connectedPortCount;
      dataArray[2][1] += charger.portStat?.disabledPortCount;
      dataArray[3][1] += charger.portStat?.chargingPortCount;
      dataArray[4][1] += charger.portStat?.troublePortCount;
      dataArray[5][1] += charger.portStat?.offLinePortCount;
      // dataArray[0][1] += charger.portCount?.unknownPortCount;
      portCount += charger.portStat?.portCount;
      // dataArray[0][1] += charger.portCount?.currentLoad;
    });
    setChartData(dataArray);
    setPortCount(portCount);
  }, [allChargers]);

  const handleRedirect = (route) => {
    router.history.push(`/chargers?filteredStatus=${route}`);
  };

  if (!allChargers.length && !loading) {
    return (
      <DivStyles
        width='100%'
        position='relative'
        margin='0 5px 0 0'
        wrapMargin='0 0 5px'
        flex='1'
        borderRight='1px solid #e7e7e7'
        borderLeft='1px solid #e7e7e7'
        borderTop='1px solid #e7e7e7'
        borderBottom='1px solid #e7e7e7'>
        <TitleStyles
          size='14px'
          align='left'
          padding='5px'
          borderBottom
          margin='0'
          weight='400'>
          {strings.chargerStatusTitle} ({portCount}{' '}
          {strings.chargerStatusDevice})
        </TitleStyles>
        <ListPlaceholderStyles>No Chargers Assigned</ListPlaceholderStyles>
        <Button
          cso
          hasMobile
          buttonText={strings.addCharger}
          redirect='/chargers/add'
          roles={[0, 1, 2, 3]}
        />
      </DivStyles>
    );
  }

  return (
    <DivStyles
      width='100%'
      position='relative'
      margin='0 5px 0 0'
      wrapMargin='0 0 5px'
      flex='1'
      borderRight='1px solid #e7e7e7'
      borderLeft='1px solid #e7e7e7'
      borderTop='1px solid #e7e7e7'
      borderBottom='1px solid #e7e7e7'>
      <TitleStyles
        size='14px'
        align='left'
        padding='5px'
        borderBottom
        margin='0'
        weight='400'>
        {strings.chargerStatusTitle} ({portCount}{' '}
        {strings.chargerStatusDevice})
      </TitleStyles>
      <GoogleChart
        type='pie'
        divId='charging_status'
        width={reduceWidth ? '50%' : '100%'}
        height='100%'
        size='18'
        top='0'
        left='50'
        options={{ legend: { alignment: 'center' } }}
        columns={[
          ['string', 'Status'],
          ['number', 'Count'],
        ]}
        pieChart={0}
        colors={['#07e019', '#4f8ef5', 'grey', 'orange', 'red', 'lightgrey'] /* Helpers.js */}
        route={handleRedirect}
        data={chartData}
        loader={<p>Loading Chart...</p>}
      />
      <DivStyles align='right' linkColor='grey' margin='0 10px' size='14px'>
        <Link to='/chargers'>more...</Link>
      </DivStyles>
    </DivStyles>
  );
};

export default ChargerStatus;
