import { useState, useEffect } from 'react';

export const useParamsForm = intialValues => {
  const [values, setValues] = useState(intialValues);

  useEffect(() => {
    setValues(prev => ({ ...prev, ...intialValues }));
  }, [intialValues]);

  const resetForm = () => {
    setValues(intialValues);
  };

  const handleChange = e => {
    //checking for select multiple elements
    if (e.target.multiple) {
      const updatedOptions = [...e.target.options]
        .filter(option => option.selected)
        .map(x => x.value);
      setValues({ ...values, [e.target.name]: updatedOptions });
      // checking for checkbox inputs
    } else if (e.target.type === 'checkbox') {
      setValues({ ...values, [e.target.name]: e.target.checked });

      //default input
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };

  return [values, e => handleChange(e), resetForm];
};
