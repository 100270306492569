import React from 'react';
import { Link } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';

//service
import AppPlatformService from '../../../../services/AppPlatform/AppPlatformService';

const AccountHeader = props => {
  const { strings } = props;

  const openDialog = () => {
    document.getElementById('inputUpload').click();
  };

  return (
    <div className='Header-Container'>
      {Object.keys(props.driver).length > 0 && (
        <>
          <div className='Header-Image-Container' title={strings.imageToolTip}>
            <div className='Header-Image'>
              {props.driver.imagePath ? (
                <img
                  onClick={openDialog}
                  src={props.driver.imagePath}
                  alt={props.driver.firstName}
                />
              ) : (
                <img
                  onClick={openDialog}
                  src={require('../../../../assets/images/UsersPlaceholder.png').default}
                  alt='placeholder'
                />
              )}
              <input
                className='file-input'
                id='inputUpload'
                type='file'
                hidden
                accept='image/*'
                onChange={props.imageUpload}
              />
            </div>
            <FaPlus onClick={openDialog} />
          </div>
          <div className='Header-Content-Container'>
            <div className='Header-Content-Row'>
              <span>{props.driver.nickName}</span>
            </div>
            <div className='Header-Content-Row'>
              <span className='Bold'>{strings.acctBal}</span>
              <Link title={strings.funds} to='/payments'>
                <span>{AppPlatformService.currencyFormat(props.driver.balance)}</span>
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AccountHeader;
