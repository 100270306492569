import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import AppApiService from '../../../services/AppApi/AppApiService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppEarnedIncomeService = {
  strings: localStrings,
  getEarnedIncomeInfo: function() {
    return AppApiService.get('getEarnedIncomeInfo');
  },
  createPayout: function() {
    return AppApiService.get('createPayout');
  }
};

export default AppEarnedIncomeService;
