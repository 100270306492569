import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppChargerDetailsService from '../AppChargerDetailsService';

const ApprovalModal = (props) => {
  const { strings } = AppChargerDetailsService;

  const closeModal = () => {
    fetchCharger();
    props.handleModalClose(true);
  };

  const fetchCharger = () => {
    props.fetchChargingStations();
  };

  return (
    <div className='Modal-Display-Driver'>
      <div className='Modal'>
        <div className='Modal-Header'>
          {strings.photoTitle}
          <div className='Modal-Close'>
            <FaRegWindowClose onClick={closeModal} />
          </div>
        </div>
        <div className='Modal-Content'>
          <p className='Modal-Content-Text'>{strings.photoApproval}</p>
        </div>
        <div className='Modal-Button'>
          <button className='app-button' onClick={closeModal}>
            {strings.ok}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApprovalModal;
