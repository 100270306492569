import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

//layout
import {
  OuterWrapper,
  ComponentWrapper,
  SubscriptionAccess,
  RoleCanEdit,
} from '../../OperatorShared';

//servies
import AppStatementsService from '../AppStatementsService';
import AppPlatformService from '../../../../services/AppPlatform/AppPlatformService';

//components
import StatementHeader from '../StatementComponents/StatementHeader';
import Section from '../../Reports/Details/Shared/IncomeReports/Payout/components/Section';
import EnterpriseList from '../../Reports/Details/Shared/IncomeReports/Payout/components/EnterpriseSessionList/EnterpriseList';
import IncomeList from './StatementComponents/IncomeList/IncomeList';
import FeesList from './StatementComponents/FeesList/FeesList';
//import ChargingSessionEnterprise from './StatementComponents/EnterpriseSessionList/EnterpriseList';

//global components
import { LoadingSpinner } from '../../../shared';

//context
import { ErrorContext } from '../../../context/shared/ErrorContext';
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';

//tools
import { downloadMultiCSVReport } from '../../../shared/Download/download';

//styles
import { StatementContainerStyle } from '../StatementStyles';

const StatementDetails = (props) => {
  const { strings } = AppStatementsService;
  const { account } = useContext(CsoAccountContext);
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(true);
  const [statement, setStatement] = useState(null);

  useEffect(() => {
    const fetchStatement = async () => {
      try {
        const res = await AppStatementsService.getStatementsById(
          props.match.params.id
        );
        if (res) {
          setStatement(res);
          setLoading(false);
        }
      } catch (err) {
        setError({
          display: true,
          title: 'Something went wrong',
          message: err.message,
          styles: 'cso',
        });
      }
    };
    fetchStatement();
    // eslint-disable-next-line
  }, [props.match.params.id]);

  console.log(statement);

  const handleDownload = () => {

    //statement information - id/account/date
    let statementInfomation = {
      title: 'Statement Information',
      data: [
        {
          id: statement.id,
          accountName: account.csoAccountName,
          payoutDate: statement.statementDate,
        },
      ],
    };

    //payouts
    let transactions = {
      title: 'Charging sessions',
      voidFormat: true,
      data: statement.transactions,
    };

    //subscription payments
    let subscriptionPayments = {
      title: 'Subscription Payments',
      data: statement.statementFeeLineItems.map((s) => {
        delete s.time;
        return s;
      }),
    };

    const data = [
      statementInfomation,
      transactions,
      subscriptionPayments,
    ];

    //debits
    if (statement.stripeTransaction !== null) {
      let subscriptionDebit = {
        title: 'Subscription Debit',
        data: [
          {
            postDate: statement.stripeTransaction.date,
            bankName: statement.stripeTransaction.bankName,
            bankRoutingNo:
              statement.stripeTransaction.routingNumber !== ''
                ? statement.stripeTransaction.routingNumber
                : 'Credit Card',
            transID: statement.stripeTransaction.transactionId,
            amount: `(${AppPlatformService.currencyFormat(statement.stripeTransaction.amount)})`,
          },
        ],
      };

      data.push(subscriptionDebit);
    }

    downloadMultiCSVReport(
      `${account.csoAccountName}_Statement_${moment(statement.date).format(
        'MM/DD/YYYY'
      )}`,
      data
    );
  };

  return (
    <OuterWrapper title={strings.pageDetails} internal path='/statements'>
      {loading && <LoadingSpinner />}
      <SubscriptionAccess levels={[1, 2, 3]}>
        <RoleCanEdit roles={[1, 2]}>
          {!loading && (
            <ComponentWrapper title={strings.pageDetails}>
            <StatementHeader
              strings={strings}
              account={account}
              statementDetails={statement}
              handleDownload={handleDownload}
            />
              <StatementContainerStyle minHeight='580px' width='100%'>
                <>
                  <h2>
                    {strings.earnedIncomeTitle} {AppPlatformService.currencyFormat(statement.totalEarnings)}
                  </h2>
                  {statement.transactions && (
                    <>
                      <Section>
                        {strings.avgKw}:{' '}
                        {statement.averagekW !== 'NaN'
                          ? statement.averagekW.toFixed(2)
                          : statement.averagekW}
                        ; {strings.totKwhs}: {statement.totalkWhs.toFixed(2)};{' '}
                        {strings.chargeAmt}: {AppPlatformService.currencyFormat(statement.totalChargeAmount)};{' '}
                        <SubscriptionAccess levels={[3]}>
                          {strings.idleFees}: {AppPlatformService.currencyFormat(statement.totalIdleFees)};{' '}
                          {strings.resFees}: {AppPlatformService.currencyFormat(statement.totalReservationFees)}
                        </SubscriptionAccess>
                      </Section>
                      <EnterpriseList
                        statementDetails={statement}
                        strings={strings}
                      />
                    </>
                  )}
                </>

                <>
                  <h2>
                    {strings.feeTitle} 
                    {AppPlatformService.currencyFormat(statement.totalSubscriptionFees)}
                  </h2>
                  <Section>
                    {strings.subscriptionType} {statement.subscriptionType}
                  </Section>

                  <FeesList statementDetails={statement} strings={strings} />
                </>

                {/* Ampedup Fee subscriptions debit */}
                <>
                  <h2>
                    {strings.incomeDebitTitle}: (
                    {AppPlatformService.currencyFormat(statement.totalSubscriptionFees)})
                  </h2>
                  <div className='Sub-Section'>
                    {strings.incomeDebitSubTitle}
                  </div>
                  <IncomeList
                    debit
                    statementDetails={statement.stripeTransaction}
                    strings={strings}
                  />
                </>
              </StatementContainerStyle>
            </ComponentWrapper>
          )}
        </RoleCanEdit>
      </SubscriptionAccess>
    </OuterWrapper>
  );
};

export default StatementDetails;
