import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import AppApiService from '../../../services/AppApi/AppApiService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppChargersService = {
  chargerToAccount: function (charger) {
    return AppApiService.post('csoChargerToAccount', null, charger);
  },
  chargerLinkStatus: function (iotHubDeviceId) {
    return AppApiService.post('csoChargerLinkStatus', null, iotHubDeviceId);
  },
  getChargers: function (search) {
    return AppApiService.post('csoChargerList', null, search);
  },
  getCharger: function (iotHubDeviceId) {
    return AppApiService.post('getChargingStation', null, iotHubDeviceId);
  },
  getChargerLinksFromDriver: function (iotHubDeviceId) {
    return AppApiService.post(
      'csoOwnedAssignChargersList',
      null,
      iotHubDeviceId
    );
  },
  getChargerHistory: function (chargerId) {
    return AppApiService.post('csoChargerHistory', null, chargerId);
  },
  updateCharger: function (charger) {
    return AppApiService.post('csoUpdateCharger', null, charger);
  },
  deleteCharger: function (charger) {
    return AppApiService.post('csoDeleteCharger', null, charger);
  },
  changeMeterGroup: function (chargerData) {
    return AppApiService.post('changeMeterGroup', null, chargerData);
  },
  createToken: function (token) {
    return AppApiService.post('createToken', null, token);
  },
  csoGetChargerToken: function (station) {
    return AppApiService.post('csoGetChargerToken', null, station);
  },
  toggleChargersEnable: function (info) {
    return AppApiService.post('toggleChargersEnable', null, info);
  },
  enableChargerSearch: function (iotHubDeviceId) {
    return AppApiService.post('enableChargerSearch', null, iotHubDeviceId);
  },
  disableChargerSearch: function (iotHubDeviceId) {
    return AppApiService.post('disableChargerSearch', null, iotHubDeviceId);
  },
  setChargingStationPrivacy: (data) => {
    return AppApiService.post('setChargingStationPrivacy', null, data);
  },
  viewChargingStationDiagnostics: (data) => {
    return AppApiService.post('viewChargingStationDiagnostics', null, data);
  },
  startChargingSession: function (charger) {
    return AppApiService.post('startChargingSession', null, charger);
  },
  stopChargingSession: function (charger) {
    return AppApiService.post('stopChargingSession', null, charger);
  },
  getChargingSessionEta: function (vehicle) {
    return AppApiService.post('getChargingSessionEta', null, vehicle);
  },
  getChargingStationStatus: function (charger) {
    return AppApiService.post('getChargingStationStatus', null, charger);
  },
  getFirmwareUpdates: function (charger) {
    return AppApiService.post('getFirmwareUpdates', null, charger);
  },
  updateFirmware: function (charger) {
    return AppApiService.post('updateFirmware', null, charger);
  },
  resetCharger: function(data) {
    return AppApiService.post('resetCharger', null, data);
  },
  strings: localStrings,
};

export default AppChargersService;
