import React, { useState } from 'react';

//layout
import { OuterWrapper, ComponentWrapper } from '../OperatorShared';
import Button from '../../shared/Buttons/Button';

//services
import AppLocationsService from './AppLocationsService';

//components
import LocationsList from '../Locations/LocationsList/LocationsList';

//global components
import SearchFilterBar from '../OperatorShared/SearchFilterBar';
import EnterpriseAdmin from '../EnterpriseAdmin';

const Locations = (props) => {
  const { strings } = AppLocationsService;
  const [searchedValue, setSearchedValue] = useState('');
  const [filteredValue, setFilteredValue] = useState(null);
  const [submitSearch, setSubmitSearch] = useState(false);

  const handleFilterChange = (filter) => {
    setSubmitSearch(false);
    setFilteredValue(filter);
  };

  const handleSearchChange = (value) => {
    setSubmitSearch(false);
    setSearchedValue(value);
  };

  const handleSearchSubmit = (submit) => {
    setSubmitSearch(submit);
  };

  return (
    <OuterWrapper title={strings.pageTitle} internal path='/'>
      <EnterpriseAdmin allowTech>
        <ComponentWrapper title={strings.pageTitle}>
          <SearchFilterBar
            locationsFilter
            filterBy={strings.filterList}
            handleSearchSubmit={handleSearchSubmit}
            handleSearchChange={handleSearchChange}
            handleFilterChange={handleFilterChange}
          />
          {/* list of searched and filtered results */}
          <LocationsList
            filteredValue={submitSearch ? filteredValue : ''}
            searchedValue={submitSearch ? searchedValue : ''}
          />
        </ComponentWrapper>
        <Button
          cso
          hasMobile
          redirect='/locations/add'
          buttonText={strings.add}
          roles={[1]}
        />
      </EnterpriseAdmin>
    </OuterWrapper>
  );
};

export default Locations;
