import download from 'downloadjs';
import moment from 'moment';

export const downloadCSV = (data, fileName) => {
  var finalVal = '';

  let headerVal = Object.entries(data[0])
    .map(([key]) => {
      return key;
    })
    .join(',');

  for (var j = 0; j < data.length; j++) {
    var bodyVal = Object.entries(data[j])
      .map(([key, value]) => {
        return value;
      })
      .join(',');

    finalVal += bodyVal + '\n';
  }

  let blob = new Blob([headerVal + '\n' + finalVal]);
  download(blob, `${fileName}-${Date.now()}.csv`, 'text/csv');
};

export const downloadMultiCSVReport = (fileName, arrayOfData, type) => {
  let finalBuild = '';

  arrayOfData.forEach((item) => {
    if (!item.data?.length) {
      return;
    }
    var finalVal = '';

    if (item.headerVal === undefined) {
      item.headerVal = Object.entries(item.data[0])
        .map(([key]) => {
          let friendlyKey = (key[0].toUpperCase() + key.slice(1)).toString();
          console.log(key, friendlyKey.match(/[A-Z][a-z]+/g));

          if (friendlyKey.includes('kW')) {
            if (friendlyKey.includes('kk')) {
              let keykWh = friendlyKey.indexOf('k');
              return `${friendlyKey.substr(0, keykWh + 1)} ${friendlyKey.substr(
                keykWh + 1
              )}`;
            } else {
              let keykWh = friendlyKey.indexOf('k');
              return `${friendlyKey.substr(0, keykWh)} ${friendlyKey.substr(
                keykWh
              )}`;
            }
          }
          return type !== 'utility'
            ? friendlyKey.match(/[A-Z][a-z]+/g).join(' ')
            : friendlyKey;
        })
        .join(',');
    }

    for (var j = 0; j < item.data.length; j++) {
      var bodyVal = Object.entries(item.data[j])
        .map(([key, value]) => {
          if (
            key.toLowerCase().includes('cost') ||
            key.toLowerCase().includes('rate') ||
            key.toLowerCase().includes('amt')
          ) {
            if (typeof value === 'number') {
              return `$${value.toFixed(2)}`; //value.toString()
            }
          }
          if (
            key.toLowerCase().includes('kwh') ||
            key.toLowerCase().includes('kw')
          ) {
            return typeof value === 'number' ? value.toFixed(2) : value;
          }
          if (Array.isArray(value)) {
            return value.toString().split(',').join(' | ');
          }
          if (!item.voidFormat && key.toLowerCase().includes('date')) {
            return moment(value).format('MM/DD/YYYY h:mma');
          }
          return value?.toString();
        })
        .join(',');
      finalVal += bodyVal + '\n';

      if (j === item.data.length - 1) {
        finalVal += '\n';
      }
    }
    finalBuild +=
      item.title !== '' ? item.title + '\n' + item.headerVal + '\n' + finalVal: '' + item.headerVal + '\n' + finalVal;
  });

  let blob = new Blob([finalBuild]);

  download(blob, `${fileName}.csv`, 'text/csv');
};
