import AppPlatformService from '../../../../services/AppPlatform/AppPlatformService';
import AppApiService from '../../../../services/AppApi/AppApiService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppChargersModalService = {
  //iothubdeviceid and membershipid
  chargerToMembership: function(charger) {
    return AppApiService.post('chargerToMembership', null, charger);
  },
  removeChargerFromMembership: function(charger) {
    return AppApiService.post('removeChargerFromMembership', null, charger);
  },
  strings: localStrings
};

export default AppChargersModalService;
