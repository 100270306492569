import moment from 'moment';

export const dateRange = (filter) => {
  let startDate;
  let endDate;
  let groupBy;
  switch (filter) {
    case 'today':
      startDate = moment().startOf('day');
      endDate = moment();
      groupBy = 'hour';
      return { startDate, endDate, groupBy };
    case 'yesterday':
      startDate = moment().subtract(1, 'day').startOf('day');
      endDate = moment().subtract(1, 'day').endOf('day');
      groupBy = 'hour';
      return { startDate, endDate, groupBy };
    case 'thisweek':
      startDate = moment().startOf('week');
      endDate = moment();
      groupBy = 'day';
      return { startDate, endDate, groupBy };
    case 'lastweek':
      startDate = moment().subtract(1, 'week').startOf('week');
      endDate = moment().subtract(1, 'week').endOf('week');
      groupBy = 'day';
      return { startDate, endDate, groupBy };
    case 'thismonth':
      startDate = moment().startOf('month');
      endDate = moment();
      groupBy = 'day';
      return { startDate, endDate, groupBy };
    case 'lastmonth':
      startDate = moment().subtract(1, 'month').startOf('month');
      endDate = moment().subtract(1, 'month').endOf('month');
      groupBy = 'day';
      return { startDate, endDate, groupBy };
    case 'last3months':
      startDate = moment().subtract(3, 'month').startOf('month');
      endDate = moment();
      groupBy = 'day';
      return { startDate, endDate, groupBy };
    case 'last6months':
      startDate = moment().subtract(6, 'month').startOf('month');
      endDate = moment();
      groupBy = 'day';
      return { startDate, endDate, groupBy };
    case 'thisyear':
      startDate = moment().startOf('year');
      endDate = moment();
      groupBy = 'month';
      return { startDate, endDate, groupBy };
    case 'lastyear':
      startDate = moment().subtract(1, 'year').startOf('year');
      endDate = moment().subtract(1, 'year').endOf('year');
      groupBy = 'month';
      return { startDate, endDate, groupBy };
    default:
      console.log(`undefined filter ${filter}`)
      return { startDate, endDate, groupBy };
  }
};

export const handleDateCheck = (filterDate) => {
  let title = 'Days';
  switch (filterDate) {
    case 'today':
      title = 'Hours';
      return { title };    
    case 'yesterday':
      title = 'Hours';
      return { title };
    case 'thisyear':
      title = 'Months';
      return { title };
    case 'lastyear':
      title = 'Months';
      return { title };
    default:
      return { title };
  }
};

export const handleFilterUpdates = (account, filters, strings) => {
  let locStr = [...strings.filterLocationList];
  let mgStr = [...strings.filterMetergroupList];
  let chargerStr = [...strings.filterChargersList];

  //Check if all locations is filtered - if not then proceed with checking MG
  account.locations.forEach((l) => {
    locStr.push(l.name);
    if (!filters.location.includes(strings.filterLocationList[0])) {
      if (filters.location.includes(l.name)) {
        l.meterGroups.forEach((m) => {
          mgStr.push(m.name);
          if (!filters.metergroup.includes(strings.filterMetergroupList[0])) {
            if (filters.metergroup.includes(m.name)) {
              m.circuits.forEach((c) => {
                chargerStr.push(c.chargingStationIds);
              });
            }
          } else {
            m.circuits.forEach((c) => {
              chargerStr.push(c.chargingStationIds);
            });
          }
        });
      }
    } else {
      l.meterGroups.forEach((m) => {
        mgStr.push(m.name);
        if (!filters.metergroup.includes(strings.filterMetergroupList[0])) {
          if (filters.metergroup.includes(m.name)) {
            m.circuits.forEach((c) => {
              chargerStr.push(c.chargingStationIds);
            });
          }
        } else {
          m.circuits.forEach((c) => {
            chargerStr.push(c.chargingStationIds);
          });
        }
      });
    }
  });

  // if (filters.location.includes(l.name)) {
  //   if (!filters.metergroup.includes(strings.filterMetergroupList[0])) {
  //     if (filters.metergroup.includes(m.name)) {
  //       m.circuits.forEach((c) => {
  //         chargerStr.push(c.chargingStationIds);
  //       });
  //     }
  //   } else {
  //     m.circuits.forEach((c) => {
  //       chargerStr.push(c.chargingStationIds);
  //     });
  //   }
  // }
  return { locStr, mgStr, chargerStr };
  // } else {
  //   //All Locations Selected

  //   account.locations.forEach((l) => {
  //     locStr.push(l.name);
  //     l.meterGroups.forEach((m) => {
  //       mgStr.push(m.name);
  //       m.circuits.forEach((c) => {
  //         chargerStr.push(c.chargingStationIds);
  //       });
  //     });
  //   });
  //   return { locStr, mgStr, chargerStr };
  // }
};
