import React, { useState, useContext } from 'react';

//layout
import { 
  OuterWrapper, 
  ComponentWrapper, 
  SubscriptionAccess, } from '../../OperatorShared';
import Button from '../../../shared/Buttons/Button';

//services
import AppPricingSchedulesService from '../AppPricingSchedulesService';

//components
import FormHeaderComponent from './FormHeaderComponent';
import DailySchedules from './DailySchedules';

//global components
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';
import SmallBizAdmin from '../../SmallBizAdmin';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import { ErrorContext } from '../../../context/shared/ErrorContext';

//error Class
import { ErrorResponse } from '../../../shared/ErrorResponse';
import { FormStyles } from '../../../SharedStyles';

const PricingSchedulesAdd = (props) => {
  const { strings } = AppPricingSchedulesService;
  const { account, setAccount } = useContext(CsoAccountContext);
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(false);
  const [pricingSchedule, setPricingSchedule] = useState({
    csoAccountId: account.id,
    enableSchedules: false,
    defaultRate: 0,
    defaultRateType: '',
    defaultIdleFee: 0,
    defaultIdleFeeType: 'none',
    defaultMaxTime: 0,
    defaultGracePeriod: 0,
    defaultMinAmount: 0,
    schedules: [],
  });

  //form header - name and description
  const handleChange = (name, value) => {
    if (name === 'defaultIdleFeeType' && value === 'none') {
      setPricingSchedule({
        ...pricingSchedule,
        [name]: value,
        defaultIdleFee: 0,
        defaultGracePeriod: 0,
      });
    } else if (
      (name === 'defaultIdleFee' && value === '') ||
      (name === 'defaultIdleFee' && value === '0')
    ) {
      setPricingSchedule({
        ...pricingSchedule,
        [name]: 0,
        defaultIdleFeeType: 'none',
        defaultGracePeriod: 0,
      });
    } else if (name === 'defaultRateType' && value === 'free') {
      setPricingSchedule({
        ...pricingSchedule,
        [name]: value,
        defaultRate: 0,
      });
    } else if (name === 'defaultRate') {
      if (value === '0' || value === '') {
        setPricingSchedule({
          ...pricingSchedule,
          [name]: 0,
          defaultRateType: 'free',
        });
      } else {
        setPricingSchedule({
          ...pricingSchedule,
          [name]: value,
        });
      }
    } else {
      setPricingSchedule({
        ...pricingSchedule,
        [name]: value,
      });
    }
  };
  const handleScheduleAdd = (schedule) => {
    setPricingSchedule({
      ...pricingSchedule,
      schedules: schedule,
    });
  };

  const handleValidation = (finalSchedule) => {
    if (finalSchedule.defaultRate === '') {
      finalSchedule.defaultRate = 0;
    }
    if (finalSchedule.defaultIdleFee === '') {
      finalSchedule.defaultIdleFee = 0;
    }
    if (finalSchedule.defaultGracePeriod === '') {
      finalSchedule.defaultGracePeriod = 0;
    }
    if (finalSchedule.defaultMinAmount === '') {
      finalSchedule.defaultMinAmount = 0;
    }
    
    //check drop on sx
    if (finalSchedule.defaultRateType === '') {
      setLoading(false);
      throw new ErrorResponse(
        'Frontend',
        'Default Rate Type is required to save the schedule.',
        'Invalid Rate Type'
      );
    }
    // Checking no rate added if free is selected
    if (
      finalSchedule.defaultRateType === 'free' &&
      finalSchedule.defaultRate !== 0
    ) {
      setLoading(false);
      throw new ErrorResponse(
        'Frontend',
        'Selected default type does not require a default rate, please remove the default rate or change type to match the desired default rate.',
        'Invalid Rate'
      );
    }

    // Checking the default idle rates and types
    if (
      finalSchedule.defaultIdleFee !== 0 &&
      finalSchedule.defaultIdleFeeType === 'none'
    ) {
      setLoading(false);
      throw new ErrorResponse(
        'Frontend',
        'Selected default idle type does not require a default idle rate, please remove the default idle rate or change type to match the desired default idle rate.',
        'Invalid Rate Type'
      );
    }

    // Checking the default idle graceperiod and types
    if (
      finalSchedule.defaultGracePeriod !== 0 &&
      finalSchedule.defaultIdleFeeType === 'none'
    ) {
      setLoading(false);
      throw new ErrorResponse(
        'Frontend',
        'Selected default idle type does not require a default grace period, please remove the default grace period or change type to match the desired default grace period.',
        'Invalid Grace Period'
      );
    }

    if (!pricingSchedule.enableSchedules) {
      finalSchedule.schedules = [];
    }

    if (pricingSchedule.enableSchedules) {
      //check Day selection
      let checkedDates = finalSchedule.schedules.map((sx) => {
        return Object.keys(sx.dow).every((day) => !sx.dow[day]);
      });
      checkedDates.forEach((boo) => {
        if (boo) {
          setLoading(false);
          throw new ErrorResponse(
            'Frontend',
            'You must select a day of week for this schedule.',
            'Invalid Schedule'
          );
        }
      });

      //check drop down types
      finalSchedule.schedules.map((sx) => {
        return sx.times.forEach((time) => {
          return Object.keys(time).forEach((t) => {
            //empty strings converted to number for backend.
            if (time.idleRate === '') {
              time.idleRate = 0;
            }
            if (time.idleGracePeriod === '') {
              time.idleGracePeriod = 0;
            }

            if (time.idleRate !== 0 && time.idleRateType === 'none') {
              setLoading(false);
              throw new ErrorResponse(
                'Frontend',
                'Selected idle type does not require a rate, please remove the rate or change type to match the desired rate.',
                'Invalid Rate Type'
              );
            }
            if (time.idleGracePeriod !== 0 && time.idleRateType === 'none') {
              setLoading(false);
              throw new ErrorResponse(
                'Frontend',
                'Selected idle type does not require a grace period, please remove the grace period or change type to match the desired grace period.',
                'Invalid Rate Type'
              );
            }

            if (
              time.rateType !== 'free' &&
              (parseFloat(time.rate) === 0 || time.rate === '')
            ) {
              setLoading(false);
              throw new ErrorResponse(
                'Frontend',
                'You must have rate on the time for non free options.',
                'Invalid Rate'
              );
            }

            if (time.rateType === '' || time.idleRateType === '') {
              setLoading(false);
              throw new ErrorResponse(
                'Frontend',
                'You must have rate type on the time.',
                'Invalid Time'
              );
            }
          });
        });
      });
    }

    return finalSchedule;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let finalSX = handleValidation(pricingSchedule);
      let createdProfile = await AppPricingSchedulesService.createPricingSchedule(
        finalSX
      );
      setAccount(createdProfile);
      setLoading(false);
      setTimeout(() => {
        if (!loading) {
          props.history.push('/subscription/pricing-schedules');
        }
      }, 500);
    } catch (err) {
      if (err.name === 'Frontend') {
        setError({
          styles: 'cso',
          display: true,
          message: err.message,
          title: err.title,
        });
        setLoading(false);
      }
      if (err.response) {
        let errMessage = err.response.data;
        let errTitle = 'Something went wrong';
        // let errTitle = '';
        // let errMessage = '';
        let errorResponse = err.response.data.split(' ');
        if (errMessage.includes('Index and length must refer to a location')) {
          errTitle = 'Invalid Time Slot';
          errMessage = 'You must select a valid time.';
        }

        if (errorResponse[0] === 'Invalid') {
          errTitle = 'Invalid Schedule';
          errMessage = `More than one schedule references ${errorResponse[7]}.`;
        }
        setError({
          display: true,
          message: errMessage,
          title: errTitle,
          styles: 'cso',
        });
        setLoading(false);
      }
    }
  };

  return (
    <OuterWrapper
      title={strings.addTitle}
      internal
      path={
        [2, 3].includes(account.subscriptionLevel)
          ? '/subscription/pricing-schedules'
          : '/chargers'
      }
      myForm='myForm'
      roles={[1, 2]}>
      {loading && <LoadingSpinner />}
      <SmallBizAdmin allowCustomer>
        <ComponentWrapper roles={[1, 2]} title={strings.addTitle}>
          <FormStyles
            display='block'
            width='100%'
            id='myForm'
            onSubmit={handleSubmit}>
            {/* Enterprise, Small Biz, and Shared */}
            <FormHeaderComponent
              profile={pricingSchedule}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
            {/* Enterprise and Small Biz Only */}
            <SubscriptionAccess levels={[3]}>
              <DailySchedules
                addForm
                profile={pricingSchedule}
                handleChanges={handleScheduleAdd}
                handleChange={handleChange}
              />
            </SubscriptionAccess>
          </FormStyles>
        </ComponentWrapper>
        <Button
          cso
          hasMobile
          formSubmit
          buttonText={strings.save}
          roles={[1, 2]}
        />
      </SmallBizAdmin>
    </OuterWrapper>
  );
};

export default PricingSchedulesAdd;
