import React, { useState, useEffect, useContext } from 'react';

//services
import AppMeterGroupsService from '../AppMeterGroupsService';

//components
import MeterGroupsCard from './MeterGroupsCard';

//global Components
import Pagination from '../../../shared/Pagination/Pagination';
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';

//hooks
import { usePagination } from '../../../shared/hooks/usePagination';
import { useRouter } from '../../../shared/hooks/useRouter';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';

//images
import MeterGroupListPlaceholder from '../../../../assets/images/MeterGroupListPlaceholder.png';

//styles
import { DataCardListStyles } from '../../../OperatorStyles';
import { ListPlaceholderStyles } from '../../../SharedStyles';

const MeterGroupList = (props) => {
  const { strings } = AppMeterGroupsService;
  const { filteredValue, searchedValue } = props;
  const { account } = useContext(CsoAccountContext);
  const router = useRouter();

  const [meterGroups, setMeterGroups] = useState([]);
  const [filteredMeterGroup, setFilteredMeterGroups] = useState([]);
  const [loading, setLoading] = useState(true);

  //initial Sort Function by lastName - per Jeff. Change the value if we prefer another sort property
  const compare = (a, b) => {
    return a['name'].localeCompare(b['name']);
  };

  useEffect(() => {
    const query = new URLSearchParams(router.location.search);

    //fetches data without any params or query Strings
    const fetchingData = async () => {
      let allMeterGroups = await AppMeterGroupsService.getAllMeterGroups();
      allMeterGroups.sort(compare);
      setMeterGroups(allMeterGroups);
      if (allMeterGroups.length === 0) {
        setLoading(false);
      }
    };

    //fetches data with a query Strings based on modal from locations page for meter groups
    const fetchQueryData = async () => {
      let locationId = query.get('locationId');
      let allQueriedMeterGroups = await AppMeterGroupsService.getAllMeterGroupsByLocation(
        locationId
      );
      allQueriedMeterGroups.sort(compare);
      setMeterGroups(allQueriedMeterGroups);
      setLoading(false);
    };
    if (!router.location.search) {
      fetchingData();
    } else {
      fetchQueryData();
    }
  }, [account, router.location.search, meterGroups.length]);

  useEffect(() => {
    const lowercasedSearch = searchedValue.toLowerCase();
    const searchedMeterGroups = meterGroups.filter((group) => {
      return Object.keys(group).some((key) => {
        if (group[key] !== null) {
          return group[filteredValue ? filteredValue : key]
            .toString()
            .toLowerCase()
            .includes(lowercasedSearch);
        }
        return null;
      });
    });
    setFilteredMeterGroups(searchedMeterGroups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedValue, filteredValue]);

  /**
   * pagination for the data
   * @param {*} dataArray - items that will be sorted
   * @param {*} itemsPerPage - Custom items limit per page
   */
  const {
    next,
    prev,
    jump,
    currentData,
    currentPage,
    maxPage,
    totalData,
    currentCount,
    pageNumbers,
  } = usePagination(filteredMeterGroup, 20);

  useEffect(() => {
    setFilteredMeterGroups(meterGroups);
  }, [meterGroups]);

  useEffect(() => {
    if (filteredMeterGroup.length) {
      setLoading(false);
    }
  }, [filteredMeterGroup]);

  return (
    <>
      {loading && <LoadingSpinner />}
      {meterGroups.length !== 0 && (
        <Pagination
          currentCount={currentCount}
          totalData={totalData}
          next={next}
          prev={prev}
          jump={jump}
          currentPage={currentPage}
          maxPage={maxPage}
          pageNumbers={pageNumbers}
        />
      )}
      <DataCardListStyles>
        {currentData().map((group) => (
          <MeterGroupsCard meterGroup={group} key={group.meterGroupId} />
        ))}
        {meterGroups.length === 0 && !loading && (
          <ListPlaceholderStyles>
            <p>{strings.noMeterGroupsAdded}</p>
            <img src={MeterGroupListPlaceholder} alt='Placeholder' />
          </ListPlaceholderStyles>
        )}
      </DataCardListStyles>
    </>
  );
};
export default MeterGroupList;
