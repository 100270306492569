//services
import AppMembershipsService from './AppMembershipsService';

const { strings } = AppMembershipsService;

export const tableHeaderConvert = {
  [strings.firstName]: 'firstName',
  [strings.lastName]: 'lastName',
  [strings.email]: 'email',
  [strings.joined]: 'joined',
  [strings.expiration]: 'expiration',
};
