import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaRegEdit, FaRegTrashAlt, FaEllipsisV } from 'react-icons/fa';

//services
import AppChargingProfilesService from '../AppChargingProfilesService';

//modals
import DeleteModal from '../ChargingProfileModals/DeleteModal';

//hooks
import { useHours } from '../../../shared/hooks/useHours';
import useOnClickOutside from '../../../shared/hooks/useOnClickOutside';
import { useWindowResize } from '../../../shared/hooks/useWindowSize';
import { useContainerHeight } from '../../../shared/hooks/useContainerHeight';

//styles
import { TextStyles, TitleStyles, DivStyles } from '../../../SharedStyles';
import { DataCardStyles, ToolsStyles } from '../../../OperatorStyles';

//helpers
import { shortHandDays } from '../helpers';
import RoleCanEdit from '../../OperatorShared/RoleCanEdit';

const ChargingProfileCard = (props) => {
  const { strings } = AppChargingProfilesService;
  const { profile } = props;
  const [deleteModal, setDeleteModal] = useState(false);
  const [hours] = useHours();
  const CardRef = useRef();
  const OutsideRef = useRef();
  const size = useWindowResize(window.innerWidth, window.innerHeight);
  const { containerHeight, containerRef, setContainerRef } = useContainerHeight(
    50,
    CardRef
  );
  const [openTools, setOpenTools] = useState(false);

  //gets card container height for responsive
  useEffect(() => {
    setContainerRef(CardRef);
  }, [CardRef, setContainerRef]);

  useOnClickOutside(OutsideRef, () => setOpenTools(false));

  const toggleToolBar = () => {
    setOpenTools(!openTools);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const deleteProfile = () => {
    props.deleteProfile(profile.id);
  };

  return (
    <>
      {deleteModal && (
        <DeleteModal
          handleClose={toggleDeleteModal}
          deleteProfile={deleteProfile}
          profile={profile}
        />
      )}
      <DataCardStyles
        ref={containerRef}
        onMouseEnter={() => {
          size[0] >= 768 && setOpenTools(true);
        }}
        onMouseLeave={() => {
          size[0] >= 768 && setOpenTools(false);
        }}>
        <Link to={`/subscription/charging-profiles/${profile.id}`}>
          <DivStyles>
            <TitleStyles size='14px' weight='600' margin='0'>
              {profile.name}
            </TitleStyles>
            <TextStyles
              size='12px'
              color='#617793'
              weight='500'
              margin='2px 0 0'>
              {profile.schedules.map((sx, idx) => {
                return (
                  <span key={sx.id}>
                    {Object.entries(sx.dow)
                      .filter(([key, value]) => {
                        return value === true;
                      })
                      .map((day, idx, arr) => {
                        return (
                          <span key={day[0]}>
                            {shortHandDays[day[0]]}
                            {idx !== arr.length - 1 ? ', ' : ': '}
                          </span>
                        );
                      })}
                    {sx.times.map((time, index) => {
                      let startingTime;
                      let endingTime;
                      hours.forEach((hr) => {
                        if (hr.time === time.timeStart) {
                          startingTime = hr.display;
                        }
                        if (hr.time === time.timeEnd) {
                          endingTime = hr.display;
                        }
                      });
                      return (
                        <span key={index}>
                          {startingTime} - {endingTime}; {time.percentage}
                          %;
                        </span>
                      );
                    })}
                    {idx !== profile.schedules.length - 1 ? ' | ' : ' '}
                  </span>
                );
              })}
            </TextStyles>
          </DivStyles>
        </Link>
        <FaEllipsisV
          className='Card-Tools-Toggle'
          style={size[0] >= 768 ? { display: 'none' } : { display: 'block' }}
          onClick={toggleToolBar}
        />
        <ToolsStyles
          ref={OutsideRef}
          displayTools={openTools}
          containerHeight={(containerHeight - 35) / 2}>
          <Link
            to={`/subscription/charging-profiles/${profile.id}`}
            title={strings.editTitle}
            className='Tool-Item'>
            <FaRegEdit />
          </Link>
          <RoleCanEdit roles={[1, 2]}>
            <div className='Tool-Item' onClick={toggleDeleteModal}>
              <FaRegTrashAlt title={strings.deleteTitle} />
            </div>
          </RoleCanEdit>
        </ToolsStyles>
      </DataCardStyles>
    </>
  );
};

export default ChargingProfileCard;
