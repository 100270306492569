import React from 'react';
import { connectorStatuses, connectorConstants } from '../../helpers';

const appChargingStatus = (props) => {
  let connectors = [];
  let status = 0;
  let nonAuthedConnectors = [];
  const availableStatus = 'charging-port ';

  if (
    props.connectors.connectorStatuses &&
    props.connectors.connectorStatuses.length > 0
  ) {
    connectors = props.connectors.connectorStatuses;
  }

  if (props.currentUserConnector) {
    status = props.currentUserConnector.status;
  } else {
    status = props.connectors.availabilityStatus;
  }

  if (props.connectors.connectorStatuses) {
    nonAuthedConnectors = props.connectors.connectorStatuses.filter((c) => {
      // At least one connector exists that doesn't have someone else authed. If it's
      // not in an available state and it's in this array, we know that it's preparing
      // but not authenticated.

      return (
        (c.status === connectorConstants.Available ||
          c.isCurrentUser === true ||
          !c.isAuthedForOtherUser) &&
        c.status !== connectorConstants.Unavailable
      );
    });
  }
  if (nonAuthedConnectors.length === 0) {
    status = connectorConstants.Unavailable;
  }

  //checking charging station status
  const checkingChargingStationStatus = (connectors) => {
    let isFree = [];
    let isOwner = [];

    connectors.forEach((connector, i) => {
      if (
        !(
          connector.membershipOnlyBlocked ||
          connector.waitQueueBlocked ||
          connector.reservationBlocked
        ) &&
        (connector.status === 1 ||
          (connector.status === 2 &&
            !connector.isCurrentUser &&
            !connector.isAuthedForOtherUser &&
            connector.vehicleId === ''))
      ) {
        isFree[i] = true;
      } else {
        isFree[i] = false;
      }
    });

    let freeResult = isFree.some((value) => value === true);
    let ownedResult = isOwner.every((value) => value === false);

    //checking owner and displaying status
    if (ownedResult) {
      if (
        props.currentUserConnector &&
        props.currentUserConnector.vehicleId === props.vehicle.id
      ) {
        return connectorStatuses[status];
      }
    }
    //checking if avaiable and displaying status
    if (freeResult) {
      if (
        props.currentUserConnector &&
        props.currentUserConnector.vehicleId === props.vehicle.id
      ) {
        return connectorStatuses[status];
      } else if (
        connectors.length === 1 &&
        !props.currentUserConnector &&
        connectors[0].status === 2
      ) {
        return connectorStatuses[connectorConstants.Preparing];
      } else {
        return connectorStatuses[connectorConstants.Available];
      }
    } else {
      return connectorStatuses[connectorConstants.Unavailable];
    }
  };

  const checkChargerStatus = (port) => {
    //checking iscurrentuser and car
    if (port.isCurrentUser && port.vehicleId === props.vehicle.id) {
      return connectorStatuses[port.status].toLowerCase();
      //checking if in preparing state by plug in
    } else if (
      port.membershipOnlyBlocked ||
      port.reservationBlocked ||
      port.waitQueueBlocked
    ) {
      return connectorStatuses[connectorConstants.Unavailable].toLowerCase();
    } else if (
      !port.isCurrentUser &&
      port.vehicleId !== props.vehicle.id &&
      (port.status === 2 || port.status === 1) &&
      !port.isAuthedForOtherUser
    ) {
      return connectorStatuses[port.status].toLowerCase();
    } else {
      return connectorStatuses[connectorConstants.Unavailable].toLowerCase();
    }
  };

  return (
    <div className='charging-status-container'>
      {connectors.length > 1 &&
        ((props.currentUserConnector &&
          props.currentUserConnector.isCurrentUser) ||
          props.connectors.availabilityStatus) && (
          <div>
            port 1
            <div
              className={
                checkChargerStatus(connectors[0]) === 'available'
                  ? availableStatus
                  : availableStatus + 'port-status-unavailable'
              }>
              {checkChargerStatus(connectors[0])}
            </div>
          </div>
        )}
      <div>
        <p className='charging-progress-status'>
          Status: {checkingChargingStationStatus(connectors)}
          {checkingChargingStationStatus(connectors) === 'Charging' &&
            Object.keys(props.eta).length === 0 &&
            ' (waiting)'}
        </p>
      </div>
      {connectors.length > 1 &&
        ((props.currentUserConnector &&
          props.currentUserConnector.isCurrentUser) ||
          props.connectors.availabilityStatus) && (
          <div>
            port 2
            <div
              className={
                checkChargerStatus(connectors[1]) === 'available'
                  ? availableStatus
                  : availableStatus + 'port-status-unavailable'
              }>
              {checkChargerStatus(connectors[1])}
            </div>
          </div>
        )}
    </div>
  );
};

export default appChargingStatus;
