import React, { useState } from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//global component
import ModalButton from '../../../shared/Buttons/ModalButton';
import { LoadingSpinner } from '../../../shared';

//hooks
import { useCloudinary } from '../../../shared/Cloudinary/hook/useCloudinary';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  InputStyles,
  ButtonDivGreyStyles,
} from '../../../SharedStyles';

//helpers

const AppCSOAccountModal = (props) => {
  const { strings, showEditTitleModal, account } = props;
  const [updatedName, setUpdatedName] = useState(account.csoAccountName);

  const { cloudData, uploadImage, imageLoading } = useCloudinary({
    preset: 'account_images',
    tags: [account.csoAccountName, account.id],
  });

  const closeModal = () => {
    showEditTitleModal();
  };

  //clicks the hidden input for image upload
  const openDialog = () => {
    document.getElementById('inputUpload').click();
  };

  const handleSaveInfo = (e) => {
    e.preventDefault();
    if (cloudData) {
      props.updatingAccount({
        ...account,
        csoAccountName: updatedName,
        imagePath: cloudData.secure_url,
      });
    } else {
      props.updatingAccount({
        ...account,
        csoAccountName: updatedName,
      });
    }
    closeModal();
  };

  const handleChange = (e) => {
    setUpdatedName(e.target.value);
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {imageLoading && <LoadingSpinner />}
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.companyEditNameAndImage}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          {props.input && (
            <ModalItemStyles padding='0 10px'>
              <p>{strings.companyEditNameHeader}</p>
              <InputStyles
                type='text'
                onChange={handleChange}
                name='name'
                value={updatedName}
              />
            </ModalItemStyles>
          )}
          <ModalItemStyles>
            <p>{strings.companyEditContent}</p>
            <img
              src={
                cloudData
                  ? cloudData.secure_url
                  : account.imagePath
                  ? account.imagePath
                  : require('../../../../assets/images/AccountPlaceholder.png').default
              }
              alt={account.name}
            />
            <ModalItemStyles>
              <InputStyles
                className='file-input'
                id='inputUpload'
                type='file'
                hidden
                accept='image/*'
                onChange={uploadImage}
              />
              <ButtonDivGreyStyles
                margin='10px 0 0'
                id='fileUpload'
                onClick={openDialog}
                className='AppCso-modal-image-button'>
                {strings.companyEditLogo}
              </ButtonDivGreyStyles>
            </ModalItemStyles>
          </ModalItemStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={handleSaveInfo}
          buttonText={props.save ? strings.save : strings.ok}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default AppCSOAccountModal;
