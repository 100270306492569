import React from 'react';
import { FaRegWindowClose, FaExclamationTriangle } from 'react-icons/fa';

//services
import AppModalService from './AppModalService';

//global components
import ModalButton from '../../../../../shared/Buttons/ModalButton';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  DivStyles,
  ModalNoteStyles,
  SelectStyles,
} from '../../../../../SharedStyles';

const IpAddress = (props) => {
  const { strings } = AppModalService;

  const handleChange = (e) => {
    console.log('ipAddressModal', e.target.name, e.target.value);
  };

  const closeModal = () => {
    props.handleModalClose();
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.ipTitle}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <p>{strings.ipContent}</p>
          </ModalItemStyles>
          {/* Section */}
          <ModalItemStyles pSize='12px' textMargin='0 0 5px' padding='0 5px'>
            <DivStyles display='flex' alignItems='center' align='left'>
              <DivStyles weight='600' align='left' flex='1'>
                <p>{strings.ipSSID}</p>
                <p>{strings.ipAssignment}</p>
                <p>{strings.ipGateway}</p>
                <p>{strings.ipDNS}</p>
                <p>{strings.ipAddress}</p>
                <p>{strings.ipSubnet}</p>
                <p>{strings.ipMac}</p>
              </DivStyles>
              <DivStyles align='left' flex='1' color='#aaa6a6'>
                <p>MillStWAP</p>
                <SelectStyles
                  noDropdown
                  padding='0'
                  margin='0 0 5px'
                  size='12px'
                  onChange={handleChange}>
                  {strings.ipSelects.map((ip) => {
                    return (
                      <option value={ip} key={ip}>
                        {ip}
                      </option>
                    );
                  })}
                </SelectStyles>
                <p>{props.chargerDetails.ipAddress}</p>
                <p>255.255.255.0</p>
                <p>192.168.1.1</p>
                <p>192.168.1.1</p>
                <p>00-14-22-01-23-45</p>
              </DivStyles>
            </DivStyles>
          </ModalItemStyles>
          <ModalNoteStyles>
            <div className='Warning-modal'>
              <FaExclamationTriangle />
              <strong>{strings.warning} -</strong> {strings.firmwareWarning}
            </div>
          </ModalNoteStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={closeModal}
          buttonText={strings.ok}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default IpAddress;
