import React, { useState, useEffect } from 'react';

//components
import IncomeCard from './IncomeCard';

//styles
import {
  ListPlaceholderStyles,
  TableStyles,
  TableHeaderStyles,
  TableItemStyles,
} from '../../../../../SharedStyles';

//helpers
import IncomeLowBalance from './IncomeLowBalance';

const IncomeList = (props) => {
  const { strings, statementDetails } = props;
  const [accountCredits, setAccountCredits] = useState([]);
  const [accountDebits, setAccountDebits] = useState([]);

  useEffect(() => {
    if (statementDetails) {
      let filteredDebits = statementDetails.transactionId.startsWith('py');
      setAccountDebits(filteredDebits);
      let filteredCredits = statementDetails.transactionId.startsWith('tr');
      setAccountCredits(filteredCredits);
    }
  }, [statementDetails]);

  // Credit to payout missing due to less 25 dollars
  if (props.credit && accountCredits.length === 0) {
    return <IncomeLowBalance strings={strings} />;
  }

  return (
    <TableStyles width='100%' minHeight='40px' hideScroll>
      <TableHeaderStyles
        borderTop='none'
        borderLeft='none'
        borderRight='none'
        width='780px'>
        {strings.incomeHeader.map((title, i) => {
          return (
            <TableItemStyles
              width='100%'
              minWidth={title === 'Trans ID' ? '225px' : 'auto'}
              borderRight
              key={i}>
              {title}
            </TableItemStyles>
          );
        })}
      </TableHeaderStyles>
      {/* Credit to payout */}
      {props.credit && props.statementDetails && (
        <IncomeCard credit item={statementDetails} />
      )}
      {/* Payments  to withdraw  */}
      {props.debit && props.statementDetails && (
        <IncomeCard debit item={statementDetails} />
      )}
      {props.debit && accountDebits.length === 0 && (
        <ListPlaceholderStyles>No Debits</ListPlaceholderStyles>
      )}
    </TableStyles>
  );
};

export default IncomeList;
