import { useState, useEffect } from 'react';

//hooks
import { useParamsForm } from './useParamsForm';
import { useParamsFetch } from '../hooks/useParamsFetch';

export const useParams = ({ entityId, page, paramType, error }) => {
  const [dataParams, setDataParams] = useState({
    type: 'getParams',
    params: {
      entityId: entityId,
      type: paramType,
      page: page,
      parameterList: [],
    },
    throwError: error,
  });
  const { response } = useParamsFetch(dataParams);

  const [formData, setFormData] = useParamsForm(response);

  // Setting the value for ParameterList when form is changed
  useEffect(() => {
    if (formData) {
      //turning object into key value params array
      let formParams = Object.entries(formData).map(([key, value]) => {
        if (value !== '') {
          return { key, value };
        }
        //remove property from object if value is empty
        return { key, value };
      });

      //updating with key value array
      setDataParams((prev) => ({
        ...prev,
        params: { ...prev.params, parameterList: formParams },
      }));
    }
  }, [formData]);

  useEffect(() => {
    if (response && dataParams.type === 'setParams') {
      setDataParams((prev) => ({
        ...prev,
        type: 'getParams',
      }));
    }
    // eslint-disable-next-line
  }, [response]);

  // updates the data params to call the setParams api
  const handleSubmit = (e) => {
    e.preventDefault();
    setDataParams({
      ...dataParams,
      type: 'setParams',
    });
  };

  return [formData, setFormData, handleSubmit];
};
