import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppMembershipsService from '../AppMembershipsService';

//global components
import ModalButton from '../../../shared/Buttons/ModalButton';

//hooks
import { useRouter } from '../../../shared/hooks/useRouter';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
} from '../../../SharedStyles';

const RedirectChargers = (props) => {
  const { membership } = props;
  const { strings } = AppMembershipsService;
  const router = useRouter();

  const closeModal = () => {
    props.handleCloseModal();
  };

  const handleSaveInfo = (e) => {
    e.preventDefault();
    router.history.push(`/chargers?membershipId=${membership.id}`);
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.chargerTitle}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <p>{strings.chargerContent}</p>
          </ModalItemStyles>
          {/* Section */}
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={handleSaveInfo}
          buttonText={strings.go}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default RedirectChargers;
