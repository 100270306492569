import React, { useContext } from 'react';

//components
import DiscountSchedulesCard from './DiscountSchedulesCard';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';

const DiscountSchedulesList = (props) => {
  const { account, setAccount } = useContext(CsoAccountContext);

  const updateAccount = (csoAccount) => {
    setAccount(csoAccount);
  };

  return (
    <>
      {account &&
        account.discountSchedules.map((profile, index) => {
          return (
            <DiscountSchedulesCard
              index={index}
              key={profile.id}
              profile={profile}
              updateAccount={updateAccount}
              adminPermission={props.adminPermission}
            />
          );
        })}
    </>
  );
};

export default DiscountSchedulesList;
