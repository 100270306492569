const empty = {};

const isObject = (x) => Object(x) === x;

const diff1 = (left = {}, right = {}, rel = 'left') =>
  Object.entries(left)
    .map(([k, v]) => {
      // console.log('isObject', isObject(v), 'right', right[k]);
      // if (isObject(v) && isObject(right[k])) {
      //   console.log('k', k);
      //   console.log('v', v);
      //   console.log('rightk', right, k, right[k]);
      //   console.log('rel', rel);
      //   console.log();
      // }
      return isObject(v) && isObject(right[k])
        ? [k, diff1(v, right[k], rel)]
        : right[k] !== v
        ? [k, { [rel]: v }]
        : [k, empty];
    })
    .reduce((acc, [k, v]) => (v === empty ? acc : { ...acc, [k]: v }), empty);

// export const customDiff = (original = {}, modified = {}) =>
//   merge(diff1(x, y, 'original'), diff1(y, x, 'modified'));

// customDiff({ host: 'localhost', port: 80 }, { host: '127.0.0.1', port: 80 });

const merge = (left = {}, right = {}) =>
  Object.entries(right).reduce(
    (acc, [k, v]) =>
      isObject(v) && isObject(left[k])
        ? { ...acc, [k]: merge(left[k], v) }
        : { ...acc, [k]: v },
    left
  );

export const diff = (x = {}, y = {}) =>
  merge(diff1(x, y, 'left'), diff1(y, x, 'right'));

export const deepDiffMapper = (function () {
  return {
    VALUE_CREATED: 'created',
    VALUE_UPDATED: 'updated',
    VALUE_DELETED: 'deleted',
    VALUE_UNCHANGED: 'unchanged',
    map: function (obj1, obj2) {
      if (this.isFunction(obj1) || this.isFunction(obj2)) {
        throw new Error('Invalid argument. Function given, object expected.');
      }
      if (this.isValue(obj1) || this.isValue(obj2)) {
        return {
          type: this.compareValues(obj1, obj2),
          data: obj1 === undefined ? obj2 : obj1,
        };
      }

      var diff = {};
      for (var key in obj1) {
        if (this.isFunction(obj1[key])) {
          continue;
        }

        var value2 = undefined;
        if (obj2[key] !== undefined) {
          value2 = obj2[key];
        }

        diff[key] = this.map(obj1[key], value2);
      }
      for (var key2 in obj2) {
        if (this.isFunction(obj2[key2]) || diff[key] !== undefined) {
          continue;
        }

        diff[key] = this.map(undefined, obj2[key2]);
      }

      return diff;
    },
    compareValues: function (value1, value2) {
      if (value1 === value2) {
        return this.VALUE_UNCHANGED;
      }
      if (
        this.isDate(value1) &&
        this.isDate(value2) &&
        value1.getTime() === value2.getTime()
      ) {
        return this.VALUE_UNCHANGED;
      }
      if (value1 === undefined) {
        return this.VALUE_CREATED;
      }
      if (value2 === undefined) {
        return this.VALUE_DELETED;
      }
      return this.VALUE_UPDATED;
    },
    isFunction: function (x) {
      return Object.prototype.toString.call(x) === '[object Function]';
    },
    isArray: function (x) {
      return Object.prototype.toString.call(x) === '[object Array]';
    },
    isDate: function (x) {
      return Object.prototype.toString.call(x) === '[object Date]';
    },
    isObject: function (x) {
      return Object.prototype.toString.call(x) === '[object Object]';
    },
    isValue: function (x) {
      return !this.isObject(x) && !this.isArray(x);
    },
  };
})();
