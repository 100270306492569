import React, { useState } from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppVehicleService from '../AppVehicleService';

//components
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';

const DeleteModal = props => {
  const { strings } = AppVehicleService;
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    props.closeModal();
  };

  const handleDeleteVehicle = () => {
    setLoading(true);
    props.deleteVehicle();
  };

  return (
    <div className='Modal-Display-Driver'>
      {loading && <LoadingSpinner />}
      <div className='Modal'>
        <div className='Modal-Header'>
          {strings.deleteModalTitle}
          <div className='Modal-Close'>
            <FaRegWindowClose onClick={closeModal} />
          </div>
        </div>
        <div className='Modal-Content'>
          <p className='Modal-Content-Text'>{strings.deleteModalContent}</p>
        </div>
        <div className='Modal-Button'>
          <button className='app-button' onClick={handleDeleteVehicle}>
            {strings.deleteModalYesBtn}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
