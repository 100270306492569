import React, { useState, useEffect, useContext } from 'react';
import QrReader from 'react-qr-reader';

//services
import AppFindChargerService from '../AppFindChargerService';

//hooks
import { useRouter } from '../../../shared/hooks/useRouter';

//context
import { DriverContext } from '../../../context/driver/getDriverContext';
import { ErrorContext } from '../../../context/shared/ErrorContext';

//styles
import { DivStyles, TextStyles } from '../../../SharedStyles';
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';
import { FaCamera } from 'react-icons/fa';

const ScannerInside = (props) => {
  const { driver } = useContext(DriverContext);
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(false);
  const [cameraDenied, setCameraDenied] = useState(false);
  const [result, setResult] = useState(null);

  const router = useRouter();

  const handleSubmit = async (data) => {
    setLoading(true);
    try {
      //"https://qr.ampedup.net/<group>/?c=AMP00047"
      if (data.includes('https://qr.ampedup.net/')) {
        data = data.split('/?c=')[1];
      }
      
      let res = await AppFindChargerService.getChargingStation({
        iotHubDeviceId: data,
        location: driver.driverLocation,
        bypassDirection: false,
      });

      if (res.private || !res.enabledSubscription) {
        setResult(null);

        throw new Error();
      }

      if (res) {
        router.history.push(`/charging/${res.iotHubDeviceId}`);
      }
    } catch (err) {
      setLoading(false);
      setResult(null);
      setError({
        display: true,
        title: 'Invalid Charger',
        message: 'An invalid Charger ID was entered. Please try again.',
        styles: 'driver',
      });
    }
  };

  useEffect(() => {
    if (result) {
      handleSubmit(result);
    }
    // eslint-disable-next-line
  }, [result]);

  const handleScan = (data) => {
    setResult(data);
  };

  const handleError = (err) => {
    setCameraDenied(true);
    setError({
      display: true,
      title: 'Invalid Permission',
      message: 'Camera Permission Denied, access to scan will be disabled.',
      styles: 'driver',
    });
  };

  return (
    <DivStyles width='150px' margin='25px auto'>
      {loading && <LoadingSpinner />}
      {cameraDenied && (
        <TextStyles>
          <FaCamera />
          Disabled
        </TextStyles>
      )}
      {!cameraDenied && (
        <QrReader
          delay={300}
          showViewFinder={false}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        />
      )}
    </DivStyles>
  );
};

export default ScannerInside;
