import decodeJwt from 'jwt-decode';
import authentication from '@kdpw/msal-b2c-react';
import AppPlatformService from '../AppPlatform/AppPlatformService';
import AppStorageService from '../AppStorage/AppStorageService';
import strings from './strings';

const isAuthenticated = () => {
  return new Promise((resolve, reject) => {
    AppStorageService.getValue(AppStorageService.values.authToken)
      .then((token) => {
        resolve(token !== undefined && token !== null);
      })
      .catch(() => resolve(false));
  });
};

const adb2cLogOut = () => {
  AppPlatformService.debug('adb2cLogOut: Starting...');
  return new Promise((resolve, reject) => {
    AppPlatformService.debug('adb2cLogOut: Clearing auth token...');
    AppStorageService.removeValue(AppStorageService.values.authToken)
      .then(() => {
        AppPlatformService.debug(
          'adb2cLogOut: Calling authentication.signOut() ...'
        );
        authentication.signOut();
      })
      .finally(() => {
        AppPlatformService.debug('adb2cLogOut: Done');
        resolve();
      });
  });
};

var initialHref = window.location.href;

function restartApplication() {
  // Reload original app url (ie your index.html file)
  window.location = initialHref;
  window.location.reload();
}

/**
 * Remove all msal-related key/value pairs from sessionStorage.
 */
const clearMsalSessionVars = () => {
  for (var obj in sessionStorage) {
    if (obj.indexOf('msal.') !== -1) {
      sessionStorage.removeItem(obj);
    }
  }
};

const AppAuthService = {
  /**
   * Ensure that when loading the main application, the 'ResetPassword'
   * policy is not the acting authentication authority. If it is, we
   * need to clear out the msal-related sessionStorage objects and
   * reload the page.
   */
  checkAuthority: () => {
    const resetPasswordPolicySuffix = 'b2c_1_webapp_resetpassword';

    // TODO bavant: We need to research to see if there is a cleaner way to do this.
    for (var obj in sessionStorage) {
      if (
        obj.indexOf('msal.authority') !== -1 &&
        sessionStorage[obj].indexOf(resetPasswordPolicySuffix) !== -1
      ) {
        clearMsalSessionVars();

        // If you don't do this, the msal library will append
        // '/null' to the msal.login.request, which will
        // cause the URI to contain '/null' when the page
        // loads.
        sessionStorage.setItem('msal.login.request', '/');

        // It is necessary to refresh the page in order for
        // the sessionStorage change to take affect and for
        // the msal library to rebuild the sessionStorage.
        window.location.reload();
      }
    }
  },
  /*
    getWebSignInOptions: () => {
        return strings.adb2c[AppPlatformService.getVersion()];
    },
    */
  doAdminSignIn: (onCompleteEvent) => {
    // let adb2cOptions = {};

    // console.log('WEBSIGN IN: ', AppPlatformService.getVersion());

    // if (strings.adb2c[AppPlatformService.getVersion()]) {
    //   adb2cOptions = strings.adb2c[AppPlatformService.getVersion()];
    // } else {
    //   adb2cOptions = strings.adb2c.default;
    // }

    // if (localStorage.getItem('csoAccount')) {
    //   adb2cOptions.signInPolicy =
    //     strings.adb2c[AppPlatformService.getVersion()].csoSignInPolicy;
    // }

    // if (window.location.hostname.includes('localhost')) {
    //   adb2cOptions.redirectUri = window.location.origin;
    //   adb2cOptions.postLogoutRedirectUri = window.location.origin;
    // }

    // authentication.initialize(adb2cOptions);

    // return authentication.run(() => {
    //   const token = authentication.getAccessToken();
    //   AppStorageService.setValue(
    //     AppStorageService.values.authToken,
    //     token
    //   ).then(() => {
    return onCompleteEvent();
    //   });
    // });
  },
  doWebSignIn: (onCompleteEvent) => {
    let adb2cOptions = {};

    console.log('WEBSIGN IN: ', AppPlatformService.getVersion());

    if (strings.adb2c[AppPlatformService.getVersion()]) {
      adb2cOptions = strings.adb2c[AppPlatformService.getVersion()];
    } else {
      adb2cOptions = strings.adb2c.default;
    }

    if (localStorage.getItem('csoAccount')) {
      adb2cOptions.signInPolicy =
        strings.adb2c[AppPlatformService.getVersion()].csoSignInPolicy;
    }

    if (window.location.hostname.includes('localhost')) {
      adb2cOptions.redirectUri = window.location.origin;
      adb2cOptions.postLogoutRedirectUri = window.location.origin;
    }

    authentication.initialize(adb2cOptions);

    console.log('adb2coptions', adb2cOptions);

    return authentication.run(() => {
      const token = authentication.getAccessToken();
      AppStorageService.setValue(
        AppStorageService.values.authToken,
        token
      ).then(() => {
        onCompleteEvent();
      });
    });
  },
  doWebSignOut: () => {
    AppStorageService.removeValue(AppStorageService.values.authToken).then(
      () => {
        if (window.msal === undefined) {
          AppAuthService.doWebSignIn(() => {
            authentication.signOut();
          });
          sessionStorage.clear();
          adb2cLogOut();
        } else {
          authentication.signOut();
          sessionStorage.clear();
          adb2cLogOut();
        }
      }
    );
  },
  doMobileSignOut: () => {
    AppStorageService.removeValue(AppStorageService.values.authToken).then(
      () => {
        restartApplication();
      }
    );
  },
  isAuthenticated: () => {
    return isAuthenticated();
  },
  doMobileSignInForCsoHook: () => {
    /*
    adb2cLogOut()
    .then(() => {
      AppPlatformService.debug('Start doMobileSignInForCsoHook');
      let adb2cOptions = {};

      if (strings.adb2c[AppPlatformService.getVersion()]) {
        adb2cOptions = strings.adb2c[AppPlatformService.getVersion()];
      } else {
        adb2cOptions = strings.adb2c.default;
      }

      adb2cOptions.signInPolicy = strings.adb2c.default.csoSignInPolicy;
      adb2cOptions.redirectUri = window.location.origin;
      adb2cOptions.postLogoutRedirectUri = window.location.origin;

      authentication.initialize(adb2cOptions);
      authentication.run();
    });
    */
  },
  doMobileSignInForDriverHook: () => {
    /*
    AppPlatformService.debug('doMobileSignInForDriverHook: Starting...');
    // adb2cLogOut()
    // .then(() => {
      // AppPlatformService.debug('doMobileSignInForDriverHook: Logged Out');
      AppPlatformService.debug('doMobileSignInForDriverHook: Running auth...');
      // window.location = encodeUri(strings.adb2c.default.driverDirectUrl);
      // AppPlatformService.debug('doMobileSignInForDriverHook: Done');
    // });
    */
  },
  showSignIn: (onCompleteEvent) => {
    AppPlatformService.debug('showSignIn: Starting...');
    let authUrl = '';

    if (strings.authUrl[AppPlatformService.getVersion()]) {
      authUrl = strings.authUrl[AppPlatformService.getVersion()];
    } else {
      authUrl = strings.authUrl.default;
    }

    if (AppPlatformService.isWebApp()) {
      window.location = authUrl;
    } else {
      AppPlatformService.debug('showSignIn: detected mobile app');
      let env = AppPlatformService.getVersion();

      if (AppPlatformService.isCSOApp()) {
        authUrl = `${strings.adb2c.default.csoDirectUrl}&env=${env}&app=cso&platform=mobile`;
      } else {
        authUrl = `${strings.adb2c.default.driverDirectUrl}&env=${env}&app=driver&platform=mobile`;
      }

      AppPlatformService.debug('showSignIn: using ' + authUrl);

      let browserView = window.open(
        authUrl,
        '_blank',
        'location=no,toolbar=no'
      );
      browserView.addEventListener('loadstop', (event) => {
        if (event && event.url && event.url.indexOf('id_token=') >= 0) {
          browserView.close();
          if (onCompleteEvent) {
            // event.url.indexOf(authUrl) >= 0 &&

            // let urlStr = event.url.replace('#', '?');
            // let params = new URLSearchParams(urlStr);
            // let idToken = params.get('id_token');

            let urlStr = event.url.split('id_token=');
            let idToken = urlStr[1];

            // if (params.get('id_token') === null) {
            //   let urlStr = event.url;
            //   idToken = urlStr.get('id_token');
            //   alert(idToken);
            // } else {
            //   idToken = params.get('id_token');
            //   alert(idToken);
            // }
            // alert(urlStr);
            AppStorageService.setValue(
              AppStorageService.values.authToken,
              idToken
            ).then(() => {
              onCompleteEvent();
            });
          }
        }
      });
    }
  },
  getTokenContents: (token) => {
    return decodeJwt(token);
  },
  showSignInFailure: () => {
    alert(strings[AppPlatformService.getLanguage()].signInFailureMessage);
  },
};

export default AppAuthService;
