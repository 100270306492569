import React, { useState, useEffect, useContext } from 'react';

//services
import AppDiscountSchedulesService from '../../AppDiscountSchedulesService';

//components
import CouponCard from './CouponCard';

//global components
import { LoadingSpinner } from '../../../../shared';

//hooks
import { useWindowResize } from '../../../../shared/hooks/useWindowSize';

//context
import { ErrorContext } from '../../../../context/shared/ErrorContext';

//styles
import {
  TableStyles,
  TableHeaderStyles,
  TableItemStyles,
} from '../../../../SharedStyles';

const CouponComponent = (props) => {
  const { strings } = props;
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(true);
  const [allCoupons, setAllCoupons] = useState([]);
  const size = useWindowResize(window.innerWidth, window.innerHeight);

  const fetchCoupons = async (id) => {
    try {
      let res = await AppDiscountSchedulesService.getCouponTokens(id);
      setAllCoupons(res);
      setLoading(false);
    } catch (err) {
      setError({
        display: true,
        title: strings.invalidTitle,
        message: err.response.data.split(/[|]+/).pop(),
        styles: 'cso',
      });
    }
  };

  useEffect(() => {
    if (props.profile.id) {
      fetchCoupons(props.profile.id);
    }
    // eslint-disable-next-line
  }, [props.couponLength, props.profile.id]);

  const removeCoupon = async (couponToken) => {
    try {
      setLoading(true);
      await AppDiscountSchedulesService.deleteCouponToken(couponToken);
      let newCoupons = allCoupons.filter(
        (coupon) => coupon.token !== couponToken
      );
      setAllCoupons(newCoupons);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError({
        display: true,
        title: 'Something went wrong',
        message: err.response.data.split(/[|]+/).pop(),
        styles: 'cso',
      });
    }
  };

  return (
    <TableStyles minHeight='300px'>
      {loading && <LoadingSpinner />}
      <TableHeaderStyles
        borderLeft='none'
        borderRight='none'
        padding='5px 0'
        width={size[0] >= 768 ? '100%' : '100%'}>
        <TableItemStyles flex='1' borderRight>
          {strings.couponCode}
        </TableItemStyles>
        <TableItemStyles flex='1' borderRight>
          {strings.couponType}
        </TableItemStyles>
        <TableItemStyles flex='1' borderRight>
          {strings.couponUses}
        </TableItemStyles>
        <TableItemStyles flex='1' borderRight>
          {strings.couponExpires}
        </TableItemStyles>
      </TableHeaderStyles>
      {allCoupons.map((coup) => {
        return (
          <CouponCard
            removeCoupon={removeCoupon}
            profile={props.profile.id}
            strings={strings}
            coupon={coup}
            key={coup.id}
          />
        );
      })}
    </TableStyles>
  );
};

export default CouponComponent;
