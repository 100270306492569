import React from 'react';

//services
import AppLocationsService from '../../AppLocationsService';

//global components
import PropertyContainer from '../../../../shared/PropertyContainer/PropertyContainer';
import { RoleCanEdit } from '../../../OperatorShared';

//styles
import {
  PropertiesContentContainerStyles,
  PropertiesCardStyles,
  PropertiesHeaderContainerStyles
} from '../../../../SharedStyles';

const LocationDetails = props => {
  const { strings } = AppLocationsService;
  const { location, toggleTransformerModal, toggleLoadLimitModal } = props;

  return (
    <PropertiesCardStyles>
      <PropertiesHeaderContainerStyles>
        <div className='Title'>{strings.locationInfo}</div>
      </PropertiesHeaderContainerStyles>
      <PropertiesContentContainerStyles>
        <PropertyContainer
          title={strings.meterGroups}
          content={
            location.meterGroupCount >= 0 ? location.meterGroupCount : '--'
          }
        />
        <PropertyContainer
          title={strings.circuits}
          content={location.circuitCount >= 0 ? location.circuitCount : '--'}
        />
        <PropertyContainer
          title={strings.chargers}
          content={location.chargerCount >= 0 ? location.chargerCount : '--'}
        />
        <PropertyContainer
          title={strings.ports}
          content={location.portStat?.portCount >= 0 ? location.portStat?.portCount : '--'}
        />
        <PropertyContainer
          title={strings.transformer}
          content={
            location.transformerPower >= 0
              ? `${location.transformerPower}kVa`
              : '--'
          }
          handleClick={
            <RoleCanEdit roles={[1, 3]}>
              <div className='Content-data-row'>
                <div
                  onClick={toggleTransformerModal}
                  className='Lightgrey-button'>
                  {strings.change}
                </div>
              </div>
            </RoleCanEdit>
          }
        />
        <PropertyContainer
          title={strings.loadLimit}
          content={location.loadLimit >= 0 ? `${location.loadLimit}A` : '--'}
          handleClick={
            <RoleCanEdit roles={[1, 3]}>
              <div className='Content-data-row'>
                <div
                  onClick={toggleLoadLimitModal}
                  className='Lightgrey-button'>
                  {strings.change}
                </div>
              </div>
            </RoleCanEdit>
          }
        />
        <PropertyContainer
          title={strings.currentLoad}
          content={
            location.portStat?.currentLoad >= 0
              ? `${location.portStat.currentLoad.toFixed(0)}A`
              : '--'
          }
        />
      </PropertiesContentContainerStyles>
    </PropertiesCardStyles>
  );
};

export default LocationDetails;
