import React, { useState, useEffect, useContext } from 'react';
import { FaExclamationTriangle, FaRegWindowClose } from 'react-icons/fa';
import moment from 'moment';
import TimePicker from 'rc-time-picker';

//services
import AppChargerCardService from '../../../ChargerCard/AppChargerCardService';
import AppFindChargerService from '../../AppFindChargerService';
import AppChargingService from '../../../AppCharging/AppChargingService';

//components
import ModalButton from '../../../../shared/Buttons/ModalButton';

//context
import { DriverContext } from '../../../../context/driver/getDriverContext';
import { ErrorContext } from '../../../../context/shared/ErrorContext';

//styles
import {
  SelectStyles,
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  DivStyles,
  TextStyles,
} from '../../../../SharedStyles';

//helpers
import { timeConvert } from '../../../../shared/Helpers';
import { ErrorResponse } from '../../../../shared/ErrorResponse';

const ReservationModal = (props) => {
  const { strings } = AppFindChargerService;
  const { charger } = props;
  const { driver } = useContext(DriverContext);
  const { setError } = useContext(ErrorContext);

  const [disableButton, setDisableButton] = useState({
    display: false,
    reason:
      'Your reserve time is in the past. Please change to future time for today.',
  });
  const [connectorStatus, setConnectorStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeReservationOnStation, setActiveReservationOnStation] = useState(
    false
  );
  const [reserveData, setReserveData] = useState({
    iotHubDeviceId: charger.iotHubDeviceId,
    port: 1,
    day: 0,
    time: moment(),
  });
  const format = 'h:mm a';

  useEffect(() => {
    const fetchStatus = async () => {
      const res = await AppChargingService.getChargingStationStatus(
        charger.iotHubDeviceId
      );
      const checkingReservation = await AppChargerCardService.reservationGet(
        charger.iotHubDeviceId
      );

      if (checkingReservation) {
        let userReservation = checkingReservation.findIndex((r) => {
          return r.driverId === driver.userId;
        });
        if (userReservation > -1) {
          setActiveReservationOnStation(true);
        }
      }
      setConnectorStatus(res);
      setLoading(false);
    };
    fetchStatus();
  }, [charger, driver.userId]);

  const closeModal = () => {
    props.handleModalState('reservationModal');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let dateToUse = reserveData.time.add(reserveData.day, 'd');

      dateToUse
        .set({ second: 0, millisecond: 0 })
        .format('YYYY-MM-DDTHH:mm:ss.SSS');

      let postData = {
        iotHubDeviceId: reserveData.iotHubDeviceId,
        port: reserveData.port,
        utcStartDate: moment(dateToUse).utc().format(),
      };

      let res = await AppChargerCardService.reservationAdd(postData);

      if (res !== '') {
        throw new ErrorResponse('frontend', res, 'Something went wrong');
      }
      //value comes back null if success
      if (!res) {
        localStorage.setItem(
          'hasReservation',
          JSON.stringify({
            hasReservation: true,
            iotHubDeviceId: charger.iotHubDeviceId,
            chargerName: charger.chargerName,
          })
        );
        closeModal();
      }
    } catch (err) {
      if (err.name === 'frontend') {
        setError({
          display: true,
          message: err.message,
          title: err.title,
          styles: 'driver',
        });
      }
    }
  };

  useEffect(() => {
    let timeInPast = moment(reserveData.time).diff(moment(), 'minutes');

    //we are on today
    if (reserveData.day === 0) {
      //we are in the past
      if (timeInPast < 0) {
        setDisableButton((prev) => ({ ...prev, display: true }));
      } else {
        setDisableButton((prev) => ({ ...prev, display: false }));
      }
    } else {
      // we are in the future day
      setDisableButton((prev) => ({ ...prev, display: false }));
    }
  }, [reserveData]);

  const onChange = (value) => {
    setReserveData({ ...reserveData, time: value });
  };

  const handlePortSelection = (e) => {
    setReserveData({ ...reserveData, port: e.target.value });
  };

  const handleDaySelection = (e) => {
    setReserveData({ ...reserveData, day: e.target.value });
  };

  const handleCancelReservation = async () => {
    let res = await AppChargerCardService.reservationRemove(
      reserveData.iotHubDeviceId
    );
    if (!res) {
      localStorage.removeItem('hasReservation');
      closeModal();
    }
  };

  if (loading) {
    return (
      <ModalStyles>
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{strings.reserveTitle}</h2>
            <FaRegWindowClose className='Close' onClick={closeModal} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            <ModalItemStyles padding='0 10px'>
              <p>Checking reservation...</p>
            </ModalItemStyles>
          </ModalContentStyles>
          {/* Button Component for Modals*/}
          <DivStyles
            display='flex'
            alignItems='center'
            justifyContent='flex-end'>
            <ModalButton
              containerMargin='10px 10px 0'
              containerAlign='right'
              buttonMargin='0'
              disabled={loading}
              handleClick={handleSubmit}
              buttonText={strings.reserveButton}
            />
          </DivStyles>
        </ModalContainerStyles>
      </ModalStyles>
    );
  }

  if (activeReservationOnStation) {
    return (
      <ModalStyles>
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{strings.reserveTitle}</h2>
            <FaRegWindowClose className='Close' onClick={closeModal} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            <ModalItemStyles padding='0 10px'>
              <p>You currently have a reservation for this charging station.</p>
            </ModalItemStyles>
          </ModalContentStyles>
          {/* Button Component for Modals*/}
          <DivStyles
            display='flex'
            alignItems='center'
            justifyContent='flex-end'>
            <ModalButton
              containerMargin='10px 10px 0'
              containerAlign='right'
              buttonMargin='0'
              handleClick={handleCancelReservation}
              buttonText={strings.leaveReserveButton}
            />
          </DivStyles>
        </ModalContainerStyles>
      </ModalStyles>
    );
  }

  return (
    <>
      <ModalStyles>
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{strings.reserveTitle}</h2>
            <FaRegWindowClose className='Close' onClick={closeModal} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            <ModalItemStyles padding='0 10px'>
              <p>
                {strings.reserveContentOne} {charger.holdTime}{' '}
                {strings.reserveContentTwo}
              </p>
            </ModalItemStyles>
            <ModalItemStyles borderBottom padding='0 10px'>
              <TextStyles align='center'>
                <b>{strings.reserveFee}</b> ${charger.reservationFee}
              </TextStyles>
            </ModalItemStyles>
            {/* PORT SELECTION */}
            <ModalItemStyles
              borderBottom
              padding='10px 10px 20px'
              textMargin='0'>
              <TextStyles align='center' margin='0' padding='0'>
                <b>{strings.reservePort}</b>
              </TextStyles>
              <SelectStyles
                name='port'
                onChange={handlePortSelection}
                margin='0'>
                {connectorStatus &&
                  connectorStatus.connectorStatuses.map((c) => (
                    <option key={c.connectorId} value={c.connectorId}>
                      Port {c.connectorId}
                    </option>
                  ))}
              </SelectStyles>
            </ModalItemStyles>
            {/* DAY SELECTION */}
            <ModalItemStyles
              borderBottom
              padding='10px 10px 20px'
              textMargin='0'>
              <TextStyles align='center' margin='0' padding='0'>
                <b>{strings.reserveDay}</b>
              </TextStyles>
              <SelectStyles
                name='port'
                onChange={handleDaySelection}
                margin='0'>
                {strings.days.map((d, i) => (
                  <option key={d} value={i}>
                    {d}
                  </option>
                ))}
              </SelectStyles>
            </ModalItemStyles>
            {/* TIME SELECTION */}
            <ModalItemStyles padding='10px 10px 20px' textMargin='0'>
              <TextStyles align='center' margin='0' padding='0'>
                <b>{strings.reserveTime}</b>
              </TextStyles>
              {disableButton.display && (
                <em>
                  <TextStyles size='12px' color='red'>
                    {disableButton.reason}
                  </TextStyles>
                </em>
              )}

              <TimePicker
                showSecond={false}
                defaultValue={moment(reserveData.time)}
                onChange={onChange}
                format={format}
                use12Hours
                inputReadOnly
                allowEmpty={false}
                // disabledHours={disabledHours}
                // disabledMinutes={disabledMinutes}
              />
              {/* <TimePicker
                className='Time-Picker'
                showSecond={false}
                value={moment(reserveData.time)}
                format={format}
                use12Hours
                inputReadOnly
                id='start'
                allowEmpty={false}
              /> */}
            </ModalItemStyles>

            <ModalItemStyles fontSize='12px'>
              <TextStyles size='12px'>
                <FaExclamationTriangle className='Warning' />{' '}
                <b>{strings.reserveNote}:</b> {strings.reserveCancelOne}{' '}
                {timeConvert(charger.guaranteedSessionTime)}{' '}
                {strings.reserveCancelTwo}
              </TextStyles>
            </ModalItemStyles>
          </ModalContentStyles>
          {/* Button Component for Modals*/}
          <DivStyles
            display='flex'
            alignItems='center'
            justifyContent='flex-end'>
            <ModalButton
              disabled={disableButton.display}
              containerMargin='10px 10px 0'
              containerAlign='right'
              buttonMargin='0'
              handleClick={handleSubmit}
              buttonText={strings.reserveButton}
            />
          </DivStyles>
        </ModalContainerStyles>
      </ModalStyles>
    </>
  );
};

export default ReservationModal;
