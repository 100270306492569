import React from 'react';

import {
  DivStyles,
  TextStyles,
  LabelStyles,
  InputStyles
} from '../../SharedStyles';

const DayComponent = props => {
  const { scheduleDays, handleChange } = props;

  return (
    <>
      {/* Sunday */}
      <LabelStyles margin='10px 5px'>
        <InputStyles
          noBorder
          hidden
          checked={scheduleDays.sunday}
          name='sunday'
          onChange={handleChange}
          type='checkbox'
        />
        <DivStyles
          display='flex'
          align='center'
          justifyContent='center'
          alignItems='center'
          height='30px'
          width='30px'
          borderRadius='50%'
          borderBottom='none'
          cursor='pointer'
          dayBackground={scheduleDays.sunday ? '#28456a' : '#c2c2c2'}>
          <TextStyles textTransform='uppercase' color='#fff' margin='0'>
            S
          </TextStyles>
        </DivStyles>
      </LabelStyles>
      {/* Monday */}
      <LabelStyles margin='10px 5px'>
        <InputStyles
          noBorder
          hidden
          checked={scheduleDays.monday}
          name='monday'
          onChange={handleChange}
          type='checkbox'
        />
        <DivStyles
          display='flex'
          align='center'
          justifyContent='center'
          alignItems='center'
          height='30px'
          width='30px'
          borderRadius='50%'
          borderBottom='none'
          cursor='pointer'
          dayBackground={scheduleDays.monday ? '#28456a' : '#c2c2c2'}>
          <TextStyles textTransform='uppercase' color='#fff' margin='0'>
            M
          </TextStyles>
        </DivStyles>
      </LabelStyles>
      {/* Tuesday */}
      <LabelStyles margin='10px 5px'>
        <InputStyles
          noBorder
          hidden
          checked={scheduleDays.tuesday}
          name='tuesday'
          onChange={handleChange}
          type='checkbox'
        />
        <DivStyles
          display='flex'
          align='center'
          justifyContent='center'
          alignItems='center'
          height='30px'
          width='30px'
          borderRadius='50%'
          borderBottom='none'
          cursor='pointer'
          dayBackground={scheduleDays.tuesday ? '#28456a' : '#c2c2c2'}>
          <TextStyles textTransform='uppercase' color='#fff' margin='0'>
            T
          </TextStyles>
        </DivStyles>
      </LabelStyles>
      {/* Wednesday */}
      <LabelStyles margin='10px 5px'>
        <InputStyles
          noBorder
          hidden
          checked={scheduleDays.wednesday}
          name='wednesday'
          onChange={handleChange}
          type='checkbox'
        />
        <DivStyles
          display='flex'
          align='center'
          justifyContent='center'
          alignItems='center'
          height='30px'
          width='30px'
          borderRadius='50%'
          borderBottom='none'
          cursor='pointer'
          dayBackground={scheduleDays.wednesday ? '#28456a' : '#c2c2c2'}>
          <TextStyles textTransform='uppercase' color='#fff' margin='0'>
            W
          </TextStyles>
        </DivStyles>
      </LabelStyles>
      {/* Thursday */}
      <LabelStyles margin='10px 5px'>
        <InputStyles
          noBorder
          hidden
          checked={scheduleDays.thursday}
          name='thursday'
          onChange={handleChange}
          type='checkbox'
        />
        <DivStyles
          display='flex'
          align='center'
          justifyContent='center'
          alignItems='center'
          height='30px'
          width='30px'
          borderRadius='50%'
          borderBottom='none'
          cursor='pointer'
          dayBackground={scheduleDays.thursday ? '#28456a' : '#c2c2c2'}>
          <TextStyles textTransform='uppercase' color='#fff' margin='0'>
            T
          </TextStyles>
        </DivStyles>
      </LabelStyles>
      {/* Friday */}
      <LabelStyles margin='10px 5px'>
        <InputStyles
          noBorder
          hidden
          checked={scheduleDays.friday}
          name='friday'
          onChange={handleChange}
          type='checkbox'
        />
        <DivStyles
          display='flex'
          align='center'
          justifyContent='center'
          alignItems='center'
          height='30px'
          width='30px'
          borderRadius='50%'
          borderBottom='none'
          cursor='pointer'
          dayBackground={scheduleDays.friday ? '#28456a' : '#c2c2c2'}>
          <TextStyles textTransform='uppercase' color='#fff' margin='0'>
            F
          </TextStyles>
        </DivStyles>
      </LabelStyles>
      {/* Saturday */}
      <LabelStyles margin='10px 5px'>
        <InputStyles
          noBorder
          hidden
          checked={scheduleDays.saturday}
          name='saturday'
          onChange={handleChange}
          type='checkbox'
        />
        <DivStyles
          display='flex'
          align='center'
          justifyContent='center'
          alignItems='center'
          height='30px'
          width='30px'
          borderRadius='50%'
          borderBottom='none'
          cursor='pointer'
          dayBackground={scheduleDays.saturday ? '#28456a' : '#c2c2c2'}>
          <TextStyles textTransform='uppercase' color='#fff' margin='0'>
            S
          </TextStyles>
        </DivStyles>
      </LabelStyles>
    </>
  );
  // return (
  //   <>
  //     <LabelStyles
  //       order={props.i}
  //       margin='10px 5px'
  //       htmlFor={`${props.schedule.id}-${props.label}`}>
  //       <InputStyles
  //         noBorder
  //         hidden
  //         id={`${props.schedule.id}-${props.label}`}
  //         checked={props.checked}
  //         name={props.label}
  //         onChange={props.handleChange}
  //         type='checkbox'
  //       />
  //       <DivStyles
  //         display='flex'
  //         align='center'
  //         justifyContent='center'
  //         alignItems='center'
  //         height='30px'
  //         width='30px'
  //         borderRadius='50%'
  //         borderBottom='none'
  //         cursor='pointer'
  //         dayBackground={props.checked ? '#28456a' : '#c2c2c2'}>
  //         <TextStyles textTransform='uppercase' color='#fff' margin='0'>
  //           {props.label.slice(0, 1)}
  //         </TextStyles>
  //       </DivStyles>
  //     </LabelStyles>
  //   </>
  // );
};

export default DayComponent;
