import React, { useRef, useEffect } from 'react';

import NavSection from '../Nav/NavSection';
import Footer from '../../shared/Footer/Footer';

//hooks
import { useRouter } from '../../shared/hooks/useRouter';
import { useContainerHeight } from '../../shared/hooks/useContainerHeight';

//styles
import {
  InteriorStyles,
  InteriorDriverComponentStyles,
} from '../../DriverStyles';

import '../Driver.scss';

const InteriorWrapper = (props) => {
  const divRef = useRef();
  const { containerHeight, containerRef, setContainerRef } = useContainerHeight(
    40,
    divRef
  );

  const router = useRouter();

  useEffect(() => {
    setContainerRef(divRef);
  }, [divRef, setContainerRef]);

  return (
    <InteriorStyles>
      <div className='Body-Container'>
        <NavSection
          router={router}
          ref={containerRef}
          title={props.title}
          isInternal={props.isInternal}
          save={props.save}
          actionText={props.actionText}
          join={props.join}
          onSaveClick={props.onSaveClick}
          myForm={props.myForm}
          goBack={props.goBack}
          path={props.path}
        />
      </div>
      <InteriorDriverComponentStyles
        isInternal={props.isInternal}
        mobilePadding={
          props.isInternal
            ? props.reducePadding
              ? `${containerHeight}px`
              : `${containerHeight + 10}px`
            : '0 0 10px'
        }>
        {props.children}
      </InteriorDriverComponentStyles>
      <Footer />
    </InteriorStyles>
  );
};

export default InteriorWrapper;
