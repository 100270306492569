import React from 'react';

import moment from 'moment';

//styles
import {
  TableRowStyles,
  TableItemStyles,
} from '../../../../../../SharedStyles';

const StatusDetailsItem = (props) => {
  const { status } = props;
  return (
    <TableRowStyles
      hoverBackground='#f1f7ff'
      borderTop='none'
      borderLeft='none'
      borderRight='none'
      align='center'
      width='100%'
      minWidth='1160px'>
      
      <TableItemStyles width='100%' minWidth='90px' maxWidth='90px'>
        {status.iotHubDeviceId}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='110px' maxWidth='110px'>
        {status.serialNo}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='110px' maxWidth='110px'>
        {status.vendor}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='110px' maxWidth='110px'>
        {status.model}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='90px' maxWidth='90px'>
        {moment(status.incidentDate).format('MM/DD/YY h:mm:ss a')}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='80px' maxWidth='80px'>
        {status.source}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='110px' maxWidth='110px'>
        {status.errorType}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='110px' maxWidth='110px'>
        {status.incidentCode}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='110px' maxWidth='110px'>
        {status.incidentDescription}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='110px' maxWidth='110px'>
        {status.severity}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='110px' maxWidth='110px'>
        {status.systemStatus}
      </TableItemStyles>
    </TableRowStyles>
  );
};

export default StatusDetailsItem;
