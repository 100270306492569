import React from 'react';

//styles
import { PropertyContentStyles } from '../../SharedStyles';

const PropertyContainer = React.forwardRef((props, ref) => {
  const { title, content, handleClick } = props;

  return (
    <PropertyContentStyles
      flexGrow={props.flexGrow}
      flex={props.flex}
      ref={ref}
      contentColor={props.color && 'red'}>
      <div className='Title'>{title}</div>
      <div className='Content'>
        {title && content ? content : content === '' ? '' : '--'}
      </div>
      {handleClick}
    </PropertyContentStyles>
  );
});

export default PropertyContainer;
