import React from 'react';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';

//global components
import RoleCanEdit from '../../../OperatorShared/RoleCanEdit';

//styles
import {
  HeaderContainerStyles,
  HeaderLeftStyles,
  HeaderRightStyles,
} from '../../../../OperatorStyles';

const MeterGroupHeader = (props) => {
  const { meterGroup, toggleDeleteModal, toggleMeterGroupModal } = props;
  return (
    <HeaderContainerStyles justifyContent='space-between' padding='10px'>
      <HeaderLeftStyles>
        <h3 className='Title'>
          {meterGroup.name || 'Meter Group Name'}
          <RoleCanEdit roles={[1]}>
            <FaRegEdit onClick={toggleMeterGroupModal} />
          </RoleCanEdit>
        </h3>
        <p className='SubTitle'>
          {meterGroup.locationName || 'Meter Group Location'}
        </p>
      </HeaderLeftStyles>
      <HeaderRightStyles>
        {toggleDeleteModal && (
          <RoleCanEdit roles={[1]}>
            <FaRegTrashAlt onClick={toggleDeleteModal} />
          </RoleCanEdit>
        )}
      </HeaderRightStyles>
    </HeaderContainerStyles>
  );
};

export default MeterGroupHeader;
