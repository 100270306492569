import React from 'react';
import { Link } from 'react-router-dom';

//services
import AppAccessDeniedService from './AppAccessDeniedService';

//styles
import {
  JumboTronHeaderStyles,
  TitleStyles,
  DivStyles,
  TextStyles,
} from '../../SharedStyles';

const AccessDeniedComponent = (props) => {
  const { strings } = AppAccessDeniedService;

  if (props.errorMessage) {
    return (
      <JumboTronHeaderStyles contentAlign='left' borderBottom='none'>
        <TitleStyles size='28px' align='left'>
          {strings.deniedTitle}
        </TitleStyles>
        <div className='Content'>
          <p>{props.errorMessage}</p>
        </div>
      </JumboTronHeaderStyles>
    );
  }
  return (
    <JumboTronHeaderStyles
      padding='5%'
      borderBottom='none'
      margin='10% auto 0'
      align='left'>
      <TitleStyles size='28px' align='center' margin='0 0 20px'>
        {strings.deniedTitle}
      </TitleStyles>
      <DivStyles padding='2% 10%'>
        {props.smallBiz && (
          <>
            <TextStyles>{strings.deniedContent}</TextStyles>
            <TextStyles align='center' margin='10px 0'>
              <Link to='/subscription'>
                {strings.upgradeSmall} or {strings.upgradeEnterprise}
              </Link>
            </TextStyles>
          </>
        )}
        {props.enterprise && (
          <>
            <TextStyles>{strings.EnterpriseDeniedContent}</TextStyles>
            <TextStyles align='center' margin='10px 0'>
              <Link to='/subscription'>{strings.upgradeEnterprise}</Link>
            </TextStyles>
          </>
        )}
      </DivStyles>
    </JumboTronHeaderStyles>
  );
};

export default AccessDeniedComponent;
