import React, { useState, useEffect } from 'react';

//services
import AppChargersService from '../../AppChargersService';

//modals
import DiagnosticsModal from './Modals/DiagnosticsModal';
import Modal from '../../../../shared/Modals/Modal';

//styles
import {
  ButtonStyles,
  DivStyles,
  InputStyles,
  SelectStyles,
  TextStyles,
} from '../../../../SharedStyles';

const ChargerSelections = ({
  charger,
  handleSelections,
  diagnostics,
  isCharging,
  isFinished,
  selectedPort,
  selectedRange,
}) => {
  const [diagnosticsModal, setDiagnosticsModal] = useState(false);
  const [resetModal, setResetModal] = useState(false);

  useEffect(() => {
    if (charger.evses[0].connectors.length === 1) {
      handleSelections('port', 1);
    }
  }, [charger, handleSelections]);

  const handleChange = (e) => {
    handleSelections(e.target.name, e.target.value);
  };

  const toggleDiagnosticsModal = () => {
    setDiagnosticsModal(!diagnosticsModal);
  };

  const toggleResetModal = () => {
    setResetModal(!resetModal);
  };

  const handleReset = async (e) => {
    e.preventDefault();
    try {
      await AppChargersService.resetCharger(
        { iotHubDeviceId: charger.iotHubDeviceId, type: 'Hard' }
      );
      //setSuccess(true);
    } catch (err) {
      //setFailed(true);
    }

    toggleResetModal();
  };

  return (
    <DivStyles
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      padding='0 10px'
      borderBottom='1px solid #f3f3f3'>
      {diagnosticsModal && (
        <DiagnosticsModal
          diagnostics={diagnostics}
          closeModal={toggleDiagnosticsModal}
        />
      )}
      {resetModal && (
        <Modal
          title='Reset Charger'
          message={`Are you sure you wish to reset ${charger.iotHubDeviceId}?`}
          cancelButton='No'
          confirmButton='Yes'
          onCancel={toggleResetModal}
          onConfirm={handleReset}
        />
      )}
      <DivStyles
        align='center'
        size='14px'
        padding='10px 0'
        display='flex'
        alignItems='center'
        justifyContent='center'>
        {charger.evses[0].connectors.length > 1 && (
          <DivStyles margin='0 10px' display='flex' alignItems='center'>
            <TextStyles margin='5px 0 0'>Port: </TextStyles>
            <SelectStyles
              width='100px'
              onChange={handleChange}
              margin='0 0 0 10px'
              name='port'
              value={selectedPort}>
              <option value='' disabled>
                select port
              </option>
              {charger.evses[0].connectors.map((c) => (
                <option key={c.id} value={c.id}>
                  Port {c.id}
                </option>
              ))}
            </SelectStyles>
          </DivStyles>
        )}
        <DivStyles margin='0 10px' display='flex' alignItems='center'>
          <TextStyles margin='5px 0 0'>Desired Miles: </TextStyles>
          <InputStyles
            width='auto'
            margin='0 0 0 10px'
            type='text'
            name='kWh'
            value={selectedRange > 0 ? selectedRange : ''}
            onChange={handleChange}
            placeholder='desired miles'
            disabled={isCharging || isFinished}
          />
        </DivStyles>
      </DivStyles>
      <DivStyles>
        <ButtonStyles onClick={toggleResetModal}>
          Reset
        </ButtonStyles>
        {diagnostics && (
          <ButtonStyles onClick={toggleDiagnosticsModal}>
            Diagnostics
          </ButtonStyles>
        )}
      </DivStyles>
    </DivStyles>
  );
};

export default ChargerSelections;
