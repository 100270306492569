import { useState, useEffect } from 'react';

const AppChargingProgress = (props) => {
  const stopValue = parseFloat(props.stopBattery);
  const value = parseFloat(props.value);
  const { handleStartStop } = props;
  const [valuesIndex, setValuesIndex] = useState(value);
  const [chargeComplete, setChargeComplete] = useState(false);
  const { isCharging } = props;

  useEffect(() => {
    setChargeComplete(false);
  }, [stopValue]);

  useEffect(() => {
    setValuesIndex(value);
    if (valuesIndex !== stopValue) {
      setChargeComplete(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (isCharging) {
      setValuesIndex(value + props.addedRange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.addedRange]);

  useEffect(() => {
    const progress = () => {
      if (!chargeComplete && isCharging) {
        if (valuesIndex === stopValue && props.currentChargingCar) {
          setChargeComplete(true);
          handleStartStop();
        } else {
          if (isCharging) {
            setValuesIndex(valuesIndex + props.addedRange);
          }
        }
      }
    };
    progress();
    return () => {
      setChargeComplete(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesIndex, chargeComplete, isCharging, props.addedRange]);

  return props.children({ valuesIndex, stopValue });
};

export default AppChargingProgress;
