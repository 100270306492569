import React, { useEffect, useContext } from 'react';
import { FaPlus } from 'react-icons/fa';

//components
import BrandingForm from './BrandingForm';
import ToggleSwitch from '../../../OperatorShared/ToggleSwitch/CsoToggleSwitch';

//hooks
import { useCloudinary } from '../../../../shared/Cloudinary/hook/useCloudinary';

//styles
import {
  DivStyles,
  TitleStyles,
  TextStyles,
  ImageThumbnailStyles,
} from '../../../../SharedStyles';

//context
import { CsoAccountContext } from '../../../../context/operator/getCsoAccount';

const LocationBranding = ({ location, strings, canEdit, handleDataChange }) => {
  const { currentUser } = useContext(CsoAccountContext);
  const { cloudData, uploadImage } = useCloudinary({
    preset: 'location_images',
    tags: [location.name, location.id],
  });

  const updateImage = async () => {
    handleDataChange('locationBrandingImagePath', cloudData.secure_url);
  };

  const openDialog = () => {
    document.getElementById('inputUpload').click();
  };

  const handleEnable = async (e, name) => {
    handleDataChange(name, e.target.checked);
  };

  useEffect(() => {
    if (cloudData) {
      updateImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloudData]);

  return (
    <DivStyles borderTop padding='10px'>
      <DivStyles
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        margin='0 0 10px'>
        <TitleStyles size='14px' weight='600' margin='0'>
          {strings.brandingTitle}
        </TitleStyles>
        <ToggleSwitch
          deny={currentUser && ![1].includes(currentUser.role)}
          name='enableLocationBranding'
          checked={!!location.enableLocationBranding}
          handleChange={(e) => handleEnable(e, e.target.name)}
        />
      </DivStyles>

      <TextStyles size='14px'>
        {strings.brandingContentOne} <em>{strings.brandingContentTwo}</em>{' '}
        {strings.and} <em>{strings.brandingContentThree}</em>{' '}
        {strings.brandingContentFour}
      </TextStyles>

      <DivStyles>
        {/* Image Upload  */}
        <DivStyles
          title={strings.imageToolTip}
          display='flex'
          alignItems='flex-end'
          margin='0 0 15px'>
          <ImageThumbnailStyles
            maxWidth='100px'
            onClick={openDialog}
            src={
              cloudData
                ? cloudData.secure_url
                : location.locationBrandingImagePath
                ? location.locationBrandingImagePath
                : require('../../../../../assets/images/UsersPlaceholder.png').default
            }
            alt={location.locationName}
          />
          <input
            className='file-input'
            id='inputUpload'
            type='file'
            hidden
            accept='image/*'
            onChange={uploadImage}
          />
          <FaPlus onClick={openDialog} />
        </DivStyles>

        <BrandingForm
          strings={strings}
          canEdit={canEdit}
          location={location}
          handleDataChange={handleDataChange}
        />
      </DivStyles>
    </DivStyles>
  );
};

export default LocationBranding;
