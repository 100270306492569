import styled from 'styled-components';

import { defaultMediaQuery } from '../SharedStyles/defaultValues';

export const NavBarContainerStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin: 0 auto;
  padding: 10px;
  max-height: 70px;
  @media screen and (max-width: ${({ mobileMedia, isInternal }) => {
      if (isInternal) {
        return mobileMedia ? mobileMedia : defaultMediaQuery;
      } else {
        return '0px';
      }
    }}) {
    display: none;
  }
  .Left-Align {
    display: flex;
    align-items: center;
    flex: 1;
  }
`;

export const LogoContainerStyles = styled.div`
  .FullScreen {
    display: block;
    @media screen and (max-width: ${({ mobileMedia }) =>
        mobileMedia ? mobileMedia : defaultMediaQuery}) {
      display: none;
    }
  }
  .Mobile {
    display: none;
    @media screen and (max-width: ${({ mobileMedia }) =>
        mobileMedia ? mobileMedia : defaultMediaQuery}) {
      display: block;
    }
  }
`;

export const CenterNavStyles = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  @media screen and (max-width: 500px) {
    padding: 0 2px;
  }
  a {
    display: flex;
    align-items: center;
    margin: 0 20px;
    padding: 0 0 4px;
    color: #2c456c;
    text-decoration: none;
    display: inherit;
    font-size: 18px;
    border-bottom: 2px solid transparent;
    font-weight: 500;
    @media screen and (max-width: 500px) {
      font-size: 15px;
      padding: 0;
      margin: 0 5px;
    }
    &:hover {
      border-bottom: 2px solid #2c456c;
    }
  }
  svg {
    margin: 0 10px 0 0;
    @media screen and (max-width: 500px) {
      margin: 0 2px 0 0;
    }
  }
`;

export const UserProfileNavStyles = styled.div`
  position: relative;
  color: #a5a5a5;
  cursor: pointer;
  display: flex;
  align-items: center;
  .Image-Container {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 10px 0 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media screen and (max-width: 400px) {
      display: none;
    }
  }
  .Account-Container {
    margin: 5px 0 0;
    font-weight: 600;
    font-size: 15px;
    @media screen and (max-width: ${({ mobileMedia }) =>
        mobileMedia ? mobileMedia : defaultMediaQuery}) {
      display: none;
    }
  }
  .Mobile-Menu-Display {
    display: none;
    display: none;
    color: #2c456c;
    height: 25px;
    width: 25px;
    margin-left: 2vw;
    @media screen and (max-width: ${({ mobileMedia }) =>
        mobileMedia ? mobileMedia : defaultMediaQuery}) {
      display: block;
    }
  }
`;

export const DropDownMenuStyles = styled.div`
  animation: fadein 300ms;
  position: absolute;
  top: 53px;
  right: -2px;
  display: block;
  width: 225px;
  border: 1px solid #c2c2c2;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 #a5a5a5;
  z-index: 20;
  .Menu-Link {
    display: block;
    padding: 13px 10px;
    border-bottom: 1px solid #c2c2c2;
    color: #2c456c;
    font-size: 15px;
    text-decoration: none;
    cursor: pointer;
    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    &:hover {
      color: #2c456c;
      background-color: #f0f0f0;
      text-decoration: none;
    }
    .Menu-Icon {
      font-size: 18px;
      margin: 0 10px 0 0;
    }
  }
`;

export const MobileNavStyles = styled.div`
  display: none;
  @media screen and (max-width: ${({ mobileMedia }) =>
      mobileMedia ? mobileMedia : defaultMediaQuery}) {
    background-color: #f60;
    padding: 10px 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    background-color: #f60;
    padding: 10px 0;
    position: fixed;
    z-index: 99999;
    .Mobile-Nav-Container {
      display: inline-flex;
      align-items: center;
      color: #ffffff;
      font-weight: 600;
      .Arrow {
        margin: 0 10px;
        color: #ffffff;
        cursor: pointer;
      }
      .Title {
        color: #ffffff;
        font-weight: 600;
      }
    }
    .Save {
      background: none;
      outline: none;
      border: none;
      color: #ffffff;
      font-weight: 600;
      margin: 0 5px 0 0;
    }
  }
`;
