import React, { useEffect } from 'react';
import { useState } from 'react';

//styles
import { DivStyles, SelectRoundStyles } from '../../SharedStyles';

//helpers
import { handleBuildStructure } from './helpers';

const ReportFilter = (props) => {
  const { filterBy, filterProps, name, updateReportFilters } = props;
  const [filterStructure, setFilterStructure] = useState({});

  useEffect(() => {
    const structure = handleBuildStructure(filterBy, filterProps);

    setFilterStructure(structure);
  }, [filterBy, filterProps]);

  useEffect(() => {
    if (Object.keys(filterStructure).length > 0 && updateReportFilters) {
      updateReportFilters(name, filterStructure);
    }
    // eslint-disable-next-line
  }, [filterStructure, name]);

  const handleChange = (e) => {
    let selectedOptions;
    if (e.target.multiple) {
      let options = e.target.options;
      selectedOptions = [...options]
        .filter((option) => {
          return option.selected;
        })
        .map((selectedOption) => {
          return selectedOption.value;
        });
    } else {
      selectedOptions = e.target.value;
    }

    props.handleFilterChange(e.target.name, selectedOptions);
  };

  return (
    <DivStyles width={props.width ? props.width : '100%'} margin={props.margin}>
      {/* <TextStyles>{props.label}</TextStyles> */}
      <SelectRoundStyles
        borderRadius={props.borderRadius}
        multiple={props.multiple}
        name={props.name}
        value={props.filteredValues}
        size={
          props.multiple
            ? props.filterBy.length >= parseInt(props.multipleSize)
              ? props.multipleSize
              : props.filterBy.length === 1
              ? 1
              : 2
            : null
        }
        noDropdown={props.noDropdown}
        onChange={handleChange}>
        {Object.entries(filterStructure).map(([key, value]) => {
          return (
            <option key={key} value={key}>
              {value}
            </option>
          );
        })}
      </SelectRoundStyles>
    </DivStyles>
  );
};

export default ReportFilter;
