import styled from 'styled-components';

export const ChargerListStyles = styled.div`
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : '665px')};
  overflow-y: scroll;
  border: ${({ border }) => (border ? border : '1px solid #e7e7e7')};
  margin: ${({ margin }) => (margin ? margin : '0 10px')};
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ChargerCardStyles = styled.div`
  padding: ${({ padding }) => (padding ? padding : '10px')};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? borderBottom : '1px solid #e7e7e7'};
  text-align: left;
`;

export const ChargerCardHeaderStyles = styled.div`
  display: ${({ display }) => (display ? display : 'flex')};
  flex-wrap: ${({ flexWrap }) => (flexWrap ? flexWrap : 'nowrap')};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'space-between'};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  .Title {
    color: ${({ titleColor }) => (titleColor ? titleColor : '#000')};
    font-size: ${({ titleSize }) => (titleSize ? titleSize : '14px')};
    font-weight: 600;
  }
  .Action {
    .Heart {
      margin: 0 10px;
      cursor: pointer;
      color: #8497b6;
    }
    .Status {
      color: ${({ statusColor }) => statusColor};
      border-radius: 50%;
    }
  }
`;

export const ChargerCardAddressStyles = styled.div`
  .Distance {
    color: #f60;
    font-size: ${({ distanceSize }) => (distanceSize ? distanceSize : '12px')};
  }
  .Address {
    font-size: ${({ addressSize }) => (addressSize ? addressSize : '12px')};
  }
`;

export const ChargerCardPaneStyles = styled.div`
  display: ${({ display }) => (display ? display : 'flex')};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'space-evenly'};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  padding: 1% 0;
  border: 1px solid #e7e7e7;
  text-align: center;
  width: ${({ width }) => width};
`;

export const ChargerCardIconStyles = styled.div`
  color: ${({ color }) => (color ? color : '#8497b6')};
  font-size: 20px;
  cursor: pointer;
  flex: 1 1;
  svg {
    color: ${({ color }) => (color ? color : '#8497b6')};
  }
`;
