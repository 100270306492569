import AppAuthService from '../../../services/AppAuth/AppAuthService';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import AppApiService from '../../../services/AppApi/AppApiService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppNavService = {
  logout: function () {
    if (AppPlatformService.isWebApp()) {
      AppAuthService.doWebSignOut();
    } else {
      AppAuthService.doMobileSignOut();
    }
  },
  getAccount: function () {
    return AppApiService.get('driver');
  },
  strings: localStrings,
};

export default AppNavService;
