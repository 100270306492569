import React, { useState, useContext } from 'react';

//layout
import { ComponentWrapper, OuterWrapper } from '../OperatorShared';
import Button from '../../shared/Buttons/Button';

//services
import AppDiscountSchedulesService from './AppDiscountSchedulesService';

//components
import DiscountSchedulesList from './DiscountSchedulesList/DiscountSchedulesList';
import SmallBizAdmin from '../SmallBizAdmin';

//modals
import UpgradeModal from './DiscountSchedulesModal/UpgradeModal';

//context
import { CsoAccountContext } from '../../context/operator/getCsoAccount';

const DiscountSchedules = (props) => {
  const { strings } = AppDiscountSchedulesService;
  const { currentUser, account } = useContext(CsoAccountContext);
  const [limitReached, setLimitReached] = useState(false);

  //Read only for non-admins
  let adminPermission;
  if (currentUser) {
    adminPermission = currentUser.role === 1 || currentUser.role === 2;
  }

  const closeReachModal = () => {
    setLimitReached(!limitReached);
  };

  const checkingLimit = () => {
    if (account.subscriptionLevel === 2) {
      if (account.discountSchedules.length >= 1) {
        setLimitReached(true);
      } else {
        props.history.push('/subscription/discount-schedules/add');
      }
    } else {
      props.history.push('/subscription/discount-schedules/add');
    }
  };

  return (
    <OuterWrapper title={strings.pageTitle} internal path='/'>
      {limitReached && <UpgradeModal handleCloseModal={closeReachModal} subscriptionLevel={account.subscriptionLevel} />}
      <SmallBizAdmin>
        <ComponentWrapper title={strings.pageTitle}>
          <DiscountSchedulesList adminPermission={adminPermission} />
        </ComponentWrapper>
        <Button
          cso
          hasMobile
          onclick={checkingLimit}
          buttonText={strings.addButton}
          roles={[1, 2]}
        />
      </SmallBizAdmin>
    </OuterWrapper>
  );
};

export default DiscountSchedules;
