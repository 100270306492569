import React from 'react';

import moment from 'moment';

//styles
import {
  TableRowStyles,
  TableItemStyles,
} from '../../../../../../SharedStyles';

const NYSERDAReportItem = (props) => {
  const { session } = props;
  return (
    <TableRowStyles
      hoverBackground='#f1f7ff'
      borderTop='none'
      borderLeft='none'
      borderRight='none'
      align='center'
      width='100%'
      minWidth='1160px'>
      <TableItemStyles width='100%' minWidth='82px' maxWidth='82px'>
        {session.EVSESerialNumber}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='82px' maxWidth='82px'>
        {session.SessionStartDate}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='82px' maxWidth='82px'>
        {session.SessionStartTime}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='82px' maxWidth='82px'>
        {session.SessionEndDate}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='82px' maxWidth='82px'>
        {session.SessionEndTime}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='82px' maxWidth='82px'>
        {session.ChargingStartDate}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='82px' maxWidth='82px'>
        {session.ChargingStartTime}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='82px' maxWidth='82px'>
        {session.ChargingEndDate}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='82px' maxWidth='82px'>
        {session.ChargingEndTime}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='82px' maxWidth='82px'>
        {session.SessionDuration}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='82px' maxWidth='82px'>
        {session.ChargingDuration}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='82px' maxWidth='82px'>
        {session.TotalEnergyProvided}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='62px' maxWidth='62px'>
        {session.PeakPower}
      </TableItemStyles>
    </TableRowStyles>
  );
};

export default NYSERDAReportItem;
