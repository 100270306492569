import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppModalService from './AppModalService';

//global components
import ModalButton from '../../../../../shared/Buttons/ModalButton';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  DivStyles,
} from '../../../../../SharedStyles';

const MorePropsModal = (props) => {
  const { strings } = AppModalService;

  const closeModal = () => {
    props.handleModalClose();
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.morePropsTitle}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <p>{strings.morePropsContent}</p>
          </ModalItemStyles>
          {/* Section */}
          <ModalItemStyles pSize='12px' textMargin='0 0 5px' padding='0 5px'>
            <DivStyles display='flex' alignItems='center' align='left'>
              <DivStyles weight='600' align='left' flex='1'>
                {'Local Auth List Ctrlr'}
              </DivStyles>
              <DivStyles align='left' flex='1' color='#aaa6a6'>
                {props.charger.localAuthListCntrlr}
              </DivStyles>
            </DivStyles>
            <DivStyles display='flex' alignItems='center' align='left'>
              <DivStyles weight='600' align='left' flex='1'>
                {'Smart Charging Crrlr'}
              </DivStyles>
              <DivStyles align='left' flex='1' color='#aaa6a6'>
                {props.charger.smartChargingCrrlr}
              </DivStyles>
            </DivStyles>
            <DivStyles display='flex' alignItems='center' align='left'>
              <DivStyles weight='600' align='left' flex='1'>
                {'Cable Breakaway Sensor'}
              </DivStyles>
              <DivStyles align='left' flex='1' color='#aaa6a6'>
                {props.charger.cableBreakawaySensor}
              </DivStyles>
            </DivStyles>
            <DivStyles display='flex' alignItems='center' align='left'>
              <DivStyles weight='600' align='left' flex='1'>
                {'Charging Status Indicator'}
              </DivStyles>
              <DivStyles align='left' flex='1' color='#aaa6a6'>
                {props.charger.chargingStatusIndicator}
              </DivStyles>
            </DivStyles>
            <DivStyles display='flex' alignItems='center' align='left'>
              <DivStyles weight='600' align='left' flex='1'>
                {'Connector Holster Sensor'}
              </DivStyles>
              <DivStyles align='left' flex='1' color='#aaa6a6'>
                {props.charger.connectorHolsterSensor}
              </DivStyles>
            </DivStyles>
            <DivStyles display='flex' alignItems='center' align='left'>
              <DivStyles weight='600' align='left' flex='1'>
                {'Data Link'}
              </DivStyles>
              <DivStyles align='left' flex='1' color='#aaa6a6'>
                {props.charger.dataLink}
              </DivStyles>
            </DivStyles>
            <DivStyles display='flex' alignItems='center' align='left'>
              <DivStyles weight='600' align='left' flex='1'>
                {'Emergency Stop Sensor'}
              </DivStyles>
              <DivStyles align='left' flex='1' color='#aaa6a6'>
                {props.charger.emergencyStopSensor}
              </DivStyles>
            </DivStyles>
            <DivStyles display='flex' alignItems='center' align='left'>
              <DivStyles weight='600' align='left' flex='1'>
                {'EV Retention Lock'}
              </DivStyles>
              <DivStyles align='left' flex='1' color='#aaa6a6'>
                {props.charger.evRetentionLock}
              </DivStyles>
            </DivStyles>
            <DivStyles display='flex' alignItems='center' align='left'>
              <DivStyles weight='600' align='left' flex='1'>
                {'Enclosure Temp/Humidity'}
              </DivStyles>
              <DivStyles align='left' flex='1' color='#aaa6a6'>
                {props.charger.encloserTempHumidity}
              </DivStyles>
            </DivStyles>
          </ModalItemStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={closeModal}
          buttonText={strings.ok}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default MorePropsModal;
