import React, { useState, useContext, useEffect } from 'react';

//layout
import { InteriorWrapper, ComponentWrapper } from '../shared';
import Button from '../../shared/Buttons/Button';

//services
import AppAccountSettingsService from './AppAccountSettingsService';

//components
import Address from '../../shared/Address/Address';
import AccountHeader from './Forms/AccountHeader';
import AccountForm from './Forms/AccountForm';
import ResetForm from './Forms/ResetForm';
import CloseDriverAccount from './Forms/CloseDriverAccount';

//global components
import LoadingSpinner from '../../shared/Loading/LoadingSpinner';

//hooks
import { useCloudinary } from '../../shared/Cloudinary/hook/useCloudinary';

//styles
import './AccountSettings.scss';
import { FormContainerStyles } from '../../SharedStyles';

//context
import { DriverContext } from '../../context/driver/getDriverContext';

const AccountSettings = (props) => {
  const { strings } = AppAccountSettingsService;
  const {
    driver,
    setDriver,
    postDriver,
    updateDriverState,
    fetchDriver,
  } = useContext(DriverContext);
  const [loading, setLoading] = useState(false);
  const { cloudData, uploadImage, imageLoading } = useCloudinary({
    preset: 'profile_images',
    tags: [`${driver.firstName} ${driver.lastName}`, driver.id],
  });

  const updateDriverImage = async () => {
    let newDriver = { ...driver, imagePath: cloudData.secure_url };
    await updateDriverState(newDriver);
  };

  useEffect(() => {
    fetchDriver();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (cloudData) {
      updateDriverImage();
    }
    // eslint-disable-next-line
  }, [cloudData]);

  const updateDriver = (e) => {
    setDriver({ ...driver, [e.target.name]: e.target.value });
  };

  const onSaveClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    let res = await postDriver();
    if (res) {
      props.history.goBack();
    }
  };

  return (
    <InteriorWrapper
      title={strings.title}
      isInternal
      myForm='myForm'
      path='/charging'>
      {(loading || imageLoading) && <LoadingSpinner />}
      <ComponentWrapper title={strings.title}>
        <form onSubmit={onSaveClick} id='myForm'>
          <AccountHeader
            strings={strings}
            imageUpload={uploadImage}
            driver={driver}
          />
          <AccountForm
            driver={driver}
            strings={strings}
            updateDriver={updateDriver}
          />
          <ResetForm isDriver strings={strings}/>
          <FormContainerStyles padding='10px 0 15px'>
            <h3 className='Form-Title'>{strings.addressTitle}</h3>
            <Address driver={driver} onChange={updateDriver} />
          </FormContainerStyles>
          <CloseDriverAccount isDriver strings={strings} driver={driver} />
        </form>
      </ComponentWrapper>
      <Button driver hasMobile formSubmit buttonText={strings.save} />
    </InteriorWrapper>
  );
};

export default AccountSettings;
