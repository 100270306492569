import styled from 'styled-components';

export const PaymentCreditCardStyles = styled.div`
  margin: ${({ margin }) => (margin ? margin : '10px')};
`;

export const PaymentCreditCardDisplayStyles = styled.div`
  display: ${({ display }) => display};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  .Note {
    font-size: ${({ pSize }) => pSize};
    color: ${({ pColor }) => pColor};
    margin: 0 0 5px;
  }
`;

export const PaymentCreditCardDetailStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ margin }) => (margin ? margin : '0 0 20px')};

  svg {
    font-size: 50px;
    color: #8497b6;
    margin: 0 15px 0 0;
  }
  .Details {
    text-align: left;
    color: ${({ detailsColor }) => detailsColor};
    font-size: ${({ detailsSize }) => detailsSize};
    p {
      margin: 0;
    }
  }
`;
