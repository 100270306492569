import React from 'react';
import { ThemeProvider } from 'styled-components';

//components
import NewNav from '../../operator/AccountCreation/Nav/NewNav';
import Footer from '../../shared/Footer/Footer';

//styles
import { InteriorStyles, JumboTronHeaderStyles } from '../../SharedStyles';

//helpers
import { CSO_THEME } from '../index';

const Offline = () => {
  return (
    <ThemeProvider theme={CSO_THEME}>
      <InteriorStyles>
        <div className='Body-Container'>
          <NewNav title='No Network Connection' hideLogin />
          <div className='AppCso-interior-container Not-found'>
            <div className='AppCso-component-container'>
              <div className='component-title-container'>
                <div className='component-title'>No Internet Connection</div>
              </div>
              <div className='center-container'>
                <div>
                  <JumboTronHeaderStyles>
                    No Internet Connection
                  </JumboTronHeaderStyles>
                  <div>
                    <p>Please connect to the Internet to proceed.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </InteriorStyles>
    </ThemeProvider>
  );
};

export default Offline;
