import React from 'react';

import {
  DivStyles,
  ImageThumbnailStyles,
  TitleStyles,
  TextStyles,
} from '../../SharedStyles';

const LocationBranding = (props) => {
  const { charger } = props;

  //add logic to check for mobile vs site. and redirect

  return (
    <DivStyles
      borderBottom
      display='flex'
      alignItems='center'
      padding='0 10px 10px'
      margin='5px 0 10px'>
      <ImageThumbnailStyles
        margin='0 10px 0 0'
        maxWidth='100px'
        mobileMaxWidth='75px'
        src={charger.locationBrandingImagePath}
        alt={charger.iotHubDeviceId}
      />
      <DivStyles width='100%'>
        <TitleStyles
          weight='600'
          size='15px'
          mobileSize='13px'
          margin='0 0 2px'>
          {charger.locationName}
        </TitleStyles>
        <TextStyles size='12px' mobileSize='10px' margin='0'>
          {charger.locationBrandingInformation}
        </TextStyles>
        <DivStyles
          display='flex'
          alignItems='center'
          justifyContent='space-between'>
          <DivStyles flex='1' wrapMobile>
            <TextStyles weight='600' size='14px' mobileSize='10px' margin='0'>
              Phone:
            </TextStyles>
            <TextStyles size='14px' mobileSize='10px' margin='0'>
              {charger.locationBrandingPhone}
            </TextStyles>
          </DivStyles>
          <DivStyles flex='1' margin='0' wrapMobile>
            <TextStyles weight='600' size='14px' mobileSize='10px' margin='0'>
              Support:
            </TextStyles>
            <a
              href={charger.locationBrandingSupportURL}
              target='_blank'
              rel='noopener noreferrer'>
              <TextStyles
                size='14px'
                mobileSize='10px'
                margin='0'
                wordBreak='break-word'
                color='blue'>
                {charger.locationBrandingSupportURL}
              </TextStyles>
            </a>
          </DivStyles>
        </DivStyles>
      </DivStyles>
    </DivStyles>
  );
};

export default LocationBranding;
