import React, { useState, useContext } from 'react';

//layout
import {
  OuterWrapper,
  ComponentWrapper,
  SubscriptionAccess,
} from '../../OperatorShared/index';
import Button from '../../../shared/Buttons/Button';

//services
import AppSystemUsersService from '../AppSystemUsersService';

//components
import UserInfoForm from './EnterpriseForm/UserInfoForm';
import EnterpriseLocations from './EnterpriseForm/EnterpriseLocations';
import EnterpriseRoles from './EnterpriseForm/EnterpriseRoles';

//global components
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';
import EnterpriseAdmin from '../../EnterpriseAdmin';

//modals
import TokenModal from './TokenModal';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import { ErrorContext } from '../../../context/shared/ErrorContext';
import { FormStyles, DivStyles } from '../../../SharedStyles';

const SystemUserAdd = () => {
  const { strings } = AppSystemUsersService;
  const { account, currentUser } = useContext(CsoAccountContext);
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(false);
  const [systemUser, setSystemUser] = useState({});
  const [token, setToken] = useState({});
  const [tokenModal, setTokenModal] = useState(false);

  const toggleModal = () => {
    setTokenModal(!tokenModal);
  };

  const handleChange = (e) => {
    setSystemUser({ ...systemUser, [e.target.name]: e.target.value });
  };

  const updateRole = (role) => {
    let convertRole = parseInt(role);
    setSystemUser({ ...systemUser, role: convertRole });
  };

  const updateLocations = (locations) => {
    setSystemUser({ ...systemUser, locationIds: locations });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (systemUser.role == null) {
      systemUser.role = 1; //Administrator
    }
    const userToken = {
      ...systemUser,
      csoAccountId: account.id,
    };
    try {
      let newUserToken = await AppSystemUsersService.createSystemUserToken(
        userToken
      );
      setToken(newUserToken);
      setTokenModal(true);
    } catch (err) {
      setLoading(false);
      setError({
        display: true,
        title: 'Something went wrong',
        message: err.response.data.split(/[|]+/).pop(),
        styles: 'cso',
      });
    }
  };

  const toggleAllLocations = (name, value) => {
    setSystemUser({ ...systemUser, [name]: value });
  };

  //Enterprise Component only through routing
  return (
    <OuterWrapper
      title={strings.addTitle}
      internal
      myForm
      path='/system-users'
      roles={[1, 2]}>
      {loading && <LoadingSpinner />}
      <EnterpriseAdmin>
        <ComponentWrapper title={strings.addTitle}>
          {tokenModal && <TokenModal token={token} toggleModal={toggleModal} />}
          {/* ====================FORM================================ */}
          <FormStyles id='myForm' onSubmit={handleSubmit}>
            <DivStyles>
              <UserInfoForm
                handleChange={handleChange}
                systemUser={systemUser}
              />
              {/* =====================Enterprise Only=============================== */}
              <SubscriptionAccess levels={[3]}>
                <EnterpriseLocations
                  canEdit={[1, 2]}
                  user={systemUser}
                  account={account}
                  currentUser={currentUser}
                  updateLocations={updateLocations}
                  addUser
                  handleChange={toggleAllLocations}
                />
                <EnterpriseRoles
                  addUser
                  account={account}
                  user={systemUser}
                  updateRole={updateRole}
                />
              </SubscriptionAccess>
            </DivStyles>
            {/* ==================================================================== */}
          </FormStyles>
        </ComponentWrapper>
        <Button
          cso
          hasMobile
          formSubmit
          disabled={loading}
          buttonText={strings.save}
          roles={[1, 2]}
        />
      </EnterpriseAdmin>
    </OuterWrapper>
  );
};

export default SystemUserAdd;
