import AccessCardsService from '../../AccessCardsService';

const { strings } = AccessCardsService;

export const formSetup = [
  {
    name: 'nickName',
    type: 'text',
    element: 'input',
    placeholder: strings.activateNickname,
    required: true,
    disabled: false,
    flex: '100%',
    margin: '0 10px',
  },
  {
    name: 'serialId',
    type: 'text',
    element: 'input',
    placeholder: strings.activateSerialNo,
    required: true,
    disabled: false,
    flex: '100%',
    margin: '0 10px',
  },
];
