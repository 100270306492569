import React from 'react';

//hooks
import { useRouter } from '../../../shared/hooks/useRouter';
import { useWindowResize } from '../../../shared/hooks/useWindowSize';

//styles
import {
  TableItemStyles,
  TableRowStyles,
  TextStyles,
} from '../../../SharedStyles';

//servies
import AppPlatformService from '../../../../services/AppPlatform/AppPlatformService';

const StatementCard = (props) => {
  const { strings } = props;
  const size = useWindowResize(window.innerWidth, window.innerHeight);

  const router = useRouter();

  const handleRedirect = () => {
    router.history.push(`/statements/${props.id}`);
  };

  return (
    <TableRowStyles
      borderTop='none'
      borderLeft='none'
      borderRight='none'
      hoverBackground='#f1f7ff'
      onClick={handleRedirect}
      width={size[0] >= 768 ? '100%' : '820px'}>
      <TableItemStyles width='160px'>{props.statementDate}</TableItemStyles>
      <TableItemStyles width='160px'>{props.activityPeriod}</TableItemStyles>
      <TableItemStyles width='110px'>
        {AppPlatformService.currencyFormat(props.earnings)}
      </TableItemStyles>
      <TableItemStyles width='110px'>
        {AppPlatformService.currencyFormat(props.subFees)}
      </TableItemStyles>
    </TableRowStyles>
  );
};

export default StatementCard;
