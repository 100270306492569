import React from 'react';

//layout
import { OuterWrapper, ComponentWrapper } from '../../operator/OperatorShared';

//components
import AppAccessDeniedService from './AppAccessDeniedService';
import AccessDeniedComponent from './AccessDeniedComponent';

const AppSharedAccessDenied = () => {
  const { strings } = AppAccessDeniedService;
  return (
    <OuterWrapper title={strings.deniedTitle} internal path='/'>
      <ComponentWrapper title={strings.pageTitle}>
        <AccessDeniedComponent smallBiz />
      </ComponentWrapper>
    </OuterWrapper>
  );
};

export default AppSharedAccessDenied;
