import styled from 'styled-components';

export const InteriorStyles = styled.div`
  background-color: #fcfcfc;
  height: 100%;
  font-family: 'Raleway', sans-serif;
  .Body-Container {
    max-width: 1280px;
    margin: 0 auto;
    min-height: 100%;
    display: block;
  }
`;

export const InteriorDriverComponentStyles = styled.div`
  position: relative;
  max-width: 600px;
  width: 100%;
  min-height: 500px;
  overflow: hidden;
  margin: 0 auto;
  padding: 10px 0;
  background-color: #fff;
  box-shadow: 5px 5px 7px 0 #e8e8e8;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media screen and (max-width: ${({ theme, mobileMedia, isInternal }) => {
      if (isInternal) {
        return mobileMedia ? mobileMedia : theme.defaultMediaQuery;
      } else {
        return '0px';
      }
    }}) {
    padding: ${({ mobilePadding }) => `${mobilePadding} 0 0`};
    box-shadow: none;
    .Button-container {
      display: none;
    }
  }
`;

export const InteriorCSOComponentStyles = styled.div`
  position: relative;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '800px')};
  min-height: 700px;
  margin: 0 auto;
  padding: 10px;
  background: #fff;
  box-shadow: 5px 5px 7px 0 #e7e7e7;
  @media screen and (max-width: ${({ theme, mobileMedia }) =>
      mobileMedia ? mobileMedia : theme.defaultMediaQuery}) {
    padding: ${({ mobilePadding }) =>
      mobilePadding ? `${mobilePadding} 0 0` : '0'};
    box-shadow: none;
    min-height: 0;
    .Button-container {
      display: none;
    }
  }
`;

export const InteriorNoComponentStyles = styled.div`
  position: relative;
  max-width: 800px;
  min-height: 700px;
  margin: 0 auto;
  padding: 10px;
  background: #fff;
  box-shadow: 5px 5px 7px 0 #e7e7e7;
  @media screen and (max-width: ${({ theme, mobileMedia, isInternal }) => {
      if (isInternal) {
        return mobileMedia ? mobileMedia : theme.defaultMediaQuery;
      } else {
        return '0px';
      }
    }}) {
    padding: ${({ mobilePadding }) => `${mobilePadding} 0 0`};
    box-shadow: none;
    .Button-container {
      display: none;
    }
  }
`;
