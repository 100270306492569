import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  FaRegEdit,
  FaTachometerAlt,
  FaChargingStation,
  FaRegTrashAlt,
  FaEllipsisV,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

//services
import AppLocationsService from '../AppLocationsService';

//hooks
import useOnClickOutside from '../../../shared/hooks/useOnClickOutside';
import { useWindowResize } from '../../../shared/hooks/useWindowSize';
import { useContainerHeight } from '../../../shared/hooks/useContainerHeight';

//modals
import DeleteModal from '../LocationsModals/DeleteModal';
import RedirectMeter from '../LocationsModals/RedirectMeter';
import RedirectChargers from '../LocationsModals/RedirectChargers';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import { ErrorContext } from '../../../context/shared/ErrorContext';

//styles
import { DataCardStyles, ToolsStyles } from '../../../OperatorStyles';
import { TextStyles, DivStyles } from '../../../SharedStyles';

const LocationsCard = (props) => {
  const { strings } = AppLocationsService;
  const { location } = props;
  const { setAccount } = useContext(CsoAccountContext);
  const { setError } = useContext(ErrorContext);
  const CardRef = useRef();
  const OutsideRef = useRef();
  const size = useWindowResize(window.innerWidth, window.innerHeight);
  const { containerHeight, containerRef, setContainerRef } = useContainerHeight(
    50,
    CardRef
  );
  const [deleteModal, setDeleteModal] = useState(false);
  const [metersModal, setMetersModal] = useState(false);
  const [chargersModal, setChargersModal] = useState(false);
  const [openTools, setOpenTools] = useState(false);

  useOnClickOutside(OutsideRef, () => setOpenTools(false));

  const handleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleMetersModal = () => {
    setMetersModal(!metersModal);
  };

  const handleChargersModal = () => {
    setChargersModal(!chargersModal);
  };

  const toggleToolBar = () => {
    setOpenTools(!openTools);
  };

  const handleDeleteLocation = async (id) => {
    try {
      let updatedAccount = await AppLocationsService.deleteLocation(id);
      setAccount(updatedAccount);
      props.fetchingLocations();
    } catch (err) {
      if (err.response.data === 'Location Last One') {
        setError({
          display: true,
          title: strings.failedDeleteTitle,
          message: strings.failedDeleteOnly,
          styles: 'cso',
        });
      }
      if (err.response.data === 'Location Charger Assigned') {
        setError({
          display: true,
          title: strings.failedDeleteTitle,
          message: strings.failedDeleteCharger,
          styles: 'cso',
        });
      }
      if (err.response.data === 'Location Not Found') {
        setError({
          display: true,
          title: strings.failedDeleteTitle,
          message: strings.failedDeleteLocation,
          styles: 'cso',
        });
      }
    }
  };

  //gets card container height for responsive
  useEffect(() => {
    setContainerRef(CardRef);
  }, [CardRef, setContainerRef]);

  const CountItem = (props) => {
    return (
      <TextStyles color={props.color} margin='0' weight={props.value === 0 ? null : '600'}>
        {props.value}
      </TextStyles>
    )
  }

  const PortStat = ({ portStat }) => {
    return (
      <div className='Card-Data-Item'>
        <DivStyles display='flex' margin='0'>
          <CountItem color='#07e019' value={portStat.availablePortCount} />
          /
          <CountItem color='#4f8ef5' value={portStat.connectedPortCount} />
          /
          <CountItem color='orange' value={portStat.chargingPortCount} />
          /
          <CountItem color='grey' value={portStat.disabledPortCount} />
          /
          <CountItem color='red' value={portStat.troublePortCount} />
          /
          <CountItem color='lightgrey' value={portStat.offLinePortCount} />
        </DivStyles>
      </div>
    )
  }

  return (
    <DataCardStyles
      ref={containerRef}
      onMouseEnter={() => {
        size[0] >= 768 && setOpenTools(true);
      }}
      onMouseLeave={() => {
        size[0] >= 768 && setOpenTools(false);
      }}>
      {deleteModal && (
        <DeleteModal
          loc={location}
          handleDeleting={handleDeleteLocation}
          handleCloseModal={handleDeleteModal}
        />
      )}
      {metersModal && (
        <RedirectMeter loc={location} handleCloseModal={handleMetersModal} />
      )}
      {chargersModal && (
        <RedirectChargers
          loc={location}
          handleCloseModal={handleChargersModal}
        />
      )}
      <Link to={`/locations/${location.id}`}>
        <div className='Card-Left'>
          <div className='Card-Content'>
            <div className='Card-Content-Title'>{location.name}</div>
            <div className='Card-Content-Subtitle'>
              {location.address1} {location.city}, {location.state}{' '}
              {location.zip}
            </div>
          </div>
        </div>
        <div className='Card-Right'>
          <div className='Card-Data'>
            <div className='Card-Data-Item'>{location.transformerPower}kVA</div>
            <div className='Card-Data-Item'>
              {/* total Amperage used */}
              {location.portStat.currentLoad.toFixed(0)}A
            </div>
            <PortStat portStat={location.portStat} />
          </div>
        </div>
      </Link>
      <FaEllipsisV
        className='Card-Tools-Toggle'
        style={size[0] >= 768 ? { display: 'none' } : { display: 'block' }}
        onClick={toggleToolBar}
      />
      <ToolsStyles
        ref={OutsideRef}
        displayTools={openTools}
        containerHeight={(containerHeight - 35) / 2}>
        <Link
          to={`/locations/${location.id}`}
          title={strings.editLocationTitle}
          className='Tool-Item'>
          <FaRegEdit />
        </Link>
        <div className='Tool-Item' onClick={handleMetersModal}>
          <FaTachometerAlt title={strings.meterGroupTitle} />
        </div>
        <div className='Tool-Item' onClick={handleChargersModal}>
          <FaChargingStation title={strings.chargerTitle} />
        </div>
        <div className='Tool-Item' onClick={handleDeleteModal}>
          <FaRegTrashAlt title={strings.deleteModalTitle} />
        </div>
      </ToolsStyles>
    </DataCardStyles>
  );
};

export default LocationsCard;
