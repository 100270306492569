import React, { useState, useEffect, useRef } from 'react';
import {
  FaRegEdit,
  FaRegTrashAlt,
  FaBatteryHalf,
  FaNetworkWired,
  FaTags,
  FaShareAltSquare,
  FaUsers,
  FaHistory,
  FaEllipsisV,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

//services
import AppChargersService from '../AppChargersService';

//components
import ChargerCheckbox from './ChargerCheckbox/ChargerCheckbox';

//global components
import EnterpriseAdmin from '../../EnterpriseAdmin';
import { RoleCanEdit, SubscriptionAccess } from '../../OperatorShared';

//modals
import Delete from '../ChargersForm/ChargerEditProps/EditModals/Delete';
import MembershipModal from '../ChargersModals/MembershipModal';
import CouponModal from '../ChargersModals/CouponModal';

//hooks
import useOnClickOutside from '../../../shared/hooks/useOnClickOutside';
import { useWindowResize } from '../../../shared/hooks/useWindowSize';
import { useContainerHeight } from '../../../shared/hooks/useContainerHeight';

//styles
import {
  DivStyles,
  ImageThumbnailStyles,
  TitleStyles,
  TextStyles,
} from '../../../SharedStyles';
import { ToolsStyles } from '../../../OperatorStyles';

//helpers
import { getChargerStatusColor } from '../../../shared/Helpers';

const ChargersCard = (props) => {
  const { strings } = AppChargersService;
  const CardRef = useRef();
  const OutsideRef = useRef();
  const size = useWindowResize(window.innerWidth, window.innerHeight);
  const { containerHeight, setContainerRef } = useContainerHeight(75, CardRef);
  const [charger, setCharger] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [membershipModal, setMembershipModal] = useState(false);
  const [couponModal, setCouponModal] = useState(false);
  const [openTools, setOpenTools] = useState(false);

  useOnClickOutside(OutsideRef, () => setOpenTools(false));

  //gets card container height for responsive
  useEffect(() => {
    setContainerRef(CardRef);
  }, [CardRef, setContainerRef]);

  useEffect(() => {
    setCharger(props.charger);
  }, [props.charger]);

  const toggleToolBar = () => {
    setOpenTools(!openTools);
  };

  const handleDeleteModal = () => {
    setDeleteModal(true);
  };

  const handleModalClose = () => {
    setDeleteModal(false);
  };

  const membershipModalOpen = () => {
    setMembershipModal(!membershipModal);
  };
  const couponModalOpen = () => {
    setCouponModal(!couponModal);
  };

  const checkSelectedCharger = () => {
    props.checkSelectedCharger(charger.iotHubDeviceId);
  };

  return (
    <>
      {deleteModal && (
        <Delete
          handleModalClose={handleModalClose}
          chargerDetails={charger}
          updateChargers={props.updateChargers}
        />
      )}
      {membershipModal && (
        <MembershipModal
          charger={charger}
          cardMembership
          handleCloseModal={membershipModalOpen}
        />
      )}
      {couponModal && (
        <CouponModal
          charger={charger}
          cardCoupon
          handleCloseModal={couponModalOpen}
        />
      )}
      <DivStyles
        display='flex'
        flexWrap='nowrap'
        alignItems='start'
        linkWidth='100%'
        linkColor='#617793'
        padding='10px'
        mobilePadding='10px 5px'
        hoverBackground='#f1f7ff'
        background={props.index % 2 === 0}
        noCursor={!charger.hasBooted}
        ref={CardRef}
        onMouseEnter={() => {
          size[0] >= 768 && setOpenTools(true);
        }}
        onMouseLeave={() => {
          size[0] >= 768 && setOpenTools(false);
        }}>
        <EnterpriseAdmin noMessage>
          <ChargerCheckbox
            disabled={!charger.hasBooted}
            checked={!!charger.selected}
            handleChange={checkSelectedCharger}
          />
        </EnterpriseAdmin>
        <Link
          to={charger.hasBooted ? `/chargers/${charger.iotHubDeviceId}` : '#'}>
          {/* Card Container remove wrapMobile*/}
          <DivStyles
            display='flex'
            flexWrap='nowrap'
            alignItems='center'
            justifyContent='space-between'
            className='container-ref'>
              {/* @media screen and (max-width: 575px) {
  .eLFMpw {
    flex: 1 1 100%;
    flex-wrap: nowrap;
  }*/}
            {/* Left Card */}
            <DivStyles
              display='flex'
              flexWrap='nowrap'
              alignItems='center'
              wrapMobile>
              <ImageThumbnailStyles
                maxWidth='35px'
                margin='0 10px 0 0'
                removeMobileImg
                src={charger.vendorIcon}
                alt={charger.iotHubDeviceId}
              />
              <DivStyles align='left'>
                <TitleStyles size='14px' weight='700' margin='0'>
                  {charger.chargerName || charger.iotHubDeviceId}
                </TitleStyles>
                <TextStyles size='12px' weight='500' margin='0'>
                  {charger.chargerAddress}
                </TextStyles>
              </DivStyles>
            </DivStyles>
            {/* Right Card */}
            <DivStyles
              display='flex'
              flexWrap='nowrap'
              alignItems='center'
              align='right'
              wrapMargin='5px 0 0'
              wrapMobile
              flexMobile='1 1 auto'>
              {/* @media screen and (max-width: 575px) {
  .kUhSSm {
    margin: 5px 0px 0px;
    flex: 1 1 auto;
	flex-wrap: wrap;
  } */}
              {/* charger power */}
              {/* charger Connector Type */}
              <DivStyles size='12px' padding='0 5px'>
                {Object.keys(charger).length > 0 &&
                  charger.connectorsStatus?.map((conn, i) => (
                    <DivStyles key={i} display='flex'>
                      <DivStyles
                        flex='1 1 40%'
                        borderRight='1px solid #000'
                        size='12px'
                        padding='0 5px'
                        width='5em'
                        >
                        {charger.power} kW
                      </DivStyles>
                      <DivStyles 
                        flex='0 0 auto'
                        padding='0 5px'
                        linkWidth='auto'
                        align='left'
                        width='auto'>
                        {conn.connectorType}
                      </DivStyles>
                      <DivStyles
                        flex='none'
                        className='Data-circle Data-row'
                        margin='0 0 4px 5px'
                        height='12px'
                        width='12px'
                        style={{
                          backgroundColor: getChargerStatusColor(conn.status),
                        }} />
                    </DivStyles>
                  ))}
                {Object.keys(charger).length > 0 &&
                  !charger.connectorsStatus?.length &&
                  'None'}
              </DivStyles>
            </DivStyles>
          </DivStyles>
        </Link>
        <FaEllipsisV
          className='Card-Tools-Toggle'
          style={size[0] >= 768 ? { display: 'none' } : { display: 'block' }}
          onClick={toggleToolBar}
        />
        <ToolsStyles
          ref={OutsideRef}
          displayTools={openTools}
          containerHeight={(containerHeight - 35) / 2}>
          {!charger.hasBooted ? (
            <>
              {charger.isAmpedUpInside && (
                <RoleCanEdit roles={[1, 3]}>
                  <Link
                    to={`/chargers/${charger.iotHubDeviceId}/connect`}
                    title={strings.chargersNetwork}
                    className='Tool-Item'>
                    <FaNetworkWired />
                  </Link>
                </RoleCanEdit>
              )}
              <RoleCanEdit roles={[1]}>
                <div className='Tool-Item' onClick={handleDeleteModal}>
                  <FaRegTrashAlt title={strings.deleteModalTitle} />
                </div>
              </RoleCanEdit>
            </>
          ) : (
            <>
              <Link
                to={`/chargers/${charger.iotHubDeviceId}`}
                title={strings.editCharger}
                className='Tool-Item'>
                <FaRegEdit />
              </Link>
              <SubscriptionAccess levels={[3]}>
                <RoleCanEdit roles={[1, 2, 3, 4]}>
                  <Link
                    to={`/chargers/${charger.iotHubDeviceId}/controls`}
                    title={strings.chargersControl}
                    className='Tool-Item'>
                    <FaBatteryHalf />
                  </Link>
                </RoleCanEdit>
              </SubscriptionAccess>

              <RoleCanEdit roles={[1, 2, 4]}>
                <Link
                  to={`/chargers/${charger.iotHubDeviceId}/sessions`}
                  title={strings.chargersHistory}
                  className='Tool-Item'>
                  <FaHistory />
                </Link>
              </RoleCanEdit>
              <SubscriptionAccess levels={[1, 2, 3]}>
                <RoleCanEdit roles={[1, 2]}>
                  <div className='Tool-Item'>
                    <FaUsers
                      title={strings.chargersMembership}
                      onClick={membershipModalOpen}
                    />
                  </div>
                </RoleCanEdit>
              </SubscriptionAccess>
              <SubscriptionAccess levels={[3]}>
                <RoleCanEdit roles={[1, 2]}>
                  <div className='Tool-Item'>
                    <FaTags
                      title={strings.chargersCoupon}
                      onClick={couponModalOpen}
                    />
                  </div>
                </RoleCanEdit>
              </SubscriptionAccess>
              <Link
                to={`/chargers/${charger.iotHubDeviceId}/share`}
                title={strings.chargerShare}
                className='Tool-Item'>
                <FaShareAltSquare />
              </Link>
              {charger.isAmpedUpInside &&
                charger.chargerConnectedStatus === 'Off-line' && (
                  <RoleCanEdit roles={[1, 3]}>
                    <Link
                      to={`/chargers/${charger.iotHubDeviceId}/connect`}
                      title={strings.chargersNetwork}
                      className='Tool-Item'>
                      <FaNetworkWired />
                    </Link>
                  </RoleCanEdit>
                )}
              <RoleCanEdit roles={[1]}>
                <div className='Tool-Item' onClick={handleDeleteModal}>
                  <FaRegTrashAlt title={strings.deleteModalTitle} />
                </div>
              </RoleCanEdit>
            </>
          )}
        </ToolsStyles>
      </DivStyles>
    </>
  );
};

export default ChargersCard;
