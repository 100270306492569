import AppReportsService from './AppReportsService';

// import energyImage from '../../../assets/images/Reports/Energy_Summary140x140.jpg';
import income_twoImage from '../../../assets/images/Reports/Income_Column_two140x140.jpg';
import lineImage from '../../../assets/images/Reports/Line_Chart140x140.jpg';
// import mapImage from '../../../assets/images/Reports/Map_Report.jpg';
import session_chartImage from '../../../assets/images/Reports/session_Column140x140.jpg';
import session_detailsImage from '../../../assets/images/Reports/session_details_140x140.jpg';

const { strings } = AppReportsService;

// levels = subscriptionLevel 0: Free, 1: shared, 2: Small Biz, 3: Enterprise
// roles = 1: Admin, 2: manager, 3: tech, 4: customer rep

export const sessionReportsData = [
  {
    title: strings.sessionDetails,
    contentOne: strings.sessionDetailsContent,
    imageUrl: session_detailsImage,
    route: '/reports/charging-session-detail',
    devOnly: false,
    levels: [1, 2, 3],
    roles: [1, 2],
  },
  {
    title: strings.statusDetails,
    contentOne: strings.statusDetailsContent,
    imageUrl: session_detailsImage,
    route: '/reports/charger-status-detail',
    devOnly: false,
    levels: [3],
    roles: [1, 2],
  },
  {
    title: strings.utilitySessionReport,
    contentOne: strings.utilitySessionContent,
    imageUrl: session_detailsImage,
    route: '/reports/utility-charging-session-detail-report',
    devOnly: false,
    levels: [3],
    roles: [1, 2],
  },  

  // {
  //   title: strings.sessionLocations,
  //   contentOne: strings.sessionLocationsContentOne,
  //   emContentOne: strings.chargingSessionSummary,
  //   contentTwo: strings.sessionLocationsContentTwo,
  //   emContentTwo: strings.allLocations,
  //   contentThree: strings.sessionLocationsContentThree,
  //   imageUrl: mapImage,
  //   devOnly: false,
  // },
  // {
  //   title: strings.sessionChargerTypes,
  //   contentOne: strings.sessionChargerTypesContentOne,
  //   emContentOne: strings.sessionChargerTypes,
  //   contentTwo: strings.sessionChargerTypesContentTwo,
  //   imageUrl: session_chartImage,
  // },
];

export const energyReportsData = [
  // {
  //   title: strings.energyChargerTypes,
  //   subtitle: strings.realTime,
  //   contentOne: strings.energyChargerTypesContent,
  //   imageUrl: energyImage,
  //   route: '/reports/energy-charger-types',
  //   devOnly: false,
  // },
  {
    title: strings.energyLocations,
    contentOne: strings.energyLocationsContentOne,
    emContentOne: strings.energySummary,
    contentTwo: strings.energyLocationsContentTwo,
    imageUrl: lineImage,
    route: '/reports/energy-summary',
    devOnly: false,
    levels: [2, 3],
    roles: [1, 2],
  },
];

export const incomeReportsData = [

  {
    title: "Revenue Summary Report",
    contentOne: "Provides a birds-eye-view of revenue over time. Displays an information by location, date, charger type, and charger. Shows a summary of ports, sessions, and total revenue, broken down by revenue type",
    imageUrl: lineImage,
    route: '/reports/revenue-summary',
    devOnly: false,
    levels: [2, 3],
    roles: [1, 2],
  },
  {
    title: 'Earned Income Payout History',
    contentOne: 'View a detailed report of all earned income payouts.',
    imageUrl: income_twoImage,
    route: '/reports/payouts',
    devOnly: false,
    levels: [1, 2, 3],
    roles: [1, 2],
  },  
  // {
  //   title: strings.incomeLocations,
  //   contentOne: strings.incomeLocationsContentOne,
  //   emContentOne: strings.incomeSummary,
  //   contentTwo: strings.incomeLocationsContentTwo,
  //   imageUrl: income_twoImage
  //   devOnly: false,
  // }
];

export const customIncentiveReportsData = [
  {
    title: strings.NYSERDAenergyUse,
    contentOne: strings.NYSERDAenergyUseContent,
    imageUrl: session_detailsImage,
    route: '/reports/NYSERDA',
    devOnly: false,
    levels: [1, 2, 3],
    roles: [1, 2],
  },  
  {
    title: strings.NYSERDAChargerStatus,
    contentOne: strings.NYSERDAChargerStatusContent,
    imageUrl: session_detailsImage,
    route: '/reports/NYSERDAChargerStatus',
    devOnly: false,
    levels: [1, 2, 3],
    roles: [1, 2],
  },
]

export const miscReportsData = [
  {
    title: strings.miscDriver,
    contentOne: strings.miscDriverContentOne,
    emContentOne: strings.driverSummary,
    contentTwo: strings.miscDriverContentTwo,
    imageUrl: lineImage,
    devOnly: false,
    levels: [1, 2, 3],
    roles: [1, 2],
  },
  {
    title: strings.miscMemberships,
    contentOne: strings.miscMembershipsContent,
    imageUrl: session_chartImage,
    devOnly: false,
    levels: [1, 2, 3],
    roles: [1, 2],
  },
];

export const systemReportsData = [
  {
    title: strings.systemCommunication,
    contentOne: strings.systemCommunicationContent,
    imageUrl: session_detailsImage,
    devOnly: false,
    levels: [1, 2, 3],
    roles: [1, 2],
  },
  {
    title: strings.systemPriceSx,
    contentOne: strings.systemPriceSxContent,
    imageUrl: session_detailsImage,
    devOnly: false,
    levels: [1, 2, 3],
    roles: [1, 2],
  },
  {
    title: strings.systemChargerStatus,
    contentOne: strings.systemChargerStatusContent,
    imageUrl: session_detailsImage,
    devOnly: false,
    levels: [1, 2, 3],
    roles: [1, 2],
  },
  {
    title: strings.systemChargingProfiles,
    contentOne: strings.systemChargingProfilesContent,
    imageUrl: session_detailsImage,
    devOnly: false,
    levels: [1, 2, 3],
    roles: [1, 2],
  },
];
