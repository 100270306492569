import React from 'react';

//services
import AppChargingProfilesService from '../AppChargingProfilesService';

//styles
import {
  FormStyles,
  InputStyles,
  TextareaStyles,
  FormContainerStyles,
} from '../../../SharedStyles';

const FormHeaderComponent = (props) => {
  const passData = (e) => {
    props.handleChange([e.target.name], e.target.value);
  };

  const { strings } = AppChargingProfilesService;

  return (
    <FormContainerStyles padding='10px' borderBottom>
      <FormStyles
        display='block'
        width='100%'
        id='myForm'
        onSubmit={props.handleSubmit}>
        <InputStyles
          required
          type='text'
          name='name'
          onChange={passData}
          value={props.profile.name || ''}
          placeholder={strings.placeholderName}
        />
        <TextareaStyles
          rows={
            !props.profile.description
              ? '1'
              : props.profile.description &&
                props.profile.description.length < 100 &&
                '1'
          }
          required
          placeholder={strings.placeholderDesc}
          type='text'
          name='description'
          value={props.profile.description || ''}
          onChange={passData}
        />
      </FormStyles>
    </FormContainerStyles>
  );
};

export default FormHeaderComponent;
