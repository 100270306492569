import React from 'react';

//service
import AppSystemUsersService from '../../AppSystemUsersService';

//helpers
import states from '../../../../shared/Address/states';

//styles
import {
  FormContainerStyles,
  FormRowStyles,
  InputStyles,
  SelectStyles,
  TitleStyles,
} from '../../../../SharedStyles';

const Location = (props) => {
  const { strings } = AppSystemUsersService;
  const { location, handleLocationChange } = props;
  return (
    <FormContainerStyles padding='10px 10px 15px'>
      <TitleStyles size='14px' weight='600'>
        {strings.locationInfo}
      </TitleStyles>

      <FormRowStyles>
        <InputStyles
          flexWidth='50%'
          margin='0'
          type='text'
          maxLength='256'
          name='address1'
          placeholder={strings.address1Placeholder}
          value={location.address1 || ''}
          onChange={handleLocationChange}
          required
          disabled={props.pendingUser}
        />
      </FormRowStyles>
      <FormRowStyles>
        <InputStyles
          flexWidth='50%'
          margin='0'
          type='text'
          maxLength='256'
          name='address2'
          placeholder={strings.address2Placeholder}
          value={location.address2 || ''}
          onChange={handleLocationChange}
          disabled={props.pendingUser}
        />
      </FormRowStyles>
      <FormRowStyles>
        <InputStyles
          flexWidth='50%'
          margin='0 10px 0 0'
          type='text'
          maxLength='256'
          name='city'
          placeholder={strings.cityPlaceholder}
          value={location.city || ''}
          onChange={handleLocationChange}
          required
          disabled={props.pendingUser}
        />
        <SelectStyles
          flexWidth='50%'
          margin='0 0 0 10px'
          name='state'
          required
          onChange={handleLocationChange}
          value={location.state}
          disabled={props.pendingUser}>
          {states &&
            states.filter(x=>x.country===location.country).map((h, i) => (
              <option key={i} value={h.abbreviation}>
                {h.name}
              </option>
            ))}
        </SelectStyles>
      </FormRowStyles>
      <FormRowStyles>
        <InputStyles
          flexWidth='50%'
          margin='0 10px  0 0'
          type='text'
          maxLength='256'
          name='zip'
          placeholder={strings.zipPlaceholder}
          value={location.zip || ''}
          onChange={handleLocationChange}
          required
          disabled={props.pendingUser}
        />
        <SelectStyles
          flexWidth='50%'
          margin='0 0 0 10px'
          name='country'
          onChange={handleLocationChange}
          required
          value={location.country}
          disabled={props.pendingUser}>
          <option disabled>{strings.countryPlaceholder}</option>
          <option>United States</option>
          <option>Canada</option>
        </SelectStyles>
      </FormRowStyles>
    </FormContainerStyles>
  );
};

export default Location;
