import { useState, useEffect } from 'react';
import moment from 'moment';

export const useHours = () => {
  const [values, setValues] = useState([]);

  useEffect(() => {
    let totalHours = [];
    for (let hour = 0; hour < 24; hour++) {
      totalHours.push({
        display: moment({ hour }).format('h:mm A'),
        time: hour < 10 ? `0${hour}:00` : `${hour}:00`,
      });
      totalHours.push({
        display: moment({
          hour,
          minute: 30,
        }).format('h:mm A'),
        time: hour < 10 ? `0${hour}:30` : `${hour}:30`,
      });
      if (hour === 23) {
        totalHours.push({
          display: moment({
            hour,
            minute: 59,
          }).format('h:mm A'),
          time: `${hour}:59`,
        });
      }
    }
    setValues(totalHours);
  }, []);

  return [values];
};
