import styled from 'styled-components';

export const HeaderContainerStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #e7e7e7;
  padding: ${(props) => props.padding};
  color: #28456a;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

export const HeaderLeftStyles = styled.div`
  display: ${({ display }) => display};
  flex-wrap: wrap;
  align-items: center;
  text-align: left;
  .Title {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    svg {
      font-weight: 400;
      color: #8999ae;
      font-size: 17px;
      margin: 0 0 0 10px;
      cursor: pointer;
    }
  }
  .SubTitle {
    font-size: 12px;
    margin: 0;
  }
`;
export const HeaderRightStyles = styled.div`
  margin: 0 5px 0 0;
  display: flex;
  svg {
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;
    color: #8999ae;
    margin: 0 5px;
  }
`;
