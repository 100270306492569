export const defaultMediaQuery = '575px';
export const defaultOrange = '#ff8f45';
export const defaultHoverOrange = '#ff7317';
export const defaultGreyBlue = '#8999ae';
export const defaultHoverGreyBlue = '#617793';

export const defaultSliderColor = '#ccc';
export const driverToggleColor = '#617793';
export const driverToggleOffColor = '#fff';
export const driverSliderColor = '#ff8f45';
export const csoToggleColor = '#617793';
export const csoToggleOffColor = '#fff';
export const csoSliderColor = '#c3d6f0';
export const sliderShadowColor = '#28456a';
