import React, { useState, useEffect } from 'react';

//services
import AppDashboardService from '../../AppDashboardService';

//styles
import { DivStyles, TitleStyles, TextStyles } from '../../../../SharedStyles';

const ChargerGeneral = (props) => {
  const { strings } = AppDashboardService;
  const { account, loading, allChargers, sessions } = props;
  const [totalMG, setTotalMG] = useState(0); 
  const [totalPorts, setTotalPorts] = useState(0);
  const [totalGeneral, setTotalGeneral] = useState({
    totalkWh: 0.0,
		totalCost: 0.0,
		totalIdle: 0.0,
  });

  useEffect(() => {
    let total = 0;
    account.locations.forEach((item) => {
      total += item.meterGroups.length;
    });
    setTotalMG(total);
  }, [account]);

  useEffect(() => {
    let total = 0;
    allChargers.forEach((item) => {
      total += item.connectorsStatus.length;     
    });
    setTotalPorts(total);
    fetchingGeneral();
  }, [allChargers]);

  const fetchingGeneral = async () => {
    const general = await AppDashboardService.getCSODashboardGeneral();
    setTotalGeneral(general);
  };


  return (
    <DivStyles
      width='100%'
      margin='0'
      wrapMobile
      flex='1'
      borderRight='1px solid #e7e7e7'
      borderLeft='1px solid #e7e7e7'
      borderTop='1px solid #e7e7e7'
      borderBottom='1px solid #e7e7e7'>
      <TitleStyles
        size='14px'
        align='left'
        padding='5px'
        margin='0'
        borderBottom
        weight='400'>
        {strings.chargerGeneralTitle}
      </TitleStyles>

      <DivStyles padding='10% 10%'>
        {loading ? (
          <p>Loading data...</p>
        ) : (
          <>
            <DivStyles display='flex'>
              <TextStyles flex='1' weight='600' margin='0 10px 10px 0'>
                {strings.totalChargers}
              </TextStyles>
              <TextStyles flex='1' margin='0 0 10px'>
                {allChargers.length}
              </TextStyles>
            </DivStyles>
            <DivStyles display='flex'>
              <TextStyles flex='1' weight='600' margin='0 10px 10px 0'>
                Total Ports:
              </TextStyles>
              <TextStyles flex='1' margin='0 0 10px'>
                {totalPorts}
              </TextStyles>
            </DivStyles>
            <DivStyles display='flex'>
              <TextStyles flex='1' weight='600' margin='0 10px 10px 0'>
                {strings.totalMG}
              </TextStyles>
              <TextStyles flex='1' margin='0 0 10px'>
                {totalMG}
              </TextStyles>
            </DivStyles>
            <DivStyles display='flex'>
              <TextStyles flex='1' weight='600' margin='0 10px 10px 0'>
                {strings.totalLocations}
              </TextStyles>
              <TextStyles flex='1' margin='0 0 10px'>
                {account.locations.length}
              </TextStyles>
            </DivStyles>
            <DivStyles display='flex'>
              <TextStyles flex='1' weight='600' margin='0 10px 10px 0'>
                {strings.totalChargingSessions}
              </TextStyles>
              <TextStyles flex='1' margin='0 0 10px'>
                {sessions.length}
              </TextStyles>
            </DivStyles>
            <DivStyles display='flex'>
              <TextStyles flex='1' weight='600' margin='0 10px 10px 0'>
              {strings.totalEnergy}
              </TextStyles>
              <TextStyles flex='1' margin='0 0 10px'>
                {totalGeneral.totalkWh.toFixed(2)}
              </TextStyles>
            </DivStyles>            
            <DivStyles display='flex'>
              <TextStyles flex='1' weight='600' margin='0 10px 10px 0'>
              {strings.totalIncome}
              </TextStyles>
              <TextStyles flex='1' margin='0 0 10px'>
                {(totalGeneral.totalCost+totalGeneral.totalIdle).toFixed(2)}
              </TextStyles>
            </DivStyles>
            {/* <DivStyles display='flex'>
              <TextStyles flex='1' weight='600' margin='0 10px 10px 0'>
                {strings.totalPaidSessions}
              </TextStyles>
              <TextStyles flex='1' margin='0 0 10px'>
                193
              </TextStyles>
            </DivStyles>
            <DivStyles display='flex'>
              <TextStyles flex='1' weight='600' margin='0 10px 10px 0'>
                {strings.totalUnPaidSessions}
              </TextStyles>
              <TextStyles flex='1' margin='0 0 10px'>
                25
              </TextStyles>
            </DivStyles>
            <DivStyles display='flex'>
              <TextStyles flex='1' weight='600' margin='0 10px 10px 0'>
                {strings.totalMembers}
              </TextStyles>
              <TextStyles flex='1' margin='0 0 10px'>
                14
              </TextStyles>
            </DivStyles>
            <DivStyles display='flex'>
              <TextStyles flex='1' weight='600' margin='0 10px 10px 0'>
                {strings.reservedChargers}
              </TextStyles>
              <TextStyles flex='1' margin='0 0 10px'>
                3
              </TextStyles>
            </DivStyles> */}
          </>
        )}
      </DivStyles>
    </DivStyles>
  );
};

export default ChargerGeneral;
