import React, { useState, useContext, useEffect, useRef } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { FaTrashAlt } from 'react-icons/fa';

//services
import AppBankService from '../AppBankService';

//components
import MicroCheck from '../BankWizardForms/MicroCheck';
import SingleBankForm from '../SingleForms/SingleReceiptForm';
import SinglePaymentForm from '../SingleForms/SinglePaymentForm';

//global components
import Button from '../../../shared/Buttons/Button';
import { LoadingSpinner } from '../../../shared';

//hooks
import { useWindowResize } from '../../../shared/hooks/useWindowSize';
import { useContainerHeight } from '../../../shared/hooks/useContainerHeight';

//styles
import {
  DivStyles,
  TextStyles,
  ButtonStyles,
  TableItemStyles,
  TableHeaderStyles,
  TableRowStyles,
  TableStyles,
  TitleStyles,
} from '../../../SharedStyles';

//helpers
import { STRIPE_API } from '../../../shared';

//context
import { ErrorContext } from '../../../context/shared/ErrorContext';

const BankPortal = (props) => {
  const { strings } = AppBankService;
  const { setError } = useContext(ErrorContext);
  const [payoutBank, setPayoutBank] = useState([]);
  const [banks, setBanks] = useState([]);
  const [cards, setCards] = useState([]);
  const [microCheck, setMicroCheck] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showReceiptForm, setShowReceiptForm] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [bankNeedsVerification, setBankNeedsVerification] = useState(false);

  const CardRef = useRef();
  const OutsideRef = useRef();
  const size = useWindowResize(window.innerWidth, window.innerHeight);
  const { containerHeight, setContainerRef } = useContainerHeight(55, CardRef);

  //gets card container height for responsive
  useEffect(() => {
    setContainerRef(CardRef);
  }, [CardRef, setContainerRef]);

  const toggleForm = (form) => {
    if (form === 'receipt') {
      setShowReceiptForm(!showReceiptForm);
    }
    if ((form = 'payment')) {
      setShowPaymentForm(!showPaymentForm);
    }
  };

  useEffect(() => {
    if (props.customerData) {
      let payoutAccounts = props.customerData.payout.filter((p) => {
        p.default = false;
        if (p.id === props.connectAccount.csoStripeDefaultPayoutMethodId) {
          p.default = true;
        }
        return p.object === 'bank_account';
      });
      payoutAccounts.sort((a, b) => b.default - a.default);
      setPayoutBank(payoutAccounts);
      let bankAccounts = props.customerData.customer.filter((c) => {
        c.default = false;
        if (c.id === props.connectAccount.csoStripeDefaultPaymentMethodId) {
          c.default_for_currency = true;
        }
        return c.object === 'bank_account';
      });
      let notVerified = bankAccounts.every((b) => {
        return b.status !== 'verified';
      });
      if (notVerified) {
        setBankNeedsVerification(true);
      } else {
        setBankNeedsVerification(false);
      }
      setBanks(bankAccounts);
      let cardAccounts = props.customerData.customer.filter((c) => {
        c.default = false;
        if (c.id === props.connectAccount.csoStripeDefaultPaymentMethodId) {
          c.default = true;
        }
        return c.object === 'card';
      });
      setCards(cardAccounts);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [
    props.customerData,
    props.connectAccount.csoStripeDefaultPayoutMethodId,
    props.connectAccount.csoStripeDefaultPaymentMethodId,
  ]);

  const handleMicroCheck = () => {
    setMicroCheck(!microCheck);
  };

  const deleteBank = async (type, id) => {
    setLoading(true);
    if (type === 'payout') {
      await AppBankService.deletePayoutInfo(id);
      props.fetchStripeData();
      setLoading(false);
    }
    if (type === 'payment') {
      await AppBankService.deleteCSOPaymentInfo(id);
      props.fetchStripeData();
      setLoading(false);
    }
  };

  const updatePaymentDefault = async (id) => {
    setLoading(true);
    try {
      await AppBankService.setDefaultPayment(id);

      props.fetchStripeData();
      props.fetchStripeAccount();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.response.data);
      setError({
        display: true,
        title: 'Something went wrong',
        message: err.response.data.split(/[|]+/).pop(),
        styles: 'cso',
      });
    }
  };

  const updatePayoutDefault = async (id) => {
    try {
      setLoading(true);
      await AppBankService.setDefaultPayout(id);

      props.fetchStripeData();
      props.fetchStripeAccount();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.response.data);
      setError({
        display: true,
        title: 'Something went wrong',
        message: err.response.data.split(/[|]+/).pop(),
        styles: 'cso',
      });
    }
  };

  if (showReceiptForm) {
    return (
      <StripeProvider apiKey={STRIPE_API}>
        <Elements>
          <SingleBankForm
            fetchStripeAccount={props.fetchStripeAccount}
            fetchStripeData={props.fetchStripeData}
            handleCloseWizard={() => toggleForm('receipt')}
            {...props}
          />
        </Elements>
      </StripeProvider>
    );
  }

  if (showPaymentForm) {
    return (
      <StripeProvider apiKey={STRIPE_API}>
        <Elements>
          <SinglePaymentForm
            handleCloseWizard={() => toggleForm('payment')}
            {...props}
          />
        </Elements>
      </StripeProvider>
    );
  }

  return (
    <DivStyles padding='0 15px' margin='10px auto' ref={OutsideRef}>
      {loading && <LoadingSpinner />}
      {/* Income Section */}
      <DivStyles margin='0 0 20px'>
        <TitleStyles padding='0 0 5px' borderBottom size='22px' align='left'>
          {strings.wizard2Title}
        </TitleStyles>
        <TextStyles size='14px' align='left'>
          {strings.portalReceiptContent}
        </TextStyles>
        <Button
          cso
          margin='10px 0 5px'
          // hasMobile
          onclick={() => toggleForm('receipt')}
          buttonText={strings.receiptButton}
          roles={[1, 2]}
        />
        {payoutBank.length > 0 && (
          <DivStyles>
            <TitleStyles
              size='19px'
              align='left'
              weight='400'
              margin='10px 0 5px'>
              {strings.bankInfo}
            </TitleStyles>
            <TableStyles
              borderRight='1px solid #e7e7e7'
              overflowX='scroll'
              maxHeight={`${containerHeight * 4}px`}>
              <TableHeaderStyles width={size[0] >= 768 ? '100%' : '700px'}>
                <TableItemStyles width='150px' borderRight>
                  {strings.bankName}
                </TableItemStyles>
                <TableItemStyles width='150px' borderRight>
                  {strings.routingNumber}
                </TableItemStyles>
                <TableItemStyles width='150px'>
                  {strings.accountNumber}
                </TableItemStyles>
                <TableItemStyles width='75px' />
                <TableItemStyles width='100px' />
                <TableItemStyles width='50px' />
              </TableHeaderStyles>
              {payoutBank
                .sort((a, b) => b.default - a.default)
                .map((p) => (
                  <TableRowStyles
                    ref={CardRef}
                    width={size[0] >= 768 ? '100%' : '700px'}
                    className={p.default && 'Bold'}
                    key={p.id}>
                    <TableItemStyles width='150px'>
                      {p.bank_name}
                    </TableItemStyles>
                    <TableItemStyles width='150px'>
                      {p.routing_number}
                    </TableItemStyles>
                    <TableItemStyles width='150px'>
                      ****{p.last4}
                    </TableItemStyles>
                    <TableItemStyles width='75px'>
                      {p.default && strings.default}
                    </TableItemStyles>
                    {!p.default ? (
                      <TableItemStyles width='150px'>
                        <ButtonStyles
                          padding='0 10px'
                          margin='0'
                          inverse
                          onClick={() => updatePayoutDefault(p.id)}>
                          {strings.makeDefault}
                        </ButtonStyles>
                      </TableItemStyles>
                    ) : (
                      <TableItemStyles width='150px' />
                    )}
                    {payoutBank.length > 1 && (
                      <TableItemStyles width='50px' justifyContent='flex-end'>
                        <DivStyles
                          flex='1'
                          align='right'
                          svgSize='14px'
                          svgColor='#8999ae'
                          onClick={() => deleteBank('payout', p.id)}>
                          <FaTrashAlt />
                        </DivStyles>
                      </TableItemStyles>
                    )}
                  </TableRowStyles>
                ))}
            </TableStyles>
          </DivStyles>
        )}
      </DivStyles>
      {/* Payments Section */}
      <DivStyles margin='30px 0 20px'>
        <TitleStyles align='left' size='22px' borderBottom padding='0 0 5px'>
          {strings.wizard3Title}
        </TitleStyles>
        <DivStyles>
          <TextStyles size='14px' align='left'>
            {strings.portalPaymentContent}
          </TextStyles>
          <Button
            cso
            margin='10px 0 5px'
            // hasMobile
            onclick={() => toggleForm('payment')}
            buttonText={strings.paymentButton}
            roles={[1, 2]}
          />
          {banks.length > 0 && (
            <DivStyles>
              <TitleStyles
                align='left'
                size='19px'
                margin='10px 0 5px'
                weight='400'>
                {strings.bankInfo}
              </TitleStyles>
              {bankNeedsVerification && (
                <TextStyles
                  size='14px'
                  align='left'
                  background='#ffd60057'
                  padding='5px 5px 10px'>
                  {strings.bankVerify}
                </TextStyles>
              )}
              <TableStyles
                borderRight='1px solid #e7e7e7'
                overflowX='scroll'
                maxHeight={`${containerHeight * 4}px`}>
                <TableHeaderStyles width={size[0] >= 768 ? '100%' : '700px'}>
                  <TableItemStyles width='150px' borderRight>
                    {strings.bankName}
                  </TableItemStyles>
                  <TableItemStyles width='150px' borderRight>
                    {strings.routingNumber}
                  </TableItemStyles>
                  <TableItemStyles width='150px'>
                    {strings.accountNumber}
                  </TableItemStyles>
                  <TableItemStyles width='75px' />
                  <TableItemStyles width='150px' />
                  <TableItemStyles width='50px' />
                </TableHeaderStyles>
                {banks
                  .sort((a, b) => b.default - a.default)
                  .map((b) => (
                    <TableRowStyles
                      background={b.status !== 'verified' && '#ffd60057'}
                      width={size[0] >= 768 ? '100%' : '700px'}
                      className={b.default && 'Bold'}
                      key={b.id}>
                      {!microCheck ? (
                        <>
                          <TableItemStyles width='150px'>
                            {b.bank_name}
                          </TableItemStyles>
                          <TableItemStyles width='150px'>
                            {b.routing_number}
                          </TableItemStyles>
                          <TableItemStyles width='150px'>
                            ****{b.last4}
                          </TableItemStyles>
                          <TableItemStyles width='75px'>
                            {b.default && strings.default}
                          </TableItemStyles>
                          {!b.default && b.status === 'verified' ? (
                            <TableItemStyles width='150px'>
                              <ButtonStyles
                                padding='0 10px'
                                margin='0'
                                inverse
                                onClick={() => updatePaymentDefault(b.id)}>
                                {strings.makeDefault}
                              </ButtonStyles>
                            </TableItemStyles>
                          ) : b.status !== 'verified' ? (
                            <TableItemStyles width='150px'>
                              <ButtonStyles
                                padding='0 10px'
                                margin='0'
                                inverse
                                onClick={handleMicroCheck}>
                                {strings.verify}
                              </ButtonStyles>
                            </TableItemStyles>
                          ) : (
                            <TableItemStyles width='150px' />
                          )}
                          {banks.length > 1 && (
                            <TableItemStyles
                              width='50px'
                              justifyContent='flex-end'>
                              <DivStyles
                                flex='1'
                                align='right'
                                svgSize='14px'
                                svgColor='#8999ae'
                                onClick={() => deleteBank('payment', b.id)}>
                                <FaTrashAlt />
                              </DivStyles>
                            </TableItemStyles>
                          )}
                        </>
                      ) : (
                        <MicroCheck
                          fetchStripeData={props.fetchStripeData}
                          handleMicroCheck={handleMicroCheck}
                          fetchStripeAccount={props.fetchStripeAccount}
                        />
                      )}
                    </TableRowStyles>
                  ))}
              </TableStyles>
            </DivStyles>
          )}
          {cards.length > 0 && (
            <DivStyles>
              <TitleStyles
                size='19px'
                align='left'
                margin='25px 0 5px'
                weight='400'>
                {strings.cardInfo}
              </TitleStyles>
              <TableStyles
                borderRight='1px solid #e7e7e7'
                overflowX='scroll'
                maxHeight={`${containerHeight * 4}px`}>
                <TableHeaderStyles>
                  <TableItemStyles width='150px' borderRight>
                    {strings.brandName}
                  </TableItemStyles>
                  <TableItemStyles width='150px' borderRight>
                    {strings.last4}
                  </TableItemStyles>
                  <TableItemStyles width='150px'>
                    {strings.exps}
                  </TableItemStyles>
                  <TableItemStyles width='75px' />
                  <TableItemStyles width='100px' />
                  <TableItemStyles width='50px' />
                </TableHeaderStyles>
                {cards
                  .sort((a, b) => b.default - a.default)
                  .map((c) => (
                    <TableRowStyles
                      width={size[0] >= 768 ? '100%' : '700px'}
                      className={c.default && 'Bold'}
                      key={c.id}>
                      <TableItemStyles width='150px'>{c.brand}</TableItemStyles>
                      <TableItemStyles width='150px'>
                        ****{c.last4}
                      </TableItemStyles>
                      <TableItemStyles width='150px'>
                        {c.exp_month}/{c.exp_year}
                      </TableItemStyles>
                      <TableItemStyles width='75px'>
                        {c.default && strings.default}
                      </TableItemStyles>
                      {!c.default ? (
                        <TableItemStyles width='150px'>
                          <ButtonStyles
                            padding='0 10px'
                            margin='0'
                            inverse
                            onClick={() => updatePaymentDefault(c.id)}>
                            {strings.makeDefault}
                          </ButtonStyles>
                        </TableItemStyles>
                      ) : (
                        <TableItemStyles width='150px' />
                      )}
                      {cards.length > 1 && (
                        <TableItemStyles width='50px' justifyContent='flex-end'>
                          <DivStyles
                            flex='1'
                            align='right'
                            svgSize='14px'
                            svgColor='#8999ae'
                            onClick={() => deleteBank('payment', c.id)}>
                            <FaTrashAlt />
                          </DivStyles>
                        </TableItemStyles>
                      )}
                    </TableRowStyles>
                  ))}
              </TableStyles>
            </DivStyles>
          )}
        </DivStyles>
      </DivStyles>
    </DivStyles>
  );
};

export default BankPortal;
