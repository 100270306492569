import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppPaymentsService from './AppPaymentsService';

//components
import ModalButton from '../../shared/Buttons/ModalButton';

//styles
import {
  ModalContainerStyles,
  ModalContentStyles,
  ModalHeaderStyles,
  ModalItemStyles,
  ModalStyles,
} from '../../SharedStyles';

const PaymentModal = (props) => {
  const { strings } = AppPaymentsService;

  const closeModal = () => {
    props.closeModal();
  };

  const successCardChange = () => {
    props.closeModal();
    if (!props.success) {
      props.handlesuccess();
    }
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{props.title}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>{props.content}</ModalItemStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={successCardChange}
          buttonText={props.success ? strings.ok : strings.yes}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default PaymentModal;
