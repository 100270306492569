import React from 'react';

//layout
import { InteriorWrapper, ComponentWrapper } from '../../driver/shared';

//styles
import { DivStyles, TextStyles, LIStyles } from '../../SharedStyles';

const DriverTerms = (props) => {
  const { strings } = props;

  return (
    <InteriorWrapper title={strings.pageTitle} isInternal path='/charging'>
      <ComponentWrapper title={strings.pageTitle}>
        <DivStyles size='14px' padding='10px'>
          <TextStyles margin='0 0 10px'>{strings.ampedupNetworks}</TextStyles>
          <TextStyles margin='0 0 10px'>{strings.effectiveDate}</TextStyles>
          <TextStyles margin='0 0 10px'>{strings.paragraphOne}</TextStyles>
          {/* General */}
          <TextStyles margin='0 0 10px'>
            <span className='bold'>{strings.generalTitle}</span>{' '}
            {strings.generalParagraphOne}
          </TextStyles>
          <TextStyles margin='0 0 10px'>
            {strings.generalParagraphTwo}
          </TextStyles>
          {/* You Agree to */}
          <TextStyles margin='0 0 10px'>
            <span className='bold'>{strings.youAgreeTitle}</span>
          </TextStyles>
          <ul>
            <LIStyles>{strings.youAgreeLiOne}</LIStyles>
            <LIStyles>{strings.youAgreeLiTwo}</LIStyles>
            <LIStyles>{strings.youAgreeLiThree}</LIStyles>
            <LIStyles>{strings.youAgreeLiFour}</LIStyles>
            <LIStyles>{strings.youAgreeLiFive}</LIStyles>
          </ul>
          {/* AmpedUp! Networks Flex Billing */}
          <TextStyles margin='0 0 10px'>
            <span className='bold'>{strings.flexBillingTitle}</span>{' '}
            {strings.flexBillingParagraphOne}
          </TextStyles>
          {/* Minimum Account Balances, Fees and Charges */}
          <TextStyles margin='0 0 10px'>
            <span className='bold'>{strings.minAccountBalanceTitle}</span>
          </TextStyles>
          <TextStyles margin='0 0 10px'>
            {strings.minAccountBalanceOne}
          </TextStyles>
          <TextStyles margin='0 0 10px'>
            {strings.minAccountBalanceTwo}
          </TextStyles>
          <TextStyles margin='0 0 10px'>
            {strings.minAccountBalanceThree}
          </TextStyles>
          <TextStyles margin='0 0 10px'>
            {strings.minAccountBalanceFour}
          </TextStyles>
          {/* AmpedUp! Networks RFID Cards */}
          <TextStyles margin='0 0 10px'>
            <span className='bold'>{strings.rfidCardTitle}</span>
          </TextStyles>
          <TextStyles margin='0 0 10px'>
            {strings.rfidCardParagraphOne}
          </TextStyles>
          <TextStyles margin='0 0 10px'>
            {strings.rfidCardParagraphTwo}
          </TextStyles>
          <TextStyles margin='0 0 10px'>
            {strings.rfidCardParagraphThree}
          </TextStyles>
          {/* Using the AmpedUp! Networks Website or Mobile Applications */}
          <TextStyles margin='0 0 10px'>
            <span className='bold'>{strings.websiteUsageTitle}</span>{' '}
            {strings.websiteUsageParagraphOne}
          </TextStyles>
          {/* Offers from AmpedUp! Networks Partners */}
          <TextStyles margin='0 0 10px'>
            <span className='bold'>{strings.partnerOffersTitle}</span>{' '}
            {strings.partnerOffersParagraphOne}
          </TextStyles>
          {/* Termination */}
          <TextStyles margin='0 0 10px'>
            <span className='bold'>{strings.terminationTitle}</span>{' '}
            {strings.terminationParagraphOne}
          </TextStyles>
          {/* Changes */}
          <TextStyles margin='0 0 10px'>
            <span className='bold'>{strings.changesTitle}</span>{' '}
            {strings.changesParagraphOne}
          </TextStyles>
          <TextStyles margin='0 0 10px'>
            {strings.changesParagraphTwo}
          </TextStyles>
          {/* Indemnity */}
          <TextStyles margin='0 0 10px'>
            <span className='bold'>{strings.indemnityTitle}</span>{' '}
            {strings.indemnityParagraphOne}
          </TextStyles>
          <TextStyles margin='0 0 10px'>
            {strings.indemnityParagraphTwo}
          </TextStyles>
          <TextStyles margin='0 0 10px'>
            {strings.indemnityParagraphThree}
          </TextStyles>
          <TextStyles margin='0 0 10px'>
            {strings.indemnityParagraphFour}
          </TextStyles>
          {/* Governing Law; Arbitration */}
          <TextStyles margin='0 0 10px'>
            <span className='bold'>{strings.governingLawTitle}</span>{' '}
            {strings.governingLawOne}
          </TextStyles>
          {/* Communications */}
          <TextStyles margin='0 0 10px'>
            <span className='bold'>{strings.communcationTitle}</span>{' '}
            {strings.communcationOne}
          </TextStyles>
        </DivStyles>
      </ComponentWrapper>
    </InteriorWrapper>
  );
};

export default DriverTerms;
