import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import strings from './strings';
import AppApiService from '../../../services/AppApi/AppApiService';
import AppImagingService from '../../../services/AppImaging/AppImagingService';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppCSOSystemUsersService = {
  getSystemUsers: function() {
    return AppApiService.get('getSystemUsers');
  },
  deleteSystemUser: function(id) {
    return AppApiService.post('deleteSystemUser', null, id);
  },
  updateSystemUser: function(user) {
    return AppApiService.post('updateSystemUser', null, user);
  },
  createSystemUserToken: function(user) {
    return AppApiService.post('csoCreateSystemUserToken', null, user);
  },
  resendSystemUserToken: function(tokenUserId) {
    return AppApiService.post('csoResendSystemUserToken', null, tokenUserId);
  },
  csoDeleteSystemUser: function(id) {
    return AppApiService.post('csoDeleteSystemUser', null, id);
  },
  csoDeleteTokenSystemUser: function(id) {
    return AppApiService.post('csoDeleteTokenSystemUser', null, id);
  },
  updateCSOAccount: function(account) {
    return AppApiService.post('csoAccountUpdate', null, account);
  },
  prepareImage: function(image) {
    return AppImagingService.getBlobForDataUrl(image);
  },
  uploadImage: function(imageBlob) {
    return AppApiService.post('uploadImage', null, imageBlob);
  },
  strings: localStrings
};

export default AppCSOSystemUsersService;
