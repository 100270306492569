import React from 'react';

//styles
import {
  FormContainerStyles,
  FormRowStyles,
  InputStyles,
  SelectStyles,
} from '../../../../SharedStyles';

//helpers
import states from '../../../../shared/Address/states';

const LocationAddress = React.forwardRef((props, ref) => {
  const { strings, location, handleDataChange, currentUser } = props;
  const {
    requiredAddressRef,
    requiredStateRef,
    requiredCityRef,
    requiredZipRef,
    requiredCountryRef,
  } = ref;

  const handleChange = (e) => {
    handleDataChange(e.target.name, e.target.value);
  };

  return (
    <FormContainerStyles padding='10px 0 15px' borderBottom>
      <FormRowStyles>
        <InputStyles
          flexWidth='100%'
          margin='0 10px'
          type='text'
          onChange={handleChange}
          name='address1'
          placeholder={strings.locationAddressPlaceholder}
          value={location.address1 || ''}
          required
          ref={requiredAddressRef}
          disabled={props.canEdit && !props.canEdit.includes(currentUser.role)}
        />
      </FormRowStyles>
      <FormRowStyles>
        <InputStyles
          flexWidth='100%'
          margin='0 10px'
          type='text'
          onChange={handleChange}
          name='address2'
          placeholder={strings.locationAddress2Placeholder}
          value={location.address2 || ''}
          disabled={props.canEdit && !props.canEdit.includes(currentUser.role)}
        />
      </FormRowStyles>
      <FormRowStyles>
        <InputStyles
          flexWidth='33%'
          margin='0 10px'
          type='text'
          onChange={handleChange}
          name='city'
          placeholder={strings.locationCityPlaceholder}
          value={location.city || ''}
          required
          ref={requiredCityRef}
          disabled={props.canEdit && !props.canEdit.includes(currentUser.role)}
        />
        <SelectStyles
          flexWidth='33%'
          margin='0 10px'
          name='state'
          required
          ref={requiredStateRef}
          className='dropdown'
          id='stateSelection'
          onChange={handleChange}
          value={location.state || ''}
          disabled={props.canEdit && !props.canEdit.includes(currentUser.role)}>
          <option disabled value=''>
            {strings.locationStatePlaceholder}
          </option>
          {states &&
            states.filter(x=>x.country===location.country).map((h, i) => (
              <option key={i} value={h.abbreviation}>
                {h.name}
              </option>
            ))}
        </SelectStyles>
        <InputStyles
          flexWidth='33%'
          margin='0 10px'
          type='number'
          onChange={handleChange}
          required
          ref={requiredZipRef}
          name='zip'
          placeholder={strings.locationZipPlaceholder}
          value={location.zip || ''}
          disabled={props.canEdit && !props.canEdit.includes(currentUser.role)}
        />
      </FormRowStyles>
      <FormRowStyles>
        <SelectStyles
          flexWidth='33%'
          margin='0 10px'
          name='country'
          required
          ref={requiredCountryRef}
          className='dropdown'
          id='countrySelection'
          onChange={handleChange}
          value={location.country || ''}
          disabled={props.canEdit && !props.canEdit.includes(currentUser.role)}>
          <option disabled value=''>
            {strings.locationCountryPlaceholder}
          </option>
          <option value='United States'>United States</option>
          <option value='Canada'>Canada</option>
        </SelectStyles>
        <InputStyles noBorder flexWidth='33%' margin='0 10px' type='empty' />
        <InputStyles noBorder flexWidth='33%' margin='0 10px' type='empty' />
      </FormRowStyles>
    </FormContainerStyles>
  );
});

export default LocationAddress;
