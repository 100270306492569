import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//service
import AccessCardsService from '../../AccessCardsService';

//modal components & styles
import ModalButton from '../../../../shared/Buttons/ModalButton';
import {
  ModalContainerStyles,
  ModalContentStyles,
  ModalHeaderStyles,
  ModalItemStyles,
  ModalStyles,
} from '../../../../SharedStyles';

const DeactivateModal = ({ toggleModal, handleDisableCard }) => {
  const { strings } = AccessCardsService;

  const handleUpdate = () => {
    handleDisableCard();
    toggleModal();
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.deactivateTitle}</h2>
          <FaRegWindowClose className='Close' onClick={toggleModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          <ModalItemStyles>{strings.deactivateCard}</ModalItemStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={handleUpdate}
          buttonText={strings.ok}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default DeactivateModal;
