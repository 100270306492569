import React, { useEffect, useState, useContext } from 'react';

//services
import AppPropsService from './AppPropsService';

//components
import ChargerCommentCard from './ChargerCommentCard';

//context
import { ErrorContext } from '../../../../context/shared/ErrorContext';

//styles
import {
  FormContainerStyles,
  TitleStyles,
  ListPlaceholderStyles,
  DivStyles,
} from '../../../../SharedStyles';

const ChargerComments = (props) => {
  const { strings } = AppPropsService;
  const { setError } = useContext(ErrorContext);
  const [comments, setComments] = useState([]);

  const compare = (a, b) => {
    return a.approved - b.approved;
  };

  useEffect(() => {
    let sorted = props.charger.comments.sort(compare);
    setComments(sorted);
  }, [props.charger]);

  const deleteComment = async (commentData) => {
    try {
      await AppPropsService.deleteDriverComment(commentData);
      let updatedComments = comments.filter((comment) => {
        return comment.id !== commentData.commentId;
      });
      setComments(updatedComments);
    } catch (err) {
      setError({
        display: true,
        title: 'Failed to Delete',
        message: err.response.data.split(/[|]+/).pop(),
      });
    }
  };

  const approveComment = async (commentData) => {
    try {
      let approvedComment = await AppPropsService.approveDriverComment(
        commentData
      );
      if (approvedComment) {
        let updatedComments = comments.map((comment) => {
          if (comment.id === commentData.commentId) {
            comment.approved = true;
          }
          return comment;
        });
        setComments(updatedComments);
      }
    } catch (err) {
      setError({
        display: true,
        title: 'Failed to Approve',
        message: err.response.data.split(/[|]+/).pop(),
      });
    }
  };

  return (
    <>
      <FormContainerStyles display='block' padding='0'>
        <TitleStyles size='14px' padding='10px' margin='0' weight='600'>
          {strings.commentsHeader} ({props.charger.comments.length})
        </TitleStyles>
        <DivStyles display='block' maxHeight='400px'>
          {comments.map((comment) => (
            <ChargerCommentCard
              key={comment.id}
              deleteComment={deleteComment}
              approveComment={approveComment}
              iot={props.charger.iotHubDeviceId}
              comment={comment}
            />
          ))}
        </DivStyles>
        {props.charger.comments.length === 0 && (
          <ListPlaceholderStyles borderBottom='none'>
            <p>{strings.noComments}</p>
          </ListPlaceholderStyles>
        )}
      </FormContainerStyles>
    </>
  );
};

export default ChargerComments;
