import React, { useState } from 'react';
import { FaCloudUploadAlt } from 'react-icons/fa';

//services
import AppChargersService from '../../AppChargersService';

//permissions
import RoleCanEdit from '../../../OperatorShared/RoleCanEdit';
import SubscriptionAccess from '../../../OperatorShared/SubscriptionAccess';

//global components
import PropertyContainer from '../../../../shared/PropertyContainer/PropertyContainer';
import ConnectorImages from '../../../../shared/ConnectorImages/ConnectorImages';

//modals
import MeterGroupModal from './EditModals/MeterGroupModal';
import CircuitModal from './EditModals/CircuitModal';
import MorePropsModal from './EditModals/MorePropsModal';
import IpAddress from './EditModals/IpAddress';
import Firmware from './EditModals/Firmware';

//styles
import {
  PropertiesContentContainerStyles,
  PropertiesCardStyles,
  PropertiesHeaderContainerStyles,
  TitleStyles,
  ButtonDivGreyStyles,
} from '../../../../SharedStyles';

//enable once values come in as enum
import { phaseChargerTypeConvertor } from '../../../helpers';

const ChargerProperties = (props) => {
  const { chargerDetails, meterGroup, circuit, connectorCircuits } = props;
  const { strings } = AppChargersService;
  const [modalsDisplay, setModalsDisplay] = useState({
    meterGroup: false,
    circuit: false,
    moreProps: false,
    ipAddress: false,
    firmware: false,
  });

  const toggleDisplayModal = (modalType) => {
    let updatedValues = Object.keys(modalsDisplay).reduce(
      (reduced, key) => ({
        ...reduced,
        [key]: false,
        [modalType]: true,
      }),
      {}
    );
    setModalsDisplay(updatedValues);
  };

  const closeAllDisplayModal = () => {
    let updatedValues = Object.keys(modalsDisplay).reduce(
      (reduced, key) => ({
        ...reduced,
        [key]: false,
      }),
      {}
    );
    setModalsDisplay(updatedValues);
  };

  const circuitContent = (circuit) => {
    return (circuit?.name
        ? `${circuit.name} (${circuit.maxAmperage}a / ${circuit.chargingStationConnectorIds.reduce((a, b) => a + b?.connectorIds?.length, 0)} port${circuit.chargingStationConnectorIds.reduce((a, b) => a + b?.connectorIds?.length, 0) > 1 ? 's' : ''})`
        : '--');
  };

  const circuitsContent = (connectorCircuits) => {
    let arr = [];
    if (connectorCircuits) {
      connectorCircuits.forEach((cnnCirc) => {
        const cur = circuitContent(cnnCirc.circuit);
        const item = arr.find((x) => x.title === cur);

        if (cur !== '--') {
          if (item) {
            item.ports.push(cnnCirc.connectorId);
          }
          else {
            arr.push({title: cur, ports: [cnnCirc.connectorId]});
          }
        }
      });
    }

    if (arr.length === 0) {
      return '--';
    }

    let ret = '';
    arr.forEach((x) => {
      if (ret.length > 0){
        ret += '; ';
      }
      ret += `${x.title} Port${x.ports.indexOf(',') > 0 ? 's' : ''}:  ${x.ports}`;
    });
    return ret;
  };

  return (
    <>
      {modalsDisplay.meterGroup && (
        <MeterGroupModal
          handleModalClose={closeAllDisplayModal}
          handleDataChange={props.handleDataChange}
          charger={props.chargerDetails}
          meterGroup={props.meterGroup}
          updateMeterGroup={props.updateMeterGroup}
          updateCircuit={props.updateCircuit}
          location={props.location}
          updateConnectorCircuits={props.updateConnectorCircuits}
        />
      )}
      {modalsDisplay.circuit && (
        <CircuitModal
          handleModalClose={closeAllDisplayModal}
          handleDataChange={props.handleDataChange}
          handleConnectorDataChange={props.handleConnectorDataChange}
          circuit={props.circuit}
          connectorCircuits={props.connectorCircuits}
          meterGroup={props.meterGroup}
          charger={props.chargerDetails}
          updateCircuit={props.updateCircuit}
          updateConnectorCircuits={props.updateConnectorCircuits}
        />
      )}

      {modalsDisplay.moreProps && (
        <MorePropsModal
          handleModalClose={closeAllDisplayModal}
          charger={props.chargerDetails}
        />
      )}
      {modalsDisplay.ipAddress && (
        <IpAddress
          handleModalClose={closeAllDisplayModal}
          chargerDetails={props.chargerDetails}
        />
      )}
      {modalsDisplay.firmware && (
        <Firmware
          chargerDetails={props.chargerDetails}
          handleModalClose={closeAllDisplayModal}
          firmwareUpdates={props.firmwareUpdates}
        />
      )}
      <PropertiesCardStyles borderBottom='1px solid #e7e7e7'>
        <PropertiesHeaderContainerStyles>
          <TitleStyles size='14px' weight='600' flex='1' margin='0'>
            {strings.chargerProperties}
          </TitleStyles>
        </PropertiesHeaderContainerStyles>
        <PropertiesContentContainerStyles>
          <PropertyContainer
            title={strings.chargerMake}
            content={chargerDetails.chargePointVendor}
          />
          <PropertyContainer
            title={strings.chargerModel}
            content={chargerDetails.chargePointModel}
          />
          <PropertyContainer
            title={strings.chargerId}
            content={chargerDetails.iotHubDeviceId}
          />
          <PropertyContainer
            title={strings.serialNo}
            content={chargerDetails.chargePointSerialNumber}
          />
          <PropertyContainer
            title={strings.volts}
            content={chargerDetails.voltage}
          />
          <PropertyContainer
            title={strings.maxAmps}
            content={chargerDetails.maxCurrent}
          />
          <PropertyContainer
            title={strings.currentAmps}
            content={
              !chargerDetails.currentAmperage
                ? '0.00'
                : chargerDetails.currentAmperage.toFixed(2)
            }
          />
          <PropertyContainer
            title={strings.phaseType}
            content={
              Object.keys(chargerDetails).length > 0 &&
              chargerDetails.phaseType &&
              chargerDetails.phaseType.map((type, index) => {
                return (
                  <span className='phasetype-span' key={index}>
                    {phaseChargerTypeConvertor[type]}
                    {index !== chargerDetails.phaseType.length - 1 ? ', ' : ''}
                  </span>
                );
              })
            }
          />
          <PropertyContainer
            title={strings.uptime}
            content={chargerDetails.uptime}
          />
          <SubscriptionAccess levels={[3]}>
            <PropertyContainer
              title={strings.ocppVer}
              content={chargerDetails.ocppVersion}
            />
            <PropertyContainer
              title={strings.meterGroup}
              content={
                meterGroup.name
                  ? `${meterGroup.name} (${meterGroup.maxAmps}A)`
                  : '--'
              }
              handleClick={
                <RoleCanEdit roles={[1, 2, 3]}>
                  <ButtonDivGreyStyles
                    size='11px'
                    padding='3px 20px'
                    margin='0'
                    background='#a5a5a5'
                    border
                    onClick={() => toggleDisplayModal('meterGroup')}>
                    {strings.change}
                  </ButtonDivGreyStyles>
                </RoleCanEdit>
              }
            />
            <PropertyContainer
              title={strings.circuit}
              content={circuitsContent(connectorCircuits)}
              handleClick={
                <RoleCanEdit roles={[1, 2, 3]}>
                  <ButtonDivGreyStyles
                    size='11px'
                    padding='3px 20px'
                    margin='0'
                    background='#a5a5a5'
                    border
                    onClick={() => toggleDisplayModal('circuit')}>
                    {strings.change}
                  </ButtonDivGreyStyles>
                </RoleCanEdit>
              }
            />
            {/* Future Release 2.0 Feature*/}
            {/* <PropertyContainer
              title={strings.moreProps}
              content={'9 more'}
              handleClick={
                <RoleCanEdit roles={[1, 2, 3]}>
                  <ButtonDivGreyStyles
                    size='11px'
                    padding='3px 20px'
                    margin='0'
                    background='#a5a5a5'
                    border
                    onClick={() => toggleDisplayModal('moreProps')}>
                    {strings.more}
                  </ButtonDivGreyStyles>
                </RoleCanEdit>
              }
            /> */}
          </SubscriptionAccess>
          <PropertyContainer
            title={`${strings.ports} ${
              chargerDetails?.evses && chargerDetails?.evses[0].connectors.length
                ? `(${chargerDetails?.evses[0].connectors.length})`
                : ''
            }`}
            content={
              Object.keys(chargerDetails).length > 0 && (
                <ConnectorImages
                  showName
                  bottomName
                  charger={chargerDetails}
                  alignItems='flex-start'
                  margin='0 10px'
                  text={strings.none}
                />
              )
            }
          />
          {chargerDetails.isAmpedUpInside && (
            <>
              <PropertyContainer title={strings.macAddress} content={'--'} />
              <PropertyContainer
                title={strings.ipAddress}
                content={'--'}
                handleClick={
                  <RoleCanEdit roles={[1, 2, 3]}>
                    <ButtonDivGreyStyles
                      size='11px'
                      padding='3px 20px'
                      margin='0'
                      background='#a5a5a5'
                      border
                      onClick={() => toggleDisplayModal('ipAddress')}>
                      {strings.change}
                    </ButtonDivGreyStyles>
                  </RoleCanEdit>
                }
              />
            </>
          )}
          <PropertyContainer
            title={strings.firmware}
            content={
              <>
                {chargerDetails.firmwareVersion}{' '}
                {props.firmwareUpdates.display && (
                  <FaCloudUploadAlt color='red' fontSize='14px' />
                )}
              </>
            }
            handleClick={
              props.firmwareUpdates.display && (
                <RoleCanEdit roles={[1, 2, 3]}>
                  <ButtonDivGreyStyles
                    size='11px'
                    padding='3px 20px'
                    margin='0'
                    background='#a5a5a5'
                    border
                    onClick={() => toggleDisplayModal('firmware')}>
                    {strings.update}
                  </ButtonDivGreyStyles>
                </RoleCanEdit>
              )
            }
          />
        </PropertiesContentContainerStyles>
      </PropertiesCardStyles>
    </>
  );
};

export default ChargerProperties;
