import React, { lazy, Suspense } from 'react';

//services
import AppChargersService from '../../AppChargersService';
import AppPlatformService from '../../../../../services/AppPlatform/AppPlatformService';

//components
// import ChargersWifi from './ChargersWifi';
import { ComponentWrapper, OuterWrapper } from '../../../OperatorShared';
import { DivStyles, TextStyles, TitleStyles } from '../../../../SharedStyles';
import Button from '../../../../shared/Buttons/Button';

const ChargersWifi = lazy(() => import('./ChargersWifi'));

const ChargersConnection = (props) => {
  const { strings } = AppChargersService;

  const failedRedirect = () => {
    props.history.push(`/chargers`);
  };

  if (AppPlatformService.isCSOApp()) {
    return <Suspense fallback={<div>Loading...</div>}><ChargersWifi /></Suspense>
    // return <ChargersWifi />;
  }

  return (
    <OuterWrapper title={strings.setupWifi} internal path='/chargers' myForm>
      <ComponentWrapper title={strings.setupWifi}>
        <DivStyles align='center' margin='10px 0 30px'>
          <TitleStyles
            size='22px'
            weight='400'
            margin='15px auto 20px'
            padding='0 5%'>
            {strings.wifiHeader}
          </TitleStyles>
          <TextStyles size='14px' align='left' padding='0 5%'>
            {strings.downloadApp}
          </TextStyles>
          <Button
            cso
            onclick={failedRedirect}
            buttonText={strings.done}
            roles={[1, 2, 3, 4]}
          />
          <TextStyles size='14px' align='left' padding='0 5%'>
            <strong>{strings.note}</strong>: {strings.desktopNote}
          </TextStyles>
        </DivStyles>
      </ComponentWrapper>
    </OuterWrapper>
  );
};

export default ChargersConnection;
