import React, { useState, useEffect, useContext } from 'react';
import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa';

//service
import ChargingSessionService from '../ChargingSessionService';
import AppPlatformService from '../../../../../../../services/AppPlatform/AppPlatformService';

//components
import LoadingSpinner from '../../../../../../shared/Loading/LoadingSpinner';
import Section from '../../IncomeReports/Payout/components/Section';

//helpers
import {
  filteringSessions,
  filteredSessionsCalculations,
} from '../../../../helpers';
import { timeConvert } from '../../../../../../shared/Helpers';

//styles
import {
  DivStyles,
  ListPlaceholderStyles,
  TableHeaderStyles,
  TableItemStyles,
  TableStyles,
} from '../../../../../../SharedStyles';
import Pagination from '../../../../../../shared/Pagination/Pagination';
import DetailsItem from './DetailsItem';
import { useSortAndReverse } from '../../../../../../shared/hooks/useSortAndReverse';
import { usePagination } from '../../../../../../shared/hooks/usePagination';

import {
  tableDetailsHeaderConvert,
  tableAdminDetailsHeaderConvert,
} from '../../../../../Chargers/helpers';

//context
import { CsoAccountContext } from '../../../../../../context/operator/getCsoAccount';
import Summaries from '../../Summaries';

const DetailsList = (props) => {
  const { strings } = ChargingSessionService;
  const { filters, fetchReportData } = props;
  const { account } = useContext(CsoAccountContext);
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [paginationSessions, setPaginationSessions] = useState([]);
  const [summaries, setSummaries] = useState({
    totalSessionTime: 0,
    averageSessionTime: 0,
    averagekWh: 0,
    totalkWh: 0,
    totalRange: 0,
    totalSessionAmt: 0,
    totalIdleAmt: 0,
  });

  const fetchingSessions = async () => {
    try {
      setSessions([]);
      let history = await ChargingSessionService.getCSOSessions();

      let sortedHistory = history.sort(
        (a, b) => new Date(b.transactionDate) - new Date(a.transactionDate)
      );

      setSessions(sortedHistory);
      setLoading(false);
    } catch (err) {
      setSessions([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    let filtered = filteringSessions(sessions, filters);
    let {
      totalSessionTime,
      averageSessionTime,
      averagekWh,
      totalkWh,
      totalRange,
      totalSessionAmt,
      totalIdleAmt,
    } = filteredSessionsCalculations(filtered);

    setSummaries({
      totalSessionTime,
      averageSessionTime,
      averagekWh,
      totalkWh,
      totalRange,
      totalSessionAmt,
      totalIdleAmt,
    });
    setFilteredSessions(filtered);
  }, [filters, sessions]);

  useEffect(() => {
    fetchingSessions();
  }, []);

  const updateSortedItems = (items) => {
    setPaginationSessions(items);
  };

  const tableHeader =
    account.subscriptionLevel === 3
      ? strings.headerAdminData
      : strings.headerData;
  const tableHeaderConvertor =
    account.subscriptionLevel === 3
      ? tableAdminDetailsHeaderConvert
      : tableDetailsHeaderConvert;

  const [isActiveSort, sortReverse, changeActiveSort] = useSortAndReverse(
    'transactionDate',
    filteredSessions,
    tableHeader,
    tableHeaderConvertor,
    updateSortedItems,
    'desc'
  );

  /**
   * pagination for the data
   * @param {*} dataArray - items that will be sorted
   * @param {*} itemsPerPage - Custom items limit per page
   */
  const {
    next,
    prev,
    jump,
    currentData,
    currentPage,
    maxPage,
    totalData,
    currentCount,
    pageNumbers,
  } = usePagination(paginationSessions, 20);

  useEffect(() => {
    if (fetchReportData) {
      let reportData = {
        sessions: filteredSessions,
        summaries: summaries,
      };
      fetchReportData(reportData);
    }
    // eslint-disable-next-line
  }, [filteredSessions, summaries]);

  const tableMaxItemSize = (title) => {
    return '75px';
    switch (true) {
      case title === 'Detail':
        return '75px';
      default:
        return '75px';
    }
  };

  const tableMinItemSize = (title) => {
    return '70px';
    switch (true) {
      case title === 'Location':
        return '90px';
      case title === 'Charger':
        return '80px';
      case title === 'Date':
        return '80px';
      default:
        return '70px';
    }
  };

  return (
    <>
      <DivStyles padding='10px'>{loading && <LoadingSpinner />}</DivStyles>
      <Section padding='0 0 10px' borderBottom='none'>
        <DivStyles display='flex' flexWrap='wrap'>
          <TableStyles
            flex='1'
            borderTop='1px solid #e7e7e7'
            borderRight='1px solid #e7e7e7'>
            <Summaries
              justifyContent='center'
              titles={['Sessions', 'Tot Session Time', 'Avg Session Time']}
              content={[
                filteredSessions.length,
                timeConvert(summaries.totalSessionTime),
                timeConvert(summaries.averageSessionTime),
              ]}
            />
          </TableStyles>
          <TableStyles
            flex='1'
            borderTop='1px solid #e7e7e7'
            borderRight='1px solid #e7e7e7'>
            <Summaries
              justifyContent='center'
              titles={['Average kWh', 'Total kWh', 'Total Range Added']}
              content={[
                summaries.averagekWh.toFixed(2),
                summaries.totalkWh.toFixed(2),
                summaries.totalRange.toFixed(2),
              ]}
            />
          </TableStyles>
          <TableStyles flex='1' borderTop='1px solid #e7e7e7'>
            <Summaries
              justifyContent='center'
              titles={account.subscriptionLevel === 3 ? ['Tot Session Amt', 'Tot Idle Amt'] : ['Total Session Amt']}
              content={account.subscriptionLevel === 3 ? [
                `${AppPlatformService.currencyFormat(summaries.totalSessionAmt)}`,
                `${AppPlatformService.currencyFormat(summaries.totalIdleAmt)}`,
              ] : [`${AppPlatformService.currencyFormat(summaries.totalSessionAmt)}`]}
            />
          </TableStyles>
        </DivStyles>
      </Section>
      <Pagination
        currentCount={currentCount}
        totalData={totalData}
        next={next}
        prev={prev}
        jump={jump}
        currentPage={currentPage}
        maxPage={maxPage}
        pageNumbers={pageNumbers}
      />
      <TableStyles minHeight='500px' maxHeight='500px'>
        <TableHeaderStyles
          borderTop='none'
          borderLeft='none'
          borderRight='none'
          width='100%'
          minWidth='1260px'>
          {tableHeader.map((title, i) => {
            return (
              <TableItemStyles
                width='100%'
                minWidth={tableMinItemSize(title)}
                maxWidth={tableMaxItemSize(title)}
                borderRight
                key={i}
                onClick={() => {
                  changeActiveSort(tableHeaderConvertor[title]);
                }}>
                {isActiveSort[tableHeader[title]] && (
                  <DivStyles
                    position='absolute'
                    left='5px'
                    top='45%'
                    transform='translate(0, -55%)'>
                    {sortReverse[tableHeader[title]] ? (
                      <FaLongArrowAltDown />
                    ) : (
                      <FaLongArrowAltUp />
                    )}{' '}
                  </DivStyles>
                )}
                {title}
              </TableItemStyles>
            );
          })}
        </TableHeaderStyles>
        {currentData().map((session) => (
          <DetailsItem
            // page='reportspage'
            key={session.transactionId}
            account={account}
            transactionDate={session.transactionDate}
            transactionId={session.transactionId}
            location={session.location.split('|')[0]}
            charger={session.iotHubDeviceId}
            userId={session.userId}
            totalTime={session.totalTime}
            avgkW={session.avgkW}
            totalkWh={session.totalkWh}
            milesAdded={session.milesAdded}
            rateType={session.rateType}
            rate={session.rate}
            totalCost={session.totalCost}
            idleRateType={session.idleRateType}
            idleRate={session.idleRate}
            idleTime={session.idleTime}
            idleCost={session.idleCost}
            posType={session.posType}
            driverName={session.driverName}
            vehicleMake={session.vehicleMake}
            vehicleModel={session.vehicleModel}
            vehicleYear={session.vehicleYear}
          />
        ))}
        {filteredSessions.length === 0 && !loading && (
          <ListPlaceholderStyles borderBottom='none'>
            <p>{strings.noResults}</p>
          </ListPlaceholderStyles>
        )}
        {sessions.length === 0 && !loading && (
          <ListPlaceholderStyles borderBottom='none'>
            <p>{strings.noSessions}</p>
          </ListPlaceholderStyles>
        )}
      </TableStyles>
    </>
  );
};

export default DetailsList;
