import React, { useState } from 'react';

import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppLocationsService from '../AppLocationsService';

//global component
import ModalButton from '../../../shared/Buttons/ModalButton';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  InputStyles,
} from '../../../SharedStyles';

const LocationEditModal = React.forwardRef((props, ref) => {
  const { loc, handleCloseModal, handleDataChange } = props;
  const { strings } = AppLocationsService;
  const [name, setName] = useState(loc.name);

  const closeModal = () => {
    handleCloseModal();
  };

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleSaveInfo = (e) => {
    e.preventDefault();
    handleDataChange('name', name);
    handleCloseModal();
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.locationInfoTitle}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <p>{strings.locationInfoContent}</p>
          </ModalItemStyles>
          {/* Section */}
          <ModalItemStyles>
            <form onSubmit={handleSaveInfo} id='modalForm'>
              <InputStyles
                type='text'
                onChange={handleChange}
                name='name'
                placeholder={strings.locationNamePlaceholder}
                value={name || ''}
                required
              />
            </form>
          </ModalItemStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          formSubmit='modalForm'
          buttonText={strings.save}
        />
      </ModalContainerStyles>
      {/* 
      <div className='AppCso-modal-container'>
        <div className='AppCso-modal-title'>
          <div>{strings.locationInfoTitle}</div>
          <div className='AppCso-modal-close' onClick={closeModal}>
            <FaRegWindowClose />
          </div>
        </div>
        <form onSubmit={handleSaveInfo}>
          <div className='AppCso-modal-content'>
            <p>{strings.locationInfoContent}</p>
            <div className='AppCso-modal-item'>
              <InputStyles
                flexWidth='100%'
                margin='0 10px'
                type='text'
                ref={ref}
                onChange={handleChange}
                name='name'
                placeholder={strings.locationNamePlaceholder}
                value={name || ''}
                required
              />
            </div>
          </div>
          <div className='AppCso-modal-button-container'>
            <button type='submit' className='AppCso-modal-button'>
              {strings.save}
            </button>
          </div>
        </form>
      </div> */}
    </ModalStyles>
  );
});

export default LocationEditModal;
