import React, { useState, useContext, useEffect } from 'react';
//layout
import { InteriorWrapper, ComponentWrapper } from '../../../shared';
import Button from '../../../../shared/Buttons/Button';

//services
import AppVehicleService from '../../AppVehicleService';

//components
import EditManualVehicle from './EditManualVehicle';

//global components
import { LoadingSpinner } from '../../../../shared';

//hooks
import { useCloudinary } from '../../../../shared/Cloudinary/hook/useCloudinary';

//images
import DefaultImage from '../../../../../assets/images/MyVehiclePlaceholder300x150.png';

//context
import { DriverContext } from '../../../../context/driver/getDriverContext';

//styles
import {
  VehicleFormStyles,
  VehicleSpecContainerStyles,
} from '../../../../DriverStyles';
import {
  InputContainerStyles,
  InputStyles,
  SelectContainerStyles,
  SelectStyles,
  ButtonContainerStyles,
  ButtonSpanStyles,
} from '../../../../SharedStyles';

//helpers
import { milesToKm } from '../../../../shared/Helpers';

const EditVehicle = (props) => {
  const { strings } = AppVehicleService;
  const { driver } = useContext(DriverContext);
  const [loading, setLoading] = useState(true);
  const [vehicle, setVehicle] = useState({});
  const [vehicleCatalog, setVehicleCatalog] = useState([]);
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [years, setYears] = useState([]);

  const { cloudData, uploadImage } = useCloudinary({
    preset: 'vehicle_images',
    tags: [`${driver.firstName} ${driver.lastName}`, driver.id],
  });

  useEffect(() => {
    if (cloudData) {
      setVehicle((v) => ({
        ...v,
        imagePath: cloudData.secure_url,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloudData]);

  const fetchVehicle = async () => {
    setLoading(true);
    try {
      const vehicle = await AppVehicleService.getVehicle(props.match.params.id);
      if (vehicle) {
        setVehicle(vehicle);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getCatalogData = async () => {
    try {
      setLoading(true);
      const data = await AppVehicleService.getVehicleCatalog();
      const makes = [...new Set(data.map((vehicle) => vehicle.make))];

      setVehicleCatalog(data);
      setMakes(makes);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVehicle();
    getCatalogData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (vehicleCatalog.length > 0) {
      let currentMake = vehicleCatalog.filter((currentCar) => {
        return currentCar.make === vehicle.make;
      });

      let filteredModels = [];
      currentMake.map((currentCar) => filteredModels.push(currentCar.model));
      let distinctModels = [...new Set(filteredModels)];
      setModels(distinctModels);

      let modelsList = vehicleCatalog.filter((currentCar) => {
        return currentCar.model === vehicle.model;
      });

      let yearList = [];
      modelsList.map((vehicle) => yearList.push(vehicle.year));
      let distinctYears = [...new Set(yearList)];
      setYears(distinctYears);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleCatalog]);

  //prepopulates model, gen, and year based on current vehicle model
  useEffect(() => {
    if (!loading && vehicleCatalog !== [] && makes !== []) {
      //getting all vechiles that contain the current vehicle make
      let currentMake = vehicleCatalog.filter((currentCar) => {
        return currentCar.make === vehicle.make;
      });
      //getting all models based on makes
      let filteredModels = [];
      currentMake.map((currentCar) => filteredModels.push(currentCar.model));
      let distinctModels = [...new Set(filteredModels)];
      setModels(distinctModels);
    }

    if (!loading && models !== []) {
      let modelsList = vehicleCatalog.filter((currentCar) => {
        return currentCar.model === vehicle.model;
      });

      let yearList = [];
      modelsList.map((vehicle) => yearList.push(vehicle.year));
      let distinctYears = [...new Set(yearList)];
      setYears(distinctYears);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle]);

  //clicks the hidden input for image upload
  const openDialog = () => {
    document.getElementById('inputUpload').click();
  };

  const selectMake = (e) => {
    let make = e.target.value;
    let makeList = vehicleCatalog.filter((vehicle) => {
      return vehicle.make === make;
    });
    let filteredModels = [];
    makeList.map((vehicle) => filteredModels.push(vehicle.model));
    let distinctModels = [...new Set(filteredModels)];

    setModels(distinctModels);
    setVehicle({
      ...vehicle,
      make: make,
      model: '',
      year: '',
      color: '',
      allElectricRange: '--',
      batteryCapacity: '--',
    });
  };

  const selectModel = (e) => {
    let model = e.target.value;

    setVehicle({
      ...vehicle,
      model: model,
      year: '',
      color: '',
      allElectricRange: '--',
      batteryCapacity: '--',
    });
  };

  const selectYear = (e) => {
    let selectedVehicle = vehicleCatalog.find((car) => {
      return (
        car.make === vehicle.make &&
        car.model === vehicle.model &&
        e.target.value === car.year
      );
    });

    setVehicle({
      ...vehicle,
      year: e.target.value,
      allElectricRange: selectedVehicle.allElectricRange,
      batteryCapacity: selectedVehicle.batteryCapacity,
    });
  };

  const selectColor = (e) => {
    let color = e.target.value;
    setVehicle({ ...vehicle, color: color });
  };

  const handleChange = (e) => {
    setVehicle({ ...vehicle, [e.target.name]: e.target.value });
  };

  const saveVehicle = async (e) => {
    console.log('submitting');
    e.preventDefault();
    setLoading(true);
    try {
      const updatedvehicle = {
        ...vehicle,
        imagePath: cloudData ? cloudData.secure_url : vehicle.imagePath,
      };
      let res = await AppVehicleService.updateDriverVehicle(updatedvehicle);
      if (res) {
        props.history.push('/vehicle-list');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  if (vehicle.manualEntry) {
    return (
      <InteriorWrapper
        title={strings.editTitle}
        isInternal
        save
        myForm='myForm'
        path='/vehicle-list'>
        {loading && <LoadingSpinner />}
        <ComponentWrapper title={strings.editTitle}>
          <EditManualVehicle edit vehicle={vehicle} />
        </ComponentWrapper>
        <Button driver noMobile formSubmit buttonText={strings.save} />
      </InteriorWrapper>
    );
  }

  return (
    <InteriorWrapper
      title={strings.editTitle}
      isInternal
      path='/vehicle-list'
      save
      myForm='myForm'>
      {loading && <LoadingSpinner />}
      <ComponentWrapper title={strings.editTitle}>
        <VehicleFormStyles id='myForm' onSubmit={saveVehicle}>
          <InputContainerStyles>
            <InputStyles
              type='text'
              maxLength='256'
              name='nickName'
              value={vehicle.nickName || ''}
              onChange={handleChange}
              required
            />
          </InputContainerStyles>
          <SelectContainerStyles>
            <SelectStyles
              name='app-make-selection'
              onChange={selectMake}
              required>
              <option value={vehicle.make}>
                {vehicle.make || strings.makePlaceholder}
              </option>
              {makes.map((make) => {
                if (make !== vehicle.make) {
                  return (
                    <option key={make} value={make}>
                      {make}
                    </option>
                  );
                }
                return null;
              })}
            </SelectStyles>
          </SelectContainerStyles>
          {vehicle.make && (
            <SelectContainerStyles>
              <SelectStyles
                name='app-model-selection'
                onChange={selectModel}
                required>
                <option value={vehicle.model}>
                  {vehicle.model || strings.modelPlaceholder}
                </option>
                {models.map((model) => {
                  if (model !== vehicle.model) {
                    return (
                      <option key={model} value={model}>
                        {model}
                      </option>
                    );
                  }
                  return null;
                })}
              </SelectStyles>
            </SelectContainerStyles>
          )}
          {vehicle.model && (
            <SelectContainerStyles>
              <SelectStyles
                name='app-year-selection'
                onChange={selectYear}
                required>
                <option value={vehicle.year}>
                  {vehicle.year || strings.yearPlaceholder}
                </option>
                {years.map((year) => {
                  if (year !== vehicle.year) {
                    return (
                      <option key={year} value={year}>
                        {' '}
                        {year}{' '}
                      </option>
                    );
                  }
                  return null;
                })}
              </SelectStyles>
            </SelectContainerStyles>
          )}
          {vehicle.year && (
            <SelectContainerStyles>
              <SelectStyles
                name='color'
                value={vehicle.color || ''}
                onChange={selectColor}>
                <option value='' disabled>
                  {strings.colorSelect}
                </option>
                {strings.colors.map((color) => {
                  return (
                    <option key={color} value={color}>
                      {color}
                    </option>
                  );
                })}
              </SelectStyles>
            </SelectContainerStyles>
          )}
        </VehicleFormStyles>
        <VehicleSpecContainerStyles>
          <div className='Container-Title'>{strings.vehicleSpecs}</div>

          <div className='Image-Container'>
            {vehicle.imagePath ? (
              <img
                src={vehicle.imagePath}
                alt='vehicle'
                className='app-vehicle-image'
              />
            ) : (
              <img
                src={DefaultImage}
                alt='vehicle'
                className='app-vehicle-image'
              />
            )}
            <input
              className='file-input'
              id='inputUpload'
              type='file'
              hidden
              accept='image/*'
              onChange={uploadImage}
            />
          </div>
          <ButtonContainerStyles margin='10px auto'>
            <ButtonSpanStyles inverse id='fileUpload' onClick={openDialog}>
              {strings.changeImage}
            </ButtonSpanStyles>
          </ButtonContainerStyles>
          {Object.keys(vehicle).length > 0 && (
            <div className='Container-Content'>
              <div className='Content-Data'>
                <span className='Content-Bold'>{strings.battery}</span>
                {vehicle.batteryCapacity} {strings.kwh}
              </div>
              <div className='Content-Data'>
                <span className='Content-Bold'>{strings.range}</span>
                {milesToKm(driver.unitOfMeasurement, vehicle.allElectricRange)}
              </div>
            </div>
          )}
        </VehicleSpecContainerStyles>
      </ComponentWrapper>
      <Button driver noMobile formSubmit buttonText={strings.save} />
    </InteriorWrapper>
  );
};

export default EditVehicle;
