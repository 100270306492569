import React, { useState, useContext } from 'react';

//layout
import { OuterWrapper, ComponentWrapper } from '../OperatorShared/index';
import Button from '../../shared/Buttons/Button';

//services
import AppPricingSchedulesService from './AppPricingSchedulesService';

//components
import PricingSchedulesList from './PricingSchedulesList/PricingSchedulesList';
import SmallBizAdmin from '../SmallBizAdmin';

//modals
import UpgradeModal from '../OperatorShared/Modals/UpgradeModal';

//context
import { CsoAccountContext } from '../../context/operator/getCsoAccount';

const PricingSchedules = (props) => {
  const { account } = useContext(CsoAccountContext);
  const [limitReached, setLimitReached] = useState(false);

  const { strings } = AppPricingSchedulesService;

  const closeReachModal = () => {
    setLimitReached(!limitReached);
  };

  const checkingLimit = () => {
    if (account.subscriptionLevel === 2) {
      if (account.pricingSchedules.length >= 2) {
        setLimitReached(true);
      } else {
        props.history.push('/subscription/pricing-schedules/add');
      }
    } else {
      props.history.push('/subscription/pricing-schedules/add');
    }
  };

  return (
    <OuterWrapper title={strings.pageTitle} internal path='/'>
      <SmallBizAdmin allowCustomer>
        <ComponentWrapper title={strings.pageTitle}>
          {limitReached && (
            <UpgradeModal
              handleCloseModal={closeReachModal}
              strings={strings}
            />
          )}
          <PricingSchedulesList />
        </ComponentWrapper>
        <Button
          cso
          hasMobile
          onclick={checkingLimit}
          buttonText={strings.addButton}
          roles={[1, 2]}
        />
      </SmallBizAdmin>
    </OuterWrapper>
  );
};

export default PricingSchedules;
