import strings from './strings';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import AppApiService from '../../../services/AppApi/AppApiService';

const localStrings = strings[AppPlatformService.getLanguage()];

const AccessCardsService = {
  getAccessCards: function () {
    return AppApiService.get('getAccessCards');
  },
  orderAccessCards: function (card) {
    return AppApiService.post('orderAccessCards', null, card);
  },
  updateAccessCard: function (card) {
    return AppApiService.post('updateAccessCard', null, card);
  },
  activateAccessCard: function (card) {
    return AppApiService.post('activateAccessCard', null, card);
  },
  strings: localStrings,
};

export default AccessCardsService;
