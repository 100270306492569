import React from 'react';
import AppChargingService from '../AppChargingService';

//helpers
import { calculateMilesKwh } from '../../../shared/Helpers';
import { ChargerCardPaneStyles } from '../../../DriverStyles';

const AppChargingFavorites = props => {
  const { strings } = AppChargingService;
  const { charger, driver, defaultVehicle } = props;

  return (
    <div className='charging-history-container'>
      <div className='charging-history-header'>
        <div className='charging-history-title'>
          {charger.chargerName || charger.iotHubDeviceId}{' '}
          {charger.home && `- ${strings.owned}`}
          {charger.assigned && `- ${strings.assigned}`}
        </div>
      </div>
      <div className='charging-history-address'>{charger.chargerAddress}</div>
      {charger.dead ? (
        <ChargerCardPaneStyles>
          <p className='danger-text'>
            <em>{strings.deadCharger}</em>
          </p>
        </ChargerCardPaneStyles>
      ) : (
        <>
          <div className='charging-history-details-header'>
            <div className='charging-history-col'>{strings.titlePower}</div>
            <div className='charging-history-col'>
              {driver.unitOfMeasurement === 'Miles'
                ? strings.mileage
                : strings.kiloM}
            </div>
            {/* <div className='charging-history-col'>{strings.titleConnector}</div> */}
            <div className='charging-history-col'>
              {!charger.home ? strings.titleRate : strings.titleUtility}
            </div>
          </div>
          <div className='charging-history-details-content'>
            <div className='charging-history-col'>{charger.power}</div>
            <div className='charging-history-col'>
              {calculateMilesKwh(
                defaultVehicle,
                charger,
                driver.unitOfMeasurement
              )}
            </div>
            {/* <div className='charging-history-col'>
          {charger.connectors.map((conn, i) => (
            <p key={i}>{conn.connectorName}</p>
          ))}
        </div> */}
            <div className='charging-history-col'>
              {!charger.home
                ? charger.rateType === 'Free'
                  ? charger.rate
                  : `${charger.rate}/${charger.rateType}`
                : `$${
                    driver.homeChargerRate < 1
                      ? `0${driver.homeChargerRate}`
                      : `${parseFloat(driver.homeChargerRate).toFixed(2)}`
                  }`}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AppChargingFavorites;
