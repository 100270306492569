import AppApiService from '../../../services/AppApi/AppApiService';

const AppDriverService = {
  getAccount: function () {
    return AppApiService.get('driver');
  },
  updateDriver: function (driver) {
    return AppApiService.post('updateDriver', null, driver);
  },
  getDriverCharger: function (userLocation) {
    return AppApiService.post('getDriverCharger', null, userLocation);
  },
  getDriverChargers: function (userLocation) {
    return AppApiService.post('getDriverChargers', null, userLocation);
  },
  updateDriverCharger: function (charger) {
    return AppApiService.post('updateDriverCharger', null, charger);
  },
  getChargingStation: function (chargerId) {
    return AppApiService.post('getChargingStation', null, chargerId);
  },
  getDriverLedger: function () {
    return AppApiService.get('getDriverLedger');
  },
};

export default AppDriverService;
