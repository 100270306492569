import React, { useState } from 'react';

//layout
import { InteriorWrapper, ComponentWrapper } from '../../shared/index';
import Button from '../../../shared/Buttons/Button';

//services
import AppVehicleService from '../AppVehicleService';

//Components
import AddVehicle from './Forms/AddVehicle';
import ManualVehicle from './Forms/ManualVehicle';

//global components
import ToggleSwitch from '../../shared/ToggleSwitch';

//styles
import { CenterStyles } from '../../../SharedStyles';

const VehicleCreation = (props) => {
  const { strings } = AppVehicleService;
  const [toggleManualForm, setToggleManualForm] = useState(false);

  const toggleForm = () => {
    setToggleManualForm(!toggleManualForm);
  };

  return (
    <InteriorWrapper
      title={!toggleManualForm ? strings.title : strings.manualTitle}
      isInternal
      myForm='myForm'
      path='/vehicle-list'>
      <ComponentWrapper
        title={!toggleManualForm ? strings.title : strings.manualTitle}>
        {!toggleManualForm ? <AddVehicle /> : <ManualVehicle />}
      </ComponentWrapper>
      <CenterStyles margin='15px auto'>
        <ToggleSwitch
          doubleLabel={
            !toggleManualForm ? strings.addManualCar : strings.addNewCar
          }
          justifyContent='center'
          checked={!!toggleManualForm}
          handleChange={toggleForm}
        />
      </CenterStyles>
      <Button driver noMobile formSubmit buttonText={strings.save} />
    </InteriorWrapper>
  );
};

export default VehicleCreation;
