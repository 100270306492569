import React, { useState, useContext, useEffect } from 'react';

//layout
import { OuterWrapper, ComponentWrapper } from '../../OperatorShared';
import Button from '../../../shared/Buttons/Button';

//services
import AppChargingProfilesService from '../AppChargingProfilesService';

//components
import FormHeaderComponent from './FormHeaderComponent';
import DailySchedules from './DailySchedules';

//global components
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';
import EnterpriseAdmin from '../../EnterpriseAdmin';
import CsoNotFound from '../../../shared/AppNotFound/CsoNotFound';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import { ErrorContext } from '../../../context/shared/ErrorContext';

//error Class
import { ErrorResponse } from '../../../shared/ErrorResponse';

const ChargingProfileEdit = (props) => {
  const { strings } = AppChargingProfilesService;
  const { account, setAccount } = useContext(CsoAccountContext);
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(true);
  const [chargingProfile, setChargingProfile] = useState({});
  const [notFound, setNotFound] = useState({
    display: false,
    message: '',
  });

  //finds the charging profile in the account object by the params id
  useEffect(() => {
    if (account) {
      let singleChargingProfile = account.chargingProfiles.filter((profile) => {
        return profile.id === props.match.params.id;
      });
      if (singleChargingProfile.length > 0) {
        setChargingProfile(singleChargingProfile[0]);
        setLoading(false);
      } else {
        setLoading(false);
        setNotFound({
          display: true,
          message: strings.fourOhFour,
        });
      }
    }
  }, [account, props.match.params.id, strings]);

  //form header - name and description
  const handleChange = (name, value) => {
    setChargingProfile({
      ...chargingProfile,
      [name]: value,
    });
  };

  //charging sx
  const handleScheduleAdd = (schedule) => {
    setChargingProfile({
      ...chargingProfile,
      schedules: schedule,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let checkedDates = chargingProfile.schedules.map((sx) => {
        return Object.keys(sx.dow).every((day) => !sx.dow[day]);
      });
      checkedDates.forEach((boo) => {
        if (boo) {
          setLoading(false);
          throw new ErrorResponse(
            'Frontend',
            'You must select a day of week for this schedule.',
            'Invalid Schedule'
          );
        }
        return;
      });
      chargingProfile.schedules.forEach((sch) => {
        sch.times.forEach((time) => {
          if (time.percentage === '0' || time.percentage === '') {
            throw new ErrorResponse(
              'Frontend',
              'You must select an amperage percentage between 1-100.',
              'Invalid Amperage Percentage'
            );
          }
        });
      });
      let updatedProfile = await AppChargingProfilesService.updateChargingProfile(
        chargingProfile
      );
      setAccount(updatedProfile);
      setLoading(false);
      setTimeout(() => {
        if (!loading) {
          props.history.push('/subscription/charging-profiles');
        }
      }, 500);
    } catch (err) {
      if (err.name === 'Frontend') {
        setError({
          display: true,
          message: err.message,
          title: err.title,
          styles: 'cso',
        });
        setLoading(false);
      }
      if (err.response) {
        let errTitle = '';
        let errMessage = '';
        let errorResponse = err.response.data.split(' ');
        if (errorResponse[6] === "'System.DateTime'.") {
          errTitle = 'Invalid Time Slot';
          errMessage = 'You must select a valid time.';
        }
        if (errorResponse[6] === "'System.Int32'.") {
          errTitle = 'Invalid Amperage Percentage';
          errMessage = 'You must select an amperage percentage between 1-100.';
        }
        if (errorResponse[0] === 'Invalid') {
          errTitle = 'Invalid Schedule';
          errMessage = `More than one schedule references ${errorResponse[7]}.`;
        } else if (errorResponse[0] !== 'Error') {
          errMessage = err.response.data;
        }
        setError({
          display: true,
          message: errMessage,
          title: errTitle,
          styles: 'cso',
        });
        setLoading(false);
      }
    }
  };

  if (notFound.display) {
    return <CsoNotFound message={notFound.message} />;
  }
  return (
    <OuterWrapper
      title={strings.editTitle}
      internal
      path='/subscription/charging-profiles'
      myForm='myForm'
      roles={[1]}>
      {loading && <LoadingSpinner />}
      <EnterpriseAdmin>
        <ComponentWrapper roles={[1]} title={strings.editTitle}>
          {Object.keys(chargingProfile).length > 0 && (
            <>
              <FormHeaderComponent
                editForm
                profile={chargingProfile}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
              <DailySchedules
                editForm
                profile={chargingProfile}
                handleChanges={handleScheduleAdd}
              />
            </>
          )}
        </ComponentWrapper>
        <Button
          cso
          hasMobile
          formSubmit
          buttonText={strings.save}
          roles={[1]}
        />
      </EnterpriseAdmin>
    </OuterWrapper>
  );
};

export default ChargingProfileEdit;
