import AppApiService from '../../../services/AppApi/AppApiService';
import strings from './strings';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppChargingService = {
  getVehicles: function () {
    return AppApiService.get('getDriverVehicles');
  },
  deleteVehicle: function (vehicle) {
    return AppApiService.post('removeDriverVehicle', null, vehicle);
  },
  updateDriverVehicle: function (vehicle) {
    return AppApiService.post('updateDriverVehicle', null, vehicle);
  },
  markVehicleSelectedTimestamp: function (vehicleId) {
    return AppApiService.post('markVehicleSelectedTimestamp', null, vehicleId);
  },
  getDriverCharger: function (charger) {
    return AppApiService.post('getDriverCharger', null, charger);
  },
  getChargingStation: function (charger) {
    return AppApiService.post('getChargingStation', null, charger);
  },
  //TODO: check if duplicate
  getChargingStatus: function (charger) {
    return AppApiService.post('getChargingStatus', null, charger);
  },
  getChargingStationStatus: function (charger) {
    return AppApiService.post('getChargingStationStatus', null, charger);
  },
  linkDriverCharger: function (charger) {
    return AppApiService.post('linkDriverCharger', null, charger);
  },
  startChargingSession: function (charger) {
    return AppApiService.post('startChargingSession', null, charger);
  },
  stopChargingSession: function (charger) {
    return AppApiService.post('stopChargingSession', null, charger);
  },
  getChargingSessionEta: function (vehicle) {
    return AppApiService.post('getChargingSessionEta', null, vehicle);
  },
  updateChargingSchedule: (data) => {
    return AppApiService.post('updateChargingSchedule', null, data);
  },
  getChargingSchedule: (data) => {
    return AppApiService.post('getChargingSchedule', null, data);
  },
  updateChargerLastUsed: (iotHubDeviceId) => {
    return AppApiService.post(
      'updateDriverChargerTimeStamp',
      null,
      iotHubDeviceId
    );
  },
  setAsReadDriverMessage: function (messageId) {
    return AppApiService.post('setAsReadDriverMessage', null, messageId);
  },
  strings: localStrings,
};

export default AppChargingService;
