import React from 'react';

//services
import AppTermsService from './AppTermsService';

//components
import DriverTerms from './DriverTerms';
// import CsoTerms from './CsoTerms';

let driverApp = localStorage.getItem('driverAccount');
// let csoApp = localStorage.getItem('csoAccount');

const TermsAndConditions = (props) => {
  const { strings } = AppTermsService;

  if (driverApp) {
    return <DriverTerms strings={strings} />;
  }
  // if (csoApp) {
  //   return <CsoTerms strings={strings} />;
  // }
};

export default TermsAndConditions;
