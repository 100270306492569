import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import strings from './strings';
import AppApiService from '../../../services/AppApi/AppApiService';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppPricingSchedulesService = {
  createPricingSchedule: function(schedule) {
    return AppApiService.post('createPricingSchedule', null, schedule);
  },
  updatePricingSchedule: function(schedule) {
    return AppApiService.post('updatePricingSchedule', null, schedule);
  },
  deletePricingSchedule: function(schedule) {
    return AppApiService.post('deletePricingSchedule', null, schedule);
  },
  strings: localStrings
};

export default AppPricingSchedulesService;
