import React from 'react';

//services
import AppSystemUsersService from '../../AppSystemUsersService';

//styles
import {
  FormContainerStyles,
  FormRowStyles,
  SelectStyles,
  TitleStyles,
} from '../../../../SharedStyles';

const EnterpriseRoles = (props) => {
  const { strings } = AppSystemUsersService;
  const { user, updateRole, addUser } = props;

  const handleChange = (e) => {
    updateRole(e.target.value);
  };

  return (
    <FormContainerStyles padding='10px 10px 15px'>
      <TitleStyles size='14px' weight='600'>
        {strings.sysRoles}
      </TitleStyles>
      <FormRowStyles>
        <SelectStyles
          flexWidth='50%'
          name='role'
          required
          disabled={
            (props.canEdit &&
              !props.canEdit.includes(props.currentUser.role)) ||
            props.pendingUser
          }
          defaultValue={addUser ? strings.chooseRole : user.role}
          onChange={handleChange}>
          <option disabled>{strings.chooseRole}</option>
          {strings.roles.map((role, index) => (
            <option key={index} value={index + 1}>
              {role}
            </option>
          ))}
        </SelectStyles>
      </FormRowStyles>
    </FormContainerStyles>
  );
};

export default EnterpriseRoles;
