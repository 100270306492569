import React from 'react';

import {
  DivStyles,
  FormContainerStyles,
  InputStyles,
  SelectStyles,
} from '../../../../SharedStyles';

import { formSetup } from './form';

const ActivateForm = ({ handleChange, card }) => {
  return (
    <FormContainerStyles padding='10px 0 15px'>
      {formSetup.map((f, i) => {
        if (f.name === 'serialId' && card.activated) {
          f.disabled = true;
        } else {
          f.disabled = false;
        }
        return (
          <DivStyles key={i} padding='0 10px' width={f.flex}>
            <InputStyles
              key={i}
              type={f.type}
              name={f.name}
              placeholder={f.placeholder}
              value={card[f.name] ? card[f.name] : ''}
              onChange={handleChange}
              required={f.required}
              disabled={f.disabled}
            />
          </DivStyles>
        );
      })}
    </FormContainerStyles>
  );
};

export default ActivateForm;
