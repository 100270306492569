import React, { useContext, useState } from 'react';

//services
import AppChargingProfilesService from '../AppChargingProfilesService';

//components
import ChargingProfilesCard from './ChargingProfileCard';

//global components
import { LoadingSpinner } from '../../../shared';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';

//styles
import { ListPlaceholderStyles } from '../../../SharedStyles';

//images
import chargerPlaceholderImage from '../../../../assets/images/ChargingStationPlaceholder400.png';

const ChargingProfileList = (props) => {
  const { account, setAccount } = useContext(CsoAccountContext);
  const [loading, setLoading] = useState(false);

  const updateAccount = (csoAccount) => {
    setAccount(csoAccount);
  };

  const deleteProfile = async (profileId) => {
    setLoading(true);
    let removedProfile = await AppChargingProfilesService.deleteChargingProfile(
      profileId
    );
    updateAccount(removedProfile);
    if (removedProfile) {
      setLoading(false);
    }
  };

  if (account.chargingProfiles.length === 0) {
    return (
      <ListPlaceholderStyles borderBottom='none'>
        <img src={chargerPlaceholderImage} alt='No Vehicles' />
      </ListPlaceholderStyles>
    );
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {account &&
        account.chargingProfiles.map((profile) => {
          return (
            <ChargingProfilesCard
              deleteProfile={deleteProfile}
              key={profile.id}
              profile={profile}
              updateAccount={updateAccount}
            />
          );
        })}
    </>
  );
};

export default ChargingProfileList;
