import AppNotificationsService from './AppNotificationsService';

const { strings } = AppNotificationsService;

export const driverNotificationSettings = [
  {
    name: 'chargingStarted',
    label: strings.chargingStarted,
  },
  {
    name: 'chargingEnded',
    label: strings.chargingEnded,
  },
  {
    name: 'chargingInterrupted',
    label: strings.chargingInterrupted,
  },
  {
    name: 'waitQueueStatus',
    label: strings.waitQueueStatus,
  },
  {
    name: 'reservationStatus',
    label: strings.reservationStatus,
  },
  {
    name: 'autoPaymentNotice',
    label: strings.autoPaymentNotice,
  },
  {
    name: 'idleFeeNotice',
    label: strings.idleFeeNotice,
  },
  {
    name: 'membershipNotice',
    label: strings.membershipNotice,
  },
  {
    name: 'chargingSkipped',
    label: strings.sessionSkip,
  },
  {
    name: 'customerNotice',
    label: strings.customerNotice,
  },
];
