export const rolesPerUser = {
  1: 'Administrator',
  2: 'Manager',
  3: 'Technician',
  4: 'Customer Service',
};

export const subscriptionLevelEnum = {
  0: 'No Subscriptions',
  1: 'Shared',
  2: 'Small Business',
  3: 'Enterprise',
};

export const dayOfWeek = {
  sunday: 'Sun.',
  monday: 'Mon.',
  tuesday: 'Tue.',
  wednesday: 'Wed.',
  thursday: 'Thr.',
  friday: 'Fri.',
  saturday: 'Sat.',
};

export const phaseMGTypeConvertor = {
  1: 'unassigned',
  2: 'Single Phase Three Wire',
  3: 'Three Phase Four Wire Wye',
  4: 'Three Phase Three Wire Delta',
  5: 'Three Phase Four Wire Delta',
  6: 'Three Phase Two Wire Corner-Ground Delta',
};

export const phaseChargerTypeConvertor = {
  1: 'unassigned',
  2: 'Single',
  3: 'Three',
  4: 'Three-Delta-3',
  5: 'Three-Delta-4',
  6: 'Three-Delta-2',
};

export const tenPercentDiff = (load, current) => {
  let total = (load - current) / load;
  if (total * 100 < 10) {
    return true;
  } else {
    return false;
  }
};

export const checkingValueOfZero = (obj) => {
  let arrayObj = Object.keys(obj).every((key) => {
    if (parseInt(obj[key]) === 0) {
      return false;
    } else if (key === 'circuits' && obj[key].length === 0) {
      return false;
    } else if (key === 'phaseType' && parseInt(obj[key]) === 1) {
      return false;
    } else return true;
  });

  return arrayObj;
};
