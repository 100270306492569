import React, { useState, useContext } from 'react';
import { isIOS, isMobileSafari } from 'react-device-detect';

//services
import AppFindChargerService from '../AppFindChargerService';

//components
import Scanner from './Scanner';

//global components
import { LoadingSpinner } from '../../../shared';

//hooks
import { useRouter } from '../../../shared/hooks/useRouter';

//styles
import '../FindCharger.scss';

//context
import { DriverContext } from '../../../context/driver/getDriverContext';
import { ErrorContext } from '../../../context/shared/ErrorContext';
import { DivStyles } from '../../../SharedStyles';

const ScanCharger = (props) => {
  const { strings } = AppFindChargerService;
  const { driver } = useContext(DriverContext);
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(false);
  const [route, setRoute] = useState('');

  const router = useRouter();

  const handleChange = (e) => {
    setRoute(e.target.value.toUpperCase());
  };

  const handleSubmit = async (scannedRoute) => {
    setLoading(true);
    try {
      //"https://qr.ampedup.net/<group>/?c=AMP00047"
      if (scannedRoute.includes('https://qr.ampedup.net/')) {
        scannedRoute = scannedRoute.split('/?c=')[1];
      }

      let res = await AppFindChargerService.getChargingStation({
        iotHubDeviceId: scannedRoute,
        location: driver.driverLocation,
        bypassDirection: false,
      });

      if (res.private || !res.enabledSubscription) {
        throw new Error();
      }

      if (res) {
        router.history.push(`/charging/${res.iotHubDeviceId}`);
      }
    } catch (err) {
      setLoading(false);
      setError({
        display: true,
        title: 'Invalid Charger',
        message: 'An invalid Charger ID was entered. Please try again.',
        styles: 'driver',
      });
    }
  };

  let scanner = (
    <>
      <span className='scan-text'>{strings.scanTop}</span>
      <div className='qr'>
        <Scanner handleRouteChange={handleSubmit} />
      </div>
      <span className='scan-text'>{strings.scanBottom}</span>
    </>
  );

  if (isIOS && !isMobileSafari && window.cordova === undefined) {
    scanner = (
      <p className='scan-text'>
        QR Code scanning is not supported in this browser. Please use Safari or
        the AmpedUp! EV Driver app.
      </p>
    );
  }

  return (
    <div className='component-container'>
      <div className='scan-container'>
        {loading && <LoadingSpinner />}
        {scanner}
        <div className='charger-input-container-scan'>
          <DivStyles
            id='charger-form'
            name='charger-form'
            data-name='charger-form'>
            <input
              type='text'
              className='charger-name-input form-input'
              maxLength='256'
              name='ChargerId'
              placeholder={strings.placeholder}
              id='ChargerId'
              onChange={handleChange}
              required
            />
            <button className='app-button' onClick={() => handleSubmit(route)}>
              <div>{strings.start}</div>
            </button>
          </DivStyles>
        </div>
      </div>
    </div>
  );
};

export default ScanCharger;
