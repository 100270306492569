import React, { useState, useEffect, useContext } from 'react';
import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa';

//service
import ChargingSessionService from '../ChargingSessionService';
import AppPlatformService from '../../../../../../../services/AppPlatform/AppPlatformService';

//components
import LoadingSpinner from '../../../../../../shared/Loading/LoadingSpinner';
import Section from '../../IncomeReports/Payout/components/Section';

//helpers
import {
  filteringSessions,
  filteredSessionsCalculations,
} from '../../../../helpers';
import { timeConvert } from '../../../../../../shared/Helpers';

//styles
import {
  DivStyles,
  ListPlaceholderStyles,
  TableHeaderStyles,
  TableItemStyles,
  TableStyles,
} from '../../../../../../SharedStyles';
import Pagination from '../../../../../../shared/Pagination/Pagination';
import { useSortAndReverse } from '../../../../../../shared/hooks/useSortAndReverse';
import { usePagination } from '../../../../../../shared/hooks/usePagination';

import { statusHeaderConvert } from '../../../../../Chargers/helpers';

//context
//import { CsoAccountContext } from '../../../../../../context/operator/getCsoAccount';
import Summaries from '../../Summaries';
import StatusDetailsItem from './StatusDetailsItem';

const StatusDetailsList = (props) => {
  const { strings } = ChargingSessionService;
  const { filters, fetchReportData } = props;
  //const { account } = useContext(CsoAccountContext);
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [paginationSessions, setPaginationSessions] = useState([]);
  const [summaries, setSummaries] = useState({
    // incidentCount: 0,
    // averageSessionTime: 0,
    // averagekWh: 0,
    // totalkWh: 0,
    // totalRange: 0,
    // totalSessionAmt: 0,
    // totalIdleAmt: 0,
  });

  const fetchingSessions = async () => {
    try {
      setSessions([]);
      let history = await ChargingSessionService.getCSOChargerStatus();

      let sortedHistory = history.sort(
        (a, b) => new Date(b.incidentDate) - new Date(a.incidentDate)
      );

      setSessions(sortedHistory);
      setLoading(false);
    } catch (err) {
      setSessions([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    let filtered = filteringSessions(sessions, filters, "incidentDate");
    let {
      // totalSessionTime,
      // averageSessionTime,
      // averagekWh,
      // totalkWh,
      // totalRange,
      // totalSessionAmt,
      // totalIdleAmt,
    } = filteredSessionsCalculations(filtered);

    setSummaries({
      // totalSessionTime,
      // averageSessionTime,
      // averagekWh,
      // totalkWh,
      // totalRange,
      // totalSessionAmt,
      // totalIdleAmt,
    });
    setFilteredSessions(filtered);
  }, [filters, sessions]);

  useEffect(() => {
    fetchingSessions();
  }, []);

  const updateSortedItems = (items) => {
    setPaginationSessions(items);
  };

  const tableHeader = strings.statusHeaderTable;
  const tableHeaderConvertor = statusHeaderConvert;


  const [isActiveSort, sortReverse, changeActiveSort] = useSortAndReverse(
    'incidentDate',
    filteredSessions,
    tableHeader,
    tableHeaderConvertor,
    updateSortedItems,
    'desc'
  );

  /**
   * pagination for the data
   * @param {*} dataArray - items that will be sorted
   * @param {*} itemsPerPage - Custom items limit per page
   */
  const {
    next,
    prev,
    jump,
    currentData,
    currentPage,
    maxPage,
    totalData,
    currentCount,
    pageNumbers,
  } = usePagination(paginationSessions, 20);

  useEffect(() => {
    if (fetchReportData) {
      let reportData = {
        sessions: filteredSessions,
        summaries: summaries,
      };
      fetchReportData(reportData);
    }
    // eslint-disable-next-line
  }, [filteredSessions, summaries]);

  const tableMaxItemSize = (title) => {
    switch (true) {
      case title === 'Charger Id':
        return '90px';      
        case title === 'Incident Date':
          return '90px';
        case title === 'Source':
          return '80px';
        default:
          return '110px';
    }
  };

  const tableMinItemSize = (title) => {
    switch (true) {
      case title === 'Charger Id':
         return '90px';
      case title === 'Incident Date':
        return '90px';
      case title === 'Source':
        return '80px';
      default:
        return '110px';
    }
  };

  return (
    <>
      <DivStyles padding='10px'>{loading && <LoadingSpinner />}</DivStyles>
      <Section padding='0 0 10px' borderBottom='none'>
        <DivStyles display='flex' flexWrap='wrap'>
          <TableStyles
            flex='1'
            borderTop='1px solid #e7e7e7'
            borderRight='1px solid #e7e7e7'>
            <Summaries
              justifyContent='center'
              titles={['Incidences']}
              content={[
                filteredSessions.length,
              ]}
            />
          </TableStyles>
        </DivStyles>
      </Section>
      <Pagination
        currentCount={currentCount}
        totalData={totalData}
        next={next}
        prev={prev}
        jump={jump}
        currentPage={currentPage}
        maxPage={maxPage}
        pageNumbers={pageNumbers}
      />
      <TableStyles minHeight='500px' maxHeight='500px'>
        <TableHeaderStyles
          borderTop='none'
          borderLeft='none'
          borderRight='none'
          width='100%'
          minWidth='1160px'>
          {tableHeader.map((title, i) => {
            return (
              <TableItemStyles
                width='100%'
                minWidth={tableMinItemSize(title)}
                maxWidth={tableMaxItemSize(title)}
                borderRight
                key={i}
                onClick={() => {
                  changeActiveSort(tableHeaderConvertor[title]);
                }}>
                {isActiveSort[tableHeader[title]] && (
                  <DivStyles
                    position='absolute'
                    left='5px'
                    top='45%'
                    transform='translate(0, -55%)'>
                    {sortReverse[tableHeader[title]] ? (
                      <FaLongArrowAltDown />
                    ) : (
                      <FaLongArrowAltUp />
                    )}{' '}
                  </DivStyles>
                )}
                {title}
              </TableItemStyles>
            );
          })}
        </TableHeaderStyles>
        {currentData().map((status, i) => (
          <StatusDetailsItem key={i} page='reportspage' status={status} />
        ))}
        {filteredSessions.length === 0 && !loading && (
          <ListPlaceholderStyles borderBottom='none'>
            <p>{strings.noResults}</p>
          </ListPlaceholderStyles>
        )}
        {sessions.length === 0 && !loading && (
          <ListPlaceholderStyles borderBottom='none'>
            <p>{strings.noSessions}</p>
          </ListPlaceholderStyles>
        )}
      </TableStyles>
    </>
  );
};

export default StatusDetailsList;
