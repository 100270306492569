import React, { useState, useEffect } from 'react';
import { FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa';
import moment from 'moment';

//services
import AppChargersService from '../../AppChargersService';

//components
import ChargersCard from './ChargersCard';

//global components
import Pagination from '../../../../shared/Pagination/Pagination';
import LoadingSpinner from '../../../../shared/Loading/LoadingSpinner';

//hooks
import { usePagination } from '../../../../shared/hooks/usePagination';
import { useSortAndReverse } from '../../../../shared/hooks/useSortAndReverse';
import { useRouter } from '../../../../shared/hooks/useRouter';

//styles
import {
  TableStyles,
  TableHeaderStyles,
  TableItemStyles,
  ListPlaceholderStyles,
} from '../../../../SharedStyles';

//helpers
import { tableHeaderConvert, tableAdminHeaderConvert } from '../../helpers';

const ChargersList = (props) => {
  const { strings } = AppChargersService;
  const { filteredValue, searchedValue } = props;
  const router = useRouter();
  const [sessions, setSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchingSessions = async () => {
    let history = await AppChargersService.getChargerHistory(
      router.match.params.id
    );

    let revisedHistory = history.map((item) => {
      if (item.idTag) {
        // item.idTag = item.userId;
      }
      return item;
    });

    setSessions(revisedHistory);

    if (!revisedHistory.length) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchingSessions();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFilteredSessions(sessions);
    // eslint-disable-next-line
  }, [sessions]);

  useEffect(() => {
    if (filteredSessions.length) {
      setLoading(false);
    }
  }, [filteredSessions]);

  const updateSortedItems = (items) => {
    setFilteredSessions(items);
  };

  /**
   * Can be two of these per page for enterprise and non-enterprise
   * @param {*} sortStatus - status for the default sort
   * @param {*} dataArray - items that will be sorted
   * @param {*} headerText - strings array that will show user friendly name to map over
   * @param {*} headerConvert - converts strings in headerText array to object notation, custom function needed for this logic
   * @param {*} updateSortedItem - Function to update the current state with the sorted items
   */
  const tableHeader =
    props.account.subscriptionLevel === 3
      ? strings.headerAdminData
      : strings.headerData;
  const tableHeaderConvertor =
    props.account.subscriptionLevel === 3
      ? tableAdminHeaderConvert
      : tableHeaderConvert;

  const [isActiveSort, sortReverse, changeActiveSort] = useSortAndReverse(
    'transactionDate',
    sessions,
    tableHeader,
    tableHeaderConvertor,
    updateSortedItems,
    'desc'
  );

  /**
   * pagination for the data
   * @param {*} dataArray - items that will be sorted
   * @param {*} itemsPerPage - Custom items limit per page
   */
  const {
    next,
    prev,
    jump,
    currentData,
    currentPage,
    maxPage,
    totalData,
    currentCount,
    pageNumbers,
  } = usePagination(filteredSessions, 20);

  useEffect(() => {
    let lowercasedSearch = searchedValue.toLowerCase();

    const searchedSessions = sessions.filter((sess) => {
      return Object.keys(sess).some((key) => {
        if (filteredValue === 'transactionDate') {
          return (
            moment(sess['transactionDate']).format('MM/DD/YYYY') ===
            lowercasedSearch
          );
        }
        if (
          filteredValue === 'rateType' &&
          searchedValue.toLowerCase() === 'utility'
        ) {
          return sess['rateType'] === 'Owned';
        }
        if (filteredValue.toLowerCase() === 'free') {
          return (
            //checking undefined at the time due to bad data in past records
            sess['rateType'] !== undefined &&
            sess['rateType'].toLowerCase() === 'free'
          );
        }

        if (
          sess[filteredValue ? filteredValue : key] !== null &&
          sess[filteredValue ? filteredValue : key] !== undefined
        ) {
          return sess[filteredValue ? filteredValue : key]
            .toString()
            .toLowerCase()
            .includes(lowercasedSearch);
        }
        return null;
      });
    });
    setFilteredSessions(searchedSessions);
    if (maxPage > 1) {
      jump(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedValue, filteredValue, sessions]);

  const tableMaxItemSize = (title) => {
    switch (true) {
      case title === 'Detail':
        return '75px';
      default:
        return 'auto';
    }
  };

  const tableMinItemSize = (title) => {
    switch (true) {
      case title === 'Location':
        return '90px';
      case title === 'Charger':
        return '80px';
      case title === 'Date':
        return '80px';
      default:
        return 'auto';
    }
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      {sessions.length !== 0 && (
        <Pagination
          currentCount={currentCount}
          totalData={totalData}
          next={next}
          prev={prev}
          jump={jump}
          currentPage={currentPage}
          maxPage={maxPage}
          pageNumbers={pageNumbers}
        />
      )}
      <TableStyles minHeight='500px' maxHeight='500px'>
        <TableHeaderStyles
          borderTop='none'
          borderLeft='none'
          borderRight='none'
          width='100%'
          minWidth='778px'>
          {tableHeader.map((title, i) => {
            return (
              <TableItemStyles
                width='100%'
                minWidth={tableMinItemSize(title)}
                maxWidth={tableMaxItemSize(title)}
                borderRight
                key={i}
                onClick={() => {
                  changeActiveSort(tableHeaderConvertor[title]);
                }}>
                {isActiveSort[tableHeaderConvertor[title]] && (
                  <div>
                    {sortReverse[tableHeaderConvertor[title]] ? (
                      <FaLongArrowAltDown />
                    ) : (
                      <FaLongArrowAltUp />
                    )}{' '}
                  </div>
                )}
                {title}
              </TableItemStyles>
            );
          })}
        </TableHeaderStyles>
        {currentData().map((session) => (
          <ChargersCard
            page='chargerspage'
            key={session.transactionId}
            account={props.account}
            transactionDate={session.transactionDate}
            transactionId={session.transactionId}
            userId={session.userId}
            totalTime={session.totalTime}
            avgkW={session.avgkW}
            totalkWh={session.totalkWh}
            milesAdded={session.milesAdded}
            rateType={session.rateType}
            rate={session.rate}
            totalCost={session.totalCost}
            idleRateType={session.idleRateType}
            idleRate={session.idleRate}
            idleTime={session.idleTime}
            idleCost={session.idleCost}
          />
        ))}
        {sessions.length > 0 && !filteredSessions.length && !loading && (
          <ListPlaceholderStyles borderBottom='none'>
            <p>{strings.noResults}</p>
          </ListPlaceholderStyles>
        )}
        {sessions.length === 0 && !loading && (
          <ListPlaceholderStyles borderBottom='none'>
            <p>{strings.noSessions}</p>
          </ListPlaceholderStyles>
        )}
      </TableStyles>
    </>
  );
};

export default ChargersList;
