import React from 'react';
import moment from 'moment';

//service
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';

//styles
import { DivStyles, TextStyles } from '../../SharedStyles';

const EarnedData = ({ account, strings, earnedIncome }) => {
  console.log(earnedIncome);
  return (
    <DivStyles maxWidth='60%'>
      <DivStyles display='flex' alignItems='center' margin='15px'>
        <TextStyles
          size='14px'
          color='#8f8d8d'
          flex='1'
          maxWidth='200px'
          weight='500'>
          {strings.paymentFreq}
        </TextStyles>
        <TextStyles size='14px' color='#8f8d8d' flex='1'>
          {earnedIncome.payoutFrequency}
        </TextStyles>
      </DivStyles>
      <DivStyles display='flex' alignItems='center' margin='15px'>
        <TextStyles
          size='14px'
          color='#8f8d8d'
          flex='1'
          maxWidth='200px'
          weight='500'>
          {strings.lastPaymentDate}
        </TextStyles>
        <TextStyles size='14px' color='#8f8d8d' flex='1'>
          {earnedIncome.lastPaymentDate !== '0001-01-01T00:00:00'
            ? moment(earnedIncome.lastPaymentDate).format('MM/DD/YYYY')
            : 'Never'}
        </TextStyles>
      </DivStyles>
      <DivStyles display='flex' alignItems='center' margin='15px'>
        <TextStyles
          size='14px'
          color='#8f8d8d'
          flex='1'
          maxWidth='200px'
          weight='500'>
          {strings.nextPaymentDate}
        </TextStyles>
        <TextStyles size='14px' color='#8f8d8d' flex='1'>
          {earnedIncome.nextPaymentDate !== '0001-01-01T00:00:00'
            ? moment(earnedIncome.nextPaymentDate).format('MM/DD/YYYY')
            : 'Never'}
        </TextStyles>
      </DivStyles>
      <DivStyles display='flex' alignItems='center' margin='15px'>
        <TextStyles
          size='14px'
          color='#8f8d8d'
          flex='1'
          maxWidth='200px'
          weight='500'>
          {strings.lastPaymentAmount}
        </TextStyles>
        <TextStyles size='14px' color='#8f8d8d' flex='1'>
          {AppPlatformService.currencyFormat(earnedIncome.lastPaymentAmount)}
        </TextStyles>
      </DivStyles>
      <DivStyles display='flex' alignItems='center' margin='15px'>
        <TextStyles
          size='14px'
          color='#8f8d8d'
          flex='1'
          maxWidth='200px'
          weight='500'>
          {strings.currentEarnedIncome}
        </TextStyles>
        <TextStyles size='14px' color='#8f8d8d' flex='1'>
          {AppPlatformService.currencyFormat(earnedIncome.pendingIncomeAmount)}
        </TextStyles>
      </DivStyles>
      <DivStyles display='flex' alignItems='center' margin='15px'>
        <TextStyles
          size='14px'
          color='#8f8d8d'
          flex='1'
          maxWidth='200px'
          weight='500'>
          {strings.pendingBankFees}
        </TextStyles>
        <TextStyles size='14px' color='#8f8d8d' flex='1'>
          {AppPlatformService.currencyFormat(earnedIncome.pendingPercentageBasedBankFee)}
        </TextStyles>
      </DivStyles>
      <DivStyles display='flex' alignItems='center' margin='15px'>
        <TextStyles
          size='14px'
          color='#8f8d8d'
          flex='1'
          maxWidth='200px'
          weight='500'>
          {strings.pendingXferFees}
        </TextStyles>
        <TextStyles size='14px' color='#8f8d8d' flex='1'>
          {AppPlatformService.currencyFormat(earnedIncome.pendingFlatTransferFee)}
        </TextStyles>
      </DivStyles>
      <DivStyles display='flex' alignItems='center' margin='15px'>
        <TextStyles
          size='14px'
          color='#8f8d8d'
          flex='1'
          maxWidth='200px'
          weight='500'>
          {strings.pendingPayoutAmount}
        </TextStyles>
        <TextStyles size='14px' color='#8f8d8d' flex='1'>
          {AppPlatformService.currencyFormat(earnedIncome.pendingTransferAmount)}
        </TextStyles>
      </DivStyles>
    </DivStyles>
  );
};

export default EarnedData;
