import React, { useState, useContext } from 'react';
import { FaDoorOpen, FaInfoCircle, FaTrashAlt } from 'react-icons/fa';

//services
import AppMembershipsService from '../AppMembershipsService';

//Modals
import JoinModal from '../Modals/JoinModal';
import membershipsPlaceholder from '../../../../assets/images/MembershipsPlaceholder.png';
import RemoveModal from '../Modals/RemoveModal';

//hooks
import { useRouter } from '../../../shared/hooks/useRouter';

//context
import { DriverContext } from '../../../context/driver/getDriverContext';

//styles
import { ListCardStyles, ListActionStyles } from '../../../DriverStyles';
import { TextStyles } from '../../../SharedStyles';

const MembershipCard = (props) => {
  const { strings } = AppMembershipsService;
  const { driver } = useContext(DriverContext);

  const router = useRouter();
  const [joinModal, setJoinModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const { membership } = props;

  const handleInfo = (route) => {
    if (route === 'info') {
      router.history.push(`/memberships/info/${membership.id}`);
    }
    if (route === 'map') {
      router.history.push(`/memberships/map/${membership.id}`);
    }
    if (route === 'join') {
      setJoinModal(!joinModal);
    }
  };

  const removeMembership = () => {
    setDeleteModal(!deleteModal);
  };

  return (
    <ListCardStyles minWidth='100px'>
      {joinModal && (
        <JoinModal closeModal={handleInfo} membership={membership} />
      )}
      {deleteModal && (
        <RemoveModal
          closeModal={removeMembership}
          removeMembership={props.removeMembership}
          membership={membership}
        />
      )}
      <div className='Image' onClick={() => handleInfo('info')}>
        <img
          src={
            membership.imagePath ? membership.imagePath : membershipsPlaceholder
          }
          alt={membership.membershipName}
        />
      </div>
      <div className='Content' onClick={() => handleInfo('info')}>
        <span className='Span'>
          <h5 className='Label Title'>{membership.membershipName}</h5>
        </span>
        <span className='Span'>
          <h5 className='Label'>{strings.labelLocation}:</h5>
          <p className='Data'>{membership.locationDescription}</p>
        </span>
        <span className='Span'>
          <h5 className='Label'>{strings.labelAccessibleTo}:</h5>
          <p className='Data'>
            {membership.availabilityTo
              ? membership.availabilityTo
              : strings.none}
          </p>
        </span>
        <span className='Span'>
          <h5 className='Label'>{strings.labelChargingRate}:</h5>
          <p className='Data'>
            {membership.discountSchedulePercentage
              ? `${membership.discountSchedulePercentage}%`
              : strings.none}
          </p>
        </span>
        {!props.removeMembership &&
          membership.memberIds.includes(driver.id) &&
          membership.approved &&
          !membership.paused && (
            <span className='Span'>
              <TextStyles color='red' size='12px' margin='0' weight='600'>
                {strings.alreadyMember}
              </TextStyles>
            </span>
          )}
        {!membership.enabled && (
          <TextStyles color='red' size='12px' margin='0' weight='600'>
            {strings.currentlyUnavailable}
          </TextStyles>
        )}
        {membership.memberIds.includes(driver.id) && !membership.approved && (
          <TextStyles color='red' size='12px' margin='0' weight='600'>
            {strings.pendingApproval}
          </TextStyles>
        )}
        {membership.memberIds.includes(driver.id) && membership.paused && (
          <TextStyles color='red' size='12px' margin='0' weight='600'>
            {strings.membershipPaused}
          </TextStyles>
        )}
      </div>
      <ListActionStyles>
        <div className='Action-Item'>
          <FaInfoCircle onClick={() => handleInfo('info')} />
        </div>
        {props.removeMembership ? (
          <div className='Action-Item'>
            <FaTrashAlt onClick={removeMembership} />
          </div>
        ) : (
          <>
            {!membership.memberIds.includes(driver.id) && (
              <div className='Action-Item'>
                <FaDoorOpen
                  onClick={() => {
                    handleInfo('join');
                  }}
                />
              </div>
            )}
          </>
        )}
      </ListActionStyles>
    </ListCardStyles>
  );
};

export default MembershipCard;
