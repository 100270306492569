import React, { useState, useContext, useEffect, useRef } from 'react';

//layout
import { OuterWrapper, ComponentWrapper } from '../../OperatorShared';
import Button from '../../../shared/Buttons/Button';

//services
import AppMeterGroupsService from '../AppMeterGroupsService';

//components
import MeterGroupHeader from './MeterGroupDetails/MeterGroupHeader';
import MeterGroupDetails from './MeterGroupDetails/MeterGroupDetails';
import MeterGroupOptions from './MeterGroupsOptions';

//global components
import EnterpriseAdmin from '../../EnterpriseAdmin';
import ToggleSwitch from '../../OperatorShared/ToggleSwitch/CsoToggleSwitch';
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';
import RouteLeavingGuard from '../../../shared/LeaveGuard/LeaveGuard';

//modals
import MaxAmpsModal from '../MeterGroupsModals/DisplayModals/MaxAmpsModal';
import LoadLimitModal from '../MeterGroupsModals/DisplayModals/LoadLimitModal';
import EditModal from '../MeterGroupsModals/DisplayModals/EditModal';
import PhaseModal from '../MeterGroupsModals/DisplayModals/PhaseModal';
import VoltageModal from '../MeterGroupsModals/DisplayModals/VoltageModal';
import CircuitsModal from '../MeterGroupsModals/DisplayModals/CircuitsModal';
import MissingInfoModal from '../MeterGroupsModals/DisplayModals/MissingInfoModal';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import { ErrorContext } from '../../../context/shared/ErrorContext';

//styles
import { EnableContainerStyles } from '../../../OperatorStyles';
import { TitleStyles } from '../../../SharedStyles';

//helpers
import { checkingValueOfZero } from '../../helpers';
import { diff } from '../../../shared/LeaveGuard/ObjDiffCheck';

//error Class
import { ErrorResponse } from '../../../shared/ErrorResponse';

const MeterGroupsAdd = (props) => {
  const { strings } = AppMeterGroupsService;
  const { account, setAccount, currentUser } = useContext(CsoAccountContext);
  const { setError } = useContext(ErrorContext);
  const [meterGroup, setMeterGroup] = useState({
    phaseType: 0,
    voltage: 0,
    maxAmps: 0,
    loadLimit: 0,
    circuits: [],
  });
  const [loading, setLoading] = useState(false);
  const [finishedUpdating, setFinishedUpdating] = useState(false);
  const [editMeterGroupModal, setEditMeterGroupModal] = useState(false);
  const [voltageModal, setVoltageModal] = useState(false);
  const [phaseModal, setPhaseModal] = useState(false);
  const [circuitsModal, setCircuitsModal] = useState(false);
  const [maxAmpsModal, setMaxAmpsModal] = useState(false);
  const [loadLimitModal, setLoadLimitModal] = useState(false);
  const [disableEnableButton, setDisableEnableButton] = useState(true);
  const [missingInfoModal, setMissingInfoModal] = useState(false);

  const buttonRef = useRef(null);
  const [prevMeterGroup, setPrevMeterGroup] = useState({});
  const [isBlocking, setIsBlocking] = useState(false);

  useEffect(() => {
    setPrevMeterGroup(meterGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let data = diff(meterGroup, prevMeterGroup);
    if (Object.keys(data).length > 0) {
      setIsBlocking(true);
    } else {
      setIsBlocking(false);
    }
  }, [meterGroup, prevMeterGroup]);

  const toggleBlocking = () => {
    setIsBlocking(!isBlocking);
  };

  const handleSubmitCheck = () => {
    buttonRef.current.click();
  };

  let adminPermission;
  if (currentUser) {
    adminPermission = currentUser.role === 1;
  }

  useEffect(() => {
    if (finishedUpdating) {
      setTimeout(() => {
        props.history.push('/meter-groups');
      }, 400);
    }
  }, [finishedUpdating, props.history]);

  const setButtonDisable = (boo) => {
    setDisableEnableButton(boo);
  };

  //displays Phase modal
  const togglePhaseModal = () => {
    setPhaseModal(!phaseModal);
  };

  //displays Voltage modal
  const toggleVoltageModal = () => {
    setVoltageModal(!voltageModal);
  };

  //displays amps modal
  const toggleAmpsModal = () => {
    setMaxAmpsModal(!maxAmpsModal);
  };
  //display circuits modal
  const toggleCircuitsModal = () => {
    setCircuitsModal(!circuitsModal);
  };

  //displays limitmodal
  const toggleLoadLimitModal = () => {
    setLoadLimitModal(!loadLimitModal);
  };
  //displays the edit MG modal
  const toggleMeterGroupModal = () => {
    setEditMeterGroupModal(!editMeterGroupModal);
  };
  //enable and disables metergroup
  const handleEnable = () => {
    setMeterGroup({ ...meterGroup, enabled: !meterGroup.enabled });
  };
  //display missing data
  const handleMissingInfoModal = () => {
    setMissingInfoModal(!missingInfoModal);
  };
  //handles the update of information from the modals
  const handleDataChange = (key, value) => {
    if (key === 'circuits') {
      if (value.length === 0) {
        setMeterGroup({
          ...meterGroup,
          [key]: value,
          circuitCount: value.length,
          enabled: false,
        });
      } else {
        setMeterGroup({
          ...meterGroup,
          [key]: value,
          circuitCount: value.length,
        });
      }
    } else if (key === 'meterGroup') {
      setMeterGroup({ ...meterGroup, ...value });
    } else {
      setMeterGroup({ ...meterGroup, [key]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (
        meterGroup.enableMeterGroupChargingProfile &&
        !meterGroup.chargingProfileId
      ) {
        throw new ErrorResponse(
          'Frontend',
          strings.missingProfileIdError,
          strings.missingInfoTitle
        );
      }
      if (
        meterGroup.enablePowerManagementPolicy &&
        !meterGroup.powerManagementPolicyId
      ) {
        throw new ErrorResponse(
          'Frontend',
          strings.missingPowerPolicyError,
          strings.missingInfoTitle
        );
      }
      let createMG = {
        locationId: meterGroup.locationId,
        newMeterGroup: meterGroup,
      };
      setPrevMeterGroup(meterGroup);
      if (createMG.locationId) {
        let updatedAccount = await AppMeterGroupsService.createMeterGroup(
          createMG
        );
        setAccount(updatedAccount);
        setFinishedUpdating(true);
      } else {
        setMissingInfoModal(true);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      if (err.name === 'Frontend') {
        setError({
          display: true,
          title: err.title,
          message: err.message,
          styles: 'cso',
        });
      } else {
        setError({
          display: true,
          title: 'Something went wrong',
          message: err.response.data.split(/[|]+/).pop(),
          styles: 'cso',
        });
      }
    }
  };

  useEffect(() => {
    if (checkingValueOfZero(meterGroup)) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [meterGroup]);

  return (
    <OuterWrapper
      title={
        adminPermission
          ? strings.add
          : `${strings.meterGroupInfo} - ${strings.readOnly}`
      }
      internal
      path='/meter-groups'
      adminPermission={adminPermission}
      roles={[1]}
      myForm>
      {/* =====================Enterprise Only===================================== */}
      <EnterpriseAdmin>
        <RouteLeavingGuard
          cso
          when={isBlocking}
          toggleBlocking={toggleBlocking}
          action={handleSubmitCheck}
          loading={loading}
          navigate={(path) => props.history.push(path)}
          shouldBlockNavigation={(location) => {
            if (isBlocking) {
              return true;
            }
            return false;
          }}
        />
        <ComponentWrapper
          title={
            adminPermission
              ? strings.add
              : `${strings.meterGroupInfo} - ${strings.readOnly}`
          }>
          {loading && <LoadingSpinner />}
          {phaseModal && (
            <PhaseModal
              handleCloseModal={togglePhaseModal}
              handleDataChange={handleDataChange}
              phase={meterGroup.phaseType}
            />
          )}
          {voltageModal && (
            <VoltageModal
              handleCloseModal={toggleVoltageModal}
              handleDataChange={handleDataChange}
              voltage={meterGroup.voltage}
            />
          )}
          {circuitsModal && (
            <CircuitsModal
              addCircuits
              handleCloseModal={toggleCircuitsModal}
              handleDataChange={handleDataChange}
              circuits={meterGroup.circuits}
              meterGroupId={meterGroup.meterGroupId}
              meterGroup={meterGroup}
            />
          )}
          {maxAmpsModal && (
            <MaxAmpsModal
              handleCloseModal={toggleAmpsModal}
              handleDataChange={handleDataChange}
              maxAmps={meterGroup.maxAmps}
            />
          )}
          {loadLimitModal && (
            <LoadLimitModal
              handleCloseModal={toggleLoadLimitModal}
              handleDataChange={handleDataChange}
              loadLimit={meterGroup.loadLimit}
            />
          )}
          {editMeterGroupModal && (
            <EditModal
              handleCloseModal={toggleMeterGroupModal}
              handleDataChange={handleDataChange}
              meterGroup={meterGroup}
              account={account}
            />
          )}
          {missingInfoModal && (
            <MissingInfoModal
              missingData
              handleCloseModal={handleMissingInfoModal}
            />
          )}
          <form id='myForm' onSubmit={handleSubmit}>
            <MeterGroupHeader
              meterGroup={meterGroup}
              toggleMeterGroupModal={toggleMeterGroupModal}
            />
            <EnableContainerStyles>
              <TitleStyles size='18px' margin='0' weight='500'>
                {strings.formEnableMeterGroup}
              </TitleStyles>
              <ToggleSwitch
                adminPermission={adminPermission}
                name='enabled'
                type='metergroup'
                disable={disableEnableButton}
                checked={!!meterGroup.enabled}
                handleChange={handleEnable}
              />
            </EnableContainerStyles>
            <MeterGroupDetails
              add
              meterGroup={meterGroup}
              setButtonDisable={setButtonDisable}
              togglePhaseModal={togglePhaseModal}
              toggleVoltageModal={toggleVoltageModal}
              toggleAmpsModal={toggleAmpsModal}
              toggleCircuitsModal={toggleCircuitsModal}
              toggleLoadLimitModal={toggleLoadLimitModal}
            />
            <MeterGroupOptions
              meterGroup={meterGroup}
              handleDataChange={handleDataChange}
            />
          </form>
        </ComponentWrapper>
        <Button
          cso
          hasMobile
          formSubmit
          ref={buttonRef}
          buttonText={strings.editSave}
          roles={[1]}
        />
      </EnterpriseAdmin>
      {/* ========================================================================= */}
    </OuterWrapper>
  );
};

export default MeterGroupsAdd;
