import React, { useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useSwipeable } from 'react-swipeable';

//components
import SlideItem from './SlideItem';

//styles
import './SlideShow.scss';

const Swipeable = ({children, ...props}) => {
  const handlers = useSwipeable(props);
  return (<div { ...handlers }>{children}</div>);
}

const SliderComponent = (props) => {
  const [index, setIndex] = useState(0);
  const [forward, setForward] = useState(true);

  const nextSlide = (ev) => {
    setForward(true);
    setIndex((index + 1) % props.images.length);
  };

  const prevSlide = (ev) => {
    setForward(false);
    setIndex((index || props.images.length) - 1);
  };

  const handleIndex = (idx) => {
    setIndex(idx);
  };

  const addEndListener = (node, done) =>
    node.addEventListener('transitionend', done, false);

  return (
    <Swipeable onSwipedLeft={nextSlide} onSwipedRight={prevSlide}>
      <section
        className={`SlideShow-Component ${forward ? 'forwards' : 'backwards'}`}>
        <div
          className='next'
          onClick={nextSlide}
          style={
            index === props.images.length - 1
              ? { display: 'none' }
              : { display: 'block' }
          }
        />
        <div
          className='prev'
          onClick={prevSlide}
          style={index === 0 ? { display: 'none' } : { display: 'block' }}
        />
        <TransitionGroup>
          <CSSTransition
            key={index}
            classNames='slide'
            addEndListener={addEndListener}>
            <SlideItem
              updateDefaultImage={props.updateDefaultImage}
              charger={props.charger}
              handleIndex={handleIndex}
              prevSlide={prevSlide}
              updateCharger={props.updateCharger}
              image={props.images[index]}
              index={index}
              handleDataChange={props.handleDataChange}
            />
          </CSSTransition>
        </TransitionGroup>
      </section>

      {props.images.length > 1 && (
        <div className='indicator-container'>
          {props.images.map((image, i) => {
            return (
              <div
                key={i}
                onClick={() => setIndex(i)}
                className={
                  index === i
                    ? 'indicator-dot indicator-active'
                    : 'indicator-dot'
                }
              />
            );
          })}
        </div>
      )}
    </Swipeable>
  );
};

export default SliderComponent;
