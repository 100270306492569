import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa';

//services
import AppDashboardService from '../../AppDashboardService';

//components
import ChargingSessionsCard from './ChargingSessionCard';

//hooks
import { useSortAndReverse } from '../../../../shared/hooks/useSortAndReverse';

//styles
import {
  DivStyles,
  TitleStyles,
  SelectStyles,
  TableStyles,
  TableHeaderStyles,
  TableItemStyles,
  ListPlaceholderStyles,
} from '../../../../SharedStyles';

//helpers
import { tableHeaderEnterpriseConvert, tableHeaderConvert } from '../helpers';
import { ReportContext } from '../../../../context/operator/getReports';

const ChargingSessions = (props) => {
  const { loading } = props;
  const { strings } = AppDashboardService;
  const { sessionsContext } = useContext(ReportContext);
  const [sessions, setSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [filter, setFilter] = useState('1');

  const filterCurrentSessions = (items, filterOption) => {
    switch (filterOption) {
      case '1':
        let tenItems = items.filter((item, index) => {
          return index < 10;
        });
        return tenItems;
      case '2':
        let oneDay = moment().subtract(1, 'd').format('YYYY-MM-DD');
        let filteredDay = items.filter((item) => {
          return moment(item.transactionDate).format('YYYY-MM-DD') === oneDay;
        });
        return filteredDay;
      case '3':
        let oneMonth = moment().subtract(1, 'M').format('YYYY-MM');
        let filteredMonth = items.filter((item) => {
          return moment(item.transactionDate).format('YYYY-MM') === oneMonth;
        });
        return filteredMonth;
      default:
        return items;
    }
  };

  const fetchingSessions = async () => {
    let res = filterCurrentSessions(sessionsContext, filter);
    setSessions(res);
  };

  useEffect(() => {
    fetchingSessions();
    // eslint-disable-next-line
  }, [filter, sessionsContext]);

  const tableHeader =
    props.account.subscriptionLevel === 3
      ? strings.chargingSessionEnterpriseHeader
      : strings.chargingSessionHeader;
  const tableHeaderConvertor =
    props.account.subscriptionLevel === 3
      ? tableHeaderEnterpriseConvert
      : tableHeaderConvert;

  const updateSortedItems = (items) => {
    let res = filterCurrentSessions(items, filter);
    setFilteredSessions(res);
  };

  const [isActiveSort, sortReverse, changeActiveSort] = useSortAndReverse(
    'transactionDate',
    sessions,
    tableHeader,
    tableHeaderConvertor,
    updateSortedItems,
    'desc'
  );

  const handleChange = (e) => {
    setFilter(e.target.value);
  };

  return (
    <DivStyles
      width='100%'
      margin='0 0 0 5px'
      wrapMargin='0 0 5px'
      flex='1'
      borderRight='1px solid #e7e7e7'
      borderLeft='1px solid #e7e7e7'
      borderTop='1px solid #e7e7e7'
      borderBottom='1px solid #e7e7e7'>
      <TitleStyles
        size='14px'
        align='left'
        padding='5px'
        margin='0'
        borderBottom
        weight='400'>
        {strings.chargingSessionTitle}
      </TitleStyles>
      <DivStyles padding='0 10px 10px'>
        <SelectStyles
          width='150px'
          padding='3px 8px'
          margin='5px 0'
          bgPosition='.75em'
          size='14px'
          onChange={handleChange}
          value={filter}>
          {strings.filterSessionOptions.map((filter, idx) => (
            <option key={filter} value={idx + 1}>
              {filter}
            </option>
          ))}
        </SelectStyles>
      </DivStyles>
      <TableStyles
        minHeight='350px'
        maxHeight='350px'
        borderBottom='1px solid #e7e7e7'>
        <TableHeaderStyles
          hideScroll
          borderLeft='none'
          borderRight='none'
          borderTop='none'
          width='100%'
          minWidth='560px'>
          {tableHeader.map((title) => {
            return (
              <TableItemStyles
                width='80px'
                minWidth='80px'
                borderRight
                padding='0'
                key={title}
                onClick={() => {
                  changeActiveSort(tableHeaderConvertor[title]);
                }}>
                {isActiveSort[tableHeaderConvertor[title]] && (
                  <div>
                    {sortReverse[tableHeaderConvertor[title]] ? (
                      <FaLongArrowAltDown />
                    ) : (
                      <FaLongArrowAltUp />
                    )}{' '}
                  </div>
                )}
                {title}
              </TableItemStyles>
            );
          })}
        </TableHeaderStyles>
        {loading && (
          <ListPlaceholderStyles borderBottom='none'>
            <p>Loading...</p>
          </ListPlaceholderStyles>
        )}
        {filteredSessions.map((session) => (
          <ChargingSessionsCard key={session.transactionId} session={session} />
        ))}
        {sessions.length === 0 && !loading && (
          <ListPlaceholderStyles borderBottom='none'>
            <p>No Charging Sessions</p>
          </ListPlaceholderStyles>
        )}
      </TableStyles>
      <DivStyles align='right' linkColor='grey' margin='0 10px' size='14px'>
        <Link to='/reports/charging-session-detail'>more...</Link>
      </DivStyles>
    </DivStyles>
  );
};

export default ChargingSessions;
