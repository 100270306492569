import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppSystemUserService from '../AppSystemUsersService';

//global components
import { LoadingSpinner } from '../../../shared';
import ModalButton from '../../../shared/Buttons/ModalButton';

//hooks
import { useCloudinary } from '../../../shared/Cloudinary/hook/useCloudinary';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  ButtonDivGreyStyles,
} from '../../../SharedStyles';

const ImageModal = (props) => {
  const { strings } = AppSystemUserService;
  const { user, toggleModal, updateImage } = props;

  const { cloudData, uploadImage, imageLoading } = useCloudinary({
    preset: 'system_users_images',
    tags: [`${user.firstName} ${user.lastName}`, user.id],
  });

  const closeModal = () => {
    toggleModal();
  };

  //clicks the hidden input for image upload
  const openDialog = () => {
    document.getElementById('inputUpload').click();
  };

  const handleImageChange = async () => {
    updateImage(cloudData.secure_url);
    closeModal();
  };

  const handleSaveInfo = (e) => {
    e.preventDefault();
    if (cloudData) {
      handleImageChange();
    } else {
      closeModal();
    }
  };

  return (
    <ModalStyles>
      {imageLoading && <LoadingSpinner />}
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.imageTitle}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <p>{strings.imageContent}</p>
          </ModalItemStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <img
              className='SystemUser-modal-profile'
              src={
                cloudData
                  ? cloudData.secure_url
                  : user.imagePath
                  ? user.imagePath
                  : 'http://placehold.it/400x200'
              }
              alt={user.firstName}
            />
          </ModalItemStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <input
              className='file-input'
              id='inputUpload'
              type='file'
              hidden
              accept='image/*'
              onChange={uploadImage}
            />
            <ButtonDivGreyStyles id='fileUpload' onClick={openDialog}>
              {strings.imageButton}
            </ButtonDivGreyStyles>
          </ModalItemStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={handleSaveInfo}
          buttonText={strings.save}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default ImageModal;
