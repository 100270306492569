import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  FaRegEdit,
  FaChargingStation,
  FaRegTrashAlt,
  FaEllipsisV,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

//services
import AppMeterGroupsService from '../AppMeterGroupsService';

//global components
import RoleCanEdit from '../../OperatorShared/RoleCanEdit';
import { LoadingSpinner } from '../../../shared';

//modals
import RedirectChargers from '../MeterGroupsModals/RedirectModals/RedirectChargers';
import DeleteModal from '../MeterGroupsModals/DisplayModals/DeleteModal';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import { ErrorContext } from '../../../context/shared/ErrorContext';

//hooks
import useOnClickOutside from '../../../shared/hooks/useOnClickOutside';
import { useWindowResize } from '../../../shared/hooks/useWindowSize';
import { useContainerHeight } from '../../../shared/hooks/useContainerHeight';

//helpers
import { tenPercentDiff } from '../../helpers';

//styles
import { DataCardStyles, ToolsStyles } from '../../../OperatorStyles';
import { DivStyles, TextStyles } from '../../../SharedStyles';

const MeterGroupsCard = (props) => {
  const { strings } = AppMeterGroupsService;
  const { meterGroup } = props;
  const { setAccount } = useContext(CsoAccountContext);
  const { setError } = useContext(ErrorContext);
  const CardRef = useRef();
  const OutsideRef = useRef();
  const size = useWindowResize(window.innerWidth, window.innerHeight);
  const { containerHeight, containerRef, setContainerRef } = useContainerHeight(
    50,
    CardRef
  );

  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [chargersModal, setChargersModal] = useState(false);
  const [openTools, setOpenTools] = useState(false);

  useOnClickOutside(OutsideRef, () => setOpenTools(false));

  const handleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleChargersModal = () => {
    setChargersModal(!chargersModal);
  };

  const toggleToolBar = () => {
    setOpenTools(!openTools);
  };

  const handleDeleteMG = async (id) => {
    try {
      setLoading(true);
      let updatedAccount = await AppMeterGroupsService.deleteMeterGroup(id);
      setAccount(updatedAccount);
      if (updatedAccount) {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      if (err.response.data === 'MeterGroup Last One') {
        setError({
          display: true,
          title: strings.failedLocationDelete,
          message: strings.failedLocationDeleteMessage,
          styles: 'cso',
        });
      }
      if (err.response.data === 'MeterGroup Charger Assigned') {
        setError({
          display: true,
          title: strings.failedChargerDelete,
          message: strings.failedChargerDeleteMessage,
          styles: 'cso',
        });
      }
      if (err.response.data === 'MeterGroup Not Found') {
        setError({
          display: true,
          title: strings.failedChargerDelete,
          message: strings.failedNotFoundMessage,
          styles: 'cso',
        });
      }
    }
  };

  //gets card container height for responsive
  useEffect(() => {
    setContainerRef(CardRef);
  }, [CardRef, setContainerRef]);

  const CountItem = (props) => {
    return (
      <TextStyles color={props.color} margin='0' weight={props.value === 0 ? null : '600'}>
        {props.value}
      </TextStyles>
    )
  }

  const PortStat = ({ portStat }) => {
    return (
      <div className='Card-Data-Item'>
        <DivStyles display='flex' margin='0'>
          <CountItem color='#07e019' value={portStat.availablePortCount} />
          /
          <CountItem color='#4f8ef5' value={portStat.connectedPortCount} />
          /
          <CountItem color='orange' value={portStat.chargingPortCount} />
          /
          <CountItem color='grey' value={portStat.disabledPortCount} />
          /
          <CountItem color='red' value={portStat.troublePortCount} />
          /
          <CountItem color='lightgrey' value={portStat.offLinePortCount} />
        </DivStyles>
      </div>
    )
  }

  return (
    <DataCardStyles
      ref={containerRef}
      onMouseEnter={() => {
        size[0] >= 768 && setOpenTools(true);
      }}
      onMouseLeave={() => {
        size[0] >= 768 && setOpenTools(false);
      }}>
      {deleteModal && (
        <DeleteModal
          meterGroup={meterGroup}
          handleDeleting={handleDeleteMG}
          handleCloseModal={handleDeleteModal}
        />
      )}
      {chargersModal && (
        <RedirectChargers
          meterGroup={meterGroup}
          handleCloseModal={handleChargersModal}
        />
      )}
      {loading && <LoadingSpinner />}
      <Link to={`/meter-groups/${meterGroup.meterGroupId}`}>
        <div className='Card-Left'>
          <div className='Card-Content'>
            <div className='Card-Content-Title'>{meterGroup.name}</div>
            <div className='Card-Content-Subtitle'>
              {meterGroup.locationAddress}
            </div>
          </div>
        </div>
        <div className='Card-Right'>
          <div className='Card-Data'>
            <div className='Card-Data-Item'>{meterGroup.maxAmps}A</div>
            <div
              className={
                tenPercentDiff(meterGroup.loadLimit, meterGroup.currentLoad)
                  ? `Card-Data-Item danger-text`
                  : `Card-Data-Item`
              }>
              {meterGroup.portStat.currentLoad.toFixed(0)}A
            </div>
            <PortStat portStat={meterGroup.portStat} />
          </div>
        </div>
      </Link>
      <FaEllipsisV
        className='Card-Tools-Toggle'
        style={size[0] >= 768 ? { display: 'none' } : { display: 'block' }}
        onClick={toggleToolBar}
      />
      <ToolsStyles
        ref={OutsideRef}
        displayTools={openTools}
        containerHeight={(containerHeight - 35) / 2}>
        <Link
          to={`/meter-groups/${meterGroup.meterGroupId}`}
          title={strings.editMeterGroup}
          className='Tool-Item'>
          <FaRegEdit />
        </Link>
        <div className='Tool-Item' onClick={handleChargersModal}>
          <FaChargingStation title={strings.chargerTitle} />
        </div>
        <RoleCanEdit roles={[1]}>
          <div className='Tool-Item' onClick={handleDeleteModal}>
            <FaRegTrashAlt title={strings.deleteModalTitle} />
          </div>
        </RoleCanEdit>
      </ToolsStyles>
    </DataCardStyles>
  );
};

export default MeterGroupsCard;
