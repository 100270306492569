import React from 'react';

//components
import IncomeCard from './IncomeCard';

//styles
import {
  ListPlaceholderStyles,
  TableStyles,
  TableHeaderStyles,
  TableItemStyles,
} from '../../../../../../../../SharedStyles';

const IncomeList = (props) => {
  const { strings, statementDetails } = props;

  return (
    <TableStyles width='100%' minHeight='40px' hideScroll>
      <TableHeaderStyles
        borderTop='none'
        borderLeft='none'
        borderRight='none'
        width='780px'>
        {strings.incomeHeader.map((title, i) => {
          return (
            <TableItemStyles
              width='100%'
              minWidth={
                title === 'Trans ID'
                  ? '200px'
                  : title === 'Bank Name'
                  ? '125px'
                  : 'auto'
              }
              borderRight
              key={i}>
              {title}
            </TableItemStyles>
          );
        })}
      </TableHeaderStyles>
      {/* Credits to payout on the Payout View Page */}
      <IncomeCard item={statementDetails} />
      {Object.keys(statementDetails).length === 0 && (
        <ListPlaceholderStyles>No Payouts</ListPlaceholderStyles>
      )}
    </TableStyles>
  );
};

export default IncomeList;
