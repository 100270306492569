import React from 'react';

//services
import AppSystemUsersService from '../../AppSystemUsersService';

//styles
import {
  FormContainerStyles,
  FormRowStyles,
  InputStyles,
  TitleStyles,
} from '../../../../SharedStyles';

const UserInfoForm = (props) => {
  const { strings } = AppSystemUsersService;
  const { handleChange, systemUser } = props;
  return (
    <FormContainerStyles padding='10px 10px 15px' borderBottom>
      <TitleStyles size='14px' weight='600'>
        {strings.contactInfo}
      </TitleStyles>
      <FormRowStyles>
        <InputStyles
          flexWidth='50%'
          margin='0 10px 0 0'
          mobileMargin='0'
          type='text'
          maxLength='256'
          name='firstName'
          placeholder={strings.firstNamePlaceholder}
          value={systemUser.firstName || ''}
          onChange={handleChange}
          required
          disabled={props.pendingUser}
        />
        <InputStyles
          flexWidth='50%'
          margin='0 0 0 10px'
          mobileMargin='0'
          type='text'
          maxLength='256'
          name='lastName'
          placeholder={strings.lastNamePlaceholder}
          value={systemUser.lastName || ''}
          onChange={handleChange}
          required
          disabled={props.pendingUser}
        />
      </FormRowStyles>
      <FormRowStyles>
        <InputStyles
          flexWidth='50%'
          margin='0 10px 0 0'
          mobileMargin='0'
          type='text'
          maxLength='256'
          name='email'
          placeholder={strings.emailPlaceholder}
          value={systemUser.email || ''}
          onChange={handleChange}
          required
          disabled={props.pendingUser}
        />
        <InputStyles
          flexWidth='50%'
          margin='0 0 0 10px'
          mobileMargin='0'
          type='text'
          maxLength='256'
          name='phone'
          placeholder={strings.phonePlaceholder}
          value={systemUser.phone || ''}
          onChange={handleChange}
          disabled={props.pendingUser}
        />
      </FormRowStyles>
    </FormContainerStyles>
  );
};

export default UserInfoForm;
