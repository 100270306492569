import React, { useEffect, useState, useContext } from 'react';
import { injectStripe } from 'react-stripe-elements';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from 'react-stripe-elements';

//services
import AppBankService from '../AppBankService';

//global components
import { LoadingSpinner } from '../../../shared';

//styles
import {
  InputStyles,
  FormStyles,
  JumboTronHeaderStyles,
  TitleStyles,
  TextStyles,
  ButtonDivStyles,
  ButtonStyles,
  ButtonContainerStyles,
  DivStyles,
  FormLabelStyles,
} from '../../../SharedStyles';

//error
import { ErrorResponse } from '../../../shared/ErrorResponse';

//context
import { ErrorContext } from '../../../context/shared/ErrorContext';
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';

//helpers
import { STRIPE_OPTIONS } from '../../../shared';

const SinglePaymentForm = (props) => {
  const { strings } = AppBankService;
  const { connectAccount } = props;
  const { updatingAccount, account } = useContext(CsoAccountContext);
  const { setError } = useContext(ErrorContext);
  const [disable, setDisable] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(false);
  const [bankForm, setBankForm] = useState({
    country: 'US',
    currency: 'usd',
    routing_number: '',
    account_number: '',
    confirm_account_number: '',
    account_holder_name: '',
    account_holder_type: connectAccount && connectAccount.businessType,
  });

  const handleChange = (e) => {
    setBankForm({ ...bankForm, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    let payoutCompleted = Object.keys(bankForm).every((key) => {
      return bankForm[key] !== '';
    });
    if (payoutCompleted || connectAccount.payoutVerified) {
      setDisable(false);
    }
  }, [bankForm, connectAccount.payoutVerified]);

  const handleCCSubmit = async (e) => {
    e.preventDefault();
    //creating stripe token for card
    setLoading(true);
    try {
      let { token, error } = await props.stripe.createToken();
      if (error) {
        throw new ErrorResponse(
          'frontend',
          error.message,
          'Something went wrong'
        );
      }
      if (token) {
        setSuccess(true);
        await AppBankService.createCSOPaymentInfo({
          token: token.id,
          isBank: false,
        });
        props.fetchStripeAccount();
        props.fetchStripeData();
        if (account.subscriptionLevel === 0) {
          let upgrade = await updatingAccount({
            ...account,
            subscriptionLevel: parseInt(props.connectAccount.subscriptionPlan),
          });
          if (upgrade) {
            props.handleCloseWizard();
          }
        } else {
          props.handleCloseWizard();
        }
      }
    } catch (err) {
      setLoading(false);
      if (err.name === 'frontend') {
        setError({
          display: true,
          styles: 'cso',
          title: err.title,
          message: err.message,
        });
      } else {
        setError({
          display: true,
          styles: 'cso',
          title: 'Something went wrong',
          message: err.response.data.split(/[|]+/).pop(),
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let payoutCompleted = Object.keys(bankForm).every((key) => {
      return bankForm[key] !== '';
    });

    try {
      if (bankForm.confirm_account_number !== bankForm.account_number) {
        throw new ErrorResponse(
          'frontend',
          'You must provide matching account numbers.',
          'Mismatch Account Numbers'
        );
      }

      if (payoutCompleted) {
        let { token, error } = await props.stripe.createToken(
          'bank_account',
          bankForm
        );
        if (error) {
          throw new ErrorResponse(
            'frontend',
            error.message,
            'Something went wrong'
          );
        }
        await AppBankService.createCSOPaymentInfo({
          token: token.id,
          isBank: true,
        });
      }

      props.fetchStripeAccount();
      props.fetchStripeData();
      props.handleCloseWizard();
    } catch (err) {
      setLoading(false);
      if (err.name === 'frontend') {
        setError({
          display: true,
          styles: 'cso',
          title: err.title,
          message: err.message,
        });
      } else {
        setError({
          display: true,
          styles: 'cso',
          title: 'Something went wrong',
          message: err.response.data.split(/[|]+/).pop(),
        });
      }
    }
  };

  return (
    <JumboTronHeaderStyles align='left' padding='10px 30px' borderBottom='none'>
      {loading && <LoadingSpinner />}
      <TitleStyles
        size='28px'
        align='left'
        display='flex'
        padding='0 0 5px'
        borderBottom
        alignItems='center'
        justifyContent='space-between'>
        {strings.wizard3Title}
      </TitleStyles>
      <TextStyles size='14px'>{strings.wizard3Content1}</TextStyles>
      <DivStyles align='center'>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={(tabIndex) => setTabIndex(tabIndex)}>
          <TabList>
            <Tab>{strings.tabTitle}</Tab>
            <Tab>{strings.tabTitle2}</Tab>
          </TabList>
          <TabPanel>
            <FormStyles
              display='block'
              margin='0 auto'
              width='90%'
              id='bankForm'
              onSubmit={handleSubmit}>
              <InputStyles
                type='text'
                name='account_holder_name'
                onChange={handleChange}
                placeholder='Account Holder Name'
                value={bankForm.account_holder_name}
                required
              />
              <InputStyles
                type='number'
                name='account_number'
                onChange={handleChange}
                placeholder='Account Number'
                value={bankForm.account_number}
                required
              />
              <InputStyles
                type='number'
                name='confirm_account_number'
                onChange={handleChange}
                placeholder='Confirm Account Number'
                value={bankForm.confirm_account_number}
                required
              />
              <InputStyles
                type='number'
                name='routing_number'
                onChange={handleChange}
                placeholder='Routing Number'
                value={bankForm.routing_number}
                required
              />
              <ButtonContainerStyles>
                <ButtonDivStyles
                  inverse
                  onClick={props.handleCloseWizard}
                  title={strings.cancel}>
                  {strings.cancel}
                </ButtonDivStyles>
                <ButtonStyles
                  type='submit'
                  form='bankForm'
                  disabled={disable}
                  title={strings.save}>
                  {strings.save}
                </ButtonStyles>
              </ButtonContainerStyles>
            </FormStyles>
          </TabPanel>
          <TabPanel>
            <FormStyles
              display='block'
              margin='0 auto'
              width='90%'
              id='ccForm'
              onSubmit={handleCCSubmit}>
              {loading && <LoadingSpinner />}
              {success && (
                <p className='danger-text'>
                  <em>Successfully validated</em>
                </p>
              )}
              <DivStyles width='100%'>
                <FormLabelStyles width='100%' margin='0 0 5px 0'>
                  <CardNumberElement {...STRIPE_OPTIONS()} />
                </FormLabelStyles>
                <DivStyles display='flex'>
                  <FormLabelStyles width='100%' margin='0 10px 5px 0'>
                    <CardExpiryElement {...STRIPE_OPTIONS()} />
                  </FormLabelStyles>
                  <FormLabelStyles width='100%' margin='0 0 5px 10px'>
                    <CardCvcElement {...STRIPE_OPTIONS()} />
                  </FormLabelStyles>
                </DivStyles>
              </DivStyles>
              <ButtonContainerStyles>
                <ButtonDivStyles
                  inverse
                  onClick={props.handleCloseWizard}
                  title={strings.cancel}>
                  {strings.cancel}
                </ButtonDivStyles>
                <ButtonStyles
                  type='submit'
                  form='ccForm'
                  disabled={disable}
                  title={strings.save}>
                  {strings.save}
                </ButtonStyles>
              </ButtonContainerStyles>
            </FormStyles>
          </TabPanel>
        </Tabs>
      </DivStyles>
    </JumboTronHeaderStyles>
  );
};

export default injectStripe(SinglePaymentForm);
