import React, { useState, useContext } from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppChargersService from '../../../AppChargersService';
import AppModalService from './AppModalService';

//global components
import { LoadingSpinner } from '../../../../../shared';
import ModalButton from '../../../../../shared/Buttons/ModalButton';

//hooks
import { useRouter } from '../../../../../shared/hooks/useRouter';

//context
import { CsoAccountContext } from '../../../../../context/operator/getCsoAccount';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
} from '../../../../../SharedStyles';

const Delete = (props) => {
  const { strings } = AppModalService;
  const { chargerDetails } = props;
  const router = useRouter();
  const { setAccount } = useContext(CsoAccountContext);
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    props.handleModalClose();
  };

  const handleDelete = async () => {
    setLoading(true);
    if (props.massDelete) {
      let removeChargers = props.chargers.map((charger) => {
        return charger.iotHubDeviceId;
      });
      let data = {
        chargingStationIds: removeChargers,
      };

      let account = await AppChargersService.deleteCharger(data);
      setAccount(account);

      if (router.match.params.id) {
        router.history.push('/chargers');
      }
      if (account) {
        props.updateChargers();
        closeModal();
      }
    } else {
      let data = {
        chargingStationIds: [chargerDetails.iotHubDeviceId],
      };
      let account = await AppChargersService.deleteCharger(data);
      setAccount(account);

      if (router.match.params.id) {
        router.history.push('/chargers');
      }
      if (account) {
        if (props.updateChargers) {
          props.updateChargers();
        }
        closeModal();
      }
    }
  };

  return (
    <ModalStyles>
      {loading && <LoadingSpinner />}
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.deleteTitle}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <p>{strings.deleteContent}</p>
          </ModalItemStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={handleDelete}
          buttonText={strings.remove}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default Delete;
