import React, { useState, useEffect, useContext } from 'react';

//services
import AppDiscountSchedulesService from '../AppDiscountSchedulesService';

//context
import { ErrorContext } from '../../../context/shared/ErrorContext';

//styles
import {
  HeaderContainerStyles,
  ContentContainerStyles
} from '../../../OperatorStyles';
import {
  InputStyles,
  SelectStyles,
  FormStyles,
  TitleStyles,
  InputContainerStyles,
  TextStyles,
  ButtonDivGreyStyles
} from '../../../SharedStyles';

//error Class
import { ErrorResponse } from '../../../shared/ErrorResponse';
import { LoadingSpinner } from '../../../shared';
import { SubscriptionAccess } from '../../OperatorShared';

const FormHeaderComponent = props => {
  const { strings } = AppDiscountSchedulesService;
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState({
    discountId: '',
    csoAccountId: '',
    expiration: '',
    usesRemaining: '',
    usageType: '',
    token: '',
    tokenType: 'couponToken'
  });

  useEffect(() => {
    if (props.account) {
      setCoupon(c => ({
        ...c,
        discountId: props.profile.id,
        csoAccountId: props.account.id
      }));
    }
  }, [props.account, props.profile]);

  const handleChange = e => {
    if (e.target.name === 'usageType' && e.target.value === '0') {
      setCoupon({ ...coupon, usesRemaining: -1, usageType: 'unlimited' });
    } else {
      setCoupon({
        ...coupon,
        [e.target.name]: e.target.value
      });
    }
  };
  const passData = e => {
    props.handleChange([e.target.name], e.target.value);
  };
  const createToken = async () => {
    setLoading(true);
    try {
      if (!coupon.usageType) {
        throw new ErrorResponse(
          'Frontend',
          strings.missingUsageType,
          strings.invalidTitle
        );
      }
      if (
        (parseInt(coupon.usageType) === 1 &&
          parseInt(coupon.usesRemaining) <= 0) ||
        coupon.usesRemaining === ''
      ) {
        throw new ErrorResponse(
          'Frontend',
          strings.missingUsageCount,
          strings.invalidTitle
        );
      }
      await AppDiscountSchedulesService.createCouponToken(coupon);
      setCoupon(c => ({
        ...c,
        expiration: '',
        usesRemaining: '',
        usageType: ''
      }));
      props.couponCounter(1);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.name === 'Frontend') {
        setError({
          display: true,
          title: err.title,
          message: err.message,
          styles: 'cso'
        });
      } else {
        setError({
          display: true,
          title: strings.invalidTitle,
          message: err.message,
          styles: 'cso'
        });
      }
    }
  };

  return (
    <FormStyles display='block' id='myForm' onSubmit={props.handleSubmit}>
      {loading && <LoadingSpinner />}
      <HeaderContainerStyles padding='15px 10px 30px'>
        <InputStyles
          width='100%'
          required
          type='text'
          name='name'
          value={props.profile.name || ''}
          onChange={passData}
          placeholder={strings.placeholderName}
        />
      </HeaderContainerStyles>
      <ContentContainerStyles padding='10px 10px 20px' border={coupon}>
        <TitleStyles size='14px' weight='600'>
          {strings.cardDiscount}
        </TitleStyles>
        <InputContainerStyles
          display='flex'
          alignItems='flex-end'
          labelMargin='0'
          labelColor='#c2c2c2'>
          <label>{strings.titlePer}</label>
          <InputStyles
            width='50px'
            mobileWidth='50px'
            margin='0 10px'
            padding='0 2px'
            required
            type='number'
            min='0'
            max='100'
            name='discountPercentage'
            value={props.profile.discountPercentage || ''}
            onChange={passData}
            placeholder={strings.placeholderRate}
          />
          <label>%</label>
        </InputContainerStyles>
      </ContentContainerStyles>
      <SubscriptionAccess levels={[3]}>
        {props.editSx && (
          <ContentContainerStyles
            padding='10px 10px 20px'
            optionsMargin='0 0 10px 0'
            buttonMargin='0 0 0 30%'>
            <TitleStyles size='14px' weight='600'>
              {strings.titleCoupon}
            </TitleStyles>
            <InputContainerStyles
              display='flex'
              alignItems='flex-end'
              labelMargin='0'
              labelColor='#c2c2c2'>
              <label>{strings.cardUsage}</label>
              <SelectStyles
                width='95px'
                mobileWidth='95px'
                padding='0 2px'
                bgPosition='.65em'
                margin='0 10px'
                name='usageType'
                onChange={handleChange}
                value={coupon.usageType}>
                <option disabled value=''>
                  {strings.type}
                </option>
                {strings.types.map((type, index) => {
                  return (
                    <option key={type} value={index}>
                      {type}
                    </option>
                  );
                })}
              </SelectStyles>
              {coupon.usageType !== 'unlimited' && (
                <InputStyles
                  width='50px'
                  mobileWidth='50px'
                  padding='0 2px'
                  margin='0'
                  type='number'
                  min='1'
                  name='usesRemaining'
                  onChange={handleChange}
                  placeholder={strings.uses}
                  value={
                    coupon.usesRemaining === -1 ? '' : coupon.usesRemaining
                  }
                />
              )}
            </InputContainerStyles>
            <InputContainerStyles
              display='flex'
              alignItems='flex-end'
              margin='10px 0'
              labelMargin='0'
              labelColor='#c2c2c2'>
              <label>{strings.cardExpiration}</label>
              <InputStyles
                margin='0 10px'
                padding='0 2px'
                width='150px'
                mobileWidth='150px'
                type='date'
                name='expiration'
                onChange={handleChange}
                placeholder={strings.placeholderRate}
              />
              <TextStyles size='12px' color='#c2c2c2' margin='0'>
                {strings.empty}
              </TextStyles>
            </InputContainerStyles>
            <InputContainerStyles
              display='flex'
              alignItems='flex-end'
              margin='10px 0'
              labelMargin='0'
              labelColor='#c2c2c2'>
              {!loading ? (
                <ButtonDivGreyStyles
                  size='11px'
                  padding='3px 10px'
                  margin='10px 0 0 30%'
                  background='#a5a5a5'
                  border
                  onClick={createToken}>
                  {strings.generate}
                </ButtonDivGreyStyles>
              ) : (
                <ButtonDivGreyStyles
                  size='11px'
                  padding='3px 10px'
                  margin='10px 0 0 30%'
                  background='#a5a5a5'
                  border>
                  {strings.generating}
                </ButtonDivGreyStyles>
              )}
            </InputContainerStyles>
          </ContentContainerStyles>
        )}
      </SubscriptionAccess>
    </FormStyles>
  );
};

export default FormHeaderComponent;
