import React, { useState, useEffect } from 'react';

//components
import GoogleChart from '../../../../OperatorShared/GoogleCharts/GoogleChart';

//helpers
import { handleDateCheck } from './helpers';
import { ListPlaceholderStyles, TextStyles } from '../../../../../SharedStyles';

const RevenueChart = (props) => {
  const { reportData, filterDate, fetchReportData } = props;
  const [chartData, setChartData] = useState([]);
  const [chartInfo, setChartInfo] = useState({
    haxis: {
      title: 'Days',
    },
    vaxis: {
      title: 'dollars',
      minValue: 0,
      maxValue: 300,
    },
  });

  useEffect(() => {
    let highestTotalkWh = 0;
    let endDate = 0;
    let startDate = 0;

    let dataArray = reportData
      .map((d, idx) => {
        if (idx === 0) {
          startDate = d[0];
        }
        if (idx === reportData.length - 1) {
          endDate = d[0];
        }
        if (d[1] > highestTotalkWh) {
          highestTotalkWh = d[1];
        }
        if (!(d[0] instanceof Date)) {
          let date = d[0].split(',').map((i) => {
            if (i.includes('(')) {
              i = i.split('(').join('');
            }
            if (i.includes(')')) {
              i = i.split(')').join('');
            }
            return parseInt(i);
          });

          let year = date[0];
          let month = date[1];
          let day = date[2];
          let hour = date[3];

          d[0] = new Date(year, month, day, hour);
        }

        return d;
      })
      .sort((a, b) => {
        return a[0] - b[0];
      });

    let exportData = dataArray.map((d) => {
      return { date: d[0], session: d[1], idleFee: d[2], Other: d[3] };
    });

    if (fetchReportData) {
      fetchReportData(exportData);
    }

    let { title } = handleDateCheck(filterDate);

    setChartInfo((prev) => ({
      ...prev,
      haxis: {
        ...prev.haxis,
        minValue: startDate,
        maxValue: endDate,
        title: title,
      },
      vaxis: { ...prev.vaxis, maxValue: highestTotalkWh + 20, minValue: 0 },
    }));

    setChartData(dataArray);
    // eslint-disable-next-line
  }, [reportData, filterDate]);

  const handleHAxisFormat = (date) => {
    if (date === 'today' || date === 'yesterday') {
      return 'h a';
    }
    return 'M/d/yy';
  };

  if (reportData.length === 0) {
    return (
      <ListPlaceholderStyles borderBottom='none'>
        <TextStyles size='14px'>No Results</TextStyles>
      </ListPlaceholderStyles>
    );
  }

  return (
    <GoogleChart
      type='line'
      divId='charger_revenue'
      width='75%'
      height='70%'
      size='14'
      top='30'
      left='100'
      minWidth={props.minWidth}
      options={{
        // animation: {
        //   duration: 500,
        //   startup: true,
        //   easing: 'inAndOut',
        // },
        hAxis: {
          format: handleHAxisFormat(filterDate),
          title: chartInfo.haxis.title,
          gridlines: { count: 15 },
        },
        vAxis: {
          title: chartInfo.vaxis.title,
          gridlines: { color: 'none' },
          viewWindow: {
            min: chartInfo.vaxis.minValue,
            max: chartInfo.vaxis.maxValue,
          },
        },
        colors: ['#a52714', '#097138'],
      }}
      columns={[
        ['date', chartInfo.haxis.title],
        ['number', 'Session Amt'],
        ['number', 'Idle Fee Amt'],
        ['number', 'Other'],
      ]}
      pieChart={0}
      data={chartData}
      loader={<p>Loading Chart...</p>}
    />
  );
};

export default RevenueChart;
