import React, { useEffect, useCallback, useContext } from 'react';
import { FaRegWindowClose, FaCamera } from 'react-icons/fa';

//services
import AppChargersService from '../../../AppChargersService';
import AppModalService from './AppModalService';
import AppImagingService from '../../../../../../services/AppImaging/AppImagingService';

//global components
import { LoadingSpinner } from '../../../../../shared';

//hooks
import { useCloudinary } from '../../../../../shared/Cloudinary/hook/useCloudinary';

//context
import { ErrorContext } from '../../../../../context/shared/ErrorContext';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  ModalNoteStyles,
  ButtonDivGreyStyles,
} from '../../../../../SharedStyles';

//helpers
import { uuid } from '../../../../../shared/Helpers';

const ImageModal = (props) => {
  const { strings } = AppModalService;
  const { charger, updateCharger, handleModalClose } = props;
  const { setError } = useContext(ErrorContext);

  const { cloudData, uploadImage, imageLoading } = useCloudinary({
    preset: 'charger_images',
    tags: [props.charger.iotHubDeviceId],
  });

  const closeModal = () => {
    props.handleModalClose();
  };

  const postImage = useCallback(async () => {
    let updatedCharger = { ...charger };
    let newImage = {
      approved: true,
      imagePath: cloudData.secure_url,
      id: uuid(),
    };
    updatedCharger.images.push(newImage);
    let res = await AppChargersService.updateCharger(updatedCharger);
    updateCharger(res);
    handleModalClose();
  }, [charger, updateCharger, handleModalClose, cloudData]);

  const onCameraSuccess = async (imageData) => {
    let file = 'data:image/jpeg;base64,' + imageData;
    uploadImage(file);
  };

  const onCameraError = () => {
    setError({
      display: true,
      message: 'Problem with camera.',
      title: 'Problem',
      styles: 'cso',
    });
  };

  const takePicture = () => {
    AppImagingService.getFromCamera(onCameraSuccess, onCameraError);
  };

  useEffect(() => {
    if (cloudData) {
      postImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloudData]);

  const openDialog = () => {
    document.getElementById('inputUpload').click();
  };

  return (
    <ModalStyles>
      {imageLoading && <LoadingSpinner />}
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.photoTitle}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <p>{strings.photoContent}</p>
          </ModalItemStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <p>
              <strong>{strings.photoHint}</strong> {strings.photoHintContent}
            </p>
          </ModalItemStyles>
        </ModalContentStyles>
        <input
          id='inputUpload'
          type='file'
          hidden
          accept='image/*'
          onChange={uploadImage}
        />
        <ButtonDivGreyStyles onClick={takePicture}>
          <FaCamera /> {strings.photoTake}
        </ButtonDivGreyStyles>
        <ModalNoteStyles>{strings.or}</ModalNoteStyles>
        <ButtonDivGreyStyles onClick={openDialog}>
          {strings.photoBrowse}
        </ButtonDivGreyStyles>
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default ImageModal;
