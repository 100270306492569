import React, { useState, useEffect, useContext } from 'react';

//services
import AppBankService from '../AppBankService';

//global components
import { LoadingSpinner } from '../../../shared';

//context
import { ErrorContext } from '../../../context/shared/ErrorContext';
//styles
import {
  InputStyles,
  ButtonStyles,
  ButtonDivStyles,
  FormStyles,
} from '../../../SharedStyles';

const MicroCheck = (props) => {
  const { setError } = useContext(ErrorContext);
  const [deposits, setDeposits] = useState({
    first_deposit: '',
    second_deposit: '',
  });
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    if (e.target.value < 1) {
      e.target.value = e.target.value * 100;
    }
    setDeposits({ ...deposits, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let microd = [
        parseInt(deposits.first_deposit),
        parseInt(deposits.second_deposit),
      ];
      let data = await AppBankService.validateCSOPaymentInfo({
        microCharges: microd,
      });
      if (data) {
        setVerified(true);
      }
    } catch (err) {
      setLoading(false);
      setError({
        display: true,
        title: 'Failed Validate',
        message: err.response.data.split(/[|]+/).pop(),
        styles: 'cso',
      });
    }
  };

  const closeMicro = () => {
    props.handleMicroCheck();
  };

  useEffect(() => {
    if (verified) {
      props.handleMicroCheck();
      props.fetchStripeAccount();
      props.fetchStripeData();
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verified]);

  return (
    <FormStyles onSubmit={handleSubmit} id='Micro' margin='0 auto'>
      {loading && <LoadingSpinner />}
      <InputStyles
        margin='0 10px 5px'
        type='number'
        name='first_deposit'
        onChange={handleChange}
        placeholder='First deposit'
        value={deposits.first_deposit}
      />
      <InputStyles
        margin='0 10px 5px'
        type='number'
        name='second_deposit'
        onChange={handleChange}
        placeholder='Second deposit'
        value={deposits.second_deposit}
      />
      <ButtonStyles type='submit' form='Micro'>
        Verify
      </ButtonStyles>
      <ButtonDivStyles onClick={closeMicro}>Cancel</ButtonDivStyles>
    </FormStyles>
  );
};

export default MicroCheck;
