import React from 'react';

//layout
import { InteriorWrapper, ComponentWrapper } from '../../shared';
import Button from '../../../shared/Buttons/Button';

//services
import AppMembershipsService from '../AppMembershipsService';

//components
import MembershipList from '../MembershipList/MembershipList';

const AddMembership = (props) => {
  const { strings } = AppMembershipsService;

  return (
    <InteriorWrapper
      title={strings.addMembershipTitle}
      isInternal
      path='/memberships'>
      <ComponentWrapper title={strings.addMembershipTitle}>
        <MembershipList searchMemberships />
      </ComponentWrapper>
      <Button
        driver
        noMobile
        redirect='/memberships/'
        buttonText={strings.membershipsTitle}
      />
    </InteriorWrapper>
  );
};

export default AddMembership;
