import React, { useState, useEffect, useContext, useRef } from 'react';
import moment from 'moment';

import {
  FaInfoCircle,
  FaRegTrashAlt,
  FaRegPauseCircle,
  FaRegCheckSquare,
  FaEllipsisV,
  FaRegPlayCircle,
} from 'react-icons/fa';

//services
import AppMembershipsService from '../../AppMembershipsService';

//modals
import UserDataModal from '../../MembershipsModals/UserDataModal';

//hooks
import useOnClickOutside from '../../../../shared/hooks/useOnClickOutside';
import { useWindowResize } from '../../../../shared/hooks/useWindowSize';
import { useContainerHeight } from '../../../../shared/hooks/useContainerHeight';
import { useRouter } from '../../../../shared/hooks/useRouter';

//context
import { ErrorContext } from '../../../../context/shared/ErrorContext';

//styles
import {
  TableRowStyles,
  TableItemStyles,
  DivStyles,
} from '../../../../SharedStyles';
import { ToolsStyles } from '../../../../OperatorStyles';
import RemoveMember from '../../MembershipsModals/RemoveMember';

const MembersCard = (props) => {
  const { strings } = AppMembershipsService;
  const { firstName, lastName, joined, paused, expiration, userId, email } = props;
  const router = useRouter();
  const { setError } = useContext(ErrorContext);
  const [deleteDriverLink, setDeleteDriverLink] = useState(false);
  const [userDataModal, setUserDataModal] = useState(false);
  const CardRef = useRef();
  const OutsideRef = useRef();
  const size = useWindowResize(window.innerWidth, window.innerHeight);
  const { containerHeight, containerRef, setContainerRef } = useContainerHeight(
    50,
    CardRef
  );
  const [openTools, setOpenTools] = useState(false);

  useOnClickOutside(OutsideRef, () => setOpenTools(false));

  const toggleToolBar = () => {
    setOpenTools(!openTools);
  };

  //gets card container height for responsive
  useEffect(() => {
    setContainerRef(CardRef);
    // eslint-disable-next-line
  }, [CardRef]);

  const toggleDelete = () => {
    setDeleteDriverLink(!deleteDriverLink);
  };

  const toggleData = () => {
    setUserDataModal(!userDataModal);
  };

  const toggleValue = async (status) => {
    try {
      const memberObject = {
        userId,
        membershipId: router.match.params.id,
      };
      if (status === 'approve') {
        memberObject.approved = true;
        let approve = await AppMembershipsService.approveMembership(
          memberObject
        );
        if (approve) {
          props.sortByname();
        }
      }
      if (status === 'pause') {
        memberObject.paused = !paused;

        let pause = await AppMembershipsService.pauseMembership(memberObject);
        if (pause) {
          props.sortByname();
        }
      }
    } catch (err) {
      setError({
        display: true,
        title: 'Something went wrong',
        message: err.response ? err.response.data : err.message,
        styles: 'cso',
      });
    }
  };

  return (
    <>
      {deleteDriverLink && (
        <RemoveMember
          removeMember={props.removeMember}
          handleCloseModal={toggleDelete}
          membership={props.membership}
          userId={userId}
          title={strings.removeMemberTitle}
          content={`${strings.removeMemberContentOne} ${firstName} ${lastName} ${strings.removeMemberContentTwo}`}
          button={strings.remove}
        />
      )}
      {userDataModal && (
        <UserDataModal user={props} handleCloseModal={toggleData} />
      )}
      <TableRowStyles
        rowbackground={
          (props.paused && '#f6d9c6') || (!props.approved && '#ffd60057')
        }
        hoverBackground='#f1f7ff'
        borderTop='none'
        borderLeft='none'
        borderRight='none'
        borderBottom='none'
        width='100%'
        align='center'
        ref={containerRef}
        onMouseEnter={() => {
          size[0] >= 768 && setOpenTools(true);
        }}
        onMouseLeave={() => {
          size[0] >= 768 && setOpenTools(false);
        }}>
        <DivStyles
          display='flex'
          alignItems='center'
          width='100%'
          onClick={toggleData}>
          <TableItemStyles flex='1'>{props.firstName}</TableItemStyles>
          <TableItemStyles flex='1'>{props.lastName}</TableItemStyles>
          <TableItemStyles flex='1'>{props.email}</TableItemStyles>
          <TableItemStyles flex='1'>
            {joined === '0001-01-01T00:00:00'
              ? strings.pending
              : moment(joined).format('MM/DD/YYYY')}
          </TableItemStyles>
          <TableItemStyles flex='1'>
            {expiration === '0001-01-01T00:00:00'
              ? strings.expiresNever
              : moment(expiration).format('MM/DD/YYYY hh:mm a')}
          </TableItemStyles>
        </DivStyles>
        <FaEllipsisV
          className='Card-Tools-Toggle'
          style={size[0] >= 768 ? { display: 'none' } : { display: 'block' }}
          onClick={toggleToolBar}
        />
        <ToolsStyles
          ref={OutsideRef}
          displayTools={openTools}
          containerHeight={(containerHeight - 35) / 2}>
          {!props.approved ? (
            <div className='Tool-Item' onClick={() => toggleValue('approve')}>
              <FaRegCheckSquare />
            </div>
          ) : (
            <>
              {props.paused ? (
                <div className='Tool-Item' onClick={() => toggleValue('pause')}>
                  <FaRegPlayCircle />
                </div>
              ) : (
                <div className='Tool-Item' onClick={() => toggleValue('pause')}>
                  <FaRegPauseCircle />
                </div>
              )}
              <div
                title={strings.notificationsTitle}
                onClick={toggleData}
                className='Tool-Item'>
                <FaInfoCircle />
              </div>
            </>
          )}
          <div className='Tool-Item' onClick={toggleDelete}>
            <FaRegTrashAlt title={strings.delete} />
          </div>
        </ToolsStyles>
      </TableRowStyles>
    </>
  );
};

export default MembersCard;
