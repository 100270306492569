import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppOwnedChargersService from '../AppOwnedChargersService';
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';

const DeleteModal = props => {
  const { strings } = AppOwnedChargersService;
  const { loading } = props;

  const closeModal = () => {
    props.handleModalToggle();
  };

  const removeChargerHandler = e => {
    props.removeOwnedCharger();
  };

  return (
    <div className='Modal-Display-Driver'>
      {loading && <LoadingSpinner />}
      <div className='Modal'>
        <div className='Modal-Header'>
          {strings.removeTitle}
          <div className='Modal-Close'>
            <FaRegWindowClose onClick={closeModal} />
          </div>
        </div>
        <div className='Modal-Content'>
          <p className='Modal-Content-Text'>{strings.removeContent}</p>
        </div>
        <div className='Modal-Button'>
          <button className='app-button' onClick={removeChargerHandler}>
            {strings.removeButton}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
