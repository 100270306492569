import styled from 'styled-components';

export const ToggleContainerStyles = styled.div`
  text-align: left;
  display: ${({ display }) => (display ? display : 'inline-block')};
  margin: ${({ margin }) => (margin ? margin : '0 0.5em')};
  padding: ${({ padding }) => padding};
  justify-content: ${({ justifyContent }) => justifyContent};
`;

export const ToggleSwitchStyles = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 0.95em;
  top: 0.25em;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  /* The slider */
  .Slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 200ms;
    transition: 200ms;
    width: 40px;
    &::before {
      position: absolute;
      content: '';
      height: 1.3em;
      width: 1.3em;
      bottom: -3px;
      background-color: ${({ theme }) => theme.toggleOffColor};
      transition: 200ms;
    }
  }
  /* Rounded sliders */
  .Slider.Round {
    border-radius: 34px;
    &:before {
      border-radius: 50%;
    }
  }
  /* Toggle Off - Slider Bar*/
  input + .Slider {
    background: ${({ theme }) => theme.toggleOffSliderColor};
  }
  /* Toggle Off - Circle*/
  input + .Slider:before {
    background: ${({ theme }) => theme.toggleOffColor};
    border: 1px solid #c3d6f0;
    box-shadow: ${({ theme }) => `1px 1px 3px -1px ${theme.toggleShadow}`};
  }
  /* Toggle On - Slider Bar*/
  input:checked + .Slider {
    background: ${({ theme }) => theme.toggleSliderColor};
  }
  /* Toggle On - Circle */
  input:checked + .Slider:before {
    background: ${({ theme }) => theme.toggleColor};
    box-shadow: ${({ theme }) => `1px 1px 3px -1px ${theme.toggleShadow}`};
  }

  /* Toggle disabled - Slider Bar*/
  input:disabled + .Slider {
    background: ${({ theme }) => theme.toggleDisabledSliderColor};
  }
  /* Toggle disabled - Circle */
  input:disabled + .Slider:before {
    background: ${({ theme }) => theme.toggleDisabledColor};
    box-shadow: ${({ theme }) => `1px 1px 3px -1px ${theme.toggleShadow}`};
  }
`;

export const ToggleLabelStyles = styled.label`
  display: ${({ display }) => (display ? display : 'inline-block')};
  margin: ${({ margin }) => margin};
  color: ${({ color }) => (color ? color : '#2c456c')};
  font-size: ${({ size }) => (size ? size : '16px')};
  &:empty {
    display: none;
  }
`;

export const ToggleSwitchInputFields = styled.div`
  margin: 0 0 20px;
  text-align: ${({ textAlign }) => textAlign};
  .Content-Title {
    font-weight: 600;
    color: #28456a;
    font-size: 14px;
    padding: 10px 10px 0;
    margin: 0;
  }
`;

export const ToggleSwitchContent = styled.div`
  border-bottom: ${({ borderBottom }) => borderBottom};
`;
