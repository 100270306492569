import styled from 'styled-components';

export const LedgerContainerStyles = styled.div``;

export const LedgerHeaderStyles = styled.div``;

export const LedgerSelectContainerStyles = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0;
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    flex-wrap: wrap;
    margin: 0;
  }
`;

export const LedgerFilterSelectStyles = styled.select`
  margin: 0px 5px 0px;
  padding: 5px 10px;
  background-image: linear-gradient(180deg, #f3f3f3, #f3f3f3);
  color: #f60;
  border: 1px solid #cccccc;
  font-weight: 600;
  -webkit-appearance: menulist-button;
  padding-top: 5px;
  height: 35px;
  outline: none;
  flex: ${({ flex }) => (flex ? flex : '1')};
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    flex: 100%;
    margin: 5px 10px;
  }
`;

export const LedgerDataGraphContainerStyles = styled.div`
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid #e7e7e7;
`;

export const LedgerDataGraphStyles = styled.div`
  width: 75px;
  height: 75px;
  border: 4px solid #42be42;
  border-radius: 50%;
  margin: 15px auto;
  padding: 16px 5px;
  line-height: normal;
  p {
    margin: 0;
  }
`;

export const LedgerListStyles = styled.div`
  height: ${({ height }) => (height ? height : '440px')};
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
`;

export const LedgerCardStyles = styled.div`
  padding: 5px 10px;
  border-bottom: 1px solid #e7e7e7;
  font-size: 14px;
  text-align: left;
  &:hover {
    background: linear-gradient(180deg, #f3f3f3, #f3f3f3);
  }
`;

export const LedgerCardHeaderStyles = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  .Title {
    font-weight: 600;
    color: ${({ titleColor }) => titleColor};
  }
  .Date {
    color: #c2c2c2;
    font-size: 11px;
    line-height: 10px;
  }
`;

export const LedgerCardAddressStyles = styled.div`
  font-size: 12px;
  line-height: 12px;
  margin: ${({ margin }) => margin};
`;
