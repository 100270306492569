import React, { useContext } from 'react';

//components
import Dashboard from '../Dashboard/Dashboard';
import AccountCreation from '../AccountCreation/AccountCreation';

//global components
import { LoadingSpinner } from '../../shared';

//context
import { CsoAccountContext } from '../../context/operator/getCsoAccount';

const Landing = (props) => {
  const { account, loading } = useContext(CsoAccountContext);

  if (loading) {
    return <LoadingSpinner />;
  } else if (account) {
    return <Dashboard />;
  } else {
    return <AccountCreation />;
  }
};

export default Landing;
