import React, { useContext } from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppSystemUsersService from '../SystemUsers/AppSystemUsersService';
import AppNavSectionService from '../NavSection/AppNavSectionService';
//hooks
import { useRouter } from '../../shared/hooks/useRouter';

//context
import { CsoAccountContext } from '../../context/operator/getCsoAccount';

//global component
import ModalButton from '../../shared/Buttons/ModalButton';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
} from '../../SharedStyles';
import { ErrorContext } from '../../context/shared/ErrorContext';

const CsoModal = (props) => {
  const { toggleModal, fromCard } = props;
  const { setAccount, quickFetchCso, account } = useContext(CsoAccountContext);
  const { setError } = useContext(ErrorContext);

  const router = useRouter();

  const closeModal = () => {
    toggleModal();
  };

  const handleSaveInfo = async (e) => {
    e.preventDefault();
    try {
      if (props.systemUserModal) {
        const userAccount = {
          id: props.systemUserId,
          userId: props.userId,
        };
        if (!props.pending) {
          const subscriptionLevel = account.subscriptionLevel;

          await AppSystemUsersService.deleteSystemUser(userAccount);

          let finished = quickFetchCso(); //return promise
          if (finished) {
            closeModal();
            if (subscriptionLevel < 3) { // none enterprise has only one user
              AppNavSectionService.logout();
            }
            else {
              router.history.goBack();
            }
          }
        } else {
          let updatedAccount = await AppSystemUsersService.csoDeleteTokenSystemUser(
            props.systemUserId
          );
          setAccount(updatedAccount);
          closeModal();
          if (!fromCard) router.history.goBack();
        }
      }
    } catch (err) {
      setError({
        display: true,
        title: 'Something went wrong',
        message: err.response ? err.response.data : err.message,
        styles: 'cso',
      });
    }
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{props.title}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <p>{props.content}</p>
          </ModalItemStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={handleSaveInfo}
          buttonText={
            props.save ? props.save : props.deleteModal ? props.button : 'Ok'
          }
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default CsoModal;
