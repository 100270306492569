import AppPlatformService from '../AppPlatform/AppPlatformService';
import strings from './strings';

var dbCache = {};

const getDb = function () {
  return window.sqlitePlugin.openDatabase({
    name: AppPlatformService.isDriverApp()
      ? strings.dbNames.driver
      : strings.dbNames.cso,
    location: 'default',
    androidDatabaseProvider: 'system',
  });
};

const AppStorageService = {
  values: strings.keys,
  clearCache: function () {
    dbCache = {};
  },
  clearCachedValue: function (key) {
    if (dbCache[key]) {
      dbCache[key] = undefined;
    }
  },
  setValue: function (key, value) {
    return new Promise((resolve, reject) => {
      if (AppPlatformService.isBrowser()) {
        AppPlatformService.debug(
          'AppStorageService.setValue: Using HTML storage'
        );
        window.localStorage.setItem(key, value);
        dbCache[key] = value;
        resolve(true);
      } else {
        AppPlatformService.debug('AppStorageService.setValue: Using SQLite');
        const db = getDb();
        db.sqlBatch(
          [
            strings.dbScripts.values.create,
            [strings.dbScripts.values.insert, [key, value]],
            [strings.dbScripts.values.update, [value, key]],
          ],
          function () {
            dbCache[key] = value;
            resolve(true);
          },
          function (error) {
            alert(error.message);
            resolve(false);
          }
        );
      }
    });
  },
  getValue: function (key) {
    return new Promise((resolve, reject) => {
      if (dbCache[key]) {
        resolve(dbCache[key]);
      } else {
        if (AppPlatformService.isBrowser()) {
          AppPlatformService.debug(
            'AppStorageService.getValue: Using HTML storage'
          );
          const value = window.localStorage.getItem(key);
          dbCache[key] = value;
          resolve(value);
        } else {
          AppPlatformService.debug('AppStorageService.getValue: Using SQLite');
          const db = getDb();
          db.executeSql(
            strings.dbScripts.values.create,
            [],
            function () {
              db.executeSql(
                strings.dbScripts.values.select,
                [key],
                function (resultset) {
                  if (resultset && resultset.rows.length > 0) {
                    dbCache[key] = resultset.rows.item(0).value;
                    resolve(resultset.rows.item(0).value);
                  } else {
                    resolve(undefined);
                  }
                },
                function (error) {
                  reject(error.message);
                }
              );
            },
            function (error) {
              reject(error.message);
            }
          );
        }
      }
    });
  },
  removeValue: function (key) {
    return new Promise((resolve, reject) => {
      if (AppPlatformService.isBrowser()) {
        AppPlatformService.debug(
          'AppStorageService.removeValue: Using HTML storage'
        );
        localStorage.removeItem('auth.token');
        localStorage.removeItem('driverAccount');
        localStorage.removeItem('csoAccount');

        resolve();
      } else {
        AppPlatformService.debug('AppStorageService.removeValue: Using SQLite');
        const db = getDb();
        db.sqlBatch(
          [
            strings.dbScripts.values.create,
            [strings.dbScripts.values.delete, [key]],
          ],
          function () {
            dbCache[key] = undefined;
            resolve();
          },
          function (error) {
            alert(error.message);
            resolve();
          }
        );
      }
    });
  },
};

export default AppStorageService;
