import React, { useState, useEffect, useContext } from 'react';
import {
  FaDollarSign,
  FaSistrix,
  FaUserLock,
  FaCalendarAlt,
  FaRegEye,
  FaFilter,
} from 'react-icons/fa';

//services
import AppMembershipService from '../../AppMembershipsService';

//global components
import { SubscriptionAccess } from '../../../OperatorShared';
import ToggleSwitch from '../../../../driver/shared/ToggleSwitch';

//modals
import MembershipsModal from '../../MembershipsModals/MembershipsModal';

//context
import { CsoAccountContext } from '../../../../context/operator/getCsoAccount';

//helpers
import { booConvertSelect } from './helpers';

//styles
import {
  FormContainerStyles,
  FormRowStyles,
  FormLeftStyles,
  SelectStyles,
  TitleStyles,
  InputStyles,
} from '../../../../SharedStyles';

const MembershipOptions = (props) => {
  const { strings } = AppMembershipService;
  const { membership, handleDataChange } = props;
  const { account } = useContext(CsoAccountContext);
  const [displayModal, setDisplayModal] = useState(false);
  const [discounts, setDiscounts] = useState([]);
  const [displayAutoJoin, setDisplayAutoJoin] = useState(false);

  useEffect(() => {
    if (account) {
      setDiscounts(account.discountSchedules);
    }
  }, [account]);

  useEffect(() => {
    setDisplayAutoJoin(props.membership.visibility === 2);
  }, [props.membership.visibility]);

  const handleInputChange = (e) => {
    if (e.target.name === 'expires') {
      let value;
      if (e.target.value === '2') {
        value = true;
        props.handleDataChange({
          ...props.membership,
          [e.target.name]: value,
          memberExpirationDays: 0,
        });
      } else {
        value = false;
        props.handleDataChange({
          ...props.membership,
          [e.target.name]: value,
          memberExpirationDays: -1,
        });
      }
    } else if (e.target.name === 'isAutoJoin') {
      props.handleDataChange({
        ...props.membership,
        [e.target.name]: e.target.checked,
      });
    } else if (e.target.name === 'visibility') {
      props.handleDataChange({
        ...props.membership,
        [e.target.name]: parseInt(e.target.value),
      });
      setDisplayAutoJoin(props.membership.visibility === 2);
    } else {
      props.handleDataChange({
        ...props.membership,
        [e.target.name]: e.target.value,
      });
    }
  };
  
  const handleDisplayModal = () => {
    setDisplayModal(!displayModal);
  };

  useEffect(() => {
    if (account && 
        (account.subscriptionLevel === 1 || account.subscriptionLevel === 2)) { //SmallBusiness-2, Share-1
      handleDataChange({
        ...membership,
        visibility: 2, //PublicWithApproval-2
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <>
      {displayModal && (
        <MembershipsModal
          strings={strings}
          defaultModal
          discounts={discounts}
          membership={props.membership}
          handleCloseModal={handleDisplayModal}
          title={strings.discountSchedule}
          content={props.membership.discountScheduleId}
          button={strings.membershipDeleteButton}
        />
      )}
      <FormContainerStyles padding='10px' headersPadding='0' borderBottom>
        <TitleStyles size='14px' margin='0 0 10px' weight='600'>
          {strings.options}
        </TitleStyles>
        {/* Discount Schedule */}
        <FormRowStyles display='block' padding='5px 5px 0' margin='0'>
          <FormRowStyles justifyContent='space-between' margin='0'>
            <FormLeftStyles display='flex'>
              <FaDollarSign />
              <TitleStyles size='16px' margin='0' weight='500'>
                {strings.discountSchedule}
              </TitleStyles>
            </FormLeftStyles>
          </FormRowStyles>
          <FormRowStyles alignItems='center'>
            <SelectStyles
              size='14px'
              mobileSize='14px'
              width='60%'
              mobileWidth='80%'
              margin='0 20px 0 35px'
              mobileMargin='0 0 15px'
              padding='5px 0px 5px 4px'
              bgPosition='1em'
              name='discountScheduleId'
              onChange={handleInputChange}
              value={props.membership.discountScheduleId || ''}>
              <option value=''>{strings.discountScheduleOptions}</option>
              {discounts.map((option) => {
                return (
                  <option
                    className='AppCso-options-item-option'
                    key={option.id}
                    value={option.id}>
                    {option.name}
                  </option>
                );
              })}
            </SelectStyles>
            {props.membership.discountScheduleId && (
              <FaRegEye className='View' onClick={handleDisplayModal} />
            )}
          </FormRowStyles>
        </FormRowStyles>
        {/* Member Expiration */}
        <FormRowStyles display='block' padding='5px 5px 0' margin='0'>
          <FormRowStyles justifyContent='space-between' margin='0'>
            <FormLeftStyles display='flex'>
              <FaCalendarAlt />
              <TitleStyles size='16px' margin='0' weight='500'>
                {strings.memberExpiration}
              </TitleStyles>
            </FormLeftStyles>
          </FormRowStyles>
          <FormRowStyles alignItems='center'>
            <SelectStyles
              size='14px'
              mobileSize='14px'
              width='25%'
              mobileWidth='25%'
              margin='0 20px 0 35px'
              mobileMargin='0 20px 15px 0 '
              padding='5px 0px 5px 4px'
              bgPosition='1em'
              name='expires'
              onChange={handleInputChange}
              value={booConvertSelect[props.membership.expires] || ''}>
              <option value='' disabled>
                {strings.memberExpiresPlaceholder}
              </option>
              <option value='1'>{strings.memberExpirationOptions[0]}</option>
              <option value='2'>{strings.memberExpirationOptions[1]}</option>
            </SelectStyles>
            {props.membership.expires && (
              <InputStyles
                size='14px'
                mobileSize='14px'
                width='10%'
                mobileWidth='25%'
                margin='0'
                mobileMargin='0 0 15px'
                padding='5px 0px 5px 4px'
                type='number'
                name='memberExpirationDays'
                onChange={handleInputChange}
                placeholder={strings.memberExpirationDays}
                value={props.membership.memberExpirationDays || ''}
              />
            )}
          </FormRowStyles>
        </FormRowStyles>
        {/* Search Visibility */}
        <SubscriptionAccess levels={[3]}>
          <FormRowStyles display='block' padding='5px 5px 0' margin='0'>
            <FormRowStyles justifyContent='space-between' margin='0'>
              <FormLeftStyles display='flex'>
                <FaSistrix />
                <TitleStyles size='16px' margin='0' weight='500'>
                  {strings.searchVisibility}
                </TitleStyles>
              </FormLeftStyles>
            </FormRowStyles>
            <FormRowStyles alignItems='center'>
              <SelectStyles
                size='14px'
                mobileSize='14px'
                width='60%'
                mobileWidth='80%'
                margin='0 20px 0 35px'
                mobileMargin='0 0 15px'
                padding='5px 0px 5px 4px'
                bgPosition='1em'
                name='visibility'
                onChange={handleInputChange}
                value={props.membership.visibility || 0}>
                {strings.searchVisibilityOptions.map((option, index) => {
                  return (
                    <option
                      className='AppCso-options-item-option'
                      key={option}
                      value={index + 1}>
                      {option}
                    </option>
                  );
                })}
              </SelectStyles>
            </FormRowStyles>
          </FormRowStyles>
        </SubscriptionAccess>
        {/* Auto Join */}
        {displayAutoJoin && (<SubscriptionAccess levels={[3]}>
          <FormRowStyles display='block' padding='5px 5px 0' margin='0'>
            <FormRowStyles justifyContent='space-between' margin='0'>
              <FormLeftStyles display='flex'>
                <FaFilter />
                <TitleStyles size='16px' margin='0' weight='500'>
                  {strings.autoJoinEmailValidation}
                </TitleStyles>
              </FormLeftStyles>
              <ToggleSwitch
                    name='isAutoJoin'
                    checked={!!membership.isAutoJoin}
                    handleChange={handleInputChange}
                  />
            </FormRowStyles>
            <FormRowStyles alignItems='center'>
              <InputStyles
                size='14px'
                mobileSize='14px'
                width='60%'
                mobileWidth='80%'
                margin='0 20px 0 35px'
                mobileMargin='0 0 15px'
                padding='5px 0px 5px 4px'
                bgPosition='1em'
                onChange={handleInputChange}
                type='name'
                placeholder={strings.autoJoinEmailDomainsPlaceholder}
                value={props.membership.autoJoinEmailDomains || ''}
                name='autoJoinEmailDomains'
                disabled={!membership.isAutoJoin}
              />
            </FormRowStyles>
          </FormRowStyles>
        </SubscriptionAccess>)}
        {/* Available to */}
        <FormRowStyles display='block' padding='5px 5px 0' margin='0'>
          <FormRowStyles justifyContent='space-between' margin='0'>
            <FormLeftStyles display='flex'>
              <FaUserLock />
              <TitleStyles size='16px' margin='0' weight='500'>
                {strings.availableTo}
              </TitleStyles>
            </FormLeftStyles>
          </FormRowStyles>
          <FormRowStyles alignItems='center'>
            <InputStyles
              size='14px'
              mobileSize='14px'
              width='60%'
              mobileWidth='80%'
              margin='0 20px 0 35px'
              mobileMargin='0 0 15px'
              padding='5px 0px 5px 4px'
              bgPosition='1em'
              onChange={handleInputChange}
              type='name'
              placeholder={strings.availableToPlaceholder}
              name='availabilityTo'
              value={props.membership.availabilityTo || ''}
            />
          </FormRowStyles>
        </FormRowStyles>
      </FormContainerStyles>
    </>
  );
};

export default MembershipOptions;
