import React, { useState, useMemo, useEffect, useContext } from 'react';
import moment from 'moment';
import { FaRegWindowClose } from 'react-icons/fa';
import TimePicker from 'rc-time-picker';

//services
import AppChargingService from '../AppChargingService';

//components
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';
import ToggleSwitch from '../../shared/ToggleSwitch';

//styles
import 'rc-time-picker/assets/index.css';

//context
import { ErrorContext } from '../../../context/shared/ErrorContext';
import {
  DivStyles,
  ModalContainerStyles,
  ModalContentStyles,
  ModalHeaderStyles,
  ModalItemStyles,
  ModalStyles,
  SelectStyles,
  TextStyles,
  TitleStyles,
} from '../../../SharedStyles';
import ModalButton from '../../../shared/Buttons/ModalButton';

const AppChargingScheduleModal = (props) => {
  const { strings } = AppChargingService;
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(true);
  const [disableSx, setDisableSx] = useState(false);

  const [sx, setSx] = useState({
    display: false,
    startDateTime: moment(),
    endDateTime: moment().add(5, 'hours'),
    scheduleDaily: false,
    enabled: false,
    connectorId: null,
  });

  console.log('SX modal');

  const format = 'h:mm a';

  useMemo(() => {
    const getSx = async () => {
      try {
        // lastScheduledSessionSkipped;  for true show attempted otherwise show successfully
        const scheduleData = await AppChargingService.getChargingSchedule({
          iotHubDeviceId: props.iotHubDeviceId,
          vehicleId: props.vehicleId,
        });

        if (scheduleData) {
          if (scheduleData.lastStartedDateTime === '0001-01-01T00:00:00') {
            setSx((prevSX) => ({
              ...prevSX,
              ...scheduleData,
              display: false,
              startDateTime: scheduleData.startDateTime,
              endDateTime: scheduleData.endDateTime,
              enabled: scheduleData.enabled,
              scheduleDaily: scheduleData.scheduleDaily,
              connectorId: scheduleData.connectorId,
            }));
          } else {
            setSx((prevSx) => ({
              ...prevSx,
              ...scheduleData,
              display: true,
              startDateTime: scheduleData.startDateTime,
              endDateTime: scheduleData.endDateTime,
              enabled: scheduleData.enabled,
              scheduleDaily: scheduleData.scheduleDaily,
              connectorId: scheduleData.connectorId,
            }));
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError({
          display: true,
          title: 'No Schedule',
          message: 'Could not find a schedule for this station and vehicle',
          styles: 'driver',
        });
      }
    };
    getSx();
  }, [props.iotHubDeviceId, props.vehicleId, setError]);

  const closeModal = () => {
    props.handleModal();
  };

  const handleChange = (e) => {
    setSx({ ...sx, [e.target.name]: e.target.value });
  };

  const handleTimeChange = (e, name) => {
    setSx({ ...sx, [name]: e });
  };

  const handleScheduleDaily = () => {
    setSx({ ...sx, scheduleDaily: !sx.scheduleDaily });
  };

  const enabledHandler = () => {
    setSx({ ...sx, enabled: !sx.enabled });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const scheduleData = {
      ...sx,
      startDateTime: sx.startDateTime,
      endDateTime: sx.endDateTime,
    };

    props.submit(scheduleData);
  };

  const mockConnectors = {
    connectorStatuses: [
      {
        connectorId: 1,
      },
      {
        connectorId: 2,
      },
    ],
  };

  useEffect(() => {
    if (props.connectors.connectorStatuses.length === 0) {
      setDisableSx(true);
      setSx((s) => ({ ...s, enabled: false }));
    }
    if (props.connectors.connectorStatuses.length === 1) {
      setSx((s) => ({ ...s, connectorId: 1 }));
    }
  }, [props.connectors]);

  return (
    <ModalStyles>
      {loading && <LoadingSpinner />}
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.scheduleCharge}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <form onSubmit={handleSubmit} id='sx-form'>
          <ModalContentStyles textMargin='0'>
            {/* Section */}
            <ModalItemStyles padding='0 10px'>
              <ToggleSwitch
                disable={disableSx}
                checked={!!sx.enabled}
                name='enabled'
                handleChange={enabledHandler}
                label={strings.enabled}
              />
              <ToggleSwitch
                padding='5px 10px'
                disable={!sx.enabled}
                checked={!!sx.scheduleDaily}
                name='scheduleDaily'
                handleChange={handleScheduleDaily}
                label={strings.scheduleDaily}
              />
            </ModalItemStyles>
            <ModalItemStyles padding='0 10px'>
              <DivStyles align='left'>
                <TitleStyles size='14px' margin='10px 0'>
                  {strings.scheduleStart}:
                </TitleStyles>
                <TimePicker
                  className='Time-Picker'
                  showSecond={false}
                  value={moment(sx.startDateTime)}
                  onChange={(e) => handleTimeChange(e, 'startDateTime')}
                  format={format}
                  use12Hours
                  inputReadOnly
                  id='start'
                  disabled={!sx.enabled}
                  allowEmpty={false}
                />
              </DivStyles>
              <DivStyles align='left'>
                <TitleStyles size='14px' margin='10px 0'>
                  {strings.scheduleEnd}:
                </TitleStyles>
                <TimePicker
                  className='Time-Picker'
                  showSecond={false}
                  placeholder={'End Time'}
                  value={moment(sx.endDateTime)}
                  onChange={(e) => handleTimeChange(e, 'endDateTime')}
                  format={format}
                  use12Hours
                  inputReadOnly
                  id='finish'
                  disabled={!sx.enabled}
                  allowEmpty={false}
                />
              </DivStyles>
            </ModalItemStyles>
            {props.connectors.connectorStatuses.length > 1 && (
              <DivStyles
                display='flex'
                justifyContent='center'
                margin='20px 0'
                wrap='nowrap'
                alignItems='center'>
                <TextStyles size='16px' margin='5px 0 0'>
                  {strings.portLabel}:{' '}
                </TextStyles>
                <SelectStyles
                  width='150px'
                  margin='0 0 0 10px'
                  className='connector-selection'
                  name='connectorId'
                  id='app-connector-selection'
                  onChange={handleChange}
                  value={sx.connectorId || ''}
                  disabled={!sx.enabled}
                  required>
                  <option disabled value=''>
                    {strings.connectorPortChoose}
                  </option>
                  {mockConnectors.connectorStatuses.map((port) => {
                    return (
                      <option key={port.connectorId} value={port.connectorId}>
                        {port.connectorId}
                      </option>
                    );
                  })}
                </SelectStyles>
              </DivStyles>
            )}

            {disableSx && (
              <ModalItemStyles pSize='12px' align='left' padding='0 15px'>
                <TextStyles color='red'>
                  {strings.scheduleConnectorMissing}
                </TextStyles>
              </ModalItemStyles>
            )}
            {props.errors.length > 0 && (
              <ModalItemStyles pSize='12px' align='left' padding='0 15px'>
                <TextStyles color='red'>{strings.scheduleErrors}</TextStyles>
                <ul>
                  {props.errors.map((value, index) => {
                    return <li key={index}>{value}</li>;
                  })}
                </ul>
              </ModalItemStyles>
            )}
            {sx.display && (
              <ModalItemStyles pSize='12px' align='left' padding='0 15px'>
                <TextStyles>
                  {!sx.lastScheduledSessionSkipped ? (
                    <>
                      {strings.lastSuccess}:{' '}
                      {moment(sx.lastStartedDateTime).format('MM/DD h:mm a')}
                    </>
                  ) : (
                    <>
                      {strings.lastAttempt}:{' '}
                      {moment(sx.lastStartedDateTime).format('MM/DD h:mm a')}
                    </>
                  )}
                </TextStyles>
              </ModalItemStyles>
            )}
          </ModalContentStyles>
          {/* Button Component for Modals*/}
          <DivStyles
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            align='left'>
            <TextStyles size='12px' margin='10px 15px'>
              {strings.scheduleForget}
            </TextStyles>
            <ModalButton
              disabled={disableSx}
              containerMargin='10px 10px 0'
              containerAlign='right'
              buttonPadding='10px'
              buttonMargin='0'
              formSubmit='sx-form'
              buttonText={strings.scheduleOk}
            />
          </DivStyles>
        </form>
      </ModalContainerStyles>
    </ModalStyles>
  );
};
export default AppChargingScheduleModal;
