import React from 'react';
import { ThemeProvider } from 'styled-components';

//services
import AppLandingService from './AppLandingService';
import AppPlatformService from '../../services/AppPlatform/AppPlatformService';

//images
import landingLogo from '../../assets/images/logo.png';

//styles
import { ButtonStyles } from '../SharedStyles';
import './AppLanding.scss';

//helpers
import { CSO_THEME, DRIVER_THEME } from '../shared';

export default function AppLanding() {
  if (AppPlatformService.isCSOApp()) {
    return (
      <ThemeProvider theme={CSO_THEME}>
        <div className='app-landing'>
          <div className='body-container'>
            <div>
              <img src={landingLogo} alt='AmpedUp Logo' />
            </div>

            <div className='title'>{AppLandingService.strings.welcome}</div>
            <div className='text'>
              {AppLandingService.strings.csoText}{' '}
              <em>{AppLandingService.strings.ampedupCso}</em>{' '}
              {AppLandingService.strings.csoTextTwo}
            </div>

            <div className='button-container'>
              <ButtonStyles
                type='button'
                onClick={AppLandingService.onContinueClick}>
                {AppLandingService.strings.continue}
              </ButtonStyles>
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
  if (AppPlatformService.isDriverApp()) {
    return (
      <ThemeProvider theme={DRIVER_THEME}>
        <div className='app-landing'>
          <div className='body-container'>
            <div>
              <img src={landingLogo} alt='AmpedUp Logo' />
            </div>

            <div className='title'>{AppLandingService.strings.welcome}</div>
            <div className='text'>
              {AppLandingService.strings.text}{' '}
              <em>{AppLandingService.strings.ampedup}</em>{' '}
              {AppLandingService.strings.textTwo}
            </div>

            <div className='button-container'>
              <ButtonStyles
                type='button'
                onClick={AppLandingService.onContinueClick}>
                {AppLandingService.strings.continue}
              </ButtonStyles>
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }

  return (
    <div className='app-landing'>
      <div className='body-container'>
        <div>
          <img src={landingLogo} alt='AmpedUp Logo' />
        </div>

        <div className='title'>{AppLandingService.strings.welcome}</div>
        <div className='text'>
          {AppLandingService.strings.text}{' '}
          <em>{AppLandingService.strings.ampedup}</em>{' '}
          {AppLandingService.strings.textTwo}
        </div>

        <div className='button-container'>
          <ButtonStyles
            type='button'
            onClick={AppLandingService.onContinueClick}>
            {AppLandingService.strings.continue}
          </ButtonStyles>
        </div>
      </div>
    </div>
  );
}
