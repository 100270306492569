import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppMembershipsService from '../AppMembershipsService';

//components
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';

//images
import membershipsPlaceholder from '../../../../assets/images/MembershipsPlaceholder.png';

//context
import { ErrorContext } from '../../../context/shared/ErrorContext';

const JoinModal = (props) => {
  const { strings } = AppMembershipsService;
  const { membership } = props;
  const { setError } = useContext(ErrorContext);

  const [loading, setLoading] = useState(false);

  const handleJoinMembership = async () => {
    setLoading(true);
    try {
      let success = await AppMembershipsService.insertDriverMembership(
        props.membership.id
      );
      if (success) {
        props.history.push('/memberships');
      }
    } catch (error) {
      setLoading(false);
      setError({
        display: true,
        title: 'Something went wrong',
        message:
          'We are unable to add you to the membership at this current time',
        styles: 'driver',
      });
    }
  };

  return (
    <div className='Modal-Display-Driver'>
      {loading && <LoadingSpinner />}
      <div className='Modal'>
        <div className='Modal-Header'>
          {strings.joinTitle}
          <div className='Modal-Close'>
            <FaRegWindowClose onClick={() => props.closeModal('join')} />
          </div>
        </div>
        <div className='Modal-Content'>
          <div className='Modal-Image'>
            <img
              src={
                membership.imagePath
                  ? membership.imagePath
                  : membershipsPlaceholder
              }
              alt={membership.membershipName}
            />
          </div>
          <h2 className='Modal-Content-Title'>{membership.membershipName}</h2>
          <p className='Modal-Content-Text'>{strings.joinParagraph}</p>
        </div>
        <div className='Modal-Button'>
          <button className='app-button' onClick={handleJoinMembership}>
            {strings.joinButton}
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(JoinModal);
