import React, { useState, useEffect, useContext } from 'react';

//layout
import { OuterWrapper, ComponentWrapper } from '../../OperatorShared/index';

//services
import AppChargersService from '../AppChargersService';

//components
import ChargerHeader from '../ChargerHeader';
import ChargersList from './ChargerList/ChargersList';

//global components
import SearchFilterBar from '../../OperatorShared/SearchFilterBar';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';

const ChargersHistory = (props) => {
  const { strings } = AppChargersService;
  const { account, currentUser } = useContext(CsoAccountContext);
  const [charger, setCharger] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchedValue, setSearchedValue] = useState('');
  const [filteredValue, setFilteredValue] = useState(null);
  const [submitSearch, setSubmitSearch] = useState(false);

  useEffect(() => {
    const fetchCharger = async () => {
      const request = {
        iotHubDeviceId: props.match.params.id,
        location: currentUser.userLocation,
      };
      let response = await AppChargersService.getCharger(request);
      setCharger(response);
      if (response) {
        setLoading(false);
      }
    };
    fetchCharger();
  }, [props.match.params.id, currentUser.userLocation]);

  const handleFilterChange = (filter) => {
    setSubmitSearch(false);
    if (filter !== '') {
      setFilteredValue(filter);
    } else {
      setFilteredValue(null);
      setSearchedValue('');
    }
  };

  const handleSearchChange = (value) => {
    setSubmitSearch(false);
    setSearchedValue(value);
  };

  const handleSearchSubmit = (submit) => {
    setSubmitSearch(submit);
  };
  return (
    <OuterWrapper
      title={strings.chargingSessionTitle}
      internal
      path='/chargers'>
      <ComponentWrapper title={strings.chargingSessionTitle}>
        {loading && <LoadingSpinner />}
        {charger && <ChargerHeader charger={charger} />}
        {account && (
          <SearchFilterBar
            sessionFilter
            account={account}
            filterBy={
              account.subscriptionLevel === 3
                ? strings.sessionAdminFilterList
                : strings.sessionFilterList
            }
            handleSearchSubmit={handleSearchSubmit}
            handleSearchChange={handleSearchChange}
            handleFilterChange={handleFilterChange}
          />
        )}
        {charger && (
          <ChargersList
            account={account}
            charger={charger}
            filteredValue={submitSearch ? filteredValue : ''}
            searchedValue={submitSearch ? searchedValue : ''}
          />
        )}
      </ComponentWrapper>
    </OuterWrapper>
  );
};

export default ChargersHistory;
