import React, { useState, useEffect, useContext, useMemo } from 'react';

//services
import AppChargingProfilesService from '../AppPricingSchedulesService';

//components
import Schedules from './PricingSx/Schedules';

//global components
import ToggleSwitch from '../../OperatorShared/ToggleSwitch/CsoToggleSwitch';
import RoleCanEdit from '../../OperatorShared/RoleCanEdit';

//context
import { ErrorContext } from '../../../context/shared/ErrorContext';
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';

//styles
import {
  DivStyles,
  TitleStyles,
  ButtonGreyStyles,
} from '../../../SharedStyles';

const DailySchedules = (props) => {
  const { strings } = AppChargingProfilesService;
  const { editForm, profile } = props;
  const { account } = useContext(CsoAccountContext);
  const { setError } = useContext(ErrorContext);
  const [sx, setSx] = useState([]);

  let schedulesViaProps = profile.schedules;

  useMemo(() => {
    if (editForm) {
      schedulesViaProps.map((sx) => {
        sx.times.map((time) => {
          time.id = Math.random().toString(36).substring(2);
          return time;
        });
        return sx;
      });
      setSx(schedulesViaProps);
    } else {
      const SxObject = {
        id: Math.random().toString(36).substring(2),
        dow: {
          sunday: false,
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
        },
        times: [
          {
            id: Math.random().toString(36).substring(2),
            timeStart: '',
            timeEnd: '',
            rate: 0,
            rateType: '',
            idleRate: 0,
            idleRateType: 'none',
            idleGracePeriod: 0,
            minAmount: 0,
            //maxTime ???
          },
        ],
      };
      setSx((s) => [...s, SxObject]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editForm]);

  const handleAddSx = () => {
    if (
      (account.subscriptionLevel === 2 && sx.length === 2) ||
      (account.subscriptionLevel === 3 && sx.length === 7)
    ) {
      setError({
        display: true,
        title: strings.sxLimitTitle,
        message:
          account.subscriptionLevel !== 2
            ? strings.sxLimitMessage
            : strings.sxsmBizLimitMessage,
        styles: 'cso',
      });
    } else {
      const SxObject = {
        id: Math.random().toString(36).substring(2),
        dow: {
          sunday: false,
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
        },
        times: [
          {
            id: Math.random().toString(36).substring(2),
            timeStart: '',
            timeEnd: '',
            rate: 0,
            rateType: '',
            idleRate: 0,
            idleRateType: 'none',
            idleGracePeriod: 0,
            minAmount: 0,
            //maxTime ???
          },
        ],
      };
      setSx([...sx, SxObject]);
    }
  };

  const passData = (e) => {
    if (props.profile.schedules.length === 0) {
      handleAddSx();
    }
    props.handleChange([e.target.name], e.target.checked);
  };

  //pulls out the sx with the new id and rebuilds in same order
  const updatingSxArray = (newSch) => {
    let currentIndex;
    let removedSchedules = sx.filter((sch, index) => {
      if (sch.id === newSch.id) {
        currentIndex = index;
      }
      return newSch.id !== sch.id;
    });
    removedSchedules.splice(currentIndex, 0, newSch);
    setSx(removedSchedules);
  };

  useEffect(() => {
    props.handleChanges(sx);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sx]);

  const removeItemFromSx = (id) => {
    let removedSchedules = sx.filter((sch) => {
      return sch.id !== id;
    });
    setSx(removedSchedules);
  };

  return (
    <DivStyles padding='10px'>
      <DivStyles
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        margin='10px 0 30px'>
        <DivStyles display='flex' alignItems='center'>
          <TitleStyles size='14px' weight='600' margin='0'>
            {strings.dailySx}
          </TitleStyles>
          {props.profile.enableSchedules && (
            <RoleCanEdit roles={[1]}>
              <ButtonGreyStyles
                size='12px'
                margin='0 0 0 30px'
                padding='0 10px'
                disabled={sx.length === 7}
                onClick={handleAddSx}>
                {strings.addSchedule}
              </ButtonGreyStyles>
            </RoleCanEdit>
          )}
        </DivStyles>
        <ToggleSwitch
          name='enableSchedules'
          handleChange={passData}
          disabled={props.adminPermission}
          checked={props.profile.enableSchedules}
        />
      </DivStyles>
      {props.profile.enableSchedules &&
        sx.map((schedule, i) => {
          return (
            <Schedules
              key={schedule.id}
              account={account}
              profile={props.profile}
              editForm={props.editForm}
              updatingSxArray={updatingSxArray}
              removeItemFromSx={removeItemFromSx}
              allSxs={sx}
              schedule={schedule}
              count={i + 1}
            />
          );
        })}
    </DivStyles>
  );
};

export default DailySchedules;
