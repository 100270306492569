import React, { useState, useContext } from 'react';

//layout
import { InteriorWrapper, ComponentWrapper } from '../shared';
import Button from '../../shared/Buttons/Button';

//services
import AppNotificationsService from './AppNotificationsService';

//components
import ToggleSwitch from '../shared/ToggleSwitch';
import LoadingSpinner from '../../shared/Loading/LoadingSpinner';

//styles
import {
  InputStyles,
  ToggleSwitchContent,
  ToggleSwitchInputFields,
  InputContainerStyles,
} from '../../SharedStyles';

//context
import { DriverContext } from '../../context/driver/getDriverContext';
import { ErrorContext } from '../../context/shared/ErrorContext';

//error Class
import { ErrorResponse } from '../../shared/ErrorResponse';

//helpers
import { driverNotificationSettings } from './helpers';

const Notifications = (props) => {
  const { strings } = AppNotificationsService;
  const { driver, setDriver, postDriver } = useContext(DriverContext);
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      if (
        driver.notifyByEmail &&
        (!driver.notificationEmail || driver.notificationEmail === '')
      ) {
        throw new ErrorResponse(
          'frontend',
          'Email required when enabled.',
          'Missing Email'
        );
      }
      if (
        driver.notifyByText &&
        (!driver.notificationText || driver.notificationText === '')
      ) {
        throw new ErrorResponse(
          'frontend',
          'Phone number required when enabled.',
          'Missing Phone Number'
        );
      }
      let updatedDriver = await postDriver();
      if (updatedDriver) {
        props.history.goBack();
      }
    } catch (err) {
      setLoading(false);
      if (err.name === 'frontend') {
        setError({
          display: true,
          title: err.title,
          message: err.message,
          styles: 'driver',
        });
      }
    }
  };

  const handleChangeToggle = (e) => {
    setDriver({ ...driver, [e.target.name]: e.target.checked });
  };

  const handleTextChange = (e) => {
    setDriver({ ...driver, [e.target.name]: e.target.value });
  };

  return (
    <InteriorWrapper
      title={strings.title}
      isInternal
      myForm='myForm'
      path='/charging'>
      <ComponentWrapper title={strings.title}>
        {loading && <LoadingSpinner />}
        <form id='myForm' onSubmit={handleSubmit}>
          <ToggleSwitchContent borderBottom='1px solid #e7e7e7'>
            <ToggleSwitch
              checked={!!driver.enableNotifications}
              name='enableNotifications'
              handleChange={handleChangeToggle}
              label='Enable notifications'
            />
          </ToggleSwitchContent>
          <ToggleSwitchContent borderBottom='1px solid #e7e7e7'>
            {driverNotificationSettings.map((setting) => (
              <ToggleSwitch
                key={setting.name}
                disable={!driver.enableNotifications}
                checked={!!driver[setting.name]}
                name={setting.name}
                handleChange={handleChangeToggle}
                label={setting.label}
              />
            ))}
          </ToggleSwitchContent>
          <ToggleSwitchInputFields textAlign='left'>
            <h2 className='Content-Title'>{strings.notifyBy}</h2>
            <ToggleSwitch
              disable={!driver.enableNotifications}
              checked={!!driver.notifyByEmail}
              name='notifyByEmail'
              handleChange={handleChangeToggle}
              label={strings.email}
            />
            <InputContainerStyles padding='0 20px 0 10px'>
              <InputStyles
                padding='0'
                type='text'
                onChange={handleTextChange}
                value={driver.notificationEmail || ''}
                name='notificationEmail'
                placeholder={strings.email}
              />
            </InputContainerStyles>
            <ToggleSwitch
              disable={!driver.enableNotifications}
              checked={!!driver.notifyByText}
              name='notifyByText'
              handleChange={handleChangeToggle}
              label={strings.textMessage}
            />
            <InputContainerStyles padding='0 20px 0 10px'>
              <InputStyles
                padding='0'
                type='text'
                value={driver.notificationText || ''}
                onChange={handleTextChange}
                placeholder='mobile number'
                name='notificationText'
              />
            </InputContainerStyles>
          </ToggleSwitchInputFields>
        </form>
      </ComponentWrapper>
      <Button driver hasMobile formSubmit buttonText={strings.save} />
    </InteriorWrapper>
  );
};

export default Notifications;
