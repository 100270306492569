import React from 'react';

//styles
import { ConnectorStyles, ConnectorSpanStyles } from './ConnectorsStyles';

//helpers
import { chargerConnectorObj } from '../Helpers';

const ConnectorImages = (props) => {
  const { charger } = props;
  const list = charger?.evses ? charger.evses[0].connectors : charger.connectors;
  return (
    <ConnectorStyles alignItems={props.alignItems}>
      {list.map((connector, i) => {
        chargerConnectorObj.forEach((connImage) => {
          const connectorType = connector.connectorType ? connector.connectorType : connector.connectorName;
          if (
            connImage.type.toLowerCase() ===
            connectorType.toLowerCase()
          ) {
            connector = {
              ...connImage,
              ...connector,
            };
            connector.connectorCount = 1;
            connector.connectorType = connectorType;
          }
        });
        return (
          <ConnectorSpanStyles
            margin={props.margin}
            key={i}>
            {props.showName && !props.bottomName && (
              <>
                {connector.connectorType} ({connector.connectorCount}){' '}
              </>
            )}
            <img
              className='connector-image-small'
              src={connector.image}
              alt={connector.type}
            />
            {!props.showName && <>({connector.connectorCount}) </>}
            {props.bottomName && connector.connectorType}
          </ConnectorSpanStyles>
        );
      })}
      {charger.connectors.length === 0 && props.text ? props.text : null}
    </ConnectorStyles>
  );
};

export default ConnectorImages;
