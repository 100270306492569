import AppApiService from '../../../services/AppApi/AppApiService';

const AppSlideService = {
  //iothubdeviceId and image id object
  approveChargerImage: function(charger) {
    return AppApiService.post('approveChargerImage', null, charger);
  },
  //iothubdeviceId and image id object
  deleteChargerImage: function(charger) {
    return AppApiService.post('deleteChargerImage', null, charger);
  }
};

export default AppSlideService;
