import React, { useState, useContext } from 'react';
import { FaRegEdit } from 'react-icons/fa';

//layout
import { OuterWrapper, ComponentWrapper } from '../../OperatorShared';
import Button from '../../../shared/Buttons/Button';

//services
import AppMembershipsService from '../AppMembershipsService';

//components
import MembershipChargers from './MembershipsOptions/MembershipChargers';
import MembershipOptions from './MembershipsOptions/MembershipOptions';
import MembershipMessage from './MembershipsOptions/MembershipMessage';

//global components
import ToggleSwitch from '../../../driver/shared/ToggleSwitch';
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';
import SubscriptionAccess from '../../OperatorShared/SubscriptionAccess';
import AccessDeniedComponent from '../../../shared/AppAccessDenied/AccessDeniedComponent';

//modals
import EditModal from '../MembershipsModals/EditModal';

//context
import { CsoMembershipContext } from '../../../context/operator/getCsoMembership';
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import { ErrorContext } from '../../../context/shared/ErrorContext';

//images
import placeholder from '../../../../assets/images/subscriptionShareImage.png';

//error Class
import { ErrorResponse } from '../../../shared/ErrorResponse';

const MembershipAdd = props => {
  const { strings } = AppMembershipsService;
  const { fetchMemberships } = useContext(CsoMembershipContext);
  const { setError } = useContext(ErrorContext);
  const { account, currentUser  } = useContext(CsoAccountContext);
  const [loading, setLoading] = useState(false);
  const [membership, setMembership] = useState({
    imagePath: null,
    enabled: false,
    csoAccountId: account.csoAccountId
  });

  console.log('Logging Membership');

  const [imageData, setImageData] = useState({
    image: null,
    membershipImage: placeholder
  });
  const [showEditModal, setShowEditModal] = useState(false);

  const showEditTitleModal = () => {
    setShowEditModal(!showEditModal);
  };

  const handleEnableToggle = e => {
    setMembership({ ...membership, enabled: !membership.enabled });
  };

  const handleDataChange = (updatedMembership, image) => {
    if (image) {
      setImageData(image);
      setMembership({
        ...membership,
        ...updatedMembership
      });
    } else {
      setMembership({
        ...membership,
        ...updatedMembership
      });
    }
  };

  const handleValidation = membership => {
    if (!membership.membershipName || !membership.membershipCode) {
      throw new ErrorResponse(
        'Frontend',
        'Memberships require a name and membership code to be created.',
        'Failed Save'
      );
    }
    if (!membership.hasOwnProperty('expires')) {
      throw new ErrorResponse(
        'Frontend',
        'Memberships need a expires settings to save.',
        'Failed Save'
      );
    }
    if (membership.enabled && !membership.discountScheduleId) {
      throw new ErrorResponse(
        'Frontend',
        'You must have a discount applied to enable the membership.',
        'Failed Save'
      );
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      if (account && (account.subscriptionLevel === 1 || account.subscriptionLevel === 2)) { // share, small biz
        membership.visibility = 2; // public approval required
      }
      handleValidation(membership);
      await AppMembershipsService.insertMembership(membership);
      setLoading(false);
      fetchMemberships();
      setTimeout(() => {
        if (!loading) {
          props.history.push('/subscription/memberships/');
        }
      }, 500);
    } catch (err) {
      setLoading(false);
      if (err.name === 'Frontend') {
        setError({
          display: true,
          title: err.title,
          message: err.message,
          styles: 'cso'
        });
      }
    }
  };

  return (
    <OuterWrapper
      title={strings.add}
      internal
      path='/subscription/memberships'
      myForm
      roles={[1, 2, 4]}>
      {loading && <LoadingSpinner />}
      {showEditModal && (
        <EditModal
          input
          newMembership
          membership={membership}
          handleChangeModal={handleDataChange}
          toggleModal={showEditTitleModal}
          title={strings.membershipNameLogo}
          inputDescription={strings.membershipModalInput}
          content={strings.membershipModalContent}
          addLogo={strings.membershipModalAddLogo}
        />
      )}
      {/* =====================Enterprise and Small Biz Only===================================== */}
      {/*<SmallBizAdmin allowCustomer redirect>*/}
      <SubscriptionAccess levels={[1, 2, 3]}>
      {(account &&
          (account.subscriptionLevel === 3 &&
            currentUser &&
            currentUser.role !== 3)) ||
        (account && account.subscriptionLevel === 2) ||
        (account && account.subscriptionLevel === 1) ? (
          <>
            <ComponentWrapper title={strings.add}>
              <form id='myForm' onSubmit={handleSubmit}>
                <div className='Content-header-container'>
                  <div className='Left-align'>
                    <div className='Content-header-image'>
                      <img
                        src={
                          membership.imagePath
                            ? membership.imagePath
                            : imageData.membershipImage
                        }
                        alt='placholder'
                      />
                    </div>
                    <div className='Left-stack'>
                      <h3>
                        {membership.membershipName || 'Membership Name'}{' '}
                        <span className='Edit-icon' onClick={showEditTitleModal}>
                          <FaRegEdit />
                        </span>
                      </h3>
                      <p>
                        {strings.code}: {membership.membershipCode || 'MemCode'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='Content-enable-container'>
                  <h3>{strings.enableMembership}</h3>
                  <ToggleSwitch
                    newMembership
                    name='enabled'
                    checked={!!membership.enabled}
                    handleChange={handleEnableToggle}
                  />
                </div>
                <MembershipChargers
                  newMembership
                  membership={membership}
                  handleDataChange={handleDataChange}
                />
                <div className='OverFlow-Container-Y'>
                  <MembershipOptions
                    newMembership
                    membership={membership}
                    handleDataChange={handleDataChange}
                  />
                  <MembershipMessage
                    newMembership
                    membership={membership}
                    handleDataChange={handleDataChange}
                  />
                </div>
              </form>
            </ComponentWrapper>
            <Button
              cso
              hasMobile
              formSubmit
              buttonText={strings.modalSave}
              roles={[1, 2, 4]}
            />
          </>
        ) : 
          <AccessDeniedComponent
            smallBiz
            permissionIssue={
              account.subscriptionLevel === 3 &&
              currentUser &&
              currentUser.role !== 3
            }
          />
        }
      </SubscriptionAccess>
      {/*</SmallBizAdmin>*/}
      {/* ========================================================================= */}
    </OuterWrapper>
  );
};

export default MembershipAdd;
