import React from 'react';

//layout
import { SubscriptionAccess } from '../../OperatorShared';

//components
import ChargerStatus from './ChargerStatus/ChargerStatus';
import ChargerEnergy from './ChargerEnergy/ChargerEnergy';
import ChargerMap from './ChargerMap/ChargerMap';
import ChargingSessions from './ChargingSessions/ChargingSessions';
import ChargerRevenue from './ChargerRevenue/ChargerRevenue';

//styles
import { DivStyles } from '../../../SharedStyles';
// import ChargerIncome from './ChargerIncome/ChargerIncome';
import ChargerGeneral from './ChargerGeneral/ChargerGeneral';

//context


const SbAndEnter = (props) => {
  const { strings, account, sessions, allChargers, loading } = props;

  return (
    <>
      <DivStyles display='flex' padding='10px' flexWrap='wrap'>
        <ChargerStatus strings={strings} loading={props.loading} />
        <ChargingSessions
          account={account}
          strings={strings}
          loading={loading}
        />
      </DivStyles>
      <SubscriptionAccess levels={[3]}>
      <DivStyles display='flex' padding='10px' flexWrap='wrap'>
          <ChargerEnergy loading={props.loading} />
        </DivStyles>        
        <DivStyles display='flex' padding='10px' flexWrap='wrap'>
          <ChargerRevenue loading={props.loading} />
        </DivStyles>
        <DivStyles display='flex' padding='10px' flexWrap='wrap'>
          <ChargerMap />
        </DivStyles>
      </SubscriptionAccess>
      <DivStyles display='flex' padding='10px' flexWrap='wrap'>
        {/* <ChargerIncome /> */}
        <ChargerGeneral
          sessions={sessions}
          loading={loading}
          account={account}
          allChargers={allChargers}
        />
      </DivStyles>
    </>
  );
};

export default SbAndEnter;
