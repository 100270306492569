import React, { useState, useEffect, useContext } from 'react';

//services
import AppMembershipsService from '../AppMembershipsService';

//components
import MembershipCard from './MembershipCard';

//global components
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';
import Pagination from '../../../shared/Pagination/Pagination';

//hooks
import { usePagination } from '../../../shared/hooks/usePagination';

//context
import { CsoMembershipContext } from '../../../context/operator/getCsoMembership';

//styles
import { DivStyles, ListPlaceholderStyles } from '../../../SharedStyles';

//images
import membershipsPlaceholder from '../../../../assets/images/MembershipsPlaceholder.png';

const MembershipList = (props) => {
  const { strings } = AppMembershipsService;
  const { allMemberships, fetchMemberships } = useContext(CsoMembershipContext);
  const [memberships, setMemberships] = useState([]);
  const [filteredMemberships, setFilteredMemberships] = useState([]);
  const [loading, setLoading] = useState(true);

  const { filteredValue, searchedValue } = props;

  useEffect(() => {
    const sortByname = async () => {
      let gotMemberships = await fetchMemberships();
      gotMemberships.sort((a, b) => {
        return a['membershipName'].localeCompare(b['membershipName']);
      });
      setMemberships(gotMemberships);
      if (gotMemberships.length === 0) {
        setLoading(false);
      }
    };
    sortByname();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMemberships.length]);

  useEffect(() => {
    const lowercasedSearch = searchedValue.toLowerCase();

    const searchedMembership = memberships.filter((member) => {
      return Object.keys(member).some((key) => {
        if (member[key] !== null) {
          return member[filteredValue ? filteredValue : key]
            .toString()
            .toLowerCase()
            .includes(lowercasedSearch);
        }
        return null;
      });
    });
    setFilteredMemberships(searchedMembership);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedValue, filteredValue]);

  useEffect(() => {
    setFilteredMemberships(memberships);
  }, [memberships]);

  useEffect(() => {
    if (filteredMemberships.length) {
      setLoading(false);
    }
  }, [filteredMemberships]);

  const removeMembership = async () => {
    setLoading(true);
    await fetchMemberships();
  };

  /**
   * pagination for the data
   * @param {*} dataArray - items that will be sorted
   * @param {*} itemsPerPage - Custom items limit per page
   */
  const {
    next,
    prev,
    jump,
    currentData,
    currentPage,
    maxPage,
    totalData,
    currentCount,
    pageNumbers,
  } = usePagination(filteredMemberships, 20);

  return (
    <>
      {loading && <LoadingSpinner />}
      {memberships.length !== 0 && (
        <Pagination
          currentCount={currentCount}
          totalData={totalData}
          next={next}
          prev={prev}
          jump={jump}
          currentPage={currentPage}
          maxPage={maxPage}
          pageNumbers={pageNumbers}
        />
      )}
      <DivStyles padding='0'>
        {currentData().map((membership, i) => (
          <MembershipCard
            index={i}
            removeMembership={removeMembership}
            key={membership.id}
            membership={membership}
          />
        ))}
        {memberships.length === 0 && !loading && (
          <ListPlaceholderStyles borderBottom='none'>
            <img src={membershipsPlaceholder} alt='No Memberships' />
            <p>{strings.noMemberships}</p>
          </ListPlaceholderStyles>
        )}
      </DivStyles>
    </>
  );
};

export default MembershipList;
