import React, { useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useSwipeable } from 'react-swipeable';

//components
import AppChargingContent from '../AppChargingContentSlider/AppChargingContent/AppChargingContent';

const pages = 2;


const Swipeable = ({children, ...props}) => {
  const handlers = useSwipeable(props);
  return (<div { ...handlers }>{children}</div>);
}

const AppChargingContentSlider = (props) => {
  const [index, setIndex] = useState(0);
  const [forward, setForward] = useState(true);

  const nextSlide = (ev) => {
    let newIndex = (index + 1) % pages;
    if (
      newIndex > index &&
      (!ev.event || ev.event.target.className.indexOf('slider') === -1)
    ) {
      setForward(true);
      setIndex(newIndex);
    }
  };

  const prevSlide = (ev) => {
    let newIndex = (index || pages) - 1;

    if (
      newIndex < index &&
      (!ev.event || ev.event.target.className.indexOf('slider') === -1)
    ) {
      setForward(false);
      setIndex(newIndex);
    }
  };

  const addEndListener = (node, done) =>
    node.addEventListener('transitionend', done, false);

  return (
    <>
      <Swipeable onSwipedLeft={nextSlide} onSwipedRight={prevSlide}>
        <section className={`slideshow ${forward ? 'forwards' : 'backwards'}`}>
          <div
            className='next content-slider-next'
            style={index === 0 ? { display: 'block' } : { display: 'none' }}
            onClick={nextSlide}
          />
          <div
            className='prev content-slider-prev'
            style={index === 0 ? { display: 'none' } : { display: 'block' }}
            onClick={prevSlide}
          />
          <TransitionGroup component={null}>
            <CSSTransition
              key={index}
              classNames='slide'
              addEndListener={addEndListener}>
              <AppChargingContent
                car={props}
                index={index}
                charger={props.charger}
                linkedcharger={props.linkedcharger}
                checkingIsCharging={props.checkingIsCharging}
                getCharger={props.getCharger}
              />
            </CSSTransition>
          </TransitionGroup>
        </section>
        <div className='charging-indicator-container'>
          <div
            onClick={prevSlide}
            className={
              index === 0 ? 'charging-dot charging-active' : 'charging-dot'
            }
          />
          <div
            onClick={nextSlide}
            className={
              index === 1 ? 'charging-dot charging-active' : 'charging-dot'
            }
          />
        </div>
      </Swipeable>
    </>
  );
};

export default AppChargingContentSlider;
