import React, { createContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

//services
import AppApiService from '../../../services/AppApi/AppApiService';
import { AppGeoLocationService } from '../../../services/AppGeoLocation/AppGeoLocationService';

//global components
import LoadingSpinner from '../../shared/Loading/LoadingSpinner';
import NoLocation from '../../shared/NoLocationPermissions/NoLocation';

export const CsoAccountContext = createContext();

const CsoAccount = (props) => {
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [missingLocationPermission, setMissingLocationPermission] = useState(
    false
  );

  const quickFetchCso = async () => {
    let csoAccountData = await AppApiService.get('getCsoAccount');
    setAccount(csoAccountData.csoAccount);

    return true;
  };

  // useEffect(() => {
  //   quickFetchCso();
  // }, [props.location]);

  //fetches CSO Account and Current user on load
  async function fetchCso() {
    setLoading(true);
    try {
      const location = {
        type: 'Point',
        coordinates: null,
      };
      let userLoc = await AppGeoLocationService.getCurrentPosition();

      if (userLoc) {
        localStorage.removeItem('geoLocation');
        location.coordinates = [userLoc.lat, userLoc.lng];
      }

      let csoAccountData = await AppApiService.get('getCsoAccount');
      if (csoAccountData) {
        csoAccountData.csoSystemUser.userLocation = location;
        setAccount(csoAccountData.csoAccount);
        if (
          localStorage.getItem('cso-language') !==
            csoAccountData.csoSystemUser.language &&
          csoAccountData.csoSystemUser.language !== null
        ) {
          localStorage.setItem(
            'cso-language',
            csoAccountData.csoSystemUser.language
          );
          window.location.reload();
        }

        if (!csoAccountData.csoSystemUser.language) {
          csoAccountData.csoSystemUser.language = 'en-US';
        }
        setCurrentUser(csoAccountData.csoSystemUser);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (localStorage.getItem('geoLocation')) {
        setMissingLocationPermission(true);
      }
    }
  }

  //CSOAccount Creation
  async function postAccount(account) {
    setLoading(true);
    const location = {
      type: 'Point',
      coordinates: null,
    };
    AppGeoLocationService.getCurrentPosition().then((coord) => {
      location.coordinates = [coord.lat, coord.lng];
    });
    let newAccount = await AppApiService.post(
      'csoAccountCreation',
      null,
      account
    );
    newAccount.csoSystemUser.userLocation = location;
    setAccount(newAccount.csoAccount);
    setCurrentUser(newAccount.csoSystemUser);
    setLoading(false);
    return newAccount;
  }

  //Update the current user logged in only - State only - called in function below
  async function updateCurrentUserToAccount(user) {
    setAccount({ ...account, csoSystemUser: user });
  }

  //updates DB with currentuser and then calls function to update state
  async function updatingCurrentUser(prevLanguage) {
    setLoading(true);
    let updatedCurrentUser = await AppApiService.post(
      'updateSystemUser',
      null,
      currentUser
    );
    if (prevLanguage !== updatedCurrentUser.language) {
      window.location.reload();
    }
    setCurrentUser(updatedCurrentUser);
    updateCurrentUserToAccount(updatedCurrentUser);
    setLoading(false);
    return true;
  }

  async function updateUser(user) {
    setCurrentUser(user);
  }

  //updates the account
  async function updatingAccount(account) {
    let updatedAccount = await AppApiService.post(
      'csoAccountUpdate',
      null,
      account
    );
    setAccount((p) => ({ ...p, ...updatedAccount }));
    setLoading(false);

    return true;
  }

  //called on load to get everything cso related.
  useEffect(() => {
    fetchCso();
  }, []);

  return (
    <CsoAccountContext.Provider
      value={{
        account,
        loading,
        setLoading,
        setAccount,
        fetchCso,
        postAccount,
        updatingAccount,
        currentUser,
        setCurrentUser,
        updatingCurrentUser,
        updateUser,
        quickFetchCso,
      }}>
      {!loading && !missingLocationPermission ? (
        props.children
      ) : missingLocationPermission ? (
        <NoLocation />
      ) : (
        <LoadingSpinner />
      )}
    </CsoAccountContext.Provider>
  );
};

export default withRouter(CsoAccount);
