import React, { useState, useEffect, useContext } from 'react';

//layout
import { InteriorWrapper, ComponentWrapper } from '../shared';
import Button from '../../shared/Buttons/Button';

//services
import AppMembershipsService from './AppMembershipsService';

//components
import LoadingSpinner from '../../shared/Loading/LoadingSpinner';
import MembershipList from './MembershipList/MembershipList';

//modals
import PrivateCode from './Modals/PrivateCode';

//images
import membershipsPlaceholder from '../../../assets/images/MembershipsPlaceholder.png';

//context
import { ErrorContext } from '../../context/shared/ErrorContext';

//styles
import {
  ListPlaceholderStyles,
  ButtonContainerStyles,
} from '../../SharedStyles';

const Memberships = (props) => {
  const { strings } = AppMembershipsService;
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(true);
  const [memberships, setMemberships] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const fetchMemberships = async () => {
    try {
      const memberships = await AppMembershipsService.getDriverMemberships();
      setMemberships(memberships);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError({
        display: true,
        title: 'Something went wrong',
        message: 'We are having trouble gathering memberships',
        styles: 'driver',
      });
    }
  };

  useEffect(() => {
    fetchMemberships();
    // eslint-disable-next-line
  }, [showModal]);

  const toggleModalHandler = () => {
    setShowModal(!showModal);
  };

  const removeMembershipHandler = async (data) => {
    try {
      setLoading(true);
      await AppMembershipsService.removeDriverMembership(data);
      await fetchMemberships();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError({
        display: true,
        title: 'Something went wrong',
        message: 'We are unable to remove the membership at this current time',
        styles: 'driver',
      });
    }
  };

  return (
    <InteriorWrapper
      title={strings.membershipsTitle}
      isInternal
      path='/charging'>
      {loading && <LoadingSpinner />}
      <ComponentWrapper title={strings.membershipsTitle}>
        {showModal && <PrivateCode closeModal={toggleModalHandler} />}
        {memberships.length > 0 && (
          <MembershipList
            myMemberships
            memberships={memberships}
            removeMembership={removeMembershipHandler}
          />
        )}
        {memberships.length === 0 && !loading && (
          <ListPlaceholderStyles>
            <img src={membershipsPlaceholder} alt='No Memberships' />
            <p>{strings.noMemberships}</p>
          </ListPlaceholderStyles>
        )}
      </ComponentWrapper>
      <ButtonContainerStyles
        display='flex'
        justifyContent='center'
        margin='20px 0'>
        <Button
          driver
          hasMobile
          margin='0'
          doubleButton={[
            {
              text: strings.addButton,
              mobileText: strings.searchButton,
              link: '/memberships/add-membership',
              onclick: null,
              icon: null,
              inverse: false,
            },
            {
              text: strings.privateCode,
              mobileText: strings.privateCode,
              link: null,
              onclick: toggleModalHandler,
              icon: null,
              inverse: true,
            },
          ]}
        />
      </ButtonContainerStyles>
    </InteriorWrapper>
  );
};

export default Memberships;
