import React from 'react';
import { Link } from 'react-router-dom';
import { LogoContainerStyles } from '../../../SharedStyles';

//image
import landingLogo from '../../../../assets/images/logo.png';

const Logo = () => {
  return (
    <LogoContainerStyles mobileMedia='767px'>
      <Link to='/'>
        <img
          src={landingLogo}
          alt='AmpedUp Logo'
          className='FullScreen'
        />
        {/* <img
          src={require('../../../../assets/images/ampedup-mobile.png').default}
          alt='Mobile logo'
          className='Mobile'
        /> */}
      </Link>
    </LogoContainerStyles>
  );
};

export default Logo;
