import axios from 'axios';
import AppStorageService from '../AppStorage/AppStorageService';
import AppPlatformService from '../AppPlatform/AppPlatformService';
import strings from './strings';

const getApiHeaders = function (token) {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
};

const checkIfJsonObject = (data) => {
  try {
    return JSON.parse(data);
  } catch (err) {
    return data;
  }
};

const getEndpointUrl = function (endpoint, params) {
  let url = '';

  if (strings.endpoints[AppPlatformService.getVersion()]) {
    url = strings.endpoints[AppPlatformService.getVersion()][endpoint];
  } else {
    url = strings.endpoints.default[endpoint];
  }

  if (params && params !== '') {
    url = url + (url.indexOf(`${endpoint}?`) >= 0 ? '&' : '?') + params;
  }
  return url;
};

const AppApiService = {
  get: function (endpoint, params) {
    return new Promise((resolve, reject) => {
      AppStorageService.getValue(AppStorageService.values.authToken).then(
        (token) => {
          axios
            .get(getEndpointUrl(endpoint, params), {
              headers: getApiHeaders(token),
            })
            .then((response) => {
              if (response.status < 200 || response.status > 299) {
                reject(`${response.status} - ${response.statusText}`);
              }

              resolve(checkIfJsonObject(response.data));
              // resolve(response.data ? JSON.parse(response.data) : null);
            })
            .catch((error) => reject(error));

          /*
                fetch(getEndpointUrl(endpoint, params), {
                    method: 'GET',
                    headers: getApiHeaders(token)
                })
                .then(response => {
                    if (response.status < 200 || response.status > 299) {
                        reject(`${response.status} - ${response.statusText}`);
                    }
                    resolve(response.json());
                })
                .catch(error => reject(error));
                */
        }
      );
    });
  },
  post: function (endpoint, params, data) {
    return new Promise((resolve, reject) => {
      AppStorageService.getValue(AppStorageService.values.authToken).then(
        (token) => {
          axios
            .post(getEndpointUrl(endpoint, params), data, {
              headers: getApiHeaders(token),
            })
            .then((response) => {
              if (response.status < 200 || response.status > 299) {
                reject(`${response.status} - ${response.statusText}`);
              }
              resolve(checkIfJsonObject(response.data));
            })
            .catch((error) => reject(error));

          /*
                fetch(getEndpointUrl(endpoint, params), {
                    method: 'POST',
                    headers: getApiHeaders(token),
                    body: JSON.stringify(data)
                })
                .then(response => response.json())
                .then(data => onCompleteEvent(data));
                */
        }
      );
    });
  },
  put: function (endpoint, params, data) {
    return new Promise((resolve, reject) => {
      AppStorageService.getValue(AppStorageService.values.authToken).then(
        (token) => {
          axios
            .put(getEndpointUrl(endpoint, params), data, {
              headers: getApiHeaders(token),
            })
            .then((response) => {
              if (response.status < 200 || response.status > 299) {
                reject(`${response.status} - ${response.statusText}`);
              }
              resolve(checkIfJsonObject(response.data));
              // resolve(JSON.parse(response.data));
            })
            .catch((error) => reject(error));

          /*
                fetch(getEndpointUrl(endpoint, params), {
                    method: 'PUT',
                    headers: getApiHeaders(token),
                    body: JSON.stringify(data)
                })
                .then(response => response.json())
                .then(data => onCompleteEvent(data));
                */
        }
      );
    });
  },
  delete: function (endpoint, params) {
    return new Promise((resolve, reject) => {
      AppStorageService.getValue(AppStorageService.values.authToken).then(
        (token) => {
          axios
            .delete(getEndpointUrl(endpoint, params), {
              headers: getApiHeaders(token),
            })
            .then((response) => {
              if (response.status < 200 || response.status > 299) {
                reject(`${response.status} - ${response.statusText}`);
              }
              resolve(checkIfJsonObject(response.data));
              // resolve(JSON.parse(response.data));
            })
            .catch((error) => reject(error));

          /*
                fetch(getEndpointUrl(endpoint, params), {
                    method: 'DELETE',
                    headers: getApiHeaders(token)
                })
                .then(response => response.json())
                .then(data => onCompleteEvent(data));
                */
        }
      );
    });
  },
};

export default AppApiService;
