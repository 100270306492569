import React, { useState, useEffect, useMemo } from 'react';

//services
import AppChargingProfilesService from '../AppChargingProfilesService';

//components
import Schedules from './ChargingProfileSx/Schedules';

//global components
import RoleCanEdit from '../../OperatorShared/RoleCanEdit';

//styles
import {
  DivStyles,
  TitleStyles,
  ButtonGreyStyles,
} from '../../../SharedStyles';

const DailySchedules = (props) => {
  const { strings } = AppChargingProfilesService;
  const { editForm, profile } = props;
  const [sx, setSx] = useState([]);

  useMemo(() => {
    let schedulesViaProps = profile.schedules;
    if (editForm) {
      schedulesViaProps.map((sx) => {
        sx.times.map((time) => {
          time.id = Math.random().toString(36).substring(2);
          return time;
        });
        return sx;
      });
      setSx(schedulesViaProps);
    } else {
      const SxObject = {
        id: Math.random().toString(36).substring(2),
        dow: {
          sunday: false,
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
        },
        times: [
          {
            id: Math.random().toString(36).substring(2),
            timeStart: '',
            timeEnd: '',
            percentage: 100,
          },
        ],
      };
      setSx((s) => [...s, SxObject]);
    }
    // eslint-disable-next-line
  }, [editForm]);

  const handleAddSx = () => {
    const SxObject = {
      id: Math.random().toString(36).substring(2),
      dow: {
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
      },
      times: [
        {
          id: Math.random().toString(36).substring(2),
          timeStart: '',
          timeEnd: '',
          percentage: 0,
        },
      ],
    };
    setSx([...sx, SxObject]);
  };

  //pulls out the sx with the new id and rebuilds in same order
  const updatingSxArray = (newSch) => {
    let currentIndex;
    let removedSchedules = sx.filter((sch, index) => {
      if (sch.id === newSch.id) {
        currentIndex = index;
      }
      return newSch.id !== sch.id;
    });
    removedSchedules.splice(currentIndex, 0, newSch);
    setSx(removedSchedules);
  };

  useEffect(() => {
    props.handleChanges(sx);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sx]);

  const removeItemFromSx = (id) => {
    let removedSchedules = sx.filter((sch) => {
      return sch.id !== id;
    });
    setSx(removedSchedules);
  };

  return (
    <DivStyles padding='10px'>
      <DivStyles display='flex' alignItems='center' margin='10px 0 30px'>
        <TitleStyles size='14px' weight='600' margin='0'>
          {strings.dailySx}
        </TitleStyles>
        <RoleCanEdit roles={[1]}>
          <ButtonGreyStyles
            size='12px'
            margin='0 0 0 30px'
            padding='0 10px'
            disabled={sx.length === 7}
            onClick={handleAddSx}>
            {strings.addSchedule}
          </ButtonGreyStyles>
        </RoleCanEdit>
      </DivStyles>
      {sx.map((schedule, i) => {
        return (
          <Schedules
            key={schedule.id}
            editForm={props.editForm}
            updatingSxArray={updatingSxArray}
            removeItemFromSx={removeItemFromSx}
            allSxs={sx}
            schedule={schedule}
            count={i + 1}
          />
        );
      })}
    </DivStyles>
  );
};

export default DailySchedules;
