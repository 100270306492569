import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

let actionItems = {};
localStrings.actionFilters.forEach((action, i) => {
  actionItems = { ...actionItems, [action]: i };
});
export const actionFilterEnum = actionItems;

export const tableHeaderConvert = {
  Date: 'transactionDate',
  'Trans ID': 'transactionId',
  'Driver ID': 'userId',
  'Tot Time': 'totalTime',
  'Avg kW': 'avgkW',
  kWh: 'totalkWh',
  'Range Added': 'milesAdded',
  'Rate Type': 'rateType',
  Rate: 'rate',
  Amt: 'totalCost',
};

export const tableAdminHeaderConvert = {
  Date: 'transactionDate',
  'Trans ID': 'transactionId',
  'Driver ID': 'userId',
  'Tot Time': 'totalTime',
  'Avg kW': 'avgkW',
  kWh: 'totalkWh',
  'Range Added': 'milesAdded',
  'Rate Type': 'rateType',
  Rate: 'rate',
  Amt: 'totalCost',
  'Idle Rate Type': 'idleRateType',
  'Idle Rate': 'idleRate',
  'Idle Time': 'idleTime',
  'Idle Amt': 'idleCost',
};

export const tableDetailsHeaderConvert = {
  Date: 'transactionDate',
  'Trans ID': 'transactionId',
  'Driver ID': 'userId',
  'Charger': 'iotHubDeviceId',
  'Location': 'location',
  'Tot Time': 'totalTime',
  'Avg kW': 'avgkW',
  kWh: 'totalkWh',
  'Range Added': 'milesAdded',
  'Rate Type': 'rateType',
  Rate: 'rate',
  Amt: 'totalCost',
};

export const tableAdminDetailsHeaderConvert = {
  Date: 'transactionDate',
  'Trans ID': 'transactionId',
  'PoS Type': 'posType',
  'Charger': 'iotHubDeviceId',
  'Location': 'location',
  'Tot Time': 'totalTime',
  'Avg kW': 'avgkW',
  kWh: 'totalkWh',
  'Range Added': 'milesAdded',
  'Rate': 'rate',
  Amt: 'totalCost',
  'Idle Rate': 'idleRate',
  'Idle Time': 'idleTime',
  'Idle Amt': 'idleCost',
  'Driver Name': 'driverName',
  'Vehicle Make': 'vehicleMake',
  'Vehicle Model': 'vehicleModel',
  'Vehicle Year': 'vehicleYear',
};

export const tableHeaderStatementConvert = {
  'Statement Date': 'statementDate',
  'Activity Period': 'activityPeriod',
  Earnings: 'earnings',
  Fees: 'fees',
  Payout: 'payout',
  Detail: 'detail',
};

export const utilityHeaderConvert = {
  PIEID: 'PIEID',
  CSID: 'CSID',
  DRID: 'DRID',
  CEVID: 'CEVID',
  VCTime: 'VCTime',
  VDTime: 'VDTime',
  CSTime: 'CSTime',
  CETime: 'CETime',
  CEKW: 'CEKW',
  PEKWH: 'PEKWH',
  CUEKWH: 'CUEKWH',
  '15MSTA': '15MSTA',
  '15MSTO': '15MSTO',
  RAVGP: 'RAVGP',
  RPP: 'RPP',
  FEE: 'FEE',
};

export const NYSERDAHeaderConvert = {
  'EVSE Serial Number' : 'EVSESerialNumber',
  'Session Start Date' : 'SessionStartDate',
  'Session Start Time' : 'SessionStartTime',
  'Session End Date' : 'SessionEndDate',
  'Session End Time' : 'SessionEndTime',
  'Charging Start Date' : 'ChargingStart Date',
  'Charging Start Time' : 'ChargingStart Time',
  'Charging End Date' : 'ChargingEndDate',
  'Charging End Time' : 'ChargingEndTime',
  'Session Duration' : 'SessionDuration',
  'Charging Duration' : 'ChargingDuration',
  'Total Energy Provided' : 'TotalEnergyProvided',
  'Peak Power' : 'PeakPower',
};

export const NYSERDAChargerStatusHeaderConvert = {
  'EVSE Serial Number' : 'ChargerID',
  'EVSE Status' : 'SystemStatus',
  'Effective Status Date' : 'IncidentDate',
  'Effective Status Time' : 'IncidentTime',
};

export const statusHeaderConvert = {
  'Charger Id': 'iotHubDeviceId',
  'Serial No': 'serialNo',
  Vendor: 'vendor',
  Model: 'model',
  'Incident Date': 'incidentDate',
  'Error Type': 'errorType',
  Source: 'source',
  'Incident Code': 'incidentCode',
  'Incident Description': 'incidentDescription',
  Severity: 'severity',
  'System Status': 'systemStatus',
};
