import React, { useEffect, useState, useContext } from 'react';

//layout
import { InteriorWrapper, ComponentWrapper } from '../shared';
import Button from '../../shared/Buttons/Button';

//services
import AppOwnedChargersService from './AppOwnedChargersService';

//components
import OwnedChargerItem from './OwnedChargersList/OwnedChargerItem';

//modals
import AddCharger from './modals/AddCharger';

//styles
import { ListStyles } from '../../DriverStyles';
import { ListPlaceholderStyles } from '../../SharedStyles';

//images
import chargerPlaceholderImage from '../../../assets/images/ChargingStationPlaceholder400.png';

//context
import { ErrorContext } from '../../context/shared/ErrorContext';
import { DriverChargerContext } from '../../context/driver/getDriverLinkedChargers';

const OwnedChargers = (props) => {
  const { strings } = AppOwnedChargersService;
  const { setError } = useContext(ErrorContext);
  const { linkedChargers, fetchLinkedChargerData } = useContext(
    DriverChargerContext
  );
  const [chargers, setChargers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addChargerModal, setAddChargerModal] = useState(false);

  const handleModalToggle = () => {
    setAddChargerModal(!addChargerModal);
  };

  //carried from charging page when no chargers are there
  const query = new URLSearchParams(props.location.search);

  const fetchChargers = async (linked) => {
    //auto pops up modal
    let displayModal = query.get('addCharger');

    try {
      setLoading(true);
      let filterChargers = linked.filter((charger) => {
        return charger.home || charger.assigned;
      });
      setChargers(filterChargers);
      setLoading(false);
      if (displayModal) {
        handleModalToggle();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChargers(linkedChargers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkedChargers]);

  const addOwnedChargerHandler = async (token) => {
    setLoading(true);
    try {
      const tokenPayload = {
        tokenValue: token.token,
        tokenType: 'ownedChargerToken',
      };
      let tokenResponse = await AppOwnedChargersService.redeemToken(
        tokenPayload
      );
      let response = await fetchLinkedChargerData();
      if (tokenResponse) {
        if (response) {
          let privateOptions = {
            iotHubDeviceId: response.iotHubDeviceId,
            private: true,
          };
          await AppOwnedChargersService.setChargingStationPrivacy(
            privateOptions
          );
        }
      }
      setLoading(false);

      setAddChargerModal(false);
    } catch (error) {
      setLoading(false);
      //global error context
      setError({
        display: true,
        title: strings.errorTitle,
        message: strings.errorMessage,
        styles: 'driver',
      });
      setAddChargerModal(false);
    }
  };

  return (
    <InteriorWrapper title={strings.title} isInternal path='/charging'>
      <ComponentWrapper title={strings.title}>
        <ListStyles>
          {chargers.length >= 1 &&
            chargers.map((charger, index) => {
              return (
                <OwnedChargerItem
                  borderBottom={index !== chargers.length - 1}
                  fetchChargers={fetchChargers}
                  charger={charger}
                  key={charger.id}
                />
              );
            })}
        </ListStyles>
        {chargers.length === 0 && (
          <ListPlaceholderStyles>
            <p>{strings.noChargers}</p>
            <img src={chargerPlaceholderImage} alt='No Chargers' />
          </ListPlaceholderStyles>
        )}
        {addChargerModal && (
          <AddCharger
            loading={loading}
            handleModalToggle={handleModalToggle}
            submitHandler={addOwnedChargerHandler}
          />
        )}
      </ComponentWrapper>
      <Button
        driver
        hasMobile
        onclick={handleModalToggle}
        buttonText={strings.add}
      />
    </InteriorWrapper>
  );
};

export default OwnedChargers;
