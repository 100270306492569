import React, { useState, useContext, useEffect } from 'react';

//layout
import {
  OuterWrapper,
  ComponentWrapper,
  SubscriptionAccess,
} from '../../OperatorShared';
import Button from '../../../shared/Buttons/Button';

//sevices
import AppDiscountSchedulesService from '../AppDiscountSchedulesService';

//components
import FormHeaderComponent from './FormHeaderComponent';
import CouponComponent from './Coupon/CouponComponent';
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import { ErrorContext } from '../../../context/shared/ErrorContext';

const DiscountSchedulesEdit = (props) => {
  const { strings } = AppDiscountSchedulesService;

  const { account, setAccount, currentUser } = useContext(CsoAccountContext);
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(false);
  const [discountSchedule, setDiscountSchedule] = useState({});
  const [couponLength, setCouponLength] = useState(0);

  useEffect(() => {
    setLoading(true);
    if (account) {
      if (props.match.params.shared !== 'shared') {
        let singleSchedule = account.discountSchedules.filter((sx) => {
          return sx.id === props.match.params.shared;
        });
        setDiscountSchedule(singleSchedule[0]);
        setLoading(false);
      }
      if (props.match.params.shared === 'shared') {
        let singleSchedule = account.discountSchedules;
        if (singleSchedule.length > 0) {
          setDiscountSchedule(singleSchedule[0]);
          setLoading(false);
        } else {
          setLoading(false);
          setDiscountSchedule({});
        }        
      }
    }
  }, [account, props.match.params.id, props.match.params.shared]);

  //Read only for non-admins
  let adminPermission;
  if (currentUser) {
    adminPermission = currentUser.role === 1 || currentUser.role === 2;
  }

  const handleChange = (name, value) => {
    setDiscountSchedule({
      ...discountSchedule,
      [name]: value,
      chargingStations: [],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let createdProfile;
      if (account.subscriptionLevel === 1 && !discountSchedule.id) {
        createdProfile = await AppDiscountSchedulesService.createDiscountSchedule(
          discountSchedule
        );
      } else {
        createdProfile = await AppDiscountSchedulesService.updateDiscountSchedule(
          discountSchedule
        );      }
      setAccount(createdProfile);
      setLoading(false);
      if (createdProfile) {
        if (account.subscriptionLevel !== 1) {
          props.history.push('/subscription/discount-schedules');
        } else {
          props.history.push('/');
        }
      }
    } catch (err) {
      if (err.response) {
        setLoading(false);
        setError({
          display: true,
          title: strings.invalidTitle,
          message: err.response.data.split(/[|]+/).pop(),
          styles: 'cso',
        });
      }
    }
  };

  const couponCounter = (num) => {
    setCouponLength(couponLength + num);
  };

  return (
    <OuterWrapper
      title={
        discountSchedule && discountSchedule.id
          ? adminPermission
            ? strings.editTitle
            : `${strings.editTitle} ${strings.readOnly}`
          : strings.addTitle
      }
      internal
      path={
        [2, 3].includes(account.subscriptionLevel)
          ? '/subscription/discount-schedules'
          : '/chargers'
      }
      roles={[1, 2]}
      myForm='myForm'>
      {loading && <LoadingSpinner />}
      <SubscriptionAccess levels={[1, 2, 3]}>
        <ComponentWrapper
          title={
            discountSchedule && discountSchedule.id
            ? adminPermission
              ? strings.editTitle
              : `${strings.editTitle} ${strings.readOnly}`
              : strings.addTitle
          }>
          <>
            <FormHeaderComponent
              editSx
              account={account}
              profile={discountSchedule}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              couponCounter={couponCounter}
            />
            <SubscriptionAccess levels={[3]}>
              <CouponComponent
                profile={discountSchedule}
                strings={strings}
                couponLength={couponLength}
              />
            </SubscriptionAccess>
          </>
        </ComponentWrapper>
        <Button
          cso
          hasMobile
          formSubmit
          buttonText={strings.save}
          roles={[1, 2]}
        />
      </SubscriptionAccess>
    </OuterWrapper>
  );
};

export default DiscountSchedulesEdit;
