import React, { useState, useEffect } from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppMembershipsService from '../AppMembershipsService';

//global components
import ModalButton from '../../../shared/Buttons/ModalButton';
import { LoadingSpinner } from '../../../shared';

//hooks
import { useRouter } from '../../../shared/hooks/useRouter';
import { useCloudinary } from '../../../shared/Cloudinary/hook/useCloudinary';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  InputStyles,
  ImageThumbnailStyles,
  ButtonDivGreyStyles,
  DivStyles,
} from '../../../SharedStyles';

//images
import placeholder from '../../../../assets/images/subscriptionShareImage.png';

const EditModal = (props) => {
  const { strings } = AppMembershipsService;

  const [membership, setMembership] = useState({});

  const router = useRouter();
  const { cloudData, uploadImage, imageLoading } = useCloudinary({
    preset: 'membership_images',
    tags: [membership.membershipName],
  });

  useEffect(() => {
    setMembership(props.membership);
  }, [props.membership]);

  const closeModal = () => {
    props.toggleModal();
  };

  //clicks the hidden input for image upload
  const openDialog = () => {
    document.getElementById('inputUpload').click();
  };

  const handleSaveInfo = async (e) => {
    e.preventDefault();
    if (props.deleteModal) {
      await AppMembershipsService.deleteMembership(membership.id);
      props.removeMembership(membership.id);
      router.history.push('/subscription/memberships');
      if (router.match.params.id) {
        closeModal();
      }
    } else {
      props.handleChangeModal(membership);
      closeModal();
    }
  };

  useEffect(() => {
    if (cloudData) {
      setMembership((prev) => ({
        ...prev,
        imagePath: cloudData.secure_url,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloudData]);

  const handleChange = (e) => {
    setMembership({ ...membership, [e.target.name]: e.target.value });
  };

  return (
    <ModalStyles>
      {imageLoading && <LoadingSpinner />}
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{props.title}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          {!props.deleteModal && (
            <ModalItemStyles padding='0 10px'>
              <p>{strings.membershipModalInput}</p>
              <InputStyles
                type='text'
                onChange={handleChange}
                name='membershipName'
                placeholder={strings.membershipPlaceholderName}
                value={membership.membershipName || ''}
              />
              <InputStyles
                type='text'
                onChange={handleChange}
                name='membershipCode'
                placeholder={strings.tokenCode}
                value={membership.membershipCode || ''}
              />
            </ModalItemStyles>
          )}

          {/* Section */}
          <ModalItemStyles>
            <p>{props.content}</p>
            {!props.deleteModal && (
              <>
                <DivStyles display='block'>
                  <ImageThumbnailStyles
                    src={
                      cloudData
                        ? cloudData.secure_url
                        : membership.imagePath
                        ? membership.imagePath
                        : placeholder
                    }
                    alt={
                      membership.membershipName
                        ? membership.membershipName
                        : 'Placeholder Building'
                    }
                  />
                  <InputStyles
                    className='file-input'
                    id='inputUpload'
                    type='file'
                    hidden
                    accept='image/*'
                    onChange={uploadImage}
                  />
                </DivStyles>
                <ButtonDivGreyStyles id='fileUpload' onClick={openDialog}>
                  {strings.membershipModalAddLogo}
                </ButtonDivGreyStyles>
              </>
            )}
          </ModalItemStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={handleSaveInfo}
          buttonText={props.deleteModal ? strings.delete : strings.ok}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default EditModal;
