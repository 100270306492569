import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  FaUserTag,
  FaRegTrashAlt,
  FaUsers,
  FaChargingStation,
  FaRegEdit,
  FaEllipsisV,
} from 'react-icons/fa';

//services
import AppMembershipsService from '../AppMembershipsService';

//modals
import EditModal from '../MembershipsModals/EditModal';
import RedirectChargers from '../MembershipsModals/RedirectChargers';

//global components
import SubscriptionAccess from '../../OperatorShared/SubscriptionAccess';

//hooks
import useOnClickOutside from '../../../shared/hooks/useOnClickOutside';
import { useWindowResize } from '../../../shared/hooks/useWindowSize';
import { useContainerHeight } from '../../../shared/hooks/useContainerHeight';

//styles
import {
  DivStyles,
  ImageThumbnailStyles,
  TitleStyles,
  TextStyles,
  ImageContainerFitStyles,
} from '../../../SharedStyles';
import { ToolsStyles } from '../../../OperatorStyles';

//images
import placeholder from '../../../../assets/images/subscriptionShareImage.png';

const MembershipCard = (props) => {
  const { strings } = AppMembershipsService;
  const { membership } = props;
  const CardRef = useRef();
  const OutsideRef = useRef();
  const size = useWindowResize(window.innerWidth, window.innerHeight);
  const { containerHeight, containerRef, setContainerRef } = useContainerHeight(
    50,
    CardRef
  );
  const [deleteModal, setDeleteModal] = useState(false);
  const [chargersModal, setChargersModal] = useState(false);
  const [openTools, setOpenTools] = useState(false);

  useOnClickOutside(OutsideRef, () => setOpenTools(false));

  //gets card container height for responsive
  useEffect(() => {
    setContainerRef(CardRef);
    // eslint-disable-next-line
  }, [CardRef]);

  const toggleToolBar = () => {
    setOpenTools(!openTools);
  };

  const toggleModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleChargersModal = () => {
    setChargersModal(!chargersModal);
  };

  return (
    <>
      {deleteModal && (
        <EditModal
          removeMembership={props.removeMembership}
          deleteModal
          fromCard
          membership={props.membership}
          toggleModal={toggleModal}
          title={strings.membershipDelete}
          content={strings.membershipDeleteMessage}
          button={strings.membershipDeleteButton}
        />
      )}
      {chargersModal && (
        <RedirectChargers
          membership={membership}
          handleCloseModal={handleChargersModal}
        />
      )}
      <DivStyles
        display='flex'
        flexWrap='nowrap'
        alignItems='center'
        linkWidth='100%'
        linkColor='#617793'
        padding='10px'
        hoverBackground='#f1f7ff'
        background={props.index % 2 === 0}
        ref={containerRef}
        onMouseEnter={() => {
          size[0] >= 768 && setOpenTools(true);
        }}
        onMouseLeave={() => {
          size[0] >= 768 && setOpenTools(false);
        }}>
        <Link to={`/subscription/memberships/${membership.id}`}>
          {/* Card Container */}
          <DivStyles
            display='flex'
            flexWrap='nowrap'
            alignItems='center'
            justifyContent='space-between'>
            {/* Left Card */}
            <DivStyles display='flex' flexWrap='nowrap' alignItems='center'>
              <ImageContainerFitStyles
                width='50px'
                height='50px'
                removeMobileImg='400px'
                margin='0 10px 0 0'>
                <ImageThumbnailStyles
                  width='100%'
                  height='100%'
                  objectFit='cover'
                  src={
                    membership.imagePath ? membership.imagePath : placeholder
                  }
                  alt={membership.membershipName}
                />
              </ImageContainerFitStyles>
              <DivStyles align='left'>
                <TitleStyles size='14px' weight='700' margin='0 0 5px'>
                  {membership.membershipName}
                </TitleStyles>
                <TextStyles size='12px' weight='500' margin='0'>
                  {strings.code}: {membership.membershipCode}
                </TextStyles>
              </DivStyles>
            </DivStyles>
            {/* Right Card */}
            <DivStyles
              display='flex'
              flexWrap='nowrap'
              alignItems='center'
              align='right'>
              {/* Members Count  */}
              <DivStyles size='12px' padding='0 5px' margin='0 15px 0 0'>
                {strings.members}
                {membership.memberIds.length}
              </DivStyles>
            </DivStyles>
          </DivStyles>
        </Link>
        <FaEllipsisV
          className='Card-Tools-Toggle'
          style={size[0] >= 768 ? { display: 'none' } : { display: 'block' }}
          onClick={toggleToolBar}
        />
        <ToolsStyles
          ref={OutsideRef}
          displayTools={openTools}
          containerHeight={(containerHeight - 35) / 2}>
          <Link
            to={`/subscription/memberships/${membership.id}`}
            title={strings.editTooltip}
            className='Tool-Item'>
            <FaRegEdit />
          </Link>
          <div className='Tool-Item'>
            <FaChargingStation
              title={strings.chargersTooltip}
              onClick={handleChargersModal}
            />
          </div>
          <Link
            className='Tool-Item'
            to={`/subscription/memberships/${membership.id}/members`}>
            <FaUsers title={strings.membershipMembersTooltip} />
          </Link>
          <SubscriptionAccess levels={[2, 3]}>
            <Link
              to={`/subscription/memberships/${membership.id}/token`}
              className='Tool-Item'>
              <FaUserTag title={strings.generateCodeToopTip} />
            </Link>
          </SubscriptionAccess>
          <div className='Tool-Item' onClick={toggleModal}>
            <FaRegTrashAlt title={strings.deleteTooltip} />
          </div>
        </ToolsStyles>
      </DivStyles>
    </>
  );
};

export default MembershipCard;
