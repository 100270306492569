// CSO Components
import AccountProfile from '../../components/operator/AccountProfile/AccountProfile';
import Bank from '../../components/operator/Bank/Bank';
import BankFailed from '../../components/operator/Bank/BankFailed';
import Chargers from '../../components/operator/Chargers/Chargers';
import ChargersControl from '../../components/operator/Chargers/ChargersControl/ChargersControl';
import ChargersAdd from '../../components/operator/Chargers/ChargersForm/ChargersAdd';
import ChargersEdit from '../../components/operator/Chargers/ChargersForm/ChargersEdit';
import ChargersConnection from '../../components/operator/Chargers/ChargersForm/ChargersSetup/ChargersConnection';
import ChargersHistory from '../../components/operator/Chargers/ChargersHistory/ChargersHistory';
import ChargerShare from '../../components/operator/Chargers/ChargersShare/ChargersShare';
import ChargingProfileAdd from '../../components/operator/ChargingProfiles/ChargingProfileForms/ChargingProfileAdd';
import ChargingProfileEdit from '../../components/operator/ChargingProfiles/ChargingProfileForms/ChargingProfileEdit';
import ChargingProfiles from '../../components/operator/ChargingProfiles/ChargingProfiles';
import DiscountSchedules from '../../components/operator/DiscountSchedules/DiscountSchedules';
import DiscountSchedulesEdit from '../../components/operator/DiscountSchedules/DiscountSchedulesForms/DiscountScheduleEdit';
import DiscountSchedulesAdd from '../../components/operator/DiscountSchedules/DiscountSchedulesForms/DiscountSchedulesAdd';
import EarnedIncome from '../../components/operator/EarnedIncome/EarnedIncome';
import Landing from '../../components/operator/Landing/Landing';
import Locations from '../../components/operator/Locations/Locations';
import LocationsAdd from '../../components/operator/Locations/LocationsForms/LocationsAdd';
import LocationsEdit from '../../components/operator/Locations/LocationsForms/LocationsEdit';
import Memberships from '../../components/operator/Memberships/Memberships';
import MembershipAdd from '../../components/operator/Memberships/MembershipsForms/MembershipAdd';
import MembershipEdit from '../../components/operator/Memberships/MembershipsForms/MembershipEdit';
import Members from '../../components/operator/Memberships/MembershipsList/Members/Members';
import MembershipToken from '../../components/operator/Memberships/MembershipsList/MembershipToken';
import MeterGroups from '../../components/operator/MeterGroups/MeterGroups';
import MeterGroupsAdd from '../../components/operator/MeterGroups/MeterGroupsForm/MeterGroupsAdd';
import MeterGroupsEdit from '../../components/operator/MeterGroups/MeterGroupsForm/MeterGroupsEdit';
import Notifications from '../../components/operator/Notifications/Notifications';
import PricingSchedules from '../../components/operator/PricingSchedules/PricingSchedules';
import PricingSchedulesAdd from '../../components/operator/PricingSchedules/PricingSchedulesForms/PricingSchedulesAdd';
import PricingSchedulesEdit from '../../components/operator/PricingSchedules/PricingSchedulesForms/PricingSchedulesEdit';
import PayoutPage from '../../components/operator/Reports/Details/Shared/IncomeReports/Payout/List/PayoutPage';
import PayoutView from '../../components/operator/Reports/Details/Shared/IncomeReports/Payout/Single/PayoutView';
import ReportChargingSession from '../../components/operator/Reports/Details/Shared/ChargingSessions/Details/Details';
import ReportUtilityChargingSession from '../../components/operator/Reports/Details/Shared/ChargingSessions/UtilityDetails/UtilityDetails';
import ReportChargerStatus from '../../components/operator/Reports/Details/Shared/ChargingSessions/StatusDetails/StatusDetails';
import ReportNYSERDA from '../../components/operator/Reports/Details/Shared/CustomIncentiveReports/NYSERDAReport/NYSERDAReport';
import ReportNYSERDAChargerStatus from '../../components/operator/Reports/Details/Shared/CustomIncentiveReports/NYSERDAChargerStatusReport/NYSERDAChargerStatusReport';
import Settings from '../../components/operator/Settings/Settings';
import StatementDetails from '../../components/operator/Statements/StatementDetails/StatementDetails';
import Statements from '../../components/operator/Statements/Statements';
import Subscription from '../../components/operator/Subscription/Subscription';
import SystemUserAdd from '../../components/operator/SystemUsers/SystemUserAccount/SystemUserAdd';
import SystemUserEdit from '../../components/operator/SystemUsers/SystemUserAccount/SystemUserEdit';
import SystemUsers from '../../components/operator/SystemUsers/SystemUsers';
import CsoNotFound from '../../components/shared/AppNotFound/CsoNotFound';
import Reports from '../../components/operator/Reports/Reports';
import PrivacyPolicy from '../../components/shared/PrivacyPolicy/PrivacyPolicy';
import Energy from '../../components/operator/Reports/Details/Shared/EnergyReport/Energy';
import Revenue from '../../components/operator/Reports/Details/Shared/RevenueReport/Revenue';

/**
 * Levels = 1 All Subscription levels, 2 Small biz and Up, 3 Enterprise only
 */
export const csoRoutingMap = [
  {
    route: '/',
    component: Landing,
    level: 1,
    devOnly: false,
  },
  {
    route: '/account-profile',
    component: AccountProfile,
    level: 2,
    devOnly: false,
  },
  {
    route: '/system-users',
    component: SystemUsers,
    level: 3,
    devOnly: false,
  },
  {
    route: '/system-users/add',
    component: SystemUserAdd,
    level: 3,
    devOnly: false,
  },  
  {
    route: '/system-users/:id',
    component: SystemUserEdit,
    level: 1,
    devOnly: false,
  },
  {
    route: '/system-users/pending/:id',
    component: SystemUserEdit,
    level: 3,
    devOnly: false,
  },
  {
    route: '/system-users/:id/notifications',
    component: Notifications,
    level: 1,
    devOnly: false,
  },
  {
    route: '/locations',
    component: Locations,
    level: 3,
    devOnly: false,
  },
  {
    route: '/locations/add',
    component: LocationsAdd,
    level: 3,
    devOnly: false,
  },
  {
    route: '/locations/:id',
    component: LocationsEdit,
    level: 3,
    devOnly: false,
  },
  {
    route: '/meter-groups',
    component: MeterGroups,
    level: 3,
    devOnly: false,
  },
  {
    route: '/meter-groups/add',
    component: MeterGroupsAdd,
    level: 3,
    devOnly: false,
  },
  {
    route: '/meter-groups/:id',
    component: MeterGroupsEdit,
    level: 3,
    devOnly: false,
  },
  {
    route: '/subscription',
    component: Subscription,
    level: 1,
    devOnly: false,
  },
  {
    route: '/subscription/memberships',
    component: Memberships,
    level: 1,
    devOnly: false,
  },
  {
    route: '/subscription/memberships/add',
    component: MembershipAdd,
    level: 1,
    devOnly: false,
  },
  {
    route: '/subscription/memberships/:id',
    component: MembershipEdit,
    level: 1,
    devOnly: false,
  },
  {
    route: '/subscription/memberships/:id/members',
    component: Members,
    level: 1,
    devOnly: false,
  },
  {
    route: '/subscription/memberships/:id/token',
    component: MembershipToken,
    level: 2,
    devOnly: false,
  },
  {
    route: '/subscription/charging-profiles',
    component: ChargingProfiles,
    level: 3,
    devOnly: false,
  },
  {
    route: '/subscription/charging-profiles/add',
    component: ChargingProfileAdd,
    level: 3,
    devOnly: false,
  },
  {
    route: '/subscription/charging-profiles/:id',
    component: ChargingProfileEdit,
    level: 3,
    devOnly: false,
  },
  {
    route: '/subscription/pricing-schedules',
    component: PricingSchedules,
    level: 2,
    devOnly: false,
  },
  {
    route: '/subscription/pricing-schedules/add',
    component: PricingSchedulesAdd,
    level: 2,
    devOnly: false,
  },
  {
    route: '/subscription/pricing-schedules/:shared',
    component: PricingSchedulesEdit,
    level: 1,
    devOnly: false,
  },
  {
    route: '/subscription/pricing-schedules/:id',
    component: PricingSchedulesEdit,
    level: 2,
    devOnly: false,
  },
  {
    route: '/subscription/discount-schedules/',
    component: DiscountSchedules,
    level: 2,
    devOnly: false,
  },
  {
    route: '/subscription/discount-schedules/add',
    component: DiscountSchedulesAdd,
    level: 2,
    devOnly: false,
  },
  {
    route: '/subscription/discount-schedules/:shared',
    component: DiscountSchedulesEdit,
    level: 1,
    devOnly: false,
  },
  {
    route: '/subscription/discount-schedules/:id',
    component: DiscountSchedulesEdit,
    level: 2,
    devOnly: false,
  },
  {
    route: '/chargers',
    component: Chargers,
    level: 1,
    devOnly: false,
  },
  {
    route: '/chargers/add',
    component: ChargersAdd,
    level: 1,
    devOnly: false,
  },
  {
    route: '/chargers/:id',
    component: ChargersEdit,
    level: 1,
    devOnly: false,
  },
  {
    route: '/chargers/:id/sessions',
    component: ChargersHistory,
    level: 1,
    devOnly: false,
  },
  {
    route: '/chargers/:id/controls',
    component: ChargersControl,
    level: 1,
    devOnly: false,
  },
  {
    route: '/chargers/:id/share',
    component: ChargerShare,
    level: 1,
    devOnly: false,
  },
  {
    route: '/chargers/:id/connect',
    component: ChargersConnection,
    level: 1,
    devOnly: false,
  },
  {
    route: '/statements',
    component: Statements,
    level: 1,
    devOnly: false,
  },
  {
    route: '/statements/:id',
    component: StatementDetails,
    level: 1,
    devOnly: false,
  },
  {
    route: '/bank',
    component: Bank,
    level: 1,
    devOnly: false,
  },
  {
    route: '/bank/failed',
    component: BankFailed,
    level: 1,
    devOnly: false,
  },
  {
    route: '/bank/earned-income',
    component: EarnedIncome,
    level: 1,
    devOnly: false,
  },
  {
    route: '/reports',
    component: Reports,
    level: 2,
    devOnly: false,
  },
  {
    route: '/reports/payouts',
    component: PayoutPage,
    level: 2,
    devOnly: false,
  },
  {
    route: '/reports/payouts/:id',
    component: PayoutView,
    level: 2,
    devOnly: false,
  },
  {
    route: '/reports/charging-session-detail',
    component: ReportChargingSession,
    level: 2,
    devOnly: false,
  },
  {
    route: '/reports/utility-charging-session-detail-report',
    component: ReportUtilityChargingSession,
    level: 3,
    devOnly: false,
  },
  {
    route: '/reports/charger-status-detail',
    component: ReportChargerStatus,
    level: 3,
    devOnly: false,
  },
  {
    route: '/reports/NYSERDA',
    component: ReportNYSERDA,
    level: 3,
    devOnly: false,
  },  
  {
    route: '/reports/NYSERDAChargerStatus',
    component: ReportNYSERDAChargerStatus,
    level: 3,
    devOnly: false,
  },  
  {
    route: '/reports/charger-status-detail',
    component: ReportChargingSession,
    level: 2,
    devOnly: false,
  },
  {
    route: '/reports/energy-summary',
    component: Energy,
    level: 3,
    devOnly: false,
  },  
  {
    route: '/reports/revenue-summary',
    component: Revenue,
    level: 3,
    devOnly: false,
  },
  {
    route: '/notifications',
    component: Notifications,
    level: 1,
    devOnly: false,
  },
  {
    route: '/settings',
    component: Settings,
    level: 1,
    devOnly: false,
  },
  {
    route: '/privacy',
    component: PrivacyPolicy,
    level: 1,
    devOnly: false,
  },
  {
    route: '*',
    component: CsoNotFound,
    level: 1,
    devOnly: false,
  },
];
