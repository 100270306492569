import { useState, useEffect } from 'react';

//helpers
import { useLeaveGuard } from '../hooks/useLeaveGuard';

export const useObjectBuilder = (initalObject, initalPrevObject) => {
  const [objectOne, setObjectOne] = useState(initalObject);
  const [objectTwo, setObjectTwo] = useState(initalPrevObject);
  const [
    isBlocking,
    setIsBlocking,
    setUpdatedObject,
    setPrevObject,
  ] = useLeaveGuard(objectOne, objectTwo);

  console.log('isBlocking', isBlocking);

  useEffect(() => {
    setUpdatedObject(objectOne);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectOne]);

  useEffect(() => {
    setPrevObject(objectTwo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectTwo]);

  return [
    isBlocking,
    (boo) => {
      setIsBlocking(boo);
    },
    (updatedOne) => {
      setObjectOne(updatedOne);
    },
    (updatedTwo) => {
      setObjectTwo(updatedTwo);
    },
  ];
};
