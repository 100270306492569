import React, { useState, useEffect } from 'react';
import { FaRegWindowClose, FaExclamationTriangle } from 'react-icons/fa';

//services
import AppMeterGroupsService from '../../AppMeterGroupsService';

//global component
import ModalButton from '../../../../shared/Buttons/ModalButton';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  InputStyles,
  ModalNoteStyles,
} from '../../../../SharedStyles';

const MaxAmpsModal = (props) => {
  const { handleCloseModal, handleDataChange } = props;
  const { strings } = AppMeterGroupsService;
  const [maxAmps, setMaxAmps] = useState('');

  useEffect(() => {
    setMaxAmps(props.maxAmps);
  }, [props.maxAmps]);

  const closeModal = () => {
    handleCloseModal();
  };

  const handleChange = (e) => {
    setMaxAmps(e.target.value);
  };

  const handleSaveInfo = (e) => {
    e.preventDefault();
    handleDataChange('maxAmps', maxAmps);
    handleCloseModal();
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.ampsTitle}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0'>
            <p>{strings.ampsContent}</p>
          </ModalItemStyles>
          {/* Section */}
          <form id='modalForm' onSubmit={handleSaveInfo}>
            <ModalItemStyles>
              <InputStyles
                type='number'
                onChange={handleChange}
                name='transformerPower'
                placeholder={strings.ampsPlaceholder}
                value={maxAmps || ''}
                required
              />
            </ModalItemStyles>
          </form>
          {/* Note Section */}
          <ModalNoteStyles>
            <p className='Warning-modal'>
              <FaExclamationTriangle />
              <span className='Warning-bold'>{strings.warning}</span> -{' '}
              {strings.ampsWarning}
            </p>
          </ModalNoteStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          formSubmit='modalForm'
          buttonText={strings.ok}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default MaxAmpsModal;
