import React, { useState, useContext } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';

//services
import AppOwnedChargersService from '../AppOwnedChargersService';
import AppChargerDetailsService from '../../ChargerDetails/AppChargerDetailsService';

//modals
import DeleteModal from '../modals/DeleteModal';

//global components
import ConnectorImages from '../../../shared/ConnectorImages/ConnectorImages';
import PropertyContainer from '../../../shared/PropertyContainer/PropertyContainer';

//context
import { ErrorContext } from '../../../context/shared/ErrorContext';
import { DriverChargerContext } from '../../../context/driver/getDriverLinkedChargers';

//styles
import {
  PropertiesContentContainerStyles,
  PropertiesCardStyles,
  PropertiesHeaderContainerStyles,
  PropertiesAddressContainerStyles,
} from '../../../SharedStyles';

//helpers
import { phaseChargerTypeConvertor } from '../../../operator/helpers';

const OwnedChargerItem = (props) => {
  const { strings } = AppOwnedChargersService;
  const { charger } = props;
  const { linkedChargers, setLinkedChargers } = useContext(
    DriverChargerContext
  );
  const { setError } = useContext(ErrorContext);
  const [deleteChargerModal, setDeleteChargerModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteChargerModalHandler = () => {
    setDeleteChargerModal(!deleteChargerModal);
  };

  const removeOwnedChargerHandler = async () => {
    try {
      setLoading(true);
      const updatedCharger = { ...charger, home: false };

      let res = await AppChargerDetailsService.linkDriverCharger(
        updatedCharger
      );
      if (res) {
        let updatedLinks = linkedChargers.map((charger) => {
          if (charger.id === res.id) {
            charger = res;
          }
          return charger;
        });
        setLinkedChargers(updatedLinks);
        props.fetchChargers(updatedLinks);
        setDeleteChargerModal(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      //global error context
      setError({
        display: true,
        title: strings.errorTitle,
        message: strings.errorMessage,
        styles: 'driver',
      });
    }
  };

  return (
    <PropertiesCardStyles
      borderBottom={props.borderBottom && '1px solid #e7e7e7'}>
      {deleteChargerModal && (
        <DeleteModal
          handleModalToggle={deleteChargerModalHandler}
          removeOwnedCharger={removeOwnedChargerHandler}
          charger={charger}
          loading={loading}
        />
      )}
      <PropertiesHeaderContainerStyles>
        <div className='Title'>
          {charger.chargerName ? charger.chargerName : charger.iotHubDeviceId}{' '}
          {charger.assigned && `- ${strings.assigned}`}
          <span className='SubTitle'> - ({charger.iotHubDeviceId})</span>
        </div>
        {!charger.assigned && (
          <div className='Action-Items'>
            <FaRegTrashAlt onClick={deleteChargerModalHandler} />
          </div>
        )}
      </PropertiesHeaderContainerStyles>
      <PropertiesAddressContainerStyles>
        {charger.chargerAddress}
      </PropertiesAddressContainerStyles>
      <PropertiesContentContainerStyles>
        <PropertyContainer
          title={strings.power}
          content={`${charger.power} kW`}
        />
        <PropertyContainer
          title={strings.maxAmps}
          content={charger.maxCurrent}
        />
        <PropertyContainer
          title={strings.conn}
          content={<ConnectorImages showName charger={charger} />}
        />
        <PropertyContainer
          title={strings.serialNo}
          content={charger.chargePointSerialNumber}
        />
        <PropertyContainer title={strings.make} content={charger.make} />
        <PropertyContainer title={strings.model} content={charger.model} />
        <PropertyContainer
          title={strings.phaseType}
          content={
            Object.keys(charger).length > 0 &&
            charger.phaseType.map((type, index) => {
              return (
                <span key={index}>
                  {phaseChargerTypeConvertor[type]}
                  {index !== charger.phaseType.length - 1 ? ', ' : ''}
                </span>
              );
            })
          }
        />
        <PropertyContainer
          title={strings.firmware}
          content={charger.firmwareVersion}
        />
      </PropertiesContentContainerStyles>
      {!charger.assigned && (
        <></>
        // <ChargerCardPaneStyles width='100%'>
        //   <ToggleSwitch
        //     label={'Auto session start'}
        //     justifyContent='center'
        //     // checked={!!toggleManualForm}
        //     handleChange={() => alert('toggled')}
        //   />
        //   <ToggleSwitch
        //     label={'Make private'}
        //     justifyContent='center'
        //     checked={!!makePrivate.private}
        //     handleChange={togglePrivate}
        //   />
        // </ChargerCardPaneStyles>
      )}
    </PropertiesCardStyles>
  );
};

export default OwnedChargerItem;
