import React, { useState, useContext, useEffect, useRef } from 'react';

//layout
import { OuterWrapper, ComponentWrapper } from '../../OperatorShared';
import Button from '../../../shared/Buttons/Button';

//services
import AppLocationsService from '../AppLocationsService';

//components
import LocationAddress from '../LocationsForms/LocationDetails/LocationAddress';
import LocationDetails from '../LocationsForms/LocationDetails/LocationDetails';

//global components
import ToggleSwitch from '../../OperatorShared/ToggleSwitch/CsoToggleSwitch';
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';
import CsoNotFound from '../../../shared/AppNotFound/CsoNotFound';
import EnterpriseAdmin from '../../EnterpriseAdmin';
import RouteLeavingGuard from '../../../shared/LeaveGuard/LeaveGuard';

//modals
import TransformerModal from '../LocationsModals/TransformerModal';
import LoadLimitModal from '../LocationsModals/LoadLimitModal';
import LocationEditModal from '../LocationsModals/LocationEditModal';
import DeleteModal from '../LocationsModals/DeleteModal';

//hooks
import { useRequiredFields } from '../../../shared/hooks/useRequiredFields';
import { useObjectBuilder } from '../../../shared/LeaveGuard/useObjectBuilder';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import { ErrorContext } from '../../../context/shared/ErrorContext';

//styles
import { EnableContainerStyles } from '../../../OperatorStyles';
import { TitleStyles } from '../../../SharedStyles';

//error Class
import { ErrorResponse } from '../../../shared/ErrorResponse';
import LocationHeader from './LocationDetails/LocationHeader';
import LocationBranding from './LocationBranding/LocationBranding';

const LocationsEdit = props => {
  const { strings } = AppLocationsService;
  const { account, setAccount, currentUser } = useContext(CsoAccountContext);
  const { setError } = useContext(ErrorContext);

  const requiredNameRef = useRef();
  const requiredAddressRef = useRef();
  const requiredCityRef = useRef();
  const requiredStateRef = useRef();
  const requiredZipRef = useRef();
  const requiredCountryRef = useRef();

  const [location, setLocation] = useState({});
  const [prevLocation, setPrevLocation] = useState({});
  const [loading, setLoading] = useState(false);
  const [finishedUpdating, setFinishedUpdating] = useState(false);
  const [transformerModal, setTransformerModal] = useState(false);
  const [loadLimitModal, setLoadLimitModal] = useState(false);
  const [editLocationModal, setEditLocationModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [notFound, setNotFound] = useState({
    display: false,
    message: ''
  });

  //leaveGuard Effect
  const buttonRef = useRef(null);
  const [setRequiredOptions, missingRequiredFields] = useRequiredFields([
    requiredAddressRef,
    requiredCityRef,
    requiredStateRef,
    requiredZipRef,
    requiredCountryRef
  ]);
  const [
    isBlocking,
    setIsBlocking,
    setCustomLoc,
    setCustomPrevLoc
  ] = useObjectBuilder(location, prevLocation);

  useEffect(() => {
    if (Object.keys(location).length > 0) {
      const customObject = {
        enabled: location.enabled,
        name: location.name,
        address1: location.address1,
        address2: location.address2,
        city: location.city,
        state: location.state,
        zip: location.zip,
        country: location.country,
        transformerPower: location.transformerPower,
        loadLimit: location.loadLimit,
        enableLocationBranding: location.enableLocationBranding,
        locationInformation: location.locationInformation,
        locationPhone: location.locationPhone,
        locationURL: location.locationURL
      };
      setCustomLoc(customObject);
    }
    setRequiredOptions([
      requiredAddressRef,
      requiredCityRef,
      requiredStateRef,
      requiredZipRef,
      requiredCountryRef
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const toggleBlocking = () => {
    setIsBlocking(!isBlocking);
  };
  //end LeaveGuard Effect

  useEffect(() => {
    setLoading(true);
    const fetchLocation = async () => {
      try {
        let loc = await AppLocationsService.getSingleLocation(
          props.match.params.id
        );
        setLocation(loc);
        setPrevLocation(loc);
        const customObject = {
          enabled: loc.enabled,
          name: loc.name,
          address1: loc.address1,
          address2: loc.address2,
          city: loc.city,
          state: loc.state,
          zip: loc.zip,
          country: loc.country,
          transformerPower: loc.transformerPower,
          loadLimit: loc.loadLimit,
          enableLocationBranding: loc.enableLocationBranding,
          locationInformation: loc.locationInformation,
          locationPhone: loc.locationPhone,
          locationURL: loc.locationURL
        };
        setCustomLoc(customObject);
        setCustomPrevLoc(customObject);
        setLoading(false);
      } catch (err) {
        if (err.response) {
          setNotFound({
            display: true,
            message: err.response.data
          });
        }
      }
    };
    fetchLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, props.match.params.id]);

  //displays Transformer modal
  const toggleTransformerModal = () => {
    setTransformerModal(!transformerModal);
  };
  //displays delete modal
  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };
  //displays limitmodal
  const toggleLoadLimitModal = () => {
    setLoadLimitModal(!loadLimitModal);
  };
  //displays the edit location modal
  const toggleLocationModal = () => {
    setEditLocationModal(!editLocationModal);
  };
  //enable and disables location
  const handleEnable = e => {
    setLocation({
      ...location,
      enabled: !location.enabled
    });
  };
  //handles the update of information from the modals
  const handleDataChange = (key, value) => {
    setLocation({ ...location, [key]: value });
  };

  const handleDeleteLocation = async id => {
    try {
      let data = await AppLocationsService.deleteLocation(id);
      setAccount(data);
      setFinishedUpdating(true);
    } catch (err) {
      if (err.response.data === 'Location Last One') {
        setError({
          display: true,
          title: strings.failedDeleteTitle,
          message: strings.failedDeleteOnly,
          styles: 'cso'
        });
      }
      if (err.response.data === 'Location Charger Assigned') {
        setError({
          display: true,
          title: strings.failedDeleteTitle,
          message: strings.failedDeleteCharger,
          styles: 'cso'
        });
      }
      if (err.response.data === 'Location Not Found') {
        setError({
          display: true,
          title: strings.failedDeleteTitle,
          message: strings.failedDeleteLocation,
          styles: 'cso'
        });
      }
    }
  };

  const handleSubmitCheck = () => {
    buttonRef.current.click();
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      if (missingRequiredFields) {
        throw new ErrorResponse(
          'frontend',
          'Location Address is required.',
          'Missing Location'
        );
      }
      if (!location.name) {
        throw new ErrorResponse(
          'frontend',
          'Must have valid location data',
          'Missing Data'
        );
      }
      let updatedAccount = await AppLocationsService.updateLocation(location);
      setAccount(updatedAccount);
      setIsBlocking(false);
      if (updatedAccount) {
        setTimeout(() => {
          props.history.push('/locations');
        }, 400);
      }
    } catch (err) {
      if (err.name === 'frontend') {
        setError({
          display: true,
          title: err.title,
          message: err.message,
          styles: 'cso'
        });
      } else {
        setError({
          display: true,
          title: 'Unsuccessful',
          message: err.response.data.split(/[|]+/).pop(),
          styles: 'cso'
        });
      }
      setLoading(false);
      setIsBlocking(true);
    }
  };
  if (notFound.display && !finishedUpdating) {
    return <CsoNotFound message={notFound.message} />;
  }
  return (
    <OuterWrapper
      title={strings.editLocationTitle}
      internal
      myForm
      path='/locations'
      roles={[1, 3]}>
      {/* =====================Enterprise Only===================================== */}
      <EnterpriseAdmin allowTech>
        <RouteLeavingGuard
          cso
          when={isBlocking}
          toggleBlocking={toggleBlocking}
          action={handleSubmitCheck}
          loading={loading}
          requiredFields={missingRequiredFields}
          navigate={path => props.history.push(path)}
          shouldBlockNavigation={location => {
            if (isBlocking) {
              return true;
            }
            return false;
          }}
        />
        <ComponentWrapper title={strings.editLocationTitle} roles={[1, 3]}>
          {loading && <LoadingSpinner />}
          {transformerModal && (
            <TransformerModal
              handleCloseModal={toggleTransformerModal}
              handleDataChange={handleDataChange}
              kva={location.transformerPower}
            />
          )}
          {loadLimitModal && (
            <LoadLimitModal
              handleCloseModal={toggleLoadLimitModal}
              handleDataChange={handleDataChange}
              loadLimit={location.loadLimit}
            />
          )}
          {editLocationModal && (
            <LocationEditModal
              ref={requiredNameRef}
              handleCloseModal={toggleLocationModal}
              handleDataChange={handleDataChange}
              loc={location}
            />
          )}
          {deleteModal && (
            <DeleteModal
              handleCloseModal={toggleDeleteModal}
              handleDeleting={handleDeleteLocation}
              loc={location}
            />
          )}
          <form onSubmit={handleSubmit} id='myForm'>
            {/* Header of Edit component */}
            {Object.keys(location).length > 0 && (
              <LocationHeader
                location={location}
                toggleLocationModal={toggleLocationModal}
                toggleDeleteModal={toggleDeleteModal}
              />
            )}
            {/* enable button */}
            <EnableContainerStyles>
              <TitleStyles size='18px' margin='0' weight='500'>
                {strings.formEnableLocation}
              </TitleStyles>
              <ToggleSwitch
                labelDisplay='none'
                labelMargin='0'
                name='enabled'
                type='location'
                disabledModal
                canEdit={[1]}
                checked={!!location.enabled}
                handleChange={handleEnable}
              />
            </EnableContainerStyles>
            <LocationAddress
              ref={{
                requiredZipRef,
                requiredCityRef,
                requiredStateRef,
                requiredAddressRef,
                requiredCountryRef
              }}
              strings={strings}
              canEdit={[1]}
              handleDataChange={handleDataChange}
              location={location}
              currentUser={currentUser}
            />
            <LocationDetails
              location={location}
              toggleLoadLimitModal={toggleLoadLimitModal}
              toggleTransformerModal={toggleTransformerModal}
            />
            {account.enableBranding && (
              <LocationBranding
                strings={strings}
                canEdit={[1]}
                handleDataChange={handleDataChange}
                location={location}
              />
            )}
          </form>
        </ComponentWrapper>
        <Button
          cso
          hasMobile
          formSubmit
          ref={buttonRef}
          buttonText={strings.save}
          roles={[1, 3]}
        />
      </EnterpriseAdmin>
    </OuterWrapper>
  );
};

export default LocationsEdit;
