import React, { useContext, useState } from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppChargersService from '../AppChargersService';
import AppPlatformService from '../../../../services/AppPlatform/AppPlatformService';

//global components
import { LoadingSpinner } from '../../../shared';
import ModalButton from '../../../shared/Buttons/ModalButton';

//hooks
import { useRouter } from '../../../shared/hooks/useRouter';

//context
import { CsoChargerContext } from '../../../context/operator/chargerSetup';
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
} from '../../../SharedStyles';

const VerifyModal = (props) => {
  const { strings } = AppChargersService;
  const router = useRouter();
  const { setCharger } = useContext(CsoChargerContext);
  const { setAccount } = useContext(CsoAccountContext);
  const [errorMessage, setErrorMessage] = useState({
    display: false,
    message: '',
  });
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    props.handleCloseModal();
    setErrorMessage({ display: false, message: '' });
  };

  const handleSaveInfo = async (e) => {
    setLoading(true);
    try {
      let chargerData = props.data;
      chargerData.chargerId = chargerData.chargerId.toUpperCase();
      chargerData.verifyOnly = false;
      let response = await AppChargersService.chargerToAccount(chargerData);
      setCharger(response.charger);
      setAccount(response.csoAccount);

      if (AppPlatformService.isCSOApp()) {
        router.history.push(
          `/chargers/${response.charger.iotHubDeviceId}/connect`
        );
      } else {
        router.history.push(`/chargers`);
      }
    } catch (err) {
      setLoading(false);
      if (err.response) {
        setErrorMessage({ display: true, message: err.response.data });
      }
    }
  };

  return (
    <ModalStyles>
      {loading && <LoadingSpinner />}
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.verifyTitle}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          {errorMessage.display && (
            <ModalItemStyles padding='0 10px'>
              <p>{errorMessage.message}</p>
            </ModalItemStyles>
          )}
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <p>{strings.verifyContent}</p>
            <div>
              <h3>{props.data.chargerId}</h3>
            </div>
          </ModalItemStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={!errorMessage.display ? handleSaveInfo : closeModal}
          buttonText={strings.ok}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default VerifyModal;
