import ReactDOM from 'react-dom';

import AppRoutingService from '../../services/AppRouting/AppRoutingService';
import AppAuthService from '../../services/AppAuth/AppAuthService';
import AppPlatformService from '../../services/AppPlatform/AppPlatformService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppWebLandingService = {
  strings: localStrings,
  onContinueClick: function(isAuth) {
    AppAuthService.doWebSignIn(() => {
      ReactDOM.render(
        AppRoutingService.getRouting(isAuth),
        document.getElementById('root')
      );
    });
  }
};

export default AppWebLandingService;
