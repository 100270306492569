import React, { useRef, useEffect } from 'react';

//services
import AppSharedService from './AppSharedService';

//components
import NavSection from '../NavSection/NavSection';
import Footer from '../../shared/Footer/Footer';

//hooks
import { useContainerHeight } from '../../shared/hooks/useContainerHeight';
import { useRouter } from '../../shared/hooks/useRouter';

//styles
import '../CSOGlobal.scss';
import { InteriorStyles, InteriorCSOComponentStyles } from '../../SharedStyles';

const OuterWrapper = (props) => {
  const { strings } = AppSharedService;
  const divRef = useRef();
  const { containerHeight, containerRef, setContainerRef } = useContainerHeight(
    40,
    divRef
  );

  const router = useRouter();

  useEffect(() => {
    setContainerRef(divRef);
  }, [divRef, setContainerRef]);

  return (
    <InteriorStyles>
      <div className='Body-Container'>
        <NavSection
          router={router}
          ref={containerRef}
          title={props.title}
          isInternal={props.internal}
          save={props.save}
          subscribe={props.subscribe}
          add={props.add}
          onSaveClick={props.handleSubmit}
          myForm={props.myForm}
          roles={props.roles}
          path={props.path}
          goBack={props.goBack}
          disabled={props.disabled}
          sharedStrings={strings}
        />
      </div>
      <InteriorCSOComponentStyles
        maxWidth={props.maxWidth}
        isInternal={props.internal}
        mobilePadding={
          props.internal
            ? props.reducePadding
              ? `${containerHeight}px`
              : `${containerHeight + 5}px`
            : null
        }>
        {props.children}
      </InteriorCSOComponentStyles>
      <Footer />
    </InteriorStyles>
  );
};

export default OuterWrapper;
