import React, { useState } from 'react';

//components
import NewNav from '../../operator/AccountCreation/Nav/NewNav';
import Footer from '../Footer/Footer';

//styles
import {
  InteriorStyles,
  ButtonContainerStyles,
  ButtonStyles,
} from '../../SharedStyles';

const NoLocation = (props) => {
  const [message] = useState(JSON.parse(localStorage.getItem('geoLocation')));

  const reloadWindow = () => {
    window.location.reload();
  };

  return (
    <InteriorStyles>
      <div className='Body-Container'>
        <NewNav hideLogin title={`No User Location`} />
        <div className='AppCso-interior-container'>
          <div className='AppCso-dashboard-content'>
            <div className='AppCso-newAccount-title'>
              <h1>{`No User Location`}</h1>
            </div>
            <p style={{ textAlign: 'center', margin: '5% 0 0' }}>
              Reason: {message.message}
            </p>
            <div style={{ textAlign: 'center', margin: '5% 0 0' }}>
              Enable the location via the browser or mobile app settings
            </div>
            <div style={{ textAlign: 'center', margin: '5% 0 0' }}>
              You may need to enable the Web Browser or App Location in your
              settings to access the site.
            </div>
            <ButtonContainerStyles
              margin={props.margin}
              noMobile={props.noMobile}>
              <ButtonStyles onClick={reloadWindow}>Refresh Window</ButtonStyles>
            </ButtonContainerStyles>
          </div>
        </div>
      </div>
      <Footer />
    </InteriorStyles>
  );
};

export default NoLocation;
