import strings from './strings';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppAccessDeniedService = {
  strings: localStrings
};

export default AppAccessDeniedService;
