import React, { useState, useEffect, useContext } from 'react';

//services
import AppLocationsService from '../AppLocationsService';

//components
import LocationsCard from './LocationsCard';

//global components
import Pagination from '../../../shared/Pagination/Pagination';
import { LoadingSpinner } from '../../../shared';

//hooks
import { usePagination } from '../../../shared/hooks/usePagination';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';

//images
import MissingLocationPlaceholder from '../../../../assets/images/MissingLocationPlaceholder.png';

//styles
import { DataCardListStyles } from '../../../OperatorStyles';
import { ListPlaceholderStyles } from '../../../SharedStyles';

const LocationsList = (props) => {
  const { strings } = AppLocationsService;
  const { filteredValue, searchedValue } = props;
  const { account } = useContext(CsoAccountContext);

  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [loading, setLoading] = useState(true);

  //initial Sort Function by lastName - per Jeff. Change the value if we prefer another sort property
  const fetchingLocations = async () => {
    setLoading(true);
    let allLocations = await AppLocationsService.getAllLocations();
    allLocations.sort((a, b) => {
      return a['name'].localeCompare(b['name']);
    });
    setLocations(allLocations);
    if (allLocations.length === 0) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchingLocations();
  }, [account]);

  useEffect(() => {
    setFilteredLocations(locations);
  }, [locations]);

  useEffect(() => {
    if (filteredLocations.length) {
      setLoading(false);
    }
  }, [filteredLocations]);

  /**
   * pagination for the data
   * @param {*} dataArray - items that will be sorted
   * @param {*} itemsPerPage - Custom items limit per page
   */
  const {
    next,
    prev,
    jump,
    currentData,
    currentPage,
    maxPage,
    totalData,
    currentCount,
    pageNumbers,
  } = usePagination(filteredLocations, 20);

  useEffect(() => {
    const uncommonFields = ['address'];
    const lowercasedSearch = searchedValue.toLowerCase();
    const searchedLocations = locations.filter((loc) => {
      return Object.keys(loc).some((key) => {
        if (uncommonFields.includes(filteredValue)) {
          let address = `${loc['address1']} ${loc['address2']} ${loc['city']} ${loc['state']} ${loc['country']}`;
          return address.toLowerCase().includes(lowercasedSearch);
        } else if (loc[key] !== null) {
          return loc[filteredValue ? filteredValue : key]
            .toString()
            .toLowerCase()
            .includes(lowercasedSearch);
        }
        return null;
      });
    });
    setFilteredLocations(searchedLocations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedValue, filteredValue]);

  return (
    <>
      {loading && <LoadingSpinner />}
      {locations.length !== 0 && (
        <Pagination
          currentCount={currentCount}
          totalData={totalData}
          next={next}
          prev={prev}
          jump={jump}
          currentPage={currentPage}
          maxPage={maxPage}
          pageNumbers={pageNumbers}
        />
      )}
      <DataCardListStyles>
        {currentData().map((loc) => (
          <LocationsCard
            fetchingLocations={fetchingLocations}
            location={loc}
            key={loc.id}
          />
        ))}
        {locations.length === 0 && !loading && (
          <ListPlaceholderStyles>
            <p>{strings.noLocationsAdded}</p>
            <img src={MissingLocationPlaceholder} alt='Placeholder' />
          </ListPlaceholderStyles>
        )}
      </DataCardListStyles>
    </>
  );
};

export default LocationsList;
