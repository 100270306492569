import strings from './strings';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import AppApiService from '../../../services/AppApi/AppApiService';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppPaymentsService = {
  createPaymentAccount: function(token) {
    return AppApiService.post('createPaymentAccount', null, token);
  },
  changeCard: function(token) {
    return AppApiService.post('changeCard', null, token);
  },
  makePayment: function(amount) {
    return AppApiService.post('makePayment', null, amount);
  },
  getDriverLedger: function() {
    return AppApiService.get('getDriverLedger');
  },
  getPaymentAccountInfo: function() {
    return AppApiService.get('getPaymentAccountInfo');
  },
  deletePaymentMethod: function() {
    return AppApiService.get('deletePaymentMethod');
  },
  strings: localStrings
};

export default AppPaymentsService;
