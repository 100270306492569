import strings from './strings';
import AppApiService from '../../../services/AppApi/AppApiService';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppChargerDetailsService = {
  strings: localStrings,
  updateCSOChargerSlider: function(charger) {
    return AppApiService.post('csoUpdateCharger', null, charger);
  },
  linkDriverCharger: function(info) {
    return AppApiService.post('linkDriverCharger', null, info);
  },
  getDriverCharger: function(chargerId) {
    return AppApiService.post('getDriverCharger', null, chargerId);
  },
  getChargingStation: function(chargerId) {
    return AppApiService.post('getChargingStation', null, chargerId);
  },
  createDriverComment: function(commentObj) {
    return AppApiService.post('createDriverComments', null, commentObj);
  }
};

export default AppChargerDetailsService;
