import React from 'react';
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';

//layout
import { RoleCanEdit } from '../../OperatorShared';

//styles
import {
  DivStyles,
  ImageThumbnailStyles,
  TitleStyles,
  TextStyles,
} from '../../../SharedStyles';

//helpers
import { getChargerStatusColor } from '../../../shared/Helpers';

const ChargerHeader = (props) => {
  const { charger, location, toggleModals, roles } = props;

  const chargerStatus = getChargerStatusColor(props.charger.statusDescription);

  return (
    <>
      {Object.keys(charger).length > 0 && (
        <DivStyles display='flex' justifyContent='space-between' padding='10px'>
          {/* Left Header */}
          <DivStyles
            display='flex'
            justifyContent='space-between'
            alignItems='center'>
            <ImageThumbnailStyles
              maxWidth='50px'
              margin='0 10px 0 0'
              src={charger.vendorIcon}
              alt={charger.chargerName}
            />
            <DivStyles display='flex' flexDirection='column'>
              <TitleStyles size='14px' weight='700' margin='0'>
                {charger.chargerName || charger.iotHubDeviceId}{' '}
                <span
                  className='Edit-Icon'
                  onClick={() => toggleModals('edit')}>
                  <FaRegEdit />
                </span>
              </TitleStyles>
              {Object.keys(location).length > 0 && (
                <TextStyles size='12px' weight='500' margin='0'>
                  {charger.chargerAddress1} {charger.chargerAddress2}{' '}
                  {location.city}, {location.state} {location.zip}
                </TextStyles>
              )}
            </DivStyles>
          </DivStyles>
          {/* Right Header */}
          <DivStyles
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            size='18px'>
            <RoleCanEdit roles={roles}>
              <FaRegTrashAlt onClick={() => toggleModals('delete')} />
            </RoleCanEdit>
            <DivStyles
              margin='0 5px 0 15px'
              className='Data-circle Data-row'
              style={{ backgroundColor: chargerStatus }}></DivStyles>
          </DivStyles>
        </DivStyles>
      )}
    </>
  );
};

export default ChargerHeader;
