import React, { useState, useContext } from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//service
import AppMembershipsService from '../AppMembershipsService';

//Components
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';

//context
import { DriverContext } from '../../../context/driver/getDriverContext';

const RemoveModal = (props) => {
  const { strings } = AppMembershipsService;
  const { driver } = useContext(DriverContext);
  const [loading, setLoading] = useState(false);

  const removeMembershipHandler = () => {
    setLoading(true);
    //called from top level parents
    props.removeMembership({ id: props.membership.id, userId: driver.id });
  };

  const closeModal = () => {
    props.closeModal();
  };

  return (
    <div className='Modal-Display-Driver'>
      {loading && <LoadingSpinner />}
      <div className='Modal'>
        <div className='Modal-Header'>
          {strings.title}
          <div className='Modal-Close'>
            <FaRegWindowClose onClick={closeModal} />
          </div>
        </div>
        <div className='Modal-Content'>
          <p className='Modal-Content-Text'>{strings.message}</p>
        </div>
        <div className='Modal-Button'>
          <button className='app-button' onClick={removeMembershipHandler}>
            {strings.button}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemoveModal;
