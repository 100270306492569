import React, { useState } from 'react';
import { FaRegWindowClose, FaExclamationTriangle } from 'react-icons/fa';

//services
import AppModalService from './AppModalService';
import AppChargersService from '../../../AppChargersService';

//global components
import ModalButton from '../../../../../shared/Buttons/ModalButton';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  DivStyles,
  ModalNoteStyles,
} from '../../../../../SharedStyles';

const Firmware = (props) => {
  const { strings } = AppModalService;
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  const closeModal = () => {
    props.handleModalClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await AppChargersService.updateFirmware(
        props.chargerDetails.iotHubDeviceId
      );
      setSuccess(true);
    } catch (err) {
      setFailed(true);
    }
  };

  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.firmwareTitle}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <p>{strings.firmwareContent}</p>
          </ModalItemStyles>
          {/* Section */}
          <ModalItemStyles pSize='12px' textMargin='0 0 5px' padding='0 5px'>
            <DivStyles display='flex' alignItems='center' align='left'>
              <DivStyles weight='600' align='left' flex='1'>
                <p>{strings.firmwareInstalled}</p>
                <p>{strings.firmwareDate}</p>
                <p>{strings.firmwareUpdated}</p>
                {props.firmwareUpdates.display && (
                  <>
                    <p>{strings.firmwareLastest}</p>
                    <p>{strings.firmwareLastestDate}</p>
                  </>
                )}
              </DivStyles>
              <DivStyles align='left' flex='1' color='#aaa6a6'>
                <p>{props.chargerDetails.firmwareVersion}</p>
                <p>Jan, 12 2017</p>
                <p>Mar, 5 2017</p>
                {props.firmwareUpdates.display && (
                  <>
                    <p>{props.firmwareUpdates.firmwareVersion}</p>
                    <p>Apr, 18 2018</p>
                  </>
                )}
              </DivStyles>
            </DivStyles>
          </ModalItemStyles>
          {/* Section */}
          {(success || failed) && (
            <ModalItemStyles
              pSize='12px'
              textMargin='0 0 5px'
              padding='0 5px'
              color='red'>
              {success && strings.firmwareUpdateSuccess}
              {failed && strings.firmwareUpdateFailed}
            </ModalItemStyles>
          )}
          <ModalNoteStyles>
            <div className='Warning-modal'>
              <FaExclamationTriangle />
              <strong>{strings.warning} -</strong> {strings.firmwareWarning}
            </div>
          </ModalNoteStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          disabled={!props.firmwareUpdates.display}
          handleClick={success || failed ? handleCloseModal : handleSubmit}
          buttonText={success || failed ? strings.ok : strings.update}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default Firmware;
