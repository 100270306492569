import styled from 'styled-components';

export const EnableContainerStyles = styled.div`
  background: ${({ background }) => (background ? background : '#f1f7ff')};
  text-align: left;
  padding: ${({ padding }) => (padding ? padding : '6px 10px')};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? borderBottom : '1px solid #e7e7e7'};
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;
