import React, { useState, useContext } from 'react';
import {
  FaChargingStation,
  FaBalanceScale,
  FaPlug,
  FaRegEye,
} from 'react-icons/fa';

//services
import AppMeterGroupsService from '../AppMeterGroupsService';

//global components
import ToggleSwitch from '../../OperatorShared/ToggleSwitch/CsoToggleSwitch';

//modals
import EyeModal from '../MeterGroupsModals/DisplayModals/EyeModal';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';

//styles
import {
  FormContainerStyles,
  FormRowStyles,
  FormLeftStyles,
  FormRightStyles,
  SelectStyles,
  TitleStyles,
} from '../../../SharedStyles';

//helpers
import { powerManagementPolicyEnum } from './optionshelper';

const MeterGroupOptions = (props) => {
  const { currentUser, account } = useContext(CsoAccountContext);
  const { meterGroup } = props;
  const [chargingProfileModal, setChargingProfileModal] = useState(false);
  const [powerPolicyModal, setPowerPolicyModal] = useState(false);
  const { strings } = AppMeterGroupsService;

  let adminPermission;
  if (currentUser) {
    adminPermission = currentUser.role === 1;
  }

  const handleInputChange = (e) => {
    props.handleDataChange([e.target.name], e.target.value);
  };

  const toggleDisplayModal = (modal) => {
    if (modal === 'chargingProfile') {
      setChargingProfileModal(!chargingProfileModal);
    }
    if (modal === 'powerPolicy') {
      setPowerPolicyModal(!powerPolicyModal);
    }
  };

  const handleEnable = (e, name) => {
    props.handleDataChange(name, e.target.checked);
  };

  return (
    <FormContainerStyles padding='10px' headersPadding='0'>
      {chargingProfileModal && (
        <EyeModal
          chargingProfile={meterGroup.chargingProfileId}
          meterGroup={meterGroup}
          closeModal={() => toggleDisplayModal('chargingProfile')}
          button={`Button`}
        />
      )}
      {powerPolicyModal && (
        <EyeModal
          meterGroup={meterGroup}
          powerPolicy={powerManagementPolicyEnum}
          closeModal={() => toggleDisplayModal('powerPolicy')}
        />
      )}
      <h3 className='Title'>{strings.optionsTitle}</h3>
      <FormRowStyles display='block' padding='10px 5px 0' margin='0'>
        <FormRowStyles justifyContent='space-between' margin='0'>
          <FormLeftStyles display='flex'>
            <FaChargingStation />
            <TitleStyles size='14px' margin='0' weight='600'>
              {strings.chargingProfileTitle}
            </TitleStyles>
          </FormLeftStyles>
          <FormRightStyles>
            <ToggleSwitch
              adminPermission={adminPermission}
              name='enableMeterGroupChargingProfile'
              checked={!!meterGroup.enableMeterGroupChargingProfile}
              handleChange={handleEnable}
            />
          </FormRightStyles>
        </FormRowStyles>
        <FormRowStyles alignItems='center'>
          <SelectStyles
            size='14px'
            mobileSize='14px'
            width='60%'
            mobileWidth='80%'
            margin='0 0 0 35px'
            mobileMargin='0 0 15px'
            padding='5px 0px 5px 4px'
            bgPosition='1em'
            name='chargingProfileId'
            onChange={handleInputChange}
            value={meterGroup.chargingProfileId || ''}>
            <option value='' disabled>
              {strings.placeholderProfile}
            </option>
            {account &&
              account.chargingProfiles.map((option) => {
                return (
                  <option
                    className='AppCso-options-item-option'
                    key={option.id}
                    value={option.id}>
                    {option.name}
                  </option>
                );
              })}
          </SelectStyles>
          {meterGroup.chargingProfileId && (
            <FaRegEye
              className='View'
              onClick={() => toggleDisplayModal('chargingProfile')}
            />
          )}
        </FormRowStyles>
      </FormRowStyles>
      <FormRowStyles display='block' padding='0 5px 0' margin='0'>
        <FormRowStyles justifyContent='space-between' margin='0'>
          <FormLeftStyles display='flex'>
            <FaBalanceScale />
            <TitleStyles size='14px' margin='0' weight='600'>
              {strings.powerPolicyTitle}
            </TitleStyles>
          </FormLeftStyles>
          <FormRightStyles>
            <ToggleSwitch
              adminPermission={adminPermission}
              name='enablePowerManagementPolicy'
              checked={!!meterGroup.enablePowerManagementPolicy}
              handleChange={handleEnable}
            />
          </FormRightStyles>
        </FormRowStyles>
        <FormRowStyles alignItems='center'>
          <SelectStyles
            size='14px'
            mobileSize='14px'
            width='60%'
            mobileWidth='80%'
            margin='0 0 0 35px'
            mobileMargin='0 0 15px'
            padding='5px 0px 5px 4px'
            bgPosition='1em'
            name='powerManagementPolicyId'
            onChange={handleInputChange}
            value={meterGroup.powerManagementPolicyId || ''}>
            <option value='' disabled>
              {strings.placeholderPower}
            </option>
            {strings.placeholderPowerPolicy.map((option, index) => {
              return (
                <option
                  className='AppCso-options-item-option'
                  key={option}
                  value={index + 1}>
                  {option}
                </option>
              );
            })}
          </SelectStyles>
          {meterGroup.powerManagementPolicyId && (
            <FaRegEye
              className='View'
              onClick={() => toggleDisplayModal('powerPolicy')}
            />
          )}
        </FormRowStyles>
      </FormRowStyles>
      <FormRowStyles display='block' padding='0 5px 0' margin='0'>
        <FormRowStyles justifyContent='space-between' margin='0'>
          <FormLeftStyles display='flex'>
            <FaPlug />
            <TitleStyles size='14px' margin='0' weight='600'>
              {strings.utilityCompany}
            </TitleStyles>
          </FormLeftStyles>
        </FormRowStyles>
        <FormRowStyles alignItems='center'>
          <SelectStyles
            size='14px'
            mobileSize='14px'
            width='60%'
            mobileWidth='80%'
            margin='0 0 0 35px'
            mobileMargin='0 0 15px'
            padding='5px 0px 5px 4px'
            bgPosition='1em'
            name='utility'
            onChange={handleInputChange}
            value={meterGroup.utility || ''}>
            <option value='' disabled>
              {strings.utilityPlaceholder}
            </option>
            {strings.placeholderPowerCompany.map((option, index) => {
              return (
                <option
                  className='AppCso-options-item-option'
                  key={option}
                  value={option}>
                  {option}
                </option>
              );
            })}
          </SelectStyles>
        </FormRowStyles>
      </FormRowStyles>
    </FormContainerStyles>
  );
};

export default MeterGroupOptions;
