//service
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';

const DevModeOnly = ({ children, devOnly }) => {
  let isDev = AppPlatformService.getVersion() === 'dev';
  let isProd = AppPlatformService.getVersion() === 'prod';

  if (isProd) {
    return !devOnly ? children : null;
  }

  return isDev ? children : null;
};

export default DevModeOnly;
