import React, { useContext } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

//layout
import { InteriorWrapper, ComponentWrapper } from '../shared';

//services
import AppFindChargerService from './AppFindChargerService';

//components
import ChargerFavorites from './Favorites/ChargerFavorites';
import NewChargerMap from './Map/NewChargerMap';
import ScanCharger from './Scan/ScanCharger';

//context
import { SearchContext } from '../../context/driver/getPreviousSearch';
import { VehiclesContext } from '../../context/driver/getVehiclesContext';

//styles
import './FindCharger.scss';

const AppFindCharger = (props) => {
  const { strings } = AppFindChargerService;
  const { currentTab, setCurrentTab } = useContext(SearchContext);
  const { vehicles, vehicleLoading } = useContext(VehiclesContext);

  if (vehicles.length === 0 && !vehicleLoading) {
    props.history.push('/add-vehicle');
  }

  return (
    <InteriorWrapper>
      <ComponentWrapper external>
        <Tabs
          defaultIndex={currentTab}
          onSelect={(index) => {
            setCurrentTab(index);
          }}>
          <TabList>
            <Tab>{strings.fav}</Tab>
            <Tab>{strings.search}</Tab>
            <Tab>{strings.scan}</Tab>
          </TabList>
          <TabPanel>
            <ChargerFavorites />
          </TabPanel>
          <TabPanel>
            <NewChargerMap {...props} />
          </TabPanel>
          <TabPanel>
            <ScanCharger />
          </TabPanel>
        </Tabs>
      </ComponentWrapper>
    </InteriorWrapper>
  );
};

export default AppFindCharger;
