import React from 'react';
import moment from 'moment';

//images
import DefaultImage from '../../../../assets/images/MyVehiclePlaceholder300x150.png';

//styles
import {
  CommentCardStyles,
  CommentLeftStyles,
  CommentCenterStyles,
  CommentRightStyles,
  DivStyles,
  TextStyles,
} from '../../../SharedStyles';

const DriverComments = (props) => {
  const { comment } = props;

  return (
    <CommentCardStyles padding='5px 0'>
      <CommentLeftStyles>
        <div className='Comment-Left-Title'>{comment.vehicleName}</div>
        <div className='Comment-Left-Image'>
          <img src={comment.url ? comment.url : DefaultImage} alt={comment.vehicleName}></img>
        </div>
        <div className='Comment-Left-Title'>{comment.battery}kWh</div>
      </CommentLeftStyles>
      <DivStyles flex='1'>
        <CommentCenterStyles
          display='flex'
          justifyContent='space-between'
          alignItems='center'>
          <div className='Comment-Center-Driver'>{comment.driverName}</div>
          <div className='Comment-Right-Date'>
            {moment(comment.commentDate).format('MMM DD, YYYY')}
          </div>
        </CommentCenterStyles>
        <CommentRightStyles>
          <TextStyles margin='0 5px 0 10px'>{comment.comment}</TextStyles>
        </CommentRightStyles>
      </DivStyles>
    </CommentCardStyles>
  );
};

export default DriverComments;
