import React from 'react';

//styles
import {
  TableRowStyles,
  TableItemStyles,
} from '../../../../../../SharedStyles';

const UtilityDetailsItem = (props) => {
  const { session } = props;
  return (
    <TableRowStyles
      hoverBackground='#f1f7ff'
      borderTop='none'
      borderLeft='none'
      borderRight='none'
      align='center'
      width='100%'
      minWidth='1160px'>
      <TableItemStyles minWidth='62px' width='100%' maxWidth='62px'>
        {session.PIEID}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='62px' maxWidth='62px'>
        {session.CSID}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='62px' maxWidth='62px' title={session.DRID}>
      {(new String(session.DRID)).substr(0, 8)}...
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='62px' maxWidth='62px'>
        {session.CEVID}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='90px' maxWidth='90px'>
        {session.VCTime.indexOf('1/01/0001') !== -1 ? '--' : session.VCTime}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='90px' maxWidth='90px'>
        {session.VDTime.indexOf('1/01/0001') !== -1 ? '--' : session.VDTime}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='90px' maxWidth='90px'>
        {session.CSTime}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='90px' maxWidth='90px'>
        {session.CETime}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='62px' maxWidth='62px'>
        {session.CEKW}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='62px' maxWidth='62px'>
        {session.PEKWH}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='62px' maxWidth='62px'>
        {session.CUEKWH}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='90px' maxWidth='90px'>
        {session['15MSTA']}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='90px' maxWidth='90px'>
        {session['15MSTO']}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='62px' maxWidth='62px'>
        {session.RAVGP}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='62px' maxWidth='62px'>
        {session.RPP}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='62px' maxWidth='62px'>
        {session.FEE}
      </TableItemStyles>
    </TableRowStyles>
  );
};

export default UtilityDetailsItem;
