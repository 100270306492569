import AppPlatformService from "../../../services/AppPlatform/AppPlatformService";
import strings from "./strings";

const localStrings = strings[AppPlatformService.getLanguage()];

const AppNotificationsService = {
  strings: localStrings
};

export default AppNotificationsService;
