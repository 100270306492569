import React, { useState, useContext, useEffect } from 'react';

//services
import AppChargingService from '../AppChargingService';

//modals
import AppChargingVehicleModal from '../AppChargingModals/AppChargingVehicleModal';

//context
import { VehiclesContext } from '../../../context/driver/getVehiclesContext';

//images
import MyVehiclePlaceHolder from '../../../../assets/images/MyVehiclePlaceholder300x150.png';

const AppChargingSliderItem = (props) => {
  const { vehicle } = props;
  const { vehicles, setVehicles } = useContext(VehiclesContext);

  //Update Vehicle based on last selection
  useEffect(() => {
    const updateTimeSelected = async () => {
      let updatedVehicles = await Promise.all(
        vehicles.map(async (car) => {
          if (car.default && car.id !== props.vehicle.id) {
            car.default = false;
            await AppChargingService.updateDriverVehicle(car);
          }
          if (!car.default && car.id === props.vehicle.id) {
            car.default = true;
            await AppChargingService.updateDriverVehicle(car);
          }
          return car;
        })
      );
      setVehicles(updatedVehicles);
    };
    if (vehicle !== undefined) {
      updateTimeSelected();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.vehicle]);

  const [modalDisplay, setModalDisplay] = useState(false);

  const handleVehcileInfo = () => {
    setModalDisplay(!modalDisplay);
  };

  return (
    <>
      {vehicle !== undefined && (
        <div key={vehicle.nickName} className='slide'>
          {modalDisplay && (
            <AppChargingVehicleModal
              details={props.vehicle}
              handleModal={handleVehcileInfo}
            />
          )}
          <h3 className='caption'>{vehicle.nickName}</h3>
          <div className='image-container'>
            <img
              className='image'
              src={vehicle.imagePath ? vehicle.imagePath : MyVehiclePlaceHolder}
              alt={vehicle.nickName}
              onClick={handleVehcileInfo}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AppChargingSliderItem;
