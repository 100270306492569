import AppApiService from '../../../../../services/AppApi/AppApiService';
import AppPlatformService from '../../../../../services/AppPlatform/AppPlatformService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppPropsService = {
  approveDriverComment: function(id) {
    return AppApiService.post('approveDriverComment', null, id);
  },
  deleteDriverComment: function(id) {
    return AppApiService.post('deleteDriverComment', null, id);
  },
  strings: localStrings
};

export default AppPropsService;
