import React, { useState, useEffect, useContext } from 'react';
import QrReader from 'react-qr-reader';
import { FaCamera } from 'react-icons/fa';

//context
import { ErrorContext } from '../../../../context/shared/ErrorContext';

//styles
import { DivStyles, TextStyles } from '../../../../SharedStyles';
import LoadingSpinner from '../../../../shared/Loading/LoadingSpinner';

const ChargerQrScanner = (props) => {
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(false);
  const [cameraDenied, setCameraDenied] = useState(false);
  const [result, setResult] = useState(null);

  const handleSubmit = async (data) => {
    setLoading(true);
    try {
    } catch (err) {
      setLoading(false);
      setResult(null);
      setError({
        display: true,
        title: 'Invalid Charger',
        message: 'An invalid Charger ID was entered. Please try again.',
        styles: 'driver',
      });
    }
  };

  useEffect(() => {
    if (result) {
      handleSubmit(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const handleScan = (data) => {
    setResult(data);
  };

  const handleError = (err) => {
    setCameraDenied(true);
    setError({
      display: true,
      title: 'Invalid Permission',
      message: 'Camera Permission Denied, access to scan will be disabled.',
      styles: 'driver',
    });
  };

  return (
    <DivStyles width='150px' margin='25px auto'>
      {loading && <LoadingSpinner />}
      {cameraDenied && (
        <TextStyles>
          <FaCamera />
          Disabled
        </TextStyles>
      )}
      {!cameraDenied && (
        <QrReader
          delay={300}
          showViewFinder={false}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        />
      )}
    </DivStyles>
  );
};

export default ChargerQrScanner;
