import React, { useState, useEffect, useContext, useReducer } from 'react';
import { FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa';
import moment from 'moment';

//service
import AppIncomeService from '../../AppIncomeService';

//context
import { CsoAccountContext } from '../../../../../../../context/operator/getCsoAccount';

//component
import PayoutCard from './PayoutCard';

//global components
import Pagination from '../../../../../../../shared/Pagination/Pagination';
import LoadingSpinner from '../../../../../../../shared/Loading/LoadingSpinner';

//hooks
import { usePagination } from '../../../../../../../shared/hooks/usePagination';
import { useSortAndReverse } from '../../../../../../../shared/hooks/useSortAndReverse';

//styles
import {
  TableStyles,
  TableHeaderStyles,
  TableItemStyles,
  ListPlaceholderStyles,
  DivStyles,
} from '../../../../../../../SharedStyles';

//helpers
import { tableHeader } from '../helpers';

const initialState = {
  payouts: [],
};

function reducer(state, action) {
  switch (action.type) {
    case 'HAS_DETAILS':
      return { ...state, payouts: action.payload };
    case 'NO_DETAILS':
      return { ...state, payouts: action.payload };
    default:
      return state;
  }
}

const PayoutList = (props) => {
  const { strings } = AppIncomeService;
  const { account } = useContext(CsoAccountContext);

  const [state, dispatch] = useReducer(reducer, initialState);
  const [filteredPayouts, setFilteredPayouts] = useState([]);
  const [loading, setLoading] = useState(true);

  const { filteredValue, searchedValue } = props;

  useEffect(() => {
    const fetchPayouts = async () => {
      let res = await AppIncomeService.getPayouts();
      dispatch({ type: 'NO_DETAILS', payload: res });
      if (res.length === 0) {
        setLoading(false);
      }
    };
    if (!props.statementDetails) {
      fetchPayouts();
    } else {
      dispatch({ type: 'HAS_DETAILS', payload: props.statementDetails });
      setLoading(false);
    }
  }, [props.statementDetails]);

  useEffect(() => {
    setFilteredPayouts(state.payouts);
  }, [state.payouts]);

  useEffect(() => {
    if (filteredPayouts.length) {
      setLoading(false);
    }
  }, [filteredPayouts]);

  const updateSortedItems = (items) => {
    setFilteredPayouts(items);
  };

  /**
   * Can be two of these per page for enterprise and non-enterprise
   * @param {*} sortStatus - status for the default sort
   * @param {*} dataArray - items that will be sorted
   * @param {*} headerText - strings array that will show user friendly name to map over
   * @param {*} headerConvert - converts strings in headerText array to object notation, custom function needed for this logic
   * @param {*} updateSortedItem - Function to update the current state with the sorted items
   */
  const [isActiveSort, sortReverse, changeActiveSort] = useSortAndReverse(
    'payoutDate',
    state.payouts,
    strings.payoutHeader,
    tableHeader,
    updateSortedItems,
    'desc'
  );

  useEffect(() => {
    const dateFields = ['activityPeriod'];
    const lowercasedSearch = searchedValue.toLowerCase();
    const searchedStatements = state.payouts.filter((ment) => {
      return Object.keys(ment).some((key) => {
        if (dateFields.includes(filteredValue)) {
          return (
            moment(lowercasedSearch).format('MM/DD/YYYY') >=
              moment(ment.startDate).format('MM/DD/YYYY') &&
            moment(lowercasedSearch).format('MM/DD/YYYY') <=
              moment(ment.stopDate).format('MM/DD/YYYY')
          );
        } else if (ment[key] !== null) {
          return ment[filteredValue ? filteredValue : key]
            .toString()
            .toLowerCase()
            .includes(lowercasedSearch);
        }
        return null;
      });
    });
    setFilteredPayouts(searchedStatements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedValue, filteredValue]);

  /**
   * pagination for the data
   * @param {*} dataArray - items that will be sorted
   * @param {*} itemsPerPage - Custom items limit per page
   */
  const {
    next,
    prev,
    jump,
    currentData,
    currentPage,
    maxPage,
    totalData,
    currentCount,
    pageNumbers,
  } = usePagination(filteredPayouts, 20);

  const tableMaxItemSize = (title) => {
    switch (true) {
      case title === 'Detail':
        return '75px';
      default:
        return 'auto';
    }
  };

  const tableMinItemSize = (title) => {
    switch (true) {
      case title === 'Transaction ID':
        return '200px';
      case title === 'Earned Income':
        return '125px';
      default:
        return 'auto';
    }
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      {state.payouts.length !== 0 && (
        <Pagination
          currentCount={currentCount}
          totalData={totalData}
          next={next}
          prev={prev}
          jump={jump}
          currentPage={currentPage}
          maxPage={maxPage}
          pageNumbers={pageNumbers}
        />
      )}
      <TableStyles minHeight={!props.statementDetails && '500px'}>
        <TableHeaderStyles borderLeft='none' borderRight='none' width='100%'>
          {strings.payoutHeader.map((title) => {
            return (
              <TableItemStyles
                width='100%'
                minWidth={tableMinItemSize(title)}
                maxWidth={tableMaxItemSize(title)}
                borderRight
                key={title}
                onClick={
                  title !== 'Detail'
                    ? () => {
                        changeActiveSort(tableHeader[title]);
                      }
                    : null
                }>
                {isActiveSort[tableHeader[title]] && (
                  <DivStyles
                    position='absolute'
                    left='5px'
                    top='45%'
                    transform='translate(0, -55%)'>
                    {sortReverse[tableHeader[title]] ? (
                      <FaLongArrowAltDown />
                    ) : (
                      <FaLongArrowAltUp />
                    )}{' '}
                  </DivStyles>
                )}
                {title}
              </TableItemStyles>
            );
          })}
        </TableHeaderStyles>
        {currentData().map((payout, index) => {
          return (
            <PayoutCard
              account={account}
              key={index}
              payout={payout}
              strings={strings}
            />
          );
        })}
        {/* </Sort> */}
        {state.payouts.length === 0 && !loading && (
          <ListPlaceholderStyles borderBottom='none'>
            <p>{strings.noStatements}</p>
          </ListPlaceholderStyles>
        )}
      </TableStyles>
    </>
  );
};

export default PayoutList;
