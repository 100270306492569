import React, { useState, useContext, useEffect } from 'react';
import { FaPlus, FaInfoCircle, FaClock } from 'react-icons/fa';

//layout
import { InteriorWrapper, ComponentWrapper } from '../shared';

//services
import AppChargerDetailsService from './AppChargerDetailsService';

//components
import ChargerCard from '../ChargerCard/ChargerCard';
import Comments from './Comments/Comments';
import ChargerImage from './Containers/ChargerImage';

//global components
import LoadingSpinner from '../../shared/Loading/LoadingSpinner';

//hooks
import { useRouter } from '../../shared/hooks/useRouter';

//styles
import './ChargerDetails.scss';
import {
  ListPlaceholderStyles,
  ListContainerStyles,
  TextareaRoundStyles,
  RowStyles,
  FormSmallStyles,
  FormContainerStyles,
  TitleStyles,
  DivStyles,
  TextStyles,
} from '../../SharedStyles';

//context
import { ChargerContext } from '../../context/driver/getChargingStation';
import { VehiclesContext } from '../../context/driver/getVehiclesContext';
import { DriverContext } from '../../context/driver/getDriverContext';
import { DriverChargerContext } from '../../context/driver/getDriverLinkedChargers';
import { ErrorContext } from '../../context/shared/ErrorContext';

//images
import chargerPlaceholderImage from '../../../assets/images/ChargingStationPlaceholder400.png';
import LocationBranding from '../LocationBranding/LocationBranding';

const ChargerDetails = (props) => {
  const { strings } = AppChargerDetailsService;
  const { charger, setCharger } = useContext(ChargerContext);
  const { driver } = useContext(DriverContext);
  const { setError } = useContext(ErrorContext);
  const { defaultVehicle } = useContext(VehiclesContext);
  const {
    linkedCharger,
    setLinkedCharger,
    chargerLoading,
    fetchLinkedChargerData,
  } = useContext(DriverChargerContext);

  const router = useRouter();

  const [loading, setLoading] = useState(true);
  const [commentLoading, setCommentLoading] = useState(false);
  const [commentSubmitted, setCommentSubmitted] = useState({
    display: false,
    message: 'Thank you for submitting a comment. Comment pending approval.',
  });

  const [comment, setComment] = useState({
    iotHubDeviceId: props.match.params.id,
    comment: '',
    driverName: '',
    driverId: '',
    vehicleName: '',
    vehicleId: '',
    url: '',
  });

  useEffect(() => {
    if (defaultVehicle) {
      setComment((c) => ({
        ...c,
        driverName: driver.nickName
          ? driver.nickName
          : `${driver.firstName} ${driver.lastName}`,
        driverId: driver.id,
        vehicleName: `${defaultVehicle.make} ${defaultVehicle.model}`,
        vehicleId: defaultVehicle.id,
        battery: defaultVehicle.batteryCapacity,
        url: defaultVehicle.imagePath,
      }));
    }
  }, [driver, defaultVehicle]);

  const fetchChargingStations = async () => {
    let stationRequest = {
      iotHubDeviceId: props.match.params.id,
      location: driver.driverLocation,
      bypassDirection: false,
    };
    let linkChargerRequest = {
      iotHubDeviceId: props.match.params.id,
      location: driver.driverLocation,
      bypassDirection: true,
    };
    try {
      let chargingStation = await AppChargerDetailsService.getChargingStation(
        stationRequest
      );
      let chargerLinked = await AppChargerDetailsService.getDriverCharger(
        linkChargerRequest
      );

      if (!chargingStation.enabledSubscription) {
        router.history.push('/404');
      }

      if (chargerLinked) {
        chargingStation.favorite = chargerLinked.favorite;
        chargingStation.home = chargerLinked.home;
        if (!chargingStation.imagePath) {
          chargingStation.imagePath = chargerPlaceholderImage;
          chargingStation.placeholderImage = true;
        } else {
          chargingStation.placeholderImage = false;
        }
        chargingStation.comments = chargingStation.comments.filter(
          (comment) => comment.approved
        );
        setLinkedCharger(chargerLinked);
        setCharger(chargingStation);
        setLoading(false);
      } else {
        setLinkedCharger(null);
        chargingStation.comments = chargingStation.comments.filter(
          (comment) => comment.approved
        );
        chargingStation.favorite = false;
        chargingStation.home = false;
        if (!chargingStation.imagePath) {
          chargingStation.imagePath = chargerPlaceholderImage;
          chargingStation.placeholderImage = true;
        } else {
          chargingStation.placeholderImage = false;
        }
        setCharger(chargingStation);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setError({
        display: true,
        title: 'Could not get charger',
        message: err.message,
        styles: 'driver',
      });
    }
  };

  useEffect(() => {
    if (driver.driverLocation) {
      fetchChargingStations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driver.driverLocation]);

  const toggleFavorites = async () => {
    try {
      let chargerUpdated = {};
      if (linkedCharger) {
        chargerUpdated = {
          iotHubDeviceId: charger.iotHubDeviceId,
          favorite: !charger.favorite,
          home: linkedCharger.home,
        };
        let updatedCharger = await AppChargerDetailsService.linkDriverCharger(
          chargerUpdated
        );
        if (updatedCharger) {
          setLinkedCharger(updatedCharger);
          setCharger((c) => ({ ...c, favorite: updatedCharger.favorite }));
          fetchLinkedChargerData();
        }
      } else {
        chargerUpdated = {
          iotHubDeviceId: charger.iotHubDeviceId,
          favorite: !charger.favorite,
          home: false,
        };
        let newUpdate = await AppChargerDetailsService.linkDriverCharger(
          chargerUpdated
        );

        if (newUpdate) {
          setLinkedCharger(newUpdate);
          setCharger((c) => ({ ...c, favorite: newUpdate.favorite }));
          fetchLinkedChargerData();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (e) => {
    setComment({ ...comment, [e.target.name]: e.target.value });
  };

  const handleComment = async (e) => {
    e.preventDefault();
    setCommentLoading(true);
    let commentRes = await AppChargerDetailsService.createDriverComment(
      comment
    );
    setComment({ ...comment, comment: '' });

    if (commentRes) {
      fetchChargingStations();
      setCommentSubmitted({ ...commentSubmitted, display: true });
      setCommentLoading(false);

      setTimeout(() => {
        setCommentSubmitted({ ...commentSubmitted, display: false });
      }, 5000);
    }
  };

  const sortByDate = (a, b) => {
    return new Date(b.commentDate) - new Date(a.commentDate);
  };

  if (Object.keys(charger).length !== 0 && !loading && !chargerLoading) {
    return (
      <InteriorWrapper title={strings.title} isInternal path='/' reducePadding>
        <ComponentWrapper title={strings.title}>
          {/* Conditionally Render Via Location Branding Prop, Waiting on API */}
          {charger.enableLocationBranding && (
            <LocationBranding charger={charger} />
          )}
          {commentLoading && <LoadingSpinner />}
          <ChargerImage
            charger={charger}
            toggleFavorites={toggleFavorites}
            fetchChargingStations={fetchChargingStations}
          />
          <ChargerCard
            details={true}
            charger={charger}
            linkedCharger={linkedCharger}
          />
          <RowStyles padding='10px'>
            <div className='Details-Icon'>
              <FaInfoCircle />
            </div>
            <span className='Details-Content'>
              {charger.driverNotice ? charger.driverNotice : strings.info}
            </span>
          </RowStyles>
          <RowStyles padding='10px'>
            <div className='Details-Icon'>
              <FaClock />
            </div>
            <DivStyles className='Details-Content'>
              {charger.availabilityDescription ||
              charger.pricingScheduleDescription ? (
                charger.availabilityDescription ? (
                  <>
                    <TextStyles>{charger.availabilityDescription}</TextStyles>
                    <TextStyles>
                      {charger.pricingScheduleDescription}
                    </TextStyles>
                  </>
                ) : (
                  <TextStyles>{charger.pricingScheduleDescription}</TextStyles>
                )
              ) : (
                strings.title
              )}
            </DivStyles>
          </RowStyles>
          <ListContainerStyles borderBottom='none' padding='0'>
            <FormContainerStyles borderBottom display='block' padding='10px'>
              <TitleStyles size='12px' margin='0' weight='600'>
                {strings.comment}
              </TitleStyles>
              {Object.keys(charger).length > 0 && (
                <DivStyles display='block' maxHeight='315px'>
                  {charger.comments
                    .filter((comment) => {
                      return comment.approved === true;
                    })
                    .sort(sortByDate)
                    .map((comment) => (
                      <Comments key={comment.id} comment={comment} />
                    ))}
                </DivStyles>
              )}
              {Object.keys(charger).length > 0 &&
                charger.comments.length === 0 && (
                  <ListPlaceholderStyles>
                    <p>{strings.noComments}</p>
                  </ListPlaceholderStyles>
                )}
            </FormContainerStyles>
            <FormSmallStyles
              margin='5px auto 0'
              padding='10px'
              buttonMargin='0 10px'
              id='commentForm'
              onSubmit={handleComment}>
              {!commentSubmitted.display ? (
                <TextareaRoundStyles
                  // height='25px'
                  size='12px'
                  type='text'
                  maxLength='256'
                  name='comment'
                  onChange={handleChange}
                  placeholder={strings.add}
                  value={comment.comment}
                  required
                />
              ) : (
                <TextStyles color='red' flex='1' size='14px' align='left'>
                  {commentSubmitted.message}
                </TextStyles>
              )}
              <button type='submit' form='commentForm'>
                <FaPlus />
              </button>
            </FormSmallStyles>
          </ListContainerStyles>
        </ComponentWrapper>
      </InteriorWrapper>
    );
  } else {
    return (
      <InteriorWrapper title={strings.title} isInternal>
        <ComponentWrapper title={strings.title}>
          <LoadingSpinner />
        </ComponentWrapper>
      </InteriorWrapper>
    );
  }
};

export default ChargerDetails;
