import { useState, useContext, useEffect } from 'react';

//driver service
import AppDriverServices from '../../context/driver/AppDriverServices';

//context
import { VehiclesContext } from '../../context/driver/getVehiclesContext';
import { ErrorContext } from '../../context/shared/ErrorContext';

export const useVehiclesLedger = (initalVehicle) => {
  const { vehicles, setVehicles } = useContext(VehiclesContext);
  const { setError } = useContext(ErrorContext);
  const [vehicle, setVehicle] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchLedgers = async () => {
    try {
      setLoading(true);
      let ledgers = await AppDriverServices.getDriverLedger();
      let vehicleWithLedger = vehicles.map((vehicle) => {
        let vehicleLedger = ledgers.filter(
          (ledger) => ledger.vehicleId === vehicle.vehicleId
        );
        vehicle.chargingHistory = vehicleLedger;
        return vehicle;
      });

      if (initalVehicle) {
        let singleVehicle = vehicleWithLedger.filter((veh) => {
          return veh.vehicleId === initalVehicle.vehicleId;
        });
        setVehicle(singleVehicle[0]);
        if (singleVehicle) {
          setLoading(false);
        }
      }

      setVehicles(vehicleWithLedger);
      if (vehicleWithLedger) {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setError({
        display: true,
        title: 'Something went wrong',
        message: 'Could not load the ledger information.',
        styles: 'driver',
      });
    }
  };

  useEffect(() => {
    fetchLedgers();
    // eslint-disable-next-line
  }, []);

  return [
    initalVehicle ? vehicle : vehicles,
    initalVehicle
      ? (value) => {
          setVehicle(value);
        }
      : (value) => {
          setVehicles(value);
        },
    loading,
  ];
};
