import React, { useState, useEffect, useContext } from 'react';

//layout
import {
  ComponentWrapper,
  OuterWrapper,
  RoleCanEdit,
} from '../../../../../OperatorShared';

//services
import ChargingSessionService from '../ChargingSessionService';

//components
import DetailsList from './DetailsList';

//global components
import SubscriptionAccess from '../../../../../OperatorShared/SubscriptionAccess';
import ReportFilter from '../../../../ReportFilter';

//context
import { CsoAccountContext } from '../../../../../../context/operator/getCsoAccount';

//styles
import { ButtonGreyStyles, DivStyles } from '../../../../../../SharedStyles';

//helpers
import { handleFilterUpdates } from './helpers';

//helpers
import { timeConvert, milesToKm } from '../../../../../../shared/Helpers';
import {
  dateProperties,
  locationProperties,
  metergroupProperties,
  chargerProperties,
  chargerTypeProperties,
} from '../../filterProperties';

import { downloadMultiCSVReport } from '../../../../../../shared/Download/download';

const Details = () => {
  const { strings } = ChargingSessionService;
  const { account, currentUser } = useContext(CsoAccountContext);
  const [filters, setFilters] = useState({
    location: [locationProperties[0]],
    metergroup: [metergroupProperties[0]],
    charger: [chargerProperties[0]],
    date: dateProperties[0],
    chargerType: [chargerTypeProperties[0]],
  });
  const [locSessionStrings, setLocSessionStrings] = useState([]);
  const [mgSessionStrings, setMgSessionStrings] = useState([]);
  const [chargerSessionStrings, setChargerSessionStrings] = useState([]);
  const [reportData, setReportData] = useState({});
  const [exportReport, setExportReport] = useState({
    Date: [],
    Location: [],
    Chargers: [],
    Metergroup: [],
    ChargerType: [],
  });

  useEffect(() => {
    const { locStr, mgStr, chargerStr } = handleFilterUpdates(
      account,
      filters,
      strings
    );

    setLocSessionStrings(locStr);
    setMgSessionStrings(mgStr);
    setChargerSessionStrings(chargerStr.flat());
  }, [filters, strings, account]);

  const fetchReportData = (data) => {
    setReportData(data);
  };

  const handleDownload = () => {
    // let downloadSummaries = {
    //   ...reportData.summaries,
    //   totalSessionTime: timeConvert(reportData.summaries.totalSessionTime),
    //   averageSessionTime: timeConvert(reportData.summaries.averageSessionTime),
    //   totalRange: milesToKm(
    //     currentUser.unitOfMeasurement,
    //     reportData.summaries.totalRange
    //   ),
    // };

    let downloadSummaries = {
      sessions: reportData.sessions.length,
      totalChargeTime: timeConvert(reportData.summaries.totalSessionTime, true, true),
      averageChargeTime: timeConvert(reportData.summaries.averageSessionTime, true, true),
      averagekWh: reportData.summaries.averagekWh,
      totalkWh: reportData.summaries.totalkWh,
      totalRange: milesToKm(
        currentUser.unitOfMeasurement,
        reportData.summaries.totalRange
      ),
      totalSessionAmt: reportData.summaries.totalSessionAmt,
      totalIdleAmt: reportData.summaries.totalIdleAmt,
    }

    let downloadedSession = reportData.sessions.map((x) => {
      const s = {chargerId: x.iotHubDeviceId, ...x};
      delete s.idTag;
      delete s.location;
      delete s.chargerLevel;
      delete s.chargerOutput;
      delete s.transactionDate;
      delete s.iotHubDeviceId;
      delete s.totalTime;
      delete s.time;

      if (account.subscriptionLevel !== 3) {
        delete s.posType;
        delete s.idleRate;
        delete s.idleRateType;
        delete s.idleTime;
        delete s.idleCost;
        delete s.driverName;
        delete s.vehicleMake;
        delete s.vehicleModel;
        delete s.vehicleYear;
      }
      
      // if (isNaN(parseFloat(s.time))) {
      //   s.time = '--';
      // }

      //s.totalTime = timeConvert(s.totalTime);
      s.milesAdded = milesToKm(currentUser.unitOfMeasurement, s.milesAdded);
      return s;
    });

    let refactorFilters = {};
    Object.entries(filters).forEach(([key, value]) => {
      Object.entries(exportReport).forEach(([k, v]) => {
        Object.entries(v).forEach(([a]) => {
          if (a === value.toString()) {
            refactorFilters = { ...refactorFilters, [key]: v[a] };
          }
        });
      });
    });

    downloadMultiCSVReport(`Charging_Session-${Date.now()}`, [
      { title: 'Selected Filters', voidFormat: true, data: [refactorFilters] },
      { title: 'Summaries', data: [downloadSummaries] },
      { title: 'Sessions', voidFormat: true, data: downloadedSession },
    ]);
  };

  const updateReportFilters = (name, structure) => {
    setExportReport((prev) => ({ ...prev, [name]: structure }));
  };

  useEffect(() => {
    //check if strings include whats in filter if not reset filter
    let someLoc = locSessionStrings.some((l) => {
      if (l === strings.filterLocationList[0]) {
        l = locationProperties[0];
      }
      return filters.location.includes(l);
    });

    let someMg = mgSessionStrings.some((m) => {
      if (m === strings.filterMetergroupList[0]) {
        m = metergroupProperties[0];
      }
      return filters.metergroup.includes(m);
    });

    let someChar = chargerSessionStrings.some((c) => {
      if (c === strings.filterChargersList[0]) {
        c = chargerProperties[0];
      }
      return filters.charger.includes(c);
    });

    if (!someLoc) {
      setFilters((prev) => ({
        ...prev,
        location: [locationProperties[0]],
      }));
    }
    if (!someMg) {
      setFilters((prev) => ({
        ...prev,
        metergroup: [metergroupProperties[0]],
      }));
    }
    if (!someChar) {
      setFilters((prev) => ({
        ...prev,
        charger: [chargerProperties[0]],
      }));
    }
    // eslint-disable-next-line
  }, [locSessionStrings, mgSessionStrings, chargerSessionStrings]);

  const handleFilterChange = (type, filter) => {
    switch (true) {
      case type === 'Location':
        if (filter.includes(locationProperties[0])) {
          return setFilters({
            ...filters,
            location: [locationProperties[0]],
          });
        } else {
          return setFilters({ ...filters, location: filter });
        }
      case type === 'Metergroup':
        if (filter.includes(metergroupProperties[0])) {
          return setFilters({
            ...filters,
            metergroup: [metergroupProperties[0]],
          });
        } else {
          return setFilters({ ...filters, metergroup: filter });
        }
      case type === 'Chargers':
        if (filter.includes(locationProperties[0])) {
          return setFilters({
            ...filters,
            charger: [locationProperties[0]],
          });
        } else {
          return setFilters({ ...filters, charger: filter });
        }
      case type === 'Date':
        return setFilters({ ...filters, date: filter });
      case type === 'ChargerType':
        if (filter.includes(chargerTypeProperties[0])) {
          return setFilters({
            ...filters,
            chargerType: [chargerTypeProperties[0]],
          });
        } else if (filter.includes(chargerTypeProperties[1])) {
          return setFilters({
            ...filters,
            chargerType: [chargerTypeProperties[1]],
          });
        } else if (filter.includes(chargerTypeProperties[2])) {
          return setFilters({
            ...filters,
            chargerType: [chargerTypeProperties[2]],
          });
        } else {
          return setFilters({ ...filters, chargerType: filter });
        }
      default:
        return setFilters({
          location: [locationProperties[0]],
          metergroup: [metergroupProperties[0]],
          charger: [chargerProperties[0]],
          date: dateProperties[0],
          chargerType: [chargerTypeProperties[0]],
        });
    }
  };

  return (
    <OuterWrapper title={strings.pageTitle} maxWidth='1200px' internal path='/'>
      <SubscriptionAccess levels={[1, 2, 3]}>
        <RoleCanEdit roles={[1, 2, 4]}>
          <ComponentWrapper title={strings.pageTitle}>
            <DivStyles>
              <DivStyles
                display='flex'
                alignItems='center'
                justifyContent='space-between'>
                <ReportFilter
                  width='200px'
                  margin='10px 10px 5px'
                  label='Session Dates'
                  name='Date'
                  updateReportFilters={updateReportFilters}
                  filterBy={strings.filterDateList}
                  filterProps={dateProperties}
                  filteredValues={filters.date}
                  handleFilterChange={handleFilterChange}
                />
                <ButtonGreyStyles
                  disabled={
                    Object.keys(reportData).length > 0 &&
                    reportData.sessions.length === 0
                  }
                  padding='5px 8px'
                  margin='0 10px 0 0'
                  onClick={handleDownload}>
                  {strings.export}
                </ButtonGreyStyles>
              </DivStyles>
              <DivStyles display='flex' justifyContent='space-between'>
                <ReportFilter
                  margin='5px 10px'
                  borderRadius='0'
                  noDropdown
                  multiple
                  multipleSize='3'
                  label='Charger Locations'
                  name='Location'
                  updateReportFilters={updateReportFilters}
                  filterBy={locSessionStrings}
                  filterProps={locationProperties}
                  filteredValues={filters.location}
                  handleFilterChange={handleFilterChange}
                />
                <ReportFilter
                  margin='5px 10px'
                  borderRadius='0'
                  noDropdown
                  multiple
                  multipleSize='3'
                  label='Metergroups'
                  name='Metergroup'
                  updateReportFilters={updateReportFilters}
                  filterBy={mgSessionStrings}
                  filterProps={metergroupProperties}
                  filteredValues={filters.metergroup}
                  handleFilterChange={handleFilterChange}
                />
                <ReportFilter
                  margin='5px 10px'
                  borderRadius='0'
                  noDropdown
                  multiple
                  multipleSize='3'
                  label='Chargers'
                  name='Chargers'
                  updateReportFilters={updateReportFilters}
                  filterBy={chargerSessionStrings}
                  filterProps={chargerProperties}
                  filteredValues={filters.charger}
                  handleFilterChange={handleFilterChange}
                />
                <ReportFilter
                  margin='5px 10px 5px 0'
                  borderRadius='0'
                  noDropdown
                  multiple
                  multipleSize='3'
                  label='Charger Types'
                  name='ChargerType'
                  updateReportFilters={updateReportFilters}
                  filterBy={strings.filterChargerType}
                  filterProps={chargerTypeProperties}
                  filteredValues={filters.chargerType}
                  handleFilterChange={handleFilterChange}
                />
              </DivStyles>
            </DivStyles>
            {/* list of filtered results */}
            <DetailsList
              filters={filters}
              strings={strings}
              account={account}
              fetchReportData={fetchReportData}
            />
          </ComponentWrapper>
        </RoleCanEdit>
      </SubscriptionAccess>
    </OuterWrapper>
  );
};

export default Details;
