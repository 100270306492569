import React, { useState, useContext, useEffect } from 'react';

//layout
import { ComponentWrapper, OuterWrapper, RoleCanEdit } from '../OperatorShared';

//services
import AppEarnedIncomeService from './AppEarnedIncomeService';
import AppApiService from '../../../services/AppApi/AppApiService';

//components
import AccountHeader from '../OperatorShared/AccountHeader';
import EarnedData from './EarnedData';
import SubscriptionAccess from '../OperatorShared/SubscriptionAccess';
import Button from '../../shared/Buttons/Button';
import { LoadingSpinner } from '../../shared';

//context
import { CsoAccountContext } from '../../context/operator/getCsoAccount';

//styles
import { DivStyles, TitleStyles, TextStyles } from '../../SharedStyles';

const EarnedIncome = () => {
  const { strings } = AppEarnedIncomeService;
  const { account } = useContext(CsoAccountContext);
  const [earnedIncome, setEarnedIncome] = useState(null);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [hasKiosk, setHasKiosk] = useState(false);

  const fetchEarnedIncome = async () => {
    const res = await AppEarnedIncomeService.getEarnedIncomeInfo();
    setEarnedIncome(res);
    const settings = await AppApiService.get('getCSOAccountSettings');
    //setEarnedIncome({...res, hasKiosk: settings.hasKiosk});
    setHasKiosk(settings.hasKiosk);
    setLoading(false);
  };

  useEffect(() => {
    fetchEarnedIncome();
    // eslint-disable-next-line
  }, []);

  const handlePayout = async () => {
    setProcessing(true);
    setLoading(true);

    await AppEarnedIncomeService.createPayout();
    fetchEarnedIncome();
  };

  return (
    <OuterWrapper title={strings.title} internal path='/'>
      <SubscriptionAccess levels={[1, 2, 3]}>
        {loading && <LoadingSpinner />}
        <RoleCanEdit roles={[1, 2]}>
          <ComponentWrapper title={strings.title}>
            <AccountHeader strings={strings} />
            <DivStyles padding='10px'>
              <TitleStyles size='14px' weight='600' margin='0 0 10px'>
                {strings.title}
              </TitleStyles>
              <TextStyles size='14px' margin='0 0 10px'>
                {strings.introOne}{' '}
                {earnedIncome && earnedIncome.enableManualPayments && (
                  <>
                    {strings.introTwo} <em>{strings.introInitiate}</em>{' '}
                    {strings.introThree}{' '}
                  </>
                )}
                {strings.introFour}
              </TextStyles>
              {earnedIncome && (
                <>
                  <EarnedData
                    account={account}
                    strings={strings}
                    earnedIncome={earnedIncome}
                  />
                  {earnedIncome.enableManualPayments && (
                    <>
                      <Button
                        cso
                        margin='20px 0 10px'
                        // hasMobile
                        onclick={handlePayout}
                        disabled={
                          earnedIncome.pendingTransferAmount <
                          earnedIncome.minimumPayoutAmount.split('$')[1]
                        }
                        buttonText={`${strings.introInitiate} ${strings.now}`}
                        roles={[1, 2]}
                      />

                      <DivStyles align='center'>
                        {earnedIncome.pendingTransferAmount <
                          earnedIncome.minimumPayoutAmount.split('$')[1] && (
                          <TextStyles
                            size='14px'
                            weight='500'
                            color='red'
                            margin='0'>
                            {strings.notes3Alt}{' '}
                            {earnedIncome.minimumPayoutAmount}
                          </TextStyles>
                        )}

                        {processing && (
                          <>
                            <TextStyles
                              size='14px'
                              weight='500'
                              color='red'
                              margin='0'>
                              {strings.paymentInitiated}
                            </TextStyles>
                            <TextStyles
                              size='14px'
                              weight='500'
                              color='red'
                              margin='0 0 5px'>
                              {strings.businessDays}
                            </TextStyles>
                          </>
                        )}
                      </DivStyles>
                    </>
                  )}

                  <DivStyles>
                    <TextStyles size='12px' margin='0' weight='bold'>
                      {strings.notes}
                    </TextStyles>
                    <TextStyles size='12px' margin='0'>
                      {strings.notes1}
                    </TextStyles>
                    <TextStyles size='12px' margin='0'>
                      {strings.notes2One}{' '}
                      {earnedIncome.percentageBasedBankFee * 100}
                      {strings.notes2Two} ${earnedIncome.flatTransferFee}{' '}
                      {strings.notes2Three}{' '}
                      <em>{strings.currentEarnedIncome}</em>{' '}
                      {strings.notes2Four}
                    </TextStyles>
                    <TextStyles size='12px' margin='0'>
                      {strings.notes3} {earnedIncome.minimumPayoutAmount}.
                    </TextStyles>
                    <TextStyles size='12px' margin='0'>
                      {strings.notes4}
                    </TextStyles>
                    <TextStyles size='12px' margin='0'>
                      {strings.notes5}
                    </TextStyles>
                    <TextStyles size='12px' margin='0'>
                      {strings.notes6One} <em>{strings.currentEarnedIncome}</em>{' '}
                      {strings.notes6Two} <em>{strings.pendingBankFees} or </em>
                      <em>{strings.pendingXferFees}.</em>
                    </TextStyles>
                    {hasKiosk && (
                      <TextStyles size='12px' margin='0'>
                      {strings.notes7One} <em>{strings.notes7em}</em>{' '}
                      {strings.notes7Two} <strong>{strings.notes7Bold}</strong>{' '}
                      {strings.notes7Three} <a href="https://merchant.ampedup.net/">{strings.notes7Link}</a>{' '}                      
                    </TextStyles>
                    )}
                  </DivStyles>
                </>
              )}
            </DivStyles>
          </ComponentWrapper>
        </RoleCanEdit>
      </SubscriptionAccess>
    </OuterWrapper>
  );
};

export default EarnedIncome;
