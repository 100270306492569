import React, { useEffect, useContext, useState } from 'react';

//service
import AppDriverSettingsService from '../AppDriverSettingsService';

//components
import ModalButton from '../../../shared/Buttons/ModalButton';
import { LoadingSpinner } from '../../../shared';

//styles
import {
  DivStyles,
  ImageConnectionSpinnerStyles,
  ImageMediumStyles,
  InputStyles,
  ModalContainerStyles,
  ModalContentStyles,
  ModalHeaderStyles,
  ModalItemStyles,
  ModalStyles,
} from '../../../SharedStyles';
import { FaRegWindowClose } from 'react-icons/fa';

const NFCSetupModal = (props) => {
  const { strings } = AppDriverSettingsService;

  const [loading, setLoading] = useState(false);
  const [station, setStation] = useState('');
  const [pollingNFC, setPollingNFC] = useState({
    poll: false,
    finished: false,
    station: null,
  });
  const [pollingStatus, setPollingStatus] = useState(null);

  useEffect(() => {
    if (pollingNFC.poll) {
      let polling = setInterval(async () => {
        let res = await AppDriverSettingsService.pollingNFCProgress(
          pollingNFC.station
        );
        setPollingStatus(res);
        if (res === 'Success' || res === 'Failed') {
          clearInterval(polling);
        }
      }, 2000);
    }
  }, [pollingNFC]);

  useEffect(() => {
    if (pollingStatus === 'Failed') {
      setPollingNFC({ poll: true, finished: true, station: null });
    }
    if (pollingStatus === 'Success') {
      setPollingNFC({ poll: true, finished: true, station: null });
    }
  }, [pollingStatus]);

  const closeModal = () => {
    setLoading(false);
    props.handleCloseModal();
  };

  const handleChange = (e) => {
    setStation(e.target.value);
  };

  const handleTryAgain = () => {
    setPollingNFC({ poll: false, finished: false, station: null });
    setPollingStatus(null);
  };

  const handleSaveInfo = async (e) => {
    e.preventDefault(e);
    setLoading(true);
    try {
      let res = await AppDriverSettingsService.startNFCIDScan(station);

      if (res) {
        setPollingNFC({ poll: true, finished: false, station: station });
        setLoading(false);
      }
      // closeModal();
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {loading && <LoadingSpinner />}
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.registerDevice}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        {pollingNFC.poll ? (
          <ModalContentStyles>
            {/* Section */}
            {(pollingStatus === 'Pending' || !pollingStatus) && (
              <ImageConnectionSpinnerStyles />
            )}
            <ModalItemStyles padding='0 10px'>
              {pollingStatus === 'Pending' && <p>Registering...</p>}
              {pollingStatus === 'Success' && (
                <>
                  <p>Success!</p>
                  <p>You've successfully registered your mobile device.</p>
                  <p>
                    You'll notice under the Access Cards menu option, your phone
                    will be listed and you can give it a Nickname.
                  </p>
                  <p>
                    From this point forward, you can simply plug into the
                    charger and swipe your device to begin a charging session.
                    You can do this at any AmpedUp! Networks charger with
                    RFID/NFC scan technology.
                  </p>
                </>
              )}
              {pollingStatus === 'Failed' && (
                <>
                  <p>Registration Failed. Please try again.</p>
                  <p>Fail Reason: registration expired</p>
                </>
              )}
            </ModalItemStyles>
          </ModalContentStyles>
        ) : (
          <ModalContentStyles>
            {/* Section */}
            <ModalItemStyles padding='0 10px' align='left' imgMaxWidth='60px'>
              <ol>
                <li>Be sure the NFC function on your phone is enabled</li>
                <li>
                  Go to an EV charger with the AmpedUp! Networks or AmpedUp!
                  Inside logo
                </li>
                <li>
                  Confirm that the charger supports RFID/NFC scanning by
                  locating the NFC scan logo <br />
                  <DivStyles align='center' margin='10px auto'>
                    <ImageMediumStyles
                      maxWidth='40px'
                      src={props.tapNchargeImage}
                      alt={strings.tapChargeRegistration}
                    />
                  </DivStyles>
                </li>
                <li>Plug the charger connector into your vehicle</li>
                <li>Enter the Charger ID in the Charger ID field below</li>
                <li>Press the "Start Scan" button below</li>
                <li>
                  Swipe your phone over the NFC Scan logo until you see the
                  charger react (you have 30 seconds to do this)
                </li>
                <li>Wait for a Success! response in this dialog</li>
                <li>
                  The charger will begin charging and your Device will be
                  registered.
                </li>
              </ol>
              <InputStyles
                type='text'
                onChange={handleChange}
                name='iotHubDeviceId'
                value={station}
                placeholder='charger ID'
              />
            </ModalItemStyles>
          </ModalContentStyles>
        )}
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          disabled={station === '' || (pollingNFC.poll && !pollingNFC.finished)}
          handleClick={
            pollingStatus
              ? pollingStatus !== 'Failed'
                ? closeModal
                : handleTryAgain
              : handleSaveInfo
          }
          buttonText={
            (pollingNFC.poll && !pollingNFC.finished) || pollingStatus
              ? pollingStatus !== 'Failed'
                ? strings.ok
                : 'Try Again'
              : strings.startScan
          }
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default NFCSetupModal;
