import React from 'react';
import moment from 'moment';

//styles
import { TableRowStyles, TableItemStyles } from '../../../../../SharedStyles';

//servies
import AppPlatformService from '../../../../../../services/AppPlatform/AppPlatformService';

const IncomeCard = (props) => {
  const { item } = props;

  return (
    <TableRowStyles
      hoverBackground='#f1f7ff'
      borderTop='none'
      borderLeft='none'
      borderRight='none'
      align='center'
      width='780px'
      hideScroll>
      <TableItemStyles width='100%'>
        {moment(item.date).format('MM/DD/YY')}
      </TableItemStyles>
      <TableItemStyles width='100%'>{item.bankName}</TableItemStyles>
      <TableItemStyles width='100%'>
        {item.routingNumber !== '' ? item.routingNumber : 'Credit Card'}
      </TableItemStyles>
      <TableItemStyles width='100%'>****{item.last4}</TableItemStyles>
      <TableItemStyles minWidth='225px' width='100%'>
        {item.transactionId}
      </TableItemStyles>
      {props.credit && (
        <TableItemStyles width='100%'>
          {AppPlatformService.currencyFormat(item.amount)}
        </TableItemStyles>
      )}
      {props.debit && (
        <TableItemStyles width='100%'>
          ({AppPlatformService.currencyFormat(item.amount)})
        </TableItemStyles>
      )}
    </TableRowStyles>
  );
};

export default IncomeCard;
