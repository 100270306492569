import React, { useEffect, Fragment, useContext, useState } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppModalService from './AppModalService';

//global components
import ModalButton from '../../../../../shared/Buttons/ModalButton';

//modals
import InvalidOption from './InvalidOption';

//context
import { CsoAccountContext } from '../../../../../context/operator/getCsoAccount';

//hooks
import { useHours } from '../../../../../shared/hooks/useHours';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  ModalNoteStyles,
  DivStyles,
  TextStyles,
} from '../../../../../SharedStyles';

//helpers
import { dayOfWeek } from '../../../../helpers';
import { timeConvert, endingZeroOnMoney } from '../../../../../shared/Helpers';

const EyeModal = (props) => {
  const { strings } = AppModalService;
  const { account } = useContext(CsoAccountContext);
  const [chargingProfile, setChargingProfile] = useState({});
  const [pricingSx, setPricingSx] = useState({});
  const [discounts, setDiscounts] = useState([]);
  const [hours] = useHours();

  const closeModal = () => {
    props.closeModal();
  };

  console.log(props.discountIds);

  useEffect(() => {
    if (account) {
      const getProfiles = () => {
        if (props.chargingProfile) {
          account.chargingProfiles.forEach((prof) => {
            if (
              prof.id ===
              props.charger.evses[0].connectors[props.profileId - 1]
                .chargingProfileId
            ) {
              setChargingProfile(prof);
            }
          });
        }
        if (props.pricingSx) {
          account.pricingSchedules.forEach((sx) => {
            console.log(sx);
            if (sx.id === props.charger.pricingScheduleId) {
              setPricingSx(sx);
            }
          });
        }

        if (props.discountIds) {
          let discountsApplied = account.discountSchedules.filter((d) => {
            return props.discountIds.includes(d.id);
          });
          setDiscounts(discountsApplied);
        }
      };
      getProfiles();
    }
  }, [
    props.charger,
    account,
    props.chargingProfile,
    props.pricingSx,
    props.profileId,
  ]);

  const getKwData = (charger, percentage) => {
    const { voltage, maxCurrent } = charger;
    let kw = (voltage * maxCurrent) / 1000;
    let perc = percentage / 100;
    return (kw * perc).toFixed(2);
  };

  if (props.chargingProfile && Object.keys(chargingProfile).length > 0) {
    return (
      <ModalStyles>
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{strings.chargingProfileTitle}</h2>
            <FaRegWindowClose className='Close' onClick={closeModal} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            <ModalItemStyles
              align='left'
              padding='0'
              className='Eye-modal-header'>
              <p>{chargingProfile.name}</p>
            </ModalItemStyles>
            {/* Section */}
            <ModalItemStyles padding='0'>
              <p>{strings.chargingProfileContent}</p>
            </ModalItemStyles>
            {/* Section */}
            {chargingProfile.schedules.map((sx) => {
              return (
                <ModalItemStyles
                  align='left'
                  className='Eye-time-table'
                  padding='0'
                  pSize='12px'
                  textMargin='0 0 5px'
                  key={sx.id}>
                  {Object.keys(sx.dow).map((day) => {
                    if (sx.dow[day]) {
                      return <span key={day}>{dayOfWeek[day]} </span>;
                    }
                    return null;
                  })}
                  {sx.times.map((time, idx) => {
                    let startingTime;
                    let endingTime;
                    hours.forEach((hr) => {
                      if (hr.time === time.timeStart) {
                        startingTime = hr.display;
                      }
                      if (hr.time === time.timeEnd) {
                        endingTime = hr.display;
                      }
                    });
                    return (
                      <DivStyles justifyContent='space-between' key={idx}>
                        <TextStyles color='#8f8d8d' weight='400' size='12px'>
                          {startingTime} - {endingTime}
                        </TextStyles>
                        <div>
                          <span>
                            {time.percentage}% (
                            {getKwData(props.charger, time.percentage)} kw)
                          </span>
                        </div>
                      </DivStyles>
                    );
                  })}
                </ModalItemStyles>
              );
            })}
            {/* </ModalItemStyles> */}
            <ModalNoteStyles>
              <p className='Warning-modal'>{strings.chargingProfileNote}</p>
            </ModalNoteStyles>
          </ModalContentStyles>
          {/* Button Component for Modals*/}
          <ModalButton
            containerMargin='10px 10px 0'
            containerAlign='right'
            buttonPadding='10px'
            buttonMargin='0'
            handleClick={closeModal}
            buttonText={strings.ok}
          />
        </ModalContainerStyles>
      </ModalStyles>
    );
  }

  if (props.pricingSx && Object.keys(pricingSx).length > 0) {
    return (
      <ModalStyles>
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{props.title}</h2>
            <FaRegWindowClose className='Close' onClick={closeModal} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            <ModalItemStyles
              align='left'
              padding='0'
              className='Eye-modal-header'>
              <p>{pricingSx.name}</p>
            </ModalItemStyles>
            {/* Section */}
            <ModalItemStyles
              align='left'
              className='Eye-time-table'
              padding='0'>
              {/* Default Rate */}
              <DivStyles>
                <span>{strings.defaultRate}</span>
                <span>
                  {pricingSx.defaultRateType !== 'free'
                    ? `$${endingZeroOnMoney(
                        pricingSx.defaultRate,
                        pricingSx.defaultRateType
                      )} / ${pricingSx.defaultRateType}`
                    : strings.free}
                </span>
              </DivStyles>
              {/* Default Idle Rate */}
              <DivStyles>
                <span>{strings.defaultIdleFee}</span>
                <span>
                  {`$${endingZeroOnMoney(
                    pricingSx.defaultIdleFee,
                    pricingSx.defaultIdleFeeType
                  )} / ${pricingSx.defaultIdleFeeType}`}
                </span>
              </DivStyles>
              {/* Default Min Amt */}
              <DivStyles>
                <span>{strings.defaultMinAmt}</span>
                <span>
                  {`$${endingZeroOnMoney(
                    pricingSx.defaultMinAmount,
                    ''
                  )}`}
                </span>
              </DivStyles>
              {/* Default Grace Period */}
              <DivStyles>
                <span>{strings.grace}</span>
                <span>{timeConvert(pricingSx.defaultGracePeriod)}</span>
              </DivStyles>
              {/* Default Max Session Time */}
              <DivStyles>
                <span>{'Default Max Session Time:'}</span>
                <span>
                  {pricingSx.defaultMaxTime === 0 ||
                  pricingSx.defaultMaxTime === undefined
                    ? 'unlimited'
                    : timeConvert(pricingSx.defaultMaxTime)}
                </span>
              </DivStyles>
            </ModalItemStyles>
            {/* Section */}
            {pricingSx.enableSchedules &&
              pricingSx.schedules.map((sx) => {
                return (
                  <ModalItemStyles
                    align='left'
                    className='Eye-time-table'
                    padding='0'
                    pSize='12px'
                    textMargin='0 0 2px'
                    key={sx.id}>
                    {Object.keys(sx.dow).map((day) => {
                      if (sx.dow[day]) {
                        return <span key={day}>{dayOfWeek[day]} </span>;
                      }
                      return null;
                    })}
                    {sx.times.map((time) => {
                      let startingTime;
                      let endingTime;
                      hours.forEach((hr) => {
                        if (hr.time === time.timeStart) {
                          startingTime = hr.display;
                        }
                        if (hr.time === time.timeEnd) {
                          endingTime = hr.display;
                        }
                      });
                      return (
                        <DivStyles flexDirection='column' key={time.timeStart}>
                          <TextStyles color='#8f8d8d' weight='500'>
                            {startingTime} - {endingTime}
                          </TextStyles>
                          <DivStyles margin='0 0 0 10px'>
                            <span>{strings.rate}</span>
                            <span className='Grey-color'>
                              {time.rateType !== 'free'
                                ? `$${endingZeroOnMoney(
                                    time.rate,
                                    time.rateType
                                  )} / ${time.rateType}`
                                : strings.free}
                            </span>
                          </DivStyles>
                          <DivStyles margin='0 0 0 10px'>
                            <span>{strings.idleFee}</span>
                            <span className='Grey-color'>
                              {time.idleRateType !== 'free'
                                ? `$${endingZeroOnMoney(
                                    time.idleRate,
                                    time.idleRateType
                                  )} / ${time.idleRateType}`
                                : strings.free}
                            </span>
                          </DivStyles>
                          <DivStyles margin='0 0 0 10px'>
                            <span>{strings.idleFee}</span>
                            <span className='Grey-color'>
                              {time.idleRateType !== 'free'
                                ? `$${endingZeroOnMoney(
                                    time.minAmount,
                                    ''
                                  )}`
                                : strings.free}
                            </span>
                          </DivStyles>
                          <DivStyles margin='0 0 0 10px'>
                            <span>{strings.grace}</span>
                            <span className='Grey-color'>
                              {time.idleGracePeriod === 0
                                ? 'none'
                                : timeConvert(time.idleGracePeriod)}
                            </span>
                          </DivStyles>
                          {/* Max Session Time */}
                          <DivStyles margin='0 0 0 10px'>
                            <span>{'Max Session Time:'}</span>
                            <span>
                              {time.maxTime === 0 ||
                              time.maxTime === undefined
                                ? 'unlimited'
                                : timeConvert(time.maxTime)}
                            </span>
                          </DivStyles>
                        </DivStyles>
                      );
                    })}
                  </ModalItemStyles>
                );
              })}
            {/* Button Component for Modals*/}
          </ModalContentStyles>
          <ModalButton
            containerMargin='10px 10px 0'
            containerAlign='right'
            buttonPadding='10px'
            buttonMargin='0'
            handleClick={closeModal}
            buttonText={strings.ok}
          />
        </ModalContainerStyles>
      </ModalStyles>
    );
  }

  if (props.discountSx && props.discountIds.length > 0) {
    return (
      <ModalStyles>
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{props.title}</h2>
            <FaRegWindowClose className='Close' onClick={closeModal} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            <ModalItemStyles maxHeight='500px' borderBottom>
              {discounts.map((coupon) => {
                console.log(coupon);
                console.log(discounts);
                return (
                  <Fragment key={coupon.id}>
                    <ModalItemStyles
                      align='left'
                      padding='0'
                      className='Eye-modal-header'>
                      <p>{coupon.name}</p>
                    </ModalItemStyles>
                    <ModalItemStyles
                      align='left'
                      className='Eye-time-table'
                      padding='0'>
                      <DivStyles>
                        <span>{strings.discount}</span>
                        <span>{coupon.discountPercentage}%</span>
                      </DivStyles>
                      {coupon.coupons.map((coup, index) => {
                        return (
                          <Fragment key={coup.id}>
                            <DivStyles margin='5px 0 3px'>
                              <span>{strings.couponCode}</span>
                              <span className='Grey-color'>{coup.token}</span>
                            </DivStyles>
                            <DivStyles margin='0 0 3px'>
                              <span>{strings.couponUsageType}</span>
                              <span className='Grey-color'>
                                {coup.usesRemaining <= -1
                                  ? 'Unlimited'
                                  : '# of Uses'}
                              </span>
                            </DivStyles>
                            <DivStyles margin='0 0 3px'>
                              <span>{strings.couponUses}</span>
                              <span className='Grey-color'>
                                {coup.usesRemaining <= -1
                                  ? '--'
                                  : coup.usesRemaining}
                              </span>
                            </DivStyles>
                            <DivStyles
                              borderBottom={index !== coupon.coupons.length - 1}
                              padding='0 0 5px'>
                              <span>{strings.couponsExpires}</span>
                              <span className='Grey-color'>
                                {moment(coup.expiration).format('MM/DD/YY') !==
                                '01/01/01'
                                  ? moment(coup.expiration).format('MM/DD/YY')
                                  : 'Never'}
                              </span>
                            </DivStyles>
                          </Fragment>
                        );
                      })}
                      {/* ====== Map over coupons ========= */}
                      {coupon.coupons.length === 0 && (
                        <DivStyles>
                          <span>{strings.noCoupons}</span>
                        </DivStyles>
                      )}
                    </ModalItemStyles>
                    {/* =========End Map =========== */}
                  </Fragment>
                );
              })}
            </ModalItemStyles>
            {/* Note Section */}
            <ModalNoteStyles margin='10px 0'>
              <strong>{strings.note}</strong> {strings.couponNote}
            </ModalNoteStyles>
          </ModalContentStyles>
          {/* Button Component for Modals*/}
          <ModalButton
            containerMargin='10px 10px 0'
            containerAlign='right'
            buttonPadding='10px'
            buttonMargin='0'
            handleClick={closeModal}
            buttonText={strings.ok}
          />
        </ModalContainerStyles>
      </ModalStyles>
    );
  }

  if (props.activeMemberships && props.activeMemberships.length > 0) {
    return (
      <ModalStyles>
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{props.title}</h2>
            <FaRegWindowClose className='Close' onClick={closeModal} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            <ModalItemStyles maxHeight='500px' borderBottom>
              {props.allMemberships
                .filter((membership) =>
                  props.activeMemberships.includes(membership.id)
                )
                .map((membership) => {
                  return (
                    <Fragment key={membership.id}>
                      <ModalItemStyles
                        align='left'
                        padding='0'
                        className='Eye-modal-header'>
                        <p>{membership.membershipName}</p>
                      </ModalItemStyles>
                      <ModalItemStyles
                        align='left'
                        className='Eye-time-table'
                        padding='0'>
                        <DivStyles>
                          <span>Membership Code</span>
                          <span>{membership.membershipCode}</span>
                        </DivStyles>
                        <DivStyles>
                          <span>{strings.discount}</span>
                          <span>
                            {discounts
                              .filter((discount) => {
                                console.log(discount, membership);
                                return (
                                  membership.discountScheduleId === discount.id
                                );
                              })
                              .map((discount) => {
                                return discount.discountPercentage;
                              })}
                            %
                          </span>
                        </DivStyles>
                        <DivStyles>
                          <span>Location</span>
                          <span>{membership.locationDescription}</span>
                        </DivStyles>
                      </ModalItemStyles>
                      {/* =========End Map =========== */}
                    </Fragment>
                  );
                })}
            </ModalItemStyles>
          </ModalContentStyles>
          {/* Button Component for Modals*/}
          <ModalButton
            containerMargin='10px 10px 0'
            containerAlign='right'
            buttonPadding='10px'
            buttonMargin='0'
            handleClick={closeModal}
            buttonText={strings.ok}
          />
        </ModalContainerStyles>
      </ModalStyles>
    );
  }

  return <InvalidOption closeModal={closeModal} />;
};

export default withRouter(EyeModal);
