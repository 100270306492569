import React from 'react';

//styles
import {
  DivStyles,
  ImageContainerStyles,
  ImageMediumStyles,
  TextStyles,
} from '../../../SharedStyles';

const Description = (props) => {
  return (
    <DivStyles padding='10px' borderBottom={props.borderBottom}>
      <TextStyles size={props.fontSize}>{props.message}</TextStyles>
      {props.noteSection && (
        <TextStyles size={props.fontSize} margin={props.noteSection.margin}>
          <strong>{props.noteSection.note}</strong> {props.noteSection.message}
        </TextStyles>
      )}
      {props.image && (
        <ImageContainerStyles align='center' height='auto'>
          <ImageMediumStyles
            width='120px'
            margin='10px 0 5px'
            src={props.image}
            alt={props.message}
          />
        </ImageContainerStyles>
      )}
    </DivStyles>
  );
};

export default Description;
