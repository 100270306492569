import React from 'react';

//services
import AppNotificationsService from '../../Notifications/AppNotificationsService';

//components
import ToggleSwitch from '../../shared/ToggleSwitch';
import { DivStyles, TextStyles } from '../../../SharedStyles';

const AppFilterMap = (props) => {
  const { strings } = AppNotificationsService;
  const { filters } = props;

  const handleChange = (e) => {
    props.handleFilterUpdate([e.target.name], e.target.checked);
  };

  return (
    <DivStyles
      dayBackground='#fff'
      border='1px ssolid #e0e0e0'
      position='absolute'
      align='left'
      margin='0 auto'
      width='75%'
      zIndex='11'
      top={`${props.top}px`}
      left='0'
      right='0'
      size='14px'
      padding='10px'>
      <DivStyles margin='0 0 10px'>
        <TextStyles weight='600' margin='0 0 15px'>
          {strings.filterAvailability}
        </TextStyles>
        <ToggleSwitch
          padding='0'
          checked={!!filters.chargerConnectedStatus}
          name='chargerConnectedStatus'
          handleChange={handleChange}
          label={strings.filterAvailable}
        />
      </DivStyles>
      {/* <DivStyles margin='0 0 10px'>
          <TextStyles weight='600' margin='0 0 15px'>{strings.filterAccessType}</TextStyles>
          <ToggleSwitch
            checked={!!filters.public}
            name='public'
            handleChange={handleChange}
            label={strings.filterPublic}
          />
          <ToggleSwitch
            checked={!!filters.member}
            name='member'
            handleChange={handleChange}
            label={strings.filterMember}
          />
        </DivStyles> */}
      <DivStyles margin='0 0 10px'>
        <TextStyles weight='600' margin='0 0 15px'>
          {strings.filterConnectorType}
        </TextStyles>
        <ToggleSwitch
          padding='0'
          checked={!!filters.j1772}
          name='j1772'
          handleChange={handleChange}
          label={strings.filterJ1772}
        />
        <ToggleSwitch
          padding='0'
          checked={!!filters.quickChargeCHAdeMO}
          name='quickChargeCHAdeMO'
          handleChange={handleChange}
          label={strings.filterChademo}
        />
        <ToggleSwitch
          padding='0'
          checked={!!filters.quickChargeCCSSAE}
          name='quickChargeCCSSAE'
          handleChange={handleChange}
          label={strings.filterCCS}
        />
      </DivStyles>
    </DivStyles>
  );
};

export default AppFilterMap;
