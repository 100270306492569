import React from 'react';

//services
import AppNavSectionService from '../../NavSection/AppNavSectionService';

//components
import Logo from '../../NavSection/Logo/Logo';

//styles
import { NavBarContainerStyles, ButtonDivStyles } from '../../../SharedStyles';

const NewNav = (props) => {
  const logout = () => {
    AppNavSectionService.logout();
  };

  return (
    <header>
      <NavBarContainerStyles alignItems='center'>
        <div className='Left-Align'>
          <Logo />
        </div>
        {!props.hideLogin && (
          <ButtonDivStyles inverse onClick={logout}>
            Sign In
          </ButtonDivStyles>
        )}
      </NavBarContainerStyles>
    </header>
  );
};

export default NewNav;
