import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import AppApiService from '../../../services/AppApi/AppApiService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppStatementsService = {
  getStatements: function () {
    return AppApiService.get('getStatements');
  },
  getStatementsById: function (id) {
    return AppApiService.post('getStatementsById', null, id);
  },
  strings: localStrings,
};

export default AppStatementsService;
