import React, { createContext, useState } from 'react';

import ErrorModal from '../modals/ErrorModal';

export const ErrorContext = createContext();

const Errors = props => {
  const [error, setError] = useState({
    display: false,
    title: '',
    message: '',
    styles: ''
  });

  const closeError = () => {
    setError({ display: false, title: '', message: '', styles: '' });
  };

  return (
    <ErrorContext.Provider
      value={{
        error,
        setError
      }}>
      {error.display && (
        <ErrorModal
          message={error.message}
          title={error.title}
          closeError={closeError}
          styles={error.styles}
        />
      )}
      {props.children}
    </ErrorContext.Provider>
  );
};

export default Errors;
