import React from 'react';
import { ListPlaceholderStyles } from '../../../../SharedStyles';

//components
import CardItem from './CardItem';

const CardList = ({ cards, loading, handleRemoveAccessCard }) => {
  if (
    !loading &&
    (!cards.length ||
      cards.every(
        (c) => !c.activated && c.activatedDate !== '0001-01-01T00:00:00'
      ))
  ) {
    return <ListPlaceholderStyles>No Access Cards</ListPlaceholderStyles>;
  }
  return cards.map((c, i) => {
    if (
      c.activated ||
      (!c.activated && c.activatedDate === '0001-01-01T00:00:00')
    ) {
      return (
        <CardItem
          key={i}
          borderBottom={i !== cards.length - 1}
          card={c}
          handleRemoveAccessCard={handleRemoveAccessCard}
        />
      );
    }
    return null;
  });
};

export default CardList;
