import React, { useContext, useState } from 'react';

//services
import AppPricingSchedulesService from '../AppPricingSchedulesService';

//Components
import PricingSchedulesCard from './PricingSchedulesCard';

//global components
import { LoadingSpinner } from '../../../shared';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import { ErrorContext } from '../../../context/shared/ErrorContext';

//styles
import { ListPlaceholderStyles } from '../../../SharedStyles';

//images
import chargerPlaceholderImage from '../../../../assets/images/ChargingStationPlaceholder400.png';

const ChargingProfileList = (props) => {
  const { strings } = AppPricingSchedulesService;
  const { account, setAccount } = useContext(CsoAccountContext);
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(false);

  const updateAccount = (csoAccount) => {
    setAccount(csoAccount);
  };

  const deleteProfile = async (profileId) => {
    try {
      setLoading(true);
      let removedProfile = await AppPricingSchedulesService.deletePricingSchedule(
        profileId
      );
      updateAccount(removedProfile);

      setLoading(false);
    }
    catch (err){
      setLoading(false);
      setError({
        display: true,
        title: strings.cantDeleteTitle,
        message: strings.cantDeleteTitleMsg1 
                + err.response.data.split(/[|]+/).pop() 
                + strings.cantDeleteTitleMsg2 ,
        styles: 'cso',
      });
    }
  };

  if (account.pricingSchedules.length === 0) {
    return (
      <ListPlaceholderStyles borderBottom='none'>
        <img src={chargerPlaceholderImage} alt='No Vehicles' />
      </ListPlaceholderStyles>
    );
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {account &&
        account.pricingSchedules.map((profile) => {
          return (
            <PricingSchedulesCard
              deleteProfile={deleteProfile}
              key={profile.id}
              profile={profile}
              updateAccount={updateAccount}
            />
          );
        })}
    </>
  );
};

export default ChargingProfileList;
