import React, { useState, useContext, useEffect } from 'react';

//layout
import { OuterWrapper, ComponentWrapper } from '../../../OperatorShared';

//services
import AppMembershipsService from '../../AppMembershipsService';

//components
import MembersList from './MembersList';
import MembershipHeader from '../../MembershipsForms/MembershipHeader';

//global components
import SearchFilterBar from '../../../OperatorShared/SearchFilterBar';
import LoadingSpinner from '../../../../shared/Loading/LoadingSpinner';
import SubscriptionAccess from '../../../OperatorShared/SubscriptionAccess';
import AccessDeniedComponent from '../../../../shared/AppAccessDenied/AccessDeniedComponent';

//context
import { CsoMembershipContext } from '../../../../context/operator/getCsoMembership';
import { CsoAccountContext } from '../../../../context/operator/getCsoAccount';

const Members = (props) => {
  const { strings } = AppMembershipsService;
  const { allMemberships, loading, fetchMemberships } = useContext(
    CsoMembershipContext
  );
  const { account, currentUser } = useContext(CsoAccountContext);
  const [membership, setMembership] = useState({});
  const [searchedValue, setSearchedValue] = useState('');
  const [filteredValue, setFilteredValue] = useState(null);
  const [submitSearch, setSubmitSearch] = useState(false);

  useEffect(() => {
    fetchMemberships();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let membership = allMemberships.filter((ship) => {
      return ship.id === props.match.params.id;
    });
    if (membership.length !== 0) {
      setMembership(membership[0]);
    } else {
    }
  }, [allMemberships, props.match.params.id]);

  const handleFilterChange = (filter) => {
    setSubmitSearch(false);
    setFilteredValue(filter);
  };

  const handleSearchChange = (value) => {
    setSubmitSearch(false);
    setSearchedValue(value);
  };

  const handleSearchSubmit = (submit) => {
    setSubmitSearch(submit);
  };

  return (
    <OuterWrapper
      title={strings.membersTitle}
      internal
      path='/subscription/memberships'>
      {loading && <LoadingSpinner />}
      {/*<SmallBizAdmin smallBiz allowCustomer>*/}
      <SubscriptionAccess levels={[1, 2, 3]}>
      {(account &&
          (account.subscriptionLevel === 3 &&
            currentUser &&
            currentUser.role !== 3)) ||
        (account && account.subscriptionLevel === 2) ||
        (account && account.subscriptionLevel === 1) ? (
          <>
        <ComponentWrapper title={strings.membersTitle}>
          {Object.keys(membership).length > 0 && (
            <MembershipHeader strings={strings} membership={membership} />
          )}
          <SearchFilterBar
            membersFilter
            filterBy={strings.membersFilter}
            handleSearchSubmit={handleSearchSubmit}
            handleSearchChange={handleSearchChange}
            handleFilterChange={handleFilterChange}
          />
          <MembersList
            membership={membership}
            filteredValue={submitSearch ? filteredValue : ''}
            searchedValue={submitSearch ? searchedValue : ''}
          />
        </ComponentWrapper>
        </>
        ) : 
          <AccessDeniedComponent
            smallBiz
            permissionIssue={
              account.subscriptionLevel === 3 &&
              currentUser &&
              currentUser.role !== 3
            }
          />
        }
      </SubscriptionAccess>
      {/*</SmallBizAdmin>*/}
    </OuterWrapper>
  );
};

export default Members;
