import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import strings from './strings';
import AppApiService from '../../../services/AppApi/AppApiService';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppBankService = {
  getCSOStripeAccounts: function(id) {
    return AppApiService.post('getCSOStripeAccounts', null, id);
  },
  setDefaultPayment: function(id) {
    return AppApiService.post('setDefaultPayment', null, id);
  },
  setDefaultPayout: function(id) {
    return AppApiService.post('setDefaultPayout', null, id);
  },
  //payout
  getPayoutInfo: function() {
    return AppApiService.get('getPayoutInfo');
  },
  createPayoutAccount: function(tokenId) {
    return AppApiService.post('createPayoutAccount', null, tokenId);
  },
  deletePayoutInfo: function(id) {
    return AppApiService.post('deletePayoutInfo', null, id);
  },
  validatePayoutInfo: function(urls) {
    return AppApiService.post('validatePayoutInfo', null, urls);
  },
  //customer
  getCSOPaymentInfo: function() {
    return AppApiService.get('getCSOPaymentInfo');
  },
  createCSOPaymentInfo: function(tokenId) {
    return AppApiService.post('createCSOPaymentInfo', null, tokenId);
  },
  deleteCSOPaymentInfo: function(id) {
    return AppApiService.post('deleteCSOPaymentInfo', null, id);
  },
  validateCSOPaymentInfo: function(amounts) {
    return AppApiService.post('validateCSOPaymentInfo', null, amounts);
  },
  strings: localStrings
};

export default AppBankService;
