import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppDiscountSchedulesService from '../AppDiscountSchedulesService';

//global component
import ModalButton from '../../../shared/Buttons/ModalButton';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
} from '../../../SharedStyles';

const DeleteModal = (props) => {
  const { strings } = AppDiscountSchedulesService;

  const closeModal = () => {
    props.handleClose();
  };

  const handleSaveInfo = (e) => {
    e.preventDefault();
    props.deleteProfile(props.profile.id);
    props.handleClose();
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.deleteTitle}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles>
            <p>
              {strings.deleteContentOne} {props.profile.name}{' '}
              {strings.deleteContentTwo}
            </p>
          </ModalItemStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={handleSaveInfo}
          buttonText={strings.deleteButton}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default DeleteModal;
