import j1772 from '../../assets/images/resized-connectors/J1772.png';
import ccs1 from '../../assets/images/resized-connectors/CCS1.png';
import ccs2 from '../../assets/images/resized-connectors/CCS2.png';
import gbtac from '../../assets/images/resized-connectors/GBT_AC.png';
import gbtdc from '../../assets/images/resized-connectors/GBT_DC.png';
import mennekes from '../../assets/images/resized-connectors/Mennekes.png';
import nema6 from '../../assets/images/resized-connectors/NEMA6_50.png';
import nema14 from '../../assets/images/resized-connectors/NEMA14_50.png';
import chademo from '../../assets/images/resized-connectors/CHAdeMO.png';
import tesla from '../../assets/images/resized-connectors/Tesla.png';

/**
 * Creates a random id
 */
export function uuid() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

/**
 * Takes a measurement set on driver object, and a miles count and converts to kM or Miles based on the settings.
 * @param {*} measurement {String} - measurement from Driver Object
 * @param {*} miles {String | Number} - Miles from the Api value.
 */
export function milesToKm(measurement, miles) {
  //admin users
  if (!measurement) {
    if (miles === '--') {
      return '-- mi';
    }
    return `${parseFloat(miles).toFixed(0)} mi`;
  }
  if (measurement === 'Miles') {
    if (miles === '--') {
      return '-- mi';
    }
    return `${parseFloat(miles).toFixed(0)} mi`;
  } else {
    if (miles === '--') {
      return '-- km';
    }
    return `${(parseFloat(miles) * 1.60934).toFixed(0)} km`;
  }
}

/**
 * Converts Ft from google Api to Yds
 * @param {*} miles - {String | Number} - ft from googles api from distance,
 */
export function feetToKm(miles) {
  return `${(parseInt(miles) / 3).toFixed(2)} yd`;
}

/**
 * splits the distance string provided to allow converting from miles to km
 * @param {*} unitOfMeasurement {String} - measurement from Driver Object
 * @param {*} distance {String} - String value from api for distance - charging station and driver charger objects
 */
export const splitDistance = (unitOfMeasurement, distance) => {
  if (distance === '') return;
  let dist = distance.split(' ');
  let removeMi = dist.filter((i) => i !== 'mi');
  let values = removeMi[0].split(',');
  let newDist = values.reduce((a, b) => a + b);

  if (dist[1] === 'ft') {
    if (unitOfMeasurement !== 'Miles') {
      return `${feetToKm(newDist)} -`;
    } else {
      return `${newDist} ft -`;
    }
  } else {
    return `${milesToKm(unitOfMeasurement, newDist)} -`;
  }
};

/**
 * Converts the duration from googles api to a shorthand time
 * @param {*} duration - {String} - duration from google api call
 */
export const converHoursAndMins = (duration) => {
  let durh = duration.replace('hour', 'hr');
  let durm = durh.replace('minute', 'min');
  let durs = durm.replace('second', 'sec');
  return durs;
};

/**
 * Calculates the mi/hr on the driver charger giving the average miles charger per kwh per station
 * @param {*} vehicle - Drivers default vehicle
 * @param {*} charger - Current Charging Station viewed
 * @param {*} measurement - Drivers unitofmeasurement from object.
 */
export const calculateMilesKwh = (vehicle, charger, measurement) => {
  if (!vehicle || !charger || !measurement) {
    return '--';
  }
  let range = parseInt(vehicle.allElectricRange);
  let battery = vehicle.batteryCapacity;
  let power = charger.power;
  if (measurement === 'Miles') {
    return ((range / battery) * power).toFixed(0);
  } else {
    return ((range / battery) * power * 1.60934).toFixed(0);
  }
};

/**
 * Takes in mins and converts it to a more user friendly format such as 1hr 20mins
 * @param {*} mins - Grace period Mins from the pricing schedule
 */
export const timeConvert = (mins, nospace, onlyDidigts) => {
  if (mins === 0 || mins === undefined) {
    return 'None';
  }
  let hours = mins / 60;
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);

  if (onlyDidigts) {
    if (rhours >= 1) {
      return `${rhours}:${rminutes}`;
    } else {
      return `${rminutes}`;
    }
  }

  if (!nospace) {
    if (rhours >= 1) {
      return `${rhours} ${rhours > 1 ? 'hrs' : 'hr'} ${rminutes} ${
        rminutes > 1 ? 'mins' : 'min'
      }`;
    } else {
      return `${rminutes} ${rminutes > 1 ? 'mins' : 'min'}`;
    }
  } else {
    if (rhours >= 1) {
      return `${rhours}h ${rminutes}m`;
    } else {
      return `${rminutes}m`;
    }
  }
};

export const endingZeroOnMoney = (money, rateType) => {
  if (!money) {
    return 0;
  }
  switch (rateType) {
    // case 'kWh':
    //   if (money % 1 !== 0) {
    //     money = money.toFixed(3);
    //   }
    //   return money;
    default:
      if (money % 1 !== 0) {
        money = Number(money).toFixed(2);
      }
      return money;
  }
};

/**
 * Takes in the status to display a color for the status symbol on the charging stations
 * @param {*} status Charging Station Status from Backend API
 */
export const getChargerStatusColor = (status) => {
  switch (status) {
    case 'Off-line':
      return 'lightgrey';
    case 'Disabled':
      return 'grey';
    case 'Trouble':
      return 'red';
    case 'In-use':
    case 'Charging':
      return 'orange';
    case 'Connected':
      return '#4f8ef5';
    case 'Available':
      return '#07e019';
    default:
      return 'lightgrey';
  }
};

/**
 * Array of images and names, that will display a image based on the incoming connector type from the object.
 */
export const chargerConnectorObj = [
  {
    type: 'j1772',
    image: j1772,
  },
  {
    type: 'ccs1',
    image: ccs1,
  },
  {
    type: 'ccs2',
    image: ccs2,
  },
  {
    type: 'chademo',
    image: chademo,
  },
  {
    type: 'tesla',
    image: tesla,
  },
  {
    type: 'gbt_ac',
    image: gbtac,
  },
  {
    type: 'gbt_dc',
    image: gbtdc,
  },
  {
    type: 'mennekes',
    image: mennekes,
  },
  {
    type: 'nema6_50',
    image: nema6,
  },
  {
    type: 'nema14_50',
    image: nema14,
  },
];
