import React from 'react';

//styles
import { JumboTronHeaderStyles } from '../../../../SharedStyles';

import DEFAULT_MEMBERSHIP_IMAGE from '../../../../../assets/images/MembershipsPlaceholder.png';

const MembershipHeader = (props) => {
  const { membership } = props;

  return (
    <JumboTronHeaderStyles>
      <div className='Image'>
        <img
          src={
            membership.imagePath
              ? membership.imagePath
              : DEFAULT_MEMBERSHIP_IMAGE
          }
          alt={membership.membershipName}
        />
      </div>
      <div className='Content'>
        <h3>{membership.membershipName}</h3>
        <p>{membership.applicationMessage}</p>
      </div>
    </JumboTronHeaderStyles>
  );
};

export default MembershipHeader;
