export const headerEnterpriseSession = {
  Date: 'transactionDate',
  Time: 'time',
  'Charger ID': 'iotHubDeviceId',
  'Tot Time': 'totalTime',
  'Avg kW': 'avgkW',
  KWhs: 'totalkWh',
  'Rate Type': 'rateType',
  Rate: 'rate',
  Amt: 'totalCost',
  'Idle Rate Type': 'idleRateType',
  'Idle Rate': 'idleRate',
  'Idle Time': 'idleTime',
  'Idle Amt': 'idleCost',
};

export const headerSession = {
  Date: 'transactionDate',
  Time: 'time',
  'Charger ID': 'iotHubDeviceId',
  'Tot Time': 'totalTime',
  'Avg kW': 'avgkW',
  KWhs: 'totalkWh',
  'Rate Type': 'rateType',
  Rate: 'rate',
  Amt: 'totalCost',
};

export const headerFees = {
  Date: 'date',
  'Fee Type': 'feeType',
  'Charger ID': 'iotHubDeviceId',
  Ports: 'ports',
  Rate: 'rate',
  Amt: 'amount',
};

export const headerIncome = {
  'Post Date': 'postDate',
  'Bank Name': 'bankName',
  'Bank Routing No': 'bankRoutingNo',
  'Account No': 'accountNo',
  'Trans ID': 'transId',
  Amt: 'amt',
};
