import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import AppApiService from '../../../services/AppApi/AppApiService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const NavSectionService = {
  createSystemUserToAccount: function(token) {
    return AppApiService.post('csoCreateSystemUserToAccount', null, token);
  },
  strings: localStrings
};

export default NavSectionService;
