import React, { useState, useEffect, useContext, Fragment } from 'react';

//layout
import { OuterWrapper, ComponentWrapper } from '../OperatorShared';
import Button from '../../shared/Buttons/Button';

//services
import AppSubscriptionService from './AppSubscriptionService';
import AppBankService from '../Bank/AppBankService';

//components
import CsoToggleSwitch from '../OperatorShared/ToggleSwitch/CsoToggleSwitch';
import SubscriptionSettings from './SubscriptionSettings';
import IncompleteAccount from './IncompleteAccount';

//global components
import { LoadingSpinner } from '../../shared';

//styles
import {
  RowStyles,
  FormContainerStyles,
  ImageThumbnailStyles,
  TitleStyles,
  DivStyles,
  CenterStyles,
} from '../../SharedStyles';
import { EnableContainerStyles } from '../../OperatorStyles';

//context
import { CsoAccountContext } from '../../context/operator/getCsoAccount';

//helpers
import { plans } from './subscriptionPlans';

const Subscription = (props) => {
  const { strings } = AppSubscriptionService;
  const { account, updatingAccount } = useContext(CsoAccountContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [stripeAccount, setStripeAccount] = useState(null);
  const [agreeTerms, setAgreeTerms] = useState(false);

  useEffect(() => {
    const fetchStripe = async () => {
      try {
        let res = await AppBankService.getCSOStripeAccounts();
        setStripeAccount(res);
        if (res) {
          let updatedPlans = plans.map((plan) => {
            if (plan.name.toString() === res.subscriptionPlan) {
              plan.enabled = true;
            } else {
              plan.enabled = false;
            }
            return plan;
          });
          setData(updatedPlans);
          setLoading(false);
        }
      } catch (err) {
        setData(plans);
        setLoading(false);
      }
    };
    fetchStripe();
  }, []);

  useEffect(() => {
    let dataChecked = data.some((d) => {
      return d.enabled;
    });
    if (dataChecked) {
      setDisabled(!agreeTerms);
    } else {
      setDisabled(true);
    }
  }, [data, agreeTerms]);

  const handleToggles = (e, name) => {
    let updatedValues = data.map((plan) => {
      if (plan.name === name) {
        plan.enabled = e.target.checked;
      } else {
        plan.enabled = false;
      }
      return plan;
    });
    setData(updatedValues);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let subPlan = data.filter((p) => {
      return p.enabled;
    });
    if (
      (stripeAccount &&
        stripeAccount.payoutVerified &&
        stripeAccount.paymentVerified) ||
      (stripeAccount &&
        stripeAccount.payoutVerified &&
        stripeAccount.csoStripeDefaultPaymentMethodId &&
        stripeAccount.csoStripeDefaultPaymentMethodId.includes('card'))
    ) {
      let userAccount = account;
      userAccount.subscriptionLevel = subPlan[0].name;
      let updatedStripeAccount = stripeAccount;
      updatedStripeAccount.subscriptionPlan = subPlan[0].name;
      await AppSubscriptionService.updateCSOStripeAccount(updatedStripeAccount);
      let response = await updatingAccount(userAccount);
      if (response) {
        props.history.push('/bank');
      }
    } else {
      let response = await AppSubscriptionService.createCSOStripeAccounts(
        subPlan[0].name
      );
      if (response) {
        props.history.push('/bank');
      }
    }
  };

  if (
    (stripeAccount &&
      !stripeAccount.paymentVerified &&
      !stripeAccount.payoutVerified) ||
    (stripeAccount &&
      !stripeAccount.payoutVerified &&
      !stripeAccount.csoStripeDefaultPaymentMethodId.startsWith('card'))
  ) {
    return <IncompleteAccount strings={strings} />;
  }
  if (account && account.subscriptionLevel !== 0 && !loading) {
    return <SubscriptionSettings account={account} plans={plans} />;
  }

  return (
    <OuterWrapper
      title={strings.pageTitle}
      internal
      subscribe={strings.buttonText}
      myForm
      path='/'
      disabled={disabled}
      reducePadding
      roles={[1]}>
      {loading && <LoadingSpinner />}
      <ComponentWrapper title={strings.pageTitle}>
        <FormContainerStyles padding='0'>
          <form id='myForm' onSubmit={handleSubmit}>
            {data.map((plan, index) => (
              <Fragment key={plan.title}>
                <EnableContainerStyles headerPadding='0'>
                  <DivStyles display='flex' alignItems='center'>
                    <ImageThumbnailStyles
                      maxWidth='90px'
                      margin='0 10px 0 0'
                      src={plan.image}
                      alt={plan.title}
                    />
                    <TitleStyles size='18px' margin='0' weight='500'>
                      {plan.title}
                    </TitleStyles>
                  </DivStyles>
                  <CsoToggleSwitch
                    name={plan.name}
                    checked={!!plan.enabled}
                    handleChange={handleToggles}
                  />
                </EnableContainerStyles>
                <RowStyles
                  align='center'
                  borderBottom={index === data.length - 1 && 'none'}
                  display='block'>
                  <p>{plan.fee}</p>
                  <p>{plan.content}</p>
                </RowStyles>
              </Fragment>
            ))}
          </form>
        </FormContainerStyles>
      </ComponentWrapper>
      <CenterStyles>
        <CsoToggleSwitch 
          name='agreeTerms'
          checked={agreeTerms}
          handleChange={()=> setAgreeTerms(!agreeTerms)}
          label=<a href={strings.agreeTermsUrl}>&nbsp;{strings.agreeTermsText}</a>
        />
      </CenterStyles>
      <Button
        cso
        hasMobile
        formSubmit
        disabled={disabled}
        roles={[1]}
        buttonText={strings.buttonText}
      />
    </OuterWrapper>
  );
};

export default Subscription;
