import React, { createContext, useState } from 'react';
import { withRouter } from 'react-router-dom';

export const CsoChargerContext = createContext();

const CsoCharger = props => {
  const [charger, setCharger] = useState(null);

  return (
    <CsoChargerContext.Provider
      value={{
        charger,
        setCharger
      }}>
      {props.children}
    </CsoChargerContext.Provider>
  );
};

export default withRouter(CsoCharger);
