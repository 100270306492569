import React from 'react';

const LoadingSpinner = () => {
  return (
    <>
      <div className='show-spinner'>
        <div className='loadingSpinner' />
      </div>
    </>
  );
};

export default LoadingSpinner;
