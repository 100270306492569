import AppAuthService from '../../services/AppAuth/AppAuthService';
import AppPlatformService from '../../services/AppPlatform/AppPlatformService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppLandingService = {
  strings: localStrings,
  onContinueClick: function () {
    AppAuthService.showSignIn(() => {
      window.location.reload();
    });
  },
};

export default AppLandingService;
