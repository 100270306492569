import React, { useContext } from 'react';
import moment from 'moment';

//global components
import { SubscriptionAccess } from '../../../OperatorShared';

//styles
import { TableRowStyles, TableItemStyles } from '../../../../SharedStyles';

//helpers
import {
  endingZeroOnMoney,
  timeConvert,
  milesToKm,
} from '../../../../shared/Helpers';

//context
import { CsoAccountContext } from '../../../../context/operator/getCsoAccount';

const ChargersCard = (props) => {
  const { currentUser } = useContext(CsoAccountContext);
  return (
    <TableRowStyles
      hoverBackground='#f1f7ff'
      borderTop='none'
      borderLeft='none'
      borderRight='none'
      align='center'
      width='100%'
      minWidth={props.page === 'chargerspage' ? '778px' : '1160px'}>
      <TableItemStyles minWidth='80px' width='100%'>
        {moment(props.transactionDate).format('MM/DD/YY h:mma')}
      </TableItemStyles>
      <TableItemStyles width='100%' maxWidth='70px'>
        {props.transactionId}
      </TableItemStyles>
      <TableItemStyles width='100%' title={props.userId}>
        {(new String(props.userId)).substr(0, 8)}...
      </TableItemStyles>
      {props.charger && (
        <TableItemStyles width='100%' minWidth='80px'>
          {props.charger}
        </TableItemStyles>
      )}
      {props.location && (
        <TableItemStyles width='100%' minWidth='90px'>
          {props.location}
        </TableItemStyles>
      )}
      <TableItemStyles width='100%' maxWidth='auto'>
        {timeConvert(props.totalTime, true)}
      </TableItemStyles>
      <TableItemStyles width='100%' maxWidth='auto'>
        {props.avgkW.toFixed(2)}
      </TableItemStyles>
      <TableItemStyles width='100%' maxWidth='auto'>
        {props.totalkWh.toFixed(2)}
      </TableItemStyles>
      <TableItemStyles width='100%' maxWidth='auto'>
        {milesToKm(currentUser.unitOfMeasurement, props.milesAdded.toFixed(2))}
      </TableItemStyles>
      <TableItemStyles width='100%' maxWidth='auto'>
        {props.rateType === 'Owned' ? 'utility' : props.rateType}
      </TableItemStyles>
      <TableItemStyles width='100%' maxWidth='auto'>
        ${endingZeroOnMoney(props.rate, props.rateType)}
      </TableItemStyles>
      <TableItemStyles width='100%' maxWidth='auto'>
        ${props.totalCost}
      </TableItemStyles>
      <SubscriptionAccess levels={[3]}>
        <TableItemStyles width='100%' maxWidth='75px'>
          {props.idleRateType}
        </TableItemStyles>
        <TableItemStyles width='100%' maxWidth='75px'>
          ${endingZeroOnMoney(props.idleRate, props.idleRateType)}
        </TableItemStyles>
        <TableItemStyles width='100%' maxWidth='75px'>
          {timeConvert(props.idleTime, true)}
        </TableItemStyles>
        <TableItemStyles width='100%' maxWidth='75px'>
          ${endingZeroOnMoney(props.idleCost, props.idleRateType)}
        </TableItemStyles>
      </SubscriptionAccess>
    </TableRowStyles>
  );
};

export default ChargersCard;
