import React, { useState, useEffect } from 'react';
import { FaAddressCard } from 'react-icons/fa';

//layout
import { ComponentWrapper, InteriorWrapper } from '../../shared';
import { LoadingSpinner } from '../../../shared';

//service
import AccessCardsService from '../AccessCardsService';

//components
import Button from '../../../shared/Buttons/Button';
import Description from '../Shared/Description';

//styles
import { ButtonContainerStyles } from '../../../SharedStyles';

//image
import rfidImage from '../../../../assets/images/nfc-rfid-image.png';
import CardList from '../Components/AccessCard/CardList';

const AccessCards = (props) => {
  const { strings } = AccessCardsService;
  const [loading, setLoading] = useState(true);
  const [accessCards, setAccessCards] = useState([]);

  useEffect(() => {
    const fetchAccessCards = async () => {
      let res = await AccessCardsService.getAccessCards();
      setAccessCards(res);
    };
    fetchAccessCards();
    setLoading(false);
  }, []);

  const handleRemoveAccessCard = (card) => {
    let updatedCards = accessCards.filter((c) => c.id !== card.id);
    setAccessCards(updatedCards);
  };

  return (
    <InteriorWrapper title={strings.title} isInternal path='/charging'>
      {loading && <LoadingSpinner />}
      <ComponentWrapper title={strings.title}>
        <Description
          message={strings.description}
          image={rfidImage}
          fontSize='14px'
          borderBottom
        />
        <CardList
          handleRemoveAccessCard={handleRemoveAccessCard}
          cards={accessCards}
          loading={loading}
        />
      </ComponentWrapper>
      <ButtonContainerStyles
        display='flex'
        justifyContent='center'
        margin='20px 0'>
        <Button
          driver
          hasMobile
          margin='0'
          doubleButton={[
            {
              text: strings.orderButton,
              mobileText: null,
              link: '/access-cards/order',
              onclick: null,
              icon: <FaAddressCard />,
              inverse: false,
            },
            {
              text: strings.activateButton,
              mobileText: null,
              link: '/access-cards/activate/new',
              onclick: null,
              icon: null,
              inverse: true,
            },
          ]}
        />
      </ButtonContainerStyles>
    </InteriorWrapper>
  );
};

export default AccessCards;
