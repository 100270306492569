import AppOptionsService from './AppOptionsService';

const { strings } = AppOptionsService;

export const resMinsTime = [
  {
    key: strings.holdTime[0],
    value: 5,
  },
  {
    key: strings.holdTime[1],
    value: 10,
  },
  {
    key: strings.holdTime[2],
    value: 20,
  },
  {
    key: strings.holdTime[3],
    value: 30,
  },
];

export const resGuaranteeSessionTime = [
  {
    key: strings.sessionTime[0],
    value: 30,
  },
  {
    key: strings.sessionTime[1],
    value: 60,
  },
  {
    key: strings.sessionTime[2],
    value: 90,
  },
  {
    key: strings.sessionTime[3],
    value: 120,
  },
  {
    key: strings.sessionTime[4],
    value: 150,
  },
  {
    key: strings.sessionTime[5],
    value: 180,
  },
  {
    key: strings.sessionTime[6],
    value: 210,
  },
  {
    key: strings.sessionTime[7],
    value: 240,
  },
  {
    key: strings.sessionTime[8],
    value: 270,
  },
  {
    key: strings.sessionTime[9],
    value: 300,
  },
  {
    key: strings.sessionTime[10],
    value: 330,
  },
  {
    key: strings.sessionTime[11],
    value: 360,
  },
  {
    key: strings.sessionTime[12],
    value: 390,
  },
];
