import React, { useState, useEffect } from 'react';
import { FaRegTimesCircle } from 'react-icons/fa';

//services
import AppChargingProfilesService from '../../AppChargingProfilesService';

//global components
import RoleCanEdit from '../../../OperatorShared/RoleCanEdit';

//hooks
import { useHours } from '../../../../shared/hooks/useHours';

//styles
import {
  DivStyles,
  InputStyles,
  SelectStyles,
  TextStyles,
  LabelStyles,
} from '../../../../SharedStyles';

const Time = (props) => {
  const { strings } = AppChargingProfilesService;
  const [hours] = useHours();
  const [timeStart, setTimeStart] = useState('');
  const [timeEnd, setTimeEnd] = useState('');

  //needs to update on change to the array
  const handleChange = (e, time) => {
    if (time === 'timeStart') {
      setTimeStart(e.target.value);
      if (e.target.value > hours[0].time && props.allDay) {
        props.toggleAllDay();
      }
    }
    if (time === 'timeEnd') {
      setTimeEnd(e.target.value);
      if (e.target.value < hours[hours.length - 1].time && props.allDay) {
        props.toggleAllDay();
      }
    }
    props.handleTimeChange([e.target.name], e.target.value, props.time.id);
  };

  useEffect(() => {
    if (props.allDay) {
      setTimeStart(hours[0].time);
      setTimeEnd(hours[hours.length - 1].time);
      props.handleTimeChange('timeStart', hours[0].time, props.time.id);
      props.handleTimeChange(
        'timeEnd',
        hours[hours.length - 1].time,
        props.time.id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allDay]);

  useEffect(() => {
    setTimeEnd(props.time.timeEnd);
    setTimeStart(props.time.timeStart);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.time]);

  const getTimeSelected = (e) => {
    setTimeStart(e.target.value);
  };

  const removeTime = () => {
    props.removeTimeFromSx(props.time.id);
  };

  return (
    <DivStyles>
      {/* Schedule Header */}
      <DivStyles
        display='flex'
        alignItems='center'
        svgColor='#f44'
        svgSize='20px'
        svgMargin='0 0 0 10px'>
        <TextStyles size='14px' margin='0'>
          {strings.time}: {props.count}
        </TextStyles>
        <RoleCanEdit roles={[1]}>
          {props.times.length > 1 && <FaRegTimesCircle onClick={removeTime} />}
        </RoleCanEdit>
      </DivStyles>

      {/* Time Select for New and Edit */}
      <DivStyles display='flex' alignItems='flex-end' color='#c2c2c2'>
        {props.editForm ? (
          <>
            <LabelStyles margin='0 10px' width='75px'>
              {strings.time}
            </LabelStyles>
            <SelectStyles
              width='95px'
              margin='0 10px'
              name='timeStart'
              value={timeStart}
              onChange={(e) => {
                handleChange(e, 'timeStart');
                getTimeSelected(e);
              }}>
              <option>{strings.selectTime}</option>
              {hours.map((hour) => {
                return (
                  <option key={hour.display} value={hour.time}>
                    {hour.display}
                  </option>
                );
              })}
            </SelectStyles>{' '}
            -{' '}
            <SelectStyles
              width='95px'
              margin='0 10px'
              name='timeEnd'
              value={timeEnd}
              onChange={(e) => {
                handleChange(e, 'timeEnd');
              }}>
              <option>{strings.selectTime}</option>
              {hours.map((hour) => {
                if (hour.time > timeStart) {
                  return (
                    <option key={hour.display} value={hour.time}>
                      {hour.display}
                    </option>
                  );
                }
                return null;
              })}
            </SelectStyles>
          </>
        ) : (
          <>
            <LabelStyles margin='0 10px' width='75px' color='#c2c2c2'>
              {strings.time}
            </LabelStyles>
            <SelectStyles
              margin='0 10px'
              width='95px'
              value={timeStart}
              name='timeStart'
              onChange={(e) => {
                handleChange(e, 'timeStart');
                getTimeSelected(e);
              }}>
              <option>{strings.selectTime}</option>
              {hours.map((hour) => {
                return (
                  <option key={hour.display} value={hour.time}>
                    {hour.display}
                  </option>
                );
              })}
            </SelectStyles>{' '}
            -{' '}
            <SelectStyles
              width='95px'
              margin='0 10px'
              name='timeEnd'
              value={timeEnd}
              onChange={(e) => {
                handleChange(e, 'timeEnd');
              }}>
              <option>{strings.selectTime}</option>
              {hours.map((hour) => {
                if (hour.time > timeStart) {
                  return (
                    <option key={hour.display} value={hour.time}>
                      {hour.display}
                    </option>
                  );
                }
                return null;
              })}
            </SelectStyles>
          </>
        )}
      </DivStyles>
      {/* Power % for New and Edit */}
      <DivStyles display='flex' alignItems='flex-end' color='#c2c2c2'>
        <LabelStyles margin='0 10px' width='75px'>
          {strings.ampPct}
        </LabelStyles>
        <InputStyles
          margin='0 10px'
          width='70px'
          type='number'
          name='percentage'
          min={0}
          max={100}
          value={props.time.percentage}
          onChange={handleChange}
          required
        />
        %
      </DivStyles>
    </DivStyles>
  );
};

export default Time;
