import styled from 'styled-components';

import { defaultMediaQuery } from '../SharedStyles/defaultValues';

export const InteriorStyles = styled.div`
  background-color: #fcfcfc;
  height: 100%;
  font-family: 'Raleway', sans-serif;
  .Body-Container {
    max-width: 1280px;
    margin: 0 auto;
    min-height: 100%;
    display: block;
  }
`;

export const InteriorDriverComponentStyles = styled.div`
  position: relative;
  max-width: 600px;
  width: 100%;
  min-height: 500px;
  overflow: hidden;
  margin: 0 auto;
  padding: 10px 0;
  background-color: #fff;
  box-shadow: 5px 5px 7px 0 #e8e8e8;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    box-shadow: none;
  }
  @media screen and (max-width: ${({ mobileMedia, isInternal }) => {
      if (isInternal) {
        return mobileMedia ? mobileMedia : defaultMediaQuery;
      } else {
        return '0px';
      }
    }}) {
    padding: ${({ mobilePadding }) => `${mobilePadding} 0 0`};
    .Button-container {
      display: none;
    }
  }
`;
