import React, { useContext } from 'react';
import { FaInfoCircle, FaPhoneSquare } from 'react-icons/fa';

//styles
import {
  FormRowStyles,
  TextareaStyles,
  InputStyles,
} from '../../../../SharedStyles';
import SubscriptionAccess from '../../../OperatorShared/SubscriptionAccess';

//context
import { CsoAccountContext } from '../../../../context/operator/getCsoAccount';

const BrandingForm = ({ location, strings, canEdit, handleDataChange }) => {
  const { currentUser } = useContext(CsoAccountContext);

  const handleChange = (e) => {
    handleDataChange(e.target.name, e.target.value);
  };

  return (
    <SubscriptionAccess levels={[3]}>
      <FormRowStyles alignItems='center'>
        <FaInfoCircle fontSize='20px' />
        <TextareaStyles
          margin='0 20px 10px 10px'
          size='14px'
          type='text'
          bgPosition='1em'
          mobileSize='14px'
          flexWidth='1'
          padding='5px 0px 5px 4px'
          onChange={handleChange}
          name='locationBrandingInformation'
          rows={
            !location.locationBrandingInformation
              ? '1'
              : location.locationBrandingInformation &&
                location.locationBrandingInformation.length < 100
              ? '1'
              : ''
          }
          placeholder={strings.brandingInformation}
          value={location.locationBrandingInformation || ''}
          required={location.enableLocationBranding}
          disabled={canEdit && !canEdit.includes(currentUser.role)}
        />
      </FormRowStyles>
      <FormRowStyles alignItems='center'>
        <FormRowStyles alignItems='center'>
          <FaPhoneSquare fontSize='20px' />
          <InputStyles
            margin='0 20px 10px 10px'
            size='14px'
            type='text'
            mobileSize='14px'
            flexWidth='1'
            padding='5px 0px 5px 4px'
            onChange={handleChange}
            name='locationBrandingPhone'
            placeholder={strings.brandingPhone}
            value={location.locationBrandingPhone || ''}
            required={location.enableLocationBranding}
            disabled={canEdit && !canEdit.includes(currentUser.role)}
          />
        </FormRowStyles>
        <FormRowStyles alignItems='center'>
          <InputStyles
            margin='0 20px 10px 10px'
            size='14px'
            type='text'
            mobileSize='14px'
            flexWidth='1'
            padding='5px 0px 5px 4px'
            onChange={handleChange}
            name='locationBrandingSupportURL'
            placeholder={strings.brandingUrl}
            value={location.locationBrandingSupportURL || ''}
            required={location.enableLocationBranding}
            disabled={canEdit && !canEdit.includes(currentUser.role)}
          />
        </FormRowStyles>
      </FormRowStyles>
    </SubscriptionAccess>
  );
};

export default BrandingForm;
