import React, { useState } from 'react';
import { FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa';

//components
import FeesCard from './FeesCard';

//hooks
import { useSortAndReverse } from '../../../../../shared/hooks/useSortAndReverse';

//styles
import {
  ListPlaceholderStyles,
  TableStyles,
  TableHeaderStyles,
  TableItemStyles,
} from '../../../../../SharedStyles';

//helpers
import { headerFees } from '../helpers';

const FeesList = (props) => {
  const { strings, statementDetails } = props;
  const [sessions, setSessions] = useState([]);

  const updateSortedItems = (items) => {
    setSessions(items);
  };

  /**
   * Can be two of these per page for enterprise and non-enterprise
   * @param {*} sortStatus - status for the default sort
   * @param {*} dataArray - items that will be sorted
   * @param {*} headerText - strings array that will show user friendly name to map over
   * @param {*} headerConvert - converts strings in headerText array to object notation, custom function needed for this logic
   * @param {*} updateSortedItem - Function to update the current state with the sorted items
   */
  const [isActiveSort, sortReverse, changeActiveSort] = useSortAndReverse(
    'date',
    statementDetails.statementFeeLineItems,
    strings.feesHeader,
    headerFees,
    updateSortedItems,
    'desc'
  );

  return (
    <TableStyles
      maxHeight='340px'
      minHeight={sessions.length ? sessions.length * 35 : '200px'}
      width='100%'>
      <TableHeaderStyles
        borderTop='none'
        borderLeft='none'
        borderRight='none'
        width='761px'>
        {strings.feesHeader.map((title, i) => {
          return (
            <TableItemStyles
              width='100%'
              maxWidth={title === 'Ports' ? '75px' : 'auto'}
              borderRight
              key={i}
              onClick={() => {
                changeActiveSort(headerFees[title]);
              }}>
              {isActiveSort[headerFees[title]] && (
                <div>
                  {sortReverse[headerFees[title]] ? (
                    <FaLongArrowAltDown />
                  ) : (
                    <FaLongArrowAltUp />
                  )}{' '}
                </div>
              )}
              {title}
            </TableItemStyles>
          );
        })}
      </TableHeaderStyles>
      {sessions.map((item) => (
        <FeesCard
          key={item.iotHubDeviceId}
          date={item.date}
          iotHubDeviceId={item.iotHubDeviceId}
          feeType={item.feeType}
          rate={item.rate}
          ports={item.ports}
          amount={item.amount}
        />
      ))}
      {sessions.length === 0 && (
        <ListPlaceholderStyles borderBottom='none'>
          <p>{strings.noFees}</p>
        </ListPlaceholderStyles>
      )}
    </TableStyles>
  );
};

export default FeesList;
