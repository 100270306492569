import styled from 'styled-components';

export const ListStyles = styled.div`
  min-height: ${({ minHeight }) => minHeight};
  max-height: ${({ maxHeight }) => maxHeight};
  overflow-y: ${({ maxHeight }) => (maxHeight ? 'scroll' : 'visible')};
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    overflow-y: visible;
    max-height: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ListCardStyles = styled.div`
  padding: 5px 5px 5px 10px;
  border-bottom: 1px solid #e7e7e7;
  min-height: 100px;
  display: flex;
  flex-wrap: ${({ flexWrap }) => (flexWrap ? flexWrap : 'wrap')};
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  a {
    display: flex;
    flex-wrap: wrap;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
  .Image {
    width: 125px;
    height: 75px;
    margin: 0 10px 0 0;
    cursor: pointer;
    @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
      width: 75px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      /* @media screen and (max-width: ${({ theme }) =>
        theme.defaultMediaQuery}) {
        object-fit: cover;
      } */
    }
  }
  .Content {
    flex: 2;
    .Span {
      display: flex;
      margin: ${({ margin }) => (margin ? margin : '3px 0')};
      .Label {
        font-size: 14px;
        margin: 0 10px 0 0;
        line-height: 15px;
        font-weight: 600;
        min-width: ${({ minWidth }) => minWidth};

        @media screen and (max-width: ${({ theme }) =>
            theme.defaultMediaQuery}) {
          font-size: 13px;
        }
        @media screen and (max-width: 375px) {
          flex: 100%;
        }
        &.Title {
          cursor: pointer;
        }
      }
      .Data {
        font-size: 14px;
        line-height: 15px;
        color: #a5a5a5;
        margin: 0;
        @media screen and (max-width: ${({ theme }) =>
            theme.defaultMediaQuery}) {
          font-size: 13px;
          line-height: 1;
        }
      }
      svg {
        color: #8497b6;
        line-height: 16px;
        margin: 0 0 0 5px;
        cursor: pointer;
        &:hover {
          color: #2c456c;
        }
      }
      @media screen and (max-width: 375px) {
        flex-wrap: wrap;
      }
    }
  }
`;

export const ListActionStyles = styled.div`
  margin: 0 25px 0 0;
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    margin: 0 10px 0 0;
  }
  svg {
    color: #8497b6;
    line-height: 16px;
    margin: 5px 0;
    font-size: 18px;
    @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
      margin: 10px 0;
    }
    cursor: pointer;
    &:hover {
      color: #2c456c;
    }
  }
`;

export const VehicleFormStyles = styled.form`
  padding: 0 10px;
  margin: 0 0 20px;
  .Input {
    input {
      width: 100%;
      color: #a5a5a5;
    }
  }
`;

export const VehicleSpecContainerStyles = styled.div`
  padding: 5px 10px 15px;
  display: block;
  border-top: 1px solid #e7e7e7;
  .Container-Title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #333;
    flex: 100%;
  }
  .Image-Container {
    flex: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 5px auto 20px;
    max-width: 300px;
    max-height: 300px;
  }
  .Image-Button {
    flex: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .Container-Content {
    flex: 100%;
    font-size: 14px;
    .Content-Data {
      color: #a5a5a5;
      margin: 0 0 7px;
      span {
        margin: 0 10px 0 0;
        color: #000;
      }
    }
  }
`;
