import AppPlatformService from '../../../../../../services/AppPlatform/AppPlatformService';
import AppApiService from '../../../../../../services/AppApi/AppApiService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const EnergyService = {
  strings: localStrings,
  generateEnergySummaryReport: (query) => {
    return AppApiService.post('generateEnergySummaryReport', null, query);
  },
};

export default EnergyService;
