import AppApiService from '../../../services/AppApi/AppApiService';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppAccountProfileService = {
  deleteCsoAccount: function(csoAccountId) {
    return AppApiService.post('deleteCsoAccount', null, csoAccountId);
  },
  strings: localStrings
};

export default AppAccountProfileService;
