import AppPlatformService from '../../../../../../services/AppPlatform/AppPlatformService';
import AppApiService from '../../../../../../services/AppApi/AppApiService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const ChargingSessionService = {
  strings: localStrings,
  getCSOSessions: function () {
    return AppApiService.get('getCSOSessions');
  },
  utilityReport: function (chargers) {
    return AppApiService.post('utilityReport', null, chargers);
  },
  getCSOChargerStatus: function () {
    return AppApiService.get('getCSOChargerStatus');
  },
};

export default ChargingSessionService;
