import React, { useEffect, useState, useContext } from 'react';
import { injectStripe } from 'react-stripe-elements';

//services
import AppBankService from '../AppBankService';

//components
import { LoadingSpinner } from '../../../shared';

//styles
import {
  InputStyles,
  FormStyles,
  JumboTronHeaderStyles,
  TitleStyles,
  TextStyles,
  ButtonDivStyles,
  ButtonStyles,
  ButtonContainerStyles,
} from '../../../SharedStyles';

//error
import { ErrorResponse } from '../../../shared/ErrorResponse';

//context
import { ErrorContext } from '../../../context/shared/ErrorContext';

const SingleBankForm = (props) => {
  const { strings } = AppBankService;
  const { connectAccount } = props;
  const { setError } = useContext(ErrorContext);
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [bankForm, setBankForm] = useState({
    country: 'US',
    currency: 'usd',
    routing_number: '',
    account_number: '',
    confirm_account_number: '',
    account_holder_name: '',
    account_holder_type: connectAccount && connectAccount.businessType,
  });

  const handleChange = (e) => {
    setBankForm({ ...bankForm, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    let payoutCompleted = Object.keys(bankForm).every((key) => {
      return bankForm[key] !== '';
    });
    if (payoutCompleted || connectAccount.payoutVerified) {
      setDisable(false);
    }
  }, [bankForm, connectAccount.payoutVerified]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let payoutCompleted = Object.keys(bankForm).every((key) => {
      return bankForm[key] !== '';
    });

    try {
      if (bankForm.confirm_account_number !== bankForm.account_number) {
        throw new ErrorResponse(
          'frontend',
          'You must provide matching account numbers.',
          'Mismatch Account Numbers'
        );
      }

      if (payoutCompleted) {
        let { token, error } = await props.stripe.createToken(
          'bank_account',
          bankForm
        );
        if (error) {
          throw new ErrorResponse(
            'frontend',
            error.message,
            'Something went wrong'
          );
        }
        await AppBankService.createPayoutAccount(token.id);
      }

      props.fetchStripeAccount();
      props.handleCloseWizard();
      props.fetchStripeData();
    } catch (err) {
      setLoading(false);
      if (err.name === 'frontend') {
        setError({
          display: true,
          styles: 'cso',
          title: err.title,
          message: err.message,
        });
      } else {
        setError({
          display: true,
          styles: 'cso',
          title: 'Existing Account',
          message: err.response.data.split(/[|]+/).pop(),
        });
      }
    }
  };

  return (
    <>
      <JumboTronHeaderStyles
        align='left'
        padding='10px 30px'
        borderBottom='none'>
        {loading && <LoadingSpinner />}
        <TitleStyles
          size='28px'
          align='left'
          justifyContent='space-between'
          alignItems='center'
          display='flex'
          padding='0 0 5px'
          borderBottom>
          {strings.wizard2Title}
        </TitleStyles>
        <TextStyles size='14px'>{strings.wizard2Content1}</TextStyles>
        <FormStyles
          display='block'
          margin='0 auto'
          width='90%'
          onSubmit={handleSubmit}
          id='bankForm'>
          <InputStyles
            type='text'
            name='account_holder_name'
            onChange={handleChange}
            placeholder={strings.bankFormLabel1}
            value={bankForm.account_holder_name}
            required
          />
          <InputStyles
            type='number'
            name='account_number'
            onChange={handleChange}
            placeholder={strings.bankFormLabel2}
            value={bankForm.account_number}
            required
          />
          <InputStyles
            type='number'
            name='confirm_account_number'
            onChange={handleChange}
            placeholder={strings.bankFormLabel3}
            value={bankForm.confirm_account_number}
            required
          />
          <InputStyles
            type='number'
            name='routing_number'
            onChange={handleChange}
            placeholder={strings.bankFormLabel4}
            value={bankForm.routing_number}
            required
          />
        </FormStyles>
      </JumboTronHeaderStyles>
      <ButtonContainerStyles>
        <ButtonDivStyles
          inverse
          onClick={props.handleCloseWizard}
          title={strings.cancel}>
          {strings.cancel}
        </ButtonDivStyles>
        <ButtonStyles
          type='submit'
          form='bankForm'
          disabled={disable}
          title={strings.save}>
          {strings.save}
        </ButtonStyles>
      </ButtonContainerStyles>
    </>
  );
};

export default injectStripe(SingleBankForm);
