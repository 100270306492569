import React, { useState, useEffect, useContext } from 'react';

//services
import AppVehicleService from '../../AppVehicleService';

//components
import LoadingSpinner from '../../../../shared/Loading/LoadingSpinner';

//images
import DefaultImage from '../../../../../assets/images/MyVehiclePlaceholder300x150.png';

//hooks
import { useRouter } from '../../../../shared/hooks/useRouter';
import { useCloudinary } from '../../../../shared/Cloudinary/hook/useCloudinary';

//context
import { DriverContext } from '../../../../context/driver/getDriverContext';

//styles
import {
  VehicleFormStyles,
  VehicleSpecContainerStyles,
} from '../../../../DriverStyles';
import {
  InputContainerStyles,
  InputStyles,
  SelectContainerStyles,
  SelectStyles,
  ButtonContainerStyles,
  ButtonSpanStyles,
} from '../../../../SharedStyles';

//helpers
import { milesToKm } from '../../../../shared/Helpers';

const AddVehicle = (props) => {
  const { strings } = AppVehicleService;
  const { driver } = useContext(DriverContext);
  const router = useRouter();
  const [vehicleCatalog, setVehicleCatalog] = useState([]);
  const [makeList, setMakeList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [myVehicle, setMyVehicle] = useState({
    nickname: '',
    make: '',
    model: '',
    year: '',
    color: '',
    battery: '--',
    range: '--',
  });
  const [loading, setLoading] = useState(false);

  const { cloudData, uploadImage, imageLoading } = useCloudinary({
    preset: 'vehicle_images',
    tags: [`${driver.firstName} ${driver.lastName}`, driver.id],
  });

  useEffect(() => {
    if (cloudData) {
      setMyVehicle((v) => ({
        ...v,
        imagePath: cloudData.secure_url,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloudData]);

  const openDialog = () => {
    document.getElementById('inputUpload').click();
  };

  useEffect(() => {
    setLoading(true);
    const fetchVehiclesInformation = async () => {
      const allVehicleCatalog = await AppVehicleService.getVehicleCatalog();
      const makeLists = [
        ...new Set(allVehicleCatalog.map((vehicle) => vehicle.make)),
      ].sort();
      setVehicleCatalog(allVehicleCatalog);
      setMakeList(makeLists);
      setLoading(false);
    };
    fetchVehiclesInformation();
  }, []);

  const handleChange = (e, action) => {
    if (action === 'make') {
      const modelList = [
        ...new Set(
          vehicleCatalog
            .filter((vehicle) => {
              return vehicle.make === e.target.value;
            })
            .map((vehicle) => vehicle.model)
        ),
      ].sort();
      setModelList(modelList);
    }
    if (action === 'model') {
      const yearList = [
        ...new Set(
          vehicleCatalog
            .filter((vehicle) => {
              return (
                vehicle.make === myVehicle.make &&
                vehicle.model === e.target.value
              );
            })
            .map((vehicle) => vehicle.year)
        ),
      ].sort();
      setYearList(yearList);
    }

    setMyVehicle({ ...myVehicle, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    let selectedVehicle = vehicleCatalog.find((car) => {
      return (
        car.make === myVehicle.make &&
        car.model === myVehicle.model &&
        car.year === myVehicle.year
      );
    });
    if (selectedVehicle) {
      setMyVehicle((prevVehicle) => ({
        ...prevVehicle,
        range: selectedVehicle.allElectricRange,
        battery: selectedVehicle.batteryCapacity,
      }));
    }
  }, [
    setMyVehicle,
    myVehicle.make,
    myVehicle.year,
    myVehicle.model,
    vehicleCatalog,
  ]);

  const updateObject = (oldObject, updatedProperties) => {
    return {
      ...oldObject,
      ...updatedProperties,
    };
  };

  const saveVehicle = async (e) => {
    e.preventDefault();
    setLoading(true);
    const vehicle = vehicleCatalog.filter((vehicle) => {
      return (
        vehicle.make === myVehicle.make &&
        vehicle.model === myVehicle.model &&
        vehicle.year === myVehicle.year
      );
    });
    const newVehicle = updateObject(...vehicle, {
      nickname: myVehicle.nickname,
      color: myVehicle.color,
      imagePath: myVehicle.imagePath,
    });
    try {
      const vehicleReturn = await AppVehicleService.addDriverVehicle(
        newVehicle
      );
      if (vehicleReturn) {
        router.history.push('/vehicle-list');
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <VehicleFormStyles id='myForm' onSubmit={saveVehicle}>
        {(loading || imageLoading) && <LoadingSpinner />}
        <InputContainerStyles>
          <InputStyles
            type='text'
            maxLength='256'
            name='nickname'
            placeholder={strings.nickname}
            onChange={handleChange}
            required
          />
        </InputContainerStyles>
        <SelectContainerStyles>
          <SelectStyles
            name='make'
            disabled={!makeList.length}
            onChange={(e) => handleChange(e, 'make')}
            defaultValue={''}
            required>
            <option value='' disabled>
              {strings.makePlaceholder}
            </option>
            {makeList.map((make) => (
              <option key={make} value={make}>
                {make}
              </option>
            ))}
          </SelectStyles>
        </SelectContainerStyles>
        {modelList.length > 0 && (
          <SelectContainerStyles>
            <SelectStyles
              name='model'
              onChange={(e) => handleChange(e, 'model')}
              disabled={!modelList.length}
              required>
              <option value=''>{strings.modelPlaceholder}</option>
              {modelList.map((model) => (
                <option key={model} value={model}>
                  {model}
                </option>
              ))}
            </SelectStyles>
          </SelectContainerStyles>
        )}
        {yearList.length > 0 && (
          <SelectContainerStyles>
            <SelectStyles
              name='year'
              onChange={(e) => handleChange(e, 'year')}
              disabled={!yearList.length}
              required>
              <option value=''>{strings.yearPlaceholder}</option>
              {yearList.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </SelectStyles>
          </SelectContainerStyles>
        )}
        {myVehicle.year && (
          <SelectContainerStyles>
            <SelectStyles
              name='color'
              onChange={handleChange}
              disabled={!myVehicle.year}
              required>
              <option value=''>{strings.colorSelect}</option>
              {strings.colors.map((color) => {
                return (
                  <option key={color} value={color}>
                    {color}
                  </option>
                );
              })}
            </SelectStyles>
          </SelectContainerStyles>
        )}
      </VehicleFormStyles>
      {/* Information about vehicle selected */}
      <VehicleSpecContainerStyles>
        <div className='Container-Title'>{strings.vehicleSpecs}</div>
        <div className='Image-Container'>
          <img
            src={myVehicle.imagePath ? myVehicle.imagePath : DefaultImage}
            alt='vehicle'
          />
          <input
            className='file-input'
            id='inputUpload'
            type='file'
            hidden
            accept='image/*'
            onChange={uploadImage}
          />
        </div>
        <ButtonContainerStyles margin='10px auto'>
          <ButtonSpanStyles inverse id='fileUpload' onClick={openDialog}>
            {strings.changeImage}
          </ButtonSpanStyles>
        </ButtonContainerStyles>
        {Object.keys(myVehicle).length > 0 && (
          <div className='Container-Content'>
            <div className='Content-Data'>
              <span className='Content-Bold'>{strings.battery}</span>
              {myVehicle.battery} {strings.kwh}
            </div>
            <div className='Content-Data'>
              <span className='Content-Bold'>{strings.range}</span>
              {myVehicle.range !== '--'
                ? milesToKm(driver.unitOfMeasurement, myVehicle.range)
                : '--'}
            </div>
          </div>
        )}
      </VehicleSpecContainerStyles>
    </>
  );
};

export default AddVehicle;
