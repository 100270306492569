import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppOwnedChargersService from '../AppOwnedChargersService';

//global components
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';

//hooks
import { useForm } from '../../../shared/hooks/useForm';

const AppCharger = props => {
  const { strings } = AppOwnedChargersService;
  const { handleModalToggle } = props;

  const [passcode, setPasscode] = useForm({ token: '' });

  const closeModal = () => {
    handleModalToggle();
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.submitHandler(passcode);
  };

  return (
    <div className='Modal-Display-Driver'>
      {props.loading && <LoadingSpinner />}

      <div className='Modal'>
        <div className='Modal-Header'>
          {strings.addTitle}
          <div className='Modal-Close'>
            <FaRegWindowClose onClick={closeModal} />
          </div>
        </div>
        <div className='Modal-Content'>
          <p className='Modal-Content-Text'>{strings.addContent}</p>
          <form
            className='passcode-form'
            onSubmit={handleSubmit}
            id='charger-form'>
            <input
              type='text'
              name='token'
              className='modal-passcode-form'
              placeholder='enter token'
              onChange={setPasscode}
              value={passcode.token}
            />
          </form>
        </div>
        <div className='Modal-Button'>
          <button className='app-button' form='charger-form'>
            {strings.addButton}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppCharger;
