import React, { useState, useEffect, useCallback } from 'react';

//service
// import GoogleChartService from './GoogleChartService';

//hooks
import { useWindowResize } from '../../../shared/hooks/useWindowSize';

//styles
import { DivStyles } from '../../../SharedStyles';

const google = window.google;

if (google) {
  google.charts.load('current', {
    packages: ['corechart'],
  });
}

const GoogleChart = (props) => {
  const [loading, setLoading] = useState(true);
  const size = useWindowResize(window.innerWidth, window.innerHeight);
  // const [chartImage, setChartImage] = useState(null);

  // Callback that creates and populates a data table,
  // instantiates the pie chart, passes in the data and
  // draws it.
  const drawChart = useCallback(() => {
    // Create the data table.
    var data = new google.visualization.DataTable();
    props.columns.map((column) => {
      data.addColumn(column[0], column[1]);
      return column;
    });
    data.addRows(props.data);

    // Set chart options
    var options = {
      ...props.options,
      title: props.title,
      fontSize: props.size,
      chartArea: {
        top: props.top,
        left: props.left,
        width: props.width,
        height: props.height,
      },
      pieHole: props.pieChart,
      pieSliceText: 'value',
      sliceVisibilityThreshold: 0,
      colors: props.colors,
      tooltip: { isHtml: true, trigger: 'selection' },
    };

    // Instantiate and draw our chart, passing in some options.
    let chart;
    if (props.type === 'pie') {
      chart = new google.visualization.PieChart(
        document.getElementById(props.divId)
      );
      chart.setAction({
        id: 'alertAction',
        text: 'View Chargers',
        action: function () {
          var selection = chart.getSelection();
          if (selection.length) {
            let selectionValue = selection[0].row;
            let route = props.data[selectionValue][0];
            if (props.route) {
              props.route(route);
            }
          }
        },
      });
    }
    if (props.type === 'line') {
      chart = new google.visualization.LineChart(
        document.getElementById(props.divId)
      );
    }
    if (props.type === 'column') {
      chart = new google.visualization.ColumnChart(
        document.getElementById(props.divId)
      );
    }

    // google.visualization.events.addListener(chart, 'ready', async function () {
    //   // document.getElementById('hidden-canvas').src = chart.getImageURI();
    //   let img = chart.getImageURI();
    //   let imageBlob = await GoogleChartService.prepareImage(img);
    //   imageBlob.type = 'vehicle';
    //   imageBlob.linkId = props.type;
    //   console.log(imageBlob);
    //   let res = await GoogleChartService.uploadImage(imageBlob);
    //   console.log(res);
    // });

    chart.draw(data, options);
    setLoading(false);
  }, [props]);

  // Set a callback to run when the Google Visualization API is loaded.
  useEffect(() => {
    // Load the Visualization API and the corechart package.
    google.charts.setOnLoadCallback(drawChart);
  }, [props.data, drawChart, size]);

  // const imageUpload = (blob) => {
  //   var file = e.target.files[0];
  //   var reader = new FileReader();
  //   reader.onloadend = function () {
  //     setLoading(true);
  //     AppImagingService.getBlobForDataUrl(reader.result).then((data) => {
  //       let imageBlob = data;
  //       imageBlob.type = 'report';
  //       imageBlob.linkId = '';
  //       setImageResults(reader.result);
  //       handleImageChange(imageBlob);
  //     });
  //   };
  //   reader.readAsDataURL(file);
  // };
  // const handleImageChange = (image) => {
  //   if (image !== {}) {
  //     AppAccountSettingsService.uploadImage(image).then((data) => {
  //       setDriver({ ...driver, imagePath: data });
  //       setLoading(false);
  //       postDriver();
  //     });
  //   }
  // };

  return (
    <>
      {loading && props.loader}
      <DivStyles
        height='400px'
        id={props.divId}
        minWidth={props.minWidth}></DivStyles>
      {/* <DivStyles visibility='hidden' height='0' width='0'>
        <img id='hidden-canvas' />
      </DivStyles> */}
    </>
  );
};

export default GoogleChart;
