import styled from 'styled-components';

export const PaymentBalanceStyles = styled.div``;

export const PaymentHeader = styled.div`
  color: #2c456c;
  h2 {
    margin: 10px 0 0;
    font-size: ${({ h2Size }) => (h2Size ? h2Size : '25px')};
    line-height: 1;
    font-weight: 300;
  }
  h3 {
    margin: 10px auto 35px;
    font-size: ${({ h3Size }) => (h3Size ? h3Size : '40px')};
    line-height: 1.5;
    font-weight: 400;
  }
`;
