import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

import { CsoAccountContext } from '../../components/context/operator/getCsoAccount';
import AppAccessDenied from '../../components/shared/AppAccessDenied/AppAccessDenied';

import AppAccessDeniedService from '../../components/shared/AppAccessDenied/AppAccessDeniedService';

const EnterpriseRouting = ({ component: Component, ...rest }, props) => {
  const { account } = useContext(CsoAccountContext);

  const { strings } = AppAccessDeniedService;
  if (account && account.subscriptionLevel === 3) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  } else {
    return (
      <Route
        {...rest}
        render={(props) => (
          <AppAccessDenied
            {...props}
            enterprise
            content={strings.EnterpriseDeniedContent}
          />
        )}
      />
    );
  }
};

export default EnterpriseRouting;
