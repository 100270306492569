import React from 'react';
import moment from 'moment';

//service
import AppPlatformService from '../../../../../services/AppPlatform/AppPlatformService';

//styles
import { TableRowStyles, TableItemStyles } from '../../../../SharedStyles';

//helpers
import { timeConvert } from '../../../../shared/Helpers';

const ChargingSessionCard = (props) => {
  const { session } = props;
  return (
    <TableRowStyles
      borderTop='none'
      borderLeft='none'
      borderRight='none'
      width='100%'
      minWidth='560px'
      hoverBackground='#f1f7ff'>
      <TableItemStyles width='80px' minWidth='80px'>
        {moment(session.transactionDate).format('MM/DD/YY')}
      </TableItemStyles>
      <TableItemStyles width='80px' minWidth='80px'>
        {moment(session.transactionDate).format('hh:mma')}
      </TableItemStyles>
      <TableItemStyles align='center' width='80px' minWidth='80px'>
        {timeConvert(session.totalTime)}
      </TableItemStyles>
      <TableItemStyles width='80px' minWidth='80px'>
        {session.avgkW.toFixed(2)}
      </TableItemStyles>
      <TableItemStyles width='80px' minWidth='80px'>
        {session.totalkWh.toFixed(2)}
      </TableItemStyles>
      <TableItemStyles width='80px' minWidth='80px'>
        {AppPlatformService.currencyFormat(session.totalCost)}
      </TableItemStyles>
      <TableItemStyles width='80px' minWidth='80px'>
        {AppPlatformService.currencyFormat(session.idleCost)}
      </TableItemStyles>
    </TableRowStyles>
  );
};

export default ChargingSessionCard;
