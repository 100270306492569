import AppApiService from '../../../services/AppApi/AppApiService';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppDashboardService = {
  getChargerHistory: function (chargerId) {
    return AppApiService.post('csoChargerHistory', null, chargerId);
  },
  getCSOSessions: function () {
    return AppApiService.get('getCSOSessions');
  },
  getCSODashboardGeneral: function () {
    return AppApiService.get('getCSODashboardGeneral');
  },
  strings: localStrings,
};

export default AppDashboardService;
