import AppPlatformService from '../../../../../../services/AppPlatform/AppPlatformService';
import AppApiService from '../../../../../../services/AppApi/AppApiService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const RevenueService = {
  strings: localStrings,
  generateRevenueSummaryReport: (query) => {
    return AppApiService.post('generateRevenueSummaryReport', null, query);
  },
};

export default RevenueService;
