import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

//service
import AppPlatformService from '../../../../../../../../services/AppPlatform/AppPlatformService';

//hooks
import { useRouter } from '../../../../../../../shared/hooks/useRouter';

//styles
import {
  TableItemStyles,
  TableRowStyles,
} from '../../../../../../../SharedStyles';

const PayoutCard = (props) => {
  const { strings, payout } = props;

  const router = useRouter();

  const handleRedirect = () => {
    router.history.push(
      `/reports/payouts/${payout.id ? payout.id : payout.payoutId}`
    );
  };

  return (
    <TableRowStyles
      borderTop='none'
      borderLeft='none'
      borderRight='none'
      hoverBackground='#f1f7ff'
      onClick={handleRedirect}
      width='100%'>
      <TableItemStyles width='100%'>
        {moment(payout.payoutDate).format('MM/DD/YYYY')}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='200px'>
        {payout.stripeTransactionId ? payout.stripeTransactionId : 'deferred'}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='125px'>
        {AppPlatformService.currencyFormat(payout.totalEarnings)}
      </TableItemStyles>
      <TableItemStyles width='100%'>
        {AppPlatformService.currencyFormat(payout.totalBankFees)}
      </TableItemStyles>
      <TableItemStyles width='100%'>
        {AppPlatformService.currencyFormat(payout.totalTransferFees)}
      </TableItemStyles>
      <TableItemStyles width='100%'>
        {AppPlatformService.currencyFormat(payout.totalPayout)}
      </TableItemStyles>
      <TableItemStyles width='100%' maxWidth='75px'>
        <Link
          to={`/reports/payouts/${
            payout.id !== undefined ? payout.id : payout.payoutId
          }`}>
          {strings.view}
        </Link>
      </TableItemStyles>
    </TableRowStyles>
  );
};

export default PayoutCard;
