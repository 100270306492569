import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppNavSectionService from '../AppNavSectionService';

//styles
import { ModalStyles } from '../../../SharedStyles';

const AppCSOAccountModal = (props) => {
  const { strings } = AppNavSectionService;

  const closeModal = () => {
    props.closeModal();
  };

  return (
    <ModalStyles>
      <div className='AppCso-modal-container'>
        <div className='AppCso-modal-title'>
          <div>{strings.enterpriseTitle}</div>
          <div className='AppCso-modal-close' onClick={closeModal}>
            <FaRegWindowClose />
          </div>
        </div>
        <div className='AppCso-modal-content'>
          <div className='AppCso-modal-item'>
            {props.account.subscriptionLevel !== 3 ? (
              <p>
                {strings.subscriptions[props.account.subscriptionLevel]}{' '}
                {strings.modalContent}{' '}
                <Link to='/subscription/settings'>
                  {strings.subscriptions[3]}
                </Link>{' '}
                {strings.modalContentTwo}
              </p>
            ) : (
              <p>Invalid Permissions</p>
            )}
          </div>
        </div>
        <div className='AppCso-modal-button-container'>
          <button onClick={closeModal} className='AppCso-modal-button'>
            {strings.modalOk}
          </button>
        </div>
      </div>
    </ModalStyles>
  );
};

export default withRouter(AppCSOAccountModal);
