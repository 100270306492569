import React, { useState, useEffect, useContext } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

//layout
import { InteriorWrapper, ComponentWrapper } from '../shared/index';

//services
import AppPaymentsService from './AppPaymentsService';

//components
import PaymentsBalance from './PaymentsBalance/PaymentsBalance';
import PaymentsLedger from './PaymentsHistory/PaymentsLedger';
import PaymentsOptions from './PaymentsOptions/PaymentsOptions';

//hooks
import { useParams } from '../../shared/hooks/useParams';

//context
import { DriverContext } from '../../context/driver/getDriverContext';

//env
import { STRIPE_API } from '../../shared';

const Payments = (props) => {
  const { strings } = AppPaymentsService;
  const [tabIndex, setTabIndex] = useState(0);
  const { fetchDriver } = useContext(DriverContext);

  const [formData] = useParams({
    entityId: '',
    paramType: 1,
    page: 'system-variables | driver-defaults',
    error: 'Error',
  });

  useEffect(() => {
    fetchDriver();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StripeProvider apiKey={STRIPE_API}>
      <InteriorWrapper
        title={strings.paymentTitle}
        isInternal
        save={tabIndex === 2 ? true : false}
        myForm={tabIndex === 2 ? 'myForm' : false}
        path='/charging'>
        <ComponentWrapper title={strings.paymentTitle} align='center'>
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(tabIndex) => setTabIndex(tabIndex)}>
            <TabList>
              <Tab>{strings.balanceTitle}</Tab>
              <Tab>{strings.historyTitle}</Tab>
              <Tab>{strings.optionsTitle}</Tab>
            </TabList>
            <TabPanel>
              <PaymentsBalance
                setTabIndex={setTabIndex}
                autoPaymentAmount={formData.autoPaymentAmount}
                lowBalanceTrigger={formData.lowBalanceTrigger}
              />
            </TabPanel>
            <TabPanel>
              <PaymentsLedger />
            </TabPanel>
            <TabPanel>
              <Elements>
                <PaymentsOptions strings={strings} routing={props} fetchDriver={fetchDriver} />
              </Elements>
            </TabPanel>
          </Tabs>
        </ComponentWrapper>
      </InteriorWrapper>
    </StripeProvider>
  );
};
export default Payments;
