import React from 'react';

import moment from 'moment';

//styles
import {
  TableRowStyles,
  TableItemStyles,
} from '../../../../../../SharedStyles';

const NYSERDAChargerStatusReportItem = (props) => {
  const { session } = props;
  return (
    <TableRowStyles
      hoverBackground='#f1f7ff'
      borderTop='none'
      borderLeft='none'
      borderRight='none'
      align='center'
      width='100%'
      minWidth='1160px'>
      <TableItemStyles width='100%' minWidth='82px' maxWidth='82px'>
        {session.ChargerID}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='82px' maxWidth='82px'>
        {session.SystemStatus}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='82px' maxWidth='82px'>
        {session.IncidentDate}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='82px' maxWidth='82px'>
        {session.IncidentTime}
      </TableItemStyles>
    </TableRowStyles>
  );
};

export default NYSERDAChargerStatusReportItem;
