import React from 'react';

//components
import ReportsCard from './ReportsCard';

//styles
import { DivStyles, TitleStyles } from '../../../../SharedStyles';
import DevModeOnly from '../../../../shared/Tools/DevModeOnly';
import { RoleCanEdit, SubscriptionAccess } from '../../../OperatorShared';

const ReportsList = (props) => {
  if (props.empty) {
    return (
      <DivStyles
        width='100%'
        position='relative'
        margin={props.margin}
        wrapMargin='0 0 5px'
        flex='1'
        borderRight='none'
        borderLeft='none'
        borderTop='none'
        borderBottom='none'
      />
    );
  }
  return (
    <DivStyles
      width='100%'
      position='relative'
      margin={props.margin}
      wrapMargin='0 0 5px'
      flex='1'
      borderRight='1px solid #e7e7e7'
      borderLeft='1px solid #e7e7e7'
      borderTop='1px solid #e7e7e7'
      borderBottom='1px solid #e7e7e7'>
      <TitleStyles
        size='14px'
        align='left'
        padding='5px'
        borderBottom
        margin='0'
        weight='400'>
        {props.title}
      </TitleStyles>
      {props.reportsData.map((data) => (
        <DevModeOnly key={data.title} devOnly={data.devOnly}>
          <SubscriptionAccess levels={data.levels}>
            <RoleCanEdit roles={data.roles}>
              <ReportsCard
                title={data.title}
                subtitle={data.subtitle}
                imageUrl={data.imageUrl}
                route={data.route}
                contentOne={data.contentOne}
                contentTwo={data.contentTwo}
                contentThree={data.contentThree}
                emContentOne={data.emContentOne}
                emContentTwo={data.emContentTwo}
              />
            </RoleCanEdit>
          </SubscriptionAccess>
        </DevModeOnly>
      ))}
    </DivStyles>
  );
};

export default ReportsList;
