import React from 'react';

//services
import AppFooterService from './AppFooterService';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';

//styles
import { FooterStyles } from '../../SharedStyles';

//helpers
const isMobile =
  AppPlatformService.isCSOApp() || AppPlatformService.isDriverApp();

const isDev = AppPlatformService.getVersion() === 'dev';

const Footer = (props) => {
  const { strings } = AppFooterService;
  let csoVersion = 'version dev 1.0.16';
  let driverVersion = 'version dev 1.1.12';

  return (
    <FooterStyles
      isMobile={isMobile}
      align='center'
      background='#f3f3f3'
      padding='10px 0'
      size='14px'
      top='2em'>
      {strings.textOne} {new Date().getUTCFullYear()} {strings.textTwo}{' '}
      {isDev && AppPlatformService.isCSOApp() && <span>- {csoVersion}</span>}
      {isDev && AppPlatformService.isDriverApp() && (
        <span>- {driverVersion}</span>
      )}
      {isDev && !isMobile && (
        <span>
          - CSO {csoVersion} - Driver {driverVersion}{' '}
        </span>
      )}
    </FooterStyles>
  );
};

export default Footer;
