export const tableHeaderEnterpriseConvert = {
  Date: 'transactionDate',
  Time: 'time',
  'Tot Time': 'totalTime',
  'Avg kW': 'avgkW',
  kWh: 'totalkWh',
  Amt: 'totalCost',
  Additional: 'idleCost',
};

export const tableHeaderConvert = {
  Date: 'transactionDate',
  Time: 'time',
  'Tot Time': 'totalTime',
  'Avg kW': 'avgkW',
  kWh: 'totalkWh',
  Amt: 'totalCost',
};
