import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa';

//services
import AppMembershipsService from '../../AppMembershipsService';

//components
import MembersCard from './MembersCard';

//global components
import { LoadingSpinner } from '../../../../shared';
import Pagination from '../../../../shared/Pagination/Pagination';

//hooks
import { usePagination } from '../../../../shared/hooks/usePagination';
import { useSortAndReverse } from '../../../../shared/hooks/useSortAndReverse';
import { useRouter } from '../../../../shared/hooks/useRouter';

//context
import { ErrorContext } from '../../../../context/shared/ErrorContext';

//styles
import {
  TableStyles,
  TableHeaderStyles,
  TableItemStyles,
  ListPlaceholderStyles,
} from '../../../../SharedStyles';

//helpers
import { tableHeaderConvert } from '../../helpers';

const MembershipMembers = (props) => {
  const { strings } = AppMembershipsService;
  const { filteredValue, searchedValue } = props;
  const router = useRouter();
  const { setError } = useContext(ErrorContext);

  const [members, setMembers] = useState([]);
  const [filteredMembers, setFilteredMember] = useState([]);
  const [loading, setLoading] = useState(true);

  const sortByname = async () => {
    let sortedMembersInMembership = await AppMembershipsService.getMembersInMembership(
      router.match.params.id
    );
    sortedMembersInMembership.sort((a, b) => {
      return a['lastName'].localeCompare(b['lastName']);
    });

    setMembers(sortedMembersInMembership);

    if (!sortedMembersInMembership.length) {
      setLoading(false);
    }
  };

  //load members in membership
  useEffect(() => {
    sortByname();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFilteredMember(members);
  }, [members]);

  useEffect(() => {
    if (filteredMembers.length) {
      setLoading(false);
    }
  }, [filteredMembers]);

  const updateSortedItems = (items) => {
    setFilteredMember(items);
  };

  /**
   * Can be two of these per page for enterprise and non-enterprise
   * @param {*} sortStatus - status for the default sort
   * @param {*} dataArray - items that will be sorted
   * @param {*} headerText - strings array that will show user friendly name to map over
   * @param {*} headerConvert - converts strings in headerText array to object notation, custom function needed for this logic
   * @param {*} updateSortedItem - Function to update the current state with the sorted items
   */
  const [isActiveSort, sortReverse, changeActiveSort] = useSortAndReverse(
    'lastName',
    members,
    strings.headerData,
    tableHeaderConvert,
    updateSortedItems
  );

  /**
   * pagination for the data
   * @param {*} dataArray - items that will be sorted
   * @param {*} itemsPerPage - Custom items limit per page
   */
  const {
    next,
    prev,
    jump,
    currentData,
    currentPage,
    maxPage,
    totalData,
    currentCount,
    pageNumbers,
  } = usePagination(filteredMembers, 20);

  // Filter and Search Value Logic to map over and returns members with the conditions
  useEffect(() => {
    const dateTimes = [
      'last7Days',
      'last30Days',
      'last3Months',
      'last6Months',
      'lastYear',
      'thisYear',
    ];
    const expiredTimes = ['expired', 'memberSince'];
    
    console.log(searchedValue);
    console.log(filteredValue);
    let lowercasedSearch = searchedValue.toLowerCase();
    if (filteredValue === 'joined') {
      lowercasedSearch = moment(lowercasedSearch).format('YYYY/MM/DD');
    }
    const searchedMembers = members.filter((member) => {
      return Object.keys(member).some((key) => {
        console.log(key);
        if (dateTimes.includes(filteredValue)) {
          if (filteredValue === 'lastYear') {
            return (
              member['joined'].split('-')[0] === lowercasedSearch.split('-')[0]
            );
          }
          if (filteredValue === 'thisYear') {
            return (
              member['joined'].split('-')[0] === lowercasedSearch.split('-')[0]
            );
          }
          return member['joined'].split('T') > lowercasedSearch;
        }
        if (expiredTimes.includes(filteredValue)) {
          if (filteredValue === 'expired') {
            return (
              moment(member['expiration'])
                .format('YYYY/MM/DD')
                .split('/')[0] !== '0001' &&
              moment(member['expiration']).format('YYYY/MM/DD') <
                moment().format('YYYY/MM/DD')
            );
          }
          if (filteredValue === 'memberSince') {
            let searchedDate = lowercasedSearch.split('/');
            let formatDate = lowercasedSearch;
            if (searchedDate.length === 3) {
              formatDate = `${searchedDate[2]}/${searchedDate[0]}/${searchedDate[1]}`;
            }
            let joinDate = moment(member['joined']).format('YYYY/MM/DD');
            return joinDate.includes(formatDate);
          }
        }
        if (member[key] !== null) {
          return member[filteredValue ? filteredValue : key]
            .toString()
            .toLowerCase()
            .includes(lowercasedSearch);
        }

        return null;
      });
    });
    setFilteredMember(searchedMembers);
    if (maxPage > 1) {
      jump(1);
    }
    // eslint-disable-next-line
  }, [searchedValue, filteredValue, members]);

  const removeMember = async (id, userId) => {
    const membership = { id, userId };
    try {
      let res = await AppMembershipsService.removeDriverMembership(membership);
      if (res) {
        let updatedMembers = members.filter((u) => u.userId !== userId);
        setMembers(updatedMembers);
      }
    } catch (err) {
      setError({
        display: true,
        title: 'Something went wrong',
        message: err.response
          ? err.response.data
          : 'Was unable to remove member',
        styles: 'cso',
      });
    }
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      {members.length !== 0 && (
        <Pagination
          currentCount={currentCount}
          totalData={totalData}
          next={next}
          prev={prev}
          jump={jump}
          currentPage={currentPage}
          maxPage={maxPage}
          pageNumbers={pageNumbers}
        />
      )}
      <TableStyles minHeight='500px'>
        <TableHeaderStyles
          borderTop='none'
          borderLeft='none'
          borderRight='none'
          width='100%'>
          {strings.headerData.map((title) => {
            return (
              <TableItemStyles
                flex='1'
                borderRight
                key={tableHeaderConvert[title]}
                onClick={() => {
                  changeActiveSort(tableHeaderConvert[title]);
                }}>
                {isActiveSort[tableHeaderConvert[title]] && (
                  <div>
                    {sortReverse[tableHeaderConvert[title]] ? (
                      <FaLongArrowAltDown />
                    ) : (
                      <FaLongArrowAltUp />
                    )}{' '}
                  </div>
                )}
                {title}
              </TableItemStyles>
            );
          })}
        </TableHeaderStyles>
        {/* need to pass props like below for reuseable sort */}
        {currentData().map((user) => (
          <MembersCard
            removeMember={removeMember}
            membership={props.membership}
            sortByname={sortByname}
            key={user.id}
            firstName={user.firstName}
            lastName={user.lastName}
            expiration={user.expiration}
            joined={user.joined}
            kwhs={user.kwhs}
            sessions={user.sessions}
            amount={user.amount}
            id={user.id}
            userId={user.userId}
            approved={user.approved}
            paused={user.paused}
            email={user.email}
          />
        ))}
        {members.length > 0 && !filteredMembers.length && !loading && (
          <ListPlaceholderStyles borderBottom='none'>
            <p>No results found.</p>
          </ListPlaceholderStyles>
        )}
        {members.length === 0 && !loading && (
          <ListPlaceholderStyles borderBottom='none'>
            <p>{strings.noMembers}</p>
          </ListPlaceholderStyles>
        )}
      </TableStyles>
    </>
  );
};

export default MembershipMembers;
