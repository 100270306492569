import React, { useEffect, useState } from 'react';
import { FaRegWindowClose, FaExclamationTriangle } from 'react-icons/fa';

//services
import AppChargersModalService from '../../../ChargersModals/AppChargersModalService';

//global components
import ModalButton from '../../../../../shared/Buttons/ModalButton';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  SelectStyles,
  ModalNoteStyles,
} from '../../../../../SharedStyles';

const CircuitModal = (props) => {
  const { strings } = AppChargersModalService;
  const [allCircuits, setAllCircuits] = useState([]);
  const [circuit, setCircuit] = useState('');
  const [connectorCircuits, setConnectorCircuits] = useState([]);

  useEffect(() => {
    setCircuit(props.circuit.id);
    setAllCircuits(props.meterGroup.circuits);
    setConnectorCircuits(props.connectorCircuits);
  }, [props.circuit.id, props.meterGroup.circuits, props.connectorCircuits]);

  const closeModal = () => {
    props.handleModalClose();
  };

  const handleChange = (e) => {
    setCircuit(e.target.value);
  };

  const handleCircuitChange = (connectorId, e) => {
    const cnnCircs = [...connectorCircuits];

    const cir = allCircuits.find(x => x.id === e.target.value);

    let updatedList = connectorCircuits.map(item => 
      {
        if (item.connectorId == connectorId){
          return {...item, circuit: cir};
        }
        return item;
      });

    setConnectorCircuits(updatedList);
  };

  const handleUpdateCircuit = () => {
    props.updateConnectorCircuits(connectorCircuits);
    connectorCircuits.forEach((x) => {
      props.handleConnectorDataChange(x.connectorId, 'csoCircuitId', x.circuit?.id);
    });
    
    closeModal();
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.circuitTitle}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <p>
              {strings.circuitContentOne} ({props.meterGroup.name}){' '}
              {strings.circuitContentTwo}
            </p>
          </ModalItemStyles>
          {/* Section */}
          
          {connectorCircuits.map((cnnCir) => {
            return (
            <>
              <ModalNoteStyles>
                <div className='Warning-modal'>
                  <span>
                    {`Port ${cnnCir.connectorId} ${strings.circuitBreaker} `}
                  </span>
                </div>
              </ModalNoteStyles>
              <ModalItemStyles key={cnnCir.connectorId}>
                <SelectStyles
                  value={cnnCir.circuit?.id || ''}
                  name='circuit'
                  onChange={(e) => handleCircuitChange(cnnCir.connectorId, e)}>
                  <option disabled value=''>
                    {`${strings.selectPlaceholderCircuit} ${cnnCir.connectorId}`}
                  </option>
                  {allCircuits.map((circuit) => (
                    <option key={circuit.id} value={circuit.id}>
                      {circuit.name} ({circuit.maxAmperage}a /{' '}
                      {circuit.chargingStationConnectorIds.reduce((a, b) => a + b?.connectorIds?.length, 0)} port{circuit.chargingStationConnectorIds.reduce((a, b) => a + b?.connectorIds?.length, 0) > 1 ? 's' : ''})
                    </option>
                  ))}
                </SelectStyles>
              </ModalItemStyles>
            </>
            );
          })}
          
          <ModalNoteStyles>
            <div className='Warning-modal'>
              <FaExclamationTriangle />
              <span>
                <strong>{strings.warning} -</strong> {strings.circuitWarning}
              </span>
            </div>
          </ModalNoteStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={handleUpdateCircuit}
          buttonText={strings.save}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default CircuitModal;
