import styled from 'styled-components';

export const DivStyles = styled.div`
  z-index: ${({ zIndex }) => zIndex};
  visibility: ${({ visibility }) => visibility};
  position: ${({ position }) => (position ? position : 'relative')};
  color: ${({ color }) => color};
  transform: ${({ transform }) => transform};
  background: ${({ yellowBg, dayBackground, background }) =>
    yellowBg
      ? yellowBg
      : dayBackground
      ? dayBackground
      : background && '#f7f7f7'};
  font-size: ${({ size }) => size};
  line-height: ${({ lineHeight }) => lineHeight};
  max-height: ${({ maxHeight }) => maxHeight};
  overflow-y: ${({ overflow, maxHeight }) =>
    overflow ? overflow : maxHeight && 'scroll'};
  overflow-x: ${({ overflowX }) => overflowX};
  min-height: ${({ minHeight }) => minHeight};
  display: ${({ display }) => display};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  align-items: ${({ alignItems }) => alignItems};
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};
  height: ${({ height }) => height};
  flex: ${({ flex }) => flex};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  font-weight: ${({ weight }) => weight};
  border-right: ${({ borderRight }) => borderRight};
  border-left: ${({ borderLeft }) => borderLeft};
  border-top: ${({ borderTop }) =>
    borderTop
      ? borderTop !== true
        ? borderTop
        : '1px solid #e7e7e7'
      : 'none'};
  border-bottom: ${({ borderBottom }) =>
    borderBottom
      ? borderBottom !== true
        ? borderBottom
        : '1px solid #e7e7e7'
      : 'none'};
  border: ${({ border }) => border && '1px solid #e7e7e7'};
  border-radius: ${({ borderRadius }) => borderRadius};
  text-align: ${({ align }) => align};
  align-self: ${({ alignSelf }) => alignSelf};
  line-height: ${({ lineHeight }) => lineHeight};
  cursor: ${({ cursor }) => cursor};
  &:hover {
    background: ${({ hoverBackground }) => hoverBackground};
  }
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  flexMobile: ${({ flexMobile, wrapMobile }) => `${flexMobile} ${wrapMobile}`};
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    flex-wrap: ${({ wrapMobile }) => wrapMobile && 'wrap'};
    flex: ${({ wrapMobile, flexMobile }) => wrapMobile ? ( flexMobile ? flexMobile : '100%') : null};
    justify-content: ${({ justifyContentMobile }) => justifyContentMobile};
    margin: ${({ wrapMargin }) => wrapMargin};
    min-height: ${({ mobileMinHeight }) =>
      mobileMinHeight ? mobileMinHeight : 'auto'};
    max-height: ${({ mobileMaxHeight }) =>
      mobileMaxHeight ? mobileMaxHeight : 'none'};
    padding: ${({ mobilePadding }) => mobilePadding};
  }
  a {
    line-height: 1;
    color: ${({ linkColor }) => linkColor};
    display: ${({ linkDisplay }) => linkDisplay};
    width: ${({ linkWidth }) => linkWidth};
    cursor: ${({ noCursor }) => (noCursor ? 'default' : 'pointer')};
    text-decoration: none;
    &:hover,
    &:active {
      text-decoration: none;
    }
  }
  svg {
    color: ${({ svgColor }) => (svgColor ? svgColor : '#8999ae')};
    font-size: ${({ svgSize }) => svgSize};
    margin: ${({ svgMargin }) => svgMargin};
    opacity: ${({ svgOpacity }) => svgOpacity};
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  .Card-Tools-Toggle {
    color: #2c456c;
    height: 20px;
    cursor: pointer;
    margin: 0 0 0 5px;
  }
  &.next {
    position: absolute;
  }
  &.prev {
    position: absolute;
  }
  .gm-control-active.gm-fullscreen-control {
    display: none;
  }
`;
