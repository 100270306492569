import React, { useContext } from 'react';

import AccessDeniedComponent from '../shared/AppAccessDenied/AccessDeniedComponent';

import { CsoAccountContext } from '../context/operator/getCsoAccount';
import { CsoMembershipContext } from '../context/operator/getCsoMembership';
import UpgradeModal from './Memberships/MembershipsModals/UpgradeModal';

const SmallBizAdmin = props => {
  const { account, currentUser } = useContext(CsoAccountContext);
  const { allMemberships } = useContext(CsoMembershipContext);

  if (account && account.subscriptionLevel === 2 && props.redirect) {
    if (allMemberships.length === 0) {
      return props.children;
    } else {
      return (
        <>
          {props.children}
          <UpgradeModal redirect />
        </>
      );
    }
  }
  if (props.allowTech) {
    return (
      <>
        {(account &&
          (account.subscriptionLevel === 3 &&
            currentUser &&
            currentUser.role !== 4)) ||
        (account && account.subscriptionLevel === 2) ? (
          <>{props.children}</>
        ) : !props.noMessage ? (
          <AccessDeniedComponent
            smallBiz
            permissionIssue={
              account.subscriptionLevel === 3 &&
              currentUser &&
              currentUser.role !== 4
            }
          />
        ) : null}
      </>
    );
  }
  if (props.allowCustomer) {
    return (
      <>
        {(account &&
          (account.subscriptionLevel === 3 &&
            currentUser &&
            currentUser.role !== 3)) ||
        (account && account.subscriptionLevel === 2) ? (
          <>{props.children}</>
        ) : !props.noMessage ? (
          <AccessDeniedComponent
            smallBiz
            permissionIssue={
              account.subscriptionLevel === 3 &&
              currentUser &&
              currentUser.role !== 3
            }
          />
        ) : null}
      </>
    );
  }
  if (!props.allowCustomer || !props.allowTech) {
    return (
      <>
        {(account &&
          (account.subscriptionLevel === 3 &&
            currentUser &&
            currentUser.role !== 3) &&
          (account.subscriptionLevel === 3 &&
            currentUser &&
            currentUser.role !== 4)) ||
        (account && account.subscriptionLevel === 2) ? (
          <>{props.children}</>
        ) : !props.noMessage ? (
          <AccessDeniedComponent
            smallBiz
            permissionIssue={
              account.subscriptionLevel === 3 &&
              currentUser &&
              currentUser.role !== 3 &&
              (account.subscriptionLevel === 3 &&
                currentUser &&
                currentUser.role !== 4)
            }
          />
        ) : null}
      </>
    );
  }
};

export default SmallBizAdmin;
