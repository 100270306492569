import React from 'react';

//services
import AppPrivacyPolicyService from './AppPrivacyPolicyService';

//components
import DriverPolicy from './DriverPolicy';
import CsoPolicy from './CsoPolicy';

let driverApp = localStorage.getItem('driverAccount');
let csoApp = localStorage.getItem('csoAccount');

const PrivacyPolicy = (props) => {
  const { strings } = AppPrivacyPolicyService;

  if (driverApp) {
    return <DriverPolicy strings={strings} />;
  }
  if (csoApp) {
    return <CsoPolicy strings={strings} />;
  }
};

export default PrivacyPolicy;
