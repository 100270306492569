import AppApiService from '../../../services/AppApi/AppApiService';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppCSONotificationsService = {
  updateSystemUser: function(user) {
    return AppApiService.post('updateSystemUser', null, user);
  },
  getSystemUsers: function() {
    return AppApiService.get('getSystemUsers');
  },
  strings: localStrings
};

export default AppCSONotificationsService;
