import React from 'react';

//components
import ChargerStatus from './ChargerStatus/ChargerStatus';
// import ChargerEnergy from './ChargerEnergy/ChargerEnergy';
// import ChargerMap from './ChargerMap/ChargerMap';
// import ChargingSessions from './ChargingSessions/ChargingSessions';
// import ChargerIncome from './ChargerIncome/ChargerIncome';
// import ChargerGeneral from './ChargerGeneral/ChargerGeneral';

//hooks
import { useWindowResize } from '../../../shared/hooks/useWindowSize';

//styles
import { DivStyles } from '../../../SharedStyles';

//context
// import { CsoAccountContext } from '../../../context/operator/getCsoAccount';

const NonAndShared = (props) => {
  const { strings } = props;
  const size = useWindowResize(window.innerWidth, window.innerHeight);

  return (
    <DivStyles display='flex' padding='10px'>
      <ChargerStatus
        strings={strings}
        reduceWidth={size[0] <= 770 ? false : true}
      />
    </DivStyles>
  );
};

export default NonAndShared;
