import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaRegEdit, FaRegTrashAlt, FaEllipsisV } from 'react-icons/fa';

//services
import AppDiscountSchedulesService from '../AppDiscountSchedulesService';

//global components
import { LoadingSpinner } from '../../../shared';
import { RoleCanEdit } from '../../OperatorShared';

//modals
import DeleteModal from '../DiscountSchedulesModal/DeleteModal';

//hooks
import useOnClickOutside from '../../../shared/hooks/useOnClickOutside';
import { useWindowResize } from '../../../shared/hooks/useWindowSize';
import { useContainerHeight } from '../../../shared/hooks/useContainerHeight';

//context
import { ErrorContext } from '../../../context/shared/ErrorContext';

//styles
import { DivStyles, TitleStyles, TextStyles } from '../../../SharedStyles';
import { ToolsStyles } from '../../../OperatorStyles';

const DiscountSchedulesCard = (props) => {
  const { strings } = AppDiscountSchedulesService;
  const { profile } = props;
  const CardRef = useRef();
  const OutsideRef = useRef();
  const size = useWindowResize(window.innerWidth, window.innerHeight);
  const { containerHeight, containerRef, setContainerRef } = useContainerHeight(
    50,
    CardRef
  );
  const { setError } = useContext(ErrorContext);
  const [deleteModal, setDeleteModal] = useState(false);
  const [allCoupons, setAllCoupons] = useState([]);
  const [loading, setLoading] = useState(false);

  const [openTools, setOpenTools] = useState(false);

  useOnClickOutside(OutsideRef, () => setOpenTools(false));

  //gets card container height for responsive
  useEffect(() => {
    setContainerRef(CardRef);
  }, [CardRef, setContainerRef]);

  const toggleToolBar = () => {
    setOpenTools(!openTools);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const deleteProfile = async (profileId) => {
    setLoading(true);
    try {
      let removedProfile = await AppDiscountSchedulesService.deleteDiscountSchedule(
        profileId
      );
      props.updateAccount(removedProfile);
      setLoading(false);
    } catch (err) {
      setLoading(false);

      setError({
        display: true,
        title: strings.invalidTitle,
        message: err.response.data.split(/[|]+/).pop(),
        styles: 'cso',
      });
    }
  };

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        let res = await AppDiscountSchedulesService.getCouponTokens(profile.id);
        setAllCoupons(res);
      } catch (err) {
        setError({
          display: true,
          title: 'Invalid Coupons List',
          message: err.response.data.split(/[|]+/).pop(),
          styles: 'cso',
        });
      }
    };
    fetchCoupons();
  }, [profile.id, setError]);

  return (
    <>
      {deleteModal && (
        <DeleteModal
          handleClose={toggleDeleteModal}
          deleteProfile={deleteProfile}
          profile={profile}
        />
      )}
      {loading && <LoadingSpinner />}
      <DivStyles
        display='flex'
        flexWrap='nowrap'
        alignItems='center'
        linkWidth='100%'
        linkColor='#617793'
        padding='10px'
        background={props.index % 2 === 0}
        ref={containerRef}
        onMouseEnter={() => {
          size[0] >= 768 && setOpenTools(true);
        }}
        onMouseLeave={() => {
          size[0] >= 768 && setOpenTools(false);
        }}>
        <Link to={`/subscription/discount-schedules/${profile.id}`}>
          <DivStyles
            display='flex'
            flexWrap='nowrap'
            wrapMobile
            alignItems='center'
            justifyContent='space-between'>
            {/* Left Card */}
            <DivStyles
              display='flex'
              flexWrap='nowrap'
              alignItems='center'
              wrapMobile>
              <DivStyles align='left'>
                <TitleStyles size='14px' weight='700' margin='0 0 10px'>
                  {profile.name}
                </TitleStyles>
                <TextStyles size='12px' weight='500' margin='0'>
                  {strings.cardDiscount}: {profile.discountPercentage}%
                  {allCoupons.length > 0 && (
                    <span>; {strings.activeCoupons}</span>
                  )}
                </TextStyles>
              </DivStyles>
            </DivStyles>
          </DivStyles>
        </Link>
        <FaEllipsisV
          className='Card-Tools-Toggle'
          style={size[0] >= 768 ? { display: 'none' } : { display: 'block' }}
          onClick={toggleToolBar}
        />
        <ToolsStyles
          ref={OutsideRef}
          displayTools={openTools}
          containerHeight={(containerHeight - 35) / 2}>
          <Link
            to={`/subscription/discount-schedules/${profile.id}`}
            title={strings.editTitle}
            className='Tool-Item'>
            <FaRegEdit />
          </Link>
          <RoleCanEdit roles={[1, 2]}>
            <div className='Tool-Item' onClick={toggleDeleteModal}>
              <FaRegTrashAlt title={strings.deleteTitle} />
            </div>
          </RoleCanEdit>
        </ToolsStyles>
      </DivStyles>
    </>
  );
};

export default DiscountSchedulesCard;
