import React, { useState, useEffect, useContext } from 'react';

//services
import AppFindChargerService from '../../../FindCharger/AppFindChargerService';

//components
import ChargerCard from '../../../ChargerCard/ChargerCard';

//hooks
// import UseInfiniteScroll from '../../../../shared/hooks/useInfiniteScroll';

//images
import chargerPlaceholderImage from '../../../../../assets/images/ChargingStationPlaceholder400.png';

//context
import { DriverContext } from '../../../../context/driver/getDriverContext';
import { DriverChargerContext } from '../../../../context/driver/getDriverLinkedChargers';

//styles
import {
  TitleStyles,
  ListPlaceholderStyles,
  ImageMediumStyles,
} from '../../../../SharedStyles';

const AppSearchedChargers = (props) => {
  const { driver } = useContext(DriverContext);
  const { linkedChargers } = useContext(DriverChargerContext);
  const [allChargers, setAllChargers] = useState([]);

  // const [isFetching, setIsFetching] = UseInfiniteScroll(fetchMoreListItems);

  const { chargers } = props;

  //  Scrolling function needed to pass state and make the calls
  // function fetchMoreListItems() {
  //   setTimeout(() => {
  //     setAllChargers(prevState => [
  //       ...prevState,
  //       ...Array.from(allChargers(5).keys(), n => n + prevState.length + 1)
  //     ]);
  //     setIsFetching(false);
  //   }, 2000);
  // }

  useEffect(() => {
    const fetchChargingStations = async () => {
      let updatedChargers = chargers.map((charger) => {
        for (let fav of linkedChargers) {
          if (charger.iotHubDeviceId === fav.iotHubDeviceId) {
            charger.favorite = fav.favorite;
            charger.home = fav.home;
            charger.assigned = fav.assigned;
            charger.enabledSubscription = fav.subscriptionEnabled;
          }
        }
        return charger;
      });

      const result = [];
      const map = new Map();
      for (const item of updatedChargers) {
        if (!map.has(item.iotHubDeviceId)) {
          map.set(item.iotHubDeviceId, true);
          result.push({
            ...item,
          });
        }
      }
      setAllChargers(result);
    };
    fetchChargingStations();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleFavorites = async (charger) => {
    try {
      let chargerUpdated = {
        iotHubDeviceId: charger.iotHubDeviceId,
        favorite: !charger.favorite,
        home: charger.home,
      };

      let updatedCharger = await AppFindChargerService.linkDriverCharger(
        chargerUpdated
      );

      if (updatedCharger) {
        let chargingStation = allChargers.map((station) => {
          if (station.iotHubDeviceId === charger.iotHubDeviceId) {
            station.favorite = updatedCharger.favorite;
            station.home = updatedCharger.home;
          }
          return station;
        });
        setAllChargers(chargingStation);
      }
    } catch (e) {
      console.log(e);
    }
  };

  let chargerList = allChargers
    .map((charger) => {
      return (
        <ChargerCard
          searchCard
          togglefavorites={toggleFavorites}
          driver={driver}
          key={charger.iotHubDeviceId}
          charger={charger}
          // linkedCharger={charger}
        />
      );
    })
    .sort((a, b) => {
      let chargerA = a.props.charger.distance;
      let chargerB = b.props.charger.distance;

      let distA = chargerA.split(' ');
      let distB = chargerB.split(' ');

      let removeMiA = distA.filter((i) => i !== 'mi');
      let removeMiB = distB.filter((i) => i !== 'mi');

      let valuesA = removeMiA[0].split(',');
      let valuesB = removeMiB[0].split(',');

      let newDistA = valuesA.reduce((c, d) => c + d);
      let newDistB = valuesB.reduce((c, d) => c + d);

      let distanceA = parseInt(newDistA);
      let distanceB = parseInt(newDistB);

      return distanceA > distanceB ? 1 : distanceB > distanceA ? -1 : 0;
    });

  const defaultList = (
    <>
      <ListPlaceholderStyles borderBottom='none'>
        <TitleStyles size='14px'>No charging stations found.</TitleStyles>
        <ImageMediumStyles src={chargerPlaceholderImage} alt='No Chargers' />
      </ListPlaceholderStyles>
    </>
  );

  return allChargers.length > 0 ? chargerList : defaultList;
};

export default AppSearchedChargers;
