import React, { useEffect, useState } from 'react';

//services
import AppMeterGroupsService from '../../AppMeterGroupsService';

//global components
import PropertyContainer from '../../../../shared/PropertyContainer/PropertyContainer';
import { RoleCanEdit } from '../../../OperatorShared';

//styles
import {
  PropertiesContentContainerStyles,
  PropertiesCardStyles,
  PropertiesHeaderContainerStyles,
  ButtonDivGreyStyles
} from '../../../../SharedStyles';

//helpers
import { tenPercentDiff } from '../../../helpers';
import { phaseMGTypeConvertor } from '../../../helpers';

const MeterGroupDetails = props => {
  const { strings } = AppMeterGroupsService;
  const [totalCircuitAmps, setTotalCircuitAmps] = useState(0);

  const {
    meterGroup,
    togglePhaseModal,
    toggleVoltageModal,
    toggleAmpsModal,
    toggleCircuitsModal,
    toggleLoadLimitModal
  } = props;

  // calcuate the maxamperage on the circuits button
  useEffect(() => {
    let total = 0;
    if (Object.keys(meterGroup).length > 0) {
      meterGroup.circuits.map(circ => {
        return (total += circ.maxAmperage);
      });
    }

    setTotalCircuitAmps(total);
  }, [meterGroup]);

  return (
    <PropertiesCardStyles borderBottom='1px solid #e7e7e7'>
      <PropertiesHeaderContainerStyles>
        <div className='Title'>{strings.meterGroupInfo}</div>
      </PropertiesHeaderContainerStyles>
      <PropertiesContentContainerStyles>
        <PropertyContainer
          title={strings.lot}
          content={meterGroup.lotId || '--'}
        />
        <PropertyContainer
          title={strings.chargers}
          content={
            !props.add
              ? meterGroup.chargerCount
                ? `${meterGroup.chargerCount}`
                : `--`
              : `--`
          }
        />
        <PropertyContainer
          title={strings.ports}
          content={meterGroup.portStat?.portCount >= 0 ? `${meterGroup.portStat?.portCount}/${meterGroup.portStat?.chargingPortCount}` : '--'}
        />
        <PropertyContainer
          title={strings.phaseType}
          content={
            meterGroup.phaseType
              ? phaseMGTypeConvertor[meterGroup.phaseType]
              : '--'
          }
          handleClick={
            <RoleCanEdit roles={[1]}>
              <ButtonDivGreyStyles
                size='11px'
                padding='3px 20px'
                margin='0'
                background='#a5a5a5'
                border
                onClick={togglePhaseModal}>
                {strings.change}
              </ButtonDivGreyStyles>
            </RoleCanEdit>
          }
        />
        <PropertyContainer
          title={strings.voltage}
          content={meterGroup.voltage}
          handleClick={
            <RoleCanEdit roles={[1]}>
              <ButtonDivGreyStyles
                size='11px'
                padding='3px 20px'
                margin='0'
                background='#a5a5a5'
                border
                onClick={toggleVoltageModal}>
                {strings.change}
              </ButtonDivGreyStyles>
            </RoleCanEdit>
          }
        />
        <PropertyContainer
          title={strings.circuits}
          content={
            meterGroup.circuitCount
              ? `${
                  !props.add
                    ? meterGroup.circuitCount
                    : meterGroup.circuits.length
                } (${totalCircuitAmps}A)`
              : '--'
          }
          handleClick={
            <RoleCanEdit roles={[1]}>
              <ButtonDivGreyStyles
                size='11px'
                padding='3px 20px'
                margin='0'
                background='#a5a5a5'
                border
                onClick={toggleCircuitsModal}>
                {strings.change}
              </ButtonDivGreyStyles>
            </RoleCanEdit>
          }
        />
        <PropertyContainer
          title={strings.maxAmps}
          content={meterGroup.maxAmps ? `${meterGroup.maxAmps}A` : '--'}
          handleClick={
            <RoleCanEdit roles={[1]}>
              <ButtonDivGreyStyles
                size='11px'
                padding='3px 20px'
                margin='0'
                background='#a5a5a5'
                border
                onClick={toggleAmpsModal}>
                {strings.change}
              </ButtonDivGreyStyles>
            </RoleCanEdit>
          }
        />
        <PropertyContainer
          title={strings.loadLimit}
          content={meterGroup.loadLimit ? `${meterGroup.loadLimit}A` : '--'}
          handleClick={
            <RoleCanEdit roles={[1]}>
              <ButtonDivGreyStyles
                size='11px'
                padding='3px 20px'
                margin='0'
                background='#a5a5a5'
                border
                onClick={toggleLoadLimitModal}>
                {strings.change}
              </ButtonDivGreyStyles>
            </RoleCanEdit>
          }
        />
        <PropertyContainer
          color={tenPercentDiff(meterGroup.loadLimit, meterGroup.portStat?.currentLoad)}
          title={strings.currentLoad}
          content={
            meterGroup.portStat?.currentLoad
              ? `${meterGroup.portStat.currentLoad.toFixed(0)}A`
              : '--'
          }
        />
      </PropertiesContentContainerStyles>
    </PropertiesCardStyles>
  );
};

export default MeterGroupDetails;
