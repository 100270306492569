import React, { useState, useEffect, useContext } from 'react';

//service
import EnergyService from './EnergyService';

//layout
import {
  ComponentWrapper,
  OuterWrapper,
  RoleCanEdit,
  SubscriptionAccess,
} from '../../../../OperatorShared';

//components
import ReportFilter from '../../../ReportFilter';
import EnergyContent from './EnergyContent';
import LoadingSpinner from '../../../../../shared/Loading/LoadingSpinner';

//context
import { CsoAccountContext } from '../../../../../context/operator/getCsoAccount';

//helpers
import { handleFilterUpdates } from '../ChargingSessions/Details/helpers';
import { downloadMultiCSVReport } from '../../../../../shared/Download/download';
import { dateRange } from './helpers';
import {
  dateProperties,
  locationProperties,
  metergroupProperties,
  chargerProperties,
  chargerTypeProperties,
} from '../filterProperties';

//styles
import { ButtonGreyStyles, DivStyles } from '../../../../../SharedStyles';

const Energy = () => {
  const { strings } = EnergyService;
  const { account } = useContext(CsoAccountContext);
  const [filters, setFilters] = useState({
    location: [locationProperties[0]],
    metergroup: [metergroupProperties[0]],
    charger: [chargerProperties[0]],
    date: dateProperties[2],
    chargerType: [chargerTypeProperties[0]],
  });

  const [blockGenerate, setBlockGenerate] = useState(true);

  const [locSessionStrings, setLocSessionStrings] = useState([]);
  const [mgSessionStrings, setMgSessionStrings] = useState([]);
  const [chargerSessionStrings, setChargerSessionStrings] = useState([]);
  const [reportData, setReportData] = useState({});
  const [energyData, setEnergyData] = useState([]);
  const [exportReport, setExportReport] = useState({
    Date: [],
    Location: [],
    Chargers: [],
    Metergroup: [],
    ChargerType: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { locStr, mgStr, chargerStr } = handleFilterUpdates(
      account,
      filters,
      strings
    );
    setLocSessionStrings(locStr);
    setMgSessionStrings(mgStr);
    setChargerSessionStrings(chargerStr.flat());
  }, [filters, strings, account]);

  useEffect(() => {
    generateReport();
    // eslint-disable-next-line
  }, []);

  const generateReport = async () => {
    try {
      const { startDate, endDate, groupBy } = dateRange(filters.date);

      let query = {
        startDate: startDate,
        endDate: endDate,
        groupBy: groupBy,
        charger: filters.charger,
        location: filters.location,
        chargerType: filters.chargerType,
        metergroup: filters.metergroup,
      };

      const res = await EnergyService.generateEnergySummaryReport(query);
      console.log(res);
      setReportData(res);
      setBlockGenerate(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleGenerateReport = () => {
    setLoading(true);
    generateReport();
  };

  const fetchReportData = (data) => {
    setEnergyData(data);
  };

  const handleDownload = () => {
    const { startDate, endDate } = dateRange(filters.date);

    let reportExport = reportData;
    delete reportExport.energyData;
    delete reportExport.sessionsAmt;
    delete reportExport.otherAmt;

    let refactorFilters = {};
    Object.entries(filters).forEach(([key, value]) => {
      Object.entries(exportReport).forEach(([k, v]) => {
        Object.entries(v).forEach(([a]) => {
          if (a === value.toString()) {
            refactorFilters = { ...refactorFilters, [key]: v[a] };
          }
        });
      });
    });

    downloadMultiCSVReport(`Energy-Summary-${Date.now()}`, [
      { title: 'Selected Filters', voidFormat: true, data: [refactorFilters] },
      {
        title: 'Filtered Range',
        data: [{ beginningRange: startDate, endingRange: endDate }],
      },
      { title: 'Summaries', data: [reportExport] },
      { title: 'Energy Report', data: energyData },
    ]);
  };

  const updateReportFilters = (name, structure) => {
    setExportReport((prev) => ({ ...prev, [name]: structure }));
  };

  useEffect(() => {
    //check if strings include whats in filter if not reset filter
    let someLoc = locSessionStrings.some((l) => {
      if (l === strings.filterLocationList[0]) {
        l = locationProperties[0];
      }
      return filters.location.includes(l);
    });

    let someMg = mgSessionStrings.some((m) => {
      if (m === strings.filterMetergroupList[0]) {
        m = metergroupProperties[0];
      }
      return filters.metergroup.includes(m);
    });

    let someChar = chargerSessionStrings.some((c) => {
      if (c === strings.filterChargersList[0]) {
        c = chargerProperties[0];
      }
      return filters.charger.includes(c);
    });

    if (!someLoc) {
      setFilters((prev) => ({
        ...prev,
        location: [locationProperties[0]],
      }));
    }
    if (!someMg) {
      setFilters((prev) => ({
        ...prev,
        metergroup: [metergroupProperties[0]],
      }));
    }
    if (!someChar) {
      setFilters((prev) => ({
        ...prev,
        charger: [chargerProperties[0]],
      }));
    }

    // eslint-disable-next-line
  }, [locSessionStrings, mgSessionStrings, chargerSessionStrings]);

  const handleFilterChange = (type, filter) => {
    setBlockGenerate(false);
    switch (true) {
      case type === 'Location':
        if (filter.includes(locationProperties[0])) {
          return setFilters({
            ...filters,
            location: [locationProperties[0]],
          });
        } else {
          return setFilters({ ...filters, location: filter });
        }
      case type === 'Metergroup':
        if (filter.includes(metergroupProperties[0])) {
          return setFilters({
            ...filters,
            metergroup: [metergroupProperties[0]],
          });
        } else {
          return setFilters({ ...filters, metergroup: filter });
        }
      case type === 'Chargers':
        if (filter.includes(locationProperties[0])) {
          return setFilters({
            ...filters,
            charger: [locationProperties[0]],
          });
        } else {
          return setFilters({ ...filters, charger: filter });
        }
      case type === 'Date':
        return setFilters({ ...filters, date: filter });
      case type === 'ChargerType':
        if (filter.includes(chargerTypeProperties[0])) {
          return setFilters({
            ...filters,
            chargerType: [chargerTypeProperties[0]],
          });
        } else if (filter.includes(chargerTypeProperties[1])) {
          return setFilters({
            ...filters,
            chargerType: [chargerTypeProperties[1]],
          });
        } else if (filter.includes(chargerTypeProperties[2])) {
          return setFilters({
            ...filters,
            chargerType: [chargerTypeProperties[2]],
          });
        } else {
          return setFilters({ ...filters, chargerType: filter });
        }
      default:
        return setFilters({
          location: [locationProperties[0]],
          metergroup: [metergroupProperties[0]],
          charger: [chargerProperties[0]],
          date: dateProperties[2],
          chargerType: [chargerTypeProperties[0]],
        });
    }
  };

  return (
    <OuterWrapper title={strings.pageTitle} maxWidth='1200px' internal path='/'>
      <SubscriptionAccess levels={[1, 2, 3]}>
        <RoleCanEdit roles={[1, 2, 4]}>
          <ComponentWrapper title={strings.pageTitle}>
            {loading && <LoadingSpinner />}
            <DivStyles>
              <DivStyles
                display='flex'
                alignItems='center'
                justifyContent='space-between'>
                <ReportFilter
                  width='200px'
                  margin='10px 10px 5px'
                  label='Energy Date'
                  name='Date'
                  updateReportFilters={updateReportFilters}
                  filterBy={strings.filterDateList}
                  filterProps={dateProperties}
                  filteredValues={filters.date}
                  handleFilterChange={handleFilterChange}
                />
                <DivStyles display='flex' alignItems='center'>
                  <ButtonGreyStyles
                    padding='5px 8px'
                    disabled={blockGenerate}
                    margin='0 10px 0 0'
                    onClick={handleGenerateReport}>
                    {'generate'}
                  </ButtonGreyStyles>
                  <ButtonGreyStyles
                    padding='5px 8px'
                    margin='0 10px 0 0'
                    disabled={
                      Object.keys(reportData).length > 0 &&
                      reportData.energyData.length === 0
                    }
                    onClick={handleDownload}>
                    {strings.export}
                  </ButtonGreyStyles>
                </DivStyles>
              </DivStyles>
              <DivStyles display='flex' justifyContent='space-between'>
                <ReportFilter
                  margin='5px 10px'
                  borderRadius='0'
                  noDropdown
                  multiple
                  multipleSize='3'
                  label='Charger Locations'
                  name='Location'
                  updateReportFilters={updateReportFilters}
                  filterBy={locSessionStrings}
                  filterProps={locationProperties}
                  filteredValues={filters.location}
                  handleFilterChange={handleFilterChange}
                />
                <ReportFilter
                  margin='5px 10px'
                  borderRadius='0'
                  noDropdown
                  multiple
                  multipleSize='3'
                  label='Metergroups'
                  name='Metergroup'
                  updateReportFilters={updateReportFilters}
                  filterBy={mgSessionStrings}
                  filterProps={metergroupProperties}
                  filteredValues={filters.metergroup}
                  handleFilterChange={handleFilterChange}
                />
                <ReportFilter
                  margin='5px 10px'
                  borderRadius='0'
                  noDropdown
                  multiple
                  multipleSize='3'
                  label='Chargers'
                  name='Chargers'
                  updateReportFilters={updateReportFilters}
                  filterBy={chargerSessionStrings}
                  filterProps={chargerProperties}
                  filteredValues={filters.charger}
                  handleFilterChange={handleFilterChange}
                />
                <ReportFilter
                  margin='5px 10px 5px 0'
                  borderRadius='0'
                  noDropdown
                  multiple
                  multipleSize='3'
                  label='Charger Types'
                  name='ChargerType'
                  updateReportFilters={updateReportFilters}
                  filterBy={strings.filterChargerType}
                  filterProps={chargerTypeProperties}
                  filteredValues={filters.chargerType}
                  handleFilterChange={handleFilterChange}
                />
              </DivStyles>
            </DivStyles>
            {/* list of filtered results */}
            <EnergyContent
              loading={loading}
              filters={filters}
              strings={strings}
              account={account}
              reportData={reportData}
              fetchReportData={fetchReportData}
            />
          </ComponentWrapper>
        </RoleCanEdit>
      </SubscriptionAccess>
    </OuterWrapper>
  );
};

export default Energy;
