import React, { useEffect, useCallback } from 'react';

//component
import ConnectorImages from '../../../../shared/ConnectorImages/ConnectorImages';

//styles
import { ListCardStyles } from '../../../../DriverStyles';
import { DivStyles, TextStyles } from '../../../../SharedStyles';

const MembershipContent = (props) => {
  const { membership, strings } = props;

  const setAvailableUnits = useCallback(() => {
    let total = 0;
    if (membership.connectors && membership.connectors.length > 0) {
      membership.connectors.forEach((connector) => {
        total += connector.activeConnectorCount;
      });
    } else {
      return strings.none;
    }
    return `${total}: `;
  }, [membership.connectors, strings]);

  useEffect(() => {
    setAvailableUnits();
  }, [props.membership, setAvailableUnits]);

  return (
    <ListCardStyles margin='10px 0'>
      <div className='Content'>
        {!membership.enabled && (
          <TextStyles color='red' size='14px' margin='0' weight='600'>
            {strings.currentlyUnavailable}
          </TextStyles>
        )}
        <span className='Span'>
          <h5 className='Label'>{strings.labelLocation}:</h5>
          <div className='Data'>
            {!membership.locations.length && strings.none}
          </div>
        </span>
        <DivStyles size='14px' margin='0 0 0 25px'>
          <DivStyles margin='0 0 10px'>
            <TextStyles margin='0'>{membership.locationDescription}</TextStyles>
          </DivStyles>
        </DivStyles>
        <span className='Span'>
          <h5 className='Label'>{strings.labelAccessibleTo}:</h5>
          <p className='Data'>
            {membership.availabilityTo
              ? membership.availabilityTo
              : strings.none}
          </p>
        </span>
        <span className='Span'>
          <h5 className='Label'>{strings.labelChargingRate}:</h5>
          <p className='Data'>
            {membership.discountSchedulePercentage
              ? `${membership.discountSchedulePercentage}%`
              : strings.none}
          </p>
        </span>
        <span className='Span'>
          <h5 className='Label'>{strings.labelNumberOfChargers}:</h5>
          <p className='Data'>
            {membership.chargingStationCount
              ? membership.chargingStationCount
              : strings.none}
          </p>
        </span>
        <span className='Span'>
          <h5 className='Label'>{strings.labelUnitsAvailable}:</h5>
          <p className='Data'>
            {setAvailableUnits()}
            {membership.connectors.map((connector) => {
              return (
                <span key={connector.connectorName}>
                  {connector.connectorName} ({connector.activeConnectorCount}){' '}
                </span>
              );
            })}
          </p>
        </span>
        <span className='Span'>
          <h5 className='Label'>{strings.labelConnectorTypes}:</h5>
          <div className='Data'>
            {/* {membership.connectors.length === 0 && strings.none} */}
            <ConnectorImages
              charger={membership}
              showName
              alignItems='flex-start'
              margin='0 10px'
              text={strings.none}
            />{' '}
          </div>
        </span>
      </div>
    </ListCardStyles>
  );
};

export default MembershipContent;
