import React, { useState, useEffect } from 'react';

//styles
import { TitleStyles } from '../../../../../SharedStyles';

//helpers
import { milesToKm } from '../../../../../shared/Helpers';

const ProgressChildDisplay = ({
  diagnostics,
  isConvert,
  maxRange,
  startRange,
  batteryCapacity,
  chargingSessionEta,
  strings,
}) => {
  const [addedRange, setAddedRange] = useState(startRange);
  const [addedkWh, setAddedkWh] = useState(0);
  const [addedPercentage, setAddedPercentage] = useState(0);

  useEffect(() => {
    if (Object.keys(chargingSessionEta).length > 0) {
      setAddedRange(startRange + chargingSessionEta.addedRange);
      setAddedkWh(
        Math.ceil(
          parseInt(startRange) / (parseInt(maxRange) / batteryCapacity)
        ) + chargingSessionEta.kwhDelivered
      );
      setAddedPercentage(
        ((startRange + Math.floor(chargingSessionEta.addedRange)) / maxRange) *
          100
      );
    } else {
      setAddedRange(startRange);
      setAddedkWh(0);
      setAddedPercentage(0);
    }
  }, [chargingSessionEta, diagnostics, startRange, maxRange, batteryCapacity]);

  switch (true) {
    case isConvert.isKwh:
      return (
        <>
          <TitleStyles color='#fff' size='20px'>
            {strings.energy}
          </TitleStyles>
          <TitleStyles color='#fff' size='35px'>
            {addedkWh.toFixed(1)} kWh
          </TitleStyles>
        </>
      );
    case isConvert.isPercentage:
      return (
        <>
          <TitleStyles color='#fff' size='20px'>
            {strings.percentage}
          </TitleStyles>
          <TitleStyles color='#fff' size='35px'>
            {addedPercentage.toFixed(0)}%
          </TitleStyles>
        </>
      );
    default:
      return (
        <>
          <TitleStyles color='#fff' size='20px'>
            {strings.range}
          </TitleStyles>
          <TitleStyles color='#fff' size='35px'>
            {milesToKm(
              diagnostics ? diagnostics.unitOfMeasurement : 'Miles',
              Math.floor(addedRange).toFixed(0)
            )}
          </TitleStyles>
        </>
      );
  }
};

export default ProgressChildDisplay;
