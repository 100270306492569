import React from 'react';
import { Link } from 'react-router-dom';
import { FaRegWindowClose } from 'react-icons/fa';

///global components
import ModalButton from '../../../shared/Buttons/ModalButton';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
} from '../../../SharedStyles';

const UpgradeModal = (props) => {
  const { handleCloseModal, strings } = props;

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.limitReached}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <p>
              {strings.limitContent}{' '}
              <Link to='/subscription'>{strings.limitUpgradeTo}</Link>{' '}
              {strings.limitContentTwo}
            </p>
          </ModalItemStyles>
          {/* Section */}
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={closeModal}
          buttonText={strings.ok}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default UpgradeModal;
