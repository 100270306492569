import React, { useEffect } from 'react';

//hooks
import { useConnectors } from '../OperatorShared/hooks/useConnectors';

//styles
import {
  TextStyles,
  TitleStyles,
  DivStyles,
  ImageThumbnailStyles,
} from '../../SharedStyles';

const ChargerHeader = (props) => {
  const { charger } = props;
  const [connectors, setEvses] = useConnectors();

  useEffect(() => {
    setEvses(charger.connectors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charger.evses]);

  return (
    <>
      <DivStyles
        padding='10px'
        borderBottom
        display='flex'
        flexWrap='nowrap'
        wrapMobile
        alignItems='center'
        justifyContent='space-between'>
        {/* Left Card */}
        <DivStyles
          display='flex'
          flexWrap='nowrap'
          alignItems='center'
          wrapMobile>
          <ImageThumbnailStyles
            maxWidth='50px'
            margin='0 10px 0 0'
            removeMobileImg
            src={charger.vendorIcon}
            alt={charger.iotHubDeviceId}
          />
          <DivStyles align='left'>
            <TitleStyles size='14px' weight='700' margin='0'>
              {charger.chargerName || charger.iotHubDeviceId}
            </TitleStyles>
            <TextStyles size='12px' weight='500' margin='0'>
              {charger.chargerAddress}
            </TextStyles>
          </DivStyles>
        </DivStyles>
        {/* Right Card */}
        <DivStyles
          display='flex'
          flexWrap='nowrap'
          alignItems='center'
          align='right'
          wrapMargin='5px 0 0'
          wrapMobile>
          {/* device ID */}
          <DivStyles
            color='#8999ae'
            lineHeight='1'
            borderRight='1px solid #000'
            size='12px'
            padding='0 10px'>
            ({charger.iotHubDeviceId})
          </DivStyles>
          {/* charger power */}
          <DivStyles
            color='#8999ae'
            lineHeight='1'
            borderRight='1px solid #000'
            size='12px'
            padding='0 10px'>
            {charger.power} kW
          </DivStyles>
          {/* charger Connector Type */}
          <DivStyles
            color='#8999ae'
            lineHeight='1'
            size='12px'
            padding='0 10px'
            margin='0 5px 0 0'>
            {connectors.map((c, idx) => {
              return `${c}${idx === connectors.length - 1 ? '' : ', '}`;
            }) || 0}
          </DivStyles>
        </DivStyles>
      </DivStyles>
    </>
  );
};

export default ChargerHeader;
