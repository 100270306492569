import styled from 'styled-components';

export const ConnectorStyles = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  justify-content: center;
`;

export const ConnectorSpanStyles = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &:nth-child(even) {
    margin: ${({ margin }) => margin};
  }
`;
