import strings from './strings';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppPrivacyPolicyService = {
  strings: localStrings,
};

export default AppPrivacyPolicyService;
