import React, { useRef, useState, useContext, useEffect } from 'react';

//layout
import { OuterWrapper, ComponentWrapper } from '../../OperatorShared';
import Button from '../../../shared/Buttons/Button';

//services
import AppLocationsService from '../AppLocationsService';

//components
import LocationAddress from '../LocationsForms/LocationDetails/LocationAddress';
import LocationDetails from '../LocationsForms/LocationDetails/LocationDetails';
import LocationHeader from './LocationDetails/LocationHeader';
import LocationBranding from './LocationBranding/LocationBranding';

//global components
import ToggleSwitch from '../../OperatorShared/ToggleSwitch/CsoToggleSwitch';
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';
import EnterpriseAdmin from '../../EnterpriseAdmin';
import RouteLeavingGuard from '../../../shared/LeaveGuard/LeaveGuard';

//modals
import TransformerModal from '../LocationsModals/TransformerModal';
import LoadLimitModal from '../LocationsModals/LoadLimitModal';
import LocationEditModal from '../LocationsModals/LocationEditModal';
import MissingData from '../../OperatorShared/Modals/MissingData';

//hooks
import { useRequiredFields } from '../../../shared/hooks/useRequiredFields';
import { useObjectBuilder } from '../../../shared/LeaveGuard/useObjectBuilder';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import { ErrorContext } from '../../../context/shared/ErrorContext';

//styles
import { EnableContainerStyles } from '../../../OperatorStyles';
import { TitleStyles } from '../../../SharedStyles';

//error Class
import { ErrorResponse } from '../../../shared/ErrorResponse';

const LocationsAdd = props => {
  const { strings } = AppLocationsService;
  const { account, setAccount } = useContext(CsoAccountContext);
  const { error, setError } = useContext(ErrorContext);

  const requiredAddressRef = useRef();
  const requiredCityRef = useRef();
  const requiredStateRef = useRef();
  const requiredZipRef = useRef();
  const requiredCountryRef = useRef();

  const [location, setLocation] = useState({
    enabled: true
  });
  const [prevLocation] = useState({ enabled: true });
  const [loading, setLoading] = useState(false);
  const [transformerModal, setTransformerModal] = useState(false);
  const [loadLimitModal, setLoadLimitModal] = useState(false);
  const [editLocationModal, setEditLocationModal] = useState(false);
  const [warning, setWarning] = useState({
    display: false,
    message: ''
  });

  //leaveGuard Effect
  const buttonRef = useRef(null);
  const [setRequiredOptions, missingRequiredFields] = useRequiredFields([
    requiredAddressRef,
    requiredCityRef,
    requiredStateRef,
    requiredZipRef,
    requiredCountryRef
  ]);
  const [isBlocking, setIsBlocking, setCustomLoc] = useObjectBuilder(
    location,
    prevLocation
  );

  useEffect(() => {
    setCustomLoc(location);
    setRequiredOptions([
      requiredAddressRef,
      requiredCityRef,
      requiredStateRef,
      requiredZipRef,
      requiredCountryRef
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // useEffect(() => {
  //   setIsBlocking(true);
  // }, [error]);

  const toggleBlocking = () => {
    setIsBlocking(!isBlocking);
  };
  //end LeaveGuard Effect

  const closeWarning = () => {
    setWarning({ display: false, message: '' });
  };

  //displays Transformer modal
  const toggleTransformerModal = () => {
    setTransformerModal(!transformerModal);
  };

  //displays limitmodal
  const toggleLoadLimitModal = () => {
    setLoadLimitModal(!loadLimitModal);
  };

  //displays the edit location modal
  const toggleLocationModal = () => {
    setEditLocationModal(!editLocationModal);
  };

  //enable and disables location
  const handleEnable = e => {
    setLocation({
      ...location,
      enabled: !location.enabled
    });
  };

  //handles the update of information from the modals
  const handleDataChange = (key, value) => {
    setLocation({ ...location, [key]: value });
  };

  const handleSubmitCheck = () => {
    buttonRef.current.click();
  };

  //checked form required fields are not empty

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      if (missingRequiredFields) {
        throw new ErrorResponse(
          'frontend',
          'Location Address is required.',
          'Missing Location'
        );
      }
      if (!location.name) {
        throw new ErrorResponse(
          'frontend',
          'Must have valid location data',
          'Missing Data'
        );
      }
      let updatedAccount = await AppLocationsService.createLocation(location);
      setAccount(updatedAccount);
      setIsBlocking(false);
      if (updatedAccount) {
        setTimeout(() => {
          props.history.push('/locations');
        }, 400);
      }
    } catch (err) {
      if (err.name === 'frontend') {
        setError({
          display: true,
          title: err.title,
          message: err.message,
          styles: 'cso'
        });
      } else {
        setError({
          display: true,
          title: 'Unsuccessful',
          message: err.response.data.split(/[|]+/).pop(),
          styles: 'cso'
        });
      }
      setLoading(false);
      setIsBlocking(true);
    }
  };

  console.log('location');

  return (
    <OuterWrapper
      title={strings.locationInfoTitleAdd}
      internal
      myForm
      path='/locations'
      roles={[1]}>
      {/* =====================Enterprise Only===================================== */}
      <EnterpriseAdmin>
        <RouteLeavingGuard
          cso
          when={isBlocking}
          toggleBlocking={toggleBlocking}
          error={error.display}
          action={handleSubmitCheck}
          loading={loading}
          requiredFields={missingRequiredFields}
          navigate={path => props.history.push(path)}
          shouldBlockNavigation={location => {
            if (isBlocking) {
              return true;
            }
            return false;
          }}
        />
        <ComponentWrapper title={strings.locationInfoTitleAdd} roles={[1]}>
          {loading && <LoadingSpinner />}
          {warning.display && (
            <MissingData message={warning.message} handleClose={closeWarning} />
          )}
          {transformerModal && (
            <TransformerModal
              handleCloseModal={toggleTransformerModal}
              handleDataChange={handleDataChange}
              kva={location.transformerPower}
            />
          )}
          {loadLimitModal && (
            <LoadLimitModal
              handleCloseModal={toggleLoadLimitModal}
              handleDataChange={handleDataChange}
              loadLimit={location.loadLimit}
            />
          )}
          {editLocationModal && (
            <LocationEditModal
              // ref={requiredNameRef}
              handleCloseModal={toggleLocationModal}
              handleDataChange={handleDataChange}
              loc={location}
            />
          )}
          <form id='myForm' onSubmit={handleSubmit}>
            <LocationHeader
              location={location}
              toggleLocationModal={toggleLocationModal}
              strings={strings}
            />
            <EnableContainerStyles>
              <TitleStyles size='18px' margin='0' weight='500'>
                {strings.formEnableLocation}
              </TitleStyles>
              <ToggleSwitch
                labelDisplay='none'
                labelMargin='0'
                name='enabled'
                disabledModal
                type='location'
                canEdit={[1]}
                checked={!!location.enabled}
                handleChange={handleEnable}
              />
            </EnableContainerStyles>
            <LocationAddress
              ref={{
                requiredStateRef,
                requiredZipRef,
                requiredCityRef,
                requiredAddressRef,
                requiredCountryRef
              }}
              strings={strings}
              handleDataChange={handleDataChange}
              location={location}
            />
            <LocationDetails
              location={location}
              toggleLoadLimitModal={toggleLoadLimitModal}
              toggleTransformerModal={toggleTransformerModal}
            />
            {account.enableBranding && (
              <LocationBranding
                strings={strings}
                canEdit={[1]}
                handleDataChange={handleDataChange}
                location={location}
              />
            )}
          </form>
        </ComponentWrapper>
        <Button
          cso
          hasMobile
          formSubmit
          ref={buttonRef}
          buttonText={strings.save}
          roles={[1, 2]}
        />
      </EnterpriseAdmin>
      {/* ========================================================================= */}
    </OuterWrapper>
  );
};

export default LocationsAdd;
