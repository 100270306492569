import React, { useEffect, useState, useContext, useRef } from 'react';
import axios from 'axios';
import useSupercluster from 'use-supercluster';
import GoogleMapReact from 'google-map-react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppDashboardService from '../../AppDashboardService';

//hooks
import { useRouter } from '../../../../shared/hooks/useRouter';

//context
import { CsoChargerContext } from '../../../../context/operator/getCsoChargers';
import { CsoAccountContext } from '../../../../context/operator/getCsoAccount';

//key
import { MAP_API } from '../../../../shared';

//styles
import {
  DivStyles,
  TitleStyles,
  ImageThumbnailStyles,
  TextStyles,
} from '../../../../SharedStyles';

// InfoWindow component
const InfoWindow = (props) => {
  const { charger } = props;
  const router = useRouter();
  const infoWindowStyle = {
    position: 'relative',
    width: 200,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 10,
    fontSize: 14,
    zIndex: 100,
    margin: '0 5px',
  };

  const handleRedirect = () => {
    router.history.push(`/chargers/${charger.iotHubDeviceId}`);
  };
  return (
    <div className='Info-window' style={infoWindowStyle}>
      <div style={{ fontSize: 16 }}>
        {charger.chargerName}
        <FaRegWindowClose
          style={{
            float: 'right',
            cursor: 'pointer',
          }}
          onClick={() => props.closeInfoWindow(charger.iotHubDeviceId)}
        />
      </div>
      <div style={{ fontSize: 14 }}>
        <div style={{ color: 'grey' }}>{charger.chargerAddress} </div>
      </div>
      <div style={{ fontSize: 14, color: 'grey' }}>
        {charger.connectors.map((c, i) => (
          <span key={i}>{c.connectorName} </span>
        ))}
      </div>
      <div style={{ fontSize: 14, color: 'green' }}>
        {charger.chargerConnectedStatus}
      </div>
      <div className='app-button' onClick={handleRedirect}>
        Go
      </div>
    </div>
  );
};

const ClusterMarker = ({ children, ...props }) => {
  let windowsOpen = 
    props.cluster.properties.cluster &&
    props.chargers.some((c) => c.clusterShow);

  return (
    <>
      {!windowsOpen && children}
      <DivStyles
        transform='translate(-50%,-50%)'
        width='1150px'
        display='flex'
        justifyContent='center'
        className='Cluster'>
        {props.cluster.properties.cluster &&
          props.chargers.map((c) =>
            c.clusterShow ? (
              <InfoWindow
                clusterMarker
                key={c.iotHubDeviceId}
                {...props}
                charger={c}
              />
            ) : null
          )}
      </DivStyles>
    </>
  );
};

// Marker component
const Marker = (props) => {
  // if (props.user) {
  //   return (
  //     <>
  //       <ImageThumbnailStyles
  //         transform='translate(-50%, -50%)'
  //         src={require('../../../../../assets/images/blue-dot.png').default}
  //         alt='Your Location'
  //       />
  //     </>
  //   );
  // }
  return (
    <div className='Marker'>
      <ImageThumbnailStyles
        transform='translate(-50%, -50%)'
        cursor='pointer'
        src={require('../../../../../assets/images/spotlight-poi2.png').default}
        alt={props.charger.iotHubDeviceId}
      />
      {!props.charger.cluster && props.charger.show && (
        <InfoWindow pinMarker {...props} charger={props.charger} />
      )}
    </div>
  );
};

const ChargerMap = (props) => {
  const { strings } = AppDashboardService;
  const { account } = useContext(CsoAccountContext);
  const { allChargers } = useContext(CsoChargerContext);
  const [chargers, setChargers] = useState(allChargers);
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [coordinates, setCoordinates] = useState([]);
  const [failedMap, setFailedMap] = useState(false);

  useEffect(() => {
    setChargers(allChargers);
  }, [allChargers]);

  useEffect(() => {
    const fetchHeadQuartersLocation = async () => {
      let headQuarters = `${account.headquartersAddress1}${account.headquartersAddress2}${account.headquartersCity},${account.headquartersState}${account.headquartersZip}`;
      let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${headQuarters}&key=${MAP_API}`;

      let googleResults = await axios.get(url);
      if (
        googleResults.data.error_message ||
        googleResults.data.status === 'ZERO_RESULTS'
      ) {
        setFailedMap(true);
        return;
      }

      setCoordinates([
        googleResults.data.results[0].geometry.location.lat,
        googleResults.data.results[0].geometry.location.lng,
      ]);
    };
    fetchHeadQuartersLocation();
  }, [account]);

  const points = chargers.map((charger) => ({
    type: 'Feature',
    properties: {
      cluster: false,
      ...charger,
    },
    geometry: {
      type: 'Point',
      coordinates: [
        charger.location.coordinates[1],
        charger.location.coordinates[0],
      ],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  //console.log(clusters);

  // onChildClick callback can take two arguments: key and childProps
  const onChildClickCallback = (key, ...childProps) => {
    const { isCluster } = childProps[0];

    if (isCluster) {
      const { id } = childProps[0].cluster;
      const [longitude, latitude] = childProps[0].cluster.geometry.coordinates;

      const expansionZoom = Math.min(
        supercluster.getClusterExpansionZoom(id),
        20
      );

      if (expansionZoom > 19) {
        let items = supercluster.getLeaves(id);
        let allChargers = chargers.map((c) => {
          items.forEach((i) => {
            if (c.iotHubDeviceId === i.properties.iotHubDeviceId) {
              c.show = false;
              c.clusterShow = true;
            }
          });
          return c;
        });

        setChargers(allChargers);
        mapRef.current.setZoom(expansionZoom);
        mapRef.current.panTo({
          lat: latitude,
          lng: longitude,
        });
      } else {
        mapRef.current.setZoom(expansionZoom);
        mapRef.current.panTo({
          lat: latitude,
          lng: longitude,
        });
      }
    }

    if (!isCluster) {
      const { lat, lng } = childProps[0];
      let allChargers = chargers.map((c) => {
        if (c.iotHubDeviceId === key) {
          c.show = true;
        } else {
          c.show = false;
        }
        return c;
      });
      setChargers(allChargers);
      mapRef.current.panTo({
        lat,
        lng,
      });
    }
  };

  //closes info window
  const closeInfoWindow = (chargerId) => {
    let allChargers = chargers.map((c) => {
      if (c.iotHubDeviceId === chargerId) {
        c.show = false;
        c.clusterShow = false;
      }
      return c;
    });
    setChargers(allChargers);
  };

  const getMapBounds = (map, maps) => {
    const bounds = new maps.LatLngBounds();

    chargers.forEach((charger) => {
      bounds.extend(
        new maps.LatLng(
          charger.location.coordinates[0],
          charger.location.coordinates[1]
        )
      );
    });
    return bounds;
  };

  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };

  const apiIsLoaded = (map, maps) => {
    if (map) {
      const bounds = getMapBounds(map, maps);
      map.fitBounds(bounds);
      bindResizeListener(map, maps, bounds);
    }
  };

  if (failedMap) {
    return (
      <DivStyles
        width='100%'
        height='20vh'
        borderTop='1px solid #e7e7e7'
        borderLeft='1px solid #e7e7e7'
        borderRight='1px solid #e7e7e7'
        borderBottom='1px solid #e7e7e7'>
        <TitleStyles
          size='14px'
          align='left'
          padding='5px'
          borderBottom
          margin='0'
          weight='400'>
          {strings.chargerMapsTitle}
        </TitleStyles>
        <DivStyles
          height='90%'
          width='100%'
          display='flex'
          alignItems='center'
          justifyContent='center'>
          <TextStyles>
            Charger map is currently unavailable at this time.
          </TextStyles>
        </DivStyles>
      </DivStyles>
    );
  }

  console.log(clusters);

  return (
    <DivStyles
      width='100%'
      height='50vh'
      borderTop='1px solid #e7e7e7'
      borderLeft='1px solid #e7e7e7'
      borderRight='1px solid #e7e7e7'
      borderBottom='1px solid #e7e7e7'>
      <TitleStyles
        size='14px'
        align='left'
        padding='5px'
        borderBottom
        margin='0'
        weight='400'>
        {strings.chargerMapsTitle}
      </TitleStyles>
      <DivStyles height='90%' width='100%'>
        {chargers.length > 0 && coordinates.length > 0 && (
          <GoogleMapReact
            style={{ width: '100%', height: '500' }}
            bootstrapURLKeys={{ key: MAP_API }}
            defaultCenter={{ lat: coordinates[0], lng: coordinates[1] }}
            defaultZoom={15}
            zoom={zoom}
            boundsChanged={bounds}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              mapRef.current = map;
              apiIsLoaded(map, maps, chargers);
            }}
            onChange={({ zoom, bounds }) => {
              if (zoom <= 20) {
                setZoom(zoom);
              } else {
                setZoom(20);
              }
              setBounds([
                bounds.nw.lng,
                bounds.se.lat,
                bounds.se.lng,
                bounds.nw.lat,
              ]);
            }}
            onChildClick={onChildClickCallback}>
            {clusters.map((cluster, index) => {
              const [longitude, latitude] = cluster.geometry.coordinates;
              const {
                cluster: isCluster,
                point_count: pointCount,
              } = cluster.properties;
              if (isCluster) {
                return (
                  <ClusterMarker
                    cluster={cluster}
                    chargers={index === 0 ? chargers : []}
                    closeInfoWindow={closeInfoWindow}
                    isCluster
                    key={`cluster-${cluster.id}`}
                    lat={latitude}
                    lng={longitude}>
                    <DivStyles
                      transform='translate(-50%, -50%)'
                      zIndex='2'
                      color='#fff'
                      dayBackground='#2c456c'
                      borderRadius='50%'
                      padding='10px'
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      width={`${10 + (pointCount / points.length) * 20}px`}
                      height={`${10 + (pointCount / points.length) * 20}px`}>
                      {pointCount}
                    </DivStyles>
                  </ClusterMarker>
                );
              }
              return (
                <Marker
                  {...props}
                  key={cluster.properties.iotHubDeviceId}
                  lat={latitude}
                  lng={longitude}
                  charger={cluster.properties}
                  closeInfoWindow={closeInfoWindow}
                />
              );
            })}
            {/* {coordinates.length && (
              <Marker lat={coordinates[0]} lng={coordinates[1]} user />
            )} */}
          </GoogleMapReact>
        )}
      </DivStyles>
    </DivStyles>
  );
};

export default ChargerMap;
