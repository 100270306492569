import React, { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';

//services
import AppPlatformService from '../../../../../services/AppPlatform/AppPlatformService';

//components
import SliderComponent from '../../../../shared/Slideshow/SlideShow';
import { RoleCanEdit } from '../../../OperatorShared';

//modals
import ImageModal from '../ChargerEditProps/EditModals/ImageModal';
import BrowserCameraModal from '../ChargerEditProps/EditModals/BrowserCameraModal';
import ApprovalModal from '../ChargerEditProps/EditModals/ApprovalModal';

//images
import chargerPlaceholderImage from '../../../../../assets/images/ChargingStationPlaceholder400.png';

const images = [
  {
    id: 123,
    imagePath: chargerPlaceholderImage,
    approved: true,
    default: true,
    placeholder: true,
  },
];

const ChargerImage = (props) => {
  const [imageModal, setImageModal] = useState(false);
  const [browserCamera, setBrowerCamera] = useState(false);
  const [approvalModal, setApprovalModal] = useState(false);
  const [imagesCollection, setImagesCollection] = useState([]);

  useEffect(() => {
    if (props.charger.images && props.charger.images.length > 0) {
      let imageSlider = props.charger.images
        .map((img) => {
          if (img.imagePath === props.charger.imagePath) {
            img.default = true;
          } else {
            img.default = false;
          }
          return img;
        })
        .sort((a, b) => a.approved - b.approved);
      setImagesCollection(imageSlider);
    } else {
      setImagesCollection(images);
    }
  }, [props.charger]);

  const updateDefaultImage = (imageId) => {
    let imageSlider = imagesCollection.map((img) => {
      if (img.id === imageId) {
        img.default = true;
      } else {
        img.default = false;
      }
      return img;
    });
    setImagesCollection(imageSlider);
  };

  const launchCamera = () => {
    if (AppPlatformService.isWebApp()) {
      setBrowerCamera(true);
    } else {
      setImageModal(true);
    }
  };

  const successUpload = (boo) => {
    setApprovalModal(boo);
  };

  const closeApprovalModal = () => {
    setApprovalModal(false);
  };

  const closeModal = () => {
    setImageModal(false);
    setBrowerCamera(false);
  };

  return (
    imagesCollection.length > 0 && (
      <div className='Header-image'>
        {imageModal && (
          <ImageModal
            updateCharger={props.updateCharger}
            charger={props.charger}
            account={props.account}
            handleModalClose={closeModal}
            successUpload={successUpload}
          />
        )}
        {browserCamera && (
          <BrowserCameraModal
            updateCharger={props.updateCharger}
            charger={props.charger}
            account={props.account}
            handleModalClose={closeModal}
            successUpload={successUpload}
          />
        )}
        {approvalModal && (
          <ApprovalModal handleModalClose={closeApprovalModal} />
        )}
        <SliderComponent
          charger={props.charger}
          updateDefaultImage={updateDefaultImage}
          updateCharger={props.updateCharger}
          images={imagesCollection}
          handleDataChange={props.handleDataChange}
        />
        <RoleCanEdit roles={[1, 2, 3, 4]}>
          <div className='add-photo'>
            <FaPlus onClick={launchCamera} />
          </div>
        </RoleCanEdit>
      </div>
    )
  );
};

export default ChargerImage;
