import { useLayoutEffect, useState } from 'react';

export const useWindowResize = (initalWidth, initalHeight) => {
  const [size, setSize] = useState([initalWidth, initalHeight]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);
  return size;
};
