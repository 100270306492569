import styled from 'styled-components';

export const ToolsStyles = styled.div`
  position: absolute;
  left: auto;
  right: 0px;
  top: ${(props) => props.containerHeight}px;
  display: ${(props) => (props.displayTools ? 'block' : 'none')};
  z-index: 1;
  padding-right: 5px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #fff;
  font-size: 15px;
  .Tool-Item {
    width: auto;
    position: relative;
    display: inline-block;
    padding: 10px 5px 10px 15px;
    color: #8497b6;
    text-decoration: none;
    cursor: pointer;
    line-height: 1;
    svg {
      overflow: hidden;
      vertical-align: middle;
    }
  }
`;
