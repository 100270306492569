import React, { useState, useEffect, useRef } from 'react';

import { FaRegTrashAlt, FaEllipsisV } from 'react-icons/fa';

//hooks
import { useWindowResize } from '../../../shared/hooks/useWindowSize';
import { useContainerHeight } from '../../../shared/hooks/useContainerHeight';
import useOnClickOutside from '../../../shared/hooks/useOnClickOutside';

//styles
import { TableRowStyles, TableItemStyles } from '../../../SharedStyles';
import { ToolsStyles } from '../../../OperatorStyles';

const AssignedUsers = (props) => {
  const { driver } = props;
  const CardRef = useRef();
  const OutsideRef = useRef();
  const size = useWindowResize(window.innerWidth, window.innerHeight);
  const { containerHeight, containerRef, setContainerRef } = useContainerHeight(
    30,
    CardRef
  );
  const [openTools, setOpenTools] = useState(false);

  useOnClickOutside(OutsideRef, () => setOpenTools(false));

  //gets card container height for responsive
  useEffect(() => {
    setContainerRef(CardRef);
  }, [CardRef, setContainerRef]);

  const deleteAssignedDriver = async () => {
    props.removeAssignedDriver(driver);
  };

  const toggleToolBar = () => {
    setOpenTools(!openTools);
  };

  return (
    <>
      <TableRowStyles
        borderTop='none'
        borderLeft='none'
        borderRight='none'
        padding='5px 0'
        width={size[0] >= 768 ? '100%' : '100%'}
        align='center'
        ref={containerRef}
        onMouseEnter={() => {
          size[0] >= 768 && setOpenTools(true);
        }}
        onMouseLeave={() => {
          size[0] >= 768 && setOpenTools(false);
        }}>
        <TableItemStyles flex='1'>{driver.firstName}</TableItemStyles>
        <TableItemStyles flex='1'>{driver.lastName}</TableItemStyles>
        <TableItemStyles flex='1'>{driver.email}</TableItemStyles>
        <FaEllipsisV
          className='Card-Tools-Toggle'
          style={size[0] >= 768 ? { display: 'none' } : { display: 'block' }}
          onClick={toggleToolBar}
        />
        <ToolsStyles
          ref={OutsideRef}
          displayTools={openTools}
          containerHeight={(containerHeight - 35) / 2}>
          <div className='Tool-Item' onClick={deleteAssignedDriver}>
            <FaRegTrashAlt title={'Delete'} />
          </div>
        </ToolsStyles>
      </TableRowStyles>
    </>
  );
};

export default AssignedUsers;
