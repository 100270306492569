export const dateProperties = [
  'today',
  'yesterday',
  'thisweek',
  'lastweek',
  'thismonth',
  'lastmonth',
  'last3months',
  'last6months',
  'thisyear',
  'lastyear',
];
export const locationProperties = ['alllocations'];
export const metergroupProperties = ['allmetergroups'];
export const chargerProperties = ['allchargers'];
export const chargerTypeProperties = [
  'allchargertypes',
  'alllevel2',
  'alllevel3',
  'level2-30',
  'level2-40',
  'level2-50',
  'level3-22',
  'level3-50',
  'level3-100',
  'level3-150',
  'level3-200',
];
