import strings from './strings';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import AppApiService from '../../../services/AppApi/AppApiService';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppFindChargerService = {
  strings: localStrings,
  linkDriverCharger: function (info) {
    return AppApiService.post('linkDriverCharger', null, info);
  },
  getChargingStation: function (charger) {
    return AppApiService.post('getChargingStation', null, charger);
  },
  searchChargers: function (address) {
    return AppApiService.post('searchChargers', null, address);
  },
  // {iotHubDeviceId, couponId} = return empty for none, or coupon if valid
  validateCoupon: function (coupon) {
    return AppApiService.post('validateCoupon', null, coupon);
  },
};

export default AppFindChargerService;
