import React, { useState } from 'react';

//layout
import { OuterWrapper, ComponentWrapper } from '../OperatorShared';
import Button from '../../shared/Buttons/Button';

//services
import AppSystemUsersService from './AppSystemUsersService';

//components
import SystemUserList from './SystemUserList/SystemUserList';

//global components
import EnterpriseAdmin from '../EnterpriseAdmin';
import SearchFilterBar from '../OperatorShared/SearchFilterBar';

//styles
import { TextStyles } from '../../SharedStyles';

const SystemUsers = (props) => {
  const { strings } = AppSystemUsersService;
  const [searchedValue, setSearchedValue] = useState('');
  const [filteredValue, setFilteredValue] = useState(null);
  const [submitSearch, setSubmitSearch] = useState(false);

  const handleFilterChange = (filter) => {
    setSubmitSearch(false);
    if (filter !== '') {
      setFilteredValue(filter);
    } else {
      setFilteredValue(null);
      setSearchedValue('');
    }
  };

  const handleSearchChange = (value) => {
    setSubmitSearch(false);
    setSearchedValue(value);
  };

  const handleSearchSubmit = (submit) => {
    setSubmitSearch(submit);
  };

  return (
    <OuterWrapper title={strings.pageTitle} internal path='/'>
      <EnterpriseAdmin>
        <ComponentWrapper title={strings.pageTitle}>
          <SearchFilterBar
            systemUserFilter
            filterBy={strings.filterList}
            handleSearchSubmit={handleSearchSubmit}
            handleSearchChange={handleSearchChange}
            handleFilterChange={handleFilterChange}
          />
          <SystemUserList
            filteredValue={submitSearch ? filteredValue : ''}
            searchedValue={submitSearch ? searchedValue : ''}
          />
        </ComponentWrapper>
        <Button
          cso
          hasMobile
          redirect='/system-users/add'
          buttonText={strings.addTitle}
          roles={[1, 2]}
        />
        <TextStyles align='center'>
          <em>*{strings.notAccepted}</em>
        </TextStyles>
      </EnterpriseAdmin>
    </OuterWrapper>
  );
};

export default SystemUsers;
