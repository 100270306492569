import React, { createContext, useState, useEffect } from 'react';

//services
import AppDriverService from './AppDriverServices';
import { AppGeoLocationService } from '../../../services/AppGeoLocation/AppGeoLocationService';

//global
import LoadingSpinner from '../../shared/Loading/LoadingSpinner';
import NoLocation from '../../shared/NoLocationPermissions/NoLocation';

export const DriverContext = createContext();

const DriverAccount = (props) => {
  const [driver, setDriver] = useState(null);
  const [driverLoading, setdriverLoading] = useState(true);
  const [failedCalled, setFailedCalled] = useState(false);
  const [missingLocationPermission, setMissingLocationPermission] = useState(
    false
  );

  async function fetchDriver() {
    try {
      const location = {
        type: 'Point',
        coordinates: null,
      };
      let userloc = await AppGeoLocationService.getCurrentPosition();
      if (userloc) {
        localStorage.removeItem('geoLocation');
        location.coordinates = [userloc.lat, userloc.lng];
      }
      let driverData = await AppDriverService.getAccount();
      let driverPreferredLanguage = localStorage.getItem('driver-language');

      if (driverData) {
        driverData.driverLocation = location;

        //checking driver language and storage language match if not, updating to the storage and
        //reloading app to get the new language platform strings
        if (
          driverPreferredLanguage !== driverData.language &&
          driverData.language !== null
        ) {
          localStorage.setItem('driver-language', driverData.language);
          window.location.reload();
        }

        //checking driver language and storage language exist if not, updating to english default
        //default platform language is en-US, no reloading needed.
        if (!driverData.language) {
          driverData.language = 'en-US';
        }

        //if driver language and storage language exist if they match,
        //the record will remain as the selected value from db. IE: driver.language

        if (!driverData.notificationEmail) {
          driverData.notificationEmail = driverData.email;
        }

        setDriver(driverData);
      }
      setdriverLoading(false);
    } catch (err) {
      setdriverLoading(false);
      if (err) {
        setdriverLoading(false);
        setFailedCalled(true);
      }
      if (localStorage.getItem('geoLocation')) {
        setMissingLocationPermission(true);
      }
    }
  }

  async function postDriver(prevLanguage) {
    let newDriver = await AppDriverService.updateDriver(driver);
    const location = {
      type: 'Point',
      coordinates: null,
    };
    let userloc = await AppGeoLocationService.getCurrentPosition();
    if (userloc) {
      location.coordinates = [userloc.lat, userloc.lng];
    }
    setDriver((d) => ({ ...d, ...newDriver }));

    if (prevLanguage && prevLanguage !== newDriver.language) {
      window.location.reload();
    }
    return newDriver;
  }

  async function updateDriverState(updatedDriver) {
    let newDriver = await AppDriverService.updateDriver(updatedDriver);
    setDriver((d) => ({ ...d, ...newDriver }));
    return newDriver;
  }

  useEffect(() => {
    fetchDriver();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DriverContext.Provider
      value={{
        driver,
        driverLoading,
        setdriverLoading,
        setDriver,
        fetchDriver,
        postDriver,
        updateDriverState,
      }}>
      {!driverLoading && !missingLocationPermission ? (
        props.children
      ) : missingLocationPermission ? (
        <NoLocation />
      ) : failedCalled ? (
        <NoLocation failedCalled={failedCalled} />
      ) : (
        <LoadingSpinner />
      )}
    </DriverContext.Provider>
  );
};

export default DriverAccount;
