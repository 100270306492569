import React, { useState, useEffect } from 'react';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

//component
import ChargerProgressLogic from './ChargerProgressLogic';

//styles
import { DivStyles } from '../../../../../SharedStyles';

//helpers
import ProgressChildDisplay from './ProgressChildDisplay';

const ChargerProgress = ({
  diagnostics,
  chargingSessionEta,
  isCharging,
  selectedRange,
  handleStartStop,
  strings,
}) => {
  //Displaying % vs kwh vs miles added
  const [isConvert, setIsConvert] = useState({
    count: 0,
    isPercentage: false,
    isKwh: false,
    default: true,
  });
  const [settings, setSettings] = useState({
    maxRange: 500,
    startRange: 0,
    desiredRange: 0,
    batteryCap: 120,
  });

  useEffect(() => {
    if (!isCharging) {
      let maxRange =
        diagnostics && diagnostics.authenticatedUser
          ? diagnostics.authenticatedUser.chargeRangeInfo
            ? diagnostics.authenticatedUser.chargeRangeInfo.maxRangeOfVehicle
            : 500
          : 500;

      let startRange =
        diagnostics && diagnostics.authenticatedUser
          ? diagnostics.authenticatedUser.chargeRangeInfo
            ? diagnostics.authenticatedUser.chargeRangeInfo.startRangeOfVehicle
            : 0
          : 0;

      let desiredRange =
        diagnostics && diagnostics.authenticatedUser
          ? diagnostics.authenticatedUser.chargeRangeInfo
            ? diagnostics.authenticatedUser.chargeRangeInfo.desiredMaxRange
            : selectedRange
          : selectedRange;

      let batteryCap =
        diagnostics && diagnostics.authenticatedUser
          ? diagnostics.authenticatedUser.chargeRangeInfo
            ? diagnostics.authenticatedUser.chargeRangeInfo
                .vehicleBatteryCapacity
            : 120
          : 120;
      setSettings({ maxRange, startRange, desiredRange, batteryCap });
    }
  }, [diagnostics, isCharging, selectedRange]);

  //===================will change based on metervalues ================
  useEffect(() => {
    let meterMeasurements = localStorage.getItem('meterMeasurement');
    if (meterMeasurements) {
      setIsConvert(JSON.parse(meterMeasurements));
    }
  }, []);

  //function for %, kwH and miles added
  const handleConverison = () => {
    //currently only Percentage
    if (isConvert.count === 0) {
      setIsConvert({
        isKwh: true,
        default: false,
        count: 1,
        isPercentage: false,
      });
      localStorage.setItem(
        'meterMeasurement',
        JSON.stringify({
          isKwh: true,
          default: false,
          count: 1,
          isPercentage: false,
        })
      );
    }
    if (isConvert.count === 1) {
      setIsConvert({
        isKwh: false,
        default: false,
        count: 2,
        isPercentage: true,
      });
      localStorage.setItem(
        'meterMeasurement',
        JSON.stringify({
          isKwh: false,
          default: false,
          count: 2,
          isPercentage: true,
        })
      );
    }
    if (isConvert.count === 2) {
      setIsConvert({
        count: 0,
        isPercentage: false,
        isKwh: false,
        default: true,
      });
      localStorage.setItem(
        'meterMeasurement',
        JSON.stringify({
          count: 0,
          isPercentage: false,
          isKwh: false,
          default: true,
        })
      );
    }
  };

  return (
    <ChargerProgressLogic
      value={settings.startRange}
      addedRange={
        Object.keys(chargingSessionEta).length > 0
          ? chargingSessionEta.addedRange
          : 0
      }
      isCharging={isCharging}
      batteryCapacity={settings.maxRange}
      desiredRange={settings.desiredRange}
      handleStartStop={handleStartStop}>
      {(value) => (
        <DivStyles
          display='flex'
          alignItems='center'
          justifyContent='center'
          align='center'
          onClick={handleConverison}>
          <CircularProgressbarWithChildren
            maxValue={settings.maxRange}
            value={value.stopValue}
            circleRatio={0.75}
            background
            strokeWidth={9}
            backgroundPadding={2}
            styles={buildStyles({
              backgroundColor: '#2c456c',
              pathColor: 'grey',
              trailColor: '#eee',
              textColor: '#fff',
              fontSize: '16px',
              rotation: 1 / 2 + 1 / 8,
            })}>
            <CircularProgressbarWithChildren
              maxValue={settings.maxRange}
              value={value.valuesIndex}
              circleRatio={0.75}
              background
              backgroundPadding={2.5}
              styles={buildStyles({
                trailColor: 'transparent',
                backgroundColor: 'transparent',
                pathColor: 'lime',
                textColor: '#fff',
                fontSize: '16px',
                rotation: 1 / 2 + 1 / 8,
              })}>
              <DivStyles>
                <ProgressChildDisplay
                  maxRange={parseInt(settings.maxRange)}
                  startRange={parseInt(settings.startRange)}
                  desiredRange={parseInt(settings.desiredRange)}
                  batteryCapacity={parseInt(settings.batteryCap)}
                  diagnostics={diagnostics}
                  chargingSessionEta={chargingSessionEta}
                  strings={strings}
                  isConvert={isConvert}
                />
              </DivStyles>
            </CircularProgressbarWithChildren>
          </CircularProgressbarWithChildren>
        </DivStyles>
      )}
    </ChargerProgressLogic>
  );
};

export default ChargerProgress;
