import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import AppApiService from '../../../services/AppApi/AppApiService';
import AppImagingService from '../../../services/AppImaging/AppImagingService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppMembershipsService = {
  insertMembership: function (membership) {
    return AppApiService.post('insertMembership', null, membership);
  },
  getMembershipsByCSO: function (csoId) {
    return AppApiService.post('getMembershipsByCSO', null, csoId);
  },
  getAllMemberships: function () {
    return AppApiService.get('getMemberships');
  },
  getMembershipById: function (id) {
    return AppApiService.post('getMembershipById', null, id);
  },
  getMembershipBySearch: function (code) {
    return AppApiService.post('getMembershipBySearch', null, code);
  },
  updateMembership: function (membershipId) {
    return AppApiService.post('updateMembership', null, membershipId);
  },
  deleteMembership: function (membershipId) {
    return AppApiService.post('deleteMembership', null, membershipId);
  },
  prepareImage: function (image) {
    return AppImagingService.getBlobForDataUrl(image);
  },
  uploadImage: function (imageBlob) {
    return AppApiService.post('uploadImage', null, imageBlob);
  },
  getMembersInMembership: function (membershipId) {
    return AppApiService.post('getMembersInMembership', null, membershipId);
  },
  createMembershipToken: function (membershipId) {
    return AppApiService.post('createMembershipToken', null, membershipId);
  },
  getMembershipTokenById: function (membershipId) {
    return AppApiService.post('getMembershipToken', null, membershipId);
  },
  approveMembership: function (member) {
    return AppApiService.post('approveMembership', null, member);
  },
  pauseMembership: function (member) {
    return AppApiService.post('pauseMembership', null, member);
  },
  removeDriverMembership: function (membershipData) {
    return AppApiService.post('removeDriverMembership', null, membershipData);
  },
  strings: localStrings,
};

export default AppMembershipsService;
