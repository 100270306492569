import { useState } from 'react';

function usePagination(data, itemsPerPage) {
  const [currentPage, setCurrentPage] = useState(1);

  const maxPage = Math.ceil(data.length / itemsPerPage);
  const totalData = data.length;
  const pageNumbers = [];

  for (let i = 1; i <= maxPage; i++) {
    pageNumbers.push(i);
  }

  // calculate start and end item indexes
  let startIndex = (currentPage - 1) * itemsPerPage;
  let currentCount = Math.min(startIndex + itemsPerPage - 1, totalData - 1);

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
  }

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;

    return data.slice(begin, end);
  }

  // Returns an array of maxLength (or less) page numbers
  // where a 0 in the returned array denotes a gap in the series.
  // Parameters:
  //   totalPages:     total number of pages
  //   page:           current page
  //   maxLength:      maximum size of returned array
  // function getPageList(totalPages, page, maxLength) {
  //   if (maxLength < 5) throw new error('maxLength must be at least 5');

  //   function range(start, end) {
  //     return Array.from(Array(end - start + 1), (_, i) => i + start);
  //   }

  //   var sideWidth = maxLength < 9 ? 1 : 2;
  //   var leftWidth = (maxLength - sideWidth * 2 - 3) >> 1;
  //   var rightWidth = (maxLength - sideWidth * 2 - 2) >> 1;
  //   if (totalPages <= maxLength) {
  //     // no breaks in list
  //     return range(1, totalPages);
  //   }
  //   if (page <= maxLength - sideWidth - 1 - rightWidth) {
  //     // no break on left of page
  //     return range(1, maxLength - sideWidth - 1).concat(
  //       0,
  //       range(totalPages - sideWidth + 1, totalPages)
  //     );
  //   }
  //   if (page >= totalPages - sideWidth - 1 - rightWidth) {
  //     // no break on right of page
  //     return range(1, sideWidth).concat(
  //       0,
  //       range(totalPages - sideWidth - 1 - rightWidth - leftWidth, totalPages)
  //     );
  //   }
  //   // Breaks on both sides
  //   return range(1, sideWidth).concat(
  //     0,
  //     range(page - leftWidth, page + rightWidth),
  //     0,
  //     range(totalPages - sideWidth + 1, totalPages)
  //   );
  // }

  // useEffect(() => {
  //   let info = getPageList(maxPage, currentPage, itemsPerPage);
  //   console.log('DATA', info);
  // }, [data, itemsPerPage]);

  return {
    next,
    prev,
    jump,
    currentData,
    currentPage,
    maxPage,
    totalData,
    currentCount,
    pageNumbers,
  };
}

export { usePagination };
