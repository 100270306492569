import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';

//styles
import { MobileNavStyles } from '../../../DriverStyles';

const MobileMenu = React.forwardRef((props, ref) => {
  const { strings } = props;
  return (
    <MobileNavStyles ref={ref}>
      <div className='Mobile-Nav-Container'>
        <div className='Arrow'>
          <span onClick={props.handleRedirection}>
            <FaArrowLeft />
          </span>
        </div>
        <div className='Title'>{props.title}</div>
      </div>
      {props.join && (
        <div className='Save' onClick={props.join}>
          {strings.join}
        </div>
      )}
      {props.myForm && (
        <button className='Save' form={props.myForm}>
          {props.actionText ? props.actionText : strings.save}
        </button>
      )}
      {props.onSaveClick && (
        <div className='Save' onClick={props.onSaveClick}>
          {props.actionText ? props.actionText : strings.save}
        </div>
      )}
    </MobileNavStyles>
  );
});

export default MobileMenu;
