import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { FaTrashAlt, FaAddressCard, FaMobileAlt } from 'react-icons/fa';

//modals
import DeactivateModal from '../Modals/DeactivateModal';

//styles
import { DivStyles, TextStyles } from '../../../../SharedStyles';
import AccessCardsService from '../../AccessCardsService';

const CardItem = ({ card, handleRemoveAccessCard, borderBottom }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleDisableCard = async () => {
    let res = await AccessCardsService.updateAccessCard({
      ...card,
      activated: false,
    });
    console.log(res);
    handleRemoveAccessCard(res);
  };

  return (
    <DivStyles
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      borderBottom={borderBottom}
      linkWidth='100%'>
      {showModal && (
        <DeactivateModal
          toggleModal={toggleModal}
          handleDisableCard={handleDisableCard}
        />
      )}
      <Link to={`/access-cards/activate/${card.id}`}>
        <DivStyles
          padding='10px'
          display='flex'
          justifyContent='space-between'
          alignItems='center'>
          <DivStyles
            flex='1'
            svgColor='#000 !important'
            svgSize='45px'
            margin='0 auto'
            align='center'
            maxWidth='115px'>
            {card.serialId === '' && card.activated ? (
              <FaMobileAlt />
            ) : (
              <FaAddressCard />
            )}
          </DivStyles>
          <DivStyles flex='3' size='14px'>
            {card.activated ? (
              <>
                {card.serialId !== '' ? (
                  <>
                    <TextStyles margin='0 0 5px'>
                      <strong>Card Name:</strong> {card.nickName}
                    </TextStyles>
                    <TextStyles margin='0'>
                      <strong>Serial No:</strong> {card.serialId}
                    </TextStyles>
                  </>
                ) : (
                  <>
                    <TextStyles margin='0 0 5px'>
                      <strong>Device Name:</strong> {card.nickName}
                    </TextStyles>
                    <TextStyles margin='0'>Mobile Device</TextStyles>
                  </>
                )}
              </>
            ) : (
              <>
                <TextStyles
                  margin='0 0 2px'
                  color={!card.nickName ? 'red' : null}>
                  {card.nickName && (
                    <>
                      <strong>Card Name:</strong> {card.nickName}
                    </>
                  )}
                  {!card.nickName && <strong>Processing</strong>}
                </TextStyles>
                <TextStyles margin='0' size='12px'>
                  Your card is currently in for processing
                </TextStyles>
              </>
            )}
          </DivStyles>
        </DivStyles>
      </Link>
      {card.activated && (
        <DivStyles
          padding='10px 0'
          align='right'
          margin='0 15px 0 0'
          alignSelf='stretch'>
          <FaTrashAlt onClick={toggleModal} />
        </DivStyles>
      )}
    </DivStyles>
  );
};

export default CardItem;
