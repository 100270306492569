import React, { useState, useContext } from 'react';

//layout
import {
  OuterWrapper,
  ComponentWrapper,
  SubscriptionAccess,
} from '../OperatorShared';
import Button from '../../shared/Buttons/Button';

//services
import AppMembershipsService from './AppMembershipsService';

//components
import MembershipList from './MembershipsList/MembershipList';

//global components
import SearchFilterBar from '../OperatorShared/SearchFilterBar';

//modals
import UpgradeModal from './MembershipsModals/UpgradeModal';

//context
import { CsoAccountContext } from '../../context/operator/getCsoAccount';
import { CsoMembershipContext } from '../../context/operator/getCsoMembership';

//styles
import './Memberships.scss';

const Memberships = (props) => {
  const { strings } = AppMembershipsService;
  const { account } = useContext(CsoAccountContext);
  const { allMemberships } = useContext(CsoMembershipContext);
  const [searchedValue, setSearchedValue] = useState('');
  const [filteredValue, setFilteredValue] = useState(null);
  const [submitSearch, setSubmitSearch] = useState(false);
  const [limitReached, setLimitReached] = useState(false);

  const closeReachModal = () => {
    setLimitReached(!limitReached);
  };

  const checkingLimit = () => {
    let limit = -1;
    if (account.subscriptionLevel === 1) {
      limit = 1;
    }
    if (account.subscriptionLevel === 2) {
      limit = 2;
    }
    if (limit === -1 || allMemberships.length < limit) {
      props.history.push('/subscription/memberships/add');
    } else {
      setLimitReached(true);
    }
  };

  const handleFilterChange = (filter) => {
    setSubmitSearch(false);
    setFilteredValue(filter);
  };

  const handleSearchChange = (value) => {
    setSubmitSearch(false);
    setSearchedValue(value);
  };

  const handleSearchSubmit = (submit) => {
    setSubmitSearch(submit);
  };

  return (
    <OuterWrapper title={strings.pageTitle} internal path='/'>
      {limitReached && <UpgradeModal handleCloseModal={closeReachModal} subscriptionLevel={account.subscriptionLevel} />}
      <SubscriptionAccess levels={[1, 2, 3]}>
        <ComponentWrapper title={strings.pageTitle}>
          <SubscriptionAccess levels={[3]}>
            <SearchFilterBar
              membershipFilter
              filterBy={strings.filterList}
              handleSearchSubmit={handleSearchSubmit}
              handleSearchChange={handleSearchChange}
              handleFilterChange={handleFilterChange}
            />
          </SubscriptionAccess>
          {/* list of searched and filtered results */}
          <MembershipList
            filteredValue={submitSearch ? filteredValue : ''}
            searchedValue={submitSearch ? searchedValue : ''}
          />
        </ComponentWrapper>
        <Button
          cso
          hasMobile
          buttonText={strings.add}
          onclick={checkingLimit}
          roles={[1, 2, 4]}
        />
      </SubscriptionAccess>
    </OuterWrapper>
  );
};

export default Memberships;
