import strings from './strings';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import AppApiService from '../../../services/AppApi/AppApiService';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppFindChargerService = {
  strings: localStrings,
  waitQueueAdd: function (queInfo) {
    return AppApiService.post('waitQueueAdd', null, queInfo);
  },
  waitQueueGet: function (queInfo) {
    return AppApiService.post('waitQueueGet', null, queInfo);
  },
  waitQueueReady: function (queInfo) {
    return AppApiService.post('waitQueueReady', null, queInfo);
  },
  // {iotHubDeviceId, couponId} = return empty for none, or coupon if valid
  waitQueueRemove: function (queInfo) {
    return AppApiService.post('waitQueueRemove', null, queInfo);
  },
  reservationAdd: function (resInfo) {
    return AppApiService.post('reservationAdd', null, resInfo);
  },
  reservationRemove: function (resInfo) {
    return AppApiService.post('reservationRemove', null, resInfo);
  },
  reservationGet: function (resInfo) {
    return AppApiService.post('reservationGet', null, resInfo);
  },
};

export default AppFindChargerService;
