import React from 'react';

//helpers
import {
  connectorStatuses,
  connectorConstants,
} from '../../../../driver/helpers';

//styles
import { DivStyles, TextStyles } from '../../../../SharedStyles';

const ChargingStatus = (props) => {
  const { selectedPort, connectors } = props;

  const availableStatus = 'charging-port ';

  //checking charging station status
  const checkingChargingStationStatus = (connectors) => {
    let offline = connectors.every((c) => c.status === 6);

    let status = !offline
      ? connectorStatuses[connectorConstants.Available]
      : connectorStatuses[connectorConstants.Unavailable];

    if (!selectedPort) {
      return status;
    }

    connectors.forEach((c) => {
      if (c.connectorId === parseInt(selectedPort)) {
        status = connectorStatuses[c.status];
      }
    });

    return status;
  };

  const checkChargerStatus = (port) => {
    if (
      port.membershipOnlyBlocked ||
      port.reservationBlocked ||
      port.waitQueueBlocked
    ) {
      return connectorStatuses[connectorConstants.Unavailable].toLowerCase();
    } else {
      return connectorStatuses[port.status].toLowerCase();
    }
  };

  return (
    <DivStyles
      margin='0 75px'
      display='flex'
      alignItems='center'
      justifyContent='center'>
      {connectors.length > 1 && (
        <DivStyles flex='1' margin='10px 0 0' align='center' size='13px'>
          <TextStyles>port 1</TextStyles>
          <DivStyles
            size='13px'
            color='#fff'
            className={
              checkChargerStatus(connectors[0]) === 'available'
                ? availableStatus
                : availableStatus + 'port-status-unavailable'
            }>
            {checkChargerStatus(connectors[0])}
          </DivStyles>
        </DivStyles>
      )}
      <DivStyles
        display='flex'
        alignItems='center'
        justifyContent='center'
        margin='5% 0 1%'>
        <TextStyles size='14px' fontStyle='italic'>
          Status: {checkingChargingStationStatus(connectors)}
        </TextStyles>
      </DivStyles>
      {connectors.length > 1 && (
        <DivStyles flex='1' margin='10px 0 0' align='center' size='13px'>
          <TextStyles>port 2</TextStyles>
          <DivStyles
            size='13px'
            color='#fff'
            className={
              checkChargerStatus(connectors[1]) === 'available'
                ? availableStatus
                : availableStatus + 'port-status-unavailable'
            }>
            {checkChargerStatus(connectors[1])}
          </DivStyles>
        </DivStyles>
      )}
    </DivStyles>
  );
};

export default ChargingStatus;
