import React from 'react';

//hooks
import { useRouter } from '../../../../shared/hooks/useRouter';

//styles
import {
  DivStyles,
  ImageThumbnailStyles,
  TitleStyles,
  TextStyles,
} from '../../../../SharedStyles';

const ReportsCard = (props) => {
  const router = useRouter();

  const handleRedirect = () => {
    router.history.push(props.route);
  };

  return (
    <DivStyles
      display='flex'
      alignItems='flex-start'
      padding='10px'
      color='#303030'
      cursor='pointer'
      onClick={handleRedirect}>
      <ImageThumbnailStyles
        maxWidth='75px'
        margin='0 10px 0 0'
        border='1px dotted #c2c2c2'
        src={props.imageUrl}
        alt={props.title}
      />
      <DivStyles>
        <TitleStyles size='15px' color='#000' weight='700' margin='0 0 10px'>
          {props.title} <span className='Subtitle'>{props.subtitle}</span>
        </TitleStyles>
        <TextStyles size='12px'>
          {props.contentOne} <em>{props.emContentOne}</em> {props.contentTwo}{' '}
          <em>{props.emContentTwo}</em> {props.contentThree}{' '}
        </TextStyles>
      </DivStyles>
    </DivStyles>
  );
};

export default ReportsCard;
