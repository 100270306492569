import AppPlatformService from '../../../../../../services/AppPlatform/AppPlatformService';
import AppApiService from '../../../../../../services/AppApi/AppApiService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const CustomIncentiveService = {
  strings: localStrings,
  getNYSERDAReport: function (chargers) {
    return AppApiService.post('NYSERDAReport', null, chargers);
  },  
  getNYSERDAChargerStatusReport: function (chargers) {
    return AppApiService.post('NYSERDAChargerStatusReport', null, chargers);
  },
};

export default CustomIncentiveService;
