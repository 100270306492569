import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  ButtonStyles,
  DivStyles,
} from '../../SharedStyles';

const Modal = (props) => {
  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{props.title}</h2>
          <FaRegWindowClose className='Close' onClick={props.onCancel} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles>{props.message}</ModalItemStyles>
          {/* Section */}
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <DivStyles margin='10px 0 0 '>
          {props.cancelButton && (
            <ButtonStyles inverse onClick={props.onCancel}>
              {props.cancelButton}
            </ButtonStyles>
          )}
          {props.confirmButton && (
            <ButtonStyles onClick={props.onConfirm}>
              {props.confirmButton}
            </ButtonStyles>
          )}
        </DivStyles>
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default Modal;
