import styled from 'styled-components';

export const ListPlaceholderStyles = styled.div`
  padding: ${({ padding }) => (padding ? padding : '50px')};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? borderBottom : '1px solid #e7e7e7'};
  text-align: center;

  img,
  svg {
    max-width: ${({ imgMaxWidth }) => (imgMaxWidth ? imgMaxWidth : '250px')};
    width: 100%;
    margin: ${({ imgMargins }) => (imgMargins ? imgMargins : '20px auto')};
    display: block;
  }
  p {
    padding: ${({ textPadding }) => (textPadding ? textPadding : '0 0 5%')};
    text-align: center;
    color: ${({ pColor }) => pColor};
    font-size: ${({ pSize }) => pSize};
  }
  .Placeholder-Content {
    margin: ${({ contentMargin }) =>
      contentMargin ? contentMargin : '0 0 20px'};
    svg.Placeholder-Svg {
      font-size: ${({ contentFontSize }) =>
        contentFontSize ? contentFontSize : '70px'};
      color: ${({ contentColor }) => (contentColor ? contentColor : '#8999ae')};
    }
  }
  a {
    margin: ${({ linkMargin }) => (linkMargin ? linkMargin : '0 10px 10px')};
  }
`;
