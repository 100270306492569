import React, { useState, useEffect, useContext } from 'react';

//layout
import { InteriorWrapper, ComponentWrapper } from '../../shared';
import Button from '../../../shared/Buttons/Button';

//services
import AppMembershipsService from '../AppMembershipsService';

//components
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';
import MembershipContent from './MembershipContainers/MembershipContent';
import MembershipHeader from './MembershipContainers/MembershipHeader';
import JoinModal from '../Modals/JoinModal';

//context
import { DriverContext } from '../../../context/driver/getDriverContext';

const MembershipInfo = (props) => {
  const { driver } = useContext(DriverContext);
  const [loading, setLoading] = useState(true);
  const [membership, setMembership] = useState({});
  const [currentMember, setCurrentMember] = useState(false);
  const [modalDisplay, setModalDisplay] = useState(false);
  const { strings } = AppMembershipsService;

  const fetchMemberships = async () => {
    let currentMembership = await AppMembershipsService.getMembershipById(
      props.match.params.id
    );
    if (currentMembership.memberIds.includes(driver.id)) {
      setCurrentMember(true);
    }
    setMembership(currentMembership);
    setLoading(false);
  };

  const toggleModal = () => {
    setModalDisplay(!modalDisplay);
  };

  useEffect(() => {
    if (Object.keys(driver).length > 0) {
      fetchMemberships();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driver]);

  return (
    <InteriorWrapper
      title={strings.membershipInfoTitle}
      isInternal
      join={!loading ? !currentMember && toggleModal : null}
      path='/memberships'>
      {loading && <LoadingSpinner />}
      <ComponentWrapper title={strings.membershipInfoTitle}>
        {modalDisplay && (
          <JoinModal closeModal={toggleModal} membership={membership} />
        )}
        <MembershipHeader membership={membership} />
        {Object.keys(membership).length > 0 && (
          <MembershipContent membership={membership} strings={strings} />
        )}
      </ComponentWrapper>
      {!loading && (
        <Button
          driver
          noMobile
          onclick={!currentMember && toggleModal}
          redirect={currentMember ? '/memberships/' : false}
          buttonText={!currentMember ? strings.join : strings.back}
        />
      )}
    </InteriorWrapper>
  );
};

export default MembershipInfo;
