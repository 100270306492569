import React, { useContext } from 'react';

//services
import AppSharedService from './AppSharedService';

//context
import { CsoAccountContext } from '../../context/operator/getCsoAccount';
import {
  ComponentInternalStyles,
  ComponentExternalStyles,
  ComponentHeaderStyles,
  ComponentTitleStyles,
  TextStyles,
} from '../../SharedStyles';

const ComponentWrapper = (props) => {
  const { strings } = AppSharedService;
  const { align, mobileMedia } = props;
  const { currentUser } = useContext(CsoAccountContext);

  if (props.external) {
    return (
      <ComponentExternalStyles border>{props.children}</ComponentExternalStyles>
    );
  }

  return (
    <ComponentInternalStyles
      align={align}
      mobileMedia={mobileMedia}
      minHeight='500px'>
      {props.title && (
        <ComponentHeaderStyles
          className={props.mobile ? 'Header-Mobile' : 'Header'}>
          <ComponentTitleStyles>
            {props.dashboardTitle} {props.dashboardSubTitle}
            {props.title}{' '}
            {props.roles &&
              !props.roles.includes(currentUser.role) &&
              `- ${strings.readOnly}`}
          </ComponentTitleStyles>
        </ComponentHeaderStyles>
      )}
      {props.dashboardTitle && (
        <ComponentHeaderStyles
          className={props.mobile ? 'Header-Mobile' : 'Header'}>
          <ComponentTitleStyles display='flex' alignItems='center'>
            <TextStyles size='16px' margin='0 5px 0 0' weight='700'>
              {props.normalCase
                ? props.dashboardTitle
                : props.dashboardTitle.toUpperCase()}
            </TextStyles>{' '}
            <TextStyles size='12px' weight='400' margin='0'>
              {props.dashboardSubTitle}
            </TextStyles>
          </ComponentTitleStyles>
        </ComponentHeaderStyles>
      )}
      {props.children}
    </ComponentInternalStyles>
  );
};

export default ComponentWrapper;
