import React, { useState } from 'react';

//services
import AppAccountProfileService from '../AccountProfile/AppAccountProfileService';

//styles
import {
  InputStyles,
  FormContainerStyles,
  FormRowStyles,
  SelectStyles,
  ButtonContainerStyles,
  ButtonStyles,
} from '../../SharedStyles';

//helpers
import states from '../../shared/Address/states';

const AccountForm = (props) => {
  const { strings } = AppAccountProfileService;
  const [formData, setFormData] = useState({
    csoAccountName: null,
    headquartersAddress1: null,
    headquartersAddress2: '',
    headquartersCity: null,
    headquartersState: null,
    headquartersZip: null,
    headquartersCountry: null,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.createNewCSO(formData);
  };

  return (
    <>
      <form onSubmit={handleSubmit} id='Account-Creation'>
        <FormContainerStyles
          padding='10px 0 15px'
          headersPadding='0'
          borderBottom
          width='70%'
          margin='0 auto'>
          <h3 className='Form-Title'>{strings.companyInfo}</h3>
          <FormRowStyles margin='0'>
            <InputStyles
              type='text'
              maxLength='256'
              name='csoAccountName'
              placeholder={strings.accountName}
              onChange={handleChange}
              required
            />
          </FormRowStyles>
        </FormContainerStyles>
        <FormContainerStyles
          padding='10px 0 15px'
          headersPadding='0'
          borderBottom
          width='70%'
          margin='0 auto'>
          <h3 className='Form-Title'>{strings.headquartersTitle}</h3>
          <FormRowStyles margin='0'>
            <InputStyles
              type='text'
              maxLength='256'
              name='headquartersAddress1'
              placeholder={strings.placeholderAddress1}
              onChange={handleChange}
              required
            />
          </FormRowStyles>
          <FormRowStyles margin='0'>
            <InputStyles
              type='text'
              maxLength='256'
              name='headquartersAddress2'
              placeholder={strings.placeholderAddres2}
              onChange={handleChange}
            />
          </FormRowStyles>
          <FormRowStyles margin='0'>
            <InputStyles
              margin='0 5px 0 0'
              type='text'
              maxLength='256'
              name='headquartersCity'
              placeholder={strings.placeholderCity}
              onChange={handleChange}
              required
            />
            <SelectStyles
              margin='0 0 0 5px'
              name='headquartersState'
              required
              onChange={handleChange}
              defaultValue=''>
              <option disabled value=''>
                {strings.placeholderState}
              </option>
              {states &&
                states.filter(x=>x.country===formData.headquartersCountry).map((h, i) => (
                  <option key={i} value={h.abbreviation}>
                    {h.name}
                  </option>
                ))}
            </SelectStyles>
          </FormRowStyles>
          <FormRowStyles margin='0'>
            <InputStyles
              margin='0 5px 0 0'
              type='text'
              maxLength='256'
              name='headquartersZip'
              onChange={handleChange}
              placeholder={strings.placeholderZIP}
              required
            />
            <SelectStyles
              margin='0 0 0 5px'
              name='headquartersCountry'
              required
              onChange={handleChange}
              defaultValue=''>
              <option disabled value=''>
                {strings.placeholderCountry}
              </option>
              {strings.countries.map((c) => (
                <option key={c}>{c}</option>
              ))}
            </SelectStyles>
          </FormRowStyles>
          <ButtonContainerStyles>
            <ButtonStyles type='submit' form='Account-Creation'>
              {strings.save}
            </ButtonStyles>
            <ButtonStyles inverse onClick={() => props.showCsoAccountForm()}>
              {strings.cancel}
            </ButtonStyles>
          </ButtonContainerStyles>
        </FormContainerStyles>
      </form>
    </>
  );
};

export default AccountForm;
