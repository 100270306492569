import React, { useContext } from 'react';
import { CsoAccountContext } from '../../context/operator/getCsoAccount';

const RoleCanEdit = (props) => {
  const { currentUser } = useContext(CsoAccountContext);
  const { roles } = props;
  return (
    <>
      {currentUser && roles && roles.includes(currentUser.role)
        ? props.children
        : null}
    </>
  );
};

export default RoleCanEdit;
