import React, { useState, useEffect, Fragment, useContext } from 'react';

//layout
import { OuterWrapper, ComponentWrapper } from '../OperatorShared';
import Button from '../../shared/Buttons/Button';

//services
import AppSubscriptionService from './AppSubscriptionService';
import AppBankService from '../Bank/AppBankService';

//components
import CsoToggleSwitch from '../OperatorShared/ToggleSwitch/CsoToggleSwitch';
import LoadingSpinner from '../../shared/Loading/LoadingSpinner';

//hooks
import { useRouter } from '../../shared/hooks/useRouter';

//context
import { CsoAccountContext } from '../../context/operator/getCsoAccount';

//styles
import {
  RowStyles,
  FormContainerStyles,
  ImageThumbnailStyles,
  TitleStyles,
  DivStyles,
} from '../../SharedStyles';
import { EnableContainerStyles } from '../../OperatorStyles';

//helpers
import { plans } from './subscriptionPlans';

const SubscriptionSettings = (props) => {
  const { strings } = AppSubscriptionService;
  const { account, updatingAccount } = useContext(CsoAccountContext);
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentSub, setCurrentSub] = useState({});
  const [unsub, setUnsub] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [stripeAccount, setStripeAccount] = useState(null);

  useEffect(() => {
    const fetchStripe = async () => {
      let res = await AppBankService.getCSOStripeAccounts();
      setStripeAccount(res);
    };
    fetchStripe();
    let settingPlans = plans.map((plan) => {
      plan.enabled = false;
      return plan;
    });
    setData(settingPlans);
    let curr = plans.filter((plan) => {
      return plan.name === account.subscriptionLevel;
    });
    setCurrentSub(curr[0]);
    setLoading(false);
  }, [account]);

  useEffect(() => {
    let updatedValues = data.every((plan) => {
      return !plan.enabled;
    });
    if (!unsub && updatedValues) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [data, unsub]);

  const handleToggles = (e, name) => {
    let updatedValues = data.map((plan) => {
      if (plan.name === name) {
        plan.enabled = e.target.checked;
        setUnsub(false);
      } else {
        plan.enabled = false;
      }
      return plan;
    });
    setData(updatedValues);
  };

  const handleUnSubToggle = (e) => {
    if (e.target.checked) {
      let updatedValues = data.map((plan) => {
        plan.enabled = false;

        return plan;
      });
      setData(updatedValues);
    }
    setUnsub(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let updatedAccount = account;

    let subPlan = data.filter((p) => {
      return p.enabled;
    });
    if (unsub) {
      setCurrentSub(null);
      updatedAccount.subscriptionLevel = 0;
    } else {
      setCurrentSub(subPlan[0]);
      updatedAccount.subscriptionLevel = subPlan[0].name;
      let updatedStripeAccount = stripeAccount;
      updatedStripeAccount.subscriptionPlan = subPlan[0].name;
      await AppSubscriptionService.updateCSOStripeAccount(updatedStripeAccount);
    }
    let response = await updatingAccount(updatedAccount);
    if (response) {
      router.history.push('/');
    }
  };

  return (
    <OuterWrapper
      title={strings.subscriptionTitle}
      internal
      subscribe={unsub ? strings.unButtonText : strings.buttonText}
      myForm
      path='/'
      roles={[1]}>
      {loading && <LoadingSpinner />}
      <ComponentWrapper title={strings.subscriptionTitle}>
        <FormContainerStyles padding='0'>
          <form style={{ width: '100%' }} id='myForm' onSubmit={handleSubmit}>
            {props.account.subscriptionLevel !== 3 && (
              <RowStyles
                borderBottom='none'
                display='block'
                padding='10px'
                headerSize='14px'
                headerWeight='600'
                titleAlign='left'>
                <h2 className='Title'>{strings.upgrade}</h2>
              </RowStyles>
            )}
            {data.map((plan, index) => {
              if (plan.name > props.account.subscriptionLevel) {
                return (
                  <Fragment key={plan.title}>
                    <EnableContainerStyles headerPadding='0'>
                      <DivStyles display='flex' alignItems='center'>
                        <ImageThumbnailStyles
                          maxWidth='90px'
                          margin='0 10px 0 0'
                          src={plan.image}
                          alt={plan.title}
                        />
                        <TitleStyles size='18px' margin='0' weight='500'>
                          {plan.title}
                        </TitleStyles>
                      </DivStyles>
                      <CsoToggleSwitch
                        name={plan.name}
                        checked={!!plan.enabled}
                        handleChange={handleToggles}
                      />
                    </EnableContainerStyles>
                    <RowStyles display='block' align='center'>
                      <p>{plan.fee}</p>
                      <p>{plan.content}</p>
                    </RowStyles>
                  </Fragment>
                );
              }
              return null;
            })}
            {currentSub && (
              <>
                <RowStyles
                  borderBottom='none'
                  display='block'
                  padding='10px'
                  headerSize='14px'
                  headerWeight='600'
                  titleAlign='left'>
                  <h2 className='Title'>{strings.options}</h2>
                </RowStyles>
                <EnableContainerStyles headerPadding='0'>
                  <DivStyles display='flex' alignItems='center'>
                    <ImageThumbnailStyles
                      maxWidth='90px'
                      margin='0 10px 0 0'
                      src={currentSub.image}
                      alt={currentSub.title}
                    />
                    <TitleStyles size='18px' margin='0' weight='500'>
                      {strings.unsubscribe} {currentSub.title}
                    </TitleStyles>
                  </DivStyles>
                  <CsoToggleSwitch
                    name='unsub'
                    checked={!!unsub}
                    handleChange={handleUnSubToggle}
                  />
                </EnableContainerStyles>
                <RowStyles borderBottom='none' display='block' align='center'>
                  <p>{currentSub.fee}</p>
                </RowStyles>
              </>
            )}
          </form>
        </FormContainerStyles>
      </ComponentWrapper>
      <Button
        cso
        hasMobile
        formSubmit
        disabled={disabled}
        buttonText={unsub ? strings.unButtonText : strings.buttonText}
        roles={[1]}
      />
    </OuterWrapper>
  );
};

export default SubscriptionSettings;
