import React, {useState, useContext} from 'react';

//service
import AppAccountProfileService from '../AppAccountProfileService';
import AppNavSectionService from '../../NavSection/AppNavSectionService';

//components
import Modal from '../../../shared/Modals/Modal';

//styles
import {
  ButtonContainerStyles,
  ButtonStyles
} from '../../../SharedStyles';

//hooks
import { LoadingSpinner } from '../../../shared';

//context
import { ErrorContext } from '../../../context/shared/ErrorContext';

const CloseAccount = props => {
  const { strings, account } = props;
  const [closeAccountModal, setCloseAccountModal] = useState(false);
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(false);
  
  const toggleDelete = () => {
    setCloseAccountModal(!closeAccountModal);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      await AppAccountProfileService.deleteCsoAccount(account.csoAccountId);

      toggleDelete();

      setLoading(false);

      AppNavSectionService.logout();

    } catch (err) {
      setLoading(false);
      
      toggleDelete();
      
      setError({
        display: true,
        title: 'Something went wrong',
        message: err.response ? 
          err.response.data === '' ? err.message : err.response.data
          : err.message,
        styles: 'cso',
      });
    }
  };

  return (
    <div>
    {loading && <LoadingSpinner />}
    {closeAccountModal && (
      <Modal
          title={strings.closeAccountTitle}
          message={strings.deleteContent}
          cancelButton='No'
          confirmButton='Yes'
          onCancel={() => toggleDelete()}
          onConfirm={handleDelete}
        />
    )}
    <ButtonContainerStyles
      borderBottom={!props.cso}
      align={props.cso && 'left'}
      margin={props.cso ? '5px 0' : '0'}
      padding={props.cso ? '0' : '20px 0'}>

        <ButtonStyles
          margin={props.cso && '0'}
          inverse
          title={strings.closeAccountModal}
          onClick={toggleDelete}>
          {strings.closeAccountModal}
        </ButtonStyles>

    </ButtonContainerStyles>
    </div>
  );
};

export default CloseAccount;
