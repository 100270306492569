import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { FaRegTrashAlt, FaEllipsisV } from 'react-icons/fa';

//services
import AppDiscountSchedulesService from '../../AppDiscountSchedulesService';

//hooks
import { useWindowResize } from '../../../../shared/hooks/useWindowSize';
import { useContainerHeight } from '../../../../shared/hooks/useContainerHeight';
import useOnClickOutside from '../../../../shared/hooks/useOnClickOutside';

//context
// import { ErrorContext } from '../../../../context/shared/ErrorContext';

//styles
import { TableRowStyles, TableItemStyles } from '../../../../SharedStyles';
import { ToolsStyles } from '../../../../OperatorStyles';

const CouponCard = (props) => {
  const { strings } = AppDiscountSchedulesService;
  const { coupon } = props;
  const CardRef = useRef();
  const OutsideRef = useRef();
  const size = useWindowResize(window.innerWidth, window.innerHeight);
  const { containerHeight, containerRef, setContainerRef } = useContainerHeight(
    50,
    CardRef
  );
  const [openTools, setOpenTools] = useState(false);

  useOnClickOutside(OutsideRef, () => setOpenTools(false));

  //gets card container height for responsive
  useEffect(() => {
    setContainerRef(CardRef);
  }, [CardRef, setContainerRef]);

  const deleteCoupon = async () => {
    props.removeCoupon(coupon.token);
  };

  const toggleToolBar = () => {
    setOpenTools(!openTools);
  };

  return (
    <>
      <TableRowStyles
        background={props.pending && '#f6d9c6'}
        borderTop='none'
        borderLeft='none'
        borderRight='none'
        padding='5px 0'
        width={size[0] >= 768 ? '100%' : '100%'}
        align='center'
        ref={containerRef}
        onMouseEnter={() => {
          size[0] >= 768 && setOpenTools(true);
        }}
        onMouseLeave={() => {
          size[0] >= 768 && setOpenTools(false);
        }}>
        <TableItemStyles flex='1'>{coupon.token}</TableItemStyles>
        <TableItemStyles flex='1'>
          {coupon.usesRemaining <= -1 ? strings.unlimited : strings.usages}
        </TableItemStyles>
        <TableItemStyles flex='1'>
          {coupon.usesRemaining <= -1 ? '--' : coupon.usesRemaining}
        </TableItemStyles>
        <TableItemStyles flex='1'>
          {coupon.ttl <= 1
            ? strings.never
            : moment(coupon.expiration).format('MM/DD/YY')}
        </TableItemStyles>
        <FaEllipsisV
          className='Card-Tools-Toggle'
          style={size[0] >= 768 ? { display: 'none' } : { display: 'block' }}
          onClick={toggleToolBar}
        />
        <ToolsStyles
          ref={OutsideRef}
          displayTools={openTools}
          containerHeight={(containerHeight - 35) / 2}>
          <div className='Tool-Item' onClick={deleteCoupon}>
            <FaRegTrashAlt title={strings.delete} />
          </div>
        </ToolsStyles>
      </TableRowStyles>
    </>
  );
};

export default CouponCard;
