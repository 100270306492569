import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';

//services
import AppCreationService from './AppCreationService';
import { AppGeoLocationService } from '../../../services/AppGeoLocation/AppGeoLocationService';

//components
import Footer from '../../shared/Footer/Footer';
import NewNav from './Nav/NewNav';
import AccountForm from './AccountForm';

//global components
import LoadingSpinner from '../../shared/Loading/LoadingSpinner';

//error
import { ErrorResponse } from '../../shared/ErrorResponse';

//context
import { CsoAccountContext } from '../../context/operator/getCsoAccount';

//styles
import {
  InteriorStyles,
  InteriorNoComponentStyles,
  JumboTronHeaderStyles,
  TitleStyles,
  ButtonDivStyles,
  InputStyles,
  InputContainerStyles,
} from '../../SharedStyles';

const AccountCreation = (props) => {
  const { strings } = AppCreationService;
  const {
    postAccount,
    setLoading,
    loading,
    setAccount,
    setCurrentUser,
  } = useContext(CsoAccountContext);
  const [systemUserToken, setSystemUserToken] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [error, setError] = useState({
    display: false,
    message: '',
  });

  //displays the new CSO Account form
  const showCsoAccountForm = () => {
    setShowForm(!showForm);
  };

  //creates a new CSO Account and Primary System User for the account
  const createNewCSO = async (formData) => {
    setLoading(true);
    let response = await postAccount(formData);
    if (response) {
      setLoading(false);
    }
  };

  //use for adding the token
  const handleChange = (e) => {
    setSystemUserToken(e.target.value);
  };

  //addes the system user to a CSO Account Via Token provided.
  const handleTokenSubmission = async (e) => {
    e.preventDefault();
    try {
      if (systemUserToken === '') {
        throw new ErrorResponse('Frontend', strings.missingToken);
      }
      const location = {
        type: 'Point',
        coordinates: null,
      };
      AppGeoLocationService.getCurrentPosition().then((coord) => {
        location.coordinates = [coord.lat, coord.lng];
      });
      let createdCsoAccount = await AppCreationService.createSystemUserToAccount(
        systemUserToken
      );
      if (createdCsoAccount) {
        createdCsoAccount.csoSystemUser.userLocation = location;
        setAccount(createdCsoAccount.csoAccount);
        setCurrentUser(createdCsoAccount.csoSystemUser);
      }
    } catch (err) {
      if (err.name === 'Frontend') {
        setError({ display: true, message: err.message });
      }
      if (err.response) {
        setError({
          display: true,
          message: strings.invalidToken,
        });
      }
    }
  };

  return (
    <InteriorStyles>
      {loading && <LoadingSpinner />}
      <div className='Body-Container'>
        <NewNav />
        <InteriorNoComponentStyles>
          <JumboTronHeaderStyles margin='10% auto'>
            {showForm ? (
              <>
                <TitleStyles size='28px'>{strings.newAccountForm}</TitleStyles>
                <AccountForm
                  createNewCSO={createNewCSO}
                  showCsoAccountForm={showCsoAccountForm}
                />
              </>
            ) : (
              <>
                <TitleStyles size='28px'>{strings.newAccount}</TitleStyles>
                <ButtonDivStyles margin='5% 0' onClick={showCsoAccountForm}>
                  {strings.createAccount}
                </ButtonDivStyles>
              </>
            )}
          </JumboTronHeaderStyles>
          {!showForm && (
            <JumboTronHeaderStyles margin='4% 0 0' borderBottom='none'>
              <TitleStyles size='28px'>{strings.referredBy}</TitleStyles>
              <div className='Content'>
                {error.display && (
                  <p className='danger-text'>{error.message}</p>
                )}
                <p>{strings.enterToken}</p>
              </div>
              <InputContainerStyles
                display='flex'
                alignItems='center'
                width='50%'
                margin='0 auto'>
                <InputStyles
                  type='text'
                  flexWidth='1'
                  margin='0 20px 0 0'
                  className='form-input'
                  name='systemUserToken'
                  placeholder={strings.accountToken}
                  onChange={handleChange}
                />
              </InputContainerStyles>
              <ButtonDivStyles margin='5% 0' onClick={handleTokenSubmission}>
                {strings.submitToken}
              </ButtonDivStyles>
            </JumboTronHeaderStyles>
          )}
        </InteriorNoComponentStyles>
      </div>
      <Footer />
    </InteriorStyles>
  );
};

export default withRouter(AccountCreation);
