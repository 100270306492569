import React, { useState, useContext } from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppFindChargerService from '../../AppFindChargerService';

//context
import { CouponContext } from '../../../../context/driver/addCoupon';

const CouponModal = (props) => {
  const { strings } = AppFindChargerService;
  const { coupon, setCoupon } = useContext(CouponContext);
  const [invalid, setInvalid] = useState(false);
  const [error, setError] = useState({
    display: true,
    message: '',
    title: '',
  });

  const closeModal = () => {
    props.handleModalState('couponModal');
  };

  const handleChange = (e) => {
    setCoupon({ [e.target.name]: e.target.value });
  };

  const submitCoupon = async () => {
    try {
      let result = await AppFindChargerService.validateCoupon({
        iotHubDeviceId: props.charger.iotHubDeviceId,
        couponId: coupon.couponId,
      });

      if (result !== null) {
        setCoupon({
          ...coupon,
          iotHubDeviceId: props.charger.iotHubDeviceId,
          completed: true,
        });
        closeModal();
      } else {
        setInvalid(true);
      }
    } catch (err) {
      setError({
        display: true,
        message: err.response.data.split(/[|]+/).pop(),
        title: 'Something went wrong',
      });
    }
  };

  const deleteCoupon = () => {
    setCoupon(null);
    closeModal();
  };

  return (
    <div className='Modal-Display-Driver'>
      <div className='Modal'>
        <div className='Modal-Header'>
          {strings.couponTitle}
          <div className='Modal-Close'>
            <FaRegWindowClose onClick={closeModal} />
          </div>
        </div>
        {coupon && coupon.completed ? (
          <>
            <div className='Modal-Content'>
              <p className='Modal-Content-Text'>{strings.couponRemove}</p>
            </div>
            <div className='Modal-Button'>
              <button className='app-button' onClick={deleteCoupon}>
                {strings.ok}
              </button>
            </div>
          </>
        ) : (
          <>
            <div className='Modal-Content'>
              <p className='Modal-Content-Text'>{strings.couponContent}</p>

              {invalid && (
                <p className='danger-text'>This is not a valid coupon.</p>
              )}
              {error.display && (
                <>
                  <p>{error.title}</p>
                  <p>{error.message}</p>
                </>
              )}
              <input
                type='text'
                name='couponId'
                placeholder={strings.couponPlaceholder}
                onChange={handleChange}
              />
            </div>
            <div className='Modal-Button'>
              <button className='app-button' onClick={submitCoupon}>
                {strings.couponButton}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CouponModal;
