import React from 'react';

//styles
import {
  ComponentInternalStyles,
  ComponentExternalStyles,
  ComponentHeaderStyles,
  ComponentTitleStyles,
} from '../../SharedStyles';

const ComponentWrapper = (props) => {
  const { align, mobileMedia } = props;

  if (props.external) {
    return <ComponentExternalStyles>{props.children}</ComponentExternalStyles>;
  }

  return (
    <ComponentInternalStyles
      align={align}
      mobileMedia={mobileMedia}
      margin='0 10px'>
      {props.title && (
        <ComponentHeaderStyles
          className={props.mobile ? 'Header-Mobile' : 'Header'}>
          <ComponentTitleStyles className='Title'>
            {props.title}
          </ComponentTitleStyles>
        </ComponentHeaderStyles>
      )}
      {props.children}
    </ComponentInternalStyles>
  );
};

export default ComponentWrapper;
