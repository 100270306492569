import React, { useState, useEffect, useContext } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {
  FaRegWindowClose,
  FaArrowCircleRight,
  FaArrowCircleLeft,
} from 'react-icons/fa';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from 'react-stripe-elements';

//services
import AppBankService from '../AppBankService';

//global components
import Button from '../../../shared/Buttons/Button';
import { LoadingSpinner } from '../../../shared';

//styles
import {
  InputStyles,
  RowStyles,
  JumboTronHeaderStyles,
  FormContainerStyles,
  FormLabelStyles,
  DivStyles,
  FormStyles,
  TitleStyles,
} from '../../../SharedStyles';

//context
import { ErrorContext } from '../../../context/shared/ErrorContext';

//helpers
import { STRIPE_OPTIONS } from '../../../shared';

const CreditCardForm = (props) => {
  const { strings } = AppBankService;
  const {
    bankForm,
    handleBankForm,
    handleCustomerToken,
    loading,
    prevStep,
    connectAccount,
  } = props;
  const { setError } = useContext(ErrorContext);

  const [tabIndex, setTabIndex] = useState(0);
  const [processLoading, setProcessLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [failedMessage, setFailedMessage] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const handleChange = (e) => {
    handleBankForm('customerForm', e.target.name, e.target.value);
  };

  useEffect(() => {
    let customerCompleted = Object.keys(bankForm.customerForm).every((key) => {
      return bankForm.customerForm[key] !== '';
    });
    if (
      customerCompleted ||
      bankForm.customerCardToken ||
      connectAccount.csoStripeDefaultPaymentMethodId
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [bankForm, connectAccount]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    //creating stripe token for card
    setProcessLoading(true);
    try {
      let { token, error } = await props.stripe.createToken();
      if (error) {
        setFailed(true);
        setFailedMessage(error.message);
      }
      if (token) {
        setSuccess(true);
        setFailed(false);
      }
      setProcessLoading(false);
      handleCustomerToken(token);
    } catch (err) {
      setError({
        display: true,
        styles: 'cso',
        title: 'Something went wrong',
        message: err.response.data.split(/[|]+/).pop(),
      });
    }
  };

  return (
    <>
      <JumboTronHeaderStyles
        align='left'
        contentAlign='center'
        contentMargin='10px 0 15px'
        contentPadding='0'
        contentSize='16px'>
        {loading && <LoadingSpinner />}
        <TitleStyles
          size='28px'
          borderBottom
          padding='0 0 5px'
          display='flex'
          alignItems='center'
          justifyContent='space-between'>
          {strings.wizard3Title}
          {connectAccount.csoStripeDefaultPaymentMethodId && (
            <span class='Close'>
              <FaRegWindowClose onClick={props.handleCloseWizard} />
            </span>
          )}
        </TitleStyles>
        <div className='Content'>
          <FormContainerStyles align='left'>
            <p>{strings.wizard3Content1}</p>
            <p>
              {strings.wizard3Content3a} <em>{strings.next}</em>{' '}
              {strings.wizard3Content3b}
            </p>
          </FormContainerStyles>
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(tabIndex) => setTabIndex(tabIndex)}>
            <TabList>
              <Tab>{strings.tabTitle}</Tab>
              <Tab>{strings.tabTitle2}</Tab>
            </TabList>
            <TabPanel>
              <InputStyles
                type='text'
                name='account_holder_name'
                onChange={handleChange}
                placeholder='Account Holder Name'
                value={bankForm.customerForm.account_holder_name}
              />
              <InputStyles
                type='number'
                name='account_number'
                onChange={handleChange}
                placeholder='Account Number'
                value={bankForm.customerForm.account_number}
              />
              <InputStyles
                type='number'
                name='confirm_account_number'
                onChange={handleChange}
                placeholder='Confirm Account Number'
                value={bankForm.customerForm.confirm_account_number}
              />
              <InputStyles
                type='number'
                name='routing_number'
                onChange={handleChange}
                placeholder='Routing Number'
                value={bankForm.customerForm.routing_number}
              />
            </TabPanel>
            <TabPanel>
              <FormStyles
                display='block'
                margin='0 auto'
                width='90%'
                id='myForm'
                onSubmit={handleSubmit}>
                {processLoading && <LoadingSpinner />}
                {success && (
                  <p className='danger-text'>
                    <em>Successfully validated</em>
                  </p>
                )}
                {failed && (
                  <p className='danger-text'>
                    <em>{failedMessage}</em>
                  </p>
                )}
                <DivStyles width='100%'>
                  <FormLabelStyles width='100%' margin='0 0 5px 0'>
                    <CardNumberElement {...STRIPE_OPTIONS()} />
                  </FormLabelStyles>
                  <DivStyles display='flex'>
                    <FormLabelStyles width='100%' margin='0 10px 5px 0'>
                      <CardExpiryElement {...STRIPE_OPTIONS()} />
                    </FormLabelStyles>
                    <FormLabelStyles width='100%' margin='0 0 5px 10px'>
                      <CardCvcElement {...STRIPE_OPTIONS()} />
                    </FormLabelStyles>
                  </DivStyles>
                </DivStyles>
                <Button
                  cso
                  hasMobile
                  formSubmit
                  buttonText={strings.validate}
                  roles={[1, 2]}
                />
              </FormStyles>
            </TabPanel>
          </Tabs>
        </div>
      </JumboTronHeaderStyles>
      <RowStyles borderBottom='none' flex='100%'>
        <div className='Arrows'>
          <button title={strings.previous} onClick={prevStep}>
            <FaArrowCircleLeft />
          </button>
          <button
            disabled={disabled}
            onClick={props.handleSubmit}
            title={strings.next}>
            <FaArrowCircleRight />
          </button>
        </div>
      </RowStyles>
    </>
  );
};

export default CreditCardForm;
