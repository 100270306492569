import React from 'react';

//styles
import {
  TableHeaderStyles,
  TableItemStyles,
  TableRowStyles,
} from '../../../../SharedStyles';

const Summaries = (props) => {
  return (
    <>
      <TableHeaderStyles
        borderTop='none'
        borderLeft='none'
        borderRight='none'
        whiteSpace='nowrap'
        justifyContent={props.justifyContent}>
        {props.titles.map((t, i) => (
          <TableItemStyles key={i} width='120px'>
            {t}
          </TableItemStyles>
        ))}
      </TableHeaderStyles>
      <TableRowStyles
        justifyContent={props.justifyContent}
        rowbackground='#fff'
        borderTop='none'
        borderLeft='none'
        borderRight='none'>
        {props.content.map((c, i) => (
          <TableItemStyles key={i} width='120px'>
            {c}
          </TableItemStyles>
        ))}
      </TableRowStyles>
    </>
  );
};

export default Summaries;
