import React from 'react';

//styles
import {
  ButtonContainerStyles,
  ButtonLinkStyles,
  ButtonStyles
} from '../../../SharedStyles';

//helpers
import { RESET_PASSWORD_URL } from '../../../shared';
import AppPlatformService from '../../../../services/AppPlatform/AppPlatformService';

const ResetForm = props => {
  const { strings } = props;

  const openResetPasswordUrl = () => {
    if (window.cordova) {
      window.cordova.InAppBrowser.open(
        RESET_PASSWORD_URL,
        '_blank',
        'location=yes'
      );
    }
  };

  return (
    <ButtonContainerStyles
      borderBottom={!props.cso}
      align={props.cso && 'left'}
      margin={props.cso ? '5px 0' : '0'}
      padding={props.cso ? '0' : '20px 0'}>
      {AppPlatformService.isCSOApp() || AppPlatformService.isDriverApp() ? (
        <ButtonStyles
          margin={props.cso && '0'}
          inverse
          title={strings.resetToolTip}
          onClick={openResetPasswordUrl}>
          {strings.resetToolTip}
        </ButtonStyles>
      ) : (
        <ButtonLinkStyles
          margin={props.cso && '0'}
          inverse
          title={strings.resetToolTip}
          href={RESET_PASSWORD_URL}>
          {strings.resetToolTip}
        </ButtonLinkStyles>
      )}
    </ButtonContainerStyles>
  );
};

export default ResetForm;
