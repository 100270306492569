import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import AppApiService from '../../../services/AppApi/AppApiService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppSubscriptionService = {
  createCSOStripeAccounts: function (urls) {
    return AppApiService.post('createCSOStripeAccounts', null, urls);
  },
  updateCSOStripeAccount: function (stripeAccount) {
    return AppApiService.post('updateCSOStripeAccount', null, stripeAccount);
  },
  strings: localStrings,
};

export default AppSubscriptionService;
