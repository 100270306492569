import styled from 'styled-components';

export const ListContainerStyles = styled.div`
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? borderBottom : '1px solid #e7e7e7'};
  padding: ${({ padding }) => (padding ? padding : '5px 10px 10px')};
  height: ${({ height }) => height};
  font-size: ${({ size }) => size};
  overflow-y: auto;
  -ms-overflow-style: ${({ scrollBar }) => (scrollBar ? scrollBar : 'auto')};
  scrollbar-width: ${({ scrollBar }) => (scrollBar ? scrollBar : 'auto')};
  .Title {
    font-size: 12px;
    color: #2c456c;
    font-weight: 600;
  }
`;
