import React from 'react';
import moment from 'moment';

//styles
import { TableRowStyles, TableItemStyles } from '../../../../../SharedStyles';

//servies
import AppPlatformService from '../../../../../../services/AppPlatform/AppPlatformService';

const FeesCard = (props) => {
  return (
    <TableRowStyles
      hoverBackground='#f1f7ff'
      borderTop='none'
      borderLeft='none'
      borderRight='none'
      align='center'
      width='761px'>
      <TableItemStyles width='100%'>
        {moment(props.date).format('MM/DD/YY h:mma')}
      </TableItemStyles>
      <TableItemStyles width='100%'>{props.feeType}</TableItemStyles>
      <TableItemStyles width='100%'>{props.iotHubDeviceId}</TableItemStyles>
      <TableItemStyles width='100%' maxWidth='75px'>
        {props.ports}
      </TableItemStyles>
      <TableItemStyles width='100%'>{props.rate}</TableItemStyles>
      <TableItemStyles width='100%'>{AppPlatformService.currencyFormat(props.amount)}</TableItemStyles>
    </TableRowStyles>
  );
};

export default FeesCard;
