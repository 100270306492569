import React, { useState } from 'react';

//services
import AppPaymentsService from '../AppPaymentsService';

//components
import PaymentsFundsHistory from './PaymentsFundsHistory';
import PaymentsHistory from './PaymentsHistory';
import OtherLedgers from './OtherLedgers';

//Global Components
import ToggleSwitch from '../../shared/ToggleSwitch';
import { DivStyles } from '../../../SharedStyles';

const PaymentsLedger = (props) => {
  const { strings } = AppPaymentsService;
  const [ledgerShowing, setLedgerShowing] = useState({
    sessions: true,
    funds: false,
    other: false,
  });

  const toggleLedger = (e) => {
    setLedgerShowing((prev) => {
      for (let prop in prev) {
        if (e.target.name === prop) {
          prev[prop] = true;
        } else {
          prev[prop] = false;
        }
      }
      return { ...prev };
    });
  };

  return (
    <>
      <DivStyles display='flex' justifyContent='center'>
        <ToggleSwitch
          checked={!!ledgerShowing.sessions}
          name='sessions'
          handleChange={toggleLedger}
          label='sessions'
        />
        <ToggleSwitch
          checked={!!ledgerShowing.funds}
          name='funds'
          handleChange={toggleLedger}
          label='funds'
        />
        <ToggleSwitch
          checked={!!ledgerShowing.other}
          name='other'
          handleChange={toggleLedger}
          label='other'
        />
      </DivStyles>
      {ledgerShowing.sessions && <PaymentsHistory />}
      {ledgerShowing.funds && <PaymentsFundsHistory />}
      {ledgerShowing.other && <OtherLedgers />}
    </>
  );
};

export default PaymentsLedger;
