import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//layout
import { InteriorWrapper, ComponentWrapper } from '../shared/index';

//services
import AppChargingService from './AppChargingService';

//components
import AppChargingSliderShow from './AppChargingSlideShow/AppChargingSliderShow';
import LoadingSpinner from '../../shared/Loading/LoadingSpinner';

//styles
import './AppCharging.scss';

//context
import { VehiclesContext } from '../../context/driver/getVehiclesContext';
import { SearchContext } from '../../context/driver/getPreviousSearch';
import { DriverChargerContext } from '../../context/driver/getDriverLinkedChargers';
import { DriverContext } from '../../context/driver/getDriverContext';

import LocationBranding from '../LocationBranding/LocationBranding';
import { CouponContext } from '../../context/driver/addCoupon';

const AppCharging = (props) => {
  const { strings } = AppChargingService;
  const { setCurrentTab } = useContext(SearchContext);
  const { driver } = useContext(DriverContext);
  const { vehicles, vehicleLoading } = useContext(VehiclesContext);
  const {
    linkedChargers,
    linkedCharger,
    setLinkedCharger,
    chargerLoading,
    fetchLinkCharger,
  } = useContext(DriverChargerContext);
  const { coupon } = useContext(CouponContext);
  const [charger, setCharger] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentlyCharging, setCurrentlyCharging] = useState(false);
  const [increaseInterval, setIncreaseInterval] = useState(false);

  //getting charger by props id
  const getCharger = async (location, id) => {
    try {
      let chargingStation = await AppChargingService.getChargingStation({
        iotHubDeviceId: id,
        location: location,
        bypassDirection: true,
        coupon: coupon ? coupon.couponId : '',
      });

      let favCharger = await fetchLinkCharger(id);

      if (chargingStation.private) {
        if (!(favCharger.home || favCharger.assigned)) {
          setCharger(null);
          setLinkedCharger(null);
          setLoading(false);
          return;
        }
      }

      localStorage.setItem(
        'lastSelectedCharger',
        chargingStation.iotHubDeviceId
      );

      if (favCharger) {
        chargingStation.favorite = favCharger.favorite;
        chargingStation.home = favCharger.home;
        chargingStation.assigned = favCharger.assigned;
        setLinkedCharger(favCharger);
      } else {
        chargingStation.favorite = false;
        chargingStation.home = false;
        chargingStation.assigned = false;
        setLinkedCharger(null);
      }

      setCharger((c) => ({ ...c, ...chargingStation }));
      if (!chargerLoading && chargingStation) {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setCharger(null);
      setLinkedCharger(null);
      if (charger.iotHubDeviceId !== props.match.params.id) {
        setCharger(null);
        setLinkedCharger(null);
      }
    }
  };

  //getting charger by first favorite in the array if no props id provided
  const getChargerNoParams = async () => {
    let cachedStation = localStorage.getItem('lastSelectedCharger');

    let firstFavorite = linkedChargers.filter((linked) => {
      return linked.favorite || linked.home || linked.assigned;
    });

    const compareTwoProps = (a, b) => {
      return new Date(b.lastChargeTimeStamp) - new Date(a.lastChargeTimeStamp);
    };

    let sortedFavorites = firstFavorite.sort(compareTwoProps);

    if (cachedStation) {
      getCharger(driver.driverLocation, cachedStation);
      let cached = sortedFavorites.filter(
        (station) => station.iotHubDeviceId === cachedStation
      );
      setLinkedCharger((lc) => ({ ...lc, ...cached[0] }));
    } else if (sortedFavorites.length === 0) {
      setCharger(null);
      setLinkedCharger(null);
      setLoading(false);
    } else {
      getCharger(driver.driverLocation, sortedFavorites[0].iotHubDeviceId);
      setLinkedCharger((lc) => ({ ...lc, ...sortedFavorites[0] }));
    }
  };

  useEffect(() => {
    //Inital call
    if (props.match.params.id) {
      getCharger(driver.driverLocation, props.match.params.id);
    } else {
      getChargerNoParams();
    }

    let apiCounter = 0;
    //Inital call interval for repeated called
    const fetchingChargers = setInterval(
      () => {
        apiCounter = ++apiCounter;
        if (apiCounter === 30) {
          setIncreaseInterval(true);
        }

        if (props.match.params.id) {
          getCharger(driver.driverLocation, props.match.params.id);
        } else {
          getChargerNoParams();
        }
      },
      increaseInterval ? 600000 : 30000
    );
    return () => {
      clearInterval(fetchingChargers);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlyCharging, chargerLoading, props.match.params.id]);

  const checkingIsCharging = (isCharging) => {
    setCurrentlyCharging(isCharging);
  };

  return (
    <InteriorWrapper title={strings.title}>
      {loading && <LoadingSpinner />}
      {charger && Object.keys(charger).length > 0 && vehicles.length !== 0 ? (
        <>
          {/* Conditionally Render Via Location Branding Prop, Waiting on API */}
          {charger.enableLocationBranding && (
            <LocationBranding charger={charger} />
          )}
          <AppChargingSliderShow
            details={vehicles}
            charger={charger}
            linkedcharger={linkedCharger ? linkedCharger : null}
            checkingIsCharging={checkingIsCharging}
            getCharger={getCharger}
          />
        </>
      ) : (
        <>
          {!charger && !chargerLoading && !loading && (
            <ComponentWrapper title={strings.missingChargerTitle} mobile>
              <div className='List-Placeholder'>
                <p className='Placeholder-Content'>
                  {strings.missingChargerContent}
                </p>
                <div>
                  <Link
                    className='app-button'
                    to='/'
                    onClick={() => {
                      setCurrentTab(1);
                    }}>
                    {strings.missingChargerButton}
                  </Link>
                  or
                  <Link
                    className='app-button'
                    to='/my-chargers?addCharger=true'>
                    {strings.missingOwnedButton}
                  </Link>
                </div>
              </div>
            </ComponentWrapper>
          )}
          {vehicles.length === 0 && !vehicleLoading && (
            <ComponentWrapper title={strings.missingVehicleTitle} mobile>
              <div className='List-Placeholder'>
                <p className='Placeholder-Content'>
                  {strings.missingVehicleContent}
                </p>
                <div>
                  <Link className='app-button' to='/add-vehicle'>
                    {strings.missingVehicleButton}
                  </Link>
                </div>
              </div>
            </ComponentWrapper>
          )}
        </>
      )}
    </InteriorWrapper>
  );
};

export default AppCharging;
