import React, { useState, useEffect, useContext } from 'react';

//layout
import { ComponentWrapper, InteriorWrapper } from '../../shared';
import { LoadingSpinner } from '../../../shared';

//service
import AccessCardsService from '../AccessCardsService';

//context
import { ErrorContext } from '../../../context/shared/ErrorContext';

//components
import Button from '../../../shared/Buttons/Button';

//styles
import ActivateForm from '../Components/ActivateForm/ActivateForm';

const AccessCardActivate = (props) => {
  const { strings } = AccessCardsService;
  const [loading, setLoading] = useState(true);
  const { setError } = useContext(ErrorContext);
  const [card, setCard] = useState({});

  useEffect(() => {
    const fetchAccessCards = async () => {
      let res = await AccessCardsService.getAccessCards();
      if (props.match.params.id) {
        let singleCard = res
          .filter((c) => c.id === props.match.params.id)
          .reduce((acc, next) => ({ ...acc, ...next }), {});
        if (!singleCard.activated) {
          singleCard.serialId = '';
        }
        setCard(singleCard);
      }
    };
    fetchAccessCards();
    setLoading(false);
  }, []);

  console.log(card);

  const handleChange = (e) => {
    setCard({ ...card, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (card.activate) {
        await AccessCardsService.updateAccessCard(card);
      } else {
        await AccessCardsService.activateAccessCard(card);
      }
      props.history.push('/access-cards');
    } catch (err) {
      setLoading(false);
      setError({
        display: true,
        title: 'Error',
        message: err.response.data.split(/[|]+/).pop(),
        styles: 'driver',
      });
    }
  };

  return (
    <InteriorWrapper
      title={strings.activateTitle}
      isInternal
      myForm='myForm'
      actionText={card.activated ? strings.save : strings.activate}
      path='/access-cards'>
      {loading && <LoadingSpinner />}
      <ComponentWrapper title={strings.activateTitle}>
        <form id='myForm' onSubmit={handleSubmit}>
          <ActivateForm
            strings={strings}
            handleChange={handleChange}
            card={card}
          />
        </form>
      </ComponentWrapper>
      <Button
        driver
        hasMobile
        formSubmit
        buttonText={card.activated ? strings.save : strings.activate}
      />
    </InteriorWrapper>
  );
};

export default AccessCardActivate;
