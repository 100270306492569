// import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
// import strings from './strings';

// const localStrings = strings[AppPlatformService.getLanguage()];

// let actionItems = {};
// localStrings.actionFilters.forEach((action, i) => {
//   actionItems = { ...actionItems, [action]: i };
// });
// export const actionFilterEnum = actionItems;

export const tableHeaderConvert = {
  First: 'firstName',
  Last: 'lastName',
  Email: 'email',
  Role: 'role',
  Created: 'createdDate',
  'Last Sign-in': 'lastSignIn',
  'Location Name': 'locationDisplayName',
};
