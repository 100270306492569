import React from 'react';

//styles
import {
  FormContainerStyles,
  FormRowStyles,
  InputStyles,
} from '../../../SharedStyles';

const AccountForm = (props) => {
  const { driver, strings } = props;

  const handleChange = (e) => {
    props.updateDriver(e);
  };
  return (
    <FormContainerStyles padding='10px 0 15px' borderBottom>
      <FormRowStyles>
        <InputStyles
          driver
          flexWidth='50%'
          margin='0 10px'
          type='text'
          name='firstName'
          placeholder={strings.fn}
          value={driver.firstName || ''}
          onChange={handleChange}
          required
        />
        <InputStyles
          driver
          flexWidth='50%'
          margin='0 10px'
          type='text'
          name='lastName'
          placeholder={strings.ln}
          value={driver.lastName || ''}
          onChange={handleChange}
          required
        />
      </FormRowStyles>
      <FormRowStyles>
        <InputStyles
          driver
          flexWidth='50%'
          margin='0 10px'
          type='text'
          maxLength='256'
          name='nickName'
          placeholder={strings.nickname}
          value={driver.nickName || ''}
          onChange={handleChange}
        />
        <InputStyles flexWidth='50%' margin='0 10px' noBorder type='empty' />
      </FormRowStyles>
      <FormRowStyles>
        <InputStyles
          driver
          flexWidth='50%'
          margin='0 10px'
          type='email'
          name='email'
          disabled
          placeholder={strings.email}
          value={driver.email || ''}
          onChange={handleChange}
        />
        <InputStyles
          flexWidth='50%'
          margin='0 10px'
          type='text'
          name='phone'
          placeholder={strings.phone}
          value={driver.phone || ''}
          onChange={handleChange}
          required
        />
      </FormRowStyles>
    </FormContainerStyles>
  );
};

export default AccountForm;
