// Driver Components
import AccessCardActivate from '../../components/driver/AccessCards/Pages/AccessCardActivate';
import AccessCardOrder from '../../components/driver/AccessCards/Pages/AccessCardOrder';
import AccessCards from '../../components/driver/AccessCards/Pages/AccessCards';
import AccountSettings from '../../components/driver/AccountSettings/AccountSettings';
import AppCharging from '../../components/driver/AppCharging/AppCharging';
import ChargerDetails from '../../components/driver/ChargerDetails/ChargerDetails';
import AppFindCharger from '../../components/driver/FindCharger/FindCharger';
import AddMembership from '../../components/driver/Memberships/AddMembership/AddMembership';
import MembershipInfo from '../../components/driver/Memberships/MembershipInfo/MembershipInfo';
// import MembershipLocations from '../../components/driver/Memberships/MembershipMaps/MembershipLocations';
import Memberships from '../../components/driver/Memberships/Memberships';
import Notifications from '../../components/driver/Notifications/Notifications';
import OwnedChargers from '../../components/driver/OwnedChargers/OwnedChargers';
import Payments from '../../components/driver/Payments/Payments';
import Settings from '../../components/driver/Settings/Settings';
import EditVehicle from '../../components/driver/Vehicles/VehicleForms/Forms/EditVehicle';
import VehicleCreation from '../../components/driver/Vehicles/VehicleForms/VehicleCreation';
import VehicleList from '../../components/driver/Vehicles/VehiclesList/VehicleList';
import AppNotFound from '../../components/shared/AppNotFound/AppNotFound';
import PrivacyPolicy from '../../components/shared/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from '../../components/shared/TermsAndConditions/TermsAndConditions';

export const driverRoutingMap = [
  {
    route: '/',
    component: AppFindCharger,
    devOnly: false,
  },
  {
    route: '/charging',
    component: AppCharging,
    devOnly: false,
  },
  {
    route: '/charging/:id',
    component: AppCharging,
    devOnly: false,
  },
  {
    route: '/account-settings',
    component: AccountSettings,
    devOnly: false,
  },
  {
    route: '/memberships',
    component: Memberships,
    devOnly: false,
  },
  {
    route: '/memberships/info/:id',
    component: MembershipInfo,
    devOnly: false,
  },
  {
    route: '/memberships/add-membership',
    component: AddMembership,
    devOnly: false,
  },
  // Turn off to add the name of the locations from cso side of the app per Jeff
  // {
  //   route: '/memberships/map/:id',
  //   component: MembershipLocations,
  //   devOnly: true,
  // },
  {
    route: '/vehicle-list',
    component: VehicleList,
    devOnly: false,
  },
  {
    route: '/add-vehicle',
    component: VehicleCreation,
    devOnly: false,
  },
  {
    route: '/vehicle-list/:id',
    component: EditVehicle,
    devOnly: false,
  },
  {
    route: '/charger-details/:id',
    component: ChargerDetails,
    devOnly: false,
  },
  {
    route: '/my-chargers',
    component: OwnedChargers,
    devOnly: false,
  },
  {
    route: '/payments',
    component: Payments,
    devOnly: false,
  },
  {
    route: '/notifications',
    component: Notifications,
    devOnly: false,
  },
  {
    route: '/access-cards',
    component: AccessCards,
    devOnly: false,
  },
  {
    route: '/access-cards/order',
    component: AccessCardOrder,
    devOnly: false,
  },
  {
    route: '/access-cards/activate/:id',
    component: AccessCardActivate,
    devOnly: false,
  },
  {
    route: '/settings',
    component: Settings,
    devOnly: false,
  },
  {
    route: '/privacy',
    component: PrivacyPolicy,
    devOnly: false,
  },
  {
    route: '/terms',
    component: TermsAndConditions,
    devOnly: false,
  },
  {
    route: '*',
    component: AppNotFound,
    devOnly: false,
  },
];
