import React, { useContext } from 'react';
import { FaRegEdit } from 'react-icons/fa';

//context
import { CsoAccountContext } from '../../context/operator/getCsoAccount';
import {
  DivStyles,
  ImageMediumStyles,
  TitleStyles,
  TextStyles
} from '../../SharedStyles';

const AccountHeader = ({ showEditTitleModal, strings }) => {
  const { account } = useContext(CsoAccountContext);
  return (
    <DivStyles display='flex' alignItems='center' padding='10px' borderBottom>
      <DivStyles>
        <ImageMediumStyles
          maxWidth='120px'
          maxHeight='100px'
          src={
            account.imagePath
              ? account.imagePath
              : require('../../../assets/images/AccountPlaceholder.png').default
          }
          alt={account.csoAccountName || 'Placeholder'}
        />
      </DivStyles>
      <DivStyles margin=' 0 0 0 10px'>
        <TitleStyles
          color='#000'
          size='14px'
          weight='bold'
          margin='0'
          display='flex'
          alignItems='center'>
          {account.csoAccountName || ''}{' '}
          {showEditTitleModal && (
            <DivStyles
              svgMargin='0 0 0 10px'
              svgSize='17px'
              onClick={showEditTitleModal}>
              <FaRegEdit />
            </DivStyles>
          )}
        </TitleStyles>
        <TextStyles color='#000' size='12px' margin='0'>
          {strings.accountId}: {account.id || ''}
        </TextStyles>
      </DivStyles>
    </DivStyles>
  );
};

export default AccountHeader;
