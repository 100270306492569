import strings from './strings';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppTermsService = {
  strings: localStrings,
};

export default AppTermsService;
