import { useState, useEffect } from 'react';

import { handleSortReversePagination } from '../../shared/Pagination/helpers';

export const useSortAndReverse = (
  intialSortStatus,
  intialData,
  intialSortable,
  intialConverted,
  updateSortedItems,
  desc
) => {
  const [sortStatus, setSortStatus] = useState(intialSortStatus);
  const [isActiveSort, setIsActiveSort] = useState({});
  const [sortReverse, setSortReverse] = useState({});

  useEffect(() => {
    intialSortable.forEach((name) => {
      if (intialConverted[name] === intialSortStatus) {
        setIsActiveSort((a) => ({
          ...a,
          [intialSortStatus]: true,
        }));
        setSortReverse((r) => ({
          ...r,
          [intialSortStatus]: desc ? true : false,
        }));
      } else {
        setIsActiveSort((a) => ({
          ...a,
          [intialConverted[name]]: false,
        }));
        setSortReverse((r) => ({
          ...r,
          [intialConverted[name]]: desc ? false : true,
        }));
      }
    });
  }, [desc, intialConverted, intialData, intialSortable, intialSortStatus]);

  useEffect(() => {
    let newlySorted = handleSortReversePagination(
      sortReverse,
      sortStatus,
      intialData
    );
    let sortedWithTime = newlySorted.map((n) => {
      n.time = Math.floor(Date.now() * Math.random);
      return n;
    });
    updateSortedItems(sortedWithTime);
    // eslint-disable-next-line
  }, [sortReverse, intialData, sortStatus]);

  const changeActiveSort = (status) => {
    let updatedValues = Object.keys(isActiveSort).reduce(
      (reduced, key) => ({ ...reduced, [key]: false, [status]: true }),
      {}
    );

    setSortStatus(status);
    setIsActiveSort(updatedValues);

    setSortReverse({ ...sortReverse, [status]: !sortReverse[status] });
  };

  return [isActiveSort, sortReverse, changeActiveSort];
};
