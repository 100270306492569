import strings from './strings';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import AppApiService from '../../../services/AppApi/AppApiService';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppMembershipsService = {
  getDriverMemberships: () => {
    return AppApiService.get('getDriverMemberships');
  },
  getMembershipsBySearch: searchTerm => {
    return AppApiService.post('getMembershipsBySearch', null, {
      SearchString: searchTerm
    });
  },
  getPrivateToken: token => {
    return AppApiService.post('getPrivateMembershipByToken', null, token);
  },
  joinPrivate: token => {
    return AppApiService.post('joinPrivateMembershipByToken', null, token);
  },
  insertDriverMembership: id => {
    return AppApiService.post('insertDriverMembership', null, id);
  },
  removeDriverMembership: id => {
    return AppApiService.post('removeDriverMembership', null, id);
  },
  getMembershipById: membershipId => {
    return AppApiService.post('getMembershipById', null, membershipId);
  },
  updateDriver: driver => {
    return AppApiService.post('updateDriver', null, driver);
  },
  strings: localStrings
};

export default AppMembershipsService;
