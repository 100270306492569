import React, { createContext, useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';

import AppMembershipsService from '../../operator/Memberships/AppMembershipsService';

import { CsoAccountContext } from './getCsoAccount';

export const CsoMembershipContext = createContext();

const CsoMembershipState = (props) => {
  const { account, loading, setLoading } = useContext(CsoAccountContext);
  const [allMemberships, setAllMemberships] = useState([]);

  async function fetchMemberships() {
    if (account) {
      let gotAllMemberships = await AppMembershipsService.getMembershipsByCSO(
        account.csoAccountId
      );
      setAllMemberships(gotAllMemberships);
      setLoading(false);

      return gotAllMemberships;
    }
  }

  useEffect(() => {
    fetchMemberships();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CsoMembershipContext.Provider
      value={{
        loading,
        setLoading,
        allMemberships,
        setAllMemberships,
        fetchMemberships,
      }}>
      {props.children}
    </CsoMembershipContext.Provider>
  );
};

export default withRouter(CsoMembershipState);
