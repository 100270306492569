import React, { useState, useContext, useEffect } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useSwipeable } from 'react-swipeable';

//services
import AppChargingContentSlider from '../AppChargingContentSlider/AppChargingContentSlider';

//components
import AppChargingSliderItem from './AppChargingSliderItem';

//context
import { VehiclesContext } from '../../../context/driver/getVehiclesContext';

const Swipeable = ({children, ...props}) => {
  const handlers = useSwipeable(props);
  return (<div { ...handlers }>{children}</div>);
}

const AppChargingSliderShow = (props) => {
  const { vehicles } = useContext(VehiclesContext);
  const [index, setIndex] = useState(null);
  const [forward, setForward] = useState(true);

  useEffect(() => {
    //set index based on the default value
    let filterTest = vehicles.filter((veh, i) => {
      if (veh.default) {
        setIndex(i);
      }
      return veh.default && veh;
    });
    //if no defaults exist - set index to 0
    if (filterTest.length === 0) {
      setIndex(0);
    }
  }, [vehicles]);

  const nextSlide = (ev) => {
    let newIndex = (index + 1) % vehicles.length;
    if (newIndex > index) {
      setForward(true);
      setIndex(newIndex);
    }
  };

  const prevSlide = (ev) => {
    let newIndex = (index || vehicles.length) - 1;
    if (newIndex < index) {
      setForward(false);
      setIndex(newIndex);
    }
  };

  const addEndListener = (node, done) =>
    node.addEventListener('transitionend', done, false);

  return (
    index !== null && (
      <>
        <Swipeable onSwipedLeft={nextSlide} onSwipedRight={prevSlide}>
          <section
            className={`slideshow vehicleSlidershow ${
              forward ? 'forwards' : 'backwards'
            }`}>
            <div
              className='next'
              onClick={nextSlide}
              style={
                index === vehicles.length - 1
                  ? { display: 'none' }
                  : { display: 'block' }
              }
            />
            <div
              className='prev'
              onClick={prevSlide}
              style={index === 0 ? { display: 'none' } : { display: 'block' }}
            />
            <TransitionGroup component={null}>
              <CSSTransition
                key={index}
                classNames='slide'
                addEndListener={addEndListener}>
                <AppChargingSliderItem vehicle={vehicles[index]} />
              </CSSTransition>
            </TransitionGroup>
          </section>
        </Swipeable>
        <AppChargingContentSlider
          {...vehicles[index]}
          charger={props.charger}
          linkedcharger={props.linkedcharger}
          checkingIsCharging={props.checkingIsCharging}
          getCharger={props.getCharger}
        />
      </>
    )
  );
};

export default AppChargingSliderShow;
