import React, { useState, useEffect } from 'react';
import { FaRegTimesCircle } from 'react-icons/fa';

//services
import AppChargingProfilesService from '../../AppChargingProfilesService';

//components
import Time from './Time';
import DayComponent from '../../../OperatorShared/DayComponent';

//global components
import ToggleSwitch from '../../../OperatorShared/ToggleSwitch/CsoToggleSwitch';
import { RoleCanEdit } from '../../../OperatorShared';

//styles
import { ContentContainerStyles } from '../../../../OperatorStyles';
import {
  DivStyles,
  TextStyles,
  ButtonGreyStyles
} from '../../../../SharedStyles';

const Schedules = props => {
  const { strings } = AppChargingProfilesService;

  const [allDay, setAllDay] = useState(false);
  const [schedule, setSchedule] = useState(props.schedule);

  //adds an empty time object to times array on the schedule
  const addTimesToSchedule = () => {
    const timeObject = {
      id: Math.random()
        .toString(36)
        .substring(2),
      timeStart: '',
      timeEnd: '',
      percentage: 100
    };
    setSchedule({
      ...schedule,
      times: [...schedule.times, timeObject]
    });
  };

  //flips the dow to opposite on click
  const toggleDay = e => {
    setSchedule({
      ...schedule,
      dow: {
        ...schedule.dow,
        [e.target.name]: e.target.checked
      }
    });
  };

  useEffect(() => {
    props.updatingSxArray(schedule);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule]);

  useEffect(() => {
    schedule.times.map(time => {
      if (
        time.timeStart === '00:00' &&
        time.timeEnd === '23:59' &&
        schedule.times.length === 1
      ) {
        setAllDay(true);
      }
      return null;
    });
  }, [schedule]);

  //function for time object changes in time component and passed and merged to sx object
  const handleTimeChange = (name, value, timeId) => {
    let currentTimes = schedule.times.map(time => {
      if (time.id === timeId) {
        time[name] = value;
      }
      if (time.percentage > 100) {
        time.percentage = 100;
      }
      return time;
    });
    setSchedule({
      ...schedule,
      times: [...currentTimes]
    });
    props.updatingSxArray(schedule);
  };

  const removeSchedule = () => {
    props.removeItemFromSx(schedule.id);
  };

  const removeTimeFromSx = id => {
    let removedSchedules = schedule.times.filter(time => {
      return time.id !== id;
    });
    setSchedule({
      ...schedule,
      times: removedSchedules
    });
  };

  const toggleAllDay = () => {
    setAllDay(!allDay);
  };

  return (
    <DivStyles>
      {/* Schedule Header */}
      <DivStyles
        display='flex'
        alignItems='center'
        svgColor='#f44'
        svgSize='20px'
        svgMargin='0 0 0 10px'>
        <TextStyles size='14px' margin='0'>
          {strings.schedule}: {props.count}
        </TextStyles>
        <RoleCanEdit roles={[1]}>
          {props.allSxs.length > 1 && (
            <FaRegTimesCircle onClick={removeSchedule} />
          )}
        </RoleCanEdit>
      </DivStyles>
      {/* Day Component */}
      <DivStyles display='flex' alignItems='center'>
        {Object.keys(schedule).length > 0 && (
          // Object.entries(schedule.dow).map(([key, value]) => {
          //   return (
          //     <DayComponent
          //       key={key}
          //       label={key}
          //       handleChange={toggleDay}
          //       checked={value}
          //     />
          //   );
          // })
          <DayComponent scheduleDays={schedule.dow} handleChange={toggleDay} />
        )}
      </DivStyles>
      {/* All Day Toggle */}
      <DivStyles display='flex' alignItems='center'>
        <TextStyles size='14px' weight='400' margin='0'>
          {strings.allDay}
        </TextStyles>
        <ToggleSwitch
          name='allDay'
          checked={allDay}
          handleChange={toggleAllDay}
          disable={schedule.times.length > 1}
        />
      </DivStyles>
      {/* Time Slot Schedules */}
      {Object.keys(schedule).length > 0 &&
        schedule.times.map((time, i) => {
          return (
            <ContentContainerStyles padding='10px 10px 20px' key={time.id}>
              <Time
                handleTimeChange={handleTimeChange}
                removeTimeFromSx={removeTimeFromSx}
                time={time}
                scheduleTime={schedule}
                times={schedule.times}
                count={i + 1}
                editForm={props.editForm}
                allDay={allDay}
                toggleAllDay={toggleAllDay}
              />
            </ContentContainerStyles>
          );
        })}
      {!allDay && (
        <RoleCanEdit roles={[1]}>
          <ButtonGreyStyles
            size='12px'
            margin='0 0 10px 30%'
            padding='0 10px'
            onClick={addTimesToSchedule}>
            {strings.addTime}
          </ButtonGreyStyles>
        </RoleCanEdit>
      )}
    </DivStyles>
  );
};

export default Schedules;
