import React from 'react';
import { Link } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';

//global components
import RoleCanEdit from '../../operator/OperatorShared/RoleCanEdit';

//styles
import {
  ButtonContainerStyles,
  ButtonSpanStyles,
  ButtonMobileStyles,
  ButtonStyles,
  DivStyles,
  TextStyles,
  ButtonResetStyles,
} from '../../SharedStyles';

const Button = React.forwardRef((props, ref) => {
  if (props.cso) {
    return (
      <RoleCanEdit roles={props.roles}>
        <ButtonContainerStyles
          margin={props.margin}
          noMobile={props.noMobile}
          hasMobile={props.hasMobile}>
          {props.redirect && (
            <Link
              className={props.disabled && 'disabled'}
              to={props.disabled ? '#' : props.redirect}>
              <ButtonSpanStyles>{props.buttonText}</ButtonSpanStyles>
            </Link>
          )}
          {props.onclick && (
            <ButtonStyles
              onClick={props.onclick}
              disabled={props.disabled}
              inverse={props.inverse}>
              {props.buttonText}
            </ButtonStyles>
          )}
          {props.formSubmit && (
            <ButtonStyles
              ref={ref}
              form='myForm'
              type='submit'
              inverse={props.inverse}
              disabled={props.disabled}>
              {props.buttonText}
            </ButtonStyles>
          )}
          {props.doubleButton &&
            props.doubleButton.map((button, index) => {
              if (button.link) {
                return (
                  <Link to={button.link} key={index}>
                    <ButtonSpanStyles inverse={button.inverse}>
                      {button.text}
                    </ButtonSpanStyles>
                  </Link>
                );
              }
              if (button.onclick) {
                return (
                  <ButtonStyles
                    key={index}
                    onClick={button.onclick}
                    inverse={button.inverse}
                    disabled={props.disabled}>
                    {button.text}
                  </ButtonStyles>
                );
              }
              return null;
            })}
        </ButtonContainerStyles>
        {/* Mobile Add Button - SVG*/}
        {props.hasMobile && (
          <ButtonMobileStyles>
            {props.redirect && (
              <Link
                to={props.disabled ? '#' : props.redirect}
                className={
                  props.disabled ? 'Mobile-Link disabled' : 'Mobile-Link'
                }>
                <FaPlus title={props.buttonText} />
              </Link>
            )}
            {props.onclick && (
              <ButtonResetStyles
                className='Mobile-Link'
                onClick={props.onclick}
                disabled={props.disabled}>
                <FaPlus title={props.buttonText} />
              </ButtonResetStyles>
            )}
            {props.doubleButton &&
              props.doubleButton.map((button, index) => {
                if (button.link) {
                  return (
                    <Link className='Mobile-Link' to={button.link} key={index}>
                      {button.mobileText && (
                        <TextStyles>{button.mobileText}</TextStyles>
                      )}
                      {button.icon ? (
                        button.icon
                      ) : (
                        <FaPlus title={props.mobileText} />
                      )}
                    </Link>
                  );
                }
                if (button.onclick) {
                  return (
                    <DivStyles
                      svgColor='#fff'
                      className='Mobile-Link'
                      key={index}
                      onClick={button.onclick}>
                      <TextStyles>{button.mobileText}</TextStyles>
                      <FaPlus title={props.mobileText} />
                    </DivStyles>
                  );
                }
                return null;
              })}
          </ButtonMobileStyles>
        )}
      </RoleCanEdit>
    );
  }
  if (props.driver) {
    return (
      <>
        <ButtonContainerStyles
          margin={props.margin}
          noMobile={props.noMobile}
          hasMobile={props.hasMobile}>
          {props.redirect && (
            <Link
              className={props.disabled && 'disabled'}
              to={props.disabled ? '#' : props.redirect}>
              <ButtonSpanStyles>{props.buttonText}</ButtonSpanStyles>
            </Link>
          )}
          {props.onclick && (
            <ButtonStyles
              onClick={props.onclick}
              disabled={props.disabled}
              inverse={props.inverse}>
              {props.buttonText}
            </ButtonStyles>
          )}
          {props.formSubmit && (
            <ButtonStyles
              ref={ref}
              form='myForm'
              type='submit'
              inverse={props.inverse}
              disabled={props.disabled}>
              {props.buttonText}
            </ButtonStyles>
          )}
          {props.doubleButton &&
            props.doubleButton.map((button, index) => {
              if (button.link) {
                return (
                  <Link to={button.link} key={index}>
                    <ButtonSpanStyles inverse={button.inverse}>
                      {button.text}
                    </ButtonSpanStyles>
                  </Link>
                );
              }
              if (button.onclick) {
                return (
                  <ButtonStyles
                    key={index}
                    onClick={button.onclick}
                    disabled={props.disabled}
                    inverse={button.inverse}>
                    {button.text}
                  </ButtonStyles>
                );
              }
              return null;
            })}
        </ButtonContainerStyles>
        {/* Mobile Add Button - SVG*/}
        {props.hasMobile && (
          <ButtonMobileStyles>
            {props.redirect && !props.doubleButton && (
              <Link
                to={props.disabled ? '#' : props.redirect}
                className={
                  props.disabled ? 'Mobile-Link disabled' : 'Mobile-Link'
                }>
                <FaPlus title={props.buttonText} />
              </Link>
            )}
            {props.onclick && !props.doubleButton && (
              <ButtonResetStyles
                className='Mobile-Link'
                onClick={props.onclick}
                disabled={props.disabled}>
                <FaPlus title={props.buttonText} />
              </ButtonResetStyles>
            )}
            {props.doubleButton &&
              props.doubleButton.map((button, index) => {
                if (button.link) {
                  return (
                    <Link className='Mobile-Link' to={button.link} key={index}>
                      {button.mobileText && (
                        <TextStyles>{button.mobileText}</TextStyles>
                      )}
                      {button.icon ? (
                        button.icon
                      ) : (
                        <FaPlus title={props.mobileText} />
                      )}
                    </Link>
                  );
                }
                if (button.onclick) {
                  return (
                    <DivStyles
                      svgColor='#fff'
                      className='Mobile-Link'
                      key={index}
                      onClick={button.onclick}>
                      <TextStyles>{button.mobileText}</TextStyles>
                      <FaPlus title={props.mobileText} />
                    </DivStyles>
                  );
                }
                return null;
              })}
          </ButtonMobileStyles>
        )}
      </>
    );
  }
  return null;
});

export default Button;
