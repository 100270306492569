import React, { useState, useEffect, useRef } from 'react';
import { FaSearchengin } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';

import {
  locationFiltersObject,
  MeterGroupFilterObjects,
  systemUserFilterObjects,
  membershipFilterObjects,
  membersFilterObjects,
  filterByTime,
  statementFilterObjects,
  sessionFilterObjects,
  payoutFilterObjects,
} from './helpers';

import AppMissingData from './Modals/AppMissingData';
import AppSharedService from './AppSharedService';
import {
  FormStyles,
  DivStyles,
  SelectRoundStyles,
  InputRoundStyles,
  ButtonPlusStyles,
} from '../../SharedStyles';

const SearchFilterBar = (props) => {
  const strings = {
    ...AppMissingData.strings,
    ...props.strings,
    ...AppSharedService.strings,
  };
  const inputRef = useRef();
  const [searchedValue, setSearchedValue] = useState('');
  const [filteredValue, setFilteredValue] = useState(null);
  const [filterList, setFilterList] = useState([]);
  const [chargersSearch, setChargerSearch] = useState([
    {
      id: 1,
      name: '',
      value: '',
    },
  ]);

  useEffect(() => {
    //SystemUsers filters
    if (props.systemUserFilter) {
      if (filteredValue === 'pending') {
        setSearchedValue('true');
      } else if (filteredValue === 'nonPending') {
        setSearchedValue('false');
      } else if (filteredValue === 'expires') {
        setSearchedValue('Expired');
      } else {
        setSearchedValue('');
      }
      let rebuiltArray = props.filterBy.map((fil) => {
        return {
          filterString: fil,
          searchFilterString: systemUserFilterObjects[fil],
        };
      });
      setFilterList(rebuiltArray);
    }

    //MeterGroup filters
    if (props.meterGroupFilter) {
      if (filteredValue === 'enabled') {
        setSearchedValue('false');
      } else {
        setSearchedValue('');
      }
      let rebuiltArray = props.filterBy.map((fil) => {
        return {
          filterString: fil,
          searchFilterString: MeterGroupFilterObjects[fil],
        };
      });
      setFilterList(rebuiltArray);
    }

    //locations filters
    if (props.locationsFilter) {
      let rebuiltArray = props.filterBy.map((fil) => {
        return {
          filterString: fil,
          searchFilterString: locationFiltersObject[fil],
        };
      });
      setFilterList(rebuiltArray);
    }

    //membership filters
    if (props.membershipFilter) {
      let rebuiltArray = props.filterBy.map((fil) => {
        return {
          filterString: fil,
          searchFilterString: membershipFilterObjects[fil],
        };
      });
      setFilterList(rebuiltArray);
    }

    //members filters
    if (props.membersFilter) {
      if (filteredValue === 'paused') {
        setSearchedValue('true');
      } else if (filteredValue === 'approved') {
        setSearchedValue('false');
      } else if (filteredValue === 'expired') {
        setSearchedValue('true');
      } else if (filterByTime(filteredValue)) {
        let dateTime = filterByTime(filteredValue);
        setSearchedValue(dateTime);
      } else {
        setSearchedValue('');
      }
      let rebuiltArray = props.filterBy.map((fil) => {
        return {
          filterString: fil,
          searchFilterString: membersFilterObjects[fil],
        };
      });
      setFilterList(rebuiltArray);
    }

    //charger filters
    if (props.chargersFilter) {
      let rebuiltArray = props.filterBy.map((fil) => {
        return {
          filterString: fil,
          searchFilterString: fil,
        };
      });
      setFilterList(rebuiltArray);
    }

    //sessions filters
    if (props.sessionFilter) {
      let rebuiltArray = props.filterBy.map((fil) => {
        return {
          filterString: fil,
          searchFilterString: sessionFilterObjects[fil],
        };
      });
      setFilterList(rebuiltArray);
    }

    //statement filters
    if (props.statementFilter) {
      let rebuiltArray = props.filterBy.map((fil) => {
        return {
          filterString: fil,
          searchFilterString: statementFilterObjects[fil],
        };
      });
      setFilterList(rebuiltArray);
    }

    if (props.payoutReportFilter) {
      let rebuiltArray = props.filterBy.map((fil) => {
        return {
          filterString: fil,
          searchFilterString: payoutFilterObjects[fil],
        };
      });
      setFilterList(rebuiltArray);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredValue]);

  const handleFilterChange = (e) => {
    setSearchedValue('');
    setFilteredValue(e.target.value);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    props.handleSearchChange('');
    props.handleSearchSubmit(false);
  };

  const handleSearchChange = (e) => {
    setSearchedValue(e.target.value);
  };

  const handleChargerSearch = (e, cs) => {
    let currentChargerSearch = chargersSearch.map((c) => {
      if (c.id === cs.id) {
        if (e.target.name === 'name') {
          c.name = e.target.value;
          c.value = '';
        }
        if (e.target.name === 'value') {
          c.value = e.target.value;
        }
      }
      return c;
    });

    setChargerSearch(currentChargerSearch);
  };

  const removeInputFields = (cs) => {
    let currentChargerSearch = chargersSearch.filter((c) => {
      return c.id !== cs.id;
    });
    setChargerSearch(currentChargerSearch);
  };

  const addInputFields = () => {
    let input = {
      id: chargersSearch.length + 1,
      name: '',
      value: '',
    };
    setChargerSearch([...chargersSearch, input]);
  };

  const chargerSubmit = (e) => {
    e.preventDefault();
    props.history.push(`/chargers`);
    props.searchingChargers(chargersSearch);
  };

  const passUpSubmit = (e) => {
    e.preventDefault();
    if (filteredValue === null && searchedValue !== '') return;
    if (filteredValue === 'nonPending') {
      props.handleFilterChange('pending');
    } else {
      props.handleFilterChange(filteredValue);
    }
    props.handleSearchChange(searchedValue);
    props.handleSearchSubmit(true);
  };

  if (props.chargersFilter) {
    return (
      <FormStyles padding='10px' width='100%' onSubmit={chargerSubmit}>
        <DivStyles width='100%' maxHeight='150px' mobileMaxHeight='150px'>
          {chargersSearch.map((cs, index) => (
            <DivStyles
              display='flex'
              flexWrap='wrap'
              alignItems='center'
              margin='0 0 5px'
              key={index}>
              <SelectRoundStyles
                width='100px'
                size='14px'
                margin='0'
                name='name'
                value={cs.name || ''}
                onChange={(e) => handleChargerSearch(e, cs)}>
                <option value={''}>{strings.filterBy}</option>
                {filterList.map((option) => {
                  return (
                    <option
                      key={option.filterString}
                      value={option.searchFilterString}>
                      {option.filterString}
                    </option>
                  );
                })}
              </SelectRoundStyles>
              <InputRoundStyles
                mobileMargin='0 5px'
                flex='2'
                size='14px'
                margin='0 10px'
                onChange={(e) => handleChargerSearch(e, cs)}
                placeholder={strings.enterValue}
                name='value'
                value={cs.value}
              />
              <DivStyles>
                <DivStyles
                  color='#8f8d8d'
                  size='11px'
                  border='1px solid #e7e7e7'
                  borderRadius='15px'
                  padding='0 10px'
                  margin='0 0 3px'
                  onClick={addInputFields}>
                  and
                </DivStyles>
                {chargersSearch.length > 1 && (
                  <DivStyles
                    color='#8f8d8d'
                    size='11px'
                    border='1px solid #e7e7e7'
                    borderRadius='15px'
                    padding='0 10px'
                    onClick={() => removeInputFields(cs)}>
                    x
                  </DivStyles>
                )}
              </DivStyles>
            </DivStyles>
          ))}
        </DivStyles>
        <ButtonPlusStyles
          position='sticky'
          svgMargin='0 0 0 5px'
          color='#8497b6'
          size='30px'
          type='submit'>
          <FaSearchengin />
        </ButtonPlusStyles>
      </FormStyles>
    );
  }

  return (
    <FormStyles padding='10px' width='100%' onSubmit={passUpSubmit}>
      <DivStyles width='100%'>
        {chargersSearch.map((cs, index) => (
          <DivStyles
            display='flex'
            flexWrap='wrap'
            alignItems='center'
            margin='0 0 5px'
            key={index}>
            <SelectRoundStyles
              width='100px'
              size='14px'
              margin='0'
              onChange={handleFilterChange}>
              <option value=''>{strings.filterBy}</option>
              {filterList.map((option) => {
                return (
                  <option
                    key={option.filterString}
                    value={option.searchFilterString}>
                    {option.filterString}
                  </option>
                );
              })}
            </SelectRoundStyles>
            <InputRoundStyles
              mobileMargin='0 5px'
              flex='2'
              size='14px'
              margin='0 10px'
              onChange={handleSearchChange}
              ref={inputRef}
              placeholder={
                (filteredValue &&
                  filteredValue.toLowerCase().includes('date')) ||
                (filteredValue &&
                  filteredValue.toLowerCase().includes('period')) ||
                (filteredValue && filteredValue.toLowerCase().includes('since'))
                  ? `ex. 10/01/${new Date().getFullYear()}`
                  : strings.enterValue
              }
            />
          </DivStyles>
        ))}
      </DivStyles>
      <ButtonPlusStyles
        position='sticky'
        svgMargin='0 0 0 5px'
        color='#8497b6'
        size='30px'
        type='submit'>
        <FaSearchengin />
      </ButtonPlusStyles>
    </FormStyles>
  );
};

export default withRouter(SearchFilterBar);
