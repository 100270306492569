import React, {useContext} from 'react';
import moment from 'moment';

import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppFindChargerService from '../../AppFindChargerService';
import AppChargingService from '../../../../driver/AppCharging/AppChargingService';

import { ErrorContext } from '../../../../context/shared/ErrorContext';

//global component
import ModalButton from '../../../../shared/Buttons/ModalButton';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  ModalNoteStyles,
} from '../../../../SharedStyles';
import { DivStyles } from '../../../../SharedStyles';

const DriverMessageModal = (props) => {
  const { strings } = AppFindChargerService;
  const message = props.lastMessage;
  const { setError } = useContext(ErrorContext);

  const closeModal = async () => {
    try {
      await AppChargingService.setAsReadDriverMessage(message.id);
      props.closeModal();
      props.setAsRead();
    } catch (err) {
      setError({
        display: true,
        title: 'Something went wrong',
        message: err.response.data.split(/[|]+/).pop(),
        styles: 'driver',
      });
      props.closeModal();
    }
    };

  return (
    <ModalStyles>
    <ModalContainerStyles>
      {/* Header Bar */}
      <ModalHeaderStyles>
        <h2 className='Title'>{strings.driverMessagesTitle}</h2>
        <FaRegWindowClose className='Close' onClick={closeModal} />
      </ModalHeaderStyles>
      {/* Content Section */}
      <ModalContentStyles>
        {/* Section */}
        <ModalItemStyles padding='0 10px'>
          <p>
          {moment(message.timeStamp).format('MM/DD h:mm:ss a')} 
          </p>
        </ModalItemStyles>
        <ModalNoteStyles
                      color='#333333'
                      padding='10px 20px 0'
                      align='left'>
          <p>{message.message}</p>
          </ModalNoteStyles>
      </ModalContentStyles>
      {/* Button Component for Modals*/}
      <DivStyles
        display='flex'
        alignItems='center'
        justifyContent='flex-end'>
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonMargin='0'
          handleClick={closeModal}
          buttonText={strings.ok}
        />
      </DivStyles>
    </ModalContainerStyles>
  </ModalStyles>
  );
};

export default DriverMessageModal;
