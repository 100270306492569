import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { FaRegEdit } from 'react-icons/fa';

//services
import AppSystemUsersService from '../AppSystemUsersService';
import AppAuthService from '../../../../services/AppAuth/AppAuthService';
import AppStorageService from '../../../../services/AppStorage/AppStorageService';

//components
import ResetForm from '../../../driver/AccountSettings/Forms/ResetForm';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import {
  DivStyles,
  ImageThumbnailStyles,
  TextStyles,
  TitleStyles,
  ImageContainerFitStyles,
} from '../../../SharedStyles';

const UserHeaderDisplay = (props) => {
  const { strings } = AppSystemUsersService;
  const { getTokenContents } = AppAuthService;
  const { currentUser } = useContext(CsoAccountContext);
  const { systemUser, toggleImageModal } = props;
  const [currentUserSigninEmail, setCurrentUserSigninEmail] = useState('');

  useEffect(() => {
    const getTokenInfo = async () => {
      let value = await AppStorageService.getValue(
        AppStorageService.values.authToken
      );

      let tokenEmail = await getTokenContents(value).emails;

      setCurrentUserSigninEmail(tokenEmail);
    };
    getTokenInfo();
  }, [getTokenContents]);

  if (!systemUser.pending) {
    return (
      <DivStyles display='flex' padding='10px' alignItems='center'>
        <ImageContainerFitStyles width='55px' height='55px'>
          <ImageThumbnailStyles
            borderRadius='50%'
            border='1px solid #e7e7e7'
            width='100%'
            objectFit='cover'
            src={
              systemUser.imagePath
                ? systemUser.imagePath
                : require('../../../../assets/images/UsersPlaceholder.png').default
            }
            alt={systemUser.firstName}
          />
        </ImageContainerFitStyles>
        <DivStyles margin='0 0 0 10px'>
          <TitleStyles size='14px' weight='700' margin='0'>
            {systemUser.firstName} {systemUser.lastName}{' '}
            <span className='Edit-Icon'>
              <FaRegEdit onClick={toggleImageModal} />
            </span>
          </TitleStyles>
          {systemUser.id === currentUser.id && (
            <DivStyles>
              <TextStyles color='grey' size='12px'>
                {strings.signInEmail}: {currentUserSigninEmail}
              </TextStyles>
              <ResetForm cso strings={strings} />
            </DivStyles>
          )}
        </DivStyles>
      </DivStyles>
    );
  }
  return (
    <>
      <DivStyles display='block' padding='10px'>
        <TitleStyles size='14px' weight='400' margin='0'>
          <strong>{strings.pendingUser}:</strong>{' '}
          {systemUser.token ? systemUser.token : systemUser.expires}
        </TitleStyles>
        {systemUser.token && systemUser.expires !== 'Expired' && (
          <TitleStyles size='14px' weight='400' margin='10px 0 0'>
            <strong>Token Expires:</strong>{' '}
            {moment(systemUser.expires)
              .utc(systemUser.expires)
              .local()
              .format('MM/DD/YYYY hh:mm a')}
          </TitleStyles>
        )}
      </DivStyles>
    </>
  );
};

export default UserHeaderDisplay;
