import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaRegEdit, FaRegTrashAlt, FaEllipsisV } from 'react-icons/fa';

//services
import AppPricingSchedulesService from '../AppPricingSchedulesService';

//global component
import { RoleCanEdit } from '../../OperatorShared';

//modals
import DeleteModal from '../PricingSchedulesModals/DeleteModal';

//hooks
import useOnClickOutside from '../../../shared/hooks/useOnClickOutside';
import { useWindowResize } from '../../../shared/hooks/useWindowSize';
import { useContainerHeight } from '../../../shared/hooks/useContainerHeight';

//styles
import { TextStyles, TitleStyles, DivStyles } from '../../../SharedStyles';
import { DataCardStyles, ToolsStyles } from '../../../OperatorStyles';

const PricingSchedulesCard = (props) => {
  const { strings } = AppPricingSchedulesService;
  const { profile } = props;
  const [deleteModal, setDeleteModal] = useState(false);
  const CardRef = useRef();
  const OutsideRef = useRef();
  const size = useWindowResize(window.innerWidth, window.innerHeight);
  const { containerHeight, containerRef, setContainerRef } = useContainerHeight(
    50,
    CardRef
  );
  const [openTools, setOpenTools] = useState(false);

  //gets card container height for responsive
  useEffect(() => {
    setContainerRef(CardRef);
    // eslint-disable-next-line
  }, [CardRef]);

  useOnClickOutside(OutsideRef, () => setOpenTools(false));

  const toggleToolBar = () => {
    setOpenTools(!openTools);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const deleteProfile = () => {
    props.deleteProfile(profile.id);
  };

  console.log(profile);

  return (
    <>
      {deleteModal && (
        <DeleteModal
          handleClose={toggleDeleteModal}
          deleteProfile={deleteProfile}
          profile={profile}
        />
      )}
      <DataCardStyles
        ref={containerRef}
        onMouseEnter={() => {
          size[0] >= 768 && setOpenTools(true);
        }}
        onMouseLeave={() => {
          size[0] >= 768 && setOpenTools(false);
        }}>
        <Link to={`/subscription/pricing-schedules/${profile.id}`}>
          <DivStyles>
            <TitleStyles size='14px' weight='600' margin='0'>
              {profile.name}
            </TitleStyles>
            <TextStyles
              size='12px'
              color='#617793'
              weight='500'
              margin='2px 0 0'>
              {profile.pricingScheduleDescription}
            </TextStyles>
          </DivStyles>
        </Link>
        <FaEllipsisV
          className='Card-Tools-Toggle'
          style={size[0] >= 768 ? { display: 'none' } : { display: 'block' }}
          onClick={toggleToolBar}
        />
        <ToolsStyles
          ref={OutsideRef}
          displayTools={openTools}
          containerHeight={(containerHeight - 35) / 2}>
          <Link
            to={`/subscription/pricing-schedules/${profile.id}`}
            title={strings.editTitle}
            className='Tool-Item'>
            <FaRegEdit />
          </Link>
          <RoleCanEdit roles={[1, 2]}>
            <div className='Tool-Item' onClick={toggleDeleteModal}>
              <FaRegTrashAlt title={strings.deleteTitle} />
            </div>
          </RoleCanEdit>
        </ToolsStyles>
      </DataCardStyles>
    </>
  );
};

export default PricingSchedulesCard;
