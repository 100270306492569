import React, { createContext, useState } from 'react';

export const ChargerContext = createContext();

const ChargerStation = props => {
  const [charger, setCharger] = useState({});
  const [loading, setLoading] = useState(true);
  const [searched, setSearched] = useState('');

  return (
    <ChargerContext.Provider
      value={{
        charger,
        loading,
        setLoading,
        setCharger,
        searched,
        setSearched
      }}>
      {props.children}
    </ChargerContext.Provider>
  );
};

export default ChargerStation;
