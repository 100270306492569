import styled from 'styled-components';

export const DataCardListStyles = styled.div`
  min-height: 500px;

  @media screen and (max-width: ${(props) => props.mediaMaxWidth}) {
    min-height: auto;
  }
`;

export const DataCardStyles = styled.div`
  padding: 10px;
  text-align: left;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  &:nth-child(even) {
    background: #f7f7f7;
  }
  &:hover {
    background: #f1f7ff;
  }

  a {
    color: #8f8d8d;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    &:hover {
      text-decoration: none;
    }
    .Card-Left {
      font-size: 12px;
      align-items: flex-start;
      flex: 2 0;
      .Card-Content {
        color: #2c456c;
        .Card-Content-Title {
          font-size: 14px;
          font-weight: 700;
        }
        .Card-Content-Subtitle {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
    .Card-Right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .Card-Data {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        .Card-Data-Item {
          border-left: 1px solid #000;
          line-height: 12px;
          padding: 0 10px;
          font-size: 12px;
          font-weight: 400;
          &:first-child {
            border: none;
          }
        }
      }
    }
  }
  .Card-Tools-Toggle {
    color: #2c456c;
    height: 20px;
    cursor: pointer;
  }
`;

export const CommentCardStyles = styled.div`
  position: relative;
  color: ${({ color }) => color};
  background: ${({ background }) => background};
  height: ${({ height }) => height};
  font-size: ${({ size }) => size};
  max-height: ${({ maxHeight }) => maxHeight};
  overflow-y: ${({ maxHeight }) => maxHeight && 'scroll'};
  min-height: ${({ minHeight }) => minHeight};
  display: ${({ display }) => display};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  align-items: ${({ alignItems }) => alignItems};
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
  width: ${({ width }) => width};
  flex: ${({ flex }) => flex};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  font-weight: ${({ weight }) => weight};
  border-right: ${({ borderRight }) => borderRight};
  border-left: ${({ borderLeft }) => borderLeft};
  border-top: ${({ borderTop }) => (borderTop ? '1px solid #e7e7e7' : 'none')};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? '1px solid #e7e7e7' : 'none'};
  text-align: ${({ align }) => align};
  &:nth-child(even) {
    background: ${({ background }) => (background ? background : '#f7f7f7')};
  }
  &:hover {
    background: ${({ hoverBackground }) => hoverBackground};
  }
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    flex-wrap: ${({ wrapMobile }) => wrapMobile && 'wrap'};
    flex: ${({ wrapMobile }) => wrapMobile && '100%'};
    margin: ${({ wrapMargin }) => wrapMargin};
    min-height: auto;
    max-height: none;
  }
  .Comment-Actions {
    display: none;
  }
`;
