import styled from 'styled-components';

// removedHeight
export const StatementTableStyle = styled.div`
  font-size: 12px;
  /* max-height: ${props => props.maxHeight}; */
  min-height: ${props => props.minHeight};
  width: 100%;
  overflow: scroll;

  .Statement-Table-Container {
    width: ${props => props.width};
    .Statement-Table-Header {
      position: sticky;
      position: -webkit-sticky;
      top: 0px;
      z-index: 1;
      border-bottom: 1px solid #e7e7e7;
      background-color: #fcfcfc;
      color: #28456a;
      font-weight: 500;
      text-align: center;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding: 10px 0;
      width: 100%;
      .Table-Item {
        flex: 1 1;
        padding: 0 5px;
        width: 60px;
        border-right: 1px solid #000;
        line-height: 15px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        &:last-child {
          border: none;
        }
      }
    }
    .Statement-Table-Rows {
      display: flex;
      color: #8f8d8d;
      flex-wrap: nowrap;
      justify-content: flex-start;
      padding: 10px 0;
      min-width: 700px;
      align-items: center;
      width: 100%;
      .Table-Item {
        flex: 1 1;
        padding: 0 5px;
        width: 60px;
        line-height: 15px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        &:last-child {
          border: none;
        }
      }
    }
  }
`;
