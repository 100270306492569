import React, { useState, useContext } from 'react';

//layout
import {
  ComponentWrapper,
  OuterWrapper,
  // Button,
} from '../OperatorShared';
import Button from '../../shared/Buttons/Button';

//services
import AppAccountProfileService from './AppAccountProfileService';

//global components
import LoadingSpinner from '../../shared/Loading/LoadingSpinner';
import SmallBizAdmin from '../SmallBizAdmin';
import RoleCanEdit from '../OperatorShared/RoleCanEdit';

//components
import AccountHeader from '../OperatorShared/AccountHeader';
import AccountModal from './AccountModals/AccountModal';
import AccountForm from './AccountForm/AccountForm';
import CloseAccountModal from './AccountModals/CloseAccountModal';

//styles  - Needs to be converted to styled components
import './AccountProfile.scss';

//context
import { CsoAccountContext } from '../../context/operator/getCsoAccount';

const AccountProfile = (props) => {
  const { strings } = AppAccountProfileService;
  const { account, updatingAccount, setAccount } = useContext(
    CsoAccountContext
  );
  const [showEditModal, setShowEditModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setAccount({ ...account, [e.target.name]: e.target.value });
  };

  const showEditTitleModal = () => {
    setShowEditModal(!showEditModal);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let res = await updatingAccount(account);

    if (res) {
      setLoading(false);
    }
  };

  // internal allows for menu, myForm allows form to submit vs button
  return (
    <OuterWrapper
      title={strings.pageTitle}
      internal
      myForm
      path='/'
      roles={[1, 2]}>
      {loading && <LoadingSpinner />}
      <SmallBizAdmin>
        <ComponentWrapper title={strings.pageTitle}>
          {showEditModal && (
            <AccountModal
              input
              account={account}
              updatingAccount={setAccount}
              showEditTitleModal={showEditTitleModal}
              strings={strings}
            />
          )}
          <form id='myForm' onSubmit={handleSubmit}>
            <AccountHeader
              showEditTitleModal={showEditTitleModal}
              strings={strings}
            />
            <AccountForm account={account} handleChange={handleChange} />
          </form>
        </ComponentWrapper>
        <Button
          cso
          hasMobile
          buttonText={strings.save}
          formSubmit
          roles={[1, 2, 3, 4]}
        />
        <RoleCanEdit roles={[1]}>
          <CloseAccountModal 
            strings={strings} 
            account={account} 
          />
        </RoleCanEdit>
      </SmallBizAdmin>
    </OuterWrapper>
  );
};

export default AccountProfile;
