import React, { useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useSwipeable } from 'react-swipeable';

//components
import SlideItem from './SlideItem';

//styles
import { SectionImageGallery } from '../../../SharedStyles';

const Swipeable = ({children, ...props}) => {
  const handlers = useSwipeable(props);
  return (<div { ...handlers }>{children}</div>);
}

const SliderComponent = (props) => {
  const [index, setIndex] = useState(0);
  const [forward, setForward] = useState(true);

  const nextSlide = (ev) => {
    setForward(true);
    setIndex((index + 1) % props.images.length);
  };

  const prevSlide = (ev) => {
    setForward(false);
    setIndex((index || props.images.length) - 1);
  };

  const addEndListener = (node, done) =>
    node.addEventListener('transitionend', done, false);

  return (
    <Swipeable onSwipedLeft={nextSlide} onSwipedRight={prevSlide}>
      <SectionImageGallery className={forward ? 'forwards' : 'backwards'}>
        <div
          className='next'
          onClick={nextSlide}
          style={
            index === props.images.length - 1
              ? { display: 'none' }
              : { display: 'block' }
          }
        />
        <div
          className='prev'
          onClick={prevSlide}
          style={index === 0 ? { display: 'none' } : { display: 'block' }}
        />
        <TransitionGroup className='Slides'>
          <CSSTransition
            key={index}
            classNames='fade'
            addEndListener={addEndListener}>
            <SlideItem
              prevSlide={prevSlide}
              toggleFavorites={props.toggleFavorites}
              charger={props.charger}
              image={props.images[index]}
            />
          </CSSTransition>
        </TransitionGroup>
      </SectionImageGallery>
      <div className='indicator-container'>
        {props.images.map((image, i) => {
          return (
            <div
              key={i}
              onClick={() => setIndex(i)}
              className={
                index === i ? 'indicator-dot indicator-active' : 'indicator-dot'
              }
            />
          );
        })}
      </div>
    </Swipeable>
  );
};

export default SliderComponent;
