import React, { useContext, useEffect, useCallback } from 'react';
import { FaRegWindowClose, FaCamera } from 'react-icons/fa';

//services
import AppChargerDetailsService from '../AppChargerDetailsService';
import AppImagingService from '../../../../services/AppImaging/AppImagingService';

//global components
import { LoadingSpinner } from '../../../shared';

//hooks
import { useCloudinary } from '../../../shared/Cloudinary/hook/useCloudinary';

//context
import { ErrorContext } from '../../../context/shared/ErrorContext';

//styles
import {
  ButtonDivGreyStyles,
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  ModalNoteStyles,
} from '../../../SharedStyles';

//helpers
import { uuid } from '../../../shared/Helpers';

const ImageModal = (props) => {
  const { strings } = AppChargerDetailsService;
  const { charger, successUpload } = props;
  const { setError } = useContext(ErrorContext);

  const { cloudData, uploadImage, imageLoading } = useCloudinary({
    preset: 'charger_images',
    tags: [props.charger.iotHubDeviceId],
  });

  const closeModal = () => {
    props.handleModalClose();
  };

  const postImage = useCallback(async () => {
    let updatedCharger = { ...charger };
    let newImage = {
      approved: false,
      imagePath: cloudData.secure_url,
      id: uuid(),
    };
    updatedCharger.images.push(newImage);
    await AppChargerDetailsService.updateCSOChargerSlider(updatedCharger);
    successUpload();
  }, [charger, cloudData, successUpload]);

  const onCameraSuccess = async (imageData) => {
    let file = 'data:image/jpeg;base64,' + imageData;
    uploadImage(file);
  };

  const onCameraError = () => {
    setError({
      display: true,
      message: 'Problem with camera.',
      title: 'Problem',
      styles: 'driver',
    });
  };

  const takePicture = () => {
    AppImagingService.getFromCamera(onCameraSuccess, onCameraError);
  };

  useEffect(() => {
    if (cloudData) {
      postImage();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloudData]);

  const openDialog = () => {
    document.getElementById('inputUpload').click();
  };

  return (
    <ModalStyles>
      {imageLoading && <LoadingSpinner />}
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.photoTitle}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <p>{strings.photoContent}</p>
          </ModalItemStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <p>
              <strong>{strings.photoHint}</strong> {strings.photoHintContent}
            </p>
          </ModalItemStyles>
        </ModalContentStyles>
        <ModalItemStyles padding='0 10px'>
          <ButtonDivGreyStyles onClick={takePicture}>
            <FaCamera />
            {strings.photoTake}
          </ButtonDivGreyStyles>
        </ModalItemStyles>
        <input
          id='inputUpload'
          type='file'
          hidden
          accept='image/*'
          onChange={uploadImage}
        />
        <ModalNoteStyles>{strings.or}</ModalNoteStyles>
        <ButtonDivGreyStyles onClick={openDialog}>
          {strings.photoBrowse}
        </ButtonDivGreyStyles>
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default ImageModal;
