import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { FaRegTrashAlt, FaRegCheckSquare, FaEllipsisV } from 'react-icons/fa';

//images
import DefaultImage from '../../../../../assets/images/MyVehiclePlaceholder300x150.png';

//hooks
import useOnClickOutside from '../../../../shared/hooks/useOnClickOutside';
import { useWindowResize } from '../../../../shared/hooks/useWindowSize';
import { useContainerHeight } from '../../../../shared/hooks/useContainerHeight';

//styles
import {
  TitleStyles,
  DivStyles,
  ImageThumbnailStyles,
  TextStyles,
} from '../../../../SharedStyles';
import { CommentCardStyles, ToolsStyles } from '../../../../OperatorStyles';

const ChargerCommentCard = (props) => {
  const { comment } = props;

  const CardRef = useRef();
  const OutsideRef = useRef();
  const size = useWindowResize(window.innerWidth, window.innerHeight);
  const { containerHeight, setContainerRef } = useContainerHeight(110, CardRef);

  //gets card container height for responsive
  useEffect(() => {
    setContainerRef(CardRef);
  }, [CardRef, setContainerRef]);

  const [openTools, setOpenTools] = useState(false);

  useOnClickOutside(OutsideRef, () => setOpenTools(false));

  const toggleToolBar = () => {
    setOpenTools(!openTools);
  };

  let commentData = {
    iotHubDeviceId: props.iot,
    commentId: comment.id,
  };

  return (
    <CommentCardStyles
      display='flex'
      hoverBackground='#f1f7ff'
      background={!comment.approved && 'rgba(255, 214, 0, 0.2)'}
      ref={CardRef}
      onMouseEnter={() => {
        size[0] >= 768 && setOpenTools(true);
      }}
      onMouseLeave={() => {
        size[0] >= 768 && setOpenTools(false);
      }}>
      <DivStyles display='block' padding='5px' maxWidth='140px'>
        <TitleStyles size='11px' align='center' margin='0 0 3px'>
          {comment.vehicleName}
        </TitleStyles>
        <ImageThumbnailStyles
          maxWidth='130px'
          src={comment.url ? comment.url : DefaultImage}
          alt={comment.vehicleName}
        />
        <TitleStyles size='11px' align='center' margin='0 0 3px'>
          {comment.battery}kWh
        </TitleStyles>
      </DivStyles>
      <DivStyles flex='1'>
        <DivStyles
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          flex='1'
          margin='5px 10px'>
          <TextStyles color='#a5a5a5' size='14px'>
            {comment.driverName}
          </TextStyles>
          {/* <DivStyles display='flex'>
            <DivStyles className='Comment-Actions' svgMargin='0 15px'>
              {!comment.approved && (
                <FaRegCheckSquare
                  onClick={() => props.approveComment(commentData)}
                />
              )}
            </DivStyles>
            <DivStyles className='Comment-Actions' svgMargin='0 10px 0 0'>
              <FaRegTrashAlt onClick={() => props.deleteComment(commentData)} />
            </DivStyles>
          </DivStyles> */}
          <DivStyles className='Comment-Date' margin='0 10px 0 0'>
            <TextStyles color='#a5a5a5' size='14px'>
              {moment(comment.commentDate).format('MMM DD, YYYY')}
            </TextStyles>
          </DivStyles>
        </DivStyles>
        <DivStyles margin='0 5px 0 10px'>
          <TextStyles size='14px'>{comment.comment}</TextStyles>
        </DivStyles>
      </DivStyles>
      <FaEllipsisV
        className='Card-Tools-Toggle'
        style={
          size[0] >= 768
            ? { display: 'none' }
            : {
                display: 'block',
                alignSelf: 'center',
              }
        }
        onClick={toggleToolBar}
      />
      <ToolsStyles
        style={{ alignSelf: 'center' }}
        ref={OutsideRef}
        displayTools={openTools}
        containerHeight={(containerHeight - 35) / 2}>
        <DivStyles className='Tool-Item' svgMargin='0 15px'>
          {!comment.approved && (
            <FaRegCheckSquare
              onClick={() => props.approveComment(commentData)}
            />
          )}
        </DivStyles>
        <DivStyles className='Tool-Item' svgMargin='0 10px 0 0'>
          <FaRegTrashAlt onClick={() => props.deleteComment(commentData)} />
        </DivStyles>
      </ToolsStyles>
    </CommentCardStyles>
  );
};

export default ChargerCommentCard;
