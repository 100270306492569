import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppSharedModalService from './AppSharedModalService';

//global component
import ModalButton from '../../shared/Buttons/ModalButton';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
} from '../../SharedStyles';
import { DivStyles } from '../../SharedStyles';

const LogoutModal = (props) => {
  const { strings } = AppSharedModalService;

  const closeModal = () => {
    props.handleCloseModal();
  };

  const handleLogout = () => {
    props.handleLogout();
    props.handleCloseModal();
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.logoutConfirm}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <p>{strings.logoutContent}</p>
          </ModalItemStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <DivStyles display='flex' alignItems='center' justifyContent='center'>
          <ModalButton
            containerMargin='10px 10px 0'
            containerAlign='right'
            buttonMargin='0'
            inverse
            handleClick={closeModal}
            buttonText={strings.no}
          />
          <ModalButton
            containerMargin='10px 10px 0'
            containerAlign='right'
            buttonMargin='0'
            handleClick={handleLogout}
            buttonText={strings.yes}
          />
        </DivStyles>
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default LogoutModal;
