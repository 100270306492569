import React, { useContext } from 'react';

//layout
import {
  ComponentWrapper,
  SubscriptionAccess,
  OuterWrapper,
  RoleCanEdit,
} from '../OperatorShared';

//services
import AppReportsService from './AppReportsService';

//components
import ReportsList from './Details/Shared/ReportsList';

//context
import { CsoAccountContext } from '../../context/operator/getCsoAccount';
import { DivStyles } from '../../SharedStyles';

//helpers
import {
  sessionReportsData,
  energyReportsData,
  incomeReportsData,
  customIncentiveReportsData,
  // miscReportsData,
  // systemReportsData,
} from './index';

const Reports = () => {
  const { strings } = AppReportsService;
  const { account } = useContext(CsoAccountContext);

  return (
    <OuterWrapper maxWidth='1200px' title={strings.pageTitle}>
      <ComponentWrapper
        normalCase
        dashboardTitle={strings.pageTitle}
        dashboardSubTitle={`- ${account.csoAccountName}`}>
        {/* Charging Session and Energy Reports + Revenue*/}
        <DivStyles display='flex' padding='10px' flexWrap='wrap'>
          <SubscriptionAccess levels={[1, 2, 3]}>
            <RoleCanEdit roles={[1, 2]}>
              <ReportsList
                margin='0 10px 0 5px'
                reportsData={sessionReportsData}
                title={strings.chargingSessionReports}
              />
            </RoleCanEdit>
          </SubscriptionAccess>
          <SubscriptionAccess levels={[3]}>
            <ReportsList
              margin='0 5px 0 10px'
              reportsData={energyReportsData}
              title={strings.energyReports}
            />
          </SubscriptionAccess>
        </DivStyles>
        {/* Income and Misc Reports */}
        <DivStyles display='flex' padding='10px' flexWrap='wrap'>
          <SubscriptionAccess levels={[1, 2, 3]}>
            <RoleCanEdit roles={[0, 1]}>
              <ReportsList
                margin='0 10px 0 5px'
                reportsData={incomeReportsData}
                title={strings.incomeReports}
              />
            </RoleCanEdit>
          </SubscriptionAccess>
          <ReportsList
            margin='0 5px 0 10px'
            reportsData={customIncentiveReportsData}
            title={strings.customIncentiveReports}
          />

          {/* <ReportsList
          margin='0 5px 0 10px'
          reportsData={miscReportsData}
          title={strings.miscReports}
        /> */}
        </DivStyles>
        {/* System Reports */}
        {/* <DivStyles
        display='flex'
        padding='10px'
        margin='0 0 10px'
        flexWrap='wrap'>
        <ReportsList
          margin='0 10px 0 5px'
          reportsData={systemReportsData}
          title={strings.systemReports}
        />
        <ReportsList margin='0 5px 0 10px' empty />
      </DivStyles> */}
      </ComponentWrapper>
    </OuterWrapper>
  );
};

export default Reports;
