import React, { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';

//services
import AppPlatformService from '../../../../services/AppPlatform/AppPlatformService';

//components
import Slideshow from '../Slideshow/Slideshow';

//modals
import ImageModal from '../modals/ImageModal';
import BrowserCameraModal from '../modals/BrowserCameraModal';
import ApprovalModal from '../modals/ApprovalModal';

//images
import chargerPlaceholderImage from '../../../../assets/images/ChargingStationPlaceholder400.png';

const images = [
  {
    id: 123,
    imagePath: chargerPlaceholderImage,
    approved: true,
    default: true,
    placeholder: true,
  },
];

const ChargerImage = (props) => {
  const [imageModal, setImageModal] = useState(false);
  const [browserCamera, setBrowerCamera] = useState(false);
  const [approvalModal, setApprovalModal] = useState(false);
  const [imagesCollection, setImagesCollection] = useState([]);

  useEffect(() => {
    if (props.charger.images.length > 0) {
      let imageSlider = props.charger.images
        .map((img) => {
          img.placeholder = false;
          if (img.imagePath === props.charger.imagePath) {
            img.default = true;
          } else {
            img.default = false;
          }
          return img;
        })
        .filter((img) => img.approved)
        .sort((a, b) => b.default - a.default);

      if (imageSlider.length === 0) {
        setImagesCollection(images);
      } else {
        setImagesCollection(imageSlider);
      }
    } else {
      setImagesCollection(images);
    }
  }, [props.charger]);

  const launchCamera = () => {
    if (AppPlatformService.isWebApp()) {
      setBrowerCamera(true);
    } else {
      setImageModal(true);
    }
  };

  const successUpload = () => {
    setApprovalModal(true);
  };

  useEffect(() => {
    if (approvalModal) {
      closeModal();
    }
  }, [approvalModal]);

  const closeModal = (approval) => {
    setImageModal(false);
    setBrowerCamera(false);
    if (approval) {
      setApprovalModal(false);
    }
  };

  return (
    <div className='Header-image'>
      {imageModal && (
        <ImageModal
          charger={props.charger}
          handleModalClose={closeModal}
          successUpload={successUpload}
        />
      )}
      {browserCamera && (
        <BrowserCameraModal
          charger={props.charger}
          handleModalClose={closeModal}
          successUpload={successUpload}
        />
      )}
      {approvalModal && (
        <ApprovalModal
          fetchChargingStations={props.fetchChargingStations}
          handleModalClose={closeModal}
        />
      )}
      <Slideshow
        charger={props.charger}
        images={imagesCollection}
        toggleFavorites={props.toggleFavorites}
      />
      <div className='add-photo'>
        <FaPlus onClick={launchCamera} />
      </div>
    </div>
  );
};

export default ChargerImage;
