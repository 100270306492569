import React, { useEffect, useState, useContext } from 'react';
import { FaRegWindowClose, FaExclamationTriangle } from 'react-icons/fa';

//services
import AppChargersModalService from '../../../ChargersModals/AppChargersModalService';

//context
import { CsoChargerContext } from '../../../../../context/operator/getCsoChargers';

//global components
import ModalButton from '../../../../../shared/Buttons/ModalButton';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  DivStyles,
  ModalNoteStyles,
  SelectStyles,
} from '../../../../../SharedStyles';

//helpers
import { phaseMGTypeConvertor } from '../../../../helpers';

const MeterGroupModal = (props) => {
  const { strings } = AppChargersModalService;
  const { allChargers } = useContext(CsoChargerContext);
  const [location] = useState(props.location);
  const [meterGroup, setMeterGroup] = useState(props.meterGroup);
  const [allMeterGroups, setAllMeterGroups] = useState(
    props.location.meterGroups
  );
  const [totalPorts, setTotalPorts] = useState(0);
  const [totalAmps, setTotalAmps] = useState(0);
  const [displayWarning, setDisplayWarning] = useState({
    phase: false,
    amp: false,
  });

  useEffect(() => {
    const fetchCharger = async () => {
      setAllMeterGroups(props.location.meterGroups);
      let total = 0;
      props.meterGroup.circuits.forEach((cir) => {
        total += cir.chargingStationConnectorIds.reduce((a, b) => a + b?.connectorIds?.length, 0);
      });
      setTotalPorts(total);
    };
    if (Object.keys(props.meterGroup).length > 0) {
      fetchCharger();
    }
  }, [props.location, props.meterGroup]);

  useEffect(() => {
    //figure out the combine amps of all charging stations in this meter group
    let meterGroupChargers = allChargers.filter((charger) => {
      return charger.meterGroupId === meterGroup.meterGroupId;
    });
    let totalAmps = meterGroupChargers.reduce((prev, next) => {
      return prev + next.maxCurrent;
    }, 0);
    setTotalAmps(totalAmps);
    //calculate total chargers in circuits
    if (Object.keys(meterGroup).length > 0) {
      let total = 0;
      meterGroup.circuits.forEach((cir) => {
        if (cir.chargingStationIds.length > 0) {
          total += cir.chargingStationConnectorIds.reduce((a, b) => a + b?.connectorIds?.length, 0);
        }
      });
      setTotalPorts(total);
    }
  }, [meterGroup, allChargers]);

  const closeModal = () => {
    props.handleModalClose();
  };

  const handleChange = (e) => {
    setDisplayWarning({ phase: false, amp: false });
    if (e.target.value !== '') {
      let updatedMG = location.meterGroups.filter((meter) => {
        return meter.meterGroupId === e.target.value;
      });

      setMeterGroup(updatedMG[0]);
    } else {
      setMeterGroup('');
      setTotalAmps(0);
      setTotalPorts(0);
    }
  };

  const handleUpdateMG = () => {
    if (!props.charger.phaseType.includes(meterGroup.phaseType)) {
      setMeterGroup({});
      setDisplayWarning({
        ...displayWarning,
        phase: true,
      });
    } else {
      props.updateMeterGroup(meterGroup.meterGroupId);
      props.handleDataChange('csoMeterGroupId', meterGroup.meterGroupId);
      props.updateCircuit();
      props.updateConnectorCircuits();
      closeModal();
    }
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.meterGroupTitle}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <p>{strings.meterGroupContent}</p>
          </ModalItemStyles>
          {/* Section */}
          <ModalItemStyles>
            <SelectStyles
              value={meterGroup.meterGroupId || ''}
              name='meterGroup'
              onChange={handleChange}>
              <option value=''>{strings.unassigned}</option>
              {allMeterGroups.map((group) => (
                <option key={group.meterGroupId} value={group.meterGroupId}>
                  {group.name}
                </option>
              ))}
            </SelectStyles>
          </ModalItemStyles>
          {/* Section */}
          <ModalItemStyles pSize='12px' textMargin='0 0 5px' padding='0 5px'>
            <DivStyles display='flex' alignItems='center' align='left'>
              <DivStyles weight='600' align='left' flex='1'>
                <p>{strings.meterGroupName}</p>
                <p>{strings.meterGroupLocation}</p>
                {meterGroup.lotId && <p>{strings.meterGroupLot}</p>}
                <p>{strings.meterGroupPhaseType}</p>
                <p>{strings.meterGroupVoltage}</p>
                <p>{strings.meterGroupMaxAmps}</p>
                <p>{strings.meterGroupCircuits}</p>
                <p>{strings.meterGroupPower}</p>
                <p>{strings.meterGroupPorts}</p>
                <p>{strings.meterGroupCombined}</p>
              </DivStyles>
              <DivStyles align='left' flex='1' color='#aaa6a6'>
                <p>{meterGroup.name}</p>
                <p>{location.name}</p>
                {meterGroup.lotId && <p>{meterGroup.lotId}</p>}
                <p>{phaseMGTypeConvertor[meterGroup.phaseType]}</p>
                <p>{meterGroup.voltage}</p>
                <p>{meterGroup.maxAmps}</p>
                <p>{meterGroup.circuits && meterGroup.circuits.length}</p>
                <p>{meterGroup.enablePowerManagementPolicy ? 'Yes' : 'No'}</p>
                <p>{totalPorts} </p>
                <p>{totalAmps} </p>
              </DivStyles>
            </DivStyles>
          </ModalItemStyles>

          <ModalNoteStyles>
            {displayWarning.amp && (
              <div className='Warning-modal'>
                <FaExclamationTriangle />
                <span>
                  <strong>{strings.warning} -</strong>{' '}
                  {strings.meterGroupAmpWarning}
                </span>
              </div>
            )}
            {displayWarning.phase && (
              <div className='Warning-modal'>
                <FaExclamationTriangle />
                <span>
                  <strong>{strings.warning} -</strong>{' '}
                  {strings.meterGroupPhaseWarning}
                </span>
              </div>
            )}
          </ModalNoteStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          formSubmit='no-submit'
          handleClick={handleUpdateMG}
          buttonText={strings.save}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default MeterGroupModal;
