import React, { useEffect, useState, useContext } from 'react';

//service
import AppPaymentsService from '../AppPaymentsService';
import AppPlatformService from '../../../../services/AppPlatform/AppPlatformService';

//components
import LedgerHistory from '../../LedgerHistory/LedgerHistory';

//global components
import { LoadingSpinner } from '../../../shared';

//styles
import {
  LedgerSelectContainerStyles,
  LedgerFilterSelectStyles,
  LedgerDataGraphContainerStyles,
  LedgerDataGraphStyles,
  LedgerListStyles,
} from '../../../DriverStyles';
import { ListPlaceholderStyles, TextStyles } from '../../../SharedStyles';

//context
import { DriverContext } from '../../../context/driver/getDriverContext';
import { VehiclesContext } from '../../../context/driver/getVehiclesContext';

// object which holds the order value of the month
var monthNames = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

const PaymentsHistory = () => {
  const { strings } = AppPaymentsService;
  const { driver } = useContext(DriverContext);
  const { vehicles } = useContext(VehiclesContext);

  const [loading, setLoading] = useState(true);
  const [ledgerInfo, setLedgerInfo] = useState([]);
  const [didMount, setDidMount] = useState(false);

  //filters
  const [uniqueYears, setUniqueYears] = useState([]);
  const [uniqueMonths, setUniqueMonths] = useState([]);
  const [filteredTransaction, setFilteredTransaction] = useState([]);
  const [filter, setFilter] = useState({
    month: '',
    year: '',
    vehicleId: '',
  });
  const [utilityCost, setUtilityCost] = useState(0);
  const [miles, setMiles] = useState(0); //total calc for miles on filtered search history
  const [kiloWatts, setKiloWatts] = useState(0); //total calc for kwh on filtered search history
  const [totalCost, setTotalCost] = useState(0); //total calc for cost on filtered search history

  useEffect(() => {
    setDidMount(true);
    setLoading(true);
    const gettingLedgerInfo = async () => {
      let ledgerData = await AppPaymentsService.getDriverLedger();
      setLedgerInfo(ledgerData);
      if (ledgerData) {
        setLoading(false);
      }
    };
    gettingLedgerInfo();
    return () => {
      setDidMount(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const gettingFilters = () => {
      ///getting years
      let ledgerYears = ledgerInfo.map((ledger) => {
        let yearOnly = ledger.ledgerDate.split('-');
        return yearOnly[0];
      });
      let uniqueYears = [...new Set(ledgerYears)];
      setUniqueYears(uniqueYears);
      //getting months
      let ledgerMonths = ledgerInfo.map((ledger) => {
        // let monthOnly = ledger.ledgerDate.split('-');
        let newMonth = new Date(ledger.ledgerDate);
        let updatedMonth = newMonth.toLocaleDateString(
          driver.language ? driver.language : 'en-US',
          {
            month: 'long',
          }
        );
        return updatedMonth;
      });

      let uniqueMonths = [...new Set(ledgerMonths)];

      uniqueMonths.sort(function (a, b) {
        return monthNames[a] - monthNames[b];
      });

      setUniqueMonths(uniqueMonths);
    };

    gettingFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ledgerInfo]);

  //getting Month out to compare function
  const getMonth = (date) => {
    const dateMonth = new Date(date);
    const monthConvert = dateMonth.toLocaleDateString(
      driver.language ? driver.language : 'en-US',
      {
        month: 'long',
      }
    );
    return monthConvert;
  };

  //getting Year out to compare function
  const getYear = (date) => {
    let singleDate = date;
    let brokenUpDate = singleDate.split('-');
    return brokenUpDate[0];
  };

  const handleFilterChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (ledgerInfo) {
      setUtilityCost(0);
      setTotalCost(0);
      setMiles(0);
      setKiloWatts(0);
      let filteredArray = ledgerInfo
        .filter((item) => {
          item.month = getMonth(item.ledgerDate);
          item.year = getYear(item.ledgerDate);
          if (filter.vehicleId !== '') {
            return item.vehicleId === filter.vehicleId;
          } else {
            return item;
          }
        })
        .filter((item) => {
          if (filter.month !== '') {
            return item.month === filter.month;
          } else {
            return item;
          }
        })
        .filter((item) => {
          if (filter.year !== '') {
            return item.year === filter.year;
          } else {
            return item;
          }
        })
        .filter(
          (history) =>
            history.ledgerEntryType === 3 ||
            (history.ledgerEntryType === 2 && history.rateType !== 'Card')
        )
        .map((h) => {
          if (h.rateType === 'Owned') {
            setUtilityCost((prev) => (prev += h.amount));
          } else {
            setTotalCost((prev) => (prev += h.amount));
          }
          setMiles((prev) => {
            return driver.unitOfMeasurement === 'Miles'
              ? prev + h.miles
              : (parseInt(prev + h.miles) * 1.60934).toFixed(0);
          });
          setKiloWatts((prev) => (prev += h.totalPower));
          return h;
        });
      setFilteredTransaction(filteredArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ledgerInfo, filter]);

  const ledgerInfoData = filteredTransaction.map((history, index) => {
    return <LedgerHistory {...history} key={index} sessionHistory />;
  });

  //prevents memory leak with changing component before async function finishes
  if (!didMount) {
    return null;
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      <LedgerSelectContainerStyles>
        <LedgerFilterSelectStyles
          name='vehicleId'
          onChange={handleFilterChange}>
          <option value=''>{strings.allVehicles}</option>
          {vehicles.map((vehicle) => (
            <option key={vehicle.id} value={vehicle.id}>
              {vehicle.nickName}
            </option>
          ))}
        </LedgerFilterSelectStyles>
        <LedgerFilterSelectStyles name='month' onChange={handleFilterChange}>
          <option value=''>Month</option>
          {uniqueMonths.map((month) => (
            <option key={month} value={month}>
              {month}
            </option>
          ))}
        </LedgerFilterSelectStyles>
        <LedgerFilterSelectStyles name='year' onChange={handleFilterChange}>
          <option value=''>Year</option>
          {uniqueYears.map((year) => {
            return (
              <option value={year} key={year}>
                {year}
              </option>
            );
          })}
        </LedgerFilterSelectStyles>
      </LedgerSelectContainerStyles>

      <LedgerDataGraphContainerStyles>
        <LedgerDataGraphStyles>
          <TextStyles size='13px'>
            {driver.unitOfMeasurement === 'Miles'
              ? strings.distanceUnit
              : strings.distanceUnitKm}
          </TextStyles>
          <TextStyles size='14px' weight='600'>
            {miles.toFixed(0)}
          </TextStyles>
        </LedgerDataGraphStyles>
        <LedgerDataGraphStyles>
          <TextStyles size='13px'>{strings.powerUnit}</TextStyles>
          <TextStyles size='14px' weight='600'>
            {kiloWatts.toFixed(2)}
          </TextStyles>
        </LedgerDataGraphStyles>
        <LedgerDataGraphStyles>
          <TextStyles size='13px'>{strings.cost}</TextStyles>
          <TextStyles size='14px' weight='600'>
            {AppPlatformService.currencyFormat(totalCost)}
          </TextStyles>
        </LedgerDataGraphStyles>
        <LedgerDataGraphStyles>
          <TextStyles size='13px'>{strings.titleUtility}</TextStyles>
          <TextStyles size='14px' weight='600'>
            {AppPlatformService.currencyFormat(utilityCost)}
          </TextStyles>
        </LedgerDataGraphStyles>
      </LedgerDataGraphContainerStyles>

      <LedgerListStyles>
        {ledgerInfoData.length !== 0 ? (
          ledgerInfoData
        ) : (
          <ListPlaceholderStyles borderBottom='none'>
            <p>{strings.noLedger}</p>
          </ListPlaceholderStyles>
        )}
      </LedgerListStyles>
    </>
  );
};

export default PaymentsHistory;
