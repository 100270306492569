import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppMembershipsService from '../AppMembershipsService';

//components
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';

//images
import membershipsPlaceholder from '../../../../assets/images/MembershipsPlaceholder.png';

const PrivateCode = (props) => {
  const inputRef = useRef(null);
  const { strings } = AppMembershipsService;

  const [privateMembership, setPrivateMembership] = useState(null);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [error, setError] = useState({
    display: false,
    message: '',
  });

  const handleChange = (e) => {
    setToken(e.target.value);
  };

  const searchToken = async (e) => {
    e.preventDefault();
    if (!token) {
      inputRef.current.focus();
      return;
    }
    setLoading(true);
    try {
      let membership = await AppMembershipsService.getPrivateToken(token);

      setPrivateMembership(membership);
      if (membership) {
        setError({ display: false, message: strings.privateFailed });

        setLoading(false);
      }
    } catch (err) {
      if (err.response) {
        setError({ display: true, message: strings.privateFailed });
        setLoading(false);
      }
    }
  };

  const handleClose = () => {
    props.closeModal(false);
  };

  const handleJoinPrivate = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await AppMembershipsService.joinPrivate(token);
      handleClose();
    } catch (err) {
      if (err.response) {
        setError({ display: true, message: strings.privateFailed });
        setLoading(false);
      }
    }
  };

  return (
    <div className='Modal-Display-Driver'>
      {loading && <LoadingSpinner />}
      <div className='Modal'>
        {!privateMembership ? (
          <>
            <div className='Modal-Header'>
              {strings.findMembership}
              <div className='Modal-Close'>
                <FaRegWindowClose onClick={handleClose} />
              </div>
            </div>
            <div className='Modal-Content'>
              <p className='Modal-Content-Text'>
                {strings.findMembershipContent}
              </p>
              {error.display && (
                <div className='Error-Container'>{error.message}</div>
              )}
              <input
                onChange={handleChange}
                ref={inputRef}
                type='text'
                name='token'
                value={token || ''}
                placeholder={strings.searchCode}
              />
            </div>
            <div className='Modal-Button'>
              <button className='app-button' onClick={searchToken}>
                {strings.search}
              </button>
            </div>
          </>
        ) : (
          <>
            <div className='Modal-Header'>
              {strings.joinTitle}
              <div className='Modal-Close'>
                <FaRegWindowClose onClick={handleClose} />
              </div>
            </div>
            <div className='Modal-Content'>
              <div className='Modal-Image'>
                <img
                  src={
                    privateMembership.imagePath
                      ? privateMembership.imagePath
                      : membershipsPlaceholder
                  }
                  alt={privateMembership.membershipName}
                />
              </div>
              <h2 className='Modal-Content-Title'>
                {privateMembership.membershipName}
              </h2>
              {error.display && (
                <div className='Error-Container'>{error.message}</div>
              )}
              <p className='Modal-Content-Text'>{strings.joinParagraph}</p>
            </div>
            <div className='Modal-Button'>
              <button className='app-button' onClick={handleJoinPrivate}>
                {strings.joinButton}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default withRouter(PrivateCode);
