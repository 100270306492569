import React from 'react';
import { FaMapMarkerAlt, FaInfoCircle } from 'react-icons/fa';

//services
import AppMembershipService from '../../AppMembershipsService';

//styles
import {
  FormContainerStyles,
  FormRowStyles,
  FormLeftStyles,
  TextareaStyles,
  TitleStyles,
} from '../../../../SharedStyles';

const MembershipMessage = (props) => {
  const { strings } = AppMembershipService;

  const handleInputChange = (e) => {
    props.handleDataChange({
      ...props.membership,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <FormContainerStyles padding='0' headersPadding='0' borderBottom>
      <TitleStyles
        size='14px'
        margin='0 0 10px'
        weight='600'
        padding='10px 10px 0'>
        {strings.membershipLocations}
      </TitleStyles>
      {/* Applying Location to membership*/}
      <FormRowStyles padding='5px 10px 20px' borderBottom>
        <FormLeftStyles display='flex'>
          <FaMapMarkerAlt />
        </FormLeftStyles>
        <TextareaStyles
          rows={
            !props.membership.locationDescription
              ? '1'
              : props.membership.locationDescription &&
                props.membership.locationDescription.length < 100
              ? '1'
              : ''
          }
          size='14px'
          mobileSize='14px'
          flexWidth='1'
          margin='0 20px 0 0'
          padding='5px 0px 5px 4px'
          bgPosition='1em'
          onChange={handleInputChange}
          type='name'
          placeholder={strings.applyingLocationPlaceholder}
          value={props.membership.locationDescription || ''}
          name='locationDescription'
        />
      </FormRowStyles>
      <TitleStyles
        size='14px'
        margin='0 0 10px'
        weight='600'
        padding='10px 10px 0'>
        {strings.applyingMemberMesage}
      </TitleStyles>
      {/* Applying Message to New Membership or Edit Membership*/}
      <FormRowStyles padding='5px 10px 20px' margin='0 0 10px'>
        <FormLeftStyles display='flex'>
          <FaInfoCircle />
        </FormLeftStyles>
        <TextareaStyles
          rows={
            !props.membership.applicationMessage
              ? '1'
              : props.membership.applicationMessage &&
                props.membership.applicationMessage.length < 100
              ? '1'
              : ''
          }
          size='14px'
          mobileSize='14px'
          flexWidth='1'
          margin='0 20px 0 0'
          padding='5px 0px 5px 4px'
          bgPosition='1em'
          onChange={handleInputChange}
          type='name'
          placeholder={strings.applyingMemberPlaceholder}
          value={props.membership.applicationMessage || ''}
          name='applicationMessage'
        />
      </FormRowStyles>
    </FormContainerStyles>
  );
};

export default MembershipMessage;
