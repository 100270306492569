import AppApiService from '../../../services/AppApi/AppApiService';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
import AppImagingService from '../../../services/AppImaging/AppImagingService';

import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppVehicleService = {
  getVehicle: function(vehicle) {
    return AppApiService.post('getDriverVehicle', null, { VehicleID: vehicle });
  },
  getVehicles: function() {
    return AppApiService.get('getDriverVehicles');
  },
  getVehicleCatalog: function() {
    return AppApiService.get('getVehicleCatalog');
  },
  addDriverVehicle: function(vehicle) {
    return AppApiService.post('insertDriverVehicle', null, vehicle);
  },
  updateDriverVehicle: function(vehicle) {
    return AppApiService.post('updateDriverVehicle', null, vehicle);
  },
  deleteVehicle: function(vehicle) {
    return AppApiService.post('removeDriverVehicle', null, vehicle);
  },
  prepareImage: function(image) {
    return AppImagingService.getBlobForDataUrl(image);
  },
  uploadImage: function(imageBlob) {
    return AppApiService.post('uploadImage', null, imageBlob);
  },
  strings: localStrings
};

export default AppVehicleService;
