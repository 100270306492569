import React, { useState, useEffect, useContext } from 'react';
import { FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa';

//services
import AppSystemUsersService from '../AppSystemUsersService';

//components
import SystemUserCard from './SystemUserCard';

//global components
import { LoadingSpinner } from '../../../shared';
import Pagination from '../../../shared/Pagination/Pagination';

//hooks
import { useWindowResize } from '../../../shared/hooks/useWindowSize';
import { usePagination } from '../../../shared/hooks/usePagination';
import { useSortAndReverse } from '../../../shared/hooks/useSortAndReverse';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';

//styles
import {
  TableStyles,
  TableHeaderStyles,
  TableItemStyles,
} from '../../../SharedStyles';

//helpers
import { tableHeaderConvert } from '../helpers';
import { rolesPerUser } from '../../helpers';

const SystemUserList = (props) => {
  const { strings } = AppSystemUsersService;
  const { filteredValue, searchedValue } = props;
  const { account } = useContext(CsoAccountContext);

  const [systemUsers, setSystemUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [adminsCount, setAdminsCount] = useState(0);

  const size = useWindowResize(window.innerWidth, window.innerHeight);

  //initial Sort Function by lastName - per Jeff. Change the value if we prefer another sort property
  const fetchingUsers = async () => {
    let allUsers = await AppSystemUsersService.getSystemUsers();
    let sortedUsers = allUsers.sort((a, b) => {
      return a['lastName'].localeCompare(b['lastName']);
    });
    let adjustedUsers = sortedUsers.map((user) => {
      user.role = rolesPerUser[user.role];
      return user;
    });
    setSystemUsers(adjustedUsers);
    if (!allUsers.length) {
      setLoading(false);
    }
    setAdminsCount(allUsers.filter(a => (a.role === 'Administrator' && !a.pending)).length);
  };

  useEffect(() => {
    fetchingUsers();
  }, [account]);

  useEffect(() => {
    setFilteredUsers(systemUsers);
  }, [systemUsers]);

  useEffect(() => {
    if (filteredUsers.length) {
      setLoading(false);
    }
  }, [filteredUsers]);

  const updateSortedItems = (items) => {
    setFilteredUsers(items);
  };

  /**
   * Can be two of these per page for enterprise and non-enterprise
   * @param {*} sortStatus - status for the default sort
   * @param {*} dataArray - items that will be sorted
   * @param {*} headerText - strings array that will show user friendly name to map over
   * @param {*} headerConvert - converts strings in headerText array to object notation, custom function needed for this logic
   * @param {*} updateSortedItem - Function to update the current state with the sorted items
   */
  const [isActiveSort, sortReverse, changeActiveSort] = useSortAndReverse(
    'lastName',
    systemUsers,
    strings.headerData,
    tableHeaderConvert,
    updateSortedItems
  );

  /**
   * pagination for the data
   * @param {*} dataArray - items that will be sorted
   * @param {*} itemsPerPage - Custom items limit per page
   */
  const {
    next,
    prev,
    jump,
    currentData,
    currentPage,
    maxPage,
    totalData,
    currentCount,
    pageNumbers,
  } = usePagination(filteredUsers, 20);

  useEffect(() => {
    let lowercasedSearch = searchedValue.toLowerCase();

    const searchedUsers = systemUsers.filter((user) => {
      return Object.keys(user).some((key) => {
        if (user[key] !== null) {
          return user[filteredValue ? filteredValue : key]
            .toString()
            .toLowerCase()
            .includes(lowercasedSearch);
        }
        return null;
      });
    });
    setFilteredUsers(searchedUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedValue, filteredValue]);

  return (
    <>
      {loading && <LoadingSpinner />}

      {systemUsers.length !== 0 && (
        <Pagination
          currentCount={currentCount}
          totalData={totalData}
          next={next}
          prev={prev}
          jump={jump}
          currentPage={currentPage}
          maxPage={maxPage}
          pageNumbers={pageNumbers}
        />
      )}
      <TableStyles minHeight='500px'>
        <TableHeaderStyles
          borderTop='none'
          borderLeft='none'
          borderRight='none'
          width={size[0] >= 768 ? '100%' : '760px'}>
          {strings.headerData.map((title) => {
            return (
              <TableItemStyles
                flex={
                  title === 'Email'
                    ? '200px'
                    : title === 'Location Name'
                    ? '150px'
                    : '90px'
                }
                borderRight
                key={tableHeaderConvert[title]}
                onClick={() => {
                  changeActiveSort(tableHeaderConvert[title]);
                }}>
                {isActiveSort[tableHeaderConvert[title]] && (
                  <div>
                    {sortReverse[tableHeaderConvert[title]] ? (
                      <FaLongArrowAltDown />
                    ) : (
                      <FaLongArrowAltUp />
                    )}{' '}
                  </div>
                )}
                {title}
              </TableItemStyles>
            );
          })}
        </TableHeaderStyles>
        {currentData().map((user) => (
          <SystemUserCard
            key={user.id}
            firstName={user.firstName}
            lastName={user.lastName}
            email={user.email}
            role={user.role}
            createdDate={user.createdDate}
            lastSignIn={user.lastSignIn}
            locationDisplayName={user.locationDisplayName}
            id={user.id}
            userId={user.userId}
            pending={user.pending}
            token={user.token}
            adminsCount={adminsCount}
          />
        ))}
      </TableStyles>
    </>
  );
};

export default SystemUserList;
