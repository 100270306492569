import React, { useContext, useState } from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppChargersModalService from './AppChargersModalService';

//global components
import { LoadingSpinner } from '../../../shared';
import ModalButton from '../../../shared/Buttons/ModalButton';

//context
import { CsoMembershipContext } from '../../../context/operator/getCsoMembership';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  SelectStyles,
  ModalNoteStyles,
} from '../../../SharedStyles';

const MembershipModal = (props) => {
  const { strings } = AppChargersModalService;
  const { allMemberships } = useContext(CsoMembershipContext);
  const [loading, setLoading] = useState(false);
  const [selectedMemberships, setSelectedMemberships] = useState([]);

  const [errorMessage, setErrorMessage] = useState({
    display: false,
    message: '',
  });

  const closeModal = () => {
    props.handleCloseModal();
  };

  const handleChange = (e) => {
    let options = e.target.options;
    let selectedMemberships;
    selectedMemberships = [...options]
      .filter((option) => {
        return option.selected;
      })
      .map((selectedOption) => {
        return selectedOption.value;
      });
    setSelectedMemberships(selectedMemberships);
  };

  const handleSaveInfo = async (e) => {
    setLoading(true);
    try {
      //add multiple chargers membership
      if (props.addMembership) {
        let iotHubDeviceIds = props.chargers.map((charger) => {
          return charger.iotHubDeviceId;
        });
        const memberInfo = {
          iotHubDeviceId: iotHubDeviceIds,
          membershipId: selectedMemberships,
        };
        let response = await AppChargersModalService.chargerToMembership(
          memberInfo
        );
        if (response) {
          closeModal();
        }
      }
      //remove multiple chargers membership
      if (props.removeMembership) {
        let iotHubDeviceIds = props.chargers.map((charger) => {
          return charger.iotHubDeviceId;
        });
        const memberInfo = {
          iotHubDeviceId: iotHubDeviceIds,
          membershipId: selectedMemberships,
        };
        let response = await AppChargersModalService.removeChargerFromMembership(
          memberInfo
        );
        if (response) {
          closeModal();
        }
      }
      //add single charger membership
      if (props.cardMembership) {
        const memberInfo = {
          iotHubDeviceId: [props.charger.iotHubDeviceId],
          membershipId: selectedMemberships,
        };
        let response = await AppChargersModalService.chargerToMembership(
          memberInfo
        );
        if (response) {
          closeModal();
        }
      }
    } catch (err) {
      if (err.response) {
        setErrorMessage({ display: true, message: err.response.data });
      }
    }
  };

  if (props.addMembership) {
    return (
      <ModalStyles>
        {loading && <LoadingSpinner />}
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{strings.addMembershipTitle}</h2>
            <FaRegWindowClose className='Close' onClick={closeModal} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            {errorMessage.display && (
              <ModalItemStyles padding='0 10px'>
                <p>ERROR: {errorMessage.message}</p>
              </ModalItemStyles>
            )}
            <ModalItemStyles padding='0 10px'>
              <p>
                {strings.addMembershipContentOne} {props.chargers.length}{' '}
                {strings.addMembershipContentTwo}
              </p>
            </ModalItemStyles>
            {/* Section */}
            <ModalItemStyles padding='0 10px'>
              <SelectStyles
                noDropdown
                onChange={handleChange}
                size={allMemberships.length}
                multiple
                name='membershipIds'>
                {allMemberships.map((membership) => {
                  return (
                    <option key={membership.id} value={membership.id}>
                      {membership.membershipName}
                    </option>
                  );
                })}
              </SelectStyles>
            </ModalItemStyles>
            {/* Note Section */}
            <ModalNoteStyles>
              <p>
                <strong>{strings.note}</strong> {strings.addMembershipNote}
              </p>
            </ModalNoteStyles>
          </ModalContentStyles>
          {/* Button Component for Modals*/}
          <ModalButton
            disabled={
              props.chargers.length === 0 || selectedMemberships.length === 0
            }
            containerMargin='10px 10px 0'
            containerAlign='right'
            buttonPadding='10px'
            buttonMargin='0'
            handleClick={handleSaveInfo}
            buttonText={strings.assignChargers}
          />
        </ModalContainerStyles>
      </ModalStyles>
    );
  }

  if (props.removeMembership) {
    return (
      <ModalStyles>
        {loading && <LoadingSpinner />}
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{strings.removeMembershipTitle}</h2>
            <FaRegWindowClose className='Close' onClick={closeModal} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            <ModalItemStyles padding='0 10px'>
              <p>
                {strings.removeMembershipContentOne} {props.chargers.length}{' '}
                {strings.removeMembershipContentTwo}
              </p>
            </ModalItemStyles>
            {/* Section */}
            <ModalItemStyles padding='0 10px'>
              <SelectStyles
                noDropdown
                onChange={handleChange}
                size={allMemberships.length}
                multiple
                name='membershipIds'>
                {allMemberships.map((membership) => {
                  return (
                    <option key={membership.id} value={membership.id}>
                      {membership.membershipName}
                    </option>
                  );
                })}
              </SelectStyles>
            </ModalItemStyles>
          </ModalContentStyles>
          {/* Button Component for Modals*/}
          <ModalButton
            disabled={props.chargers.length === 0}
            containerMargin='10px 10px 0'
            containerAlign='right'
            buttonPadding='10px'
            buttonMargin='0'
            handleClick={handleSaveInfo}
            buttonText={strings.removeChargers}
          />
        </ModalContainerStyles>
      </ModalStyles>
    );
  }

  if (props.cardMembership) {
    return (
      <ModalStyles>
        {loading && <LoadingSpinner />}
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{strings.addSMembershipTitle}</h2>
            <FaRegWindowClose className='Close' onClick={closeModal} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            {errorMessage.display && (
              <ModalItemStyles padding='0 10px'>
                <p>ERROR: {errorMessage.message}</p>
              </ModalItemStyles>
            )}
            <ModalItemStyles padding='0 10px'>
              <p>
                {strings.addSMembershipContentOne}{' '}
                {strings.addSMembershipContentTwo}
              </p>
            </ModalItemStyles>
            {/* Section */}
            <ModalItemStyles padding='0 10px'>
              <SelectStyles
                noDropdown
                onChange={handleChange}
                size={allMemberships.length}
                multiple
                name='membershipIds'>
                {allMemberships.map((membership) => {
                  return (
                    <option key={membership.id} value={membership.id}>
                      {membership.membershipName}
                    </option>
                  );
                })}
              </SelectStyles>
            </ModalItemStyles>
            {/* Note Section */}
            <ModalNoteStyles>
              <p>
                <strong>{strings.note}</strong> {strings.addSMembershipNote}
              </p>
            </ModalNoteStyles>
          </ModalContentStyles>
          {/* Button Component for Modals*/}
          <ModalButton
            disabled={selectedMemberships.length === 0}
            containerMargin='10px 10px 0'
            containerAlign='right'
            buttonPadding='10px'
            buttonMargin='0'
            handleClick={handleSaveInfo}
            buttonText={strings.assignChargers}
          />
        </ModalContainerStyles>
      </ModalStyles>
    );
  }
};

export default MembershipModal;
