import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppMeterGroupsService from '../../AppMeterGroupsService';

//global component
import ModalButton from '../../../../shared/Buttons/ModalButton';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
} from '../../../../SharedStyles';

const MissingInfoModal = (props) => {
  const { handleCloseModal, missingData } = props;
  const { strings } = AppMeterGroupsService;

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.missingInfoTitle}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0'>
            {missingData ? (
              <p>{strings.missingLocationInfo}</p>
            ) : (
              <p>{strings.missingInfoContent}</p>
            )}
          </ModalItemStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={closeModal}
          buttonText={strings.ok}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default MissingInfoModal;
