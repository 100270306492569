import React, { useState } from 'react';

//layout
import { OuterWrapper, ComponentWrapper } from '../OperatorShared';
import Button from '../../shared/Buttons/Button';

//services
import AppMeterGroupsService from './AppMeterGroupsService';

//components
import MeterGroupsList from './MeterGroupsList/MeterGroupsList';

//global components
import SearchFilterBar from '../OperatorShared/SearchFilterBar';
import EnterpriseAdmin from '../EnterpriseAdmin';

const MeterGroups = (props) => {
  const { strings } = AppMeterGroupsService;
  const [searchedValue, setSearchedValue] = useState('');
  const [filteredValue, setFilteredValue] = useState(null);
  const [submitSearch, setSubmitSearch] = useState(false);

  const handleFilterChange = (filter) => {
    setSubmitSearch(false);
    setFilteredValue(filter);
  };

  const handleSearchChange = (value) => {
    setSubmitSearch(false);
    setSearchedValue(value);
  };

  const handleSearchSubmit = (submit) => {
    setSubmitSearch(submit);
  };

  return (
    <OuterWrapper title={strings.pageTitle} internal path='/'>
      <EnterpriseAdmin allowTech>
        <ComponentWrapper title={strings.pageTitle}>
          <SearchFilterBar
            meterGroupFilter
            filterBy={strings.filterList}
            handleSearchSubmit={handleSearchSubmit}
            handleSearchChange={handleSearchChange}
            handleFilterChange={handleFilterChange}
          />
          {/* list of searched and filtered results */}
          <MeterGroupsList
            filteredValue={submitSearch ? filteredValue : ''}
            searchedValue={submitSearch ? searchedValue : ''}
          />
        </ComponentWrapper>
        <Button
          cso
          hasMobile
          redirect='/meter-groups/add'
          buttonText={strings.add}
          roles={[1]}
        />
      </EnterpriseAdmin>
    </OuterWrapper>
  );
};

export default MeterGroups;
