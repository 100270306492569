import React from 'react';

//services
import AppNavService from './AppNavService';

//components
import AccountBlock from './AccountBlock/AccountBlock';
import MobileMenu from './MobileMenu/MobileMenu';
import Logo from './Logo/Logo';
import CenterNav from './CenterNav/CenterNav';

//styles
import { NavBarContainerStyles } from '../../SharedStyles';
import './NavSection.scss';

const NavSection = React.forwardRef((props, ref) => {
  const { strings } = AppNavService;
  const { router } = props;

  //props passed interior wrapper
  const handleRedirection = () => {
    if (props.goBack) {
      router.history.goBack();
    } else {
      router.history.push(props.path);
    }
  };

  return (
    <header>
      <NavBarContainerStyles mobileMedia='575px' isInternal={props.isInternal}>
        <div className='Left-Align'>
          <Logo />
          <CenterNav strings={strings} />
        </div>
        <AccountBlock />
      </NavBarContainerStyles>
      {props.isInternal && (
        <MobileMenu
          title={props.title}
          handleRedirection={handleRedirection}
          ref={ref}
          strings={strings}
          save={props.save}
          actionText={props.actionText}
          join={props.join}
          onSaveClick={props.onSaveClick}
          myForm={props.myForm}
        />
      )}
    </header>
  );
});
export default NavSection;
