import React from 'react';
import moment from 'moment';

//styles
import { DivStyles, TextStyles, TitleStyles } from '../../../../SharedStyles';

const ChargerTimeSummary = ({
  strings,
  diagnostics,
  chargingSessionEta,
  showSummary,
}) => {
  const getStartedDisplay = () => {
    return Object.keys(chargingSessionEta).length === 0
      ? '--'
      : moment(chargingSessionEta.startedTimestamp).format('h:mm:ss a');
  };
  const getElapsedDisplay = () => {
    return Object.keys(chargingSessionEta).length === 0
      ? '--'
      : `${chargingSessionEta.elapsedTime.Hours}h ${chargingSessionEta.elapsedTime.Minutes}m ${chargingSessionEta.elapsedTime.Seconds}s`;
  };
  const getFinishedDisplay = () => {
    return showSummary
      ? moment(chargingSessionEta.finishedTimestamp).format('h:mm a')
      : '--';
  };

  var getEtaDisplay = () => {
    // Note: When showing the charging summary, the ETA should no longer be dispalyed as the session has ended.
    return Object.keys(chargingSessionEta).length === 0 || showSummary
      ? '--'
      : chargingSessionEta.estimatedTimeRemainingMins >= 1440
      ? '24hrs +'
      : moment()
          .add(chargingSessionEta.estimatedTimeRemainingMins, 'minutes')
          .format('h:mm a');
  };

  return (
    <DivStyles className='Control-Summaries' align='center' margin='2% 0'>
      <DivStyles display='flex' justifyContent='center' alignItems='center'>
        <TitleStyles size='14px' weight='700' flex='1' lineHeight='1'>
          {strings.started}
        </TitleStyles>
        <TitleStyles size='14px' weight='700' flex='1' lineHeight='1'>
          {strings.elapsed}
        </TitleStyles>
        {diagnostics &&
          diagnostics.authenticatedUser &&
          diagnostics.authenticatedUser.userId !==
            'b79bb5a3-05bc-47ee-bbb1-3bf19eb5c463' && (
            <TitleStyles size='14px' weight='700' flex='1' lineHeight='1'>
              {strings.eta}
            </TitleStyles>
          )}
        <TitleStyles size='14px' weight='700' flex='1' lineHeight='1'>
          {strings.finished}
        </TitleStyles>
      </DivStyles>
      <DivStyles display='flex' justifyContent='center' alignItems='center'>
        <TextStyles size='14px' flex='1'>
          {getStartedDisplay()}
        </TextStyles>
        <TextStyles size='14px' flex='1'>
          {getElapsedDisplay()}
        </TextStyles>
        {diagnostics &&
          diagnostics.authenticatedUser &&
          diagnostics.authenticatedUser.userId !==
            'b79bb5a3-05bc-47ee-bbb1-3bf19eb5c463' && (
            <TextStyles size='14px' flex='1'>
              {getEtaDisplay()}
            </TextStyles>
          )}
        <TextStyles size='14px' flex='1'>
          {getFinishedDisplay()}
        </TextStyles>
      </DivStyles>
    </DivStyles>
  );
};

export default ChargerTimeSummary;
