import React, { useState, useContext } from 'react';
import { FaArrowCircleRight, FaRegWindowClose } from 'react-icons/fa';
import { injectStripe } from 'react-stripe-elements';

//services
import AppBankService from '../AppBankService';

//components
import BankForm from '../BankWizardForms/BankForm';
import CreditCardForm from '../BankWizardForms/CreditCardForm';

//context
import { ErrorContext } from '../../../context/shared/ErrorContext';

//error Class
import { ErrorResponse } from '../../../shared/ErrorResponse';
import {
  JumboTronHeaderStyles,
  RowStyles,
  TitleStyles,
} from '../../../SharedStyles';

const FormWizard = (props) => {
  const { strings } = AppBankService;
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(false);

  const [bankForm, setBankForm] = useState({
    payoutForm: {
      country: 'US',
      currency: 'usd',
      routing_number: '',
      account_number: '',
      confirm_account_number: '',
      account_holder_name: '',
      account_holder_type:
        props.connectAccount && props.connectAccount.businessType,
    },
    customerForm: {
      country: 'US',
      currency: 'usd',
      routing_number: '',
      account_number: '',
      confirm_account_number: '',
      account_holder_name: '',
      account_holder_type:
        props.connectAccount && props.connectAccount.businessType,
    },
    customerCardToken: null,
  });

  const handleCustomerToken = (token) => {
    setBankForm((b) => ({ ...b, customerCardToken: token }));
  };

  const handleBankForm = (object, property, value) => {
    setBankForm((b) => ({
      ...b,
      [object]: { ...b[object], [property]: value },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let payoutCompleted = Object.keys(bankForm.payoutForm).every((key) => {
      return bankForm.payoutForm[key] !== '';
    });
    let customerCompleted = Object.keys(bankForm.customerForm).every((key) => {
      return bankForm.customerForm[key] !== '';
    });

    try {
      if (
        bankForm.payoutForm.confirm_account_number !==
          bankForm.payoutForm.account_number ||
        bankForm.customerForm.confirm_account_number !==
          bankForm.customerForm.account_number
      ) {
        throw new ErrorResponse(
          'frontend',
          'You must provide matching account numbers.',
          'Mismatch Account Numbers'
        );
      }

      if (payoutCompleted) {
        let { token, error } = await props.stripe.createToken(
          'bank_account',
          bankForm.payoutForm
        );
        if (error) {
          throw new ErrorResponse(
            'frontend',
            `${error.message}  Click ok and press the back arrow to adjust.`,
            'Income Account Error'
          );
        }
        await AppBankService.createPayoutAccount(token.id);
      }

      if (customerCompleted) {
        let { token, error } = await props.stripe.createToken(
          'bank_account',
          bankForm.customerForm
        );
        if (error) {
          throw new ErrorResponse(
            'frontend',
            error.message,
            'Payment Account Error'
          );
        }
        await AppBankService.createCSOPaymentInfo({
          token: token.id,
          isBank: true,
        });
      }
      //Customer CC sources saved with Token
      if (bankForm.customerCardToken) {
        await AppBankService.createCSOPaymentInfo({
          token: bankForm.customerCardToken.id,
          isBank: false,
        });
      }
      props.fetchStripeAccount();
      props.handleShowWizard(false);
      props.fetchStripeData();
      props.stepReset(1);
    } catch (err) {
      setLoading(false);
      if (err.name === 'frontend') {
        setError({
          display: true,
          styles: 'cso',
          title: err.title,
          message: err.message,
        });
      } else {
        setError({
          display: true,
          styles: 'cso',
          title: 'Existing Account',
          message: err.response.data.split(/[|]+/).pop(),
        });
      }
    }
  };

  const handleCloseWizard = () => {
    props.handleShowWizard(false);
    props.stepReset(1);
  };

  switch (props.step) {
    case 1:
      return (
        <>
          <JumboTronHeaderStyles
            align='left'
            contentAlign='left'
            contentMargin='10px 0 15px'
            contentPadding='0'
            contentSize='16px'>
            <TitleStyles
              size='28px'
              borderBottom
              padding='0 0 5px'
              display='flex'
              alignItems='center'
              justifyContent='space-between'>
              {strings.wizard1Title}
              {props.connectAccount.payoutVerified && (
                <span class='Close'>
                  <FaRegWindowClose onClick={handleCloseWizard} />
                </span>
              )}
            </TitleStyles>
            <div className='Content'>
              <p>{strings.wizard1Content1}</p>
              <p>{strings.wizard1Content2}</p>
              <p>
                {strings.wizard1Content3a} <em>{strings.next}</em>{' '}
                {strings.wizard1Content3b}
              </p>
            </div>
          </JumboTronHeaderStyles>
          <RowStyles borderBottom='none' flex='100%'>
            <div className='Arrows'>
              <button title={strings.next} onClick={props.nextStep}>
                <FaArrowCircleRight />
              </button>
            </div>
          </RowStyles>
        </>
      );
    case 2:
      return (
        <BankForm
          handleBankForm={handleBankForm}
          bankForm={bankForm}
          handleCloseWizard={handleCloseWizard}
          {...props}
        />
      );

    case 3:
      return (
        <CreditCardForm
          handleBankForm={handleBankForm}
          bankForm={bankForm}
          loading={loading}
          handleCloseWizard={handleCloseWizard}
          handleCustomerToken={handleCustomerToken}
          handleSubmit={handleSubmit}
          {...props}
        />
      );
    default:
      return null;
  }
};

export default injectStripe(FormWizard);
