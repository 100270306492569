import React, { useEffect, useContext } from 'react';

//layout
import { InteriorWrapper, ComponentWrapper } from '../../shared/index';
import Button from '../../../shared/Buttons/Button';

//services
import AppVehicleService from '../AppVehicleService';

//components
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';
import VehicleCard from './VehicleCard';

//images
import VehiclePlaceholder from '../../../../assets/images/largeVehiclePlaceholder.png';

//Context
import { VehiclesContext } from '../../../context/driver/getVehiclesContext';

//Styles
import { ListStyles } from '../../../DriverStyles';
import { ListPlaceholderStyles } from '../../../SharedStyles';

const VehicleList = (props) => {
  const { strings } = AppVehicleService;
  const { vehicles, fetchVehicles, vehicleLoading } = useContext(
    VehiclesContext
  );

  useEffect(() => {
    fetchVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteVehicle = async (vehicle) => {
    await AppVehicleService.deleteVehicle(vehicle);
    fetchVehicles();
  };

  return (
    <InteriorWrapper title={strings.myVehicleTitle} isInternal path='/charging'>
      {vehicleLoading && <LoadingSpinner />}
      <ComponentWrapper title={strings.myVehicleTitle}>
        {vehicles.length > 0 && (
          <ListStyles minHeight='500px'>
            {vehicles.map((vehicle) => (
              <VehicleCard
                key={vehicle.id}
                vehicle={vehicle}
                deleteVehicle={deleteVehicle}
              />
            ))}
          </ListStyles>
        )}
        {vehicles.length === 0 && (
          <ListPlaceholderStyles>
            <img src={VehiclePlaceholder} alt='No Vehicles' />
            <p>{strings.none}</p>
          </ListPlaceholderStyles>
        )}
      </ComponentWrapper>
      <Button
        driver
        hasMobile
        redirect='/add-vehicle'
        buttonText={strings.add}
      />
    </InteriorWrapper>
  );
};

export default VehicleList;
