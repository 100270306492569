import React from 'react';
import AppChargingProfilesService from './AppChargingProfilesService';

//layout
import { OuterWrapper, ComponentWrapper } from '../OperatorShared';
import Button from '../../shared/Buttons/Button';
import EnterpriseAdmin from '../EnterpriseAdmin';

//components
import ChargingProfileList from './ChargingProfileList/ChargingProfileList';

const ChargingProfiles = (props) => {
  const { strings } = AppChargingProfilesService;

  return (
    <OuterWrapper title={strings.pageTitle} internal path='/'>
      <EnterpriseAdmin>
        <ComponentWrapper title={strings.pageTitle} roles={[1]}>
          <ChargingProfileList />
        </ComponentWrapper>
        <Button
          cso
          hasMobile
          redirect='/subscription/charging-profiles/add'
          buttonText={strings.addButton}
          roles={[1]}
        />
      </EnterpriseAdmin>
    </OuterWrapper>
  );
};

export default ChargingProfiles;
