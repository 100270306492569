import React, { useState, useEffect } from 'react';
import {
  ButtonPlusStyles,
  DivStyles,
  InputStyles,
  TextStyles,
} from '../../SharedStyles';

import AppPaginationService from './AppPaginationService';

import './Pagination.scss';

const Pagination = ({
  currentPage,
  maxPage,
  next,
  prev,
  jump,
  totalData,
  currentCount,
  pageNumbers,
}) => {
  const { strings } = AppPaginationService;
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  const changePage = (e) => {
    let val = e.target.value;
    if (val > maxPage) {
      setPage(maxPage);
      jump(maxPage);
    } else if (val < 1) {
      setPage(1);
      jump(1);
    } else {
      setPage(val);
      jump(val);
    }
  };

  if (!pageNumbers || pageNumbers.length <= 1) {
    // don't display pager if there is only 1 page
    return (
      <DivStyles
        display='flex'
        alignItems='center'
        justifyContent='flex-end'
        flexWrap='nowrap'
        padding='0 10px 5px'
        borderBottom='1px solid #e7e7e7'>
        <TextStyles size='14px' color='#c2c2c2'>
          {strings.count} {currentCount + 1} {strings.of} {totalData}
        </TextStyles>
      </DivStyles>
    );
  } else {
    return (
      <DivStyles
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        flexWrap='nowrap'
        padding='0 10px 5px'
        borderBottom='1px solid #e7e7e7'>
        <DivStyles display='flex' alignItems='center'>
          <DivStyles
            className={currentPage === 1 ? 'disabled' : ''}
            margin='0 0 5px'>
            <ButtonPlusStyles
              padding='0 3px'
              textDecoration='underline'
              className='AppCso-next'
              disabled={currentPage === 1 ? true : false}
              onClick={() => jump(1)}>
              {strings.first}
            </ButtonPlusStyles>
          </DivStyles>
          <DivStyles
            className={currentPage === 1 ? 'disabled' : ''}
            margin='0 0 5px'>
            <ButtonPlusStyles
              padding='0 3px'
              textDecoration='underline'
              className='AppCso-next'
              disabled={currentPage === 1 ? true : false}
              onClick={prev}>
              {strings.previous}
            </ButtonPlusStyles>
          </DivStyles>

          <DivStyles display='flex' alignItems='center' margin='0 10px'>
            <InputStyles
              hideBoxes
              fullBorder='1px solid #cccccc'
              margin='0 3px 5px'
              padding='0'
              min='1'
              max={maxPage}
              textAlign='center'
              width='20px'
              value={page}
              type='number'
              name='pageNumber'
              onChange={changePage}
            />
            <TextStyles> of {maxPage}</TextStyles>
          </DivStyles>

          <DivStyles
            className={currentPage === maxPage ? 'disabled' : ''}
            margin='0 0 5px'>
            <ButtonPlusStyles
              padding='0 3px'
              textDecoration='underline'
              disabled={currentPage === maxPage ? true : false}
              className={
                currentPage === maxPage ? 'disabled AppCso-next' : 'AppCso-next'
              }
              onClick={next}>
              {strings.next}
            </ButtonPlusStyles>
          </DivStyles>
          <DivStyles
            className={currentPage === maxPage ? 'disabled' : ''}
            margin='0 0 5px'>
            <ButtonPlusStyles
              padding='0 3px'
              textDecoration='underline'
              disabled={currentPage === maxPage ? true : false}
              className='AppCso-next'
              onClick={() => jump(pageNumbers[pageNumbers.length - 1])}>
              {strings.last}
            </ButtonPlusStyles>
          </DivStyles>
        </DivStyles>
        <DivStyles>
          <TextStyles size='14px' color='#c2c2c2'>
            {strings.count} {currentCount + 1} {strings.of} {totalData}
          </TextStyles>
        </DivStyles>
      </DivStyles>
    );
  }
};

export default Pagination;
