import { useState, useEffect } from 'react';

//helpers
import { diff } from '../LeaveGuard/ObjDiffCheck';

export const useLeaveGuard = (obj, prevObj) => {
  const [object, setObject] = useState(obj);
  const [prevObject, setPrevObject] = useState(prevObj);
  const [isBlocking, setIsBlocking] = useState(false);

  console.log('leaveGuardHook objects', object, prevObject);

  //leaveGuard Effect
  useEffect(() => {
    let data = diff(object, prevObject);
    console.log('diff', data);
    if (Object.keys(data).length > 0) {
      setIsBlocking(true);
    } else {
      setIsBlocking(false);
    }
  }, [object, prevObject]);

  return [
    isBlocking,
    (boo) => {
      setIsBlocking(boo);
    },
    (updatedObj) => {
      setObject(updatedObj);
    },
    (updatedPrevObject) => {
      setPrevObject(updatedPrevObject);
    },
  ];
};
