import React, { useContext } from 'react';
import moment from 'moment';

//services
import AppChargingService from '../AppCharging/AppChargingService';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';

//global components
import PropertyContainer from '../../shared/PropertyContainer/PropertyContainer';

//styles
import {
  LedgerCardStyles,
  LedgerCardHeaderStyles,
  LedgerCardAddressStyles,
} from '../../DriverStyles';
import { PropertiesContentContainerStyles } from '../../SharedStyles';

//context
import { DriverContext } from '../../context/driver/getDriverContext';

//helpers
import { milesToKm, timeConvert } from '../../shared/Helpers';

const LedgerHistory = (props) => {
  const { strings } = AppChargingService;
  const { driver } = useContext(DriverContext);
  const {
    chargerName,
    chargeDurationMinutes,
    miles,
    totalPower,
    address,
    ledgerEntryType,
    amount,
    ledgerDate,
    duration,
    rate,
    stopSessionTime,
    rateType,
    fundsHistory,
    sessionHistory,
    otherHistory,
    description,
  } = props;

  const parseTimeInfo = (minutes) => {
    let time = '--';

    if (minutes) {
      let hrs = (minutes / 60) | 0;
      let mins = minutes % 60 | 0;

      return `${hrs}h ${mins}m`;
    }

    return time;
  };

  return (
    <LedgerCardStyles>
      <LedgerCardHeaderStyles titleColor={fundsHistory && '#42be42'}>
        <div className='Title'>
          {fundsHistory && strings.titleFundsAdded}
          {sessionHistory && chargerName}
          {otherHistory
            ? rateType === 'Card'
              ? strings.titleAccessCards
              : 'other'
            : ''}
        </div>
        <div className='Date'>{moment(ledgerDate).format('MMM DD YYYY')}</div>
      </LedgerCardHeaderStyles>
      <LedgerCardAddressStyles margin='3px 0'>
        {!otherHistory && address}
      </LedgerCardAddressStyles>
      {/* Idle Fee Ledgers */}
      {sessionHistory && ledgerEntryType === 3 && (
        <PropertiesContentContainerStyles flexWrap='nowrap' margin='0'>
          <PropertyContainer
            title={strings.scheduleStart}
            content={moment(stopSessionTime).format('h:mma')}
          />
          <PropertyContainer
            title={strings.idleTime}
            content={`${timeConvert(duration)}`}
          />
          <PropertyContainer
            title={strings.titleRate}
            content={`${AppPlatformService.currencyFormat(rate)} ${rateType ? `/${rateType}` : ''}`}
          />
          <PropertyContainer
            title={strings.titleFee}
            content={`${AppPlatformService.currencyFormat(amount)}`}
          />
        </PropertiesContentContainerStyles>
      )}
      {/* Charging Session Cost Ledgers */}
      {sessionHistory && ledgerEntryType === 2 && (
        <PropertiesContentContainerStyles flexWrap='nowrap' margin='0'>
          <PropertyContainer
            title={strings.titleTime}
            content={parseTimeInfo(chargeDurationMinutes)}
          />
          <PropertyContainer
            title={
              driver.unitOfMeasurement === 'Miles'
                ? strings.titleMiles
                : strings.titleKiloMeters
            }
            content={milesToKm(driver.unitOfMeasurement, miles)}
          />
          <PropertyContainer title={'kWh'} content={totalPower} />
          <PropertyContainer
            title={
              props.rateType === 'Owned'
                ? strings.titleUtility
                : strings.titleCost
            }
            content={
              Number.isNaN(parseFloat(amount))
                ? `${strings.titleFree}`
                : `${AppPlatformService.currencyFormat(amount)}`
            }
          />
        </PropertiesContentContainerStyles>
      )}
      {/* Funds added Ledgers */}
      {fundsHistory && ledgerEntryType === 1 && (
        <PropertiesContentContainerStyles flexWrap='nowrap' margin='0'>
          <PropertyContainer title={''} content={''} />
          <PropertyContainer title={''} content={''} />
          <PropertyContainer
            title={strings.titleTime}
            content={moment(ledgerDate).format('hh:mma')}
          />
          <PropertyContainer
            title={strings.titleAmt}
            content={`${AppPlatformService.currencyFormat(amount)}`}
          />
        </PropertiesContentContainerStyles>
      )}
      {/* Other Ledgers */}
      {otherHistory && ledgerEntryType === 2 && (
        <PropertiesContentContainerStyles flexWrap='nowrap' margin='0'>
          <PropertyContainer
            flexGrow='2'
            title={strings.titleDescription}
            content={description}
          />
          <PropertyContainer title='' content='' flex='0' />
          <PropertyContainer
            title={'Cost'}
            content={`${AppPlatformService.currencyFormat(rate)} ${rateType ? `/${rateType}` : ''}`}
          />
          <PropertyContainer
            title={strings.titleFee}
            content={`${AppPlatformService.currencyFormat(amount)}`}
          />
        </PropertiesContentContainerStyles>
      )}
    </LedgerCardStyles>
  );
};

export default LedgerHistory;
