import styled from 'styled-components';

import { defaultOrange } from '../defaultValues';

export const TextareaStyles = styled.textarea`
  height: ${({ height }) => height};
  font-size: ${({ size }) => (size ? size : '14px')};
  width: ${({ width }) => (width ? width : '100%')};
  flex: ${({ flexWidth }) => flexWidth};
  padding: ${({ padding }) => (padding ? padding : '8px 0px 5px 5px')};
  margin: ${({ margin }) => (margin ? margin : '0 0 5px')};
  color: #a5a5a5 !important;
  border: none;
  outline: none;
  border-bottom: ${({ noBorder }) => (noBorder ? 'none' : '1px solid #cccccc')};
  background: transparent;
  &[type='empty'] {
    visibility: hidden;
  }
  &:hover,
  &:active {
    border-bottom: 1px solid ${defaultOrange};
  }
  &[disabled] {
    color: lightgrey !important;
    cursor: not-allowed;
  }
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    flex: ${({ flexWidth }) => (flexWidth ? flexWidth : '100%')};
    width: ${({ mobileWidth }) => (mobileWidth ? mobileWidth : '100%')};
    margin: ${({ mobileMargin }) => mobileMargin};
    font-size: ${({ mobileSize }) => mobileSize};
    &[type='empty'] {
      display: none;
    }
  }
`;

export const TextareaRoundStyles = styled.textarea`
  flex: ${({ flex }) => flex};
  width: ${({ width }) => (width ? width : '100%')};
  border: 1px solid #e7e7e7;
  border-radius: 15px;
  font-weight: 300;
  padding: 8px 12px;
  color: #a5a5a5;
  height: ${({ height }) => height};
  font-size: ${({ size }) => size};
  &[type='empty'] {
    visibility: hidden;
  }
  &:hover,
  &:focus,
  &:active {
    border: 1px solid ${defaultOrange};
  }
`;
