import styled from 'styled-components';

export const FormStyles = styled.form`
  display: ${({ display }) => (display ? display : 'flex')};
  flex-wrap: ${({ flexWrap }) => (flexWrap ? flexWrap : 'nowrap')};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'center'};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding};
`;

export const FormContainerStyles = styled.div`
  height: ${({ height }) => height};
  display: ${({ display }) => (display ? display : 'flex')};
  flex-wrap: ${({ flexWrap }) => (flexWrap ? flexWrap : 'wrap')};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
  width: ${({ width }) => width};
  padding: ${({ padding }) => (padding ? padding : '10px 10px 15px')};
  margin: ${({ margin }) => (margin ? margin : '0 auto')};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? '1px solid #e7e7e7' : 'none'};
  text-align: ${({ align }) => align};
  h3 {
    padding: ${({ headersPadding }) =>
      headersPadding ? headersPadding : '0 10px'};
    font-weight: 600;
    font-size: 14px;
    text-align: left;
  }
`;

export const FormRowStyles = styled.div`
  flex: 100% 1;
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? '1px solid #e7e7e7' : 'none'};
  display: ${({ display }) => (display ? display : 'flex')};
  width: ${({ width }) => width};
  flex-wrap: nowrap;
  margin: ${({ margin }) => (margin ? margin : '0 0 10px')};
  padding: ${({ padding }) => padding};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  line-height: 20px;
  font-size: ${({ size }) => size};

  @media screen and (max-width: ${({ mobileMedia, theme }) =>
      mobileMedia ? mobileMedia : theme.defaultMediaQuery}) {
    flex-wrap: wrap;
    margin: 0px;
    align-items: ${({ alignItems }) => alignItems};
  }
  .View {
    margin: 0 0 0 25px;
    color: #8999ae;
    font-size: 18px;
    cursor: pointer;
  }
  .Icon {
    color: #8497b6;
    margin: 0 10px 0 0;
    font-size: 25px;
    line-height: 35px;
  }
`;

export const FormSmallStyles = styled.form`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding};
  width: ${({ width }) => width};
  margin: ${({ margin }) => (margin ? margin : '10px auto')};
  @media screen and (max-width: ${({ mobileMedia, theme }) =>
      mobileMedia ? mobileMedia : theme.defaultMediaQuery}) {
    width: ${({ mobileWidth }) => mobileWidth};
  }
  button {
    margin: ${({ buttonMargin }) =>
      buttonMargin ? buttonMargin : '0 20px 0 0'};
    outline: none;
    border: none;
    background: none;
    svg {
      color: ${({ svgColor }) => (svgColor ? svgColor : '#333')};
      cursor: pointer;
    }
  }
`;

export const FormLeftStyles = styled.div`
  display: ${({ display }) => display};
  align-items: center;

  .Title {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
      font-size: 14px;
    }
  }
  svg {
    color: #8497b6;
    margin: ${({ svgMargin }) => (svgMargin ? svgMargin : '0 10px 0 0')};
    font-size: 25px;
    line-height: 35px;
  }
`;

export const FormRightStyles = styled.div`
  margin: 0 20px 0 0;
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    margin: 0;
  }
`;

export const FormLabelStyles = styled.div`
  font-family: Raleway, sans-serif;
  display: ${({ display }) => display};
  flex: ${({ flex }) => flex};
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? borderBottom : '1px solid #cccccc'};
  &:active,
  &:focus,
  &:hover {
    border-bottom: ${({ borderBottom }) =>
      borderBottom ? borderBottom : '1px solid #ff8f45'};
  }
  > div {
    padding: ${({ padding }) => (padding ? padding : '8px 0 5px 5px')};
  }
`;

export const LabelStyles = styled.label`
  display: ${({ display }) => display};
  flex: ${({ flex }) => flex};
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ maxWidth }) => maxWidth};
  margin: ${({ margin }) => margin};
  font-size: ${({ size }) => size};
  color: ${({ color }) => color};
  order: ${({ order }) => order};
`;
