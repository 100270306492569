import React, { useContext } from 'react';

import AccessDeniedComponent from '../shared/AppAccessDenied/AccessDeniedComponent';

import { CsoAccountContext } from '../context/operator/getCsoAccount';

const EnterpriseAdmin = props => {
  const { account, currentUser } = useContext(CsoAccountContext);

  if (props.allowTech) {
    return (
      <>
        {account &&
        (account.subscriptionLevel === 3 &&
          currentUser &&
          currentUser.role !== 4) ? (
          <>{props.children}</>
        ) : !props.noMessage ? (
          <AccessDeniedComponent enterprise />
        ) : null}
      </>
    );
  }
  if (props.allowCustomer) {
    return (
      <>
        {account &&
        (account.subscriptionLevel === 3 &&
          currentUser &&
          currentUser.role !== 3) ? (
          <>{props.children}</>
        ) : !props.noMessage ? (
          <AccessDeniedComponent enterprise />
        ) : null}
      </>
    );
  }
  if (!props.allowCustomer || !props.allowTech) {
    return (
      <>
        {account &&
        (account.subscriptionLevel === 3 &&
          currentUser &&
          currentUser.role !== 3) &&
        (account.subscriptionLevel === 3 &&
          currentUser &&
          currentUser.role !== 4) ? (
          <>{props.children}</>
        ) : !props.noMessage ? (
          <AccessDeniedComponent enterprise />
        ) : null}
      </>
    );
  }
};

export default EnterpriseAdmin;
