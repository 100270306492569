import React, { useState, useEffect } from 'react';
import { FaRegWindowClose } from 'react-icons/fa';
import AppChargingService from '../AppChargingService';

const AppChargingConnection = (props) => {
  const { strings } = AppChargingService;

  const {
    handleModal,
    handlePendingChargingResponse,
    errorMessage,
    chargingMessage,
  } = props;
  const [currentMessage, setCurrentMessage] = useState({});
  const [prevStatus, setPrevStatus] = useState({});

  useEffect(() => {
    if (Object.keys(props.chargerStatus).length > 0) {
      setPrevStatus(props.chargerStatus.connectorStatuses[props.port - 1]);
    }

    if (Object.keys(chargingMessage).length > 0) {
      setCurrentMessage(chargingMessage);
    }
    if (Object.keys(errorMessage).length > 0) {
      setCurrentMessage(errorMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargingMessage, errorMessage, prevStatus, props]);

  const closeModal = () => {
    handleModal();
  };

  const handleConfirm = () => {
    // props.handleStartStop(props.selectedConnector);
    closeModal();
  };

  useEffect(() => {
    if (
      props.chargerStatus.connectorStatuses[props.port - 1].status === 3 &&
      props.chargerStatus.connectorStatuses[props.port - 1].isCurrentUser
    ) {
      handlePendingChargingResponse(false);

      handleConfirm();
    } else if (
      prevStatus &&
      prevStatus.status === 2 &&
      props.chargerStatus.connectorStatuses[props.port - 1].status === 1
    ) {
      handlePendingChargingResponse(false);
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chargerStatus]);

  return (
    <div className='Modal-Display-Driver'>
      <div className='Modal'>
        <div className='Modal-Header'>
          <div>{currentMessage.header}</div>
          <div className='Modal-Close'>
            <FaRegWindowClose onClick={closeModal} />
          </div>
        </div>
        <div className='Modal-Content'>
          <p className='Modal-Content-Text'>{currentMessage.body}</p>
          {currentMessage.header === strings.plugConnector && (
            <>
              <div className='Modal-Image'>
                <img
                  src={require('../../../../assets/images/ConnectPlug.png').default}
                  alt={currentMessage.header}
                />
              </div>
              <p className='Modal-Content-Text'>{currentMessage.close}</p>
            </>
          )}
        </div>
        <div className='Modal-Button'>
          <button className='app-button' onClick={closeModal}>
            {currentMessage.cancel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppChargingConnection;
