import styled from 'styled-components';

export const TableContentStyles = styled.div`
  padding: 10px;
  border-top: 1px solid #e7e7e7;
  line-height: 21px;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  p {
    margin: 0 0 15px;
  }
  .Sub-Section {
    text-align: left;
    padding: 0 10px 10px;
    color: #8f8d8d;
    font-size: 13px;
    border-bottom: 1px solid #e7e7e7;
  }
`;
