import { useState, useEffect } from 'react';

//service
import ParamsService from '../../context/paramsState/ParamsService';

export const useParamsFetch = (request) => {
  const [response, setResponse] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const { params, type, throwError } = request;

  useEffect(() => {
    const FetchData = async () => {
      setLoading(true);
      try {
        if (Object.keys(params).length === 0) {
          throw throwError;
        }
        const res = await ParamsService[type](params);

        if (res) {
          let d = {};
          res.forEach((r) => {
            switch (r.Value) {
              case 'true':
                d = { ...d, [r.Key]: true };
                break;
              case 'false':
                d = { ...d, [r.Key]: false };
                break;
              default:
                d = { ...d, [r.Key]: r.Value };
            }
          });

          setResponse(d);
          setError(null);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    };
    FetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.type]);

  return { response, error, loading };
};
