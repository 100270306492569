import React, { useState, useEffect } from 'react';
import {
  FaRegWindowClose,
  FaPlus,
  FaRegTimesCircle,
  FaExclamationTriangle,
} from 'react-icons/fa';

//services
import AppMeterGroupsService from '../../AppMeterGroupsService';

//global component
import ModalButton from '../../../../shared/Buttons/ModalButton';

//styles
import {
  InputStyles,
  FormRowStyles,
  InputContainerStyles,
  ButtonContainerStyles,
  ButtonPlusStyles,
  FormContainerStyles,
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  ModalNoteStyles,
} from '../../../../SharedStyles';

const CircuitsModal = (props) => {
  const { strings } = AppMeterGroupsService;
  const { handleCloseModal, handleDataChange } = props;
  const [circuits, setCircuits] = useState([]);
  const [ampCount, setAmpCount] = useState(0);
  const [warning, setWarning] = useState(false);
  const [newCircuit, setNewCircuit] = useState({
    id: Math.random().toString(36).substring(2),
    chargingStationIds: [],
    chargingStationConnectorIds: [],
    maxAmperage: 0,
    name: '',
    portCount: 0,
  });

  useEffect(() => {
    setCircuits(props.circuits);
  }, [props.circuits]);

  useEffect(() => {
    setAmpCount(0);
    circuits.forEach((cir) => {
      setAmpCount((c) => cir.maxAmperage + c);
    });
  }, [circuits]);

  useEffect(() => {
    if (ampCount > props.meterGroup.maxAmps) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  }, [ampCount, props.meterGroup.maxAmps]);

  const closeModal = () => {
    handleCloseModal();
  };

  const handleChange = (e) => {
    setNewCircuit({ ...newCircuit, [e.target.name]: e.target.value });
  };

  const handleSaveInfo = (e) => {
    e.preventDefault();
    handleDataChange('circuits', circuits);
    handleCloseModal();
  };

  const addCircuit = async (e) => {
    e.preventDefault();
    if (Object.keys(newCircuit).length > 0) {
      let updatedCircuit = newCircuit;
      updatedCircuit.maxAmperage = parseInt(newCircuit.maxAmperage);
      if (!props.addCircuits) {
        const createCircuit = {
          meterGroupId: props.meterGroup.meterGroupId,
          newCircuit: updatedCircuit,
        };
        let accountData = await AppMeterGroupsService.createCircuit(
          createCircuit
        );
        // setAccount((a) => ({ ...a, accountData }));
        let newCircuits = [];
        accountData.locations
          .filter((loc) => loc.id === props.meterGroup.locationId)
          .forEach((singleLoc) => {
            singleLoc.meterGroups
              .filter((mg) => {
                return mg.meterGroupId === props.meterGroup.meterGroupId;
              })
              .forEach((singleMg) => {
                newCircuits = singleMg.circuits;
              });
          });
        handleDataChange('circuits', newCircuits);
      } else {
        let newArrCircuits = circuits.map((cir, i) => {
          cir.id = Math.random().toString(36).substring(2);
          return cir;
        });
        newArrCircuits.push(updatedCircuit);
        setCircuits(newArrCircuits);
      }

      setNewCircuit({
        ...newCircuit,
        name: '',
        maxAmperage: '',
      });
    }
  };

  const deleteCircuit = async (id) => {
    let removedCircuit = circuits.filter((cir) => {
      return cir.id !== id;
    });
    setCircuits(removedCircuit);
    await AppMeterGroupsService.deleteCircuit(id);
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.circuitsTitle}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0'>
            <p>{strings.circuitsContent}</p>
          </ModalItemStyles>
          {/* Section */}
          <ModalItemStyles pSize='12px' padding='0'>
            {circuits.map((circuit, index) => {
              return (
                <div className='circuit-amp-table' key={index}>
                  <p className='circuit-amp-name'>{circuit.name}</p>
                  <p className='circuit-amp-max'>
                    {circuit.maxAmperage} amps (
                    {circuit.chargingStationIds.length})
                  </p>
                  {circuit.chargingStationIds.length === 0 ? (
                    <p
                      onClick={() => deleteCircuit(circuit.id)}
                      className='danger-text'>
                      <FaRegTimesCircle />
                    </p>
                  ) : (
                    <p className='disabled-text'>
                      <FaRegTimesCircle />
                    </p>
                  )}
                </div>
              );
            })}
          </ModalItemStyles>
          {/* Section */}
          <form onSubmit={addCircuit} form='circuitForm'>
            <FormContainerStyles width='80%' margin='0 auto' padding='0'>
              <FormRowStyles alignItems='center'>
                <InputContainerStyles flexWidth='1' margin='0 10px 0 0'>
                  <InputStyles
                    size='12px'
                    padding='0 0 5px'
                    type='text'
                    onChange={handleChange}
                    name='name'
                    required
                    value={newCircuit.name || ''}
                    placeholder={strings.circuitsNamePlaceholder}
                  />
                </InputContainerStyles>
                <InputContainerStyles flexWidth='1' margin='0 10px 0 0'>
                  <InputStyles
                    size='12px'
                    padding='0 0 5px'
                    type='number'
                    min={1}
                    onChange={handleChange}
                    required
                    name='maxAmperage'
                    value={newCircuit.maxAmperage || ''}
                    placeholder={strings.circuitsPlaceholder}
                  />
                </InputContainerStyles>
                <ButtonContainerStyles flexWidth='1' align='right' margin='0'>
                  <ButtonPlusStyles
                    type='submit'
                    id='circuitForm'
                    size='18px'
                    svgMargin='0 10px 0 0'>
                    <FaPlus />
                  </ButtonPlusStyles>
                </ButtonContainerStyles>
              </FormRowStyles>
            </FormContainerStyles>
          </form>
          {/* Note Section */}
          <ModalNoteStyles color='#000'>
            <p className='Warning-modal'>
              <span className='Warning-bold'>
                <FaExclamationTriangle />
                {strings.warning}:{' '}
              </span>
              {warning ? strings.circuitAmpReached : strings.circuitsWarning}
            </p>
          </ModalNoteStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={handleSaveInfo}
          buttonText={strings.save}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default CircuitsModal;
