import React, { useState, useEffect } from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppMembershipsService from '../AppMembershipsService';

//global components
import ModalButton from '../../../shared/Buttons/ModalButton';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  TextStyles,
  TitleStyles,
} from '../../../SharedStyles';

const MembershipsModal = (props) => {
  const [discountId, setDiscountId] = useState({});

  useEffect(() => {
    let filterDiscounts = props.discounts.filter((discount) => {
      return discount.id === props.membership.discountScheduleId;
    });
    setDiscountId(filterDiscounts[0]);
  }, [props.discounts, props.membership.discountScheduleId]);

  const { strings } = AppMembershipsService;

  const closeModal = () => {
    props.handleCloseModal();
  };

  const handleSaveInfo = (e) => {
    e.preventDefault();
    closeModal();
  };

  return (
    <>
      {Object.keys(discountId).length > 0 && (
        <ModalStyles>
          <ModalContainerStyles>
            {/* Header Bar */}
            <ModalHeaderStyles>
              <h2 className='Title'>{props.title}</h2>
              <FaRegWindowClose className='Close' onClick={closeModal} />
            </ModalHeaderStyles>
            {/* Content Section */}
            <ModalContentStyles>
              {/* Section */}
              <ModalItemStyles align='left' padding='0'>
                <TitleStyles size='14px' weight='600'>
                  {discountId.name}
                </TitleStyles>
              </ModalItemStyles>
              <ModalItemStyles
                align='left'
                padding='0'
                display='flex'
                alignItems='center'
                textMargin='0 30px 0 0'>
                <TextStyles color='#28456a' size='12px'>
                  {strings.discount}
                </TextStyles>
                <TextStyles color='#8f8d8d' size='12px'>
                  {discountId.discountPercentage}%
                </TextStyles>
              </ModalItemStyles>
              {/* Section */}
            </ModalContentStyles>
            {/* Button Component for Modals*/}
            <ModalButton
              containerMargin='10px 10px 0'
              containerAlign='right'
              buttonPadding='10px'
              buttonMargin='0'
              handleClick={handleSaveInfo}
              buttonText={props.defaultModal ? 'Ok' : 'Message'}
            />
          </ModalContainerStyles>
        </ModalStyles>
      )}
    </>
  );
};

export default MembershipsModal;
