import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';

//layout
import { OuterWrapper, ComponentWrapper } from '../../OperatorShared';
import Button from '../../../shared/Buttons/Button';

//services
import AppMembershipsService from '../AppMembershipsService';

//components
import MembershipHeader from '../MembershipsForms/MembershipHeader';

//global components
import { LoadingSpinner } from '../../../shared';
import CsoNotFound from '../../../shared/AppNotFound/CsoNotFound';
import SmallBizAdmin from '../../SmallBizAdmin';

//context
import { CsoMembershipContext } from '../../../context/operator/getCsoMembership';
import {
  TitleStyles,
  JumboTronHeaderStyles,
  TextStyles,
} from '../../../SharedStyles';

const MembershipToken = (props) => {
  const { strings } = AppMembershipsService;
  const { allMemberships } = useContext(CsoMembershipContext);
  const [loading, setLoading] = useState(true);
  const [membership, setMembership] = useState({});
  const [token, setToken] = useState({
    display: false,
  });
  const [notFound, setNotFound] = useState({
    display: false,
    message: '',
  });

  useEffect(() => {
    let singleMembership = allMemberships.filter((membership) => {
      return membership.id === props.match.params.id;
    });

    if (singleMembership.length !== 0) {
      setMembership(singleMembership[0]);
      setLoading(false);
    }
    if (singleMembership.length === 0 && !loading) {
      setLoading(false);
      setNotFound({
        display: true,
        message: 'Could not find a membership with that Id',
      });
    }
  }, [allMemberships, props.match.params.id, loading]);

  useEffect(() => {
    const fetchToken = async () => {
      let tokenResult = await AppMembershipsService.getMembershipTokenById(
        props.match.params.id
      );
      if (tokenResult !== null) {
        setToken({ display: true, ...tokenResult });
      }
    };
    fetchToken();
  }, [props.match.params.id]);

  const getToken = async () => {
    setLoading(true);
    let tokenResult = await AppMembershipsService.createMembershipToken(
      membership.id
    );
    if (tokenResult !== null) {
      setToken({ display: true, ...tokenResult });
    }
    setLoading(false);
  };

  if (notFound.display) {
    return <CsoNotFound message={notFound.message} />;
  }

  return (
    <OuterWrapper
      title={strings.tokenTitle}
      internal
      path='/subscription/memberships'>
      {loading && <LoadingSpinner />}
      {/* =====================Enterprise and Small Biz Only===================================== */}
      <SmallBizAdmin allowCustomer>
        <ComponentWrapper title={strings.tokenTitle}>
          {Object.keys(membership).length > 0 && (
            <MembershipHeader
              noCode
              strings={strings}
              membership={membership}
            />
          )}
          <JumboTronHeaderStyles borderBottom='none' margin='25px 0 0 '>
            <TitleStyles size='25px' weight='400'>
              {strings.tokenCode}
            </TitleStyles>
            {token.display && (
              <>
                <TitleStyles size='40px' weight='400' margin='0 0 20px'>
                  {token.token}
                </TitleStyles>
                <TextStyles size='12px'>
                  Expires:{' '}
                  {moment(token.expiration).format('MM/DD/YYYY h:mm a')}
                </TextStyles>
              </>
            )}
            <Button
              cso
              margin='20px 0 25px'
              disabled={token.token}
              onclick={getToken}
              buttonText={strings.tokenGenerate}
              roles={[1, 2, 3, 4]}
            />
            <TextStyles size='12px' align='left' padding='0 20%'>
              <strong>{strings.tokenNote}</strong>: {strings.tokenNoteContent}
            </TextStyles>
          </JumboTronHeaderStyles>
        </ComponentWrapper>
      </SmallBizAdmin>
      {/* ========================================================================= */}
    </OuterWrapper>
  );
};

export default MembershipToken;
