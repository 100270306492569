import React, { useState, useEffect } from 'react';

const ChargingBlocked = ({ connectors }) => {
  const [isBlocked, setIsBlocked] = useState(false);
  const [blockedReason, setBlockedReason] = useState('');

  useEffect(() => {
    if (connectors.connectorStatuses.length === 1) {
      connectors.connectorStatuses.forEach(c => {
        switch (true) {
          case c.membershipOnlyBlocked:
            setBlockedReason(c.membershipOnlyBlockedReason);
            break;
          case c.waitQueueBlocked:
            setBlockedReason(c.membershipOnlyBlockedReason);
            break;
          case c.reservationBlocked:
            setBlockedReason(c.membershipOnlyBlockedReason);
            break;
          default:
            setIsBlocked(false);
            setBlockedReason('');
        }
      });
    }
    if (connectors.connectorStatuses.length > 1) {
      let blockedConnectors = connectors.connectorStatuses.every(
        c =>
          c.membershipOnlyBlocked || c.reservationBlocked || c.waitQueueBlocked
      );

      if (blockedConnectors) {
        setIsBlocked(true);
        connectors.connectorStatuses.forEach(c => {
          switch (true) {
            case c.membershipOnlyBlocked:
              setBlockedReason(c.membershipOnlyBlockedReason);
              break;
            case c.waitQueueBlocked:
              setBlockedReason(c.membershipOnlyBlockedReason);
              break;
            case c.reservationBlocked:
              setBlockedReason(c.membershipOnlyBlockedReason);
              break;
            default:
              setIsBlocked(false);
              setBlockedReason('');
          }
        });
      } else {
        setIsBlocked(false);
        setBlockedReason('');
      }
    }
  }, [connectors]);

  if (isBlocked) {
    return (
      <div className='Auto-Session'>
        <p>
          <em className='danger-text'>{blockedReason}</em>
        </p>
      </div>
    );
  }
  return null;
};

export default ChargingBlocked;
