import React, { useState, useEffect, useContext } from 'react';

//layout
import { InteriorWrapper, ComponentWrapper } from '../shared';
import Button from '../../shared/Buttons/Button';

//services
import AppDriverSettingsService from './AppDriverSettingsService';

//components
import LoadingSpinner from '../../shared/Loading/LoadingSpinner';

//styles
import {
  DivStyles,
  FormContainerStyles,
  FormRowStyles,
  ImageMediumStyles,
  InputStyles,
  SelectStyles,
  TextStyles,
} from '../../SharedStyles';

//image
import tapNchargeImage from '../../../assets/images/Tap-n-Charge.jpg';

//context
import { DriverContext } from '../../context/driver/getDriverContext';
import NFCSetupModal from './Modals/NFCSetupModal';
import DevModeOnly from '../../shared/Tools/DevModeOnly';

const Settings = (props) => {
  const { strings } = AppDriverSettingsService;
  const { driver, setDriver, postDriver } = useContext(DriverContext);
  const [loading, setLoading] = useState(false);
  const [prevLanguage] = useState(localStorage.getItem('driver-language'));
  const [showNFCModal, setShowNFCModal] = useState(false);

  const languages = AppDriverSettingsService.getSupportedLanguages();

  const handleChange = (e) => {
    localStorage.setItem('driver-language', e.target.value);
    setDriver({
      ...driver,
      [e.target.name]: e.target.value,
    });
  };

  function populateLanguageOptions() {
    return languages.map((item) => {
      return (
        <option key={item.language} value={item.language}>
          {item.description}
        </option>
      );
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let res = await postDriver(prevLanguage);
    if (res) {
      setLoading(false);
    }
  };

  return (
    <InteriorWrapper
      title={strings.settingsTitle}
      isInternal
      myForm='myForm'
      path='/charging'>
      {showNFCModal && (
        <NFCSetupModal
          handleCloseModal={() => setShowNFCModal(false)}
          tapNchargeImage={tapNchargeImage}
        />
      )}
      {loading && <LoadingSpinner />}
      <ComponentWrapper title={strings.settingsTitle}>
        <form id='myForm' onSubmit={handleSubmit}>
          <FormContainerStyles headersPadding='0' borderBottom>
            <h3>{strings.languageTitle}</h3>
            <FormRowStyles>
              <SelectStyles
                name='language'
                onChange={handleChange}
                value={driver.language}>
                {populateLanguageOptions()}
              </SelectStyles>
            </FormRowStyles>
            <h3>{strings.unitOfMeasurementTitle}</h3>
            <FormRowStyles>
              <SelectStyles
                name='unitOfMeasurement'
                onChange={handleChange}
                value={driver.unitOfMeasurement || strings.milesSelection}>
                <option value={strings.milesSelection}>
                  {strings.milesSelection}
                </option>
                <option value={strings.kilometerSelection}>
                  {strings.kilometerSelection}
                </option>
              </SelectStyles>
            </FormRowStyles>
            <h3>{strings.utilityTitle}</h3>
            <FormRowStyles>
              <InputStyles
                onChange={handleChange}
                type='number'
                step='0.01'
                name='homeChargerRate'
                value={driver.homeChargerRate || ''}
                placeholder={strings.utilityPlaceholder}
              />
            </FormRowStyles>
          </FormContainerStyles>
        </form>
        <DevModeOnly>
          <FormContainerStyles headersPadding='0'>
            <h3>{strings.tapChargeRegistration}</h3>
            <TextStyles size='14px'>{strings.tapChargeDescription}</TextStyles>
            <DivStyles align='center' margin='0 auto 20px'>
              <ImageMediumStyles
                maxWidth='85px'
                src={tapNchargeImage}
                alt={strings.tapChargeRegistration}
              />
            </DivStyles>
            <TextStyles size='14px' color='#2c456c' padding='0 10%'>
              <strong>{strings.note}</strong> {strings.tapChargerNote}
            </TextStyles>
            <FormRowStyles>
              <Button
                driver
                inverse
                onclick={() => setShowNFCModal(true)}
                buttonText={strings.registerDevice}
              />
            </FormRowStyles>
          </FormContainerStyles>
        </DevModeOnly>
      </ComponentWrapper>
      <Button driver noMobile formSubmit buttonText={strings.save} />
    </InteriorWrapper>
  );
};

export default Settings;
