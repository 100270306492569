import styled from 'styled-components';

import spinner from '../../../assets/images/connectionSpinner.gif';

export const ImageContainerFitStyles = styled.div`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  @media screen and (max-width: ${({ theme, removeMobileImg }) =>
      removeMobileImg ? removeMobileImg : theme.defaultMediaQuery}) {
    display: ${({ removeMobileImg }) => removeMobileImg && 'none'};
  }
`;

export const ImageMediumStyles = styled.img`
  display: ${({ display }) => display};
  object-fit: ${({ objectFit }) => objectFit};
  border-radius: ${({ borderRadius }) => borderRadius};
  overflow: ${({ borderRadius }) => borderRadius && 'hidden'};
  border: ${({ border }) => border};
  height: ${({ height }) => height};
  max-width: ${({ maxWidth }) => maxWidth};
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  @media screen and (max-width: ${({ theme, removeMobileImg }) =>
      removeMobileImg ? removeMobileImg : theme.defaultMediaQuery}) {
    display: ${({ removeMobileImg }) => removeMobileImg && 'none'};
  }
`;

export const ImageThumbnailStyles = styled.img`
  object-fit: ${({ objectFit }) => objectFit};
  border-radius: ${({ borderRadius }) => borderRadius};
  overflow: ${({ borderRadius }) => borderRadius && 'hidden'};
  border: ${({ border }) => border};
  height: ${({ height }) => height};
  transform: ${({ transform }) => transform};
  max-width: ${({ maxWidth }) => maxWidth};
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  cursor: ${({ cursor }) => cursor};
  z-index: ${({ zIndex }) => zIndex};
  border: ${({ border }) => border};
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    display: ${({ removeMobileImg }) => removeMobileImg && 'none'};
    max-width: ${({ mobileMaxWidth }) => mobileMaxWidth};
  }
`;

export const ImageConnectionSpinnerStyles = styled.div`
  width: 150px;
  height: 150px;
  margin-top: 25px;
  margin-right: auto;
  margin-left: auto;
  background-image: url(${spinner});
  background-position: 50% 50%;
  background-size: 300%;
  background-repeat: no-repeat;
  /* -webkit-transition: opacity 200ms ease; */
  transition: opacity 200ms ease;
`;
