import React, { useState, useContext, useEffect } from 'react';
import { injectStripe } from 'react-stripe-elements';

//services
import AppPaymentsService from '../AppPaymentsService';

//components
import CreditCardDisplay from './CreditCardDisplay';
import NewCreditCard from './NewCreditCard';

//global components
import { LoadingSpinner } from '../../../shared';
import Modal from '../../../shared/Modals/Modal';

//modals
import PaymentModal from '../PaymentModal';

//context for driver account
import { DriverContext } from '../../../context/driver/getDriverContext';
import { ErrorContext } from '../../../context/shared/ErrorContext';

//styles
import { PaymentCreditCardStyles, CenterStyles } from '../../../SharedStyles';

const PaymentsOptions = props => {
  const { strings } = props;
  const { driver, setDriver } = useContext(DriverContext);
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [editCard, setEditCard] = useState(false);
  const [customerData, setCustomerData] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  
  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    //creating stripe token for card
    let { token, error } = await props.stripe.createToken({});
    //making the call to server - first time card add
    if (error) {
      setLoading(false);
      setError({
        display: true,
        title: strings.missingTitle,
        message: strings.missingInfo,
        styles: 'driver'
      });
      return;
    }

    try {
      if (driver.customerPaymentId) {
        //returns driver object. Not needed since we are using state
        let updatedDriver = await AppPaymentsService.changeCard(token.id);
        setDriver(prevDriver => ({ ...prevDriver, ...updatedDriver }));
        setLoading(false);
        //opens success modal
        setConfirm(true);
      } else {
        //returns driver object. Not needed since we are using state
        let updatedDriver = await AppPaymentsService.createPaymentAccount(
          token.id
        );
        setDriver(prevDriver => ({ ...prevDriver, ...updatedDriver }));
        setLoading(false);
        //opens success modal
        setConfirm(true);
      }
    } catch (e) {
      setLoading(false);
      setError({
        display: true,
        title: strings.missingTitle,
        message: e.message,
        styles: 'driver'
      });
    }
  };

  
  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      //returns driver object. Not needed since we are using state
      const updatedDriver = await AppPaymentsService.deletePaymentMethod();

      setDriver(prevDriver => ({ ...prevDriver, ...updatedDriver }));
      setCustomerData({});
      setEditCard(false);

      //fetchDriver();

      toggleDelete();

      setLoading(false);
    } catch (err) {
      setLoading(false);

      setError({
        display: true,
        title: 'Something went wrong',
        message: err.response ? 
          err.response.data === '' ? err.message : err.response.data
          : err.message,
        styles: 'driver',
      });
    }
  };

  useEffect(() => {
    if (driver.customerPaymentId) {
      setLoading(true);
      const fetchCustomerInfo = async () => {
        let customerInfo = await AppPaymentsService.getPaymentAccountInfo();
        setCustomerData(customerInfo);
        setLoading(false);
      };
      fetchCustomerInfo();
    }
  }, [driver]);

  useEffect(() => {
    if (driver.customerPaymentId) {
      setEditCard(true);
    }
  }, [confirm, driver]);

  const closeModal = () => {
    setConfirm(false);
  };

  const toggleEditCard = () => {
    setEditCard(false);
  };

  const handleChangeCard = () => {
    setEditCard(true);
  };

  const toggleDelete = () => {
    setDeleteModal(!deleteModal);
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      {confirm && (
        <PaymentModal
          closeModal={closeModal}
          handlesuccess={handleChangeCard}
          title={strings.success}
          content={strings.ccAddedOnFile}
          success
        />
      )}
      {deleteModal && (
      <Modal
          title={strings.deletePaymnetMethodTitle}
          message={strings.deletePaymnetMethodMessage}
          cancelButton='No'
          confirmButton='Yes'
          onCancel={() => toggleDelete()}
          onConfirm={handleDelete}
        />
    )}
      <PaymentCreditCardStyles>
        {editCard ? (
          <CreditCardDisplay
            customerData={customerData}
            toggleEditCard={toggleEditCard}
            removeCard={toggleDelete}
            strings={strings}
          />
        ) : (
          <NewCreditCard strings={strings} handleSubmit={handleSubmit} />
        )}
      </PaymentCreditCardStyles>
      <CenterStyles><a href={strings.termsUrl}>{strings.terms}</a> . <a href={strings.privacyUrl}>{strings.privacy}</a></CenterStyles>
    </>
  );
};

export default injectStripe(PaymentsOptions);
