import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';

//services
import AppVehicleService from '../AppVehicleService';

//Components - Modals
import DeleteModal from '../Modals/DeleteModal';

//Images
import DefaultImage from '../../../../assets/images/MyVehiclePlaceholder300x150.png';

//helpers
import { milesToKm } from '../../../shared/Helpers';

//context
import { DriverContext } from '../../../context/driver/getDriverContext';

//styles
import { ListCardStyles, ListActionStyles } from '../../../DriverStyles';

const VehicleCard = (props) => {
  const { strings } = AppVehicleService;
  const { driver } = useContext(DriverContext);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleDelete = () => {
    props.deleteVehicle(props.vehicle);
  };

  const toggleModal = () => {
    setDeleteModal(!deleteModal);
  };

  return (
    <ListCardStyles flexWrap='nowrap' mobileMedia='500px'>
      {deleteModal && (
        <DeleteModal deleteVehicle={handleDelete} closeModal={toggleModal} />
      )}
      <Link to={`/vehicle-list/${props.vehicle.id}`} title={strings.editTitle}>
        <div className='Image'>
          <img
            src={
              props.vehicle.imagePath ? props.vehicle.imagePath : DefaultImage
            }
            alt={props.vehicle.nickName}
          />
        </div>
        <div className='Content'>
          <span className='Span'>
            <h5 className='Label'>{strings.name}</h5>
            <p className='Data'>{props.vehicle.nickName}</p>
          </span>
          <span className='Span'>
            <h5 className='Label'>{strings.model}</h5>
            <p className='Data'>
              {props.vehicle.make} {props.vehicle.model}
            </p>
          </span>
          <span className='Span'>
            <h5 className='Label'>{strings.battery}</h5>
            <p className='Data'>
              {props.vehicle.batteryCapacity} {strings.capacity}
            </p>
          </span>
          <span className='Span'>
            <h5 className='Label'>{strings.range}</h5>
            <p className='Data'>
              {milesToKm(
                driver.unitOfMeasurement,
                props.vehicle.allElectricRange
              )}
            </p>
          </span>
        </div>
      </Link>
      <ListActionStyles mobileMedia='500px'>
        <div className='Action-Item'>
          <Link
            to={`/vehicle-list/${props.vehicle.id}`}
            title={strings.editTitle}>
            <FaEdit />
          </Link>
        </div>
        {/* <div className='Action-Item'>
          <Link
            to={`/vehicle-list/share/${props.vehicle.id}`}
            title={strings.shareTitle}>
            <FaShareAltSquare />
          </Link>
        </div> */}
        <div className='Action-Item'>
          <FaTrashAlt onClick={toggleModal} title={strings.deleteTitle} />
        </div>
      </ListActionStyles>
    </ListCardStyles>
  );
};

export default VehicleCard;
