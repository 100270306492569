import { useLayoutEffect, useState } from 'react';

export const useContainerHeight = (initalHeight, initalRef) => {
  const [containerHeight, setContainerHeight] = useState(initalHeight);
  const [ref, setRef] = useState(initalRef);

  useLayoutEffect(() => {
    const windowResizing = () => {
      if (ref.current) {
        setContainerHeight(ref.current.clientHeight);
      }
    };

    window.addEventListener('resize', windowResizing);

    windowResizing();
    return () => {
      window.removeEventListener('resize', windowResizing);
    };
  }, [ref]);

  return {
    containerHeight,
    containerRef: ref,
    setContainerRef: (newRef) => setRef(newRef),
  };
};
