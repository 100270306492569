import strings from './strings';
import AppApiService from '../../../services/AppApi/AppApiService';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppDriverSettingsService = {
  updateDriver: function (driver) {
    return AppApiService.post('updateDriver', null, driver);
  },
  getSupportedLanguages: () => {
    return AppPlatformService.getSupportedLanguages();
  },
  startNFCIDScan: function (charger) {
    return AppApiService.post('startNFCIDScan', null, charger);
  },
  pollingNFCProgress: function (charger) {
    return AppApiService.post('pollingNFCProgress', null, charger);
  },
  strings: localStrings,
};

export default AppDriverSettingsService;
