import React, { useContext } from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppChargingService from '../AppChargingService';

//component
import ModalButton from '../../../shared/Buttons/ModalButton';

//context
import { DriverContext } from '../../../context/driver/getDriverContext';

//styles
import {
  DivStyles,
  ModalContainerStyles,
  ModalContentStyles,
  ModalHeaderStyles,
  ModalStyles,
  TextStyles,
  TitleStyles,
} from '../../../SharedStyles';

//helpers
import { milesToKm } from '../../../shared/Helpers';

const AppChargingVehicleModal = (props) => {
  const { strings } = AppChargingService;
  const { handleModal, details } = props;
  const { driver } = useContext(DriverContext);

  const closeModal = () => {
    handleModal();
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.vehicleInfo}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles textMargin='0'>
          {/* Section */}
          <DivStyles
            display='flex'
            alignItems='center'
            size='14px'
            align='left'
            padding='0 10px'>
            <TitleStyles flex='1' size='14px' color='#000' margin='0'>
              {strings.vehicleNickname}:
            </TitleStyles>
            <TextStyles flex='1' size='14px' color='#000'>
              {details.nickName}
            </TextStyles>
          </DivStyles>
          <DivStyles
            display='flex'
            alignItems='center'
            size='14px'
            align='left'
            padding='0 10px'>
            <TitleStyles flex='1' size='14px' color='#000' margin='0'>
              {strings.vehicleMake}:
            </TitleStyles>
            <TextStyles flex='1' size='14px' color='#000'>
              {details.make}
            </TextStyles>
          </DivStyles>
          <DivStyles
            display='flex'
            alignItems='center'
            size='14px'
            align='left'
            padding='0 10px'>
            <TitleStyles flex='1' size='14px' color='#000' margin='0'>
              {strings.vehicleModel}:
            </TitleStyles>
            <TextStyles flex='1' size='14px' color='#000'>
              {details.model}
            </TextStyles>
          </DivStyles>
          <DivStyles
            display='flex'
            alignItems='center'
            size='14px'
            align='left'
            padding='0 10px'>
            <TitleStyles flex='1' size='14px' color='#000' margin='0'>
              {strings.vehicleYear}:
            </TitleStyles>
            <TextStyles flex='1' size='14px' color='#000'>
              {details.year}
            </TextStyles>
          </DivStyles>
          <DivStyles
            display='flex'
            alignItems='center'
            size='14px'
            align='left'
            padding='0 10px'>
            <TitleStyles flex='1' size='14px' color='#000' margin='0'>
              {strings.vehicleBattery}:
            </TitleStyles>
            <TextStyles flex='1' size='14px' color='#000'>
              {details.batteryCapacity}
            </TextStyles>
          </DivStyles>
          <DivStyles
            display='flex'
            alignItems='center'
            size='14px'
            align='left'
            padding='0 10px'>
            <TitleStyles flex='1' size='14px' color='#000' margin='0'>
              {strings.vehicleRange}:
            </TitleStyles>
            <TextStyles flex='1' size='14px' color='#000'>
              {milesToKm(driver.unitOfMeasurement, details.allElectricRange)}
            </TextStyles>
          </DivStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={closeModal}
          buttonText={strings.ok}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default AppChargingVehicleModal;
