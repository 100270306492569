import React from 'react';
import { ThemeProvider } from 'styled-components';

//services
import AppNotFoundService from './AppNotFoundService';

//components
import NavSection from '../../driver/Nav/NavSection';
import Footer from '../../shared/Footer/Footer';

//global components
import Button from '../Buttons/Button';

//hooks
import { useRouter } from '../../shared/hooks/useRouter';

//styles
import './NotFound.scss';
import { InteriorStyles } from '../../SharedStyles';

//helpers
import { DRIVER_THEME } from '../index';

const AppNotFound = (props) => {
  const { strings } = AppNotFoundService;
  const router = useRouter();

  const goBack = () => {
    router.history.push('/');
  };

  return (
    <ThemeProvider theme={DRIVER_THEME}>
      <InteriorStyles>
        <div className='Body-Container'>
          <NavSection title={strings.notFoundTitle} isInternal goBack />
          <div className='AppCso-interior-container Not-found'>
            <div className='AppCso-component-container'>
              <div className='component-title-container'>
                <div className='component-title'>{strings.notFoundTitle}</div>
              </div>
              <div className='center-container'>
                <div>
                  <div className='four-oh-four'>{strings.fourOfour}</div>
                  <div>
                    <p>{props.message}</p>
                    <p>{strings.notFound}</p>
                  </div>
                </div>
              </div>
            </div>
            <Button
              driver
              noMobile
              onclick={goBack}
              buttonText={strings.goBack}
            />
          </div>
        </div>
        <Footer />
      </InteriorStyles>
    </ThemeProvider>
  );
};

export default AppNotFound;
