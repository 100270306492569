import React, { useEffect, useState, useContext } from 'react';

//layout
import { OuterWrapper, ComponentWrapper } from '../../OperatorShared/index';

//services
import AppChargersService from '../AppChargersService';

//components
import { LoadingSpinner } from '../../../shared';
import ChargerHeader from '../ChargerHeader';
import ControlCharger from './Charger/ControlCharger';
import CsoNotFound from '../../../shared/AppNotFound/CsoNotFound';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';

const ChargersControl = (props) => {
  const { strings } = AppChargersService;
  const { currentUser } = useContext(CsoAccountContext);
  const [loading, setLoading] = useState(true);
  const [charger, setCharger] = useState(null);
  const [notFound, setNotFound] = useState({
    display: false,
    message: '',
  });

  useEffect(() => {
    const fetchCharger = async () => {
      try {
        const request = {
          iotHubDeviceId: props.match.params.id,
          location: currentUser.userLocation,
        };
        let charger = await AppChargersService.getCharger(request);
        setCharger(charger);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setNotFound({
          display: true,
          message: strings.fourOhFour,
        });
      }
    };
    fetchCharger();
  }, [props.match.params.id, strings, currentUser.userLocation]);

  if (notFound.display) {
    return <CsoNotFound message={notFound.message} />;
  }
  return (
    <OuterWrapper internal path='/chargers' title={strings.controlsTitle}>
      <ComponentWrapper title={strings.controlsTitle}>
        {loading && <LoadingSpinner />}
        {charger && (
          <>
            <ChargerHeader charger={charger} />
            <ControlCharger charger={charger} />
          </>
        )}
      </ComponentWrapper>
    </OuterWrapper>
  );
};

export default ChargersControl;
