import AppPlatformService from '../AppPlatform/AppPlatformService';

const AppImagingService = {
  getFromScanner: (onScanSuccess, onScanError) => {
    AppPlatformService.debug('Launching camera...');

    let cameraOptions = {
      preferFrontCamera: false, // iOS and Android
      showFlipCameraButton: true, // iOS and Android
      showTorchButton: true, // iOS and Android
      //torchOn: true, // Android, launch with the torch switched on (if available)
      saveHistory: true, // Android, save scan history (default false)
      prompt: 'Place a barcode inside the scan area', // Android
      //resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
      //formats: 'QR_CODE,PDF_417', // default: all but PDF_417 and RSS_EXPANDED
      //orientation: 'portrait', // Android only (portrait|landscape), default unset so it rotates with the device
      disableAnimations: true, // iOS
      disableSuccessBeep: false, // iOS and Android
    };

    if (window.cordova !== undefined) {
      window.cordova.plugins.barcodeScanner.scan(
        onScanSuccess,
        onScanError,
        cameraOptions
      );
    }
  },
  getFromCamera: (onCameraSuccess, onCameraError) => {
    AppPlatformService.debug('Launching camera...');
    let cameraOptions = {
      quality: 100,
      destinationType: 0,
      targetWidth: 800,
      targetHeight: 600,
    };
    navigator.camera.getPicture(onCameraSuccess, onCameraError, cameraOptions);
  },
  getDataUrlForMobileLibraryId: (id) => {
    return new Promise((resolve, reject) => {
      if (AppPlatformService.isWebApp()) {
        reject('This is for mobile use only');
      } else {
        window.cordova.plugins.photoLibrary.getImageDataUrl(
          id,
          (result) => resolve(result),
          (error) => reject(error)
        );
      }
    });
  },
  getBlobForDataUrl: (dataUrl) => {
    return new Promise((resolve, reject) => {
      let arr = dataUrl.split(',');
      let mime = arr[0].match(/:(.*?);/)[1];
      let imageType = mime.split('/');
      let imageString = arr[1];

      //   let bstr = atob(arr[1]);
      //   let n = bstr.length;
      //   let u8arr = new Uint8Array(n);

      //   while (n--) {
      //     u8arr[n] = bstr.charCodeAt(n);
      //   }

      let imageBlob = { format: imageType[1], imageString: imageString };
      //   resolve(new Blob([u8arr], { type: mime }));
      resolve(imageBlob);
    });
  },
  getBlobForMobileLibraryId: (id) => {
    return new Promise((resolve, reject) => {
      if (AppPlatformService.isWebApp()) {
        reject('This is for mobile use only');
      } else {
        window.cordova.plugins.photoLibrary.getPhoto(
          id,
          (blob) => resolve(blob),
          (error) => reject(error)
        );
      }
    });
  },
  getDataUrlForBlob: (blob) => {
    return new Promise((resolve, reject) => {
      let file = new FileReader();
      file.onload = (dataUrl) => resolve(dataUrl);
      file.readAsDataURL(blob);
    });
  },
};

export default AppImagingService;
