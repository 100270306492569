import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaTachometerAlt, FaChargingStation, FaChartPie } from 'react-icons/fa';

//global Components
import { SubscriptionAccess } from '../../OperatorShared';

//styles
import { CenterNavStyles } from '../../../SharedStyles';

const CenterNav = (props) => {
  const { strings } = props;
  return (
    <CenterNavStyles>
      <NavLink
        disabled={props.disabled}
        className='AppCso-link'
        exact
        activeClassName='is-active'
        to='/'>
        <FaTachometerAlt className='nav-icons' />
        {strings.dashboard}
      </NavLink>
      <NavLink
        disabled={props.disabled}
        className='AppCso-link'
        activeClassName='is-active'
        to='/chargers'>
        <FaChargingStation className='nav-icons' />
        {strings.chargers}
      </NavLink>
      <SubscriptionAccess levels={[2, 3]}>
        <NavLink
          disabled={props.disabled}
          className='AppCso-link'
          activeClassName='is-active'
          to='/reports'>
          <FaChartPie className='nav-icons' />
          {strings.reports}
        </NavLink>
      </SubscriptionAccess>
    </CenterNavStyles>
  );
};

export default CenterNav;
