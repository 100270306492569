import React from 'react';

//styles
import {
  InputCheckMarkStyles,
  InputCheckMarkLabelStyles,
} from '../../../../SharedStyles';

const ChargerCheckbox = (props) => {
  const { handleChange } = props;
  return (
    <InputCheckMarkLabelStyles>
      <input
        type='checkbox'
        disabled={props.disabled}
        name='selectedCharger'
        checked={props.checked}
        onChange={handleChange}
      />
      <InputCheckMarkStyles
        title={
          props.disabled ? 'Charger must be online' : null
        }></InputCheckMarkStyles>
    </InputCheckMarkLabelStyles>
  );
};

export default ChargerCheckbox;
