import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppSharedService from '../AppSharedService';

//global component
import ModalButton from '../../../shared/Buttons/ModalButton';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
} from '../../../SharedStyles';

const DisableModal = (props) => {
  const { handleCloseModal, handleChange } = props;
  const { strings } = AppSharedService;

  const closeModal = () => {
    handleCloseModal();
  };

  const handleSaveInfo = (e) => {
    e.preventDefault();
    handleChange();
    handleCloseModal();
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.disableModalTitle}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          {props.type === 'location' && (
            <ModalItemStyles padding='0 10px'>
              <p>{strings.locationDisableModalContent}</p>
            </ModalItemStyles>
          )}
          {props.type === 'metergroup' && (
            <ModalItemStyles padding='0 10px'>
              <p>{strings.metergroupDisableModalContent}</p>
            </ModalItemStyles>
          )}
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={handleSaveInfo}
          buttonText={strings.disableModalButton}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default DisableModal;
