import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppMembershipsService from './../AppMembershipsService';

//global components
import ModalButton from '../../../shared/Buttons/ModalButton';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
} from '../../../SharedStyles';

const UserDataModal = (props) => {
  const { handleCloseModal, user } = props;

  const { strings } = AppMembershipsService;

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>
            {user.firstName} {user.lastName}
          </h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles align='left' padding='0 10px'>
            <p>{strings.membersData}</p>
          </ModalItemStyles>
          {/* Section */}
          <ModalItemStyles align='left' padding='0 10px'>
            <p>Sessions: {user.sessions}</p>
          </ModalItemStyles>
          {/* Section */}
          <ModalItemStyles align='left' padding='0 10px'>
            <p>kWhs: {user.kwhs}</p>
          </ModalItemStyles>
          {/* Section */}
          <ModalItemStyles align='left' padding='0 10px'>
            <p>Amount: ${user.amount}</p>
          </ModalItemStyles>
          {/* Section */}
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={closeModal}
          buttonText={strings.ok}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default UserDataModal;
