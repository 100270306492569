import React, { useState } from 'react';

//components
import DisableModal from '../../operator/OperatorShared/ToggleSwitch/DisableModal';

//styles
import {
  ToggleContainerStyles,
  ToggleSwitchStyles,
  ToggleLabelStyles,
  InputStyles,
} from '../../SharedStyles/';

const ToggleSwitch = (props) => {
  const [disableModal, setDisableModal] = useState(false);

  const handleModalPopup = (e) => {
    if (props.disabledModal && props.checked) {
      setDisableModal(!disableModal);
    } else {
      props.handleChange(e);
    }
  };

  const toggleModal = () => {
    setDisableModal(false);
  };

  return (
    <ToggleContainerStyles
      display='flex'
      padding={props.padding ? props.padding : '10px'}
      margin='0'
      justifyContent={props.justifyContent}>
      {disableModal && (
        <DisableModal
          type={props.type}
          handleChange={props.handleChange}
          handleCloseModal={toggleModal}
        />
      )}
      {props.doubleLabel && (
        <ToggleLabelStyles margin='0 15px'>
          {props.doubleLabel}
        </ToggleLabelStyles>
      )}
      <ToggleSwitchStyles>
        <InputStyles
          checked={props.checked}
          name={props.name}
          onChange={handleModalPopup}
          type='checkbox'
          disabled={props.disable}
        />
        <span className='Slider Round' />
      </ToggleSwitchStyles>
      <ToggleLabelStyles margin='0 15px'>{props.label}</ToggleLabelStyles>
    </ToggleContainerStyles>
  );
};

export default ToggleSwitch;
