import styled from 'styled-components';

export const CommentCardStyles = styled.div`
  display: flex;
  flex-wrap: nowrap;
  font-size: ${({ size }) => (size ? size : '14px')};
  padding: ${({ padding }) => (padding ? padding : '50px')};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? borderBottom : '1px solid #e7e7e7'};

  &:last-child {
    border-bottom: none;
  }
`;

export const CommentLeftStyles = styled.div`
  flex-basis: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .Comment-Left-Title,
  .Comment-Left-Added {
    padding-top: 3px;
    color: #2c456c;
    font-size: 11px;
    line-height: 11px;
    text-align: center;
  }
  .Comment-Left-Image {
    max-width: 130px;
    max-height: 65px;
    overflow: hidden;
    padding: 2px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
export const CommentCenterStyles = styled.div`
  display: ${({ display }) => display};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  flex: 1;
  margin: 0 10px;
  color: #a5a5a5;
  svg {
    margin: 10px 5px 10px 15px;
    color: #8497b6;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
  }
`;
export const CommentRightStyles = styled.div`
  margin-top: 5px;
  padding-bottom: 5px;
  color: #2c456c;
`;
