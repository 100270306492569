import React, { useState, useEffect } from 'react';

//service
import AppPlatformService from '../../../../../../services/AppPlatform/AppPlatformService';

//components
import RevenueChart from './RevenueChart';
import Section from '../IncomeReports/Payout/components/Section';
import Summaries from '../Summaries';

//styles
import { DivStyles, TableStyles } from '../../../../../SharedStyles';

const RevenueContent = (props) => {
  const { filters, reportData } = props;
  const [summaries, setSummaries] = useState({
    numberOfPorts: 0,
    numberOfSessions: 0,
    totalRevenue: 0,
    totalSessionRevenue: 0,
    totalIdleFeeRevenue: 0,
    totalOtherRevenue: 0,
  });

  useEffect(() => {
    if (Object.keys(reportData).length > 0) {
      setSummaries((prev) => ({
        ...prev,
        numberOfPorts: reportData.totalPorts,
        numberOfSessions: reportData.totalSessions,
        totalRevenue: reportData.totalRevenue,
        totalSessionRevenue: reportData.totalSessionRevenue,
        totalIdleFeeRevenue: reportData.totalIdleFeeRevenue,
        totalOtherRevenue: reportData.totalOtherRevenue,
      }));
    }
  }, [reportData]);

  return (
    <>
      <Section padding='0' borderBottom='none'>
        <DivStyles display='flex' flexWrap='wrap'>
        <TableStyles
            borderTop='1px solid #e7e7e7'
            borderRight='1px solid #e7e7e7'
            flex='1'>
            <Summaries
              justifyContent='center'
              titles={['Ports', 'Sessions']}
              content={[summaries.numberOfPorts, summaries.numberOfSessions]}
            />
          </TableStyles>
          <TableStyles
            flex='1'
            borderTop='1px solid #e7e7e7'
            borderRight='1px solid #e7e7e7'>
            <Summaries
              justifyContent='center'
              titles={['Total Amt', 'Total Session Amt', 'Total Idle Amt', 'Total Other Amt']}
              content={[
                AppPlatformService.currencyFormat(summaries.totalRevenue),
                AppPlatformService.currencyFormat(summaries.totalSessionRevenue),
                AppPlatformService.currencyFormat(summaries.totalIdleFeeRevenue),
                AppPlatformService.currencyFormat(summaries.totalOtherRevenue),
              ]}
            />
          </TableStyles>
        </DivStyles>
      </Section>
      {!props.loading && (
        <DivStyles width='100%' overflowX='scroll'>
          <RevenueChart
            minWidth='1000px'
            filterDate={filters.date}
            reportData={props.reportData.revenueData}
            fetchReportData={props.fetchReportData}
          />
        </DivStyles>
      )}
    </>
  );
};

export default RevenueContent;
