import AppPlatformService from '../../../../services/AppPlatform/AppPlatformService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppMissingData = {
  strings: localStrings
};

export default AppMissingData;
