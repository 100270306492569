import React from 'react';

//styles
import { DivStyles } from '../../../../../../../SharedStyles';

const Section = (props) => {
  return (
    <DivStyles
      align='left'
      padding={props.padding ? props.padding : '0 10px 10px'}
      color='#8f8d8d'
      size='13px'
      borderBottom={props.borderBottom ? props.borderBottom : true}>
      {props.children}
    </DivStyles>
  );
};

export default Section;
