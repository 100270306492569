import React, { useState, useEffect, useRef } from 'react';
import { ThemeProvider } from 'styled-components';

//services
import AppWebLandingService from './AppWebLandingService';

//components
import LoadingSpinner from '../shared/Loading/LoadingSpinner';
import Footer from '../shared/Footer/Footer';

//hooks
import { useRouter } from '../shared/hooks/useRouter';

//styles
import { ButtonStyles, DivStyles } from '../SharedStyles';
import './AppWebLanding.scss';

//images
import landingLogo from '../../assets/images/logo.png';

//env
import {
  DRIVER_APPLE_APP_STORE,
  DRIVER_GOOGLE_PLAY_STORE,
  CSO_APPLE_APP_STORE,
  CSO_GOOGLE_PLAY_STORE,
} from '../shared';

//helpers
import { CSO_THEME, DRIVER_THEME } from '../shared';

export default function AppWebLanding() {
  const { strings } = AppWebLandingService;
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  const csoRef = useRef();

  const query = new URLSearchParams(router.location.search);

  let csoAccount = query.get('csoAccount');

  const handleDriverStorage = (e) => {
    setLoading(true);
    localStorage.removeItem('csoAccount');
    localStorage.removeItem('accountId');
    localStorage.setItem('driverAccount', true);
  };

  const handleCSOStorage = (e) => {
    setLoading(true);
    localStorage.removeItem('driverAccount');
    localStorage.setItem('csoAccount', true);
  };

  useEffect(() => {
    localStorage.removeItem('auth.token');
    localStorage.removeItem('driverAccount');
    localStorage.removeItem('csoAccount');
    sessionStorage.clear();

    if (csoAccount) {
      handleCSOStorage();
      AppWebLandingService.onContinueClick(localStorage.getItem('auth.token'));
    }
  }, [csoAccount]);

  if (csoAccount) {
    return (
      <DivStyles
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100%'>
        <LoadingSpinner /> Please wait while we load the account....
      </DivStyles>
    );
  }

  return (
    <>
      <div className='AppWebLanding-container'>
        {loading && <LoadingSpinner />}
        <nav className='AppWebLanding-header'>
          <img src={landingLogo} alt='AmpedUp Logo' />
        </nav>
        <div className='AppWebLanding-interior'>
          <div className='AppWebLanding-title'>{strings.title}</div>
          <div className='AppWebLanding-content-container'>
            <ThemeProvider theme={DRIVER_THEME}>
              <div className='AppWebLanding-left-container'>
                <div className='AppWebLanding-content-title'>
                  {strings.iAmAn}
                  <strong>
                    <br />
                    {strings.driver}
                  </strong>
                </div>
                <div className='AppWebLanding-content-text'>
                  {strings.driverMessage}
                </div>
                <ButtonStyles
                  margin='15px 5px'
                  type='button'
                  onClick={() => {
                    handleDriverStorage();
                    AppWebLandingService.onContinueClick();
                  }}>
                  {AppWebLandingService.strings.button}
                </ButtonStyles>
                <div className='AppWebLanding-store'>
                  <a
                    href={DRIVER_GOOGLE_PLAY_STORE}
                    className='downlaodappcontainer w-inline-block'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img
                      src={require('../../assets/images/AppsIcon/googlePlayBadge.png').default}
                      width='130'
                      alt='Google Badge'
                    />
                  </a>

                  <a
                    href={DRIVER_APPLE_APP_STORE}
                    className='downlaodappcontainer w-inline-block'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img
                      src={require('../../assets/images/AppsIcon/AppleStoreBadge.png').default}
                      width='130'
                      alt='Apple Store'
                    />
                  </a>
                </div>
              </div>
            </ThemeProvider>
            <ThemeProvider theme={CSO_THEME}>
              <div className='AppWebLanding-right-container'>
                <div className='AppWebLanding-content-title'>
                  {strings.iAmA}
                  <strong>
                    <br />
                    {strings.cso}
                  </strong>
                </div>
                <div className='AppWebLanding-content-text'>
                  {strings.csoMessage}
                </div>
                <ButtonStyles
                  margin='15px 5px'
                  ref={csoRef}
                  type='button'
                  onClick={() => {
                    handleCSOStorage();
                    AppWebLandingService.onContinueClick();
                  }}>
                  {AppWebLandingService.strings.button}
                </ButtonStyles>
                <div className='AppWebLanding-store'>
                  <a
                    href={CSO_GOOGLE_PLAY_STORE}
                    className='downlaodappcontainer w-inline-block'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img
                      src={require('../../assets/images/AppsIcon/googlePlayBadge.png').default}
                      width='130'
                      alt='Google Badge'
                    />
                  </a>
                  <a
                    href={CSO_APPLE_APP_STORE}
                    className='downlaodappcontainer w-inline-block'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img
                      src={require('../../assets/images/AppsIcon/AppleStoreBadge.png').default}
                      width='130'
                      alt='Apple Store'
                    />
                  </a>
                </div>
              </div>
            </ThemeProvider>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
