import React from 'react';
import { FaInfoCircle, FaClock } from 'react-icons/fa';

//services
import AppOptionsService from './AppOptionsService';

//styles
import {
  FormContainerStyles,
  FormRowStyles,
  FormLeftStyles,
  TitleStyles,
  InputStyles,
} from '../../../../SharedStyles';

const MembershipMessage = (props) => {
  const { currentUser } = props;
  const { strings } = AppOptionsService;

  const handleInputChange = (e) => {
    props.handleDataChange([e.target.name], e.target.value);
  };

  return (
    <>
      {/* Notice to EV Drivers */}
      <FormContainerStyles
        padding='10px 10px 20px'
        headersPadding='0'
        borderBottom>
        <TitleStyles size='14px' margin='0 0 10px' weight='600'>
          {strings.evNotes}
        </TitleStyles>
        <FormRowStyles display='flex' padding='5px 5px 0' margin='0'>
          <FormLeftStyles>
            <FaInfoCircle />
          </FormLeftStyles>
          <InputStyles
            size='14px'
            mobileSize='14px'
            width='100%'
            mobileWidth='100%'
            mobileMargin='0 0 15px'
            padding='5px 0px 5px 4px'
            onChange={handleInputChange}
            disabled={currentUser && currentUser.role === 4}
            type='name'
            placeholder={strings.evNotice}
            value={props.charger.driverNotice || ''}
            name='driverNotice'
          />
        </FormRowStyles>
      </FormContainerStyles>
      <FormContainerStyles
        padding='10px 10px 20px'
        headersPadding='0'
        borderBottom>
        <TitleStyles size='14px' margin='0 0 10px' weight='600'>
          {strings.availabilityDesc}
        </TitleStyles>
        <FormRowStyles display='flex' padding='5px 5px 0' margin='0'>
          <FormLeftStyles>
            <FaClock />
          </FormLeftStyles>
          <InputStyles
            size='14px'
            mobileSize='14px'
            width='100%'
            mobileWidth='100%'
            mobileMargin='0 0 15px'
            padding='5px 0px 5px 4px'
            disabled={currentUser && currentUser.role === 4}
            onChange={handleInputChange}
            placeholder={strings.availabilityInfo}
            value={props.charger.availabilityDescription || ''}
            name='availabilityDescription'
          />
        </FormRowStyles>
      </FormContainerStyles>
    </>
  );
};

export default MembershipMessage;
