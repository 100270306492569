import React, { useState } from 'react';

//layout
import {
  ComponentWrapper,
  OuterWrapper,
  RoleCanEdit,
} from '../../../../../../OperatorShared';

//services
import AppIncomeService from '../../AppIncomeService';

//components
import PayoutList from './PayoutList';

//global components
import SearchFilterBar from '../../../../../../OperatorShared/SearchFilterBar';

//context
import SubscriptionAccess from '../../../../../../OperatorShared/SubscriptionAccess';

const PayoutPage = () => {
  const { strings } = AppIncomeService;
  const [searchedValue, setSearchedValue] = useState('');
  const [filteredValue, setFilteredValue] = useState(null);
  const [submitSearch, setSubmitSearch] = useState(false);

  const handleFilterChange = (filter) => {
    setSubmitSearch(false);
    setFilteredValue(filter);
  };

  const handleSearchChange = (value) => {
    setSubmitSearch(false);
    setSearchedValue(value);
  };

  const handleSearchSubmit = (submit) => {
    setSubmitSearch(submit);
  };

  return (
    <OuterWrapper title={strings.earnedIncomeTitle} internal path='/'>
      <SubscriptionAccess levels={[2, 3]}>
        <RoleCanEdit roles={[1, 2]}>
          <ComponentWrapper title={strings.earnedIncomeTitle}>
            <SearchFilterBar
              payoutReportFilter
              filterBy={strings.filterList}
              handleSearchSubmit={handleSearchSubmit}
              handleSearchChange={handleSearchChange}
              handleFilterChange={handleFilterChange}
            />
            {/* list of searched and filtered results */}
            <PayoutList
              filteredValue={submitSearch ? filteredValue : ''}
              searchedValue={searchedValue}
            />
          </ComponentWrapper>
        </RoleCanEdit>
      </SubscriptionAccess>
    </OuterWrapper>
  );
};

export default PayoutPage;
