import styled from 'styled-components';

import { defaultOrange } from '../defaultValues';

export const SelectContainerStyles = styled.div`
  width: 100%;
`;

export const SelectStyles = styled.select`
  font-size: ${({ size }) => (size ? size : '14px')};
  width: ${({ width }) => (width ? width : '100%')};
  flex: ${({ flexWidth }) => flexWidth};
  padding: ${({ padding }) => (padding ? padding : '8px 0px 5px 5px')};
  margin: ${({ margin }) => (margin ? margin : '0 0 5px')};
  height: ${({ height }) => height};
  color: #a5a5a5 !important;
  border: none;
  outline: none;
  border-bottom: 1px solid #cccccc;
  background: transparent;
  cursor: pointer;
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    width: ${({ mobileWidth }) => (mobileWidth ? mobileWidth : '100%')};
    margin: ${({ mobileMargin }) => mobileMargin};
    font-size: ${({ mobileSize }) => mobileSize};
  }
  &:hover,
  &:active,
  &:focus {
    border-bottom: 1px solid ${defaultOrange};
  }
  &:disabled {
    color: #cacaca;
    cursor: not-allowed;
    -webkit-text-fill-color: #cacaca;
  }

  background-image: ${({ noDropdown }) =>
    noDropdown
      ? 'none'
      : 'linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%)'};
  background-position: ${({ noDropdown, bgPosition }) =>
    noDropdown
      ? 'none'
      : bgPosition
      ? `calc(100% - 15px) calc(${bgPosition}), calc(100% - 10px) calc(${bgPosition})`
      : 'calc(100% - 15px) calc(1.15em), calc(100% - 10px) calc(1.15em)'};
  background-size: ${({ noDropdown }) =>
    noDropdown ? 'none' : '5px 5px, 5px 5px'};
  background-repeat: ${({ noDropdown }) => (noDropdown ? 'none' : 'no-repeat')};
`;

export const SelectRoundStyles = styled.select`
  flex: 1 1;
  font-size: ${({ size }) => size};
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => height};
  border: 1px solid #e7e7e7;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : '15px'};
  font-weight: 300;
  padding: ${({ padding }) => (padding ? padding : '8px 12px')};
  margin: ${({ margin }) => margin};
  color: #a5a5a5;

  background-image: ${({ noDropdown }) =>
    noDropdown
      ? 'none'
      : 'linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%)'};
  background-position: ${({ noDropdown, bgPosition }) =>
    noDropdown
      ? 'none'
      : bgPosition
      ? `calc(100% - 15px) calc(${bgPosition}), calc(100% - 10px) calc(${bgPosition})`
      : 'calc(100% - 15px) calc(1.15em), calc(100% - 10px) calc(1.15em)'};
  background-size: ${({ noDropdown }) =>
    noDropdown ? 'none' : '5px 5px, 5px 5px'};
  background-repeat: ${({ noDropdown }) => (noDropdown ? 'none' : 'no-repeat')};

  &:hover,
  &:focus,
  &:active {
    border: 1px solid ${defaultOrange};
  }
`;
