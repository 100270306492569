import AccessCardsService from '../../AccessCardsService';

import states from '../../../../shared/Address/states.json';

const { strings } = AccessCardsService;

export const formSetup = [
  {
    name: 'shippingFirstName',
    type: 'text',
    element: 'input',
    placeholder: strings.orderFirstName,
    required: true,
    disabled: false,
    flex: '50%',
    margin: '0 10px 0 0',
  },
  {
    name: 'shippingLastName',
    type: 'text',
    element: 'input',
    placeholder: strings.orderLastName,
    required: true,
    disabled: false,
    flex: '50%',
    margin: '0 0 0 10px',
  },
  {
    name: 'shippingAddress1',
    type: 'text',
    element: 'input',
    placeholder: strings.orderAddressOne,
    required: true,
    disabled: false,
    flex: '100%',
    margin: '0 ',
  },
  {
    name: 'shippingAddress2',
    type: 'text',
    element: 'input',
    placeholder: strings.orderAddressTwo,
    required: false,
    disabled: false,
    flex: '100%',
    margin: '0 ',
  },
  {
    name: 'shippingCity',
    type: 'text',
    element: 'input',
    placeholder: strings.orderCity,
    required: true,
    disabled: false,
    flex: '50%',
    margin: '0 0 0 10px',
  },
  {
    name: 'shippingState',
    type: 'text',
    element: 'select',
    placeholder: strings.orderState,
    required: true,
    disabled: false,
    flex: '50%',
    margin: '0 10px 0 0',
    options: [
      { key: strings.orderState, value: '', disabled: true },
      ...states.map((s) => {
        return { key: s.name, value: s.abbreviation, disabled: false };
      }),
    ],
  },
  {
    name: 'shippingZip',
    type: 'text',
    element: 'input',
    placeholder: strings.orderZip,
    required: true,
    disabled: false,
    flex: '50%',
    margin: '0 0 0 10px',
  },
  {
    name: 'shippingCountry',
    type: 'text',
    element: 'select',
    placeholder: strings.orderCountry,
    required: true,
    disabled: false,
    flex: '50%',
    margin: '0 10px 0 0 ',
    options: [
      { key: strings.orderCountry, value: '', disabled: true },
      ...strings.countries.map((c) => {
        return { key: c, value: c, disabled: false };
      }),
    ],
  },
];

export const formCardTotal = {
  name: 'count',
  type: 'number',
  element: 'input',
  placeholder: strings.orderTotalCards,
  required: true,
  disabled: false,
};
