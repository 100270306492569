import React, { useState } from 'react';
import { isIOS, isMobileSafari } from 'react-device-detect';

//services
import AppImagingService from '../../../../../services/AppImaging/AppImagingService';

//components
import ChargerQrScanner from './ChargerQrScanner';

//styles
import { DivStyles, ButtonStyles, TextStyles } from '../../../../SharedStyles';

const ChargersScanner = (props) => {
  const { strings } = props;
  const [errorData, setErrorData] = useState({
    display: false,
    title: 'Camera Error',
    message: 'Something went wrong with the camera scan code',
  });

  const scanImage = () => {
    AppImagingService.getFromScanner(onScanSuccess, onScanError);
  };

  const onScanSuccess = (data) => {
    if (data) {
      props.handleScan(data);
    }
  };

  const onScanError = (err) => {
    setErrorData({ ...errorData, display: true, message: err });
  };

  if (isIOS && !isMobileSafari && window.cordova === undefined) {
    return <TextStyles size='14px'>{strings.scanNotSupported}</TextStyles>;
  }

  const scanner =
    window.cordova !== undefined ? (
      <ButtonStyles onClick={scanImage}>{strings.scanNow}</ButtonStyles>
    ) : (
      <ChargerQrScanner />
    );

  return (
    <DivStyles width='150px' margin='25px auto'>
      {scanner}
    </DivStyles>
  );
};

export default ChargersScanner;
