import React from 'react';
import { withRouter } from 'react-router-dom';
import { FaRegWindowClose } from 'react-icons/fa';

//service
import AppLeaveGuardService from './AppLeaveGuardService';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  DivStyles,
} from '../../SharedStyles';
import ModalButton from '../Buttons/ModalButton';

const LeaveModal = (props) => {
  const { strings } = AppLeaveGuardService;

  if (props.visible) {
    if (props.driver) {
      return (
        <div className='Modal-Display-Driver'>
          <div className='Modal'>
            <div className='Modal-Header'>
              <div>Leave Page?</div>
              <div className='Modal-Close'>
                <FaRegWindowClose onClick={props.closeModal} />
              </div>
            </div>
            <div className='Modal-Content'>
              <p>Changes you made may not be saved.</p>
            </div>
            <div className='Modal-Button'>
              <button className='app-button' onClick={props.onCancel}>
                Cancel
              </button>{' '}
              <button className='app-button' onClick={props.onConfirm}>
                Save
              </button>
            </div>
          </div>
        </div>
      );
    }
    if (props.cso) {
      return (
        <ModalStyles>
          <ModalContainerStyles>
            {/* Header Bar */}
            <ModalHeaderStyles>
              <h2 className='Title'>{strings.title}</h2>
              <FaRegWindowClose className='Close' onClick={props.closeModal} />
            </ModalHeaderStyles>
            {!props.requiredFields ? (
              <>
                {/* Content Section */}
                <ModalContentStyles>
                  {/* Section */}
                  <ModalItemStyles padding='0 10px'>
                    <p>{strings.unsavedMessage}</p>
                  </ModalItemStyles>
                </ModalContentStyles>
                {/* Button Component for Modals*/}
                <DivStyles
                  display='flex'
                  alignItems='center'
                  justifyContent='flex-end'>
                  <ModalButton
                    containerMargin='10px 10px 0'
                    containerAlign='right'
                    buttonPadding='10px'
                    buttonMargin='0'
                    handleClick={props.onCancel}
                    buttonText={strings.cancel}
                  />
                  <ModalButton
                    containerMargin='10px 10px 0'
                    containerAlign='right'
                    buttonPadding='10px'
                    buttonMargin='0'
                    handleClick={props.onConfirm}
                    buttonText={strings.save}
                  />
                </DivStyles>
              </>
            ) : (
              <>
                {/* Content Section */}
                <ModalContentStyles>
                  {/* Section */}
                  <ModalItemStyles padding='0 10px'>
                    <p>{strings.missingFields}</p>
                  </ModalItemStyles>
                </ModalContentStyles>
                {/* Button Component for Modals*/}
                <DivStyles
                  display='flex'
                  alignItems='center'
                  justifyContent='flex-end'>
                  <ModalButton
                    containerMargin='10px 10px 0'
                    containerAlign='right'
                    buttonPadding='10px'
                    buttonMargin='0'
                    handleClick={props.onCancel}
                    buttonText={strings.redirect}
                  />
                  <ModalButton
                    containerMargin='10px 10px 0'
                    containerAlign='right'
                    buttonPadding='10px'
                    buttonMargin='0'
                    handleClick={props.closeModal}
                    buttonText={strings.stay}
                  />
                </DivStyles>
              </>
            )}
          </ModalContainerStyles>
        </ModalStyles>
      );
    }
  }
  return null;
};

export default withRouter(LeaveModal);
