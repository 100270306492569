import { useState, useEffect } from 'react';

export const useConnectors = () => {
  const [connectors, setConnectors] = useState([]);
  const [evses, setEvses] = useState([]);

  useEffect(() => {
    let connections = evses.map((conn) => conn.connectorName);
    setConnectors(connections);
  }, [evses]);

  return [
    connectors,
    (evses) => {
      setEvses(evses);
    },
  ];
};
