import strings from "./strings";
import AppPlatformService from "../../../services/AppPlatform/AppPlatformService";

const localStrings = strings[AppPlatformService.getLanguage()];

const AppFooterService = {
  strings: localStrings
};

export default AppFooterService;
