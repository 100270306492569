import AppPlatformService from '../../../../../services/AppPlatform/AppPlatformService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppOptionsService = {
  strings: localStrings
};

export default AppOptionsService;
