import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//service
import AppChargingService from '../AppChargingService';

//components
import ModalButton from '../../../shared/Buttons/ModalButton';

//styles
import {
  ButtonStyles,
  DivStyles,
  ModalContainerStyles,
  ModalContentStyles,
  ModalHeaderStyles,
  ModalItemStyles,
  ModalStyles,
  TitleStyles,
} from '../../../SharedStyles';

const ChargingPortModal = (props) => {
  const { closeModal, onclick } = props;
  const { strings } = AppChargingService;
  let ports = null;

  const selectPortHandler = (port) => {
    onclick(port);
    closeModal();
  };

  if (
    props.connectors.connectorStatuses &&
    props.connectors.connectorStatuses.length > 1
  ) {
    ports = props.connectors.connectorStatuses.map((port) => {
      console.log(port);
      const isAvailable = [1, 2].includes(port.status);
      const portBlocked =
        port.waitQueueBlocked ||
        port.membershipOnlyBlocked ||
        port.reservationBlocked;
      return (
        <DivStyles key={port.connectorId} margin='1em 0'>
          <TitleStyles size='16px' weight='600' color='#000'>
            {strings.portLabel} {port.connectorId}
          </TitleStyles>
          <ButtonStyles
            padding='4px 10px'
            disabled={!isAvailable || port.isAuthedForOtherUser || portBlocked}
            // className='charging-port button'
            onClick={() => selectPortHandler(port)}>
            {!isAvailable ? 'unavailable' : 'Choose Port ' + port.connectorId}
          </ButtonStyles>
        </DivStyles>
      );
    });
  }

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.connectorPortChoose}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            {strings.connectorPortDesc}
          </ModalItemStyles>
          <ModalItemStyles padding='0 10px'>{ports}</ModalItemStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          handleClick={closeModal}
          buttonText={strings.cancel}
        />
      </ModalContainerStyles>
    </ModalStyles>
  );
};

export default ChargingPortModal;
