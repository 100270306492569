import React, { useState, useContext } from 'react';

//layout
import { OuterWrapper, ComponentWrapper } from '../../OperatorShared';
import Button from '../../../shared/Buttons/Button';

//services
import AppChargingProfilesService from '../AppChargingProfilesService';

//components
import FormHeaderComponent from './FormHeaderComponent';
import DailySchedules from './DailySchedules';

//global components
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';
import EnterpriseAdmin from '../../EnterpriseAdmin';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import { ErrorContext } from '../../../context/shared/ErrorContext';

//error Class
import { ErrorResponse } from '../../../shared/ErrorResponse';

const ChargingProfileAdd = (props) => {
  const { strings } = AppChargingProfilesService;
  const { account, setAccount } = useContext(CsoAccountContext);
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(false);
  const [chargingProfile, setChargingProfile] = useState({
    csoAccountId: account.id,
    schedules: [],
  });

  const handleChange = (name, value) => {
    setChargingProfile({
      ...chargingProfile,
      [name]: value,
    });
  };

  const handleScheduleAdd = (schedule) => {
    setChargingProfile({ ...chargingProfile, schedules: schedule });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let checkedDates = chargingProfile.schedules.map((sx) => {
        return Object.keys(sx.dow).every((day) => !sx.dow[day]);
      });
      checkedDates.forEach((boo) => {
        if (boo) {
          setLoading(false);
          throw new ErrorResponse(
            'Frontend',
            'You must select a day of week for this schedule.',
            'Invalid Schedule'
          );
        }
      });
      chargingProfile.schedules.forEach((sch) => {
        sch.times.forEach((time) => {
          if (time.percentage === '0' || time.percentage === '') {
            throw new ErrorResponse(
              'Frontend',
              'You must select an amperage percentage between 1-100.',
              'Invalid Amperage Percentage'
            );
          }
        });
      });
      let createdProfile = await AppChargingProfilesService.createChargingProfile(
        chargingProfile
      );
      setAccount(createdProfile);
      setLoading(false);
      setTimeout(() => {
        if (!loading) {
          props.history.push('/subscription/charging-profiles');
        }
      }, 500);
    } catch (err) {
      if (err.name === 'Frontend') {
        setError({
          display: true,
          message: err.message,
          title: err.title,
          styles: 'cso',
        });
        setLoading(false);
      }
      if (err.response) {
        let errTitle = '';
        let errMessage = '';
        let errorResponse = err.response.data.split(' ');
        if (
          errorResponse.includes("'TimeStartCS'") ||
          errorResponse.includes("'TimeEndCS'")
        ) {
          errTitle = strings.invalidTimeTitle;
          errMessage = strings.invalidTimeContent;
        }
        if (errorResponse[6] === "'System.Int32'.") {
          errTitle = strings.invalidPerTitle;
          errMessage = strings.invalidPerContent;
        }
        if (errorResponse[0] === 'Invalid') {
          errTitle = strings.invalidScheduleTitle;
          errMessage = `${strings.invalidScheduleContent} ${errorResponse[7]}.`;
        } else if (errorResponse[0] !== 'Error') {
          errTitle = strings.invalidSave;
          errMessage = err.response.data;
        }
        setError({
          display: true,
          message: errMessage,
          title: errTitle,
          styles: 'cso',
        });
        setLoading(false);
      }
    }
  };

  return (
    <OuterWrapper
      title={strings.addTitle}
      internal
      path='/subscription/charging-profiles'
      myForm='myForm'
      roles={[1]}>
      {loading && <LoadingSpinner />}
      <EnterpriseAdmin>
        <ComponentWrapper roles={[1]} title={strings.addTitle}>
          <FormHeaderComponent
            profile={chargingProfile}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
          <DailySchedules
            profile={chargingProfile}
            handleChanges={handleScheduleAdd}
          />
        </ComponentWrapper>
        <Button
          cso
          hasMobile
          formSubmit
          buttonText={strings.save}
          roles={[1]}
        />
      </EnterpriseAdmin>
    </OuterWrapper>
  );
};

export default ChargingProfileAdd;
