import styled from 'styled-components';

export const CenterStyles = styled.div`
  text-align: center;
  margin: ${({ margin }) => (margin ? margin : '15px auto')};
  p {
    color: #2c456c;
    font-size: 14px;
    padding: 0 35px;
  }
`;
