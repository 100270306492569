import React, { useState, useContext, useEffect } from 'react';

//services
import AppVehicleService from '../../AppVehicleService';

//components
import LoadingSpinner from '../../../../shared/Loading/LoadingSpinner';

//hooks
import { useRouter } from '../../../../shared/hooks/useRouter';
import { useCloudinary } from '../../../../shared/Cloudinary/hook/useCloudinary';

//context
import { ErrorContext } from '../../../../context/shared/ErrorContext';
import { DriverContext } from '../../../../context/driver/getDriverContext';

//images
import DefaultImage from '../../../../../assets/images/MyVehiclePlaceholder300x150.png';

//styles
import {
  VehicleFormStyles,
  VehicleSpecContainerStyles,
} from '../../../../DriverStyles';
import {
  InputContainerStyles,
  InputStyles,
  SelectContainerStyles,
  SelectStyles,
  ButtonContainerStyles,
  ButtonSpanStyles,
} from '../../../../SharedStyles';

const ManualVehicle = (props) => {
  const { strings } = AppVehicleService;
  const { driver } = useContext(DriverContext);

  const router = useRouter();
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(false);
  const [newVehicle, setNewVehicle] = useState({
    averageEnergyConsumptionMax: 0,
    averageEnergyConsumptionMin: 0,
    manualEntry: true,
  });

  const { cloudData, uploadImage, imageLoading } = useCloudinary({
    preset: 'vehicle_images',
    tags: [`${driver.firstName} ${driver.lastName}`, driver.id],
  });

  useEffect(() => {
    if (cloudData) {
      setNewVehicle((v) => ({
        ...v,
        imagePath: cloudData.secure_url,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloudData]);

  const openDialog = () => {
    document.getElementById('inputUpload').click();
  };

  const handleChange = (e) => {
    setNewVehicle({ ...newVehicle, [e.target.name]: e.target.value });
  };

  const saveVehicle = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const vehicleReturn = await AppVehicleService.addDriverVehicle(
        newVehicle
      );
      if (vehicleReturn) {
        router.history.push('/vehicle-list');
      }
    } catch (error) {
      setLoading(false);
      setError({
        display: true,
        title: 'Something went wrong',
        message: 'Could not create vehicle at this time.',
      });
    }
  };

  const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const yearOptions = [];
    let year = 2000;

    while (year <= currentYear + 2) {
      yearOptions.push(year);
      year++;
    }

    return yearOptions;
  };

  return (
    <>
      <VehicleFormStyles id='myForm' onSubmit={saveVehicle}>
        {(loading || imageLoading) && <LoadingSpinner />}
        <InputContainerStyles>
          <InputStyles
            type='text'
            maxLength='256'
            name='nickname'
            placeholder={strings.nickname}
            onChange={handleChange}
            required
          />
          <InputStyles
            maxLength='256'
            name='make'
            placeholder={strings.makePlaceholder}
            onChange={handleChange}
            required
          />
          <InputStyles
            maxLength='256'
            name='model'
            placeholder={strings.modelPlaceholder}
            onChange={handleChange}
            required
          />
          <SelectContainerStyles>
            <SelectStyles
              name='year'
              onChange={handleChange}
              defaultValue=''
              required>
              <option value='' disabled>
                {strings.yearPlaceholder}
              </option>
              {getYearOptions().map((el) => (
                <option key={el} value={el}>
                  {el}
                </option>
              ))}
            </SelectStyles>
          </SelectContainerStyles>
          <InputStyles
            type='number'
            min='1'
            max='9999'
            name='allElectricRange'
            placeholder={strings.manualRange}
            onChange={handleChange}
            required
          />
          <InputStyles
            type='number'
            min='1'
            max='999'
            name='batteryCapacity'
            placeholder={strings.manualBattery}
            onChange={handleChange}
            required
          />
          <SelectContainerStyles>
            <SelectStyles name='color' onChange={handleChange} required>
              <option value='Not Listed'>{strings.colorSelect}</option>
              {strings.colors.map((color) => {
                return (
                  <option key={color} value={color}>
                    {color}
                  </option>
                );
              })}
            </SelectStyles>
          </SelectContainerStyles>
        </InputContainerStyles>
      </VehicleFormStyles>
      <VehicleSpecContainerStyles>
        <div className='Container-Title'>{strings.vehicleSpecs}</div>
        <div className='Image-Container'>
          <img
            src={cloudData ? cloudData.secure_url : DefaultImage}
            alt='vehicle'
            className='app-vehicle-image'
          />
          <input
            className='file-input'
            id='inputUpload'
            type='file'
            hidden
            accept='image/*'
            onChange={uploadImage}
          />
        </div>
        <ButtonContainerStyles margin='10px auto'>
          <ButtonSpanStyles driver inverse id='fileUpload' onClick={openDialog}>
            {strings.changeImage}
          </ButtonSpanStyles>
        </ButtonContainerStyles>
      </VehicleSpecContainerStyles>
    </>
  );
};

export default ManualVehicle;
