import React from 'react';

import AllDriverVehicles from './driver/getVehiclesContext';
import DriverLinkedCharger from './driver/getDriverLinkedChargers';
import DriverAccount from './driver/getDriverContext';
import ChargerStation from './driver/getChargingStation';
import PreviousMapSearch from './driver/getPreviousSearch';
import Coupon from './driver/addCoupon';
import Errors from './shared/ErrorContext';

const ContextWrapper = (props) => {
  return (
    <Errors>
      <DriverAccount>
        <AllDriverVehicles>
          <DriverLinkedCharger>
            <ChargerStation>
              <Coupon>
                <PreviousMapSearch>{props.children}</PreviousMapSearch>
              </Coupon>
            </ChargerStation>
          </DriverLinkedCharger>
        </AllDriverVehicles>
      </DriverAccount>
    </Errors>
  );
};

export default ContextWrapper;
