import AppApiService from '../../../../../../services/AppApi/AppApiService';
import AppPlatformService from '../../../../../../services/AppPlatform/AppPlatformService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppModalService = {
  enableChargerSearch: function (iotHubDeviceId) {
    return AppApiService.post('enableChargerSearch', null, iotHubDeviceId);
  },
  disableChargerSearch: function (iotHubDeviceId) {
    return AppApiService.post('disableChargerSearch', null, iotHubDeviceId);
  },
  strings: localStrings,
};

export default AppModalService;
