import styled from 'styled-components';

export const JumboTronHeaderStyles = styled.div`
  display: ${({ display }) => display};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  padding: ${({ padding }) => (padding ? padding : '10px')};
  text-align: ${({ align }) => (align ? align : 'center')};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? borderBottom : '1px solid #e7e7e7'};
  margin: ${({ margin }) => margin};
  flex: ${({ flex }) => flex};

  .Image {
    max-width: 120px;
    margin: 0 auto 10px;
    img {
      width: 100%;
    }
  }
  .Content {
    padding: ${({ contentPadding }) =>
      contentPadding ? contentPadding : '0 15px'};
    margin: 0 auto 5px;
    text-align: ${({ contentAlign }) =>
      contentAlign ? contentAlign : 'center'};
    h3 {
      margin-bottom: 5px;
      font-size: ${({ contentHeader }) =>
        contentHeader ? contentHeader : '17px'};
    }
    p {
      font-size: ${({ contentSize }) => (contentSize ? contentSize : '14px')};
      margin: ${({ contentMargin }) => contentMargin};
    }
  }
  .react-tabs__tab-list {
    margin: 25px 0 0;
  }
`;
