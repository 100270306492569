import React, { useState, useEffect, useContext } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';

//layout
import { OuterWrapper, ComponentWrapper } from '../OperatorShared/index';
import Button from '../../shared/Buttons/Button';

//services
import AppBankService from './AppBankService';

//components
import FormWizard from './FormWizard/FormWizard';
import BankPortal from './Display/BankPortal';

//global components
import { LoadingSpinner } from '../../shared';

//styles
import { ListPlaceholderStyles, FormContainerStyles } from '../../SharedStyles';

//context
import { CsoAccountContext } from '../../context/operator/getCsoAccount';

//helpers
import { STRIPE_API, STRIPE_SUCCESS_URL, STRIPE_FAIL_URL } from '../../shared';

const Bank = (props) => {
  const { strings } = AppBankService;
  const { account, setAccount, updatingAccount } = useContext(
    CsoAccountContext
  );
  const [loading, setLoading] = useState(true);
  const [connectAccount, setConnectAccount] = useState(null);
  const [step, setStep] = useState(1);
  const [redirecting, setRedirecting] = useState(false);
  const [customerInfomation, setCustomerInfomation] = useState(null);
  const [showWizard, setShowWizard] = useState(false);

  const fetchStripeAccount = async () => {
    try {
      let stripeAccount = await AppBankService.getCSOStripeAccounts();
      setConnectAccount(stripeAccount);

      if (stripeAccount) {
        setAccount({
          ...account,
          accountVerified: stripeAccount.accountVerified,
        });
        return 'finished';
      }
    } catch (err) {
      setConnectAccount(null);
      return 'failed';
    }
  };

  const fetchStripeData = async () => {
    try {
      let payoutData = await AppBankService.getPayoutInfo();
      let customerData = await AppBankService.getCSOPaymentInfo();

      //check for null on customerData, stripe changed their code to send null by default;
      setCustomerInfomation({
        payout: payoutData,
        customer: customerData ? customerData.data : [],
      });

      if (payoutData.length === 0 && !customerData) {
        setShowWizard(true);
      }

      return 'finished';
    } catch (err) {
      setCustomerInfomation({
        payout: [],
        customer: [],
      });
      return 'failed';
    }
  };

  const handleVerify = async () => {
    setRedirecting(true);
    let response = await AppBankService.validatePayoutInfo({
      successUrl: STRIPE_SUCCESS_URL,
      failureUrl: STRIPE_FAIL_URL,
    });
    if (response && window.cordova) {
      let browserRef = window.cordova.InAppBrowser.open(
        response,
        '_blank',
        'location=no'
      );

      browserRef.addEventListener('loadstart', function (event) {
        if (event.url.indexOf(STRIPE_SUCCESS_URL || STRIPE_FAIL_URL) === 0) {
          //Loaded the redirect url
          browserRef.close();
          props.history.push('/bank');
          setLoading(false);
          setRedirecting(false);
        }
      });
    } else {
      window.location = response;
      setLoading(false);
    }
  };

  const stepReset = (num) => {
    setStep(num);
  };

  // Proceed to next step
  const nextStep = () => {
    setStep((s) => s + 1);
  };

  // Go back to prev step
  const prevStep = () => {
    setStep((s) => s - 1);
  };

  useEffect(() => {
    if (
      account &&
      account.subscriptionLevel === 0 &&
      ((connectAccount &&
        connectAccount.payoutVerified &&
        connectAccount.paymentVerified) ||
        (connectAccount &&
          connectAccount.payoutVerified &&
          connectAccount.csoStripeDefaultPaymentMethodId &&
          connectAccount.csoStripeDefaultPaymentMethodId.includes('card')))
    ) {
      setLoading(true);
      const updatingAccountAsync = async () => {
        let res = await updatingAccount({
          ...account,
          subscriptionLevel: parseInt(connectAccount.subscriptionPlan),
        });
        if (res) {
          setLoading(false);
        }
      };
      updatingAccountAsync();
    }
    // eslint-disable-next-line
  }, [connectAccount]);

  useEffect(() => {
    const fetchInformation = async () => {
      let resp1 = await fetchStripeAccount();
      let resp2 = await fetchStripeData();
      console.log('Inside Effect', resp1, resp2);
      if (resp1 === 'finished' && resp2 === 'finished') {
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    fetchInformation();
    // eslint-disable-next-line
  }, []);

  const handleShowWizard = (boolean) => {
    setShowWizard(boolean);
  };

  return (
    <StripeProvider apiKey={STRIPE_API}>
      <OuterWrapper path='/' title={strings.pageTitle} internal roles={[1, 2]}>
        <ComponentWrapper title={strings.pageTitle}>
          {loading && <LoadingSpinner />}
          {/* No Accounts  */}
          {!loading && !connectAccount && (
            <ListPlaceholderStyles borderBottom>
              <p>{strings.join}</p>
              <img
                src={require('../../../assets/images/BankPlaceholder.png').default}
                className='Placeholder Image'
                alt='Bank'
              />
            </ListPlaceholderStyles>
          )}
          {/* Account not setup through stripe */}
          {!loading && connectAccount && !connectAccount.accountVerified && (
            <ListPlaceholderStyles borderBottom textPadding='0 0 10px'>
              {redirecting && <LoadingSpinner />}
              <p>{strings.verifyThankYou}</p>
              <p>{strings.verifyProceed}</p>
              <p>
                {strings.verifyConfirm} <em>{strings.verify}</em>{' '}
                {strings.verifyConfirm2}
              </p>
              <img
                src={require('../../../assets/images/BankPlaceholder.png').default}
                className='Placeholder Image'
                alt='Bank'
              />
            </ListPlaceholderStyles>
          )}
          {/* Form wizard for first starting */}
          {connectAccount && connectAccount.accountVerified && (
            <>
              {!loading && showWizard ? (
                <FormContainerStyles
                  padding='0'
                  width='70%'
                  margin='20px auto'
                  height='550px'
                  flexDirection='column'
                  justifyContent='space-between'
                  flexWrap='nowrap'>
                  <Elements>
                    <FormWizard
                      stepReset={stepReset}
                      nextStep={nextStep}
                      prevStep={prevStep}
                      step={step}
                      connectAccount={connectAccount}
                      fetchStripeAccount={fetchStripeAccount}
                      handleShowWizard={handleShowWizard}
                      fetchStripeData={fetchStripeData}
                    />
                  </Elements>
                </FormContainerStyles>
              ) : !loading && !showWizard ? (
                <BankPortal
                  fetchStripeAccount={fetchStripeAccount}
                  fetchStripeData={fetchStripeData}
                  customerData={customerInfomation}
                  connectAccount={connectAccount}
                />
              ) : null}
            </>
          )}
        </ComponentWrapper>
        {/* No Accounts  */}
        {!loading && !connectAccount && (
          <Button
            noMobile
            buttonText={strings.subscribe}
            redirect='/subscription'
            roles={[1]}
          />
        )}
        {/* Account not setup through stripe */}
        {!loading && connectAccount && !connectAccount.accountVerified && (
          <Button
            cso
            onclick={handleVerify}
            buttonText={strings.verify}
            roles={[1]}
          />
        )}
      </OuterWrapper>
    </StripeProvider>
  );
};

export default Bank;
