import {
  locationProperties,
  metergroupProperties,
} from '../../filterProperties';

export const handleFilterUpdates = (account, filters, strings) => {
  let locStr = [...strings.filterLocationList];
  let mgStr = [...strings.filterMetergroupList];
  let chargerStr = [...strings.filterChargersList];

  //Check if all locations is filtered - if not then proceed with checking MG
  account.locations.forEach((l) => {
    locStr.push(l.name);
    if (!filters.location.includes(locationProperties[0])) {
      if (filters.location.includes(l.name)) {
        l.meterGroups.forEach((m) => {
          mgStr.push(m.name);
          if (!filters.metergroup.includes(metergroupProperties[0])) {
            if (filters.metergroup.includes(m.name)) {
              m.circuits.forEach((c) => {
                chargerStr.push(c.chargingStationIds);
              });
            }
          } else {
            m.circuits.forEach((c) => {
              chargerStr.push(c.chargingStationIds);
            });
          }
        });
      }
    } else {
      l.meterGroups.forEach((m) => {
        mgStr.push(m.name);
        if (!filters.metergroup.includes(metergroupProperties[0])) {
          if (filters.metergroup.includes(m.name)) {
            m.circuits.forEach((c) => {
              chargerStr.push(c.chargingStationIds);
            });
          }
        } else {
          m.circuits.forEach((c) => {
            chargerStr.push(c.chargingStationIds);
          });
        }
      });
    }
  });

  chargerStr = [...new Set(chargerStr.flat())];

  return { locStr, mgStr, chargerStr };
};
