import React from 'react';

//styles
import { DivStyles, TextStyles } from '../../../../../SharedStyles';

const IncomeLowBalance = (props) => {
  const { strings } = props;

  return (
    <DivStyles padding='10px' size='14px' borderBottom='none'>
      <TextStyles margin='0 0 10px'>
        <em>{strings.feesContentOne}</em>
      </TextStyles>
      <TextStyles margin='0 0 10px'>{strings.feesContentTwo}</TextStyles>
    </DivStyles>
  );
};

export default IncomeLowBalance;
