import React, { useState, useEffect } from 'react';

//services
import AppNavSectionService from './AppNavSectionService';
import AppBankService from '../Bank/AppBankService';

//components
import AccountBlock from './AccountBlock/AccountBlock';
import MobileMenu from './MobileMenu/MobileMenu';
import Logo from './Logo/Logo';
import CenterNav from './CenterNav/CenterNav';

//hooks
import { useRouter } from '../../shared/hooks/useRouter';

//styles
import { NavBarContainerStyles } from '../../SharedStyles';
import './NavSection.scss';

//context

const NavSection = React.forwardRef((props, ref) => {
  const { strings } = AppNavSectionService;

  const [hasStripeAccount, setHasStripeAccount] = useState(false);

  const router = useRouter();

  const handleRedirection = () => {
    if (props.goBack) {
      router.history.goBack();
    } else {
      router.history.push(props.path);
    }
  };

  const fetchStripeExist = async () => {
    let res = await AppBankService.getCSOStripeAccounts();
    if (res) {
      setHasStripeAccount(true);
    } else {
      setHasStripeAccount(false);
    }
  };

  useEffect(() => {
    if (!hasStripeAccount) {
      fetchStripeExist();
    }
    return () => {
      setHasStripeAccount(false);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <header>
      <NavBarContainerStyles mobileMedia='575px' isInternal={props.isInternal}>
        <div className='Left-Align'>
          <Logo />
          <CenterNav strings={strings} />
        </div>
        <AccountBlock hasStripeAccount={hasStripeAccount} />
      </NavBarContainerStyles>
      {props.isInternal && (
        <MobileMenu
          title={props.title}
          handleRedirection={handleRedirection}
          ref={ref}
          strings={strings}
          roles={props.roles}
          subscribe={props.subscribe}
          onSaveClick={props.onSaveClick || props.handleSubmit}
          router={router}
          save={props.save}
          add={props.add}
          myForm={props.myForm}
          sharedStrings={props.sharedStrings}
          disabled={props.disabled}
        />
      )}
    </header>
  );
});

export default NavSection;
