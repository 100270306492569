import React, { createContext, useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';

//services
import AppChargersService from '../../operator/Chargers/AppChargersService';

// import { ErrorResponse } from '../../shared/ErrorResponse';

//context
import { ErrorContext } from '../shared/ErrorContext';

export const CsoChargerContext = createContext();

const CsoChargers = (props) => {
  const { setError } = useContext(ErrorContext);

  const [allChargers, setAllChargers] = useState([]);
  const [loading, setLoading] = useState(true);

  async function fetchChargers() {
    try {
      let allChargers = await AppChargersService.getChargers();
      if (allChargers) {
        setAllChargers(allChargers);
        setLoading(false);
      }
      return allChargers;
    } catch (err) {
      setError({
        display: true,
        title: 'Something went wrong',
        message: 'Could not gather your charging stations at this time.',
        styles: 'cso',
      });
    }
  }

  const removeCharger = (id) => {
    let removedCharger = allChargers.filter((charger) => {
      return charger.id !== id;
    });
    setAllChargers(removedCharger);
  };

// try to comment code to avoid duplicate request on dashboard/chagrer/reports
  useEffect(() => {
    fetchChargers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CsoChargerContext.Provider
      value={{
        loading,
        setLoading,
        allChargers,
        setAllChargers,
        fetchChargers,
        removeCharger,
      }}>
      {props.children}
    </CsoChargerContext.Provider>
  );
};

export default withRouter(CsoChargers);
