import React, { useState } from 'react';

//layout
import {
  OuterWrapper,
  // Button,
  ComponentWrapper,
  SubscriptionAccess,
} from '../OperatorShared/index';
import Button from '../../shared/Buttons/Button';

//services
import AppChargersService from './AppChargersService';

//components
import ChargersList from './ChargersList/ChargersList';
import EnterpriseAdmin from '../EnterpriseAdmin';

//search Components
import SearchFilterBar from '../OperatorShared/SearchFilterBar';

const Chargers = (props) => {
  const { strings } = AppChargersService;
  const [searchedChargers, setSearchedChargers] = useState([]);

  const searchingChargers = (submittedChargers) => {
    setSearchedChargers(submittedChargers);
  };

  return (
    <OuterWrapper title={strings.pageTitle}>
      <SubscriptionAccess levels={[0, 1, 2]}>
        <ComponentWrapper title={strings.pageTitle}>
          <ChargersList searchedChargers={searchedChargers} />
        </ComponentWrapper>
      </SubscriptionAccess>
      <SubscriptionAccess levels={[3]}>
        <ComponentWrapper external>
          <EnterpriseAdmin noMessage allowTech>
            <SearchFilterBar
              chargersFilter
              strings={strings}
              filterBy={strings.filterList}
              searchingChargers={searchingChargers}
            />
          </EnterpriseAdmin>
          <ChargersList searchedChargers={searchedChargers} />
        </ComponentWrapper>
      </SubscriptionAccess>
      <Button
        cso
        hasMobile
        buttonText={strings.addCharger}
        redirect='/chargers/add'
        roles={[1, 3]}
      />
    </OuterWrapper>
  );
};

export default Chargers;
