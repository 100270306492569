import React, { useState, useEffect, useContext } from 'react';
import { FaSearchengin } from 'react-icons/fa';

//services
import AppMembershipsService from '../AppMembershipsService';

//components
import MembershipCard from './MembershipCard';
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';

//context
import { ErrorContext } from '../../../context/shared/ErrorContext';

//styles
import { ListStyles } from '../../../DriverStyles';
import {
  SearchFormStyles,
  InputRoundStyles,
  SearchIconButton,
} from '../../../SharedStyles';

const MembershipList = (props) => {
  const { strings } = AppMembershipsService;
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedMemberships, setSearchedMemberships] = useState([]);

  useEffect(() => {
    const fetchSearched = async () => {
      try {
        const memberships = await AppMembershipsService.getMembershipsBySearch(
          ''
        );
        if (memberships) {
          setSearchedMemberships(memberships);
          setLoading(false);
        }
      } catch (err) {
        setError({
          display: true,
          styles: 'driver',
          title: 'Something went wrong',
          message: err.response.data.split(/[|]+/).pop(),
        });
      }
    };
    if (props.searchMemberships) {
      fetchSearched();
    }
  }, [props.searchMemberships, setError]);

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const memberships = await AppMembershipsService.getMembershipsBySearch(
        searchTerm
      );
      setSearchedMemberships(memberships);
    } catch (err) {
      setError({
        display: true,
        styles: 'driver',
        title: 'Something went wrong',
        message: err.response.data.split(/[|]+/).pop(),
      });
    }
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      {props.searchMemberships && (
        <SearchFormStyles id='searchForm' onSubmit={handleSearch}>
          {loading && <LoadingSpinner />}
          <InputRoundStyles
            width='90%'
            flex='1 1'
            type='text'
            placeholder={strings.addMembershipPlaceholder}
            maxLength='256'
            name='searchTerm'
            id='Search-Input'
            onChange={handleChange}
            value={searchTerm}
          />
          <SearchIconButton>
            <button form='searchForm' type='submit'>
              <FaSearchengin />
            </button>
          </SearchIconButton>
        </SearchFormStyles>
      )}
      <ListStyles maxHeight='500px'>
        {props.searchMemberships &&
          searchedMemberships.map((membership) => (
            <MembershipCard membership={membership} key={membership.id} />
          ))}
        {props.myMemberships &&
          props.memberships.map((membership) => (
            <MembershipCard
              removeMembership={props.removeMembership}
              membership={membership}
              key={membership.id}
            />
          ))}
      </ListStyles>
    </>
  );
};

export default MembershipList;
