import styled from 'styled-components';

import { defaultOrange } from '../defaultValues';

export const InputContainerStyles = styled.div`
  width: ${({ width }) => (width ? width : '100%')};
  padding: ${({ padding }) => padding};
  flex: ${({ flexWidth }) => flexWidth};
  margin: ${({ margin }) => margin};
  display: ${({ display }) => display};
  align-items: ${({ alignItems }) => alignItems};
  color: ${({ color }) => color};
  display: ${({ display }) => display};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    margin: ${({ mobileMargin }) => mobileMargin};
  }
  label {
    margin: ${({ labelMargin }) => labelMargin};
    color: ${({ labelColor }) => labelColor};
    font-size: ${({ labelSize }) => labelSize};
  }
`;

export const InputStyles = styled.input`
  font-size: ${({ size }) => (size ? size : '14px')};
  width: ${({ width }) => (width ? width : '100%')};
  text-align: ${({ textAlign }) => textAlign};
  flex: ${({ flexWidth }) => flexWidth};
  padding: ${({ padding }) => (padding ? padding : '8px 0px 5px 5px')};
  margin: ${({ margin }) => (margin ? margin : '0 0 5px')};
  color: #a5a5a5 !important;
  border: none;
  outline: none;
  border-bottom: ${({ noBorder }) => (noBorder ? 'none' : '1px solid #cccccc')};
  border: ${({ fullBorder }) => fullBorder};
  background: transparent;

  ${({ hideBoxes }) =>
    hideBoxes &&
    `
::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[type=number] {
  -moz-appearance: textfield;
}
  `}
  &[type='empty'] {
    visibility: hidden;
  }
  &:hover,
  &:active {
    border-bottom: ${({ noBorder }) =>
      !noBorder && `1px solid ${defaultOrange}`};
  }
  &:disabled {
    color: #b7b7b7 !important;
    cursor: not-allowed;
    -webkit-text-fill-color: #b7b7b7;
  }
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    flex: ${({ flexWidth }) => flexWidth && '100%'};
    width: ${({ mobileWidth }) => (mobileWidth ? mobileWidth : '100%')};
    margin: ${({ mobileMargin }) => mobileMargin};
    font-size: ${({ mobileSize }) => mobileSize};
    &[type='empty'] {
      display: none;
    }
  }
`;

export const InputRoundStyles = styled.input`
  flex: ${({ flex }) => flex};
  width: ${({ width }) => (width ? width : '100%')};
  border: 1px solid #e7e7e7;
  border-radius: 15px;
  font-weight: 300;
  padding: 8px 12px;
  color: #a5a5a5;
  height: ${({ height }) => height};
  font-size: ${({ size }) => size};
  margin: ${({ margin }) => margin};
  &[type='empty'] {
    visibility: hidden;
  }
  &:hover,
  &:focus,
  &:active {
    border: 1px solid ${defaultOrange};
  }
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    width: ${({ mobileWidth }) => mobileWidth};
    margin: ${({ mobileMargin }) =>
      mobileMargin ? mobileMargin : '0 5px 10px 0'};
  }
`;

export const InputCheckMarkStyles = styled.div`
  position: relative;
  z-index: 1;
  width: ${({ width }) => (width ? width : '25px')};
  height: ${({ height }) => (height ? height : '25px')};
  margin: 0 10px 0 0;
  border: 1px solid #c2c2c1;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    width: ${({ width }) => (width ? width : '20px')};
    height: ${({ height }) => (height ? height : '20px')};
    margin: 0 5px 0 0;
  }
  &:focus,
  &:hover,
  &:active {
    border: 1px solid #ff8f45;
  }
  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;
export const InputCheckMarkLabelStyles = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  input:checked ~ div {
    &:after {
      display: block;
    }
  }
  input:disabled ~ div {
    cursor: not-allowed;
    &:focus,
    &:hover,
    &:active {
      border: 1px solid #c2c2c1;
    }
  }
  div:after {
    left: 8px;
    top: 2px;
    width: 8px;
    height: 15px;
    border: solid #c2c2c1;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
      left: 6px;
      top: 0px;
      width: 6px;
      height: 15px;
    }
  }
`;
