import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppMissingData from './AppMissingData';

//global component
import ModalButton from '../../shared/Buttons/ModalButton';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
} from '../../SharedStyles';

const ErrorModal = (props) => {
  const { strings } = AppMissingData;

  if (props.styles === 'cso') {
    return (
      <ModalStyles>
        <ModalContainerStyles>
          {/* Header Bar */}
          <ModalHeaderStyles>
            <h2 className='Title'>{props.title}</h2>
            <FaRegWindowClose className='Close' onClick={props.closeError} />
          </ModalHeaderStyles>
          {/* Content Section */}
          <ModalContentStyles>
            {/* Section */}
            <ModalItemStyles>
              <p>{props.message}</p>
            </ModalItemStyles>
            {/* Section */}
          </ModalContentStyles>
          {/* Button Component for Modals*/}
          <ModalButton
            containerMargin='10px 10px 0'
            containerAlign='right'
            buttonPadding='10px'
            buttonMargin='0'
            handleClick={props.closeError}
            buttonText={strings.ok}
          />
        </ModalContainerStyles>
      </ModalStyles>
    );
  }
  if (props.styles === 'driver') {
    return (
      // <ModalStyles>
      //   <ModalContainerStyles>
      //     {/* Header Bar */}
      //     <ModalHeaderStyles>
      //       <h2 className='Title'>{props.title}</h2>
      //       <FaRegWindowClose className='Close' onClick={props.closeError} />
      //     </ModalHeaderStyles>
      //     {/* Content Section */}
      //     <ModalContentStyles>
      //       {/* Section */}
      //       <ModalItemStyles>
      //         <p>{props.message}</p>
      //       </ModalItemStyles>
      //       {/* Section */}
      //     </ModalContentStyles>
      //     {/* Button Component for Modals*/}
      //     <ModalButton
      //       containerMargin='10px 10px 0'
      //       containerAlign='right'
      //       buttonPadding='10px'
      //       buttonMargin='0'
      //       handleClick={props.closeError}
      //       buttonText={strings.ok}
      //     />
      //   </ModalContainerStyles>
      // </ModalStyles>
      <div className='Modal-Display-Driver'>
        <div className='Modal'>
          <div className='Modal-Header'>
            {props.title}
            <div className='Modal-Close'>
              <FaRegWindowClose onClick={props.closeError} />
            </div>
          </div>
          <div className='Modal-Content'>
            <p className='Modal-Content-Text'>{props.message}</p>
          </div>
          <div className='Modal-Button'>
            <button className='app-button' onClick={props.closeError}>
              {strings.ok}
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default ErrorModal;
