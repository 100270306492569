import React, { useState, useEffect } from 'react';

//components
import EnergyChart from './EnergyChart';
import Section from '../IncomeReports/Payout/components/Section';
import Summaries from '../Summaries';

//styles
import { DivStyles, TableStyles } from '../../../../../SharedStyles';

const EnergyContent = (props) => {
  const { filters, reportData } = props;
  const [summaries, setSummaries] = useState({
    numberOfPorts: 0,
    numberOfSessions: 0,
    peakkW: 0,
    averagekWh: 0,
    totalkWh: 0,
    totalSessionAmt: 0,
    totalIdleAmt: 0,
  });

  useEffect(() => {
    if (Object.keys(reportData).length > 0) {
      setSummaries((prev) => ({
        ...prev,
        numberOfPorts: reportData.totalPorts,
        numberOfSessions: reportData.totalSessions,
        peakkW: reportData.peakkW,
        averagekWh: reportData.avgkWh,
        totalkWh: reportData.totalkWh,
        totalSessionAmt: reportData.sessionsAmt,
        totalIdleAmt: reportData.otherAmt,
      }));
    }
  }, [reportData]);

  return (
    <>
      <Section padding='0' borderBottom='none'>
        <DivStyles display='flex' flexWrap='wrap'>
        <TableStyles
            borderTop='1px solid #e7e7e7'
            borderRight='1px solid #e7e7e7'
            flex='1'>
            <Summaries
              justifyContent='center'
              titles={['Ports', 'Sessions']}
              content={[summaries.numberOfPorts, summaries.numberOfSessions]}
            />
          </TableStyles>
          <TableStyles
            flex='1'
            borderTop='1px solid #e7e7e7'
            borderRight='1px solid #e7e7e7'>
            <Summaries
              justifyContent='center'
              titles={['Peak kW', 'Total kWh', 'Avg kWh']}
              content={[
                summaries.peakkW.toFixed(2),
                summaries.totalkWh.toFixed(2),
                summaries.averagekWh.toFixed(2),
              ]}
            />
          </TableStyles>
        </DivStyles>
      </Section>
      {!props.loading && (
        <DivStyles width='100%' overflowX='scroll'>
          <EnergyChart
            minWidth='1000px'
            filterDate={filters.date}
            reportData={props.reportData.energyData}
            fetchReportData={props.fetchReportData}
          />
        </DivStyles>
      )}
    </>
  );
};

export default EnergyContent;
