import React, { useEffect, useState, useContext, useRef } from 'react';

//layout
import {
  OuterWrapper,
  ComponentWrapper,
  RoleCanEdit,
} from '../../OperatorShared';
import Button from '../../../shared/Buttons/Button';

//services
import AppChargersService from '../AppChargersService';
import AppChargersModalService from '../ChargersModals/AppChargersModalService';

//Components
import ChargerHeader from './ChargerHeader';
import ChargerImage from './ChargerEditProps/ChargerImage';
import ChargerOptions from './ChargerOptions/ChargerOptions';
import ChargerMessages from './ChargerOptions/ChargerMessages';
import ChargerComments from './ChargerEditProps/ChargerComments';
import ChargerProperties from './ChargerEditProps/ChargerProperties';

//global components
import ToggleSwitch from '../../OperatorShared/ToggleSwitch/CsoToggleSwitch';
import CsoNotFound from '../../../shared/AppNotFound/CsoNotFound';
import LoadingSpinner from '../../../shared/Loading/LoadingSpinner';
import SubscriptionAccess from '../../OperatorShared/SubscriptionAccess';
// import RouteLeavingGuard from '../../../shared/LeaveGuard/LeaveGuard';

//modals
import Edit from './ChargerEditProps/EditModals/Edit';
import Delete from './ChargerEditProps/EditModals/Delete';

//context
import { CsoAccountContext } from '../../../context/operator/getCsoAccount';
import { CsoChargerContext } from '../../../context/operator/getCsoChargers';
import { CsoMembershipContext } from '../../../context/operator/getCsoMembership';
import { ErrorContext } from '../../../context/shared/ErrorContext';

//helpers
// import { diff } from '../../../shared/LeaveGuard/ObjDiffCheck';

//error Class
import { ErrorResponse } from '../../../shared/ErrorResponse';

//styles
import {
  FormStyles,
  TitleStyles,
  DivStyles,
  ImageThumbnailStyles,
} from '../../../SharedStyles';
import { EnableContainerStyles } from '../../../OperatorStyles';

const ChargersEdit = (props) => {
  const { strings } = AppChargersService;
  const { account, updatingAccount, setAccount, currentUser } = useContext(
    CsoAccountContext
  );
  const { fetchMemberships } = useContext(CsoMembershipContext);

  const buttonRef = useRef(null);
  const { allChargers, setAllChargers } = useContext(CsoChargerContext);
  const { setError } = useContext(ErrorContext);
  const [charger, setCharger] = useState({});
  // const [prevCharger, setPrevCharger] = useState({});
  const [location, setLocation] = useState({});
  const [meterGroup, setMeterGroup] = useState({});
  const [circuit, setCircuit] = useState({});
  const [connectorCircuits, setConnectorCircuits] = useState([]);
  const [chargerEdit, setChargerEdit] = useState(false);
  const [chargerDelete, setChargerDelete] = useState(false);
  const [firmwareUpdates, setFirmwareUpdates] = useState({
    display: false,
    firmwareVersion: null,
  });
  // const [isBlocking, setIsBlocking] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updatedMemberships, setUpdatedMemberships] = useState({
    toAdd: [],
    toRemove: [],
  });

  const [chargerData, setChargerData] = useState({
    oldLocationId: '',
    newLocationId: '',
    oldMeterGroupId: '',
    newMeterGroupId: '',
    oldCircuitId: '',
    newCircuitId: '',
    oldCircuitIds: [],
    newCircuitIds: [],
    chargingStationId: '',
  });
  const [notFound, setNotFound] = useState({
    display: false,
    message: '',
  });

  const getCircuitIdsFromCharger = (response) => {
    if (response?.evses) { //todo: check connectors null?
      return response.evses[0].connectors.map(x => { 
        return { connectorId: x.id, csoCircuitId: x.csoCircuitId }; 
      });
    }
    return [];
  }

  const getCircuitIdsFromConnectorCircuits = (connectorCircuits) => {
    if (connectorCircuits) {
      return connectorCircuits.map(x => {         
        return ({ connectorId: x.connectorId, csoCircuitId: x.circuit?.id }); 
      });
    }
    return [];
  }

  const compareCircuitIds = (oldCircuitIds, newCircuitIds) => {
    if (oldCircuitIds && newCircuitIds) {
      if (oldCircuitIds.length !== newCircuitIds.length) {
        return false;
      }
      return JSON.stringify(oldCircuitIds) === JSON.stringify(newCircuitIds);
    }

    return false;
  };

  const getConnectorCircuitsForCircutsId = (allCircuits, circuitIds) => {
    const cnnCircs = [];
    circuitIds.forEach((cirId) => {
      let cnnCirc = {connectorId: cirId.connectorId, circuit: null};
      allCircuits.forEach((cir) => { 
        if (cir.id === cirId.csoCircuitId) {
          cnnCirc.circuit = cir;
        }
      });
      cnnCircs.push(cnnCirc);
    });
    return cnnCircs;
  }

  const fetchData = async () => {
    const request = {
      iotHubDeviceId: props.match.params.id,
      location: currentUser.userLocation,
    };
    try {
      let response = await AppChargersService.getCharger(request);

      try {
        let firmwareCheck = await AppChargersService.getFirmwareUpdates(
          response.iotHubDeviceId
        );

        if (firmwareCheck !== '') {
          setFirmwareUpdates({ display: true, firmwareVersion: firmwareCheck });
        }
      } catch (err) {
        console.log(err);
      }

      if (response.csoAccountId !== account.id) {
        throw new Error();
      }
      //assigning the first and only pricing schedule to the station if it is a shared account. They can only have one Pricing schedule.
      if (account.subscriptionLevel === 1) {
        if (account.pricingSchedules.length > 0) {
          response.pricingScheduleId = account.pricingSchedules[0].id;
          response.enabledPricingSchedule = true;
        }
      }
      setCharger((prevResponse) => {
        let foundIndex = account.pricingSchedules.findIndex(
          (sx) => sx.id === response.pricingScheduleId
        );
        if (foundIndex < 0) {
          response.pricingScheduleId = null;
        }
        return response;
      });
      
      setChargerData((prevData) => ({
        ...prevData,
        oldLocationId: response.csoLocationId,
        newLocationId: response.csoLocationId,
        oldCircuitId: response.csoCircuitId,
        oldCircuitIds: getCircuitIdsFromCharger(response),
        oldMeterGroupId: response.csoMeterGroupId,
        chargingStationId: response.iotHubDeviceId,
      }));
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setNotFound({
        display: true,
        message: strings.fourOhFour,
      });
    }
  };

  useEffect(() => {
    fetchData();
    return () => {
      localStorage.removeItem('activeMembershipIds');
    };
    // eslint-disable-next-line
  }, []);

  // const toggleBlocking = () => {
  //   setIsBlocking(!isBlocking);
  // };

  // useEffect(() => {
  //   let data = diff(charger, prevCharger);
  //   if (Object.keys(data).length > 0) {
  //     setIsBlocking(true);
  //   } else {
  //     setIsBlocking(false);
  //   }
  // }, [charger, prevCharger]);

  //set intial state with charger values
  useEffect(() => {
    if (account && !loading && Object.keys(meterGroup).length === 0) {
      account.locations.forEach((loc) => {
        if (loc.id === charger.csoLocationId) {
          setLocation(loc);
          loc.meterGroups.forEach((group) => {
            if (group.meterGroupId === charger.csoMeterGroupId) {
              setMeterGroup(group);
              group.circuits.forEach((cir) => { 
                if (cir.id === charger.csoCircuitId) {
                  setCircuit(cir);
                }
              });
              const cnnCircs = getConnectorCircuitsForCircutsId(group.circuits, getCircuitIdsFromCharger(charger));
              setConnectorCircuits(cnnCircs);
            }
          });
        }
      });

      if (Object.keys(charger).length > 0) {
        charger.evses[0].connectors.forEach((connector) => {
          let foundIndex = account.chargingProfiles.findIndex(
            (sx) => sx.id === connector.chargingProfileId
          );
          if (foundIndex < 0) {
            handleConnectorDataChange(connector.id, 'chargingProfileId', null);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, loading, meterGroup]);

  useEffect(() => {
    setChargerData((prevData) => ({
      ...prevData,
      newMeterGroupId: meterGroup.meterGroupId,
      newCircuitId: circuit.id,
      newCircuitIds: getCircuitIdsFromConnectorCircuits(connectorCircuits),
    }));
  }, [meterGroup, circuit, connectorCircuits]);

  //close modals
  const closeModal = () => {
    setChargerEdit(false);
    setChargerDelete(false);
    setError({ display: false, title: '', message: '', styles: 'cso' });
  };

  const updateMeterGroup = (mgId) => {
    let meter = location.meterGroups.filter((mg) => {
      return mg.meterGroupId === mgId;
    });
    setMeterGroup(meter[0]);
  };

  const updateCircuit = (cirId) => {
    if (cirId) {
      let singleCircuit = meterGroup.circuits.filter((cir) => {
        return cir.id === cirId;
      });
      setCircuit(singleCircuit[0]);
    } else {
      setCircuit({});
    }
  };
  
  const updateConnectorCircuits = (cnnCircs) => {
    if (cnnCircs && cnnCircs.length > 0) {
      setConnectorCircuits(cnnCircs);
    } else {
      setConnectorCircuits([]);
    }
  };

  //updates charger when image slider is adjusted
  const updateCharger = (data) => {
    setCharger({ ...charger, ...data });
  };

  //handles the update of information from the modals
  const handleDataChange = (key, value) => {
    setCharger({ ...charger, [key]: value });
  };

  const handleConnectorDataChange = (connectorId, key, value) => {
    let connections = charger.evses[0].connectors.map((conn) => {
      if (conn.id === connectorId) {
        conn[key] = value;
      }
      return conn;
    });
    let updateCharger = charger.evses[0];
    updateCharger.connectors = connections;

    setCharger({
      ...charger,
      evses: [updateCharger],
    });
  };

  //handles the membership updates - updated is Ids'
  const handleUpdateMemberships = (updated) => {
    let prevActiveMemberships = JSON.parse(
      localStorage.getItem('activeMembershipIds')
    );

    let addChargerToMembership = updated.filter((id) => {
      return !prevActiveMemberships.includes(id);
    });

    let removeChargerFromMembership = prevActiveMemberships.filter((id) => {
      return !updated.includes(id);
    });

    setUpdatedMemberships({
      toAdd: addChargerToMembership,
      toRemove: removeChargerFromMembership,
    });
  };

  //turn on public search via modal
  const enableSearchAfterUpdate = async () => {
    await AppChargersService.enableChargerSearch(charger.iotHubDeviceId);
  };

  //toggle Switch data updates
  const handleChange = (value, name, enable) => {
    if (enable) {
      if (value.target.checked) {
        value = true;
      } else {
        value = false;
      }
      //logic for
      if (name === 'enabled' && value === true) {
        let validMetergroup = false;
        account.locations.forEach((l) => {
          if (l.id === charger.csoLocationId) {
            l.meterGroups.forEach((m) => {
              if (m.meterGroupId === charger.csoMeterGroupId) {
                if (
                  charger.phaseType &&
                  charger.phaseType.includes(m.phaseType)
                ) {
                  validMetergroup = true;
                }
              }
            });
          }
        });
        if (!validMetergroup) {
          setError({
            display: true,
            title: 'Phase Type Mismatch',
            message:
              "The phase type of this charger does not match the selected phase type of the associated Meter Group. Change the Meter Group entry to correspond with this model's phase type.",
            styles: 'cso',
          });
          return;
        }
      }
      if (name === 'enabledSubscription') {
        let checkingStations = allChargers.filter((station) => {
          return station.enabledSubscription;
        });
        if (
          account &&
          account.subscriptionLevel === 1 &&
          checkingStations.length >= 1 &&
          value
        ) {
          value = false;
          setError({
            display: true,
            title: strings.limitReached,
            message: `${strings.limitContent} - ${strings.limit}: 1`,
            styles: 'cso',
          });
        }
        // if (
        //   account &&
        //   account.subscriptionLevel === 2 &&
        //   checkingStations.length >= 3 &&
        //   value
        // ) {
        //   value = false;
        //   setError({
        //     display: true,
        //     title: strings.limitReached,
        //     message: `${strings.limitContent} - ${strings.limit}: 3`,
        //     styles: 'cso',
        //   });
        // }
      }
    }
    setCharger({ ...charger, [name]: value });
  };

  const validationCheck = (charger) => {
    charger.evses[0].connectors.forEach((connector) => {
      if (
        (connector.enabledChargingProfile && !connector.chargingProfileId) ||
        connector.chargingProfileId === ''
      ) {
        throw new ErrorResponse(
          'frontend',
          `You have the charging profile enabled for port ${connector.id}, however you do not have a profile assigned.`,
          'Missing Charging Profile'
        );
      }
    });

    if (
      (charger.enabledPricingSchedule && !charger.pricingScheduleId) ||
      charger.pricingScheduleId === ''
    ) {
      throw new ErrorResponse(
        'frontend',
        'You have the pricing schedule enabled, however you do not have a pricing schedule assigned.',
        'Missing Pricing Schedule'
      );
    }
    if (charger.allowReservations) {
      if (!charger.reservationFee) {
        throw new ErrorResponse(
          'frontend',
          'You have the allow reservations enabled, however you do not have a reservation fee.',
          'Missing Reservation Fee'
        );
      }
      if (charger.holdTime === '') {
        throw new ErrorResponse(
          'frontend',
          'You have the allow reservations enabled, however you do not have a reservations hold time.',
          'Missing Reservation Hold Time'
        );
      }
      if (charger.guaranteedSessionTime === '') {
        throw new ErrorResponse(
          'frontend',
          'You have the allow reservations enabled, however you do not have a reservations guaranteed time.',
          'Missing Guaranteed Time'
        );
      }
    }
    if (charger.allowWaitQueue) {
      if (charger.waitQueueGracePeriod === '') {
        throw new ErrorResponse(
          'frontend',
          'You have the allow wait queue enabled, however you do not have a wait queue grace period, these can be set to minutes.',
          'Missing Grace Period'
        );
      }
    }
    if (charger.allowCoupon) {
      let isValid = account.discountSchedules.some((sx) => {
        return sx.chargingStations.includes(charger.iotHubDeviceId);
      });
      if (!isValid) {
        throw new ErrorResponse(
          'frontend',
          'You have the allow coupons enabled, however you do not have a discount assigned.',
          'Missing Discounts'
        );
      }
    }
  };

  //handle submits
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      validationCheck(charger);
      let info;
      if (charger.chargerName === '') {
        info = {
          ...charger,
          chargerName: charger.iotHubDeviceId,
          csoMeterGroupId: meterGroup.meterGroupId,
          csoCircuitId: circuit.id,
          //todo: ???
        };
      } else {
        info = {
          ...charger,
          csoMeterGroupId: meterGroup.meterGroupId,
          csoCircuitId: circuit.id,
        };
      }

      const circIds = getCircuitIdsFromConnectorCircuits(connectorCircuits);
      circIds.forEach((x) => {
        handleConnectorDataChange(x.connectorId, "csoCircuitId", x.csoCircuitId);
      });

      if (updatedMemberships.toAdd.length > 0) {
        await AppChargersModalService.chargerToMembership({
          iotHubDeviceId: [charger.iotHubDeviceId],
          membershipId: updatedMemberships.toAdd,
        });
      }
      if (updatedMemberships.toRemove.length > 0) {
        await AppChargersModalService.removeChargerFromMembership({
          iotHubDeviceId: [charger.iotHubDeviceId],
          membershipId: updatedMemberships.toRemove,
        });
      }

      localStorage.removeItem('activeMembershipIds');
      fetchMemberships();

      //handle small biz and shared enabled publicsearch logic
      if (account.subscriptionLevel === 1 || account.subscriptionLevel === 2) {
        if (charger.enabledSubscription) {
          info = { ...info, publiclySearchable: true };
        } else {
          info = { ...info, publiclySearchable: false };
        }
      }

      let updatedAllCharger = allChargers.map((c) => {
        if (c.iotHubDeviceId === charger.iotHubDeviceId) {
          c.enabledSubscription = charger.enabledSubscription;
        }
        return c;
      });
      setAllChargers(updatedAllCharger);

      let chargerRes = await AppChargersService.updateCharger(info);
      setCharger(chargerRes);
      // setPrevCharger(chargerRes);

      //calls the enabled search on driver option
      if (
        account.subscriptionLevel === 0 ||
        account.subscriptionLevel === 1 ||
        account.subscriptionLevel === 2
      ) {
        if (charger.enabledSubscription) {
          await AppChargersService.enableChargerSearch(charger.iotHubDeviceId);
        } else {
          await AppChargersService.disableChargerSearch(charger.iotHubDeviceId);
        }
      } else {
        if (charger.publiclySearchable) {
          await AppChargersService.enableChargerSearch(charger.iotHubDeviceId);
        } else {
          await AppChargersService.disableChargerSearch(charger.iotHubDeviceId);
        }
      }

      let returnValue = await updatingAccount(account);
      let shouldUpdateMG =
        chargerData.oldMeterGroupId !== chargerData.newMeterGroupId ||
        chargerData.oldCircuitId !== chargerData.newCircuitId ||
        !compareCircuitIds(chargerData.oldCircuitIds, chargerData.newCircuitIds);

      if (returnValue && shouldUpdateMG) {
        console.log(chargerData);
        let res = await AppChargersService.changeMeterGroup(chargerData);
        setAccount({ ...account, ...res });
        setMeterGroup({});
        setCircuit({});
        setConnectorCircuits([]);
      }
      setLoading(false);
      props.history.push('/chargers');
    } catch (err) {
      setLoading(false);
      if (err.name === 'frontend') {
        setError({
          display: true,
          title: err.title,
          message: err.message,
          styles: 'cso',
        });
      } else {
        setError({
          display: true,
          title: strings.pageTitle,
          message: err.message,
          styles: 'cso',
        });
      }
    }
  };

  const toggleModals = (modal) => {
    if (modal === 'delete') {
      setChargerDelete(!chargerDelete);
    }
    if (modal === 'edit') {
      setChargerEdit(!chargerEdit);
    }
  };

  // const handleSubmitCheck = () => {
  //   buttonRef.current.click();
  // };

  if (notFound.display) {
    return <CsoNotFound message={notFound.message} />;
  }
  return (
    <OuterWrapper
      title={strings.editPage}
      internal
      path='/chargers'
      myForm
      roles={[1, 2, 3]}>
      {/* <RouteLeavingGuard
        cso
        when={isBlocking}
        toggleBlocking={toggleBlocking}
        action={handleSubmitCheck}
        loading={loading}
        navigate={(path) => props.history.push(path)}
        shouldBlockNavigation={(location) => {
          if (isBlocking) {
            return true;
          }
          return false;
        }}
      /> */}
      {loading && <LoadingSpinner />}
      {chargerEdit && (
        <Edit
          enableSearchAfterUpdate={enableSearchAfterUpdate}
          handleModalClose={closeModal}
          chargerDetails={charger}
          location={location}
          handleChange={handleChange}
        />
      )}
      {chargerDelete && (
        <Delete handleModalClose={closeModal} chargerDetails={charger} />
      )}
      <ComponentWrapper title={strings.editPage}>
        <FormStyles display='block' id='myForm' onSubmit={handleSubmit}>
          <ChargerHeader
            charger={charger}
            location={location}
            toggleModals={toggleModals}
            roles={[1, 2]}
          />
          <SubscriptionAccess levels={[1, 2, 3]}>
            <ChargerImage
              charger={charger}
              account={account}
              handleDataChange={handleDataChange}
              updateCharger={updateCharger}
            />
          </SubscriptionAccess>
          <EnableContainerStyles>
            <TitleStyles size='18px' margin='0' weight='500'>
              {strings.enableCharger}
            </TitleStyles>
            <ToggleSwitch
              name='enabled'
              deny={currentUser && currentUser.role === 4}
              checked={!!charger.enabled}
              handleChange={handleChange}
            />
          </EnableContainerStyles>
          <DivStyles>
            <ChargerProperties
              chargerDetails={charger}
              meterGroup={meterGroup}
              circuit={circuit}
              connectorCircuits={connectorCircuits}
              location={location}
              updateMeterGroup={updateMeterGroup}
              updateCircuit={updateCircuit}
              updateConnectorCircuits={updateConnectorCircuits}
              handleDataChange={handleDataChange}
              handleConnectorDataChange={handleConnectorDataChange}
              firmwareUpdates={firmwareUpdates}
            />
            <SubscriptionAccess levels={[1, 2, 3]}>
              <RoleCanEdit roles={[1, 2, 4]}>
                <EnableContainerStyles
                  headerPadding='0'
                  padding='10px 10px 10px 0'>
                  <DivStyles display='flex' alignItems='center'>
                    <ImageThumbnailStyles
                      maxWidth='80px'
                      src='https://assets.website-files.com/5cd0acd3ee113701d8036b37/5cf546b773a6233ff7b92d25_AmpedUpSmallBizImage.png'
                      alt='subscription accounts'
                    />
                    <TitleStyles size='18px' margin='0' weight='500'>
                      {strings.enableSubCharger}
                    </TitleStyles>
                  </DivStyles>
                  <ToggleSwitch
                    name='enabledSubscription'
                    checked={!!charger.enabledSubscription}
                    handleChange={handleChange}
                    deny={currentUser && currentUser.role === 4}
                  />
                </EnableContainerStyles>
                {charger.enabledSubscription && (
                  <>
                    <ChargerOptions
                      currentUser={currentUser}
                      charger={charger}
                      handleDataChange={handleDataChange}
                      handleConnectorDataChange={handleConnectorDataChange}
                      handleUpdateMemberships={handleUpdateMemberships}
                    />
                    <ChargerMessages
                      currentUser={currentUser}
                      charger={charger}
                      handleDataChange={handleDataChange}
                    />
                    {Object.keys(charger).length > 0 && (
                      <ChargerComments
                        currentUser={currentUser}
                        charger={charger}
                      />
                    )}
                  </>
                )}
              </RoleCanEdit>
            </SubscriptionAccess>
          </DivStyles>
        </FormStyles>
      </ComponentWrapper>
      <Button
        cso
        hasMobile
        formSubmit
        ref={buttonRef}
        buttonText={strings.save}
        roles={[1, 2, 3]}
      />
    </OuterWrapper>
  );
};

export default ChargersEdit;
