import React, { useContext } from 'react';
import moment from 'moment';

//global components
import SubscriptionAccess from '../../../../../OperatorShared/SubscriptionAccess';

//styles
import { TableRowStyles, TableItemStyles } from '../../../../../../SharedStyles';

//helpers
import {
  endingZeroOnMoney,
  timeConvert,
  milesToKm,
} from '../../../../../../shared/Helpers';

//context
import { CsoAccountContext } from '../../../../../../context/operator/getCsoAccount';

const DetailsItem = (props) => {
  const { currentUser } = useContext(CsoAccountContext);

  const tableMaxItemSize = (title) => {
    return '75px';
    switch (true) {
      case title === 'Detail':
        return '75px';
      default:
        return '75px';
    }
  };

  const tableMinItemSize = (title) => {
    return '70px';
    switch (true) {
      case title === 'Location':
        return '90px';
      case title === 'Charger':
        return '80px';
      case title === 'Date':
        return '80px';
      default:
        return '70px';
    }
  };

  return (
    <TableRowStyles
      hoverBackground='#f1f7ff'
      borderTop='none'
      borderLeft='none'
      borderRight='none'
      align='center'
      width='100%'
      minWidth='1260px'>
      <TableItemStyles width='100%' minWidth='70px' maxWidth='75px'>
        {moment(props.transactionDate).format('MM/DD/YY h:mma')}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='70px' maxWidth='75px'>
        {props.transactionId}
      </TableItemStyles>
      <SubscriptionAccess levels={[3]}>
        <TableItemStyles width='100%' minWidth='70px' maxWidth='75px'>
          {props.posType}
        </TableItemStyles>
      </SubscriptionAccess>
      <SubscriptionAccess levels={[0,1,2]}>
        <TableItemStyles width='100%' minWidth='70px' maxWidth='75px' title={props.userId}>
          {(new String(props.userId)).substr(0, 8)}...
        </TableItemStyles>
      </SubscriptionAccess>
      {props.charger && (
        <TableItemStyles width='100%' minWidth='70px' maxWidth='75px'>
          {props.charger}
        </TableItemStyles>
      )}
      {props.location && (
        <TableItemStyles width='100%' minWidth='70px' maxWidth='75px'>
          {props.location}
        </TableItemStyles>
      )}
      <TableItemStyles width='100%' minWidth='70px' maxWidth='75px'>
        {timeConvert(props.totalTime, true)}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='70px' maxWidth='75px'>
        {props.avgkW.toFixed(2)}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='70px' maxWidth='75px'>
        {props.totalkWh.toFixed(2)}
      </TableItemStyles>
      <TableItemStyles width='100%' minWidth='70px' maxWidth='75px'>
        {milesToKm(currentUser.unitOfMeasurement, props.milesAdded.toFixed(2))}
      </TableItemStyles>
      <SubscriptionAccess levels={[3]}>
        <TableItemStyles width='100%' minWidth='70px' maxWidth='75px'>
          {props.rateType === 'Owned' ? 'utility' : `$${endingZeroOnMoney(props.rate, props.rateType)}/${props.rateType}`}
        </TableItemStyles>
      </SubscriptionAccess>
      <SubscriptionAccess levels={[0,1,2]}>
        <TableItemStyles width='100%' minWidth='70px' maxWidth='75px'>
          {props.rateType === 'Owned' ? 'utility' : props.rateType}
        </TableItemStyles>
        <TableItemStyles width='100%' minWidth='70px' maxWidth='75px'>
          ${endingZeroOnMoney(props.rate, props.rateType)}
        </TableItemStyles>
      </SubscriptionAccess>
      <TableItemStyles width='100%' minWidth='70px' maxWidth='75px'>
        ${props.totalCost}
      </TableItemStyles>
      <SubscriptionAccess levels={[3]}>
        <TableItemStyles width='100%' minWidth='70px' maxWidth='75px'>
          {`$${endingZeroOnMoney(props.idleRate, props.idleRateType)}/${props.idleRateType}`}
        </TableItemStyles>
        <TableItemStyles width='100%' minWidth='70px' maxWidth='75px'>
          {timeConvert(props.idleTime, true)}
        </TableItemStyles>
        <TableItemStyles width='100%' minWidth='70px' maxWidth='75px'>
          ${endingZeroOnMoney(props.idleCost, props.idleRateType)}
        </TableItemStyles>
        <TableItemStyles width='100%' minWidth='70px' maxWidth='75px'>
          {props.driverName}
        </TableItemStyles>
        <TableItemStyles width='100%' minWidth='70px' maxWidth='75px'>
          {props.vehicleMake}
        </TableItemStyles>
        <TableItemStyles width='100%' minWidth='70px' maxWidth='75px'>
          {props.vehicleModel}
        </TableItemStyles>
        <TableItemStyles width='100%' minWidth='70px' maxWidth='75px'>
          {props.vehicleYear}
        </TableItemStyles>
      </SubscriptionAccess>
    </TableRowStyles>
  );
};

export default DetailsItem;
