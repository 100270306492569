import React from 'react';

//layout
import { OuterWrapper, ComponentWrapper } from '../OperatorShared';

const BankFailed = () => {
  return (
    <OuterWrapper path='/' internal title='Stripe Has Failed'>
      <ComponentWrapper title='Stripe Has Failed'>
        Something went wrong with the verification Process please try again
        later.
      </ComponentWrapper>
    </OuterWrapper>
  );
};

export default BankFailed;
