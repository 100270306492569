import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import AppPlatformService from './services/AppPlatform/AppPlatformService';
import AppRoutingService from './services/AppRouting/AppRoutingService';
import AppAuthService from './services/AppAuth/AppAuthService';

//styles
import './index.scss';
// DO NOT REMOVE
import 'bootstrap/dist/css/bootstrap.css';

AppAuthService.checkAuthority();

if (AppPlatformService.isWebApp()) {
  AppAuthService.isAuthenticated().then((isAuthenticated) => {
    if (isAuthenticated) {
      ReactDOM.render(
        AppRoutingService.getRouting(isAuthenticated),
        document.getElementById('root')
      );
    } else {
      if (
        localStorage.getItem('driverAccount') ||
        localStorage.getItem('csoAccount')
      ) {
        AppAuthService.doWebSignIn(() => {
          ReactDOM.render(
            AppRoutingService.getRouting(isAuthenticated),
            document.getElementById('root')
          );
        });
      } else {
        ReactDOM.render(
          AppRoutingService.getRouting(isAuthenticated),
          document.getElementById('root')
        );
      }
    }
  });
} else {
  document.addEventListener(
    'deviceready',
    () => {
      function goOffline() {
        // Redirect to your local/offline page here
        window.open = window.cordova.InAppBrowser.open;

        AppAuthService.isAuthenticated().then((isAuthenticated) => {
          ReactDOM.render(
            AppRoutingService.offlineRouting(),
            document.getElementById('root')
          );
        });
      }

      function goOnline() {
        // Load the actual online content in InAppBrowser
        window.open = window.cordova.InAppBrowser.open;

        AppAuthService.isAuthenticated().then((isAuthenticated) => {
          ReactDOM.render(
            AppRoutingService.getRouting(isAuthenticated),
            document.getElementById('root')
          );
        });
      }

      window.open = window.cordova.InAppBrowser.open;

      AppAuthService.isAuthenticated().then((isAuthenticated) => {
        ReactDOM.render(
          AppRoutingService.getRouting(isAuthenticated),
          document.getElementById('root')
        );
      });
      document.addEventListener('offline', goOffline, false);
      document.addEventListener('online', goOnline, false);
    },
    false
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
