import React, { useState, useEffect } from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

//services
import AppLocationsService from '../AppLocationsService';

//global component
import ModalButton from '../../../shared/Buttons/ModalButton';

//styles
import {
  ModalStyles,
  ModalContainerStyles,
  ModalHeaderStyles,
  ModalContentStyles,
  ModalItemStyles,
  InputStyles,
} from '../../../SharedStyles';

const TransformerModal = (props) => {
  const { handleCloseModal, handleDataChange } = props;
  const { strings } = AppLocationsService;
  const [kva, setKva] = useState('');

  useEffect(() => {
    setKva(props.kva);
  }, [props.kva]);

  const closeModal = () => {
    handleCloseModal();
  };

  const handleChange = (e) => {
    setKva(e.target.value);
  };

  const handleSaveInfo = (e) => {
    e.preventDefault();
    handleDataChange('transformerPower', kva);
    handleCloseModal();
  };

  return (
    <ModalStyles>
      <ModalContainerStyles>
        {/* Header Bar */}
        <ModalHeaderStyles>
          <h2 className='Title'>{strings.transformerTitle}</h2>
          <FaRegWindowClose className='Close' onClick={closeModal} />
        </ModalHeaderStyles>
        {/* Content Section */}
        <ModalContentStyles>
          {/* Section */}
          <ModalItemStyles padding='0 10px'>
            <p>{strings.transformerContent}</p>
          </ModalItemStyles>
          <ModalItemStyles>
            <form onSubmit={handleSaveInfo} id='modalForm'>
              <InputStyles
                type='number'
                onChange={handleChange}
                name='transformerPower'
                placeholder={strings.transformerPlaceholder}
                value={kva || ''}
                required
              />
            </form>
          </ModalItemStyles>
        </ModalContentStyles>
        {/* Button Component for Modals*/}
        <ModalButton
          containerMargin='10px 10px 0'
          containerAlign='right'
          buttonPadding='10px'
          buttonMargin='0'
          formSubmit='modalForm'
          buttonText={strings.save}
        />
      </ModalContainerStyles>
      {/* <div className='AppCso-modal-container'>
        <div className='AppCso-modal-title'>
          <div>{strings.transformerTitle}</div>
          <div className='AppCso-modal-close' onClick={closeModal}>
            <FaRegWindowClose />
          </div>
        </div>
        <div className='AppCso-modal-content'>
          <p>{strings.transformerContent}</p>
          <div className='AppCso-modal-item'>
            <InputStyles
              type='number'
              onChange={handleChange}
              name='transformerPower'
              placeholder={strings.transformerPlaceholder}
              value={kva || ''}
            />
          </div>
        </div>
        <div className='AppCso-modal-button-container'>
          <div onClick={handleSaveInfo} className='AppCso-modal-button'>
            {strings.save}
          </div>
        </div>
      </div> */}
    </ModalStyles>
  );
};

export default TransformerModal;
