import styled from 'styled-components';

export const ContentContainerStyles = styled.div`
  text-align: left;
  border-bottom: ${props => props.border && `1px solid #e7e7e7`};
  padding: ${props => props.padding};
  font-size: 14px;
  &:last-child {
    padding: 10px 10px 20px;
  }
  h3 {
    font-weight: 600;
    margin: 0 0 15px;
    color: #28456a;
    font-size: 14px;
  }
  .Content-schedules-options {
    color: #c2c2c2;
    margin: ${props => props.optionsMargin};
    display: flex;
    align-items: center;
    label {
      margin: 0 10px 0;
    }
    .Money-Sign {
      height: 30px;
    }
  }
  .Lightgrey-button {
    background-color: #a5a5a5;
    border: none;
    color: white;
    border-radius: 3em;
    text-align: center;
    display: inline-block;
    font-size: 11px;
    width: auto;
    padding: 3px 10px;
    cursor: pointer;
    margin: ${props => props.buttonMargin};
  }
`;
