import AppAuthService from '../../../services/AppAuth/AppAuthService';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';
// import AppApiService from '../../../services/AppApi/AppApiService';
import strings from './strings';

const localStrings = strings[AppPlatformService.getLanguage()];

const NavSectionService = {
  logout: function() {
    if (AppPlatformService.isWebApp()) {
      AppAuthService.doWebSignOut();
    } else {
      AppAuthService.doMobileSignOut();
    }
  },
  strings: localStrings
};

export default NavSectionService;
