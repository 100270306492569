import React from 'react';

//services
import AppSystemUsersService from '../../AppSystemUsersService';

//global components
import CsoToggleSwitch from '../../../OperatorShared/ToggleSwitch/CsoToggleSwitch';

//styles
import {
  FormRowStyles,
  SelectStyles,
  TitleStyles,
  DivStyles,
} from '../../../../SharedStyles';

const EnterpriseLocations = (props) => {
  const { strings } = AppSystemUsersService;
  const { user, updateLocations, account, addUser } = props;

  const handleChange = (e) => {
    let options = e.target.options;
    let selectedLocations = [...options]
      .filter((option) => {
        return option.selected;
      })
      .map((selectedOption) => {
        return selectedOption.value;
      });

    updateLocations(selectedLocations);
  };

  const updateToggleSwitch = (e) => {
    props.handleChange(e.target.name, e.target.checked);
  };

  return (
    <DivStyles padding='10px 10px 15px' borderBottom>
      <TitleStyles size='14px' weight='600'>
        {strings.locations}
      </TitleStyles>
      {/* Edit Portion */}
      <CsoToggleSwitch
        disabled={
          (props.canEdit && !props.canEdit.includes(props.currentUser.role)) ||
          props.pendingUser
        }
        labelMargin='0 15px'
        padding='10px 0'
        checked={!!user.allLocations}
        name='allLocations'
        handleChange={updateToggleSwitch}
        label={strings.allLocations}
      />
      <FormRowStyles>
        {!user.allLocations && !props.pendingUser && (
          <>
            {!addUser ? (
              <SelectStyles
                noDropdown
                flexWidth='50%'
                multiple
                name='locationIds'
                required
                onChange={handleChange}
                disabled={
                  props.canEdit &&
                  !props.canEdit.includes(props.currentUser.role)
                }
                defaultValue={
                  user.locationIds.map((loc) => {
                    return loc;
                  }) || []
                }>
                {account.locations.map((loc) => {
                  return (
                    <option value={loc.id} key={loc.id}>
                      {loc.name}
                    </option>
                  );
                })}
              </SelectStyles>
            ) : (
              <SelectStyles
                noDropdown
                flexWidth='50%'
                multiple
                name='locationIds'
                required
                id='stateSelection'
                onChange={handleChange}
                disabled={
                  props.canEdit &&
                  !props.canEdit.includes(props.currentUser.role)
                }
                defaultValue={[]}>
                {account.locations.map((loc) => {
                  return (
                    <option value={loc.id} key={loc.id}>
                      {loc.name}
                    </option>
                  );
                })}
              </SelectStyles>
            )}
          </>
        )}
      </FormRowStyles>
    </DivStyles>
  );
};

export default EnterpriseLocations;
