import styled from 'styled-components';

export const PropertiesCardStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background: ${({ background }) => background};
  border-bottom: ${({ borderBottom }) => borderBottom};
  padding: ${({ padding }) => (padding ? padding : '10px 10px')};
  &:last-child {
    border-bottom: none;
  }
`;

export const PropertiesHeaderContainerStyles = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  flex: 100% 1;
  text-align: left;
  .Title {
    font-size: ${({ size }) => (size ? size : '14px')};
    font-weight: 600;
    flex: 100% 1;
    color: ${({ theme }) => theme.color};
    .SubTitle {
      font-size: 11px;
      color: grey;
      font-weight: 400;
    }
  }
  .Action-Items {
    display: flex;
    flex-wrap: nowrap;
    color: #8497b6;
    cursor: pointer;
    svg {
      margin: 0 12px 0 0;
    }
  }
`;

export const PropertiesAddressContainerStyles = styled.div`
  flex: 100% 1;
  text-align: left;
  font-size: ${({ size }) => (size ? size : '12px')};
`;

export const PropertiesContentContainerStyles = styled.div`
  flex: 100% 1;
  text-align: left;
  display: flex;
  flex-wrap: ${({ flexWrap }) => (flexWrap ? flexWrap : 'wrap')};
  margin: ${({ margin }) => (margin ? margin : '10px 0')};
`;

export const PropertyContentStyles = styled.div`
  flex-basis: ${({ flex }) => (flex ? `${flex / 4}px` : '25%')};
  flex: ${({ flexGrow }) => flexGrow};
  flex-wrap: nowrap;
  text-align: center;
  margin: 0 0 10px;
  @media screen and (max-width: ${({ theme }) => theme.defaultMediaQuery}) {
    flex-basis: ${({ flex }) => (flex ? `${flex / 3}px` : '33%')};
    flex-wrap: wrap;
  }
  @media screen and (max-width: 375px) {
    flex-basis: ${({ flex }) => (flex ? `${flex / 2}px` : '50%')};
  }
  .Title {
    font-size: ${({ size }) => (size ? size : '12px')};
    font-weight: 600;
    line-height: 18px;
    flex: 100%;
  }
  .Content {
    font-size: ${({ size }) => (size ? size : '12px')};
    line-height: 18px;
    flex: 100%;
    color: ${({ contentColor }) => contentColor};
  }
`;
