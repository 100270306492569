import React, { useEffect, useState, useContext } from 'react';

//layout
import { ComponentWrapper } from '../../shared';

//services
import AppDriverService from '../../../context/driver/AppDriverServices';
import AppFindChargerService from '../AppFindChargerService';

//components
import ChargerCard from '../../ChargerCard/ChargerCard';

//global components
import { LoadingSpinner } from '../../../shared';

//images
import chargerPlaceholderImage from '../../../../assets/images/ChargingStationPlaceholder400.png';

//context
import { DriverContext } from '../../../context/driver/getDriverContext';
import { ErrorContext } from '../../../context/shared/ErrorContext';

//styles
import { ChargerListStyles } from '../../../DriverStyles';
import { ListPlaceholderStyles } from '../../../SharedStyles';

const ChargerFavorites = (props) => {
  const { strings } = AppFindChargerService;
  const { driver } = useContext(DriverContext);
  const { setError } = useContext(ErrorContext);
  const [chargingStations, setChargingStations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDriverLinkedStations = async () => {
      try {
        let linkedChargers = await AppDriverService.getDriverChargers({
          location: driver.driverLocation,
          bypassDirection: false,
        });

        const favorites = linkedChargers.filter((charger) => {
          if (charger.rateType === '--') {
            charger.dead = true;
          }

          if (
            !(charger.home || charger.assigned) &&
            !charger.subscriptionEnabled
          ) {
            charger.dead = true;
          }

          if (!(charger.home || charger.assigned) && charger.private) {
            charger.dead = true;
          }

          return (
            charger.favorite === true ||
            charger.home === true ||
            charger.assigned === true
          );
        });
        setChargingStations(favorites);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError({
          display: true,
          title: 'Something went wrong',
          message:
            'Unable to retrieve your favorite chargers at this current time.',
          styles: 'driver',
        });
      }
    };
    fetchDriverLinkedStations();
    // eslint-disable-next-line
  }, [driver.driverLocation]);

  const compareTwoProps = (a, b) => {
    if (a.home || b.home) {
      if (a.home === b.home) {
        return (
          new Date(a.lastChargeTimeStamp) - new Date(b.lastChargeTimeStamp)
        );
      } else {
        return b.home - a.home;
      }
    } else if (a.assigned || b.assigned) {
      if (a.assigned === b.assigned) {
        return (
          new Date(a.lastChargeTimeStamp) - new Date(b.lastChargeTimeStamp)
        );
      } else {
        return b.assigned - a.assigned;
      }
    } else {
      return -1;
    }
  };

  const handleRemoveFavorite = (chargerId) => {
    let chargers = chargingStations.filter((charger) => {
      return charger.id !== chargerId;
    });
    setChargingStations(chargers);
  };

  let favorites = (
    <ListPlaceholderStyles borderBottom='none' pSize='14px'>
      <p>{strings.noFavs}</p>
      <img src={chargerPlaceholderImage} alt='Placeholder' />
    </ListPlaceholderStyles>
  );

  if (chargingStations.length > 0 && !loading) {
    let sortedStations = chargingStations.sort(compareTwoProps);
    favorites = sortedStations.map((charger) => {
      return (
        <ChargerCard
          linkedCharger={charger}
          handleRemoveFavorite={handleRemoveFavorite}
          charger={charger}
          key={charger.iotHubDeviceId}
        />
      );
    });
  }

  if (!driver.driverLocation && localStorage.getItem('geoLocation')) {
    return (
      <ComponentWrapper title='Missing Location Permission'>
        <ListPlaceholderStyles borderBottom='none' pSize='14px'>
          <p>{strings.locationFeature}</p>
        </ListPlaceholderStyles>
      </ComponentWrapper>
    );
  }

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <ChargerListStyles>{favorites}</ChargerListStyles>
      )}
    </>
  );
};

export default ChargerFavorites;
