import strings from './strings';
import AppPlatformService from '../../../services/AppPlatform/AppPlatformService';

const localStrings = strings[AppPlatformService.getLanguage()];

const AppPaginationService = {
  strings: localStrings
};

export default AppPaginationService;
